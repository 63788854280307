import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;

@Component({
  selector: 'app-obs-cartera',
  templateUrl: './obs.html',
  styleUrls: ['./obs.less'],
})
export class ObsCartera implements OnInit, AfterViewInit {
  @Input() id_company: any;
  @Input() token: any;
  @Input() role: any;
  @Input() open_by_dicom: boolean = false;

  disable: boolean = false;
  tipo: any = 'tubo';
  tipo_label: any = 'Gestión';
  boolean_obs: boolean = false;
  // clase_css:string='margin-bottom: 0px;'
  id1: any;
  user1: any;
  company_id1: any;
  text1: any;
  tipo1: any;
  efectiva1: any;
  porcentaje1: any;
  avatar1: any;
  date_created1: any;
  first_name1: any;
  last_name1: any;
  height_padding: string = 'margin-top:0px';
  @ViewChild('message') mensaje: any;
  @ViewChild('mensajeInput') mensajeInput!: ElementRef;
  @ViewChild('chatContainer') chatContainer!: ElementRef;

  constructor(
    public settings: SettingsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ObsCartera>
  ) {}

  ngOnInit() {
    this.get_personal_data();
    this.calcula_endpoint();
    this.get_observaciones();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.get_personal_data();
    this.calcula_endpoint();
    this.get_observaciones();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.goToLast();
    }, 300);
  }

  //OBS
  observaciones: any[] = [];
  hay_observaciones: boolean = false;
  url = SettingsService.API_ENDPOINT + 'media/profile_images/';
  endpoint: any;

  id_operacion: any;

  calcula_endpoint() {
    if (this.tipo == 'equifax') {
      this.endpoint = `/equifax-observaciones/${this.id_company}/`;
      this.tipo_label = 'Dicom';
    } else if (this.tipo == 'judicial') {
      this.endpoint = `/judicial-observaciones/${this.id_company}/`;
      this.tipo_label = 'Poder Judicial';
    } else if (this.tipo == 'cxp') {
      this.endpoint = `/obervacioncuentas/${this.id_operacion}/`;
      this.tipo_label = 'CXP';
    } else {
      this.endpoint = `/tubo-observaciones/${this.id_company}/`;
      this.tipo_label = 'Gestión';
    }
  }

  get_observaciones = async () => {
    this.observaciones = [];
    this.hay_observaciones = false;

    try {
      const bd: any = await this.settings.get_query(
        1,
        '/cliente/cartera-observaciones/' + this.id_company + '/'
      );
      var body_data: any = bd._body;
      var data = body_data.success;
      console.log(this.id_company);
      switch (bd.status) {
        case 200:

          this.observaciones = data.info.map((item: any) => {
            return {
              nombre: item.user_first_name + ' ' + item.user_last_name,
              fecha: item.day_agenda,
              hora: item.hora_agenda,
              contenido: item.observacion,
              tipo: item.is_client ? 'externo' : 'interno',
            };
          });

          this.observaciones = this.observaciones.reverse();


          if (this.observaciones.length > 0) {
            this.hay_observaciones = true;
            setTimeout(() => {
              this.scrollToTop();
            });
          } else {
            this.hay_observaciones = false;
          }
          setTimeout(() => {
            this.scrollToTop();
          });
          
          break;
        default:
          break;
      }
    } catch (bd: any) {
      switch (bd.status) {
        case 500:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          this.settings.send_mail_500('Recuperar contraseña', bd);
          break;

        case 400:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          break;
        default:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });

          break;
      }
    }
  };

  prevLength: any;
  disabled_btn: boolean = true;
  disable_btn_check($e: any) {
    if (
      $e.target.value != null &&
      $e.target.value != undefined &&
      $e.target.value != ''
    ) {
      this.disabled_btn = false;
    } else {
      this.disabled_btn = true;
    }
  }

  @ViewChild('chatContent') chatContent!: ElementRef;
  scrollToTop() {
    setTimeout(() => {
      this.chatContent.nativeElement.scrollTop = 0;
    }, 0);
  }

  async enviar_mensaje() {
    this.disable = true;
    this.disabled_btn = true;
    let msg;

    if (this.mensajeInput) {
      msg = this.mensajeInput.nativeElement.value;
    } else {
      console.error('Error: mensajeInput no está definido.');
      return;
    }

    if (msg == undefined || msg == null || msg == '') {
      console.error('Error: El mensaje está vacío.');
      this.disable = false;
      this.disabled_btn = false;
      return;
    }

    let value: any = { observacion: msg };
    if (this.efectiva != null) {
      value['efectiva'] = this.efectiva;
    }

    try {
      const bd: any = await this.settings.get_query(
        2,
        'cliente/cartera-observaciones/' + this.id_company + '/',
        value
      );
      var body_data: any = bd._body;

      switch (bd.status) {
        case 200:
          this.get_observaciones();
          setTimeout(() => {
            this.goToLast();
          }, 300);

          this.mensajeInput.nativeElement.value = '';

          setTimeout(() => {
            $('#rogerpapi').scrollTop(9999999999);
            $('#rogerpapi2').scrollTop(9999999999);
          }, 3000);

          this.disable = false;
          this.disabled_btn = true;
          this.open_input_dialog(
            '',
            '',
            '¡La observación fue ingresada con éxito!',
            'Puedes continuar agregando observaciones o gestionar otro prospecto',
            'check_circle',
            '#3BA46F'
          );
          break;
        default:
          break;
      }
    } catch (error: any) {
      console.error('Error en la solicitud POST:', error);
    }
  }

  efectiva = null;
  marcar_gestion($e: any) {
    this.efectiva = $e.value;
  }

  //DIALOG INPUT
  open_input_dialog(
    title: any,
    subtitle: any,
    mensajeprincipal: any,
    mensajenegrita: any,
    icon: any,
    coloricon: any
  ) {
    const dialogRef = this.dialog.open(InputDialog, {
      width: '700px',
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;

    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  user: any;
  async get_personal_data() {
    try {
      const bd: any = await this.settings.get_query(1, 'current-user/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:
          this.user =
            body_data.user.first_name + ' ' + body_data.user.last_name;

          break;
        default:
          break;
      }
    } catch (bd: any) {}
  }

  async delete_obs(id: any) {
    try {
      const bd: any = await this.settings.get_query(
        1,
        '/tubo-observaciones/' + id + '/'
      );
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:
          this.snackBar.open('Observación eliminada', 'Aceptar', {
            duration: 3000,
            panelClass: ['simpli-snack'],
          });

          this.get_observaciones();

          break;
        default:
          break;
      }
    } catch (bd: any) {}
  }

  stripHtml(html: any) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  goToLast() {
    if (!this.chatContainer) {
      console.error('Error: chatContainer not found.');
      return;
    }

    const messages = this.chatContainer.nativeElement;
    messages.scrollTop = messages.scrollHeight;
  }
  
  
  
  
}
