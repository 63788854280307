import { Component, Output, EventEmitter, ViewChild, Input, SimpleChanges,} from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData} from 'chart.js';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

declare let require: any;

import Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
import NoData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';
import { SettingsService } from 'src/app/settings/settings.service';
import { VerDetalleDialogoComponent } from './ver-detalle-dialogo/ver-detalle-dialogo.component';

// Inicializa los módulos
Boost(Highcharts);
NoData(Highcharts);
More(Highcharts);


@Component({
  selector: 'app-grafico-multivariables-dos',
  templateUrl: './grafico-multivariables.component.html',
  styleUrls: ['./grafico-multivariables.component.less']
})
export class RelacionCompraVentaComponent {

  @Input() id: any;
  @Input() graf_cvnc_obj: any;
  @Input() onReloadResumen: boolean = false;

  @Output() onReload: EventEmitter<any> = new EventEmitter();

  show_graph = false;
  _infoResumen: any;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  iterador: any;
  labels_month: any[] = [];
  @Output() grafico_multivariables = new EventEmitter<string>();

  cardInfo: any[] = [];
  labelsGraph: any[] = [];
  dataGraph: any[] = [];
  public barChartOptions: ChartConfiguration['options'] = {
  };

  public barChartData: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  hasInfo: boolean = false;
  averages:any;

  constructor(public settings: SettingsService, public dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges) {
    this.getResumen12Months();

    if (
      changes['onReloadResumen'] !== undefined &&
      changes['onReloadResumen'].currentValue
    ) {
      this.getResumen12Months();
    }
  }

  infoResumen12: any;
  async getResumen12Months() {
    this.show_graph = false;
    try {
      const body_data: any = await this.settings.get_query(
        1,
        `/resumen-dtes-sii/${this.id}/`
      );
      const _body = body_data._body.success;
      if (_body.ok) {


        let categories = [];
        let comprasMontos = [];
        let facEmitidasMontos = [];
        let notasCreditoMontos = [];
        let ventasExportacionMontos = [];
        let ventasMontos = [];

        let comprasCantidad = [];
        let facEmitidasCantidad = [];
        let notasCreditoCantidad = [];
        let ventasExportacionCantidad = [];
        let ventasCantidad = []

        // Procesar los datos
        for (const fecha in _body.info) {
          if (_body.info.hasOwnProperty(fecha)) {
              const datos = _body.info[fecha];
              categories.push(fecha);
              comprasMontos.push(datos.compras.total_monto);
              facEmitidasMontos.push(datos.facEmitidas.total_monto);
              notasCreditoMontos.push(datos.notasCredito.total_monto);
              ventasExportacionMontos.push(datos.ventasExportacion.total_monto);
              ventasMontos.push(datos.ventas)

              comprasCantidad.push(datos.compras.q_documentos);
              facEmitidasCantidad.push(datos.facEmitidas.q_documentos);
              notasCreditoCantidad.push(datos.notasCredito.q_documentos);
              ventasExportacionCantidad.push(datos.ventasExportacion.q_documentos);
              ventasCantidad.push(datos.facEmitidas.q_documentos - datos.notasCredito.q_documentos);
          }
        }

        this.options.xAxis.categories = categories;
        this.options.series[0].data = comprasMontos;
        this.options.series[1].data = facEmitidasMontos;
        this.options.series[2].data = notasCreditoMontos;
        this.options.series[3].data = ventasExportacionMontos;
        this.options.series[4].data = ventasMontos;

        this.options.series[5].data = comprasCantidad;
        this.options.series[6].data = facEmitidasCantidad;
        this.options.series[7].data = notasCreditoCantidad;
        this.options.series[8].data = ventasExportacionCantidad;
        this.options.series[9].data = ventasCantidad;


        Highcharts.chart('arreglamoslawea', this.options);


        this._infoResumen = _body.info;
        this.calculateAverage(_body.info);
        this.infoResumen12 = this.mapReumenSII(_body.info);
        this.mapDataToGraph();
        this.initGraph();
        this.setFalseReload(true);
        this.show_graph = true;
      }
    } catch (error) {
      console.log(error);
      throw new Error('Error al obtener resumen de 12 meses');
    }
  }

  setFalseReload(value: boolean) {
    console.log('seteando false in grafico');
    this.onReload.emit(value);
  }

  mapReumenSII(data: any) {
    this.cardInfo = [];
    console.log('iterar para transformar');
    for (const periodo in data) {
      const infoResumen = data[periodo];
      const date = this.getDate(periodo);
      const infoResumenMapped = {
        periodo,
        fecha: date[0],
        dialogDate: date[1],
        facEmitidas: infoResumen.facEmitidas.total_monto,
        ventas: infoResumen.ventas,
        compras: infoResumen.compras.total_monto,
        notasCredito: infoResumen.notasCredito.total_monto,
        ventaEfectiva: infoResumen.ventaEfectiva,
        ventasExportacion: infoResumen.ventasExportacion.total_monto,
        ventasIva:infoResumen?.ventas_nominales?.ventas_nominales,
      };
      this.cardInfo.push(infoResumenMapped);
    }
    console.log('card info list', this.cardInfo);
    this.dataSource.data = this.cardInfo;
    if (this.cardInfo.length > 0) {
      this.hasInfo = true;
    }
  }

  getDate(periodo: string) {
    const date = periodo.split('-');
    const year = date[0];
    const month = date[1];

    return [`${year}/${month}`, `${year}-${month}`];
  }


  formatMoney = (amount: any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) { }
  };

  verDetalles = (filtro: any, fecha: any, monto: any) => {
    if (monto != 0) {
      let dialogConfig = new MatDialogConfig();
      let dialogRef = this.dialog.open(VerDetalleDialogoComponent, {
        data: {
          width: '800px',
          filtro: filtro,
          fecha: fecha,
          monto: monto,
          company: this.id,
        },
      });
    }
  };

  getVentaEfectiva(ventas: number, compras: any, notasCredito: any) {
    return ventas - (compras + notasCredito);
  }

  getVentas(facturas: number, nc: number) {
    return facturas - nc;
  }

  mapDataToGraph() {
    this.dataGraph = []
    this.labelsGraph = []
    for (const periodo in this._infoResumen) {
      this.labelsGraph.push(periodo);
      const _infoPeriodo = this._infoResumen[periodo];
      const obj = {
        x: periodo,
        q_compras: _infoPeriodo.compras.q_documentos || 0,
        total_compras: _infoPeriodo.compras.total_monto || 0,
        q_facEmitidas: _infoPeriodo.facEmitidas.q_documentos || 0,
        total_facEmitidas: _infoPeriodo.facEmitidas.total_monto || 0,
        q_notasCredito: _infoPeriodo.notasCredito.q_documentos || 0,
        total_notasCredito: _infoPeriodo.notasCredito.total_monto || 0,
        q_ventasExportacion: _infoPeriodo.ventasExportacion.q_documentos || 0,
        total_ventasExportacion:
          _infoPeriodo.ventasExportacion.total_monto || 0,
        q_ventas:
          _infoPeriodo.facEmitidas.q_documentos -
          _infoPeriodo.notasCredito.q_documentos || 0,
        total_ventas: _infoPeriodo.ventas || 0,
        ventas_iva: _infoPeriodo?.ventas_nominales?.ventas_nominales*1000 || 0
      };
      this.dataGraph.push(obj);
    }
    console.log('datagrahpp-->',this.dataGraph)
  }

  initGraph() {
    // this.empty_graph();

    // if (Object.keys(this._infoResumen).length > 0) {

    //   this.deudores.labels = this.labelsGraph;
      
    //   /** Q totales */
    //   this.deudores.datasets[0].data = this.dataGraph.map((d) => d.q_compras);
    //   this.deudores.datasets[1].data = this.dataGraph.map((d) => d.q_facEmitidas);
    //   this.deudores.datasets[2].data = this.dataGraph.map((d) => d.q_ventas);
    //   this.deudores.datasets[3].data = this.dataGraph.map((d) => d.q_notasCredito);
    //   this.deudores.datasets[4].data = this.dataGraph.map((d) => d.q_ventasExportacion);

    //   /** monto totales */
    //   this.deudores.datasets[5].data = this.dataGraph.map((d) => d.total_compras);
    //   this.deudores.datasets[6].data = this.dataGraph.map((d) => d.total_facEmitidas);
    //   this.deudores.datasets[7].data = this.dataGraph.map((d) => d.total_ventas);
    //   this.deudores.datasets[8].data = this.dataGraph.map((d) => d.total_notasCredito);
    //   this.deudores.datasets[9].data = this.dataGraph.map((d) => d.total_ventasExportacion);
    //   this.deudores.datasets[10].data = this.dataGraph.map((d) => d.ventas_iva);





    //   this.grafico_multivariables.emit('9');
    // } else {
    //   this.grafico_multivariables.emit('9');
    //   //this.settings.empty_local_data();
    // }

  };

  
  calculateAverage(data:any){

    let sum_facEmitidas:number=0;
    let sum_compras:number=0;
    let sum_notasCredito:number=0;
    let sum_ventas:number=0;
    let sum_ventaEfectiva:number=0;
    let sum_ventasIva:number=0;
    let sum_ventaExportacion:number=0;


    let count = Object.keys(data).length;
    if(count == 0){
      count = 1;
    }

  
    for (const periodo in data) {
      const infoResumen = data[periodo];

    
      if(infoResumen?.ventas_nominales?.ventas_nominales!=null){
        sum_ventasIva+=infoResumen?.ventas_nominales?.ventas_nominales
      }

      sum_facEmitidas+=infoResumen.facEmitidas.total_monto;
      sum_compras+=infoResumen.compras.total_monto;
      sum_ventas+=infoResumen.ventas;
      sum_notasCredito+=infoResumen.notasCredito.total_monto;
      sum_ventaEfectiva+=infoResumen.ventaEfectiva;
      sum_ventaExportacion+=infoResumen.ventasExportacion.total_monto
    }
    this.averages={
      promedio_facEmitidas:sum_facEmitidas/count,
      promedio_compras:sum_compras/count,
      promedio_ventas:sum_ventas/count,
      promedio_notasCredito:sum_notasCredito/count,
      promedio_ventaIva:sum_ventasIva/count,
      promedio_ventaEfectiva:sum_ventaEfectiva/count,
      promedio_ventasExportacion:sum_ventaExportacion/count

    }    
  }
  

  options: any = {
    colors: [
        '#924eff', 
        '#0038e3', 
        '#ff81f1',
        '#ff2982', 
        '#b175ff', 
        '#002bbf', 
        '#ff9df4', 
        '#ff559f', 
        '#7a3fcc', 
        '#001f99', 
        '#cc68d8', 
        '#e60066', 
        '#c5a3ff',
        '#669fff', 
        '#ffa0e4'  
    ],
    chart: { type: 'mixed' },
    title: { text: ''},
    xAxis: {
        categories: []
    },
    yAxis: [
      { 
          title: {
              text: 'Monto',
              style: {

              }
          },
      }, { 
          title: {
              text: 'Cantidad',
              style: {

              }
          },
          opposite: true
      }
  ],
    series: [
        {
            name: 'Compras',
            type: 'column',
            yAxis: 0,
            data: []
        },
        {
            name: 'Facturas Emitidas',
            type: 'column',
            yAxis: 0,
            data: []
        },
        {
            name: 'Notas de Credito',
            type: 'column',
            yAxis: 0,
            data: []
        },
        {
            name: 'Ventas Exportación',
            type: 'column',
            yAxis: 0,
            data: []
        },
        {
          name: 'Ventas',
          type: 'column',
          yAxis: 0,
          data: []
        },
        {
            name: 'Compras Conteo',
            type: 'line',
            yAxis: 1,
            data: []
        },
        {
            name: 'Facturas Emitidas Conteo',
            type: 'line',
            yAxis: 1,
            data: []
        },
        {
            name: 'Notas de Credito Conteo',
            type: 'line',
            yAxis: 1,
            data: []
        },
        {
            name: 'Ventas Exportación Conteo',
            type: 'line',
            yAxis: 1,
            data: []
        },
        {
          name: 'Ventas Conteo',
          type: 'line',
          yAxis: 1,
          data: []
      }
    ],
};

  
 


}


