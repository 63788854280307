import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,

} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMessage, INotification, WebSocketService } from 'src/app/services/websocket.service';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;

@Component({
  selector: 'app-chat-cliente',
  templateUrl: './chat-cliente.component.html',
  styleUrls: ['./chat-cliente.component.less'],
})
export class ChatClienteComponent implements OnInit {
  @Input() id_user: any;
  @Input() parent: any;
  @Input() notifications: INotification[] = [];

  valForm = new FormGroup({
    message: new FormControl('', { nonNullable: true }),
  });

  contactos: any[] = [];

  user_selected!: any;

  spinnerContact: boolean = false;
  spinner: boolean = false;


  // pending
  pendingMessages: any[] = [];

  messages: IMessageChat[] = [];
  room_selected: IRooms | null = null;
  spinnerMessages: boolean = false;
  loadStatus: boolean = false;
  messagesChat: IMessageChat[] = [];
  socketOnline: boolean = false;

  // NUEVO
  rooms: IRooms[] = [];
  currentUser: null | string = null;
  emptyMessage: string =
    'Selecciona una chat para comenzar o abre una nueva conversación';

  constructor(
    public settings: SettingsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb: FormBuilder,
    private ws: WebSocketService,
    private cd: ChangeDetectorRef,
  ) {
    this.currentUser = window.localStorage.getItem('user_id') || null;
  }

 

  check_new_message_on_chat(msg: any) {
    if (this.user_selected && msg.sent_by == this.id_user) {
      return true;
    }

    if (this.user_selected && msg.sent_by == this.user_selected.user_id) {
      return true;
    }

    return false;
  }


  ngOnInit() {
    this.get_rooms()

    this.socketOnline = this.ws.isConnected$.value
    this.ws.isConnected().subscribe((online:any) => {
      this.socketOnline = online 

    })


    this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'message':
          const newMessage = this.mapMessages([msg]);
          this.check_new_messages(newMessage);
          this.go_to_last();
          break;
        case 'response_back-message':
          const responseMessage = this.mapMessages([msg]);
          this.check_new_messages(responseMessage);
          this.go_to_last();
          break;
        case 'response_back-room':

          this.get_rooms()
          break;
        case 'connected':

          this.reload_status_user(msg.args.room_id.id);
          break;
        case 'desconected':
          this.reload_status_user(msg.args.room_id.id);
          break;

        case 'notification':

          break;
        default:
          break;
      }
    });

    this.ws.getPendingMessages().subscribe((messages:any) => {
      this.pendingMessages = messages;


      // debo tener los mensajes originamles
      this.messagesChat = [
        ...this.messages,
        ...this.mapMessagePending(this.pendingMessages),
      ];
    });
  }

  // @ViewChildren('messageElement') messageElements!: QueryList<ElementRef>;
  // @ViewChild('supercontenedor', { static: false }) supercontenedor!: ElementRef;
  // ngAfterViewInit() {
  //   this.messageElements.changes.subscribe(() => {
  //     this.scrollToBottom();
  //   });
  // }
  

  // scrollToBottom(): void {
  //   if (this.supercontenedor && this.supercontenedor.nativeElement) {
  //     this.supercontenedor.nativeElement.scrollTop = this.supercontenedor.nativeElement.scrollHeight;
  //   }
  // }

  onTextareaInput(event: any): void {
    this.adjustTextareaHeight(event.target);
  }

  private adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    const lineHeight = this.getLineHeight();
    const minHeight = 1; 
    const maxHeight = 15; 

    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';


    const newHeight = Math.min(textarea.scrollHeight, maxHeight * lineHeight);
    textarea.style.height = newHeight + 'px';

    // Si el número de filas excede el límite, desactivar la entrada
    const currentRows = Math.ceil(newHeight / lineHeight);
    if (currentRows >= maxHeight) {
      textarea.style.overflowY = 'auto';
      textarea.style.overflowX = 'hidden';
    } else {
      textarea.style.overflowY = 'hidden';
    }
  }

  private getLineHeight(): number {
    return 100; 
  }

  go_to_last() {
    setTimeout(() => {
      try {
        let messages: any = document.getElementById('chat-container');
        let shouldScroll =
          messages.scrollTop + messages.clientHeight === messages.scrollHeight;
        if (!shouldScroll) {
          //messages.scrollTop = messages.scrollHeight;
          messages.scrollTo({ top: messages.scrollHeight, behavior: 'smooth' });
        }
      } catch {

      }
    }, 300);
  }

  ///  NUEVOS ENDPOINTS

  async createRoom() {
    try {
      const client_id = window.localStorage.getItem('user_id');
      const exe_id = window.localStorage.getItem('exe_id');
      const payload = {
        action: 'message',
        command: 'room',
        args: { users: [String(exe_id), String(client_id)] },
      };

      this.ws.sendMessage(payload);
    } catch (error) {
      throw new Error(`NO fue posible crear el room ${error}`)
    }
  }
  

  mapNotificationToRooms() {
    const _rooms = this.rooms.map((room) => {
      const notification = this.notifications.find(
        (notif) => notif.room_id === room.room_id
      );
      return {
        ...room,
        hasNew: notification !== undefined ? true : false,
        notificationDate:
          notification !== undefined
            ? this.stringToDate(notification.date_created)
            : null,
      };
    });
    this.rooms = _rooms;
    this.cd.detectChanges();
  }

  async get_rooms() {
    try {
      this.spinnerContact = true;
      const bd: any = await this.settings.get_query(1, 'ws/chat/get-rooms/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
          this.rooms = data.rooms;

          if(this.rooms.length === 0) {
            await this.createRoom()
            return
          } else {
            this.select_room(this.rooms[0])
            this.spinnerContact = false;
            return
          }
        default:
          this.spinnerContact = false;
          break;
      }
    } catch (bd: any) {
      // this.settings.manage_errors(bd.status, '', bd, false);
      this.spinnerContact = false;
    }
  }

  async select_room(room: IRooms) {
    if (this.room_selected?.room_id === room.room_id) {
      return;
    }

    this.room_selected = room;
    const room_id = this.room_selected.room_id.split('ROOM#')[1];
    this.checkHasNotifBadge(room);
    this.valForm.reset();
    await this.get_messages_room(room_id);
    await this.get_user_status(String(this.room_selected.profile_user.id));
    await this.seen_notifications(room.room_id);
  }

  checkHasNotifBadge(room: IRooms) {
    if (room.hasNew) {
      this.rooms = this.rooms.map((_room) => {
        return {
          ..._room,
          hasNew: _room.room_id === room.room_id ? false : _room.hasNew,
        };
      });
    }
    this.cd.detectChanges();
  }

  async get_messages_room(room: string) {
    try {
      this.spinnerMessages = true;
      const bd: any = await this.settings.get_query(
        1,
        `ws/chat/get-messages/?room_id=${room}`
      );
      var body_data: any =bd._body;
      var data = body_data.success;
      this.messages = this.mapMessages(data.messages)
      this.messagesChat = this.mapMessages(data.messages);
      // this.scrollToBottom();      
      this.go_to_last();
      this.spinnerMessages = false;
    } catch (bd: any) {
      this.spinnerMessages = false;
      throw new Error(`ERROR: ${bd}`);
    }
  }

  async get_user_status(user_id: string) {
    try {
      this.loadStatus = true;
      const bd: any = await this.settings.get_query(
        1,
        `ws/chat/get-status/?user_id=${user_id}`
      );
      var body_data: any =bd._body;
      this.mapUserStatus(body_data.success.user_status);
      this.loadStatus = false;
      var data = body_data.success;
    } catch (bd: any) {
      this.loadStatus = false;
      throw new Error(`ERROR: ${bd}`);
    }
  }

  mapUserStatus(user_status: IStatusUser) {
    if (this.room_selected !== null) {
      this.room_selected.profile_user.active = user_status.active;
      this.room_selected.profile_user.last_seen = user_status.lastSeen || null;
      this.room_selected.profile_user.last_seen_format =
        user_status.lastSeenFormat || null;
    }
    this.cd.detectChanges();
  }

  send() {
    const message = this.valForm.controls.message.value;
    if (message == '') {
      return false;
    }
    const payload: IMessage = {
      action: 'message',
      command: 'message',
      args: {
        text: message,
        room_id: this.room_selected?.room_id,
        user_id: window.localStorage.getItem('user_id'),
      },
    };
    this.ws.sendMessage(payload);
    this.ws.getPendingMessages().subscribe((message:any) => {
      this.pendingMessages = message;
    });
    this.valForm.reset();
    setTimeout(() => {
      let messages: any = document.getElementById('chat-container');
      let shouldScroll =
        messages.scrollTop + messages.clientHeight === messages.scrollHeight;
      if (!shouldScroll) {
        messages.scrollTop = messages.scrollHeight;
      }
    }, 300);
  }

  mapMessages(messages: IMessage[]): IMessageChat[] {
    const msg: IMessageChat[] = [];
    const currentUser = window.localStorage.getItem('user_id') || '';
    for (const message of messages) {
      const [username, avatar] = this.getUserInfo(message.args.data.from);
      msg.push({
        date_created: this.formatDateString(
          new Date(message.args.data.date_created)
        ),
        text: message.args.data.text,
        avatar: avatar,
        username: username,
        currentUser: currentUser,
        roomId: message.args.roomId,
        isCurrent:
          Number(currentUser) === Number(message.args.data.from.split('#')[1])
            ? true
            : false,
      });
    }
    return msg;
  }

  async seen_notifications(room_id: string) {
    try {
      const params = encodeURIComponent(`${room_id}`);
      const bd: any = await this.settings.get_query(
        3,
        `ws/chat/seen-notification/?room_id=${params}`
      );
      const body_data: any =bd._body;
      const data = body_data.success;
      this.parent.get_user_notifications();
    } catch (bd: any) {
      throw new Error(`ERROR: ${bd}`);
    }
  }

  getUserInfo(user_id: string): [string, string] {
    const user = user_id.split('ROOM#')[1];
    return ['A', 'A'];
  }

  formatDateString(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses son base 0, así que sumamos 1.
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hour}:${minutes}`;
  }

  stringToDate(date: string): Date {
    const _date = Date.parse(date);
    return new Date(_date);
  }

  async reload_status_user(room_id: string) {
    if (this.room_selected?.room_id === room_id) {
      await this.get_user_status(String(this.room_selected.profile_user.id));
      return;
    }
  }

  mapMessagePending(messages: IMessage[]) {
    return messages.map((message) => {
      return {
        avatar: '',
        username: '',
        date_created: 'Sin conexión...',
        text: message.args.text,
        roomId: message.args.room_id,
        currentUser: window.localStorage.getItem('user_id') || '',
        isCurrent: true,
      };
    });
  }

  check_new_messages(msg: IMessageChat[]) {
    if (this.room_selected === null) {
      const _newRoomMessage = this.rooms.find(
        (item) => item.room_id === msg[0].roomId
      );
      _newRoomMessage!.hasNew = true;
      const _newRooms = this.rooms.filter(
        (item) => item.room_id !== msg[0].roomId
      );
      this.rooms = [_newRoomMessage!, ..._newRooms];
      this.cd.detectChanges();
      return;
    }
    if (this.room_selected.room_id === msg[0].roomId) {
      this.messagesChat = [...this.messagesChat, ...msg];
      this.messages = [...this.messages, ...msg]
      this.cd.detectChanges();
      return;
    }
  }

  ngOnDestroy(): void {
    this.ws.clearPendingMessages();
  }
}

interface IMessageChat {
  date_created: string;
  text: string;
  avatar: null | string;
  username: string;
  currentUser: string;
  isCurrent: boolean;
  roomId: string;
}

interface IRooms {
  company_user: number;
  room_id: string;
  hasNew?: boolean;
  notificationDate?: Date | null;
  profile_user: {
    id: number;
    active?: boolean;
    last_seen?: string | null;
    last_seen_format?: string | null;
    first_name: string;
    last_name: string;
    username: string;
    full_name: string;
    profile: {
      avatar_url: string;
    };
  };
}

interface IStatusUser {
  active: boolean;
  lastSeen: null | string;
  lastSeenFormat: null | string;
}
