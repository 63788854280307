import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SettingsService } from 'src/app/settings/settings.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Banco {
  id: string;
  name: string;
}

interface Node {
  name: string;
  number?: number;
  formType?: string;
  children?: Node[];
  formGroup?: FormGroup;
}

@Component({
  selector: 'app-situacion-financiera',
  templateUrl: './situacion-financiera.component.html',
  styleUrl: './situacion-financiera.component.less',
  encapsulation: ViewEncapsulation.None,
})
export class SituacionFinancieraComponent implements OnInit {
  documentType: string;
  step: number = 0;
  bancos: Banco[] = [];
  filteredOptions: Observable<Banco[]> | undefined;
  bank_selected: any;

  treeControl = new NestedTreeControl<Node>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<Node>();

  valFormStep1: FormGroup;
  valFormStep2: FormGroup;
  valFormStep3: FormGroup;
  valFormStep4_1a: FormGroup;
  valFormStep4_1b: FormGroup;
  valFormStep4_1c: FormGroup;
  valFormStep4_2: FormGroup;
  valFormStep4_3: FormGroup;
  valFormStep4_4: FormGroup;

  substep: number = 0;

  company_id = localStorage.getItem('company_id');
  isLoading: boolean = true;
  buttonLoading: boolean = false;
  isLoadingStep: boolean = false;

  documentStatus: any = {
    antecedentes_personales: false,
    antecedentes_conyuge: false,
    antecedentes_comerciales: false,
    bien_raiz: false,
    vehiculo: false,
    maquinaria: false,
    activos: false,
  };

  antecedentes_comerciales: any[] = [];
  bienes_raices: any[] = [];
  vehiculos: any[] = [];
  maquinaria_equipos: any[] = [];
  otros_activos: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SituacionFinancieraComponent>,
    private settings: SettingsService,
    public snackBar: MatSnackBar
  ) {
    this.valFormStep1 = fb.group({
      direccion: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      departamento: [''],
      comuna: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      ciudad: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      telefono: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]{1}\\s[0-9]{4}\\s[0-9]{4}$'),
        ],
      ],
      profesion: [''],
    });

    this.valFormStep2 = fb.group({
      nacionalidad: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)],],
      profesion: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      empleador: [''],
    });

    this.valFormStep3 = fb.group({
      bank: ['', Validators.compose([Validators.required])],
      numero_cuenta: ['', [Validators.required, Validators.pattern('^[0-9]+$')],],
      linea_credito_disponible: ['', Validators.required],
      linea_credito_utilizada: ['', Validators.required],
      tarjeta_credito_disponible: ['', Validators.required],
      tarjeta_credito_utilizada: ['', Validators.required],
      credito_hipotecario: [null, Validators.required],
    });

    this.valFormStep4_1a = fb.group({
      calle: ['', [Validators.required, Validators.pattern(/.*\S.*/)]],
      comuna: ['', [Validators.required, Validators.pattern(/.*\S.*/)]],
      ciudad: ['', [Validators.required, Validators.pattern(/.*\S.*/)]],
    });

    this.valFormStep4_1b = fb.group({
      fojas: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      ano: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      rol: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });

    this.valFormStep4_1c = fb.group({
      avaluo_fiscal: ['', Validators.required],
      valor_comercial: ['', Validators.required],
      hipoteca: ['', Validators.required],
      seguro: [null, Validators.required],
    });

    this.valFormStep4_2 = fb.group({
      marca: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      modelo: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      ano: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      patente: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      valor_comercial_vehiculo: ['', Validators.required],
      prenda: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      seguro_vehiculo: [null, Validators.required],
    });

    this.valFormStep4_3 = fb.group({
      descripcion: [
        '',
        [Validators.required, Validators.pattern(/^(?!\s*$).+/)],
      ],
      marca: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      modelo: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      valor_comercial_maquinaria: ['', Validators.required],
      antiguedad: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          Validators.max(999),
        ],
      ],
      prenda: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      seguro_maquinaria: [null, Validators.required],
    });

    this.valFormStep4_4 = fb.group({
      descripcion: [
        '',
        [Validators.required, Validators.pattern(/^(?!\s*$).+/)],
      ],
      valor_comercial_activo: ['', Validators.required],
    });

    this.dataSource.data = [
      {
        name: 'individualización de propiedades',
        number: 1,
        children: [
          {
            name: 'Formulario',
            formType: 'individualizacion',
          },
        ],
      },
      {
        name: 'inscripción',
        number: 2,
        children: [
          {
            name: 'Formulario',
            formType: 'inscripcion',
          },
        ],
      },
      {
        name: 'otros datos',
        number: 3,
        children: [
          {
            name: 'Formulario',
            formType: 'otros',
          },
        ],
      },
    ];
    this.treeControl.expand(this.dataSource.data[0]);

    this.documentType = data.documentType;
  }

  hasChild = (_: number, node: Node): boolean => {
    return !!node.children && node.children.length > 0;
  };

  ngOnInit(): void {
    this.load_bancos();
    this.getDocumentos();
  }

  formatPhone(input: any) {
    let value = typeof input === 'object' ? input.target.value : input;
    value = value.replace(/[^\d\s]/g, '');

    if (
      typeof input === 'object' &&
      input.inputType === 'deleteContentBackward'
    ) {
      value = value.slice(0, -1);
    }

    value = value.replace(/\s/g, '');

    if (value.length > 0) {
      if (value.length >= 5) {
        value =
          value.substring(0, 1) +
          ' ' +
          value.substring(1, 5) +
          ' ' +
          value.substring(5, 9);
      } else if (value.length >= 1) {
        value = value.substring(0, 1) + ' ' + value.substring(1);
      }
    }

    if (typeof input === 'object') {
      this.valFormStep1.patchValue(
        {
          telefono: value,
        },
        { emitEvent: false }
      );
    }
    return value;
  }

  async load_bancos() {
    try {
      const bd: any = await this.settings.get_query(1, '/bank/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          let body_data = bd._body;

          for (let i = 0; i < body_data.banks.length; i++) {
            this.bancos.push(body_data.banks[i]);
          }

          this.filteredOptions = this.valFormStep3.controls[
            'bank'
          ].valueChanges.pipe(
            startWith(''),
            map((value: { name: any }) =>
              typeof value === 'string' ? value : value.name
            ),
            map((name: string) =>
              name ? this._filter(name) : this.bancos.slice()
            )
          );

          break;
        default:
          break;
      }
    } catch (bd: any) {}
  }

  private _filter(name: string): Banco[] {
    const filterValue = name.toLowerCase();
    return this.bancos.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  validate_error($event: any) {
    if (this.bank_selected == undefined) {
      this.valFormStep3.controls['bank'].setValue('');
      this.valFormStep3.controls['bank'].setErrors({ required: true });
    }
  }

  filtrar_banco($e: any) {
    this.bank_selected = $e.id;
  }

  formatMonto(event: any, controlName: string): void {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');

    const formattedValue = inputValue
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})(?=\d)/g, '$1.')
      .split('')
      .reverse()
      .join('');

    const forms = [
      this.valFormStep3,
      this.valFormStep4_1c, 
      this.valFormStep4_2,
      this.valFormStep4_3,
      this.valFormStep4_4
    ];

    forms.forEach(form => {
      if (form.contains(controlName)) {
        form.controls[controlName].setValue(formattedValue, {
          emitEvent: false
        });
      }
    });
  }


  formatNumCuenta(event: any): void {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');

    this.valFormStep3.controls['numero_cuenta'].setValue(inputValue, {
      emitEvent: false,
    });
  }

  selectedTitulos: string[] = [];
  onCheckboxChange(event: any, titulo: string): void {
    if (event.checked) {
      if (!this.selectedTitulos.includes(titulo)) {
        this.selectedTitulos.push(titulo);
      }
    } else {
      const index = this.selectedTitulos.indexOf(titulo);
      if (index > -1) {
        this.selectedTitulos.splice(index, 1);
      }
    }
  }

  substepCheckbox(titulo: string) {
    switch (titulo) {
      case 'Bienes raíces':
        this.step = 4;
        this.substep = 1;
        break;
      case 'Vehículos':
        this.step = 4;
        this.substep = 2;
        break;
      case 'Maquinaria y equipos':
        this.step = 4;
        this.substep = 3;
        break;
      case 'Otros activos':
        this.step = 4;
        this.substep = 4;
        break;
    }
  }

  async getDocumentos() {
    this.isLoading = true;
    try {
      const response: any = await this.settings.get_query(
        1,
        `/onboarding/data-onboarding/${this.company_id}/`
      );
      const documentData =
        response._body.success.info.set_documentos.set1.informacion_financiera;

      const mockEvent = { checked: true };

      //ANTECEDENTES PERSONALES
      const antecedentesPersonales = documentData.antecedentes_personales;
      if (
        antecedentesPersonales?.direccion &&
        antecedentesPersonales?.comuna &&
        antecedentesPersonales?.ciudad &&
        antecedentesPersonales?.telefono &&
        antecedentesPersonales?.profesion
      ) {
        this.documentStatus.antecedentes_personales = true;
      } else {
        this.documentStatus.antecedentes_personales = false;
      }

      this.valFormStep1.patchValue({
        direccion: antecedentesPersonales?.direccion || '',
        departamento: antecedentesPersonales?.departamento || '',
        comuna: antecedentesPersonales?.comuna || '',
        ciudad: antecedentesPersonales?.ciudad || '',
        telefono: antecedentesPersonales?.telefono? this.formatPhone(antecedentesPersonales.telefono) : '',
        profesion: antecedentesPersonales?.profesion || '',
      });

      // ANTECEDENTES DEL CONYUGUE
      const antecedentesConyugue = documentData.antecedentes_conyuge;
      if (
        antecedentesConyugue?.nacionalidad &&
        antecedentesConyugue?.profesion &&
        antecedentesConyugue?.empleador
      ) {
        this.documentStatus.antecedentes_conyugue = true;
      } else {
        this.documentStatus.antecedentes_conyugue = false;
      }

      this.valFormStep2.patchValue({
        nacionalidad: antecedentesConyugue?.nacionalidad || '',
        empleador: antecedentesConyugue?.empleador || '',
        profesion: antecedentesConyugue?.profesion || '',
      });

      

      // ANTECEDENTES COMERCIALES
      const antecedentesComerciales = documentData.antecedentes_comerciales;
      if (antecedentesComerciales?.length > 0) {
        this.documentStatus.antecedentes_comerciales = true;

        this.antecedentes_comerciales = documentData.antecedentes_comerciales.map(
          (antecedente: any) => ({
            bank: antecedente.banco,
            numero_cuenta: antecedente.numero_cuenta,
            linea_credito_disponible: antecedente.disponible_debito,
            linea_credito_utilizada: antecedente.utilizado_debito,
            tarjeta_credito_disponible: antecedente.disponible_credito,
            tarjeta_credito_utilizada: antecedente.utilizado_credito,
            credito_hipotecario: antecedente.hipotecario,
          })
        );
      } else {
        this.documentStatus.antecedentes_comerciales = false;
      }

      // CHECKBOX BIENES RAICES
      if (documentData.bienes_raices?.length > 0) {
        this.onCheckboxChange(mockEvent, 'Bienes raíces');
        this.documentStatus.bien_raiz = true;

        this.bienes_raices = documentData.bienes_raices.map(
          (bien_raiz: any) => ({
            calle: bien_raiz.calle_numero,
            comuna: bien_raiz.comuna,
            ciudad: bien_raiz.ciudad,
            fojas: bien_raiz.fojas,
            ano: bien_raiz.ano,
            rol: bien_raiz.rol,
            avaluo_fiscal: bien_raiz.avaluo_fiscal,
            valor_comercial: bien_raiz.valor_comercial,
            hipoteca: bien_raiz.hipoteca_a_favor_de,
            seguro: bien_raiz.seguro,
          })
        );
      } else {
        this.documentStatus.bien_raiz = false;
      }

      // CHECKBOX VEHICULOS
      if (documentData.vehiculos?.length > 0) {
        this.onCheckboxChange(mockEvent, 'Vehículos');
        this.documentStatus.vehiculo = true;

        this.vehiculos = documentData.vehiculos.map((vehiculo: any) => ({
          marca: vehiculo.marca,
          modelo: vehiculo.modelo,
          ano: vehiculo.ano,
          patente: vehiculo.patente,
          valor_comercial_vehiculo: vehiculo.valor_comercial,
          prenda: vehiculo.prenda_a_favor_de,
          seguro_vehiculo: vehiculo.seguro,
        }));
      } else {
        this.documentStatus.vehiculo = false;
      }

      // CHECKBOX MAQUINARIA Y EQUIPOS
      if (documentData.maquinarias?.length > 0) {
        this.onCheckboxChange(mockEvent, 'Maquinaria y equipos');
        this.documentStatus.maquinaria = true;

        this.maquinaria_equipos = documentData.maquinarias.map(
          (maquinaria: any) => ({
            descripcion: maquinaria.descripcion,
            marca: maquinaria.marca,
            modelo: maquinaria.modelo,
            antiguedad: maquinaria.antiguead,
            valor_comercial_maquinaria: maquinaria.valor_comercial,
            prenda: maquinaria.prenda_a_favor_de,
            seguro_maquinaria: maquinaria.seguro,
          })
        );
      } else {
        this.documentStatus.maquinaria = false;
      }

      // CHECKBOX OTROS ACTIVOS
      if (documentData.otros_activos?.length > 0) {
        this.onCheckboxChange(mockEvent, 'Otros activos');
        this.documentStatus.activos = true;

        this.otros_activos = documentData.otros_activos.map((activo: any) => ({
          descripcion: activo.descripcion,
          valor_comercial_activo: activo.valor_comercial,
        }));
      } else {
        this.documentStatus.activos = false;
      }

      this.isLoading = false;
    } catch (error) {
      console.error('Error al obtener datos situacion financiera:', error);
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  get allDocumentsCompleted(): boolean {
    return (
      this.documentStatus.antecedentes_personales &&
      this.documentStatus.antecedentes_conyugue &&
      this.documentStatus.antecedentes_comerciales
    );
  }

  getStatusInfo(titulo: string, type: 'class' | 'icon' | 'toggle'): string {
    let hasInfo = false;

    switch (titulo) {
      case 'Bienes raíces':
        hasInfo = this.documentStatus.bien_raiz;
        break;
      case 'Vehículos':
        hasInfo = this.documentStatus.vehiculo;
        break;
      case 'Maquinaria y equipos':
        hasInfo = this.documentStatus.maquinaria;
        break;
      case 'Otros activos':
        hasInfo = this.documentStatus.activos;
        break;
    }

    switch (type) {
      case 'class':
        return hasInfo ? 'status-cuenta listo' : 'status-cuenta falta';
      case 'icon':
        return hasInfo ? 'fa-light fa-circle-check' : 'fa-light fa-clock';
      case 'toggle':
        return hasInfo
          ? 'fa-regular fa-sharp fa-light fa-pen toggle-icon'
          : 'fa-regular fa-chevron-right toggle-icon';
      default:
        return '';
    }
  }

  get antiguedadValue(): string {
    const value = this.valFormStep4_3.get('antiguedad')?.value;
    return value ? `${value} años` : '';
  }

  transformValue(event: any): void {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    this.valFormStep4_3.patchValue({
      antiguedad: numericValue,
    });
  }

  async putStep(stepType: string) {
    this.isLoadingStep = true;
    this.buttonLoading = true;
    
    try {
      let formData: any = {
        data_documento: {},
        set_documento: "set1",
        tipo_documento: "informacion_financiera"
      };
  
      switch(stepType) {
        case 'antecedentesPersonales':
          formData.data_documento = {
            antecedentes_personales: {
              apellido_paterno: '',
              apellido_materno: '',
              nombres: '',
              rut: '',
              direccion: this.valFormStep1.value.direccion,
              departamento: this.valFormStep1.value.departamento,
              comuna: this.valFormStep1.value.comuna,
              ciudad: this.valFormStep1.value.ciudad,
              telefono: this.valFormStep1.value.telefono,
              fecha_nacimiento: '',
              edad: '',
              estado_civil: '',
              separacion_bienes: '',
              cargas: '',
              profesion: this.valFormStep1.value.profesion,
            },
          };
          break;
  
        case 'antecedentesComerciales':
          formData.data_documento = {
            antecedentes_comerciales: {
              banco: this.valFormStep3.value.bank,
              numero_cuenta: this.valFormStep3.value.numero_cuenta,
              disponible_debito: Number(this.valFormStep3.value.linea_credito_disponible.replace(/\./g, '')),
              utilizado_debito: Number(this.valFormStep3.value.linea_credito_utilizada.replace(/\./g, '')),
              disponible_credito: Number(this.valFormStep3.value.tarjeta_credito_disponible.replace(/\./g, '')),
              utilizado_credito: Number(this.valFormStep3.value.tarjeta_credito_utilizada.replace(/\./g, '')),
              hipotecario: this.valFormStep3.value.credito_hipotecario,
            },
          };
          break;

          case 'antecedentesConyuge':
            formData.data_documento = {
              antecedentes_conyuge: {
                apellido_paterno: '',
                apellido_materno: '',
                nombres: '',
                rut: '',
                nacionalidad: this.valFormStep2.value.nacionalidad,
                fecha_nacimiento: '',
                edad: '',
                profesion: this.valFormStep2.value.profesion,
                empleador: this.valFormStep2.value.empleador,
              },
            };
            break;
  
        case 'bienRaiz':
          formData.data_documento = {
            bienes_raices: {
              calle_numero: this.valFormStep4_1a.value.calle,
              comuna: this.valFormStep4_1a.value.comuna,
              ciudad: this.valFormStep4_1a.value.ciudad,
              fojas: this.valFormStep4_1b.value.fojas,
              ano: this.valFormStep4_1b.value.ano,
              rol: this.valFormStep4_1b.value.rol,
              avaluo_fiscal: Number(this.valFormStep4_1c.value.avaluo_fiscal.replace(/\./g, '')),
              valor_comercial: Number(this.valFormStep4_1c.value.valor_comercial.replace(/\./g, '')),
              hipoteca_a_favor_de: Number(this.valFormStep4_1c.value.hipoteca.replace(/\./g, '')),
              seguro: this.valFormStep4_1c.value.seguro,
              propiedad: '',
            },
          };
          break;
  
        case 'vehiculos':
          formData.data_documento = {
            vehiculos: {
              marca: this.valFormStep4_2.value.marca,
              modelo: this.valFormStep4_2.value.modelo,
              ano: this.valFormStep4_2.value.ano,
              patente: this.valFormStep4_2.value.patente,
              valor_comercial: Number(this.valFormStep4_2.value.valor_comercial_vehiculo.replace(/\./g, '')),
              prenda_a_favor_de: this.valFormStep4_2.value.prenda,
              seguro: this.valFormStep4_2.value.seguro_vehiculo,
            },
          };
          break;
  
        case 'maquinaria':
          formData.data_documento = {
            maquinarias: {
              descripcion: this.valFormStep4_3.value.descripcion,
              marca: this.valFormStep4_3.value.marca,
              modelo: this.valFormStep4_3.value.modelo,
              antiguead: Number(this.valFormStep4_3.value.antiguedad),
              valor_comercial: Number(this.valFormStep4_3.value.valor_comercial_maquinaria.replace(/\./g, '')),
              prenda_a_favor_de: this.valFormStep4_3.value.prenda,
              seguro: this.valFormStep4_3.value.seguro_maquinaria,
            },
          };
          break;
  
        case 'otrosActivos':
          formData.data_documento = {
            otros_activos: {
              descripcion: this.valFormStep4_4.value.descripcion,
              valor_comercial: Number(this.valFormStep4_4.value.valor_comercial_activo.replace(/\./g, '')),
            },
          };
          break;
      }
  
      const response: any = await this.settings.get_query(3, `/onboarding/data-onboarding/${this.company_id}/`, formData);

      const pushMessages: { [key: string]: string } = {
        antecedentesPersonales: 'Los datos fueron guardados',
        antecedentesComerciales: 'La cuenta fue registrada',
        antecedentesConyuge: 'Los datos fueron guardados',
        bienRaiz: 'El bien raíz fue registrado',
        vehiculos: 'El vehículo fue registrado',
        maquinaria: 'La maquinaria fue registrada',
        otrosActivos: 'El activo fue registrado',
      }
  
      if (response && response.status === 200) {
        this.step = 0;
        this.documentType = 'Estado de situación financiera';
        await this.getDocumentos();
  
        this.snackBar.open(pushMessages[stepType] || 'Los datos fueron guardados', 'X', {
          duration: 20000,
          panelClass: 'simpli-snack-onboarding'
        });
      }
  
    } catch (error) {
      console.error(`Error en el paso ${stepType}:`, error);

      this.snackBar.open('Error al guardar datos, intente nuevamente', 'X', {
        duration: 20000,
        panelClass: 'simpli-snack-onboarding'
      });

    } finally {
      this.isLoadingStep = false;
      this.buttonLoading = false;
    }
  }
  allowSpaces(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  selectCheckbox(type: any, data: any) {
    const formatValue = (value: number) => {
      return value.toString()
        .split('')
        .reverse()
        .join('')
        .replace(/(\d{3})(?=\d)/g, '$1.')
        .split('')
        .reverse()
        .join('');
    };

    switch (type) {
      case 'Antecedentes comerciales':
        this.step = 3;

        this.valFormStep3.patchValue({
          bank: data.bank,
          numero_cuenta: data.numero_cuenta,
          linea_credito_disponible: formatValue(data.linea_credito_disponible),
          linea_credito_utilizada: formatValue(data.linea_credito_utilizada), 
          tarjeta_credito_disponible: formatValue(data.tarjeta_credito_disponible),
          tarjeta_credito_utilizada: formatValue(data.tarjeta_credito_utilizada),
          credito_hipotecario: data.credito_hipotecario,
        });
      break;

      case 'Bienes raíces':
        this.step = 4;
        this.substep = 1;

        this.valFormStep4_1a.patchValue({
          calle: data.calle,
          comuna: data.comuna,
          ciudad: data.ciudad,
        });

        this.valFormStep4_1b.patchValue({
          fojas: data.fojas,
          ano: data.ano,
          rol: data.rol,
        });

        this.valFormStep4_1c.patchValue({
          avaluo_fiscal: formatValue(data.avaluo_fiscal),
          valor_comercial: formatValue(data.valor_comercial),
          hipoteca: formatValue(data.hipoteca),
          seguro: data.seguro,
        });
        break;

      case 'Vehículos':
        this.step = 4;
        this.substep = 2;

        this.valFormStep4_2.patchValue({
          marca: data.marca,
          modelo: data.modelo,
          ano: data.ano,
          patente: data.patente,
          valor_comercial_vehiculo: formatValue(data.valor_comercial_vehiculo),
          prenda: data.prenda,
          seguro_vehiculo: data.seguro_vehiculo,
        });
        break;

      case 'Maquinaria y equipos':
        this.step = 4;
        this.substep = 3;

        this.valFormStep4_3.patchValue({
          descripcion: data.descripcion,
          marca: data.marca,
          modelo: data.modelo,
          antiguedad: data.antiguedad,
          valor_comercial_maquinaria: formatValue(data.valor_comercial_maquinaria),
          prenda: data.prenda,
          seguro_maquinaria: data.seguro_maquinaria,
        });
        break;

      case 'Otros activos':
        this.step = 4;
        this.substep = 4;

        this.valFormStep4_4.patchValue({
          descripcion: data.descripcion,
          valor_comercial_activo: formatValue(data.valor_comercial_activo),
        });
        break;

      default:
        break;
    }
  }

  cerrarDialogo() {
    this.dialogRef.close(true);
  }

}
