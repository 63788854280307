import { Component, OnInit, Input, SimpleChanges, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { SettingsService } from "src/app/settings/settings.service";

@Component({
  selector: 'app-resumen-abonos',
  templateUrl: './resumen-abonos.html',
  styleUrls: ['./resumen-abonos.less'],
})
  
export class ResumenAbonosComponent implements OnInit {


  cantidad_facturas:any=0;
  facturas:any[]=[]
  displayedColumns: string[] = [ 'fecha_pago','monto','forma_pago'];
  dataSource = new MatTableDataSource(this.facturas);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  constructor(
    public settings: SettingsService, 
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb: FormBuilder) {

  }

  ngOnInit(){
    
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.facturas);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
    });

  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



}