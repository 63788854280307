import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QueEsReemitir } from './que_es_reemitir/que_es_reemitir';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatSnackBar } from '@angular/material/snack-bar';
import { FacturasdelSii } from '../../facturas-del-sii/facturas-del-sii';



declare var $: any;


@Component({
  selector: 'app-dialog-cesion-parcial',
  templateUrl: './dialog-cesion-parcial.html',
  styleUrls: ['./dialog-cesion-parcial.less']
})
export class DialogCesionParcial implements OnInit {


@Input() id_op:any; 
@Input() id_sim:any; 


title= "Algunas facturas no fueron cedidas";
subtitle = "Surgieron inconvenientes en la cesión. Aún así, la <br>operación sigue activa. Para continuar, revisa el<br>detalle y elimina las facturas no cedida."
show_extra:any=false;
is_responsive:boolean=false;
  constructor(private dialogRef:MatDialogRef<DialogCesionParcial>, private router:Router, private dialog:MatDialog, private settings:SettingsService, private snackBar:MatSnackBar) { }




  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }


  ver_detalle(){
    this.dialogRef.close();
    this.router.navigate(['home/operation-detail-parcial/',this.id_op, this.id_sim])
  }

  que_es_reemitir(params?:any) {

    if(this.is_responsive == true){
      const dialogRef = this.dialog.open(QueEsReemitir, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });

      if(params == 1){
        dialogRef.componentInstance.show_info = true;
        dialogRef.componentInstance.step = 3;
      }

      dialogRef.afterClosed().subscribe((result:any) => {

      });
    }else{
      const dialogRef = this.dialog.open(QueEsReemitir, {
        height: "calc(100% - 30px)",
        width: "calc(100% - 30px)",
        maxWidth: "100%",
        maxHeight: "100%",
        autoFocus:false,
      });

      if(params == 1){
        dialogRef.componentInstance.show_info = true;
        dialogRef.componentInstance.step = 3;
      }

      dialogRef.afterClosed().subscribe((result:any) => {

      });
    }


  }

  pausa_status:boolean=false;
  async pausar_operacion(){
    try {
  

      // const bd: any = await this.settings.get_query(3, '/operaciones/pausar-operacion/'+this.id_op+'/', {});
      // var body_data: any =bd._body;
      let bd = {'status':200}
      switch (bd.status) {
        case 200:
          

          this.title = "¡Pausaste la operación!"
          this.subtitle = "Es tu oportunidad para reemitir las facturas. Tienes <br> los siguientes 2 días hábiles para hacerlo, antes <br> que finalice el plazo para reactivar la operación."
          this.show_extra = true;
          this.pausa_status = true;

    



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }

  }

  async reactivar(){
    try {
  

      const bd: any = await this.settings.get_query(3, '/operaciones/reactivar-operacion/'+this.id_op+'/', {});
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          

        this.dialogRef.close("reemitir")

    



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }

  }

  close_and_recharge(){
    this.dialogRef.close(true)
    this.router.navigate(['home/operaciones/'])
  }

  reemitir_facturas(){

    // this.delete_facturas();

    this.show_extra = null;
    this.title = "¡Importante!"
    this.subtitle = "Luego de agregar la reemisión, vuelve a la<br> plataforma y agrega las facturas reemitidas."
  }

  contactar(){
    $("#chatEjecutivo").trigger('click');
  }

  reemitir_final(){
    window.open("https://www.sii.cl/servicios_online/1039-1183.html", "_blank");
    this.show_extra = 'bang bang';
    this.title = "Agrega las facturas reemitidas";
    this.subtitle ="También puedes continuar con la cesión"
  }

  agregar_mas_facturas(){
    this.dialogRef.close()
    this.router.navigate(['home/agregar-facturas/',this.id_op, this.id_sim])

  }

  continuar_cesion(){
    this.dialogRef.close(true)
    this.router.navigate(['home/detalle-operacion/',this.id_sim])
  }

  async delete_facturas(){
    try {
  

      const bd: any = await this.settings.get_query(4, '/cliente/cesion-parcial/'+this.id_op+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          

            console.log(body_data)

    



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }

  }


}