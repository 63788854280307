import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FacturasconProblemasService {
  private facturas: any[] = [];

  setFacturas(facturas: any[]) {
    this.facturas = facturas;
  }

  getFacturas(): any[] {
    return this.facturas;
  }
}