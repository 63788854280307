


    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-top" style="position: relative;">


        <mat-card class="shadow-box">

          <mat-card-header style="display: block;padding: 0;">
            <h1 class="titulo-simpli">Concentración de Compras</h1>
            <small class="subtitulo-simpli">{{ this.date_start}} / {{ this.date_end}}</small>
          </mat-card-header>


          <mat-card-content >
            <div id="container-participacion-deudores"></div>
            <div *ngIf="ELEMENT_DATA.length > 0  && loading_compras == false">Total facturas: <strong>${{monto_compras}}</strong></div>
            <div *ngIf="ELEMENT_DATA.length > 0  && loading_compras == false">Cant facturas: <strong>{{cantidad_compras}}</strong></div>

            <div class="row" *ngIf="ELEMENT_DATA.length == 0 && loading_compras == false" style="margin-top: 40px;">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      
                <div class="empty-msg">
              
                      
                  <div style="padding-top: 50px;text-align: center;">
                    <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                  </div>
            
                  <div style="padding-top: 50px;text-align: center;">
                    <mat-label class="label-bienvenida">
                      <div class="label-empty">
                        ¡Ups! Al parecer no estas sincronizado.
                      </div>
            
                      <div class="label-empty" style="margin-top: 20px;">
                        Cargar tus credenciales y dale click al botón sincronizar información en la parte superior.
                      </div>
            
                    </mat-label>
                  </div>
            
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                      <div class="btn-simpli-container">
                        <button class="primary-button" (click)="open_credenciales()" mat-flat-button>Cargar credenciales</button>
                      </div>
                      
                    </div>
                  </div>
            
                </div>
      
              </div>
      
            </div>
      
            <div class="row animate__animated animate__fadeIn" *ngIf="ELEMENT_DATA.length == 0 && loading_compras == true" style="margin-top: 40px;">
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="sinRegistros" style="text-align: center;">
                      <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                        <div style="text-align: center;">
                          <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                      </div>
                      </small>
                  </div>
              </div>
      
            </div>
          </mat-card-content>
        </mat-card>  

      </div>

      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-top" style="position: relative;">  


        <mat-card class="shadow-box">

          <mat-card-header style="display: block;padding: 0;">
            <h1 class="titulo-simpli">Ranking de compras</h1>
            <small class="subtitulo-simpli">{{ this.date_start}} / {{ this.date_end}}</small>
          </mat-card-header>

          <mat-card-content>


            <div class="row" *ngIf="ELEMENT_DATA.length > 0 && loading_compras == false">

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                <div class="table-responsive-simpli">
                  <table  mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="business_name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Proveedores</th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;"><input readonly class="no-input blurred" value="{{element.business_name}}"></td>
                    </ng-container>
                
                    <ng-container matColumnDef="conteo">
                      <th mat-header-cell *matHeaderCellDef style="min-width: 30px;" mat-sort-header>Folio</th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="text-align: center;"><input readonly class="no-input" value="{{element.conteo}}"></td>
                    </ng-container>
                
                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef style="min-width: 100px;" mat-sort-header>Monto</th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;"  style="text-align: center;"><input readonly class="no-input" value="${{formatMoney(element.monto)}}"></td>
                    </ng-container>
                
                    <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef style="min-width: 30px;" mat-sort-header>Porcentaje</th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="text-align: center;"><input readonly class="no-input" value="{{element.porcentaje}}%"></td>
                    </ng-container>
      
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>

                  </table>
                </div>

                <mat-paginator  #paginator [length]="this.len_info" [pageSize]="7"></mat-paginator>


              </div>

            </div>


            <div class="row" *ngIf="ELEMENT_DATA.length == 0 && loading_compras == false" style="margin-top: 40px;">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      
                <div class="empty-msg">
              
                      
                  <div style="padding-top: 50px;text-align: center;">
                    <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                  </div>
            
                  <div style="padding-top: 50px;text-align: center;">
                    <mat-label class="label-bienvenida">
                      <div class="label-empty">
                        ¡Ups! Al parecer no estas sincronizado.
                      </div>
            
                      <div class="label-empty" style="margin-top: 20px;">
                        Cargar tus credenciales y dale click al botón sincronizar información en la parte superior.
                      </div>
            
                    </mat-label>
                  </div>
            
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                      <div class="btn-simpli-container">
                        <button class="primary-button" (click)="open_credenciales()" mat-flat-button>Cargar credenciales</button>
                      </div>
                      
                    </div>
                  </div>
            
                </div>
      
              </div>
      
            </div>
      
            <div class="row animate__animated animate__fadeIn" *ngIf="ELEMENT_DATA.length == 0 && loading_compras == true" style="margin-top: 40px;">
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="sinRegistros" style="text-align: center;">
                      <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                        <div style="text-align: center;">
                          <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                      </div>
                      </small>
                  </div>
              </div>
      
            </div>

          </mat-card-content>
        </mat-card> 

      </div>

    </div>

    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-top" style="position: relative;">


        <mat-card class="shadow-box">

          <mat-card-header style="display: block;padding: 0;">
            <h1 class="titulo-simpli">Concentración de Cesiones de Proveedores</h1>
            <small class="subtitulo-simpli">{{ this.date_start}} / {{ this.date_end}}</small>
          </mat-card-header>



          <mat-card-content >
            <div id="container-participacion-cesiones"></div>
            <div *ngIf="ELEMENT_DATA_CESIONES.length > 0  && loading_cesiones == false">Total facturas: <strong>${{total_cesiones}}</strong></div>
            <div *ngIf="ELEMENT_DATA_CESIONES.length > 0  && loading_cesiones == false">Cant facturas: <strong>{{cant_cesiones}}</strong></div>

            <div class="row" *ngIf="ELEMENT_DATA_CESIONES.length == 0 && loading_cesiones == false" style="margin-top: 40px;">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      
                <div class="empty-msg">
              
                      
                  <div style="padding-top: 50px;text-align: center;">
                    <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                  </div>
            
                  <div style="padding-top: 50px;text-align: center;">
                    <mat-label class="label-bienvenida">
                      <div class="label-empty">
                        ¡Ups! Al parecer no estas sincronizado.
                      </div>
            
                      <div class="label-empty" style="margin-top: 20px;">
                        Cargar tus credenciales y dale click al botón sincronizar información en la parte superior.
                      </div>
            
                    </mat-label>
                  </div>
            
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                      <div class="btn-simpli-container">
                        <button class="primary-button" (click)="open_credenciales()" mat-flat-button>Cargar credenciales</button>
                      </div>
                      
                    </div>
                  </div>
            
                </div>
      
              </div>
      
            </div>
      
            <div class="row animate__animated animate__fadeIn" *ngIf="ELEMENT_DATA_CESIONES.length == 0 && loading_cesiones == true" style="margin-top: 40px;">
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="sinRegistros" style="text-align: center;">
                      <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                        <div style="text-align: center;">
                          <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                      </div>
                      </small>
                  </div>
              </div>
      
            </div>
          </mat-card-content>
        </mat-card>  

      </div>

      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-top" style="position: relative;">  


        <mat-card class="shadow-box">

          <mat-card-header style="display: block;padding: 0;">
            <h1 class="titulo-simpli">Ranking de cesiones de proveedores</h1>
            <small class="subtitulo-simpli">{{ this.date_start}} / {{ this.date_end}}</small>
          </mat-card-header>

          <mat-card-content>


            <div class="row" *ngIf="ELEMENT_DATA_CESIONES.length > 0 && loading_cesiones == false">

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                <div class="table-responsive-simpli">
                  <table mat-table #MatSort2="matSort" matSort  [dataSource]="dataSourceCesiones">
                    <ng-container matColumnDef="factoring">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acreedor </th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;"><input class="no-input" readonly value="{{element.factoring}}"></td>
                      <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">Totales</td>
                    </ng-container>
                
                    <ng-container matColumnDef="rut">
                      <th mat-header-cell *matHeaderCellDef style="min-width: 100px;" mat-sort-header> Rut</th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;"><input class="no-input" readonly value="{{element.rut}}"></td>
                      <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;"></td>
                    </ng-container>
                
                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef style="min-width: 100px;" mat-sort-header> Monto </th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;"><input class="no-input" readonly value="${{formatMoney(element.monto)}}"></td>
                      <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">${{formatMoney(anotherArray[0]?.monto)}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="porcentaje">
                      <th mat-header-cell *matHeaderCellDef style="min-width: 30px;" mat-sort-header> % </th>
                      <td mat-cell *matCellDef="let element; let index=index; let i=index;"><input class="no-input" readonly value="{{element.porcentaje}} %"></td>
                      <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">{{anotherArray[0]?.porcentaje}}%</td>
                    </ng-container>
      
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCesiones; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsCesiones"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumnsCesiones"></tr>
                  </table>
    

                </div>

                <mat-paginator #paginatorCesiones [length]="this.len_info_cesiones" [pageSize]="7"></mat-paginator>


              </div>

            </div>


            <div class="row" *ngIf="ELEMENT_DATA_CESIONES.length == 0 && loading_cesiones == false" style="margin-top: 40px;">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      
                <div class="empty-msg">
              
                      
                  <div style="padding-top: 50px;text-align: center;">
                    <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                  </div>
            
                  <div style="padding-top: 50px;text-align: center;">
                    <mat-label class="label-bienvenida">
                      <div class="label-empty">
                        ¡Ups! Al parecer no estas sincronizado.
                      </div>
            
                      <div class="label-empty" style="margin-top: 20px;">
                        Cargar tus credenciales y dale click al botón sincronizar información en la parte superior.
                      </div>
            
                    </mat-label>
                  </div>
            
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                      <div class="btn-simpli-container">
                        <button class="primary-button" (click)="open_credenciales()" mat-flat-button>Cargar credenciales</button>
                      </div>
                      
                    </div>
                  </div>
            
                </div>
      
              </div>
      
            </div>
      
            <div class="row animate__animated animate__fadeIn" *ngIf="ELEMENT_DATA_CESIONES.length == 0 && loading_cesiones == true" style="margin-top: 40px;">
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="sinRegistros" style="text-align: center;">
                      <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                        <div style="text-align: center;">
                          <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                      </div>
                      </small>
                  </div>
              </div>
      
            </div>

          </mat-card-content>
        </mat-card> 

      </div>



    </div>


