import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgendarOnboarding } from 'src/app/onboarding/agendar/agendar';
import { SettingsService } from 'src/app/settings/settings.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SituacionFinancieraComponent } from '../situacion-financiera/situacion-financiera.component';
import { DeclaracionJuradaComponent } from '../declaracion-jurada/declaracion-jurada';

@Component({
  selector: 'app-visualizar-pdf',
  templateUrl: './visualizar-pdf.component.html',
  styleUrl: './visualizar-pdf.component.less',
  encapsulation: ViewEncapsulation.None,
})
export class VisualizarPdfComponent implements OnInit {
  documentType!: string;
  isLoading: boolean = true;
  company_id = localStorage.getItem('company_id');
  pdfUrl: SafeResourceUrl | undefined;
  @Input() tittle: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VisualizarPdfComponent>,
    private settings: SettingsService,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    this.documentType = data.documentType;
  }

  ngOnInit(): void {
    this.getPfdLink();
  }

  onIframeLoad() {
    this.isLoading = false;
  }

  async getPfdLink() {
    this.isLoading = true;

    type DocumentTypes =
      | 'Declaración PEP'
      | 'Estado de situación financiera'
      | 'Declaración jurada'
      | 'Rol de avalista'
      | 'Carta de poder'
      | 'Poder notarial'
      | 'Poderes bancarios';


    const documentTypeMap: Record<DocumentTypes, { path: string, set: number }> = {
      'Declaración PEP': { path: 'declaracion_pep', set: 1 },
      'Estado de situación financiera': { path: 'informacion_financiera', set: 1 },
      'Declaración jurada': { path: 'declaracion_jurada', set: 1 },
      'Rol de avalista': { path: 'rol_avalista', set: 1 },
      'Carta de poder': { path: 'carta_poder',  set: 2 },
      'Poder notarial': { path: 'poder_notarial', set: 2 },
      'Poderes bancarios': { path: 'poderes_bancarios', set: 2 },
    };

    try {
      const docConfig = documentTypeMap[this.documentType as DocumentTypes];
      const response: any = await this.settings.get_query( 1, `/onboarding/data-onboarding/${this.company_id}/`);
      const setData = docConfig.set === 1 ? response._body.success.info.set_documentos.set1 : response._body.success.info.set_documentos.set2;

      const url = setData[docConfig.path].link + '#navpanes=0&scrollbar=1&view=FitH&page=1&pagemode=none';

      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } catch (error) {
      console.error('Error al obtener link de ', this.documentType, ' :', error);
    } finally {
      this.isLoading = false;
    }
  }

  agendar() {
    let dialogRef = this.dialog.open(AgendarOnboarding, {
      width: '700px',
      minHeight: '600px',
      autoFocus: false,
    });
  }

  openDocumentDialog(documentKey: string) {
    let dialogRef: any;

    if (documentKey === 'Estado de situación financiera') {
      dialogRef = this.dialog.open(SituacionFinancieraComponent, {
        width: '600px',
        maxWidth: '95vw',
        autoFocus: false,
        height: 'auto',
        maxHeight: '95vh',
        data: {
          documentType: this.documentType,
        },
      });

    } else if (documentKey === 'Declaración jurada') {
      dialogRef = this.dialog.open(DeclaracionJuradaComponent, {
        width: '600px',
        maxWidth: '95vw',
        autoFocus: false,
        height: 'auto',
        maxHeight: '95vh',
        data: {
          documentType: this.documentType,
        },
      });
    }

    if (dialogRef) {
      dialogRef.afterClosed().subscribe((result: boolean) => {  
        if (result === true) {
          this.dialogRef.close(true);  
        }
      });
    }
  }
}
