<div mat-dialog-title>
    <h1 class="titulo-simpli">{{title}}</h1>
    <small class="subtitulo-simpli">{{subtitle}}</small>
</div>

<div mat-dialog-content>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top cajaGrande" style="box-shadow: none !important;border: none !important; min-height: unset;">

            <small class="textoCajaGrande" style="font-size: 17px;">
            <span class="material-icons animated bounceIn iconstyle" [ngStyle]="{'color':this.coloricon}">
                {{icon}}
            </span> 
            <br>
            <span class="animate__animated animate__fadeIn" style="font-weight: 600;color: #4d4d4d;font-size: 1.1em;">{{mensajeprincipal}}</span><br>
            <ng-container *ngIf="errores.length>0">
                <ul class="animate__animated animate__fadeIn" style="text-align:left; padding-left:28px;">
                    <li *ngFor="let e of errores" style="margin-top:10px;">{{e}}</li>
                </ul>
            </ng-container>
            <span  class="animate__animated animate__fadeIn" *ngIf="errores.length==0">{{mensajenegrita}}</span>
            </small>

        </div>

    </div>

</div>

<div mat-dialog-actions style="margin-bottom: 10px;">
    <button  mat-flat-button class="primary-button" type="button" (click)="close_dialog()">Cerrar</button>
</div>