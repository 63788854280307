
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-alert-error-cesion',
  templateUrl: './alert-error-cesion.html',
  styleUrls: ['./alert-error-cesion.less']
})
export class AlertErrorCesion implements OnInit {


  @Input() id_sim:any;
  @Input() id_company:any;
  @Input() error_certificado:boolean=false;
  @Input() paso:any=0;
  parent:any;

  open_as_sim:boolean=false;

  title:string="¡Cesión realizada con éxito!";
  subtitle:string="Revisa el estado de esta etapa de la operación, con sus datos actualizados."

  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<AlertErrorCesion>, public router:Router) {

    this.id_company = localStorage.getItem('company_id');

   }


  is_responsive:boolean=false;
  ngOnInit() {
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }

  ver_estado(){
    this.dialogRef.close();
    this.parent.change_step('step-simpli3');
  }

  chat_soporte(){
    let url = "https://api.whatsapp.com/send?phone=56934677786";
    window.open(url, "_blanck")
  }

  close_all(){
    this.dialog.closeAll();
  }

  update_cert(){
    this.dialogRef.close(1)
  }

  retry(){
    this.dialogRef.close('retry')
  }


}