<div class="row row-responsive" style="margin-bottom: 50%;">

  <!--new-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <span class="material-icons" (click)="return()">
      keyboard_return
    </span>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <h1 class="titulo-simpli-responsive">Estado de tus simulaciones</h1>
    <small class="subtitulo-simpli-responsive">Revisa la información de tus simulaciones.</small>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <div class="row">

      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="margin: 0 auto;">
        <mat-form-field>
          <mat-label>Filtro por estado</mat-label>
          <mat-select [(value)]="selected" panelClass="my-select" id="selectionstatus" (selectionChange)="statuschange($event)">
              <mat-option >Limpiar</mat-option>
              <mat-divider></mat-divider>
              <mat-option value="1">Solicitada</mat-option>
              <mat-option value="2">En evaluación</mat-option>
              <mat-option value="3">Aprobada</mat-option>
              <mat-option value="4">No aprobada</mat-option>
          </mat-select>
          </mat-form-field>
      </div>

      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="margin: 0 auto;">
          <form  [formGroup]="valFormFilter">


                <mat-form-field appearance="fill">
                  <mat-label>Buscar</mat-label>
                  <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
                  <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                  <mat-hint>Buscar por monto o número de operación</mat-hint>
                </mat-form-field>

          </form>
      </div>

    </div>
    
  </div>

  <!--Loading-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">

    <div class="container-loading">

      
      <div class="img-loading">
        <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
      </div>

      <div style="margin-top: 20px;">
        <mat-label class="label-loading">
          <div>La información está siendo procesada.</div>
          <div>Esto no tomará mucho tiempo.</div>
        </mat-label>
      </div>

    </div>


  </div>

  <!--TABLA-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length >0">

    <mat-card class="card-tabla" style="position: relative;">

      <table mat-table [dataSource]="dataSource" class="table-res">

        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 

            <div [ngClass]="{'box-factura':true, 'border-green': element.status == 3 || element.status == 9}" (click)="$event.stopPropagation()">

                <div *ngIf="element.status == 3 || element.status == 9" class="label-por-ceder">Por ceder</div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                    <div class="container-label-res"><mat-label class="left-label">N° Simulación:</mat-label><mat-label class="right-label">{{element.id}}</mat-label></div>
                    <div class="container-label-res"><mat-label class="left-label">Ejecutivo:</mat-label><mat-label class="right-label">{{element.executive}}</mat-label></div>
                    <div class="container-label-res"><mat-label class="left-label">Creación:</mat-label><mat-label class="right-label">{{element.date_created}}</mat-label></div>
                    <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.total)}}</mat-label></div>
                    <div class="container-label-res"><mat-label class="left-label">Estado:</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status ==  0 || element.status == 1 " class="right-label">Solicitada</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status == 2  || element.status == 5 || element.status == 7 || element.status == 8 || element.status == 10 || element.status == 13" class="right-label">En evaluación</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status == 3 || element.status == 9" class="right-label">Aprobada</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status == 4 || element.status == 6 || element.status == 11" class="right-label">No aprobada</mat-label>

                      <mat-label style="position: relative;" *ngIf="element.status == 14" class="right-label">Cediendo</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status == 15" class="right-label">Cedida</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status == 16" class="right-label">Cedida parcial</mat-label>
                      <mat-label style="position: relative;" *ngIf="element.status == 17" class="right-label">No cedida</mat-label>


                      <span *ngIf="element.status ==  0 || element.status == 1 "><i style="color:var(--simpli-primary);" class="fa-regular fa-square-check icom-sim"></i></span>
                      <span *ngIf="element.status == 3 || element.status == 9"><i style="color:#3BA46F;" class="fa-regular fa-circle-check icom-sim"></i></span>
                      <span *ngIf="element.status == 4 || element.status == 6 || element.status == 11"><i  style="color:#FA454C;" class="fa-regular fa-circle-xmark icom-sim"></i></span>
                      <span *ngIf="element.status == 2  || element.status == 5 || element.status == 7 || element.status == 8 || element.status == 10 || element.status == 13"><i style="color:#F99438;" class="fa-regular fa-clock icom-sim"></i></span>
                    </div>

                    <div style="display: flex;">
                      <mat-label (click)="download_pdf(element.id)" class="pink-label2"><i class="fa-regular fa-download"></i> Descargar</mat-label>
                      <mat-label (click)="ver_detalle(element.id, element.status)" class="pink-label">Ver detalle <i class="fa-solid fa-angle-right"></i></mat-label>
                    </div>
                  </div>
                </div>
            </div>
            
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let doc; columns: displayedColumns;" ></tr>
      </table>
      

    </mat-card>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length == 0">
    <div class="empty-msg">
        
                
      <div style="padding-top: 50px;text-align: center;">
        <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
      </div>

      <div style="padding-top: 50px;text-align: center;">
        <mat-label class="label-bienvenida">
          <div class="label-empty">
            ¡Ups! Al parecer, no tienes simulaciones para visualizar.
          </div>

          <div class="label-empty" style="margin-top: 20px;">
            Realiza una simulación para que puedas comenzar a operar.
          </div>

        </mat-label>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="btn-simpli-container">
            <button class="primary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
          </div>
          
        </div>
      </div>

    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false">
    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
  </div>

</div>
