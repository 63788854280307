


<mat-card class="superchatdejp" style="box-shadow: none;border:none;">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <h1 class="titulo-simpli">Historial Observaciones</h1>
      <small class="subtitulo-simpli">{{this.element.deudor}}</small>
  </div>

  <mat-card-content >
    <form [formGroup]="Observaciones">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">


                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 container-chat ctn-card" style="position: relative;">

                      <div class="velo-inactivo animate__animated animate__fadeIn" style="background: #ffffff21;height: 410px;" *ngIf="cargando_obs">
                        <mat-spinner diameter="40" class="spinner-simpli" style="margin-top: 150px;"></mat-spinner>
                      </div>
                                                                    
                
                        <div #rogerpapi id="rogerpapi" class="container-message add_factoring" style="position: absolute;padding-top: 1%;">
                          <div *ngFor="let o of obs_merge" class="f_alto_comentarios pre-comentario">
                              <div class="row"  style="padding-right: 3%;">
                                  
                                  <div #rogerpapi2 id="rogerpapi2" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 texto_comentarios">
                  
                                      <div class="obsHeader">
                                      <span class="obsName">{{o.escribio}} <strong *ngIf="o.es_cobrador == true" style="color:#1b0943">&nbsp; / Cobranza</strong></span>
                                          <span class="obsDate">{{o.date_created | date: 'yyyy-MM-dd'}}</span> 
                                      </div>
                  
                                  <div class="obsBody">
                                      <ngx-avatar src="../../../../assets/img/equipo.png"></ngx-avatar>
                                      <div class="commentBody">
                                          <p class="comentario">{{stripHtml(o.observaciones)}}</p>
                                      </div>
                                      
                                  </div>
                                  
                                  </div>
                              </div>
                      
                          </div>
                        </div>
                
                        <span class="jota">
                        <mat-form-field tabindex="-2" appearance="fill" style="position: absolute;bottom: 0;background: white;width: 100%;left: 0;">
                            <mat-label>Nueva Observación</mat-label>
                            <input matInput id="messagechat" formControlName="observacion" #messagechat (keyup.enter)="addObservacion($event, Observaciones.value, 4);$event.stopPropagation()">
                            <button  id="obs_cliente_nueva" [disabled]="activador_enviar_obs" mat-icon-button matSuffix type="button" (click)="addObservacion($event, Observaciones.value, 4);$event.stopPropagation()"><mat-icon style="cursor:pointer; color:gray;font-size:20px;">send</mat-icon></button>
                        </mat-form-field>
                    </span>
                
                
                    </div> 
                </div>
            
            </div> 
        </div>
    </form>
  </mat-card-content>

  <mat-card-actions align="start" style="margin-top: 10px;margin-bottom: 10px;">
      <button mat-dialog-close mat-flat-button class="primary-button" type="button" style="margin-top: 10px;">Cerrar</button>
  </mat-card-actions>
</mat-card>