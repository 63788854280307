import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';;
import { PagoSeleccion } from '../../pago-seleccion/pago-seleccion';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;

@Component({
  selector: 'app-dialog-carga-manual-only',
  templateUrl: './dialog-carga-manual-only.component.html',
  styleUrls: ['./dialog-carga-manual-only.component.less']
})
export class DialogCargaManualOnlyComponent implements OnInit {

  company_id:any;

  title: string = "Subir plantilla";
  subtitle: string = "Descarga la plantilla con el formato, agrega información, súbela y listo!";

  disabled: boolean = false;
  process: boolean = false;

  hay_facturas_con_error: boolean = false;
  todas_las_facturas_ok: boolean = false;
  mensaje_error: string = "";

  disabled_button: boolean = true;
  label_boton_financiar: string = "Financiar pago";

  show_descargar_plantilla: boolean = true;

  facturas_financiar: Array<any> = [];

  constructor(public settings: SettingsService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
  }

  async descargar_file() {
    let resultado: any = (await this.settings.get_query(1, 'ComprasVentasManuales/' + this.company_id + '/'));
    let body_data = resultado._body;
    if (resultado) {
      window.open(body_data.nameArchivo, "blank");
    }
  }

  subir() {
    $("#file_but").trigger("click");
  }

  async onFileChange(pFileList: File[]) {

    this.files = Object.keys(pFileList).map((key:any)=> pFileList[key]);

    let formData: FormData = new FormData();
    let file_input = pFileList;

    for (let i = 0; i < file_input.length; i++) {
      let name = file_input[i].name;
      formData.append('cv_' + i, file_input[i], name);
    }

    this.disabled = true;
    this.hay_facturas_con_error = false;
    this.todas_las_facturas_ok = false;
    this.show_descargar_plantilla = false;
    this.facturas_financiar = [];

    try {
      let resultado: any = (await this.settings.get_query(2, 'ComprasVentasManuales/' + this.company_id + '/?accion=0', formData));
      let body_data = resultado._body;
      if (resultado) {
        this.reset_file();
        this.disabled = false;
        if (body_data.arrayFront.length == 0) {
          this.hay_facturas_con_error = true;
          this.mensaje_error = "Estos folios no existen en tu libro de compra, por favor intenta nuevamente.";
          this.disabled_button = true;
        } else {
          // verificar si hay errores en los folios
          let errores = [];
          for (let i in body_data.arrayFront) {
            if (body_data.arrayFront[i].errorCompras == true) {
              errores.push(body_data.arrayFront[i]);
            } else {
              this.facturas_financiar.push(body_data.arrayFront[i]);
            }
          }
          this.hay_facturas_con_error = true ? errores.length > 0 : false;

          if (this.hay_facturas_con_error) {
            // si hay errores
            if (body_data.arrayFront.length == errores.length) {
              // si todos los folio tienen errores
              this.mensaje_error = "Estos folios no existen en tu libro de compra, por favor intenta nuevamente.";
              this.disabled_button = true;
            } else {
              // si al menos hay un folio bueno
              let folios = errores.map(x => { return x.folio; }).join(",");
              this.mensaje_error = `Los folios: [${folios}] no están en tu libro de compras, puedes corregir y subirlo nuevamente. También puedes continuar con el financiamiento y omitiremos esas facturas.`;
              this.disabled_button = false;
            }
          } else {
            // si no hay errores
            this.reset_file();
            this.disabled = false;
            this.todas_las_facturas_ok = true;
            this.disabled_button = false;
          }
        }
      }
    } catch {
      this.reset_file();
      this.disabled = false;
      this.hay_facturas_con_error = true;
      this.mensaje_error = "Estos folios no existen en tu libro de compra, por favor intenta nuevamente.";
      this.disabled_button = true;
    }

  }

  onDragOver(event:any) {
    event.preventDefault();
  }

  onDropSuccess(event:any) {
    event.preventDefault();

    this.uploadFileDrag(event.dataTransfer.files);
  }

  onChange(event:any) {
    this.uploadFileDrag(event.target.files);
  }


  private uploadFileDrag(files:any) {
    if (files.length) {
      this.process = true;
      this.onFileChange(files);
      this.process = false;

    }
  }




  financiar() {

    var deuda = 0;

    console.log(this.facturas_financiar);

    for (let i = 0; i < this.facturas_financiar.length; i++) {
      deuda = deuda + this.facturas_financiar[i].mnt_total;
      let fecha_emision = new Date(this.facturas_financiar[i].fecha_emision);
      if (this.facturas_financiar[i].fecha_vencimiento != null && this.facturas_financiar[i].fecha_vencimiento != undefined) {
        let fecha_vencimiento = new Date(this.facturas_financiar[i].fecha_vencimiento);
        let dd = fecha_vencimiento.getDate();
        let mm = fecha_vencimiento.getMonth() + 1;
        let yy = fecha_vencimiento.getFullYear();
        let new_date = dd + '-' + mm + '-' + yy;
        this.facturas_financiar[i].fecha_vencimiento = new_date;
      }
      let dd = fecha_emision.getDate();
      let mm = fecha_emision.getMonth() + 1;
      let yy = fecha_emision.getFullYear();
      let new_date = dd + '-' + mm + '-' + yy;
      this.facturas_financiar[i].fecha_emision = new_date;
    }


    const dialogRef = this.dialog.open(PagoSeleccion, {
      maxHeight: '80vh',
      width: '1300px',
      disableClose: true,
    });

    dialogRef.componentInstance.facturas = this.facturas_financiar;
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.componentInstance.deuda = deuda;
    dialogRef.componentInstance.manual_ventas = true;

    dialogRef.afterClosed().subscribe((result:any) => {

    });

  }

  reset_file() {
    $("#file_but").val("");
  }

  public files: any[] = [];

}

