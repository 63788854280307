import { Component, Input, OnInit } from '@angular/core';
import { IMessage, WebSocketService } from 'src/app/services/websocket.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DialogCesionParcial } from '../dialog-cesion-parcial';
import { QueEsReemitir } from '../que_es_reemitir/que_es_reemitir';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;


@Component({
  selector: 'app-detalle-operacion-parcial',
  templateUrl: './detalle-operacion-parcial.html',
  styleUrls: ['./detalle-operacion-parcial.less']
})
export class DetailOperationParcialComponent implements OnInit {

  id_op: any;
  id_sim: any;
  monto_girar:any=0;  
  valFormFacturas!:FormGroup;

  continue_mode:boolean=false;
  
  constructor( private router:Router, public settings: SettingsService,
     public dialog: MatDialog, private activated_router: ActivatedRoute, private ws: WebSocketService, private fb:FormBuilder, private snackBar:MatSnackBar) {

    this.activated_router.params.subscribe(params => {
      this.id_op = params['id']
      this.id_sim = params['id_sim']
      if(params['continue'] != undefined && params['continue'] != null && params['continue'] != ''){
        this.continue_mode = true;
      }
    })

    

    this.valFormFacturas = this.fb.group({
      'filter': new FormControl(''),
    });

  }

  @Input() id_company:any=localStorage.getItem('company_id');




  myObserver: any;
  suscriptionEvent:any;
  ngOnDestroy() {
    this.myObserver.unsubscribe();
    this.suscriptionEvent.unsubscribe()
  }

  load_all(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.get_facturas()
  }


  is_responsive:boolean=false;
  ngOnInit() {


    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });


    this.suscriptionEvent = this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'cambios_curse_operacion':
          this.load_all()
          break;
        case 'descarte_curse_operacion':
          this.router.navigate(['home/detalle-operacion/',this.id_sim])
          break;
        default:
          break;
      }
    });

    this.load_all();

  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  return(){
    this.router.navigate(['home/operaciones'])
  }

  loading:boolean=false;
  ELEMENT_DATA:any[]=[];
  displayedColumns: string[] = ['folio','deudor','rut','monto','estado','motivo','detalle'];
  displayedColumnsRes: string[] = ['data'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  async get_facturas(){
    try {
      this.loading = true;

      const bd: any = await this.settings.get_query(1, '/cliente/cesion-parcial/'+this.id_sim+'/?filtro='+this.filter,);
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:

           console.log(data)

          this.ELEMENT_DATA = data.success.info;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource._updateChangeSubscription();

          this.pageLength = data.success.total;

          this.loading = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {
      switch (bd.status) {

        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }

    }
  }

  //DRAG EXTERNO
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  slider = document.querySelector<HTMLElement>('.table-responsive-desktop2');
  startDragging(e:any, flag:any, el:any) {
      this.mouseDown = true;
      this.startX = e.pageX - el.offsetLeft;
      this.scrollLeft = el.scrollLeft;
  }

  stopDragging(e:any, flag:any) {
      this.mouseDown = false;
  }

  moveEvent(e:any, el:any) {
      e.preventDefault();
      if (!this.mouseDown) {
      return;
      }

      const x = e.pageX - el.offsetLeft;
      const scroll = x - this.startX;
      el.scrollLeft = this.scrollLeft - scroll;
  }

  pageIndex = 0;
  pageLength = 0;
  pageSize = 7;
  page = 1;
  pageEvent(pageNumber: number): void {
      this.page = pageNumber;
      this.get_facturas();
  }

  filter_list:any[]=[];
  filter:any="";
  add_filter(filter:any){

    this.filter = filter.toLowerCase();




      if(this.filter != ''){
        this.page = 1;
        this.get_facturas();
      }else{
        this.filter = '';
        this.page = 1;
        this.get_facturas();
      }



  }

  get_filter(){
    let filter:any="";
    for( let i in this.filter_list){
      filter = filter +','+ this.filter_list[i];
    }
    filter = filter.substring(1);
    this.filter = filter;

    this.get_facturas();
  }

  remove_filter(filter:any){
    const obje = this.filter_list.find((x: any) => x == filter);

    if (obje) {
      this.filter_list.splice(this.filter_list.indexOf(obje), 1);
    }


    this.get_filter();

  }

  show_reemitir:boolean=false;
  reemitir(desactivar_eliminar?:boolean){

    if(desactivar_eliminar == true){
      console.log("ok")
    }else{
      this.delete_facturas();
    }


    if(this.is_responsive == true){
      const dialogRef = this.dialog.open(DialogCesionParcial, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });

      dialogRef.componentInstance.id_op = this.id_op;
      dialogRef.componentInstance.id_sim = this.id_sim
      dialogRef.componentInstance.title ="Reemite las facturas eliminadas"
      dialogRef.componentInstance.subtitle ="Corrige, reemite y vuelve a agregar las <br> facturas eliminadas o continúa con la cesión."

  
      dialogRef.componentInstance.show_extra = true;

      dialogRef.afterClosed().subscribe((result:any) => {
        if(result == true){
  
        }
        if(result == "reemitir"){
          this.show_reemitir = true;
        }
      });


    }else{
      const dialogRef = this.dialog.open(DialogCesionParcial, {
        width: '650px',
        disableClose: true,
        autoFocus: false,
      });

      dialogRef.componentInstance.id_op = this.id_op;
      dialogRef.componentInstance.id_sim = this.id_sim
      dialogRef.componentInstance.title ="Reemite las facturas eliminadas"
      dialogRef.componentInstance.subtitle ="Corrige, reemite y vuelve a agregar las <br> facturas eliminadas o continúa con la cesión."


      dialogRef.componentInstance.show_extra = true;

      dialogRef.afterClosed().subscribe((result:any) => {
        if(result == true){
  
        }
        if(result == "reemitir"){
          this.show_reemitir = true;
        }
      });

    }
    
  }

  que_es_reemitir(params?:any) {

    if(this.is_responsive == true){
      const dialogRef = this.dialog.open(QueEsReemitir, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });

      if(params == 1){
        dialogRef.componentInstance.show_info = true;
        dialogRef.componentInstance.step = 3;
      }

      dialogRef.afterClosed().subscribe((result:any) => {

      });
    }else{
      const dialogRef = this.dialog.open(QueEsReemitir, {
        height: "calc(100% - 30px)",
        width: "calc(100% - 30px)",
        maxWidth: "100%",
        maxHeight: "100%",
        autoFocus:false,
      });

      if(params == 1){
        dialogRef.componentInstance.show_info = true;
        dialogRef.componentInstance.step = 3;
      }

      dialogRef.afterClosed().subscribe((result:any) => {

      });
    }


  }

  continuar_cesion(){
    this.router.navigate(['home/detalle-operacion/',this.id_sim])
  }

  async delete_facturas(){
    try {
  

      const bd: any = await this.settings.get_query(4, '/cliente/cesion-parcial/'+this.id_sim+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          

            console.log(body_data)

    



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }

  }



}
