<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content style="max-height: 100% !important;">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <h1 class="title-label" *ngIf="step==1">¿Qué es un AEC?</h1>
    <h1 class="title-label" *ngIf="step==2">Carga los AEC</h1>
    <h1 class="title-label" style="margin-top: 0px;" *ngIf="step==4">Sincronización de credenciales</h1>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="step==1">

    <div class="row responsive-off">

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="p-label">
                El <strong>"Archivo Electrónico de Cesión"</strong> es un archivo digital firmando por el cedente,<br>
                que permite transferir al cesionario (factoring), el crédito contendio en una factura<br>
                electrónica.

                <br><br>

                El AEC tiene el formato definido por el SII (.XML) y en la mayoría de los casos, es<br>
                generado en el portal <strong>"sii.cl"</strong>. Para saber más, descubre <span (click)="set_step()" class="link-label2">cómo generar un AEC en el</span><br>
                <span (click)="set_step()" class="link-label2">SII.</span>

                <br><br>

                Recuerda que también puedes <strong>generar los AEC de forma automática</strong> y sin la<br>
                necesidad de salir de esta plataforma. Solo debes seleccionar la opción <strong>"Generar</strong><br>
                <strong>AEC".</strong>
              </div>


              <div style="margin-top: 60px">
                <button class="primary-button" mat-flat-button (click)="g_aec()">
                  Generar AEC
                </button>
              </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../../../assets/simpli/recurso_quene.png" style="height: 230px;">
            </div>

          </div>

    </div>

    <div class="row responsive-on">

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="img-recurso">
          <img src="../../../../../assets/simpli/recurso_quene.png" style="height: 230px;">
        </div>

      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="p-label">
            El <strong>"Archivo Electrónico de Cesión"</strong> es un archivo digital firmando por el cedente,<br>
            que permite transferir al cesionario (factoring), el crédito contendio en una factura<br>
            electrónica.

            <br><br>

            El AEC tiene el formato definido por el SII (.XML) y en la mayoría de los casos, es<br>
            generado en el portal <strong>"sii.cl"</strong>. Para saber más, descubre <span (click)="set_step()" class="link-label2">cómo generar un AEC en el</span><br>
            <span (click)="set_step()" class="link-label2">SII.</span>

            <br><br>

            Recuerda que también puedes <strong>generar los AEC de forma automática</strong> y sin la<br>
            necesidad de salir de esta plataforma. Solo debes seleccionar la opción <strong>"Generar</strong><br>
            <strong>AEC".</strong>
          </div>


          <div class="en_responsive" style="margin-top: 60px;margin-bottom: 60px;">
            <button class="primary-button" mat-flat-button (click)="g_aec()">
              Generar AEC
            </button>
          </div>
      </div>

</div>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="step==2 || step == 3">

    <div class="row">

          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <div class="p-label">
                Si necesitas generar los AEC en el SII, mira el video y descubre cómo hacerlo
              </div>

              <div style="margin-top: 30px;">
                  <video controls class="video-simpli">
                    <source src="https://simpliassets12daacw.s3.us-east-2.amazonaws.com/Tutorial+AEC+Simpli.mp4" type="video/mp4">
                    Tu navegador no soporta el tag de video.
                  </video>
              </div>


              <div class="en_responsive" style="margin-top: 60px;margin-bottom: 60px;">
                <button class="primary-button" mat-flat-button (click)="g_aec()">
                  Generar AEC
                </button>

                <button class="secondary-button" mat-flat-button *ngIf="step != 3" (click)="step = 1">
                  Volver
                </button>

                <button class="secondary-button" mat-flat-button *ngIf="step == 3" mat-dialog-close>
                  Volver
                </button>
              </div>
          </div>

    </div>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="step==4">

    <div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="p-label">
                Esta opción permite <strong>sincronizar los datos</strong> de tu empresa en esta plataforma, con<br>
                el fin de generar procesos más <strong>simples y rápidos.</strong><br>

                <br><br>

                A continuación, revisa las principales <strong>ventajas de la sincronización:</strong><br>

              </div>

              <div class="row" style="margin-top: 40px;">

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                  <div class="img-ventaja">
                    <img src="../../../../../assets/simpli/cesion/ventaja1.png">
                  </div>
                  <div class="text-img-label">
                    Cede sin salir de la<br> plataforma.
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                  <div class="img-ventaja">
                    <img src="../../../../../assets/simpli/cesion/ventaja2.png">
                  </div>
                  <div class="text-img-label">
                    Evita errores de<br> autenticación.
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                  <div class="img-ventaja">
                    <img src="../../../../../assets/simpli/cesion/ventaja3.png">
                  </div>
                  <div class="text-img-label">
                    Opera desde cualquier<br> dispositivo.
                  </div>
                </div>

              </div>

              <div class="row" style="margin-top: 20px;">

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                  <div class="img-ventaja">
                    <img src="../../../../../assets/simpli/cesion/ventaja4.svg">
                  </div>
                  <div class="text-img-label">
                    Cede más de una<br> factura con un clic.
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                  <div class="img-ventaja">
                    <img src="../../../../../assets/simpli/cesion/ventaja4.png">
                  </div>
                  <div class="text-img-label">
                    Agiliza tus<br> operaciones.
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                  <div class="img-ventaja">
                    <img src="../../../../../assets/simpli/cesion/ventaja5.png">
                  </div>
                  <div class="text-img-label">
                    Accede a tu<br> Dashboard contable.
                  </div>
                </div>

              </div>


              <div style="margin-top: 60px;" class="en_responsive">
                <button class="primary-button" mat-flat-button mat-dialog-close>
                  Volver
                </button>
              </div>
          </div>



    </div>

  </div>



</mat-dialog-content>