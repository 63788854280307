<div class="row row_main">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli">Pago Proveedores</h1>
        <small class="subtitulo-simpli">Selecciona las facturas que deseas pagar</small>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top position-relative">


        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="disabled">

            <div class="container-loading">


                <div class="img-loading">
                <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                </div>

                <div style="margin-top: 20px;">
                <mat-label class="label-loading">
                    <div>La información está siendo procesada.</div>
                    <div>Esto no tomará mucho tiempo.</div>
                </mat-label>
                </div>

            </div>


        </div>

        <mat-card *ngIf="!disabled" class="shadow-box card-tabs" style="background: white;">
            <mat-card-content>
                <div class="row w-100">
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-top">
                        <form [formGroup]="valForm">
                            <div class="row">
                                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                    <mat-form-field>
                                        <mat-label>Filtrar</mat-label>
                                        <input matInput formControlName="filter">
                                        <mat-icon matTooltip="Buscar" matSuffix color="primary"
                                            (click)="filtrar()">search</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                    <mat-form-field>
                                        <mat-label>Desde</mat-label>
                                        <input matInput readonly (click)="picker3.open()"
                                            [matDatepicker]="picker3" (dateChange)="filter_date($event, 1)"
                                            formControlName="desde" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker3">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                    <mat-form-field>
                                        <mat-label>Hasta</mat-label>
                                        <input matInput readonly (click)="picker4.open()"
                                            [matDatepicker]="picker4" (dateChange)="filter_date($event, 2)"
                                            formControlName="hasta" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker4">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker4></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-top text-right">
                        <button mat-stroked-button class="stroked-btn" (click)="reset_filters()" type="button">Limpiar filtros</button>
                        <button mat-stroked-button class="stroked-btn" id="btn_llamativo" (click)="sincronizar()" type="button" color="primary">Sincronizar SII</button>
                        <button mat-stroked-button style="margin-left: 10px;" type="button" (click)="carga_manual()" matTooltip="Carga manual" color="primary"> <mat-icon>attach_file</mat-icon></button>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="infotable">
                        <div class="table-responsive-simpli mt-20">
                            <table mat-table [dataSource]="dataSourceFacturas" matSort
                                (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc">
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef
                                        matTooltip="Se marcarán todas facturas de la página en visualización, de 20 en 20.">
                                        <input type="checkbox"
                                            class="cursor-pointer purple-checkbox"
                                            (change)="$event ? masterToggle() : null; $event.stopPropagation()"
                                            [checked]="selection.hasValue() && isAllSelected()">
                                    </th>
                                    <td mat-cell *matCellDef="let row; let index=index;"
                                        class="text-center">
                                        <input type="checkbox"
                                                class="cursor-pointer"
                                                (click)="activa($event, row); $event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)">
                                    </td>

                                </ng-container>
                                <ng-container matColumnDef="folio">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header">
                                        Folio </th>
                                    <td mat-cell *matCellDef="let element;">
                                        <input class="no-input" value="{{element.folio}}" readonly>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ultimoacreedor">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header">
                                        Acreedor Legal </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input *ngIf="element.historial_acreedores.length > 0" class="no-input table-acreedores-text"
                                            [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover"
                                            #popoverTrigger="mdePopoverTrigger"
                                            value="{{element.ultimoacreedor}}" readonly>
                                        <input *ngIf="element.historial_acreedores.length <= 0" class="no-input" value="{{element.ultimoacreedor}}" readonly>
                                        <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
                                            [mdePopoverCloseOnClick]="false">
                                            <mat-card class="shadow-box card-acreedor">
                                                <mat-card-content class="text-center">
                                                    <h1 class="h1-acreedor">Historial de Acreedores</h1>
                                                    <table class="w-100" cellspacing="0">
                                                        <tr class="h-50">
                                                            <td class="header-acreedor">Acreedor</td>
                                                            <td class="header-acreedor">Fecha</td>
                                                        </tr>
                                                        <tr class="h-50"
                                                            *ngFor="let i of element.historial_acreedores">
                                                            <td class="table-acreedor">{{i.acreedor}}</td>
                                                            <td class="table-acreedor">{{i.fecha}}</td>
                                                        </tr>
                                                    </table>
                                                </mat-card-content>
                                            </mat-card>
                                        </mde-popover>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="receptoracreedor__business_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header">
                                        Emisor Documento </th>
                                    <td mat-cell *matCellDef="let element"
                                        matTooltip="{{element.receptoracreedor_name + ' - ' + element.ultimorutacreedor}}">
                                        <input class="no-input" value="{{element.receptoracreedor_name}}" readonly>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="mnt_total">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header">
                                        Monto </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}"
                                            readonly>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="fecha_emision">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header">
                                        Fecha Emisión </th>
                                    <td mat-cell *matCellDef="let element;">
                                        <input class="no-input" value="{{element.fecha_emision}}" readonly>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturas; sticky: true"> </tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturas; let index=index; "></tr>
                            </table>
                            <div class="row w-100">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-msg">
                                    <small *ngIf="show_msg_acreedor_legal"
                                        class="text-acreedor-legal">*Pasa el cursor
                                        sobre el Acreedor Legal para ver el Historial de Acreedores
                                        recientes.</small>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <mat-paginator [length]="length" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" (page)="pageCustom($event)" #paginator>
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="!infotable">

                        <sin-registros [spin]="false"></sin-registros>

                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
    <div id="footer-options-bar" class="row animate__animated animate__fadeIn" *ngIf="!disabled_enviar">
        <div class="col-footer-options col-lg-9 col-md-9 col-sm-9 col-xs-12 align-items-center">
            <div class="footer-options-text" style="color:#1b0943 !important;">
                <p>Proveedores seleccionados: {{ total_proveedores }}</p>
                <p>Facturas seleccionadas: {{ arr_documentos_to_simulate.length }}</p>
                <h3>Total : $ {{formatMoney(total_tabla)}}</h3>
            </div>
        </div>
        <div class="col-footer-options col-lg-3 col-md-3 col-sm-3 col-xs-12 align-items-center">
            <button mat-stroked-button [disabled]="disabled_enviar" style="color:#1b0943;border: solid 2px #1b0943 !important;" class="footer-options-btn" (click)="seleccionar()" type="button">Financiar Pago</button>
        </div>
    </div>
</div>
