<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close *ngIf="step != 4">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;" *ngIf="step == 2">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color6)" class="fa-regular fa-file-shield"></i>
        </div>

        <div class="label-dialog">Para ceder por Simpli, adjunta tu</div>
        <div class="label-dialog">Certificado Digital Electrónico.</div>

        <div class="link-label"><a [routerLink]="['/home/info-certificado']" target="_blank">Descubre para qué sirve este Certificado</a></div>


        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="primary-button" mat-flat-button (click)="step = 3">
                Adjuntar Certificado
              </button>

              <button class="secondary-button" mat-flat-button (click)="step = 1">
                Volver
              </button>
            </div>

          </div>
        </div>

    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn animate__faster" *ngIf="step == 1">

      <div class="empty-msg">


        <div style="text-align: center;margin-top: 15px;margin-bottom: 15px;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;font-family: 'Sofia Sans Extra Condensed' !important;font-size: 25px;color: var(--simpli-secondary);">
              Elige por dónde vas a ceder
            </div>
          </mat-label>
        </div>

        <div class="row" style="margin-top: 60px;margin-bottom: 60px;">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="box-options">


                <div *ngIf="es_mipyme == true" class="box-option2 centerabsolute" style="width: 120px;" (click)="cederFacturaBpcSimpli()" id="button-ceder-simpli">
                <div  *ngIf="!ceder_loading; else loadingCeder">
                  <img class="img-box-option" style="margin-bottom: 20px;" src="../../../../../assets/simpli/cesion/cede_con_simpli.png" height="60px">
                  <div class="label-parrafo">Ceder por</div>
                  <div class="label-parrafo">Simpli</div>
                </div>
                <ng-template #loadingCeder>
                      <mat-spinner diameter="40" class="spinner-arrayan-general "></mat-spinner>
                </ng-template>
                </div>

                <div class="box-option2" style="width: 120px;" (click)="ceder_por_otra()">
                  <img class="img-box-option" style="margin-bottom: 20px;" src="../../../../../assets/simpli/cesion/ventaja3.png" height="60px">
                  <div class="label-parrafo">Ceder por otra</div>
                  <div class="label-parrafo">plataforma</div>
                </div>

                <div class="box-option2" style="width: 120px;" (click)="generar_aec();" *ngIf="onCederByAEC">
                  <img class="img-box-option" style="margin-bottom: 20px;" src="../../../../../assets/simpli/cesion/ventaja2.png" height="60px">
                  <div class="label-parrafo">Ceder con</div>
                  <div class="label-parrafo">AEC</div>
                </div>

            </div>

          </div>
        </div>

      </div>

    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn animate__faster" *ngIf="step == 3">

      <div class="empty-msg">


        <div style="text-align: center;margin-top: 15px;margin-bottom: 15px;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;">
              Genera los AEC de forma automática
              <br>
              ¡Sin salir de la plataforma!
            </div>
          </mat-label>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">

            <img style="margin-bottom: 20px;" src="../../../../../assets/simpli/cesion/unnamed.gif" height="165px">

            <div class="label-dialog">Si ya tienes los archivos, cárgalos ahora.</div>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button [disabled]="loading_primario" class="primary-button" mat-flat-button (click)="generar_aec_final()">
                <span *ngIf="loading_primario == false">Generar AEC</span>
                <span *ngIf="loading_primario == true"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
              </button>

              <button class="secondary-button" mat-flat-button (click)="cargar_aec()">
                Cargar AEC
              </button>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
            <div class="link-label2"><a style="color:#6B2AFB;" (click)="que_es_un_adc()">Conoce más sobre el AEC</a></div>
          </div>
        </div>

      </div>

    </div>

    <!--BARRA LOADING-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 4">

      <div class="container-loading">


        <div class="img-loading">
          <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
        </div>

        <div style="margin-top: 20px;">
          <mat-label class="label-loading">
            <div>Estamos procesando la información</div>
            <div style="display: none;">Esto no tomará mucho tiempo.</div>
          </mat-label>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;position: relative;margin-top: 30px;">
            <mat-progress-bar class="cliente-progress-bar" mode="determinate" [value]="valor_progress_bar"></mat-progress-bar>
            <div class="progress-percent">{{parsearPorcentaje(valor_progress_bar)}}</div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
            <div class="time-remains">Esto puede tardar unos minutos.</div>
          </div>

        </div>

      </div>


    </div>

    <!--CARGA DE ARCHIVOS MANUALES-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 5">

      <div  class="empty-msg">

        <div style="text-align: center;margin-top: 15px;margin-bottom: 15px;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;">
              Agrega o arrastra los AEC a esta ventana
            </div>
          </mat-label>
        </div>


        <div class="row drop-zone" (click)="triger_click()" (dragover)="allowDrop($event)" (drop)="handleDrop($event)">

          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

            <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px;">
              <i style="font-size: 30px;color:#6B2AFB;" class="fa-regular fa-circle-plus icon-file2"></i>
            </div>


          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <div class="label-adjunt">Agregar archivos</div>
            <div class="sub-label-adjunt">Solo se admite el formato “.xml” y un peso menor a “1 MB”.
            </div>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>

        </div>

        <br>

        <div class="row" *ngFor="let file of files; let index = index">
          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12" style="margin: 0 auto;">
            <div class="file-cont">
              <span class="file-delete" (click)="deleteFile(file)"><i class="fa-regular fa-xmark"></i></span>
              <input style="width: 90%;" class="no-input label-filee" readonly value="{{file.name}}">
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="primary-button" mat-flat-button *ngIf="files.length == 0"  style="background: #adadad !important;color:white;border: solid 1px gray;">
                Cargar AEC
              </button>

              <button [disabled]="loading_upload" class="primary-button" mat-flat-button  *ngIf="files.length > 0" (click)="cargar_files_aec()">
                <span *ngIf="!loading_upload">Cargar AEC</span>
                <span *ngIf="loading_upload"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
              </button>

              <input type="file" accept=".xml" (change)="handleFiles($event.target.files)" multiple style="display: none;" id="fileInput" #fileInput />

            </div>

          </div>
        </div>

      </div>



    </div>



</mat-dialog-content>
