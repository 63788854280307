<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close *ngIf="show_succes == false">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <span *ngIf="!show_succes"><i  style="font-size: 65px; color: var(--simpli-primary);" class="fa-light fa-triangle-exclamation"></i></span>
            <span *ngIf="show_succes"><i style="font-size: 65px; color: #3BA46F;" class="fa-light fa-circle-check"></i></span>
        </div>


        <div *ngIf="deletefacturas == true" class="delete-title">{{titledelete}}</div>

        <div class="label-dialog">{{title}}</div>
        <div class="label-dialog">{{subtitle}}</div>


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button *ngIf="deletefacturas == false" class="primary-button" (click)="close()" mat-flat-button>
                  Descartar
                </button>

                <button *ngIf="deletefacturas == false" class="secondary-button"  mat-flat-button mat-dialog-close>
                  Cancelar
                </button>

                <button *ngIf="deletefacturas == true && show_succes == false" class="primary-button" mat-dialog-close mat-flat-button>
                  Cancelar
                </button>

                <button [disabled]="loading_delete" *ngIf="deletefacturas == true && show_succes == false" class="secondary-button"  mat-flat-button (click)="delete_factura()">
                  <span *ngIf="loading_delete == false">Eliminar factura</span>
                  <span *ngIf="loading_delete == true"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
                </button>

                <button *ngIf="deletefacturas == true && show_succes == true" class="primary-button" mat-flat-button (click)="volver_simular()">
                  Volver a simular
                </button>

                <button *ngIf="deletefacturas == true && show_succes == true " class="secondary-button"  mat-flat-button mat-dialog-close routerLink="/home/">
                  Volver al inicio
                </button>
              </div>
              
            </div>
          </div>

    </div>


    
</mat-dialog-content>