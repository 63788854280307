
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas.component.html',
  styleUrls: ['./lista-empresas.component.less']
})
export class ListaEmpresasComponent implements OnInit {

  empresas:any[]=[]
  filter:any=""
  display_spinner:boolean=false

  length = 0;
  pageSize = 20;
  page:number=1
  pageSizeOptions: number[] = [20];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public settings: SettingsService,private activated_router: ActivatedRoute) { }

  ngOnInit() {
    this.cargar_tabla()
  }

  cargar_tabla=async()=>{
    this.display_spinner=true
    let resultado:any = (await this.settings.get_query(1, 'getempresasalianzado/?filtro='+this.filter+'&pageNumber='+this.page))      
    let body_data=resultado._body;
    if(!body_data["error"]){
      this.length=body_data["total"]
      this.empresas=body_data["empresas"]
      this.display_spinner=false
    }

  }

  pageCustom=($e: any)=>{
    this.page=$e.pageIndex + 1
    this.cargar_tabla()
  }

  filtrar = ($e: any) =>{
    if($e.target.value == ""){
      this.filter = ""
      this.page = 1
      this.length = 0;
      this.pageSize = 20;
      this.cargar_tabla()
    }else{
      this.page = 1
      this.length = 0;
      this.pageSize = 20;
      this.filter = $e.target.value;
      this.cargar_tabla()
    }
  }

  formatMoney = (amount: any, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

}
