
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;

declare var require: any;
import Boost from 'highcharts/modules/boost';
import NoData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';

// Inicializa los módulos
Boost(Highcharts);
NoData(Highcharts);
More(Highcharts);


@Component({
  selector: 'grafico-deudor-compras',
  templateUrl: './participacion-compras.component.html',
  styleUrls: ['./participacion-compras.component.less']
})

export class GraficoDeudorxComprasComponent {

  @Input() id:any;
  @Input() participacion_deudores_obj: any;
  info:Boolean=false
  cargando:Boolean=true
  show_graph = false
  @Output() participacion_deudores_ventas = new EventEmitter<string>();
  actual:any
  menos:any
  classificationData:any[] = []
  elementData:any[] = [];
  deudores:any = {
    "deductions": [],
    "currency": "Monto"
  };

  options: any = { 
    colors: ['#a060f5', '#f1a23a', '#83A0B2', '#342664', '#d53073', '#69ABF9', '#FC9AD4', '#FFCC94', '#8F8FF9', '#3484f6', '#D5BBFF'],
    chart: { type: 'pie'},    
    title: { text: ''},
    tooltip:{
      pointFormat: ''
    },
    plotOptions: { 
      pie: { 
        center: ['50%', '50%'],
        startAngle: -60
     }
    },
    series: [{
      name: '',
      data: this.classificationData,
      series: '60%',
      dataLabels: {
        color: 'red',
        style: { },
        distance: -210
      } 
    }, 
    
    {
      name: this.deudores.currency,
      data: [],
      size: '80%',
      innerSize: '93%'
    }]
  }  

  constructor(
    public snackBar: MatSnackBar, fb: FormBuilder, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService) { }

  ngOnChanges(changes: SimpleChanges) {    
    $("#container-participacion-deudores-compras").hide()
    this.informacion()
    // console.log("pase por participacion deudores")
    setTimeout(()=>{  
      this.participacion_deudores_ventas.emit('10');
    }, 6000);
    
  }

  async informacion() {
    try {

      this.cargando=true
      let mes_actual
      let mes_menos
      if (this.participacion_deudores_obj === undefined ) {
      //Mes actual
      let mensual = new Date();
      let mes_actual_ultimo = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);
      let mes1 = new Date(mes_actual_ultimo)
      let y1 = mes1.getFullYear();
      let m1:any = mes1.getMonth()+1;
      let d1:any = mes1.getDate();
      if(m1<10){m1="0"+m1}
      if(d1<10){d1="0"+d1}
      mes_actual = y1+"-"+m1+"-"+d1
  
      //Mes -6
      let mesmenos=new Date(mes_actual_ultimo.getTime() - (3650*60*60*1000)*1);
      let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
      let mes6 = new Date(ultimomenos)
      let y2 = mes6.getFullYear();
      let m2:any = mes6.getMonth()+1;
      let d2:any = mes6.getDate();
      if(m2<10){m2="0"+m2}
      if(d2<10){d2="0"+d2}
      mes_menos = y2+"-"+m2+"-"+d2
    } else {
      mes_actual = this.participacion_deudores_obj.end
      mes_menos = this.participacion_deudores_obj.start
    }
      this.actual = mes_actual
      this.menos = mes_menos
      this.show_graph = false

      
      const bd: any = await this.settings.get_query(1, 'cesion-venta-nc/'+this.id+'/'+"?inicio="+mes_menos+"&fin="+mes_actual);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

              this.cargando=false
              if(body_data.resumen.length>0){
                this.info=true
                $("#container-participacion-deudores-compras").show()
              }else{
                this.info=false
                $("#container-participacion-deudores-compras").hide()
              }
              this.deudores.deductions=[]
        
              let deductions_total=0
              let totalFacturas=0
              for(let i=0; i< body_data.resumen.length; i++){
                if(body_data.resumen[i].total == false){          
                  this.deudores.deductions.push({"ele": "<span style='font-weight: 400;font-size: 14px;color: #858489;font-family: DM Sans;'>"+body_data.resumen[i].business_name.substring(0, 20)+"</span> <br> <span style='font-family: DM Sans;font-size: 20px;color: #3a1d6a;'>"+body_data.resumen[i].porcentaje+" %</span>", "val": body_data.resumen[i].porcentaje})
                }
                else{
                  deductions_total = body_data.resumen[i].monto
                  totalFacturas = body_data.resumen[i].conteo
                }
              }
        
              let array=[]
        
              for (let i = 0; i < this.deudores.deductions.length; i += 1) {
                array.push({ "name": this.deudores.deductions[i].ele, "y": this.deudores.deductions[i].val});
              };
          
              this.classificationData[0] = {
                name: "<span style='font-family: DM Sans;font-size: 18px;color: #858489;font-weight: 300;'>Nº facturas: </span><span style='font-family: DM Sans;font-size: 17px;color: #858489;font-weight: 600;'>"+totalFacturas+"</span> <br> <span style='font-family: DM Sans;font-size: 20px;color: #3a1d6a;font-weight: 500;'>$ "+this.formatMoney(deductions_total)+"</span>",
                y: deductions_total,
                color: 'white',
                nrElements: this.deudores.deductions.length
              };

              this.elementData=array
              this.options.series[1].data=this.elementData
        
              Highcharts.chart('container-participacion-deudores-compras', this.options);
              this.show_graph = true


          break;
        default:

          break;
      }
    } catch (bd: any) {
      $("#container-participacion-deudores-compras").hide()
      this.cargando=false
      this.info=false
    }
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  }   

  ngOnInit(): void {
  }

  
}
