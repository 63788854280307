<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <h1 class="titulo-simpli">Abonos realizados</h1>



  </div>
</div>

<mat-dialog-content>

  <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

            <mat-form-field appearance="fill">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilter($event)">
              <mat-icon matSuffix class="icon-suffix">search</mat-icon>
              <mat-hint>Buscar por folio o monto</mat-hint>
            </mat-form-field>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="table-responsive-simpli add_factoring">

              <table mat-table [dataSource]="dataSource">
      
      
                <ng-container matColumnDef="forma_pago">
                  <th mat-header-cell *matHeaderCellDef> Forma de pago </th>
                  <td mat-cell *matCellDef="let element"> 
                    <input class="no-input" readonly style="text-transform: capitalize;" value="{{element.forma_pago}}">
                  </td>
                </ng-container>
              
      
                <ng-container matColumnDef="fecha_pago">
                  <th mat-header-cell *matHeaderCellDef> Fecha de pago </th>
                  <td mat-cell *matCellDef="let element">
                    <input class="no-input" readonly value="{{element.fecha_pago}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef> Monto abono</th>
                  <td mat-cell *matCellDef="let element">
                    <input class="no-input" readonly value="${{formatMoney(element.monto_abono)}}">
                  </td>
                </ng-container>
      
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>


              <div *ngIf="facturas.length == 0" style="text-align: center;margin-top: 40px;margin-bottom: 40px;">
                <i style="color: gray; font-size: 25px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
              </div>
              
      
          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
              <mat-paginator [pageSizeOptions]="[7, 14, 28, 50]"></mat-paginator>
            </div>
          </div>

      </div>
  </div>

</mat-dialog-content>