import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SettingsService } from "src/app/settings/settings.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
declare var $ :any;

@Component({
  selector: "app-facturas-pagadas",
  templateUrl: "./facturas-pagadas.component.html",
  styleUrls: ["./facturas-pagadas.component.less"],
})
export class FacturasPagadasComponent implements OnInit {
  ruta: String = SettingsService.API_ENDPOINT + "media/siicertificados/";
  id_company: any;
  fecha_inicio: any;
  fecha_fin: any;
  rut: string = "";
  loaderButton!: boolean;
  cliente: string = "";
  fecha: string = "";
  mes_actual = new Date();
  error: any
  noData = false;
  showSpinner = true;

  inicio: any;
  fin: any;
  informacionFA: any[] = [];
  informacionFANum: number = 0;
  doctos: any[] = [];
  deudor: any[] = [];
  persona: string = "";
  pageSizeOptions: number[] = [20];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageEvent!: PageEvent;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput
      .split(",")
      .map((str) => +str);
  }
  activarInfo: Boolean = false;
  activar_filtro = false;
  page: number = 1;
  fitro_doctos: any[] = [];
  fitro_deudor: any[] = [];
  valForm: FormGroup;

  constructor(
    private router: Router,
    public settings: SettingsService,
    fb: FormBuilder
  ) {
    this.valForm = fb.group({
      inicio: "",
      final: "",
    });
  }

  myObserver: any;

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('Company ID changed:', event.detail);
    this.id_company = localStorage.getItem('company_id');
    this.ngOnInit(); 
  }


  ngOnInit() {
    // this.initDate()

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });
    this.load_all();

    let inicio_query = new Date(
      this.mes_actual.getFullYear(),
      this.mes_actual.getMonth(),
      1
    );
    this.inicio = inicio_query;
    this.valForm.controls["inicio"].setValue(this.inicio);
    let y = inicio_query.getFullYear();
    let m = inicio_query.getMonth() + 1;
    let d = inicio_query.getDate();
    let fecha_inicio = d + "/" + m + "/" + y;

    let fin_query = new Date(
      this.mes_actual.getFullYear(),
      this.mes_actual.getMonth() + 1,
      0
    );
    this.fin = fin_query;
    this.valForm.controls["final"].setValue(this.fin);
    let y_f = fin_query.getFullYear();
    let m_f = fin_query.getMonth() + 1;
    let d_f = fin_query.getDate();
    let fecha_fin = d_f + "/" + m_f + "/" + y_f;

    this.fecha_inicio = fecha_inicio;
    this.fecha_fin = fecha_fin;
  }

  load_all() {
    // this.initDate()
    this.id_company = localStorage.getItem("company_id");
    this.set_storage_detail(this.id_company);
  }


  async set_storage_detail(company_id: any) {
    try {

      $("#capa").removeClass("opacity");
      let url = "clientcartera/" + company_id + "/";

      const bd: any = await this.settings.get_query(1, url);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.rut = body_data.datos.rut;
            this.cliente = body_data.datos.nombre;
            this.fecha = body_data.datos.date;
            this.informacion(1);


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  async informacion(page?: number) {
    try {

      let url =
      "facturas-pagadas-cliente/?rut_cliente=" +
      this.rut +
      "&page=" +
      page +
      "&fecha_inicio=" +
      this.fecha_inicio +
      "&fecha_fin=" +
      this.fecha_fin;
      this.showSpinner = true

      const bd: any = await this.settings.get_query(1, url);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.informacionFA = body_data.datos["array_docto"];
            this.informacionFANum = body_data.datos["doctosCount"];
            this.doctos = body_data.datos["doctosG"];
            this.deudor = body_data.datos["doctosGDeudor"];
            $("#capa").removeClass("opacity");

            if (this.informacionFANum == 0) {
              // this.activarInfo = false;
              this.noData = true
            }
            this.showSpinner = false


          break;
        default:

          break;
      }
    } catch (bd: any) {
          this.showSpinner = false
    }
  }

  activar_calendario(i: any, accion: any) {
    $(".calendario").hide();
    $(".calendario" + i).show();
    $(".texto_calendario" + i).hide();
  }

  pageCustom($e: any) {
    if (this.activar_filtro != true) {
      this.informacion($e.pageIndex + 1);
      $("#capa").addClass("opacity");
    } else {
      this.page = $e.pageIndex + 1;
      $("#capa").addClass("opacity");
      this.applyFilter();
    }
  }

  filtros(accion: number, filterValue: any, page?: number) {
    switch (accion) {
      case 1:
        this.fitro_doctos = [];
        this.fitro_doctos = filterValue.value;
        console.log(this.fitro_doctos);
        break;
      case 2:
        this.fitro_deudor = [];
        this.fitro_deudor = filterValue.value;
        break;
      default:
        this.fitro_doctos = [];
        this.fitro_deudor = [];
    }
    this.applyFilter();
  }

  async applyFilter() {

    let value: any = {
      rut_cliente: this.rut,
      page: this.page,
      fecha_inicio: this.fecha_inicio,
      fecha_fin: this.fecha_fin,
    };
    const regexDate = new RegExp(/[0-9]{0,2}\/[0-9]{0,2}\/[0-9]{0,4}/, "i");

    if (this.fitro_doctos.length > 0) {
      $("#capa").addClass("opacity");
      value["doctos"] = this.fitro_doctos;
      this.activar_filtro = true;
    }

    if (this.fitro_deudor.length > 0) {
      $("#capa").addClass("opacity");
      value["deudor"] = this.fitro_deudor;
      this.activar_filtro = true;
    }

    if (this.fitro_doctos.length == 0 && this.fitro_deudor.length == 0) {
      this.activar_filtro = false;
    }

    if (regexDate.test(this.fecha_inicio) || regexDate.test(this.fecha_fin)) {
      $("#capa").addClass("opacity");
      value["fecha_inicio"] = this.fecha_inicio;
      value["fecha_fin"] = this.fecha_fin;
      this.activar_filtro = true;
    }

    if (this.activar_filtro == true) {
      try {
        const bd: any = await this.settings.get_query(2, "facturas-pagadas-cliente/" + this.id_company + '/',value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
              this.informacionFA = body_data.datos["array_docto"];
              this.informacionFANum = body_data.datos["doctosCount"];
              this.doctos = body_data.datos["doctosG"];
              this.deudor = body_data.datos["doctosGDeudor"];
              console.log(this.informacionFANum);
              $("#capa").removeClass("opacity");
              this.noData = this.informacionFANum == 0 ?  true : false
              this.showSpinner = false
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
        this.showSpinner = false
      }
    }else{
      $("#capa").addClass("opacity");
      this.informacion(1);
    }


  }

  formatMoney(amount: any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? "-" : "";
      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
      );
    } catch (e) {}
  }

  async downloadXLXS() {
    this.loaderButton = true;
    console.log("decargar excel");
    const filtros = {
      deudor: this.fitro_deudor.length > 0 ? this.fitro_deudor : [],
      doctos: this.fitro_doctos.length > 0 ? this.fitro_doctos : [],
      fecha_inicio: this.fecha_inicio,
      fecha_fin: this.fecha_fin,
      rut_cliente: this.rut,
    };
    try {
      const body: any = await this.settings.get_query(
        2,
        `facturas-pagadas-cliente-excel/?rut_cliente=${this.rut}`,
        filtros
      );
  
      const _body = await body._body
      const file_path = _body.file_path;
  
      const link = document.createElement("a");
      link.setAttribute("download", `${file_path}.xlxs`);
      link.setAttribute("target", "_blank");
      link.href = `${SettingsService.API_ENDPOINT}media/facturas_pagadas_cliente_files/${file_path}`;
      link.click();
      this.loaderButton = false;
    } catch (error) {
    this.error = error
    this.loaderButton = false      
    }
  }

  resetFilter() {
    this.informacion(1);
  }

  filter1($e: any) {
    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    this.fecha_inicio = dia + "/" + mes + "/" + year;
    this.applyFilter();
  }

  filter2($e: any) {
    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    this.fecha_fin = dia + "/" + mes + "/" + year;
    this.applyFilter();
  }
}
