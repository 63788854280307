import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AddCta } from '../../pago-seleccion/add-cta';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
declare var $ :any;

@Component({
  selector: 'app-giro',
  templateUrl: './giro.component.html',
  styleUrls: ['./giro.component.less']
})
export class GiroComponent {

  @Input() id:any;
  @Input() done:any;
  @Input() clicked:any;
  @Input() change_giro:any;
  @Output() edited = new EventEmitter<any>();
  simulation_id:any;
  is_not_done:any;
  cargando:boolean=true;
  simulation: any;
  has_accounts:boolean=false;
  accounts = []
  id_banco_selected: any;
  ELEMENT_DATA_GIRO:Array<any> = [];
  displayedColumnsGiro: string[] = ['cuentas','bankName','CuentaRut','CuentaName','TipoCuenta','totalGiro'];
  dataSourceGiro:any = new MatTableDataSource<any>(this.ELEMENT_DATA_GIRO);
  // @ViewChild(MatPaginator) paginatorGiro: MatPaginator;

  constructor(public settings: SettingsService, private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'clicked': 
            const click = JSON.stringify(changes[propName].currentValue);   
            if (click === 'true') {     
              this.simulation_id = this.id;
              this.is_not_done = this.done;
              this.get_detalle(false);
            }
          break;
          case 'change_giro':     
            this.get_detalle(true);
          break;
        }
      }
    }
  }

  async get_detalle(update:any){
    this.cargando = true;
    let resultado: any = (await this.settings.get_query(1,'ComprasPagoAcreedor/' + this.simulation_id + '/?accion=1'));
    let body_data = resultado._body;
    if (resultado) {
      this.simulation = body_data.simulation;
      this.ELEMENT_DATA_GIRO = body_data.giro;
      if (body_data.giro[0].cuentas.length == 0) {
        this.has_accounts = false;        
      } else {
        this.has_accounts = true;  
        this.accounts = body_data.giro[0].cuentas;
      }
      this.id_banco_selected = body_data.giro[0].has_account;
      setTimeout(() => {
        this.dataSourceGiro=new MatTableDataSource(this.ELEMENT_DATA_GIRO);
        this.dataSourceGiro._updateChangeSubscription();
        // this.dataSourceGiro.paginator = this.paginatorGiro;
      });

      if (update) {
        this.edit(this.ELEMENT_DATA_GIRO[0].mnt_total);
      }

      this.cargando = false;
    }
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

  download_file(has_link:any){
    if (!has_link) {
      return false;
    }

    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = `${SettingsService.API_ENDPOINT}media/${has_link}`;
    link.click();
  }

  create_account(has_account:any)
  {
    let dialogRef = this.dialog.open(AddCta);
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = this.simulation.company.id;
    dialogRef.componentInstance.dialog_open = false;
    dialogRef.componentInstance.rut_acreedor = this.simulation.company.rut;
    dialogRef.componentInstance.nombre_acreedor = this.simulation.company.bussiness_name;
    dialogRef.componentInstance.is_compras = true;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
      //try {
          if (!has_account) {
            this.change_bank(null, result.address.id)
          } else { 
            this.get_detalle(false);
          }       
        //} catch {

        //}
      }
    });   
  }

  async change_bank($event:any, id?:any) {
    this.cargando = true;
    let value = null;
    if (id) {
      value = id;
    } else {
      value = $event.value
    }
    let resultado: any = (await this.settings.get_query(3, 'cuentaGiroCliente/' + this.simulation_id + '/', {"cuenta": value}));
    let body_data = resultado._body;
    if (resultado) {
      this.get_detalle(false);
    }
  }

  edit(giro:any) {
    this.edited.emit({edited: giro});
  }

}
