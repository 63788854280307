<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
        <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
        </div>
        <h1 class="titulo-simpli">Última observación de cobranza</h1>



    </div>
</div>


<mat-dialog-content>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  
            <form [formGroup]="valForm" >


                <app-rich-text [parent]="this"></app-rich-text>

    
        
            </form>
  
        </div>
    </div>
  
</mat-dialog-content>