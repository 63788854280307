
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top w-100">
    <h1 class="titulo-simpli" style="position: relative;">{{titulo}}</h1>
    <small class="subtitulo-simpli">{{subtitulo}}</small>


</div>


<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn w-100" *ngIf="paso_1">

      <form [formGroup]="firstFormGroup">

          <mat-stepper labelPosition="bottom" #stepper linear >

            <ng-template matStepperIcon="edit">
                <mat-icon >check</mat-icon>
            </ng-template>


                    
                  <mat-step>
                    <ng-template matStepLabel>Insertar Datos</ng-template>

                    <div class="row">
                      <!-- <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                        <h1 class="titulo-simpli">Subir archivo</h1>
                        <small class="subtitulo" *ngIf="!success && !warning">Puedes utilizar la tabla o descargar la planilla y subir un archivo</small>
                        <small class="subtitulo success-msg-text animate__animated animate__fadeIn"*ngIf="success">
                          Carga Exitosa.
                        </small>  
                        <small class="subtitulo warning-msg-text animate__animated animate__fadeIn"*ngIf="warning">
                          Hay algunos errores en los registros, verifique el formato en la Planilla.
                        </small>
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 align-right">
                        <button  mat-mini-fab [disabled]="disabled_subir_file" aria-label="Descargar planilla" (click)="descargar_file()" type="button" class="fab-excel-d"><mat-icon>download</mat-icon></button>
                        <button  mat-mini-fab [disabled]="disabled_subir_file" aria-label="Subir archivo" (click)="subir()" type="button" class="fab-excel-u"><mat-icon>attach_file</mat-icon></button>  
                        <input id="file_but_ventas" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" style="display:none;" (change)="subir_file($event)"/>
                      </div> -->
  
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
  
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
                            <div class="excel-msg info-msg" style="position: relative;"><i class="fas fa-info-circle"></i>
                              &nbsp; Descarga la plantilla excel aquí <mat-icon class="excel-btn" (click)="descargar_file()">download</mat-icon> una vez ingresadas tus facturas súbelo aquí <mat-icon class="excel-btn"(click)="subir()">attach_file</mat-icon>
                            </div>
                            <input id="file_but_ventas" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" style="display:none;" (change)="subir_file($event)"/>
  
                            <div class="info-msg" style="position: relative;">
                              <i class="fas fa-info-circle"></i>
                              &nbsp; Puedes copiar y pegar desde el Excel, las filas se crearán automaticamente, eso sí, procura respetar el formato de esta tabla.
                            </div>
                            <div class="success-msg" style="position: relative;">
                              <i class="fas fa-info-circle"></i>
                              &nbsp; El formato de las fechas debe ser: <strong>día/mes/año, día-mes-año,  año-mes-día, año/mes/día.</strong> Ejemplo 12/12/2021 o 12-12-21.
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align:right; margin-bottom:20px">
                            <button  mat-flat-button  class="primary-button" (click)="new_row()"  type="button"  style="margin-top: 10px;">Agregar Fila</button>
                            <button  mat-flat-button  class="primary-button" (click)="delete_row()" type="button"  style="margin-top: 10px;">Eliminar Fila</button>
                            <button  mat-flat-button  class="primary-button" mat-dialog-close type="button"  style="margin-top: 10px;">Cerrar</button>
                            <!-- <button  mat-flat-button  class="primary-button" (click)="listoparaenviar()" type="button"  style="margin-top: 10px;background: #3BA46F;color:white;">Enviar</button> -->
                          </div>  
                        </div>
  
                  
                        <div>
                          <hot-table [hotId]="id3" manualColumnResize="true" [allowInsertColumn]="true" [settings]="hotSettings3" licenseKey="non-commercial-and-evaluation"   stretchH='all' [data]="data3" [rowHeaders]="true"  style="position: relative;">
                            <hot-column  title="Folio"></hot-column>
                            <hot-column  title="RUT Deudor"></hot-column>
                            <hot-column  title="Nombre Deudor"></hot-column>
                            <hot-column  title="Fecha Emisión"></hot-column>
                            <hot-column  title="Fecha Vencimiento"></hot-column>
                            <hot-column  title="Monto"></hot-column>
                          </hot-table>
                        </div>
  
  
                      </div>
                      <button style="display: none;" id="siguiente_step2" mat-button matStepperNext>Next</button>
                      <button  mat-flat-button  class="primary-button" mat-dialog-close type="button"  style="margin-top: 10px;">Cerrar</button>
                      <button  mat-flat-button  class="primary-button" (click)="listoparaenviar()" type="button"  style="margin-top: 10px;">Continuar</button>
  
  
                    </div>
                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>Corrección y Continuar</ng-template>


                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
                        <div class="success-msg" *ngIf="no_continue == false && mostrar_resumen_facturas">
                          <i class="fas fa-check-circle" style="color:#3BA46F;"></i> 
                          &nbsp; Las facturas fueron corregidas, puedes emitir pago.
                        </div>
                        <div class="warning-msg" *ngIf="no_continue == true && mostrar_resumen_facturas" >
                          <i class="fas fa-exclamation-circle" style="color:#ff933b;"></i> 
                          &nbsp; Las facturas resaltadas en naranja tienen un error, introduzca el valor correcto.
                        </div>      
                        <div class="error-msg" *ngIf="hay_facturas_con_error == true">
                          <i class="fas fa-times-circle" style="color:#FA454C;"></i> 
                          &nbsp; Las facturas resaltadas en rojo tienen un error en el folio, no se encuentran o no están disponibles en tu libro de ventas.
                        </div>
                      </div>
                      
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">             
                        <table class="table" mat-table  [dataSource]="dataSourceVistaPrevia2">
                          <ng-container matColumnDef="check">
                            <th mat-header-cell *matHeaderCellDef ></th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="text-align: center;">
                              <i class="fas fa-check-circle" style="color:#3BA46F;" *ngIf="element.error == false && element.errorVentas == false"></i>
                              <i class="fas fa-exclamation-circle" style="color:#ff933b;" *ngIf="element.error == true && element.errorVentas == false"></i>
                              <i class="fas fa-times-circle" style="color:#FA454C;" *ngIf="element.error == true && element.errorVentas == true"></i>
                            </td>
                          </ng-container>
              
                          <ng-container matColumnDef="folio">
                            <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Folio</th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                              <!-- <input *ngIf="element.errorFolio==true && element.errorVentas == false" class="no-input" value="{{element.folio}}" (change)="capture_change('folio',i, $event.target.value)"> -->
                              <input class="no-input" value="{{element.folio}}" readonly>
                            </td> 
                          </ng-container>
                      
                          <ng-container matColumnDef="emisor">
                            <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Deudor</th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                              <input class="no-input" value="{{element.deudor_name}}" readonly>
                            </td>
                          </ng-container>
                      
                          <ng-container matColumnDef="rut_emisor">
                            <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Rut Deudor </th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                              <!-- <input *ngIf="element.errorRut==true && element.errorVentas == false" class="no-input corregir" value="{{element.deudor}}" (change)="capture_change('rut_emisor',i, $event.target.value, 'errorRut',element.folio)"> -->
                              <input [ngClass]="{'no-input':true, 'successrow':element.errorRut == false}" value="{{element.deudor}}" readonly>
                            </td>                        
                          </ng-container>
                      
                          <ng-container matColumnDef="fecha_emision">
                            <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Fecha Emisión </th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                            
                              <!-- <div style="position: relative;" *ngIf="element.errorFechaEmision == true && element.errorVentas == false">
                                <input class="no-input corregir" value="{{element.fecha_emision | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                              </div> -->
              
                              <div style="position: relative; text-align: center;" *ngIf="element.errorVentas == true">
                                <span>{{element.fecha_emision | date:'dd/MM/yy'}}</span>
                              </div>
              
                              <div style="position: relative;" *ngIf="element.errorVentas == false">
                                <input class="no-input" value="{{element.fecha_emision | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                              </div>
              
              
                              <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                                <mat-label>Value binding</mat-label>
                                <input matInput [matDatepicker]="index" (dateChange)="capture_date_error($event, i,'fecha_emision','errorFechaEmision')" [value]="element.fecha_emision">
                                <mat-datepicker-toggle matSuffix [for]="index"></mat-datepicker-toggle>
                                <mat-datepicker #index></mat-datepicker>
                              </mat-form-field>
              
                            </td>
              
                          </ng-container>
              
                          <ng-container matColumnDef="fecha_vencimiento">
                            <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Fecha Vencimiento </th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                            
                              <!-- <div style="position: relative;" *ngIf="element.errorFechaVencimiento==true && element.errorVentas == false">
                                <input [ngClass]="{'no-input':true,
                                                    'corregir':element.errorFechaVencimiento==true && element.errorVentas == false}" value="{{element.fecha_vencimiento | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                              </div> -->
              
                              <div style="position: relative; text-align: center;" *ngIf="element.errorVentas == true">
                                <span>{{element.fecha_vencimiento | date:'dd/MM/yy'}}</span>
                              </div>
              
                              <div style="position: relative;" *ngIf="element.errorVentas == false">
                                <input [ngClass]="{'no-input':true,'corregir':element.errorFechaVencimiento==true}" value="{{element.fecha_vencimiento | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                              </div>
              
              
                              <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                                <mat-label>Value binding</mat-label>
                                <input matInput [matDatepicker]="index" (dateChange)="capture_date_error2($event, i, 'fecha_vencimiento','errorFechaVencimiento')" [value]="element.fecha_vencimiento">
                                <mat-datepicker-toggle matSuffix [for]="index"></mat-datepicker-toggle>
                                <mat-datepicker #index></mat-datepicker>
                              </mat-form-field>
              
                          </ng-container>
              
                          <ng-container matColumnDef="monto">
                            <th mat-header-cell *matHeaderCellDef style="min-width: 100px;" > Monto </th>
                            <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                              <!-- <input *ngIf="element.errorMonto==true" class="no-input corregir" value="{{element.mnt_total}}" (change)="capture_change('monto',index, $event.target.value, 'errorMonto')"> -->
                              <input [ngClass]="{'no-input':true, 'successrow':element.errorMonto == false}" value="${{formatMoney(element.mnt_total)}}" readonly>
                            </td> 
                          </ng-container>
              
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsVistaPrevia2; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsVistaPrevia2" [ngClass]="{'errorrow':element.error == true && element.errorVentas == true}"></tr>
                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="6">No hay registros.</td>
                          </tr>
                        </table>
            
                        <mat-paginator #VistaPrevia2="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
            
                      </div>                       
                    </div>

                    <div style="margin-bottom: 30px;margin-top: 30px;">
                      <button  mat-flat-button  class="primary-button" type="button" matStepperPrevious style="margin-top: 10px;">Anterior</button>
                      <button  mat-flat-button  class="primary-button" mat-dialog-close type="button"  style="margin-top: 10px;">Cerrar</button>
                      <button [disabled]="no_continue" (click)="recalculate_manual()" matStepperNext mat-flat-button  class="primary-button" type="button" style="margin-top: 10px;">Finalizar</button>
                    </div>


                  </mat-step>


          </mat-stepper>

      </form>

</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn w-100" *ngIf="paso_2">

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="paso_2">
      <mat-card class="shadow-box">
        <mat-card-content>
    
              <!--Anticipo-->
              <div class="row col-top">
    
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <small><input class="no-input" value="95% Anticipo de Factura" readonly></small>
                </div> 
          
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{this.formatMoney(this.simulacion_recibida.anticipo)}}" readonly style="text-align: right;">
                </div>
          
                </div>
          
                <!--vencimiento promedio-->
                <div class="row">
          
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input class="no-input" value="Vencimiento promedio" readonly>
                    </div> 
          
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input class="no-input" value="{{simulacion_recibida.vencimiento}} Días" readonly style="text-align: right;">
                    </div>
          
                </div>
          
                <mat-divider class="separador5"></mat-divider>
          
                <!--Comision-->
                <div class="row">
          
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="Comisión" readonly>
                </div> 
          
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{formatMoney(this.simulacion_recibida.comision)}}" readonly style="text-align: right;">
                </div>
          
                </div>
          
                <!--Gastos operaciones-->
                <div class="row">
          
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="Gastos Operaciones" readonly>
                </div> 
          
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{formatMoney(this.simulacion_recibida.gastosOperacionales)}}" readonly style="text-align: right;">
                </div>
          
                </div>
          
                <!--IVA-->
                <div class="row">
          
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input class="no-input" value="IVA" readonly>
                    </div> 
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input class="no-input" value="$ {{this.formatMoney(this.simulacion_recibida.iva_operaciones)}}" readonly style="text-align: right;">
                    </div>
          
                </div>
          
          
                <!--Diferencia de precio-->
                <div class="row">
          
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input class="no-input" value="Diferencia de Precio" readonly>
                    </div> 
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input class="no-input" value="$ {{formatMoney(simulacion_recibida.interesTotal)}}" readonly style="text-align: right;">
                    </div>
            
                </div>
          
                <mat-divider class="separador5"></mat-divider>
          
                <!--monto liquido-->
                <div class="row">
          
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Monto Liquido a Recibir" readonly>
                    </div> 
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input  class="no-input" value="$ {{formatMoney(simulacion_recibida.liquidoRecibir)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                    </div>
                </div>
          
                <div class="row">
          
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giraremos a tus proveedores el Total de" readonly>
                  </div> 
          
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input  class="no-input" value="$ {{formatMoney(this.deuda_total)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                  </div>
                </div>
          
                <div class="row">
          
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input *ngIf="debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giraremos a tu cuenta el saldo por" readonly>
                      <input *ngIf="!debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a pagar a Arrayán" readonly>
                  </div> 
          
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input  class="no-input" value="$ {{formatMoney(por_pagar)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
          
          
                  </div>
                </div>
          
    
        </mat-card-content>
      </mat-card>
    
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="paso_2">
      <h1 class="titulo" style="position: relative;">Facturas Vinculadas</h1>
    
      <mat-card class="shadow-box">
        <mat-card-content>
    
          <div class="table-responsive col-top" style="margin-top: 30px;" >
    
    
    
            <table mat-table [dataSource]="dataSource_2">
    
    
    
              <ng-container matColumnDef="folio">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 110px;"> Folio </th>
                  <td mat-cell *matCellDef="let element;" style="max-width: 110px;" >
                      <input class="no-input" value="{{element.folio}}" readonly> 
                  </td>
              </ng-container>
          
              <ng-container matColumnDef="receptoracreedor_name">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Deudor </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}" style="min-width: 130px;"> 
                      <input class="no-input"  value="{{element.receptoracreedor_name}}" style="text-align: left;" readonly> 
                  </td>
              </ng-container>
        
              <ng-container matColumnDef="receptoracreedor_rut">
                <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
                <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                    <input class="no-input"  value="{{element.deudor}}" readonly> 
                </td>
              </ng-container>
        
              <ng-container matColumnDef="mnt_total">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                    <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                      <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
                  </td>
              </ng-container>
        
              <ng-container matColumnDef="fecha_emision">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Fecha Emisión </th>
                  <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                  <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
                  </td>
    
              </ng-container>
    
              <ng-container matColumnDef="fecha_vencimiento">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Fecha vencimiento</th>
                <td mat-cell *matCellDef="let element; let i=index, let index=index" style="min-width: 130px;">
    
                  <input   class="no-input" value="{{element.fecha_vencimiento}}" readonly> 
            
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>
    
    
        
              <tr mat-header-row *matHeaderRowDef="displayedColumns_2; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumns_2"></tr>
    
            </table>
    
            <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" ></mat-paginator>
        
    
    
    
          </div>
    
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="paso_2">
      <button  mat-flat-button (click)="anterior()"  class="primary-button"  type="button" >Anterior</button>
      <button  mat-flat-button [disabled]="disabled" (click)="cerrar_y_continuar()"  class="primary-button"  type="button" >Aceptar y Continuar</button>
    </div>
  </div>
</div>


