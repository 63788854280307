
import { ChangeDetectorRef, Component, OnInit,  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SettingsService } from '../settings/settings.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


declare var $ :any;

@Component({
  selector: 'app-registro-empresa',
  templateUrl: './registro-empresa.component.html',
  styleUrls: ['./registro-empresa.component.less'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})

export class RegistroEmpresaComponent implements OnInit {
  hide:boolean=true;
  hideRe:boolean=true;
  valForm: FormGroup;
  valFormCompany: FormGroup;
  show_spinner_mail:boolean=false;
  id_alianza: any;
  code_adwords: any;
  parametros_compite:any;
  compite=false
  display_spinner:boolean=false;
  name_empresa:string='';
  paso1:boolean=true;
  paso2:boolean=false;
  show_spinner:boolean=false;

  constructor(private activated_router: ActivatedRoute, private fb: FormBuilder, private router: Router, public settings: SettingsService, public dialog: MatDialog, private change:ChangeDetectorRef) {

    let password = new FormControl(null, Validators.compose([Validators.required,  Validators.minLength(6)]));
    let cPassword = new FormControl(null, Validators.compose([Validators.required, CustomValidators.equalTo(password)]));

    this.valForm = fb.group({
      'password': password,
      'password_confirmation': cPassword,
      'username': [null,  [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    });

    this.valFormCompany = fb.group({
      'nombre': [null, Validators.compose([Validators.required])],
      'rut': [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{7,10}[-][0-9Kk]$')])],
      'telefono': [null, Validators.compose([Validators.required])],
    });

    this.activated_router.params.subscribe((params:any) => {
      this.id_alianza = params['id_alianza']
      console.log('Ally detected')
    })

  }

  param="";
  ngOnInit() {
    let name = this.activated_router.snapshot.queryParamMap.get('gclid')
    let parametros_compite: any;
    let compite = false;
    this.activated_router.queryParamMap.subscribe((queryParams:any) => {
      console.log(queryParams);
      name = queryParams.get('gclid');
      let params = queryParams.get('params');
      if (this.id_alianza != null && this.id_alianza != undefined) {
        //if(this.id_alianza=='compite'){
        parametros_compite = params;
        compite = true;
        //}
      }
      this.parametros_compite = parametros_compite;
      if (this.parametros_compite != undefined) {
        this.valForm.controls['rut'].setValue(this.parametros_compite['rut_cliente']);
        this.valForm.controls['username'].setValue(this.parametros_compite['email']);
        this.valForm.controls['telefono'].setValue(this.parametros_compite['telefono']);
        this.valForm.controls['nombre'].setValue(this.parametros_compite['contacto']);
        this.checkRut(this.parametros_compite['rut_cliente']);
      }
      console.log(this.parametros_compite)
      this.compite = compite;

    });
    if (name != null) {
      this.code_adwords = name;
    }

    this.activated_router.queryParams.subscribe(params => {

      this.param = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    });

    if(this.param != undefined && this.param != null && this.param != ""){
      localStorage.setItem('param', this.param)
    }

    this.change.detectChanges();

  }

  ngAfterViewChecked() {

  }

  getQueryParameters(): any {
    console.log(window.location.search);
    let queryParameters = window.location.search.slice(1), params: any;
    const urlParams = new URLSearchParams(queryParameters);
    console.log(urlParams)
    let re = /([^=]*)=([^&]*)&*/g;
    let match;
    while (match = re.exec(queryParameters)) {
      params[match[1]] = match[2];
    }
    console.log(params);
    return params;
  }

  rutKey($e: any){
    let rut = $e.target.value.split('.').join('');
    let st = rut.replace('-', '');
    let arr = st.split('');
    let st_out = '';
    for (let i=0; i < arr.length; i++) {
      st_out = st_out + arr[i];
      if (i == arr.length - 2) {
        st_out = st_out + '-'
      }
    }
    rut = st_out
    this.valFormCompany.controls['rut'].setValue(rut)
  }

  submitFormCompany($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.setPaso(2);
    } else {
      this.show_spinner = false;
    }
  }

  setPaso(number: any){
    if(number == 2){
      this.paso1 = false;
      this.paso2 = true;
    }else if(number == 1){
      this.paso2 = false;
      this.paso1 = true
    }
  }

  // Back nuevo app cliente
  async changeMail($e: any ) {
    let email = $e.target.value;
    localStorage.removeItem('param')
    this.valForm.controls['username'].markAsTouched();
    if (this.valForm.controls['username'].valid) {
      try {
        let value = {'username':email}
        this.show_spinner_mail = true;
        const bd: any = await this.settings.get_query(2, 'cliente/check-signup/', value, true);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
            this.show_spinner_mail = false;
          break;
          default:
            this.show_spinner_mail = false;
          break;
        }
      } catch (bd: any) {
        this.show_spinner_mail = false;
        this.valForm.controls['username'].setErrors({'incorrect': true});
        localStorage.setItem('param', this.param)
      }
    }
  }

  async captureName($e: { target: { value: string; }; }, field: number) {
    /* Capture the data if the enrollment is interrupted. */
    try {
      let email = this.valForm.controls['username'].value;
      let fullName: string = '';
      fullName = $e.target.value;
      if (email != null && email != undefined && email != '') {
        let value = {'email':email, 'fullname': fullName};
        const bd: any = await this.settings.get_query(3, 'cliente/fail-signup/', value, true);
      }
    } catch(bd: any) {
      this.settings.manage_errors(bd.status, 'Register name.', bd);
    }
  }

  async capturePhone($e: { target: { value: string; }; }) {
    /* Capture the data if the enrollment is interrupted. */
    try {
      let email = this.valForm.controls['username'].value;
      let phone: string = '';
      phone = $e.target.value;
      if (email != null && email != undefined && email != '') {
        let value = {'email':email, 'phone':phone}
        const bd: any = await this.settings.get_query(3, 'cliente/fail-signup/', value, true);
      }
    } catch(bd: any) {
      this.settings.manage_errors(bd.status, 'Register phone', bd);
    }
  }

  async checkRut($e: any) {
    let rut = $e.target!=undefined ? (String($e.target.value)).toLocaleUpperCase() : $e;

      try {
        let value = {'rut':rut};
        this.display_spinner = true;
        const bd: any = await this.settings.get_query(2, 'cliente/check-signup/', value, true);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
            this.name_empresa = body_data.success.info.business_name;
            this.display_spinner = false;
            $("#submit_btn_final").removeClass("disable-submit");
          break;
          default:
            this.valFormCompany.controls['rut'].setErrors({invalid: true});
            this.display_spinner = false;
          break;
        }
      } catch (bd: any) {
        this.display_spinner = false;
        this.valFormCompany.controls['rut'].setErrors({invalid: true});
      }

  }

  async submitForm($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valFormCompany.controls) {
      this.valFormCompany.controls[c].markAsTouched();
    }
    if (this.valFormCompany.valid) {
      try {
        this.show_spinner = true;
        $("#submit_btn_final").addClass("disable-submit");
        value['nombre_empresa'] = this.name_empresa;
        value['username'] = this.valForm.controls['username'].value;
        value['password'] = this.valForm.controls['password'].value;
        value['password_confirmation'] = this.valForm.controls['password_confirmation'].value;
        if (this.id_alianza != undefined) {
          value['id_alianza'] = this.id_alianza
        }
        if (this.code_adwords != undefined) {
          value['code_adwords'] = this.code_adwords
        }
        if (this.compite == true) {
          value['datos_compite']['tasa'] = this.parametros_compite['tasa']
          value['datos_compite']['interes_total'] = this.parametros_compite['interes_total']
          value['datos_compite']['monto_anticipado'] = this.parametros_compite['monto_anticipado']
          value['datos_compite']['gastos'] = this.parametros_compite['gastos']
          value['datos_compite']['comision'] = this.parametros_compite['comision']
          value['datos_compite']['dias_vencimiento'] = this.parametros_compite['dias_vencimiento']
          value['datos_compite']['iva'] = this.parametros_compite['iva'];
        }

        if(this.param != "" && this.param != null && this.param != undefined){
          value['utm_source'] = this.param;
        }

        const bd: any = await this.settings.get_query(2, 'cliente/signup/', value, true);
        var body_data: any =bd._body;

        switch (bd.status) {
          case 200:

            localStorage.setItem('token', body_data?.success?.info?.token)
            localStorage.setItem('role', body_data?.success?.info?.role)
            localStorage.setItem('company_id', body_data?.success?.info?.company_id)

            this.dialog_success();
            this.show_spinner = false;
            $("#submit_btn_final").removeClass("disable-submit");
          break;
          default:
            this.show_spinner = false;
            $("#submit_btn_final").removeClass("disable-submit");
            break;
        }
      } catch (bd: any) {
        this.show_spinner = false;
        $("#submit_btn_final").removeClass("disable-submit");
      }
    }
  }

  checkPhone($e: any) {
    this.valFormCompany.controls['telefono'].markAsTouched();
    if (this.valFormCompany.controls['telefono'].valid) {
      const rgx = new RegExp(/^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/, 'i');
      if (!rgx.test($e.target.value)) {
        this.valFormCompany.controls['telefono'].setErrors({invalid: true});
      }
    }
  }

  dialog_success() {
    this.router.navigate(['/home'])
    const dialogRef = this.dialog.open(WelcomeModalLogin,{
      width:'600px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result:any) => {

    });

  }


  referido(){
    this.router.navigate(['/login'])
    localStorage.setItem('param', this.param)
  }



}

@Component({
  templateUrl: './welcomemodal.html'
})


export class WelcomeModalLogin implements OnInit {

  constructor(public dialogRef: MatDialogRef<WelcomeModalLogin>) {
  }


  ngOnInit() {
  }


}
