<div class="row row_main bottom-responsive">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli" *ngIf="STATUS == 6 && facturas.length > 0 || STATUS == 11 && facturas.length > 0 || STATUS == 4 && facturas.length > 0">Revisa el detalle de tu operación</h1>

        <h1 (click)="error_generico()" class="titulo-simpli" *ngIf="STATUS != 6 && STATUS != 11 && STATUS != 4 && STATUS != 14 && STATUS != 16 && STATUS != 15 && STATUS != 17 && facturas.length > 0">Completa el proceso de cesión</h1>
        <small class="subtitulo-simpli" *ngIf="STATUS != 6  && STATUS != 11 && STATUS != 4 && STATUS != 14 && STATUS != 16 && STATUS != 15  && STATUS != 17 && facturas.length > 0">Debes aprobar las condiciones finales de la simulación antes de ceder.</small>

        <h1 class="titulo-simpli" *ngIf="STATUS == 14 || STATUS == 15 || STATUS == 16 || STATUS == 17">Revisa el estado de tu operación</h1>

      </div>
    </div>
  </div>


  <!--Stepper simpli-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
    <div class="row">

        <!--STEPPER CONTROL-->
        <div *ngIf="STATUS != 6 && STATUS != 11 && STATUS != 4 && facturas.length > 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="container-stepper-simpli">

            <div [ngClass]="{'stepper-simpli':true, 'stepper-disable':STATUS == 14 || STATUS == 15  || STATUS == 16 || STATUS == 17 || STATUS == 5}" id="step-simpli1" (click)="change_step('step-simpli1')">
              <i class="fa-regular fa-file-lines"></i>
              <div class="step-text">Detalle general</div>

              <div class="line-step-simpli"></div>
            </div>
            


            <div [ngClass]="{'stepper-simpli':true, 'stepper-disable':STATUS == 3 || STATUS == 10}" id="step-simpli2"  (click)="change_step('step-simpli2')">
              <i class="fa-regular fa-handshake"></i>
              <div class="step-text">Cesión</div>

              <div class="line-step-simpli"></div>
            </div>


            <div [ngClass]="{'stepper-simpli':true, 'stepper-disable':STATUS == 3 || STATUS == 10}" id="step-simpli3" class="stepper-simpli" (click)="change_step('step-simpli3')">
              <div class="step-text">Estado final</div>
              <i class="fa-regular fa-calendar-clock"></i>
            </div>


          </div>

        </div>

        <div *ngIf="STATUS == 6 || STATUS == 11 || STATUS == 4" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="box-error" style="margin-top: 30px;margin-bottom: 50px;">
            <div class="row">
                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                    <div>
                        <i style="font-size: 25px;" class="fa-regular fa-triangle-exclamation"></i>
                    </div>
                </div>
      
                <div *ngIf="STATUS == 6" class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                  Recibimos tu confirmación del rechazo de condiciones de la simulación. Ya no es posible realizar la cesión. Para operar con nuevas facturas, vuelve a simular.
                </div>

                <div *ngIf="STATUS == 11" class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                  Esta operación fue descartada. Vuelve a simular para iniciar una nueva operación. Para saber más, contacta a tu ejecutivo.
                </div>

                <div *ngIf="STATUS == 4" class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                  Esta operación fue descartada. Vuelve a simular para iniciar una nueva operación. Para saber más, contacta a tu ejecutivo.
                </div>
      
            </div>
      
          </div>
        </div>

        <div *ngIf="STATUS == 6 || STATUS == 11 || STATUS == 4" class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>

        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>

        <!--STEP DETALLE GENERAL-->
        <div  class="col-lg-10 col-md-10 col-sm-10 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="step1 && facturas.length > 0">

          <div class="row">

              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                <h2 class="titulo-simpli2">Desglose de los montos</h2>

                <div class="card-simpli-cesion" style="margin-top: 40px;">
                    <mat-label class="label-interno">Información general</mat-label>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                        <mat-label class="label-izquierdo">N° Operación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho" *ngIf="id_operacion == null">{{id_sim}}</mat-label>
                        <mat-label class="label-derecho" *ngIf="id_operacion != null">{{id_operacion}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Cantidad de facturas:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{cantidad_facturas}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Fecha de creación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{date_created}}</mat-label>
                      </div>
                    </div>
                </div>

                <div class="card-simpli-cesion">
                  <mat-label class="label-interno">Montos asociados</mat-label>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total a ceder:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">% Financiamiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{anticipo}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto de anticipo:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{valor_anticipo_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Vencimiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{dias_promedio}} días</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Tasa de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{tasa_interes}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{interes_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Comisión:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Gastos operacionales:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">IVA:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{iva_operacion}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Excedentes:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{excedentes}}</mat-label>
                    </div>
                  </div>



                </div>

                <div class="card-simpli-cesion" style="padding-top: 7px;padding-bottom: 7px;">


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo-monto">Monto líquido:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho-monto">${{monto_recibir}}</mat-label>
                    </div>
                  </div>


                </div>

                <div class="card-simpli-cesion" style="border: none;padding:0 !important;background:none;width: 380px;">
                  <div style="margin-top: 30px;text-align: right;">
                    <mat-label class="add_facturas" (click)="download_pdf()"><i class="fa-regular fa-download facturas-add"></i>Descargar desglose</mat-label>&nbsp;
                  </div>
                </div>



              </div>

              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>

              <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 col-top">

                <h2 class="titulo-simpli2">Detalle de tus facturas</h2>
                <h3 class="subtitulo-simpli2" style="margin-bottom: 30px;">Selecciónalas para ver la información completa.</h3>

                <div class="container-exp add_factoring">
                  <mat-accordion>

                    <mat-expansion-panel style="margin-bottom: 15px;" *ngFor="let i of facturas" class="animate__animated animate__fadeIn">
        
                      <mat-expansion-panel-header style="position: relative;">
                        <mat-panel-title>
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="c-exp">
                                <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="N° Factura:"></div>
                                <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="{{i.document.folio}}"></div>
                              </div>
                            </div>
                          </div>
        
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="c-exp">
                                <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Monto:"></div>
                                <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="${{formatMoney(i.document.monto_total)}}"></div>
                              </div>
                            </div>
                          </div>
        
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="c-exp">
                                <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Deudor:"></div>
                                <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="{{i.document.nombre_deudor}}"></div>
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
        
                        <mat-panel-description *ngIf="STATUS != 6 && STATUS != 5 && STATUS != 11 && STATUS != 4" class="exp-desc" (click)="$event.preventDefault()">
                          <div style="margin-top: 20px;margin-right: 10px;" (click)="delete_factura(i.document_simulacion_id); $event.preventDefault();" matTooltip="Eliminar factura"><i class="fa-regular fa-trash exp-delete"></i></div>
                        </mat-panel-description>
        
                      </mat-expansion-panel-header>
        
        
        
                          <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="c-exp">
                                <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="RUT:"></div>
                                <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="{{i.document.rut_deudor}}"></div>
                              </div>
                            </div>
                          </div>
          
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="c-exp">
                                <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Fecha vencimiento:"></div>
                                <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="{{i.fecha_vencimiento}}"></div>
                              </div>
                            </div>
                          </div>
        
        
                    </mat-expansion-panel>
        
                  </mat-accordion>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
                    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
                  </div>
                </div>
                

                <div style="margin-top: 30px;text-align: right;" *ngIf="STATUS != 6 && STATUS != 5 && STATUS != 11 && STATUS != 4">
                  <mat-label class="add_facturas" (click)="open_agregar_facturas_dialog()"><i class="fa-regular fa-circle-plus facturas-add"></i> Agregar más facturas</mat-label> &nbsp;
                </div>
                
                <div class="btn-simpli-container" style="text-align: right;">
                  <button *ngIf="STATUS == 3" class="primary-button" (click)="aprobar_condiciones()" mat-flat-button>Aprobar</button>
                  <button *ngIf="STATUS == 3" class="secondary-button" (click)="rechazar()" mat-flat-button>Rechazar</button>

                  <button *ngIf="STATUS == 6 || STATUS == 11 || STATUS == 4" class="primary-button" (click)="volver_simular()" mat-flat-button>Volver a simular</button>
                  <button *ngIf="STATUS == 6 || STATUS == 11 || STATUS == 4" class="secondary-button" (click)="contactar()" mat-flat-button>Contactar a mi ejecutivo</button>
                </div>

              </div>

          </div>

        </div>

        <!--STEP CESION--> 
        <div  class="col-lg-10 col-md-10 col-sm-10 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="step2 && facturas.length > 0">

          <div class="row">

              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                <h2 class="titulo-simpli2">Desglose de los montos</h2>

                <div class="card-simpli-cesion" style="margin-top: 40px;">
                    <mat-label class="label-interno">Información general</mat-label>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                        <mat-label class="label-izquierdo">N° Operación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho" *ngIf="id_operacion == null">{{id_sim}}</mat-label>
                        <mat-label class="label-derecho" *ngIf="id_operacion != null">{{id_operacion}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Cantidad de facturas:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{cantidad_facturas}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Fecha de creación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{date_created}}</mat-label>
                      </div>
                    </div>
                </div>

                <div class="card-simpli-cesion">
                  <mat-label class="label-interno">Montos asociados</mat-label>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total a ceder:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">% Financiamiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{anticipo}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto de anticipo:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{valor_anticipo_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Vencimiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{dias_promedio}} días</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Tasa de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{tasa_interes}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{interes_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Comisión:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Gastos operacionales:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">IVA:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{iva_operacion}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Excedentes:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{excedentes}}</mat-label>
                    </div>
                  </div>



                </div>

                <div class="card-simpli-cesion" style="padding-top: 7px;padding-bottom: 7px;">


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo-monto">Monto líquido:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho-monto">${{monto_recibir}}</mat-label>
                    </div>
                  </div>


                </div>

                <div class="card-simpli-cesion" style="border: none;padding:0 !important;background:none;width: 380px;">
                  <div style="margin-top: 30px;text-align: right;">
                    <mat-label class="add_facturas" (click)="download_pdf()"><i class="fa-regular fa-download facturas-add"></i>Descargar desglose</mat-label>&nbsp;
                  </div>
                </div>



              </div>

              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>

              <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 col-top">

                <h2 class="titulo-simpli2">Resumen de tus facturas por ceder</h2>

                <div class="table-responsive-simpli add_factoring">

                  <table mat-table [dataSource]="dataSource">


                    <ng-container matColumnDef="factura">
                      <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.document.folio}}">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="deudor">
                      <th mat-header-cell *matHeaderCellDef> Deudor </th>
                      <td mat-cell *matCellDef="let element"  matTooltip="{{element.document.nombre_deudor}}"> 
                        <input class="no-input" readonly value="{{element.document.nombre_deudor}} ">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="rut">
                      <th mat-header-cell *matHeaderCellDef> RUT </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.document.rut_deudor}}">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef> Monto </th>
                      <td mat-cell *matCellDef="let element">
                        <input class="no-input" readonly value="${{formatMoney(element.document.monto_total)}}">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef> Estado </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input *ngIf="element.document.status == 0" class="no-input" readonly value="Por ceder">
                        <input *ngIf="element.document.status == 1" class="no-input" readonly value="Cediendo">
                        <input *ngIf="element.document.status == 2" class="no-input" readonly value="No cedida">
                        <input *ngIf="element.document.status == 3" class="no-input" readonly value="Cedida">
                      </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  

                </div>

                <mat-card class="card-tabla" style="position: relative;padding: 0px;">

                  <table mat-table [dataSource]="dataSource" class="table-res">
            
                    <ng-container matColumnDef="data">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
            
                        <div [ngClass]="{'box-factura':true}" (click)="$event.stopPropagation()">
            
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                                <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{element.document.folio}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Deudor:</mat-label><mat-label class="right-label">{{element.document.nombre_deudor}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">RUT:</mat-label><mat-label class="right-label">{{element.document.rut_deudor}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.document.monto_total)}}</mat-label></div>
                                <div class="container-label-res">
                                  <mat-label style="position: relative;color:#3BA46F;" *ngIf="element.document.status == 0" class="right-label">Por ceder</mat-label>
                                  <mat-label style="position: relative;color:var(--simpli-primary);" *ngIf="element.document.status == 1" class="right-label">Cediendo</mat-label>
                                  <mat-label style="position: relative;color:#FA454C;" *ngIf="element.document.status == 2" class="right-label">No cedida</mat-label>
                                  <mat-label style="position: relative;color:#3BA46F;" *ngIf="element.document.status == 3" class="right-label">Cedida</mat-label>
            
                                  <span *ngIf="element.document.status == 1"><i style="color:var(--simpli-primary);" class="fa-regular fa-square-check icom-sim"></i></span>
                                  <span *ngIf="element.document.status == 0"><i style="color:#3BA46F;" class="fa-regular fa-file-check icom-sim"></i></span>
                                  <span *ngIf="element.document.status == 2"><i  style="color:#FA454C;" class="fa-regular fa-circle-xmark icom-sim"></i></span>
                                  <span *ngIf="element.document.status == 3"><i style="color:#3BA46F;" class="fa-regular fa-circle-check icom-sim"></i></span>
                                </div>
            
                              </div>
                            </div>
                        </div>
                        
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsResponsive"></tr>
                    <tr mat-row *matRowDef="let row; let doc; columns: displayedColumnsResponsive;" ></tr>
                  </table>
                  
            
                </mat-card>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
                    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
                  </div>
                </div>
                
                <div class="btn-simpli-container" style="text-align: right;" *ngIf="STATUS != 14 && STATUS != 15 && STATUS != 16 && STATUS != 17">
                  <button *ngIf="has_valid_aecs == false" [disabled]="cediendo" class="primary-button" (click)="ceder_facturas()" mat-flat-button>
                    <mat-label *ngIf="!cediendo">Ceder facturas</mat-label>
                    <span *ngIf="cediendo"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                  </button>

                  <button *ngIf="has_valid_aecs == true" [disabled]="cediendo" class="primary-button" (click)="ceder_facturas()" mat-flat-button>
                    <mat-label *ngIf="!cediendo">Continuar cesión</mat-label>
                    <span *ngIf="cediendo"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                  </button>


                </div>

                <div style="margin-top: 30px;text-align: right;" *ngIf="STATUS == 16">
                  <mat-label class="add_facturas" (click)="open_agregar_facturas_dialog()"><i class="fa-regular fa-circle-plus facturas-add"></i> Agregar más facturas</mat-label> &nbsp;
                </div>

                <div class="btn-simpli-container" style="text-align: right;" *ngIf="STATUS == 16">
                  <button [disabled]="cediendo" class="primary-button" (click)="ceder_facturas()" mat-flat-button>
                    <mat-label *ngIf="!cediendo">Continuar</mat-label>
                    <span *ngIf="cediendo"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                  </button>
                </div>

              </div>

          </div>

        </div>

        <!--STEP ESTADO FINAL--> 
        <div  class="col-lg-10 col-md-10 col-sm-10 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="step3 && facturas.length > 0">

          <div class="row">

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                <h2 class="titulo-simpli2">Resumen del estado final </h2>

                <div class="table-responsive-simpli add_factoring">

                  <table mat-table [dataSource]="dataSource2">


                    <ng-container matColumnDef="numero_op">
                      <th mat-header-cell *matHeaderCellDef> N° Operación </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.id}}">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef> Monto </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="${{formatMoney(element.monto_operacion)}} ">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef> Estado </th>
                      <td mat-cell *matCellDef="let element" style="position: relative;"> 
                        <span *ngIf="element.estado == 0"><i style="color:#F99438;" class="fa-solid fa-circle circle-status"></i></span>
                        <span *ngIf="element.estado == 1"><i style="color:#3BA46F;" class="fa-solid fa-circle circle-status"></i></span>
                        <span *ngIf="element.estado == 2"><i style="color:#FA454C;" class="fa-solid fa-circle circle-status"></i></span>
                        <span *ngIf="element.estado == 3"><i style="color:#F99438;" class="fa-solid fa-circle circle-status"></i></span>
                        <span *ngIf="element.estado == 4"><i style="color:#FA454C;" class="fa-solid fa-circle circle-status"></i></span>

                        <input *ngIf="element.estado == 0" class="no-input" readonly value="En validación">
                        <input *ngIf="element.estado == 1" class="no-input" readonly value="Cedida">
                        <input *ngIf="element.estado == 2" class="no-input" readonly value="Descartada">
                        <input *ngIf="element.estado == 3" class="no-input" readonly value="Cesión parcial">
                        <input *ngIf="element.estado == 4" class="no-input" readonly value="No cedida">

                        <span *ngIf="element.estado == 0" matTooltip="La cesión está siendo validada. Te notificaremos cuando esta etapa finalice."><i class="fa-regular fa-circle-exclamation circle-status2"></i></span>
                        <span *ngIf="element.estado == 1" matTooltip="El giro de la operación está siendo procesado"><i  class="fa-regular fa-circle-exclamation circle-status2"></i></span>
                        <span *ngIf="element.estado == 2" matTooltip="¡Lo sentimos! la operación fue descartada. Para conocer el motivo, contacta a tu ejecutivo."><i  class="fa-regular fa-circle-exclamation circle-status2"></i></span>
                        <span *ngIf="element.estado == 3" matTooltip="Algunas facturas no fueron cedidas. Contacta a tu ejecutivo para saber más."><i  class="fa-regular fa-circle-exclamation circle-status2"></i></span>
                        <span *ngIf="element.estado == 4" matTooltip="¡Lo sentimos! la operación no pudo ser cedida. Para conocer el motivo, contacta a tu ejecutivo."><i  class="fa-regular fa-circle-exclamation circle-status2"></i></span>
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef> Fecha de giro </th>
                      <td mat-cell *matCellDef="let element">
                        <input *ngIf="element.fecha_giro != null" class="no-input" readonly value="{{element.fecha_giro}}">
                        <input *ngIf="element.fecha_giro == null" class="no-input" readonly value="-">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="ver_detalle">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let element" style="text-align: center;">
                        <button mat-flat-button color="primary" *ngIf="this.STATUS == 14 || this.STATUS == 15 || this.STATUS == 16 || this.STATUS == 17" routerLink="/home/operaciones">Ver detalle</button>
                        <button mat-flat-button color="primary" [disabled]="true" *ngIf="this.STATUS != 14 && this.STATUS != 15 && this.STATUS != 16 && this.STATUS != 17">Ver detalle</button>
                      </td>
                    </ng-container>

                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;" style="height: 50px !important;"></tr>
                  </table>
                  

                </div>

                <mat-card class="card-tabla" style="position: relative;padding: 0;">

                  <table mat-table [dataSource]="dataSource2" class="table-res">
            
                    <ng-container matColumnDef="data">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
            
                        <div [ngClass]="{'box-factura2':true}" (click)="$event.stopPropagation()">
            
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                                <div class="container-label-res"><mat-label class="left-label">N° Operación:</mat-label><mat-label class="right-label">{{element.id}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.monto_operacion)}}</mat-label></div>

                                <div class="container-label-res"><mat-label class="left-label">Estado:</mat-label>
                                  <mat-label style="position: relative;" *ngIf="element.estado == 0" class="right-label">En validación</mat-label>
                                  <mat-label style="position: relative;" *ngIf="element.estado == 1" class="right-label">Cedida</mat-label>
                                  <mat-label style="position: relative;" *ngIf="element.estado == 2" class="right-label">Descartada</mat-label>

            
                                  <span *ngIf="element.estado == 0"><i style="color:var(--simpli-primary);" class="fa-regular fa-square-check icom-sim"></i></span>
                                  <span *ngIf="element.estado == 1"><i style="color:#3BA46F;" class="fa-regular fa-circle-check icom-sim"></i></span>
                                  <span *ngIf="element.estado == 2"><i  style="color:#FA454C;" class="fa-regular fa-circle-xmark icom-sim"></i></span>

                                </div>

                                <div class="container-label-res"><mat-label class="left-label">Fecha de giro:</mat-label><mat-label class="right-label">-</mat-label></div>

            
                              </div>
                            </div>
                        </div>
                        
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsResponsive"></tr>
                    <tr mat-row *matRowDef="let row; let doc; columns: displayedColumnsResponsive;" ></tr>
                  </table>
                  
            
                </mat-card>
                
                <div class="btn-simpli-container" style="text-align: right;">
                  <button class="primary-button" mat-flat-button routerLink="/home">Volver al inicio</button>
                </div>

              </div>

          </div>

        </div>

        <!--DESCARTADAS SIN FACTURAS-->
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && show_descarte == true">

          <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <mat-card class="card-descarte">

                  <div style="text-align: center;margin-top: 60px;margin-bottom: 50px;">
                    <img src="../../../../assets/simpli/cesion/operación descartada 2.svg" height="100px">
                  </div>

                  <div class="title-descartada">La operación está descartada</div>

                  <div class="p-descartada">Ya no quedan facturas para ceder. Para iniciar 
                    <br>una nueva operación, vuelve a simular.
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              
                      <div class="btn-simpli-container">
                        <button class="primary-button" mat-flat-button (click)="volver_a_simular()">
                          <mat-label>Volver a simular</mat-label>
                        </button>

                        <button  class="secondary-button" mat-flat-button routerLink="/home/">
                          <mat-label>Volver al inicio</mat-label>
                        </button>
                      </div>
                      
                    </div>
                  </div>

                </mat-card>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>

          </div>

        </div>

        <!--Loading-->
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">

          <div class="container-loading">

            
            <div class="img-loading">
              <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
            </div>

            <div style="margin-top: 20px;">
              <mat-label class="label-loading">
                <div>La información está siendo procesada.</div>
                <div>Esto no tomará mucho tiempo.</div>
              </mat-label>
            </div>

          </div>


        </div>

        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>



    </div>




  </div>

</div>