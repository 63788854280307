<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
        <h1 class="titulo-simpli">Estado de tus simulaciones</h1>
        <small class="subtitulo-simpli">Revisa la información de tus simulaciones</small>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">

        <form  [formGroup]="valFormFilter">

            <div class="row" style="width: 100%;">

              <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 col-top" ></div>

              <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 col-top" >
                <mat-form-field appearance="fill">
                  <mat-label>Buscar</mat-label>
                  <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
                  <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                  <mat-hint>Buscar por monto o número de operación</mat-hint>
                </mat-form-field>
              </div>

            </div>

        </form>
      </div>
    </div>
  </div>


  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
    <mat-form-field>
      <mat-label>Filtro por estado</mat-label>
      <mat-select [(value)]="selected" panelClass="my-select" id="selectionstatus" (selectionChange)="statuschange($event)">
          <mat-option >Limpiar</mat-option>
          <mat-divider></mat-divider>
          <mat-option value="1">Solicitada</mat-option>
          <mat-option value="2">En evaluación</mat-option>
          <mat-option value="3">Por ceder</mat-option>
          <mat-option value="4">No aprobada</mat-option>

      </mat-select>
      </mat-form-field>
  </div>

  <!--Loading-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">

    <div class="container-loading">

      
      <div class="img-loading">
        <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
      </div>

      <div style="margin-top: 20px;">
        <mat-label class="label-loading">
          <div>La información está siendo procesada.</div>
          <div>Esto no tomará mucho tiempo.</div>
        </mat-label>
      </div>

    </div>


  </div>

  <!--TABLA-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length >0">

    <mat-card class="card-tabla" style="position: relative;">

      <div class="table-responsive-simpli add_factoring">

        <table mat-table [dataSource]="dataSource">

        
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> N° Simulación </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;width: 150px;"> 
                <input class="no-input" value="{{element.id}}" readonly>  
            </td>
          </ng-container>

          <ng-container matColumnDef="operacion">
            <th mat-header-cell *matHeaderCellDef> N° Operación </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;width: 150px;"> 
                <input *ngIf="element.operation_id != null" (click)="abrirOperacion(element.operation_id)"  style="text-decoration: underline; cursor: pointer;" class="no-input" value="{{element.operation_id}}" readonly>  
                <input *ngIf="element.operation_id == null" class="no-input" value="--" readonly>  
            </td>
          </ng-container>

        <ng-container matColumnDef="detalle">
          <th mat-header-cell *matHeaderCellDef> Detalle </th>
          <td mat-cell *matCellDef="let element" style="width: 110px;text-align: center;"> 
            <span (click)="ver_detalle(element.id, element.status, element.operation_id)" matTooltip="Ver" ><i class="icon-detail fa-regular fa-clipboard"></i></span>
            <span (click)="download_pdf(element.id)" matTooltip="Descargar"  *ngIf="downloadingId !== element.id" >
              <i class="icon-detail fa-regular fa-download">
              </i>
            </span>
            <span *ngIf="downloadingId === element.id">
              <i class="fa-solid fa-circle-notch fa-spin"></i>
            </span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="rut">
          <th mat-header-cell *matHeaderCellDef> Rut </th>
          <td mat-cell *matCellDef="let element, let indice=index" style="min-width: 110px;" > 
            <input class="no-input" value="{{element.rut}}" readonly>  
        </td>
        </ng-container>
    
        <ng-container matColumnDef="executive">
          <th mat-header-cell *matHeaderCellDef> Ejecutivo </th>
          <td mat-cell *matCellDef="let element" style="min-width: 110px;" > 
            <input class="no-input" value="{{element.executive}}" readonly> 
        </td>
        </ng-container>
    
        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
          <td mat-cell *matCellDef="let element" style="min-width: 110px;" >
             <input class="no-input" value="{{element.date_created}}" readonly> 
            </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef> Monto </th>
          <td mat-cell *matCellDef="let element" style="min-width: 110px;" > 
            <input class="no-input" value="$ {{formatMoney(element.total)}}" readonly> 
        </td>
        </ng-container>

        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element" style="text-align: center;min-width: 130px;">
            <input readonly class="no-input" value="{{element.estado}}">
          </td>
        </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>

    </mat-card>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length == 0">
    <div class="empty-msg">
        
                
      <div style="padding-top: 50px;text-align: center;">
        <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
      </div>

      <div style="padding-top: 50px;text-align: center;">
        <mat-label class="label-bienvenida">
          <div class="label-empty">
            ¡Ups! Al parecer, no tienes simulaciones para visualizar.
          </div>

          <div class="label-empty" style="margin-top: 20px;">
            Realiza una simulación para que puedas comenzar a operar.
          </div>

        </mat-label>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="btn-simpli-container">
            <button class="primary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
          </div>
          
        </div>
      </div>

    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false">
  

    <paginador-onboarding
    [pageSize]="7"
    [totalElements]="pageLength"
    [currentPage]="page"
    (pageChange)="pageEvent($event)">
    </paginador-onboarding>
  </div>

</div>
