<div mat-dialog-content>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli position-relative">{{mostrando_dialogo_de_espera ? 'Solicitud de Financiamiento' :
      'Facturas seleccionadas automáticamente'}}</h1>
    <small class="subtitulo-simpli">{{mostrando_dialogo_de_espera ? 'Procesando información' : 'En proceso'}}</small>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top w-100 dialogo-espera-box" *ngIf="mostrando_dialogo_de_espera == true">
    <dialogo-espera></dialogo-espera>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top w-100 position-relative" [hidden]="mostrando_dialogo_de_espera == true">

    <ng-container *ngIf="displayspinner">
      <div class="velo-inactivo animate__animated animate__fadeIn center__items position__absolute velo-custom">
        <mat-spinner diameter="40" class="spinner-simpli"></mat-spinner>
        <div class="show-m mt-1">Estamos creando la solicitud de Financiamiento :)</div>
      </div>
    </ng-container>

    <mat-stepper labelPosition="bottom" #stepper linear [hidden]="has_simulation == false" *ngIf="has_simulation == true">

      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>

      <mat-step [hidden]="!is_not_remuneracion">
        <ng-template matStepLabel>Selecciona cuentas bancarias</ng-template>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
            <div class="warning-msg animate__animated animate__fadeIn info-text"
              *ngIf="!esta_todo_listo">
              <i class="fa-light fa-circle-exclamation color-warning"></i>
              &nbsp; Agrega información de la cuenta de destino.
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
            <h3>Total: $ {{formatMoney(total)}}</h3>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
            <div class="table-responsive-simpli">
              <table mat-table [dataSource]="dataSourceCuentas">
                <ng-container matColumnDef="cta">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <span matTooltip="Agrega la cuenta bancaria de tu proveedor"
                      (click)="createAccount(element.company_id,element.ultimorutacreedor, element.ultimoacreedor)">
                      <i class="fa-light fa-circle-exclamation color-warning" *ngIf="element.bancos.length == 0"></i>
                    </span>
                    <span matTooltip="Agrega un cuenta bancaria"
                      (click)="createAccount(element.company_id,element.ultimorutacreedor, element.ultimoacreedor)">
                      <i matTooltip="Agregar nueva Cuenta Bancaria" *ngIf="element.bancos.length > 0"
                        class="fa-light fa-circle-plus color-success"></i>
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="receptoracreedor_name">
                  <th mat-header-cell *matHeaderCellDef> Acreedor Legal </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{element.ultimoacreedor}}">
                    <input class="no-input" value="{{element.ultimoacreedor}}" readonly>
                  </td>
                </ng-container>
                <ng-container matColumnDef="receptoracreedor_rut">
                  <th mat-header-cell *matHeaderCellDef> Rut </th>
                  <td mat-cell *matCellDef="let element">
                    <input class="no-input" value="{{element.ultimorutacreedor}}" readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef> Total</td>
                </ng-container>
                <ng-container matColumnDef="total_facturas">
                  <th mat-header-cell *matHeaderCellDef> Monto </th>
                  <td mat-cell *matCellDef="let element">
                    <input class="no-input"
                      value="$ {{formatMoney(element.total_facturas)}}" readonly>
                  </td>
                </ng-container>
                <ng-container matColumnDef="banco">
                  <th mat-header-cell *matHeaderCellDef> Seleccionar Cta. </th>
                  <td mat-cell *matCellDef="let element;let index=index">
                    <mat-form-field *ngIf="element.bancos.length > 0" class="mat-sm">
                      <mat-select (selectionChange)="asignarBanco(element.ultimorutacreedor, $event.value)"
                        [(value)]="element.id_bank">
                        <mat-option *ngFor="let i of element.bancos" [value]="i.id">{{i.bankBanco + ' | ' + i.typeBanco + ' ' +
                          i.numberBanco}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsCuentas; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsCuentas" [ngClass]="{'trbackgroud':element.bancos.length == 0}"></tr>
              </table>
              <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]"></mat-paginator>
            </div>
          </div>
          <div class="container__buttons">
            <div class="container__buttons">
              <button mat-raised-button mat-dialog-close class="neumorphism_btn" type="button">Cerrar</button>
            </div>
            <div class="container__buttons--end">
              <button mat-raised-button [disabled]="!esta_todo_listo" class="neumorphism_btn success-btn" type="button" matStepperNext>Siguiente</button>
            </div>
            <mat-spinner class="spinner-arrayan-general" *ngIf="displayspinner" diameter="40">
            </mat-spinner>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Simulación</ng-template>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
              <div class="warning-msg animate__animated animate__fadeIn info-text"
                *ngIf="disabled_next">
                <i class="fa-light fa-circle-exclamation color-warning"></i>
                &nbsp; Tus facturas de ventas no cubren tus compras, para avanzar puedes agregar facturas de venta..
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-10">
              <mat-card class="shadow-box">
                <mat-card-content>
                  <div class="row mb-20">
                    <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6 col-top">
                      <small class="subtitulo-simpli animate__animated animate__fadeIn">Puedes editar esta simulación seleccionando nuevas facturas</small>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-top text-right">
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <mat-form-field>
                        <mat-label>Filtra para agregar nuevas facturas a tu solicitud</mat-label>
                        <input matInput formControlName="filter" (change)="reset($event.target.value)">
                        <mat-icon matTooltip="Buscar" matSuffix class="btn_suffix" (click)="filtrar()">search</mat-icon>
                        <mat-hint>Introduce una palabra clave y dale click a la lupa para filtrar.</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="w-100">
                    <div class="table-responsive-simpli">
                      <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef
                              matTooltip="Se marcarán todas facturas de la página en visualización, de 20 en 20.">
                              <input type="checkbox"
                                  class="cursor-pointer"
                                  (change)="$event ? masterToggle() : null; $event.stopPropagation()"
                                  [checked]="selection.hasValue() && isAllSelected()">
                          </th>
                          <td mat-cell *matCellDef="let row; let index=index;"
                              class="text-center">
                              <input type="checkbox"
                                      class="cursor-pointer"
                                      (click)="activa(row); $event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)">
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="folio">
                          <th mat-header-cell *matHeaderCellDef> Folio </th>
                          <td mat-cell *matCellDef="let element;">
                            <input class="no-input" value="{{element.folio}}" readonly>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="receptoracreedor_name">
                          <th mat-header-cell *matHeaderCellDef> Deudor </th>
                          <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor.business_name}}">
                            <input class="no-input" value="{{element.receptoracreedor.business_name}}"
                              readonly>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="receptoracreedor_rut">
                          <th mat-header-cell *matHeaderCellDef> Rut </th>
                          <td mat-cell *matCellDef="let element">
                            <input class="no-input" value="{{element.receptoracreedor.rut}}" readonly>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="mnt_total">
                          <th mat-header-cell *matHeaderCellDef> Monto </th>
                          <td mat-cell *matCellDef="let element">
                            <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="fecha_emision">
                          <th mat-header-cell *matHeaderCellDef> Fecha Emisión </th>
                          <td mat-cell *matCellDef="let element;">
                            <input class="no-input" value="{{element.fecha_emision}}" readonly>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="fecha_vencimiento">
                          <th mat-header-cell *matHeaderCellDef>Fecha vencimiento <i
                              matTooltip="Agregar una fecha de vencimiento es opcional"
                              class="fa-light fa-info-circle"></i></th>

                          <td mat-cell *matCellDef="let element; let i=index; let index=index" style="position: relative;">
                            <span (click)="picker.open()" class="material-icons icon-calendar">
                                event
                            </span>
                            <input (click)="picker.open()" *ngIf="element.fecha_vencimiento != null" style="text-align: center;" class="no-input" readonly value="{{element.fecha_vencimiento}}">
                            <input (click)="picker.open()" *ngIf="element.fecha_vencimiento == null" style="text-align: center;" class="no-input" readonly value="--/--/--">


                            <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                                <input matInput [min]="minDate" readonly [matDatepicker]="picker" (dateChange)="recalculateFecha($event, element)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </td>

                          <td mat-footer-cell *matFooterCellDef> </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>
                      </table>
                      <mat-paginator #paginator [length]="length" [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="pageCustom($event)"> </mat-paginator>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
              <div class="info-msg animate__animated animate__fadeIn date-text">
                Generamos la simulación a 30 días de plazo; ¿deseas ajustar el plazo?
                <strong class="text-underline" (click)="r.open()">Cambia la fecha aquí</strong>
                <mat-form-field class="mat-form-field-custom-date">
                  <mat-label>Fecha de vencimiento</mat-label>
                  <input [min]="minDate" matInput [matDatepicker]="r" (dateChange)="recalculateFechaAll($event)"
                    (click)="r.open()" formControlName="secondCtrl">
                  <mat-datepicker-toggle matSuffix [for]="r"></mat-datepicker-toggle>
                  <mat-datepicker #r></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
              <mat-card class="shadow-box">
                <mat-card-content>
                  <small class="smalltitle">Simulación automática de factoring</small>
                  <div class="row col-top">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <small><input class="no-input" value="95% Anticipo de factura" readonly></small>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input text-right" value="$ {{this.formatMoney(this.simulation.valor_anticipo_total)}}" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input" value="Vencimiento promedio" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input text-right" value="{{simulation.dias_promedio}} Días" readonly>
                    </div>
                  </div>
                  <mat-divider class="separador5"></mat-divider>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input" value="Comisión" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input text-right" value="$ {{formatMoney(this.simulation.comision)}}" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input" value="Gastos operaciones" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input text-right" value="$ {{formatMoney(this.simulation.gastos)}}" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input" value="IVA" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input text-right" value="$ {{this.formatMoney(this.simulation.iva_operacion)}}" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input" value="Diferencia de precio" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input text-right" value="$ {{formatMoney(simulation.interes_total)}}" readonly>
                    </div>
                  </div>
                  <mat-divider class="separador5"></mat-divider>
                  <div class="row" *ngIf="is_not_remuneracion">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold" value="Giro a proveedores" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold text-right" value="$ {{formatMoney(deuda)}}" readonly>
                    </div>
                  </div>
                  <div class="row" *ngIf="!is_not_remuneracion">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold" value="Giro a colaboradores" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold text-right" value="$ {{formatMoney(deuda)}}" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold" value="Giro a tu cuenta" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold text-right" value="$ {{formatMoney((simulation.monto_recibir-deuda))}}" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold" value="Giro Total" readonly>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top m-0">
                      <input class="no-input resumen-simulacion-bold text-right" value="$ {{formatMoney(simulation.monto_recibir)}}" readonly>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class="container__buttons" *ngIf="is_not_remuneracion">
            <div class="container__buttons">
              <button mat-raised-button mat-dialog-close class="neumorphism_btn" type="button">Cerrar</button>
            </div>
            <div class="container__buttons--end">
              <button mat-raised-button matStepperPrevious class="neumorphism_btn" type="button">Anterior</button>
              <button *ngIf="!disabled_next && !cargando" mat-raised-button class="neumorphism_btn success-btn" type="button" (click)="postSimulacion()">Finalizar</button>
            </div>
          </div>
          <div class="container__buttons" *ngIf="!is_not_remuneracion">
            <div class="container__buttons">
              <button mat-raised-button mat-dialog-close class="neumorphism_btn" type="button" >Cerrar</button>
            </div>
            <div class="container__buttons--end">
              <button mat-raised-button matStepperPrevious class="neumorphism_btn" type="button">Anterior</button>
              <button *ngIf="!disabled_next && !cargando" mat-raised-button [disabled]="displayspinner" class="neumorphism_btn success-btn"type="button" (click)="crear_operacion_remuneraciones()">Finalizar</button>
            </div>
          </div>
          <div class="display-flex" *ngIf="cargando == true">
            <small>*Estamos sincronizando con el SII para actualizar los acreedores.</small>
            <mat-spinner class="spinner-simpli" diameter="40"></mat-spinner>
          </div>
        </form>
      </mat-step>
    </mat-stepper>

    <ng-container *ngIf="has_simulation == false">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="empty-msg">


            <div style="padding-top: 50px;text-align: center;">
              <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
            </div>

            <div style="padding-top: 50px;text-align: center;">
              <mat-label class="label-bienvenida">
                <div class="label-empty">
                  No tienes facturas disponibles para pagar la deuda,
                </div>

                <div class="label-empty" style="margin-top: 20px;">
                  la deuda actual es de ${{formatMoney(deuda)}}
                </div>

              </mat-label>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="btn-simpli-container">
                  <button class="btn-simpli-primario" mat-dialog-close mat-raised-button>Cerrar</button>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </ng-container>

  </div>

</div>
