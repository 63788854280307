<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close style="display: none;">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
          <i style="color:#3BA46F;font-size: 45px;" class="fa-sharp fa-light fa-file-circle-check"></i>
        </div>

        <div class="label-dialog-strong">{{title}}</div>
        <div class="label-dialog">{{subtitle}}</div>


        <div class="row" *ngIf="open_as_sim == false">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button (click)="adjuntar_files()">
                  Adjuntar
                </button>

                <button class="secondary-button" mat-flat-button (click)="ver_estado()">
                  Ver estado
                </button>
              </div>
              
            </div>
        </div>

        <div class="row" *ngIf="open_as_sim == true">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container">
              @if(onboarding == false){
                <button class="primary-button" mat-flat-button (click)="ver_estado()">
                  Ver estado
                </button>
              }@else{
                <button class="primary-button" mat-flat-button (click)="continue()">
                  Continuar
                </button>
              }

            </div>
            
          </div>
      </div>

    </div>


    
</mat-dialog-content>