import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SettingsService } from "src/app/settings/settings.service";
declare var $ :any; 

@Component({
  selector: "app-carga-manual-remuneraciones",
  templateUrl: "./app-carga-manual-remuneraciones.component.html",
  styleUrls: ["./app-carga-manual-remuneraciones.component.less"],
})
export class CargaManualRemuneracionesComponent implements OnInit {
  title: string = "Subir plantilla";
  subtitle: string =
    "Descarga la plantilla con el formato, agrega información, súbela y listo!";
  disabled: boolean = false;
  process: boolean = false;
  hay_facturas_con_error: boolean = false;
  todas_las_facturas_ok: boolean = false;
  mensaje_error: string = "";

  disabled_button: boolean = true;
  label_boton_financiar: string = "Continuar";
  disabled_subir_file: boolean = false;
  show_descargar_plantilla: boolean = true;
  colaboradores: Array<any> = [];

  company_id: any;

  constructor(
    public settings: SettingsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  subir() {
    $("#file_but").trigger("click");
  }

  async descargar_file() {
    this.disabled_subir_file = true;
    try {
      const body_data: any = await this.settings.get_query(
        1,
        `pagoRemuneracionesManuales/${this.data.company_id}/`
      );
      const _body = body_data._body
      if (body_data) {
        window.open(_body.nameArchivo, "blank");
        this.disabled_subir_file = false;
      }
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  // async subir_file($e: any) {

  //   this.no_continue = true;
  //   this.no_listo = true;
  //   this.success = false;
  //   this.warning = false;
  //   this.disabled_subir_file = true;

  //   this.reset_tabla();

  //   let formData: FormData = new FormData();
  //   let file_input = $e.target.files;

  //   for (let i = 0; i < file_input.length; i++) {
  //     let name = file_input[i].name;
  //     formData.append('pr_' + i, file_input[i], name);
  //   }

  //   let resultado: any = (await this.settings.get_query(2, 'pagoRemuneracionesManuales/' + this.company_id + '/', formData));
  //   let body_data = resultado._body;
  //   if (resultado) {
  //     try {
  //       if (body_data.error.length > 0) {
  //         this.warning = true;
  //         this.disabled_subir_file = false;
  //         $("#file_rem").val("");
  //         // this.snackBar.open("Hay algunos errores en los registros, verifique el formato en la Planilla.", "Aceptar", {
  //         //   duration: 5000,
  //         //   panelClass: ['JP-snackbar']
  //         // });
  //         this.open_input_dialog("Error", "Errores", "¡Oops! Hay algunos errores en los registros, verifique el formato en la Planilla.", "", "info", "#FA454C");
  //         return false;
  //       } else {
  //         this.success = true;
  //         this.reset_file();
  //       }
  //     } catch {
  //       this.success = true;
  //       this.reset_file();
  //     }

  //     if (body_data.arrayFront.length == 0) {
  //       this.warning = true;
  //       this.disabled_subir_file = false;
  //       $("#file_rem").val("");
  //       // this.snackBar.open("No existen filas válidas", "Aceptar", {
  //       //   duration: 5000,
  //       //   panelClass: ['JP-snackbar']
  //       // });
  //       this.open_input_dialog("Error", "Errores", "¡Oops! No existen filas válidas.", "", "info", "#FA454C");
  //       this.no_listo = false;
  //       return false;
  //     }

  //     for (let i in body_data.arrayFront) {
  //       if (body_data.arrayFront[i].errorRemuneraciones == true) {
  //         this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i]);
  //       } else if (body_data.arrayFront[i].errorRemuneraciones == false) {
  //         this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i]);
  //       }
  //     }

  //     if (this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0) {
  //       this.hay_facturas_con_error = true;
  //     } else {
  //       this.hay_facturas_con_error = false;
  //     }

  //     let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR);

  //     this.check_errors(this.ELEMENT_DATA_VISTAPREVIA);

  //     setTimeout(() => {
  //       this.dataSourceRem = new MatTableDataSource(array3);
  //       this.dataSourceRem.paginator = this.remPaginator;
  //       this.dataSourceRem._updateChangeSubscription();
  //       this.no_listo = false;
  //     });

  //     $("#siguiente_step").trigger('click');

  //   }

  //   this.disabled_subir_file = false;
  //   $("#file_rem").val("");

  // }

  async onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map((key: any) => pFileList[key]);

    let formData: FormData = new FormData();
    let file_input = pFileList;

    for (let i = 0; i < file_input.length; i++) {
      let name = file_input[i].name;
      formData.append("cv_" + i, file_input[i], name);
    }

    this.disabled = true;
    this.hay_facturas_con_error = false;
    this.todas_las_facturas_ok = false;
    this.show_descargar_plantilla = false;
    this.colaboradores = [];

    try {
      let resultado: any = await this.settings.get_query(
        2,
        `pagoRemuneracionesManuales/${this.company_id}/`,
        formData
      );
      let body_data = resultado._body;
      if (resultado) {
        this.reset_file();
        this.disabled = false;
        if (body_data.arrayFront.length == 0) {
          this.hay_facturas_con_error = true;
          this.mensaje_error =
            "No existen colaboradores";
          this.disabled_button = true;
        } else {
          // si hay al menos uno
          this.reset_file();
          this.disabled = false;
          this.todas_las_facturas_ok = true;
          this.disabled_button = false;

          this.colaboradores = body_data.arrayFront;
        }
      }
    } catch {
      this.reset_file();
      this.disabled = false;
      this.hay_facturas_con_error = true;
      this.mensaje_error =
        "Error en los datos";
      this.disabled_button = true;
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDropSuccess(event: any) {
    event.preventDefault();

    this.uploadFileDrag(event.dataTransfer.files);
  }

  onChange(event: any) {
    this.uploadFileDrag(event.target.files);
  }

  private uploadFileDrag(files: Array<any>) {
    if (files.length) {
      this.process = true;
      this.onFileChange(files);
      this.process = false;
    }
  }

  crearNomina() {
    console.log('facturas remuneraciones')

    // regresar lista de nomina
  }

  reset_file() {
    $("#file_but").val("");
  }

  public files: any[] = [];
}
