<mat-card class="shadow-box">


    <mat-card-header style="display: block;padding: 0;">
      <h1 class="titulo-simpli">Participación Clientes/Ventas</h1>
      <small class="subtitulo-simpli">{{ this.date_start}} / {{ this.date_end}}</small>
    </mat-card-header>
  
      <mat-card-content >

  
        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div id="container-participacion-deudores-ventas" style="width: 100%;"></div>

              <div *ngIf="hay_data == true && loading == false">Total facturas: <strong>${{total_facturas}}</strong></div>
              <div *ngIf="hay_data == true && loading == false">Cant facturas: <strong>{{total_cantidad}}</strong></div>
            </div>
  
        </div>
  
        <div class="row" *ngIf="hay_data == false && loading == false">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
            <div class="empty-msg">
          
                  
              <div style="padding-top: 50px;text-align: center;">
                <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
              </div>
        
              <div style="padding-top: 50px;text-align: center;">
                <mat-label class="label-bienvenida">
                  <div class="label-empty">
                    ¡Ups! Al parecer no estas sincronizado.
                  </div>
        
                  <div class="label-empty" style="margin-top: 20px;">
                    Cargar tus credenciales y dale click al botón sincronizar información en la parte superior.
                  </div>
        
                </mat-label>
              </div>
        
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
                  <div class="btn-simpli-container">
                    <button class="primary-button" (click)="open_credenciales()" mat-flat-button>Cargar credenciales</button>
                  </div>
                  
                </div>
              </div>
        
            </div>
  
          </div>
  
        </div>
  
        <div class="row animate__animated animate__fadeIn" *ngIf="hay_data == false && loading == true">
  
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="sinRegistros" style="text-align: center;">
                  <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                    <div style="text-align: center;">
                      <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                  </div>
                  </small>
              </div>
          </div>
  
        </div>
  
  
      </mat-card-content>
  </mat-card>  