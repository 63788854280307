import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;

@Component({
  selector: 'app-tabla-proveedor-monto',
  templateUrl: './tabla-concentraciones.component.html',
  styleUrls: ['./tabla-concentraciones.component.less'],
})
export class TablaConcentracionesProveedor implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

 
  @Input() id_company: any;
  @Input() participacion_deudores_obj: any;
  actual:any
  menos:any
  // @Input() tipoCartera;

  private error: string | null = null;
  public showSpinner: boolean = false;;
  public totalPorcentaje: any;
  public totalDeuda: any;
  public dataSource:any;
  public deudores:any;
  public selectedValue:any;
  public tableDataDeudores:any;
  public noInfo: boolean=false;

  displayedColumns: string[] = ['proveedor', 'monto', 'porcentaje'];

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.getDataTable();
  }

  async getDataTable() {
    // !! revisar el get en produccion - siempre trae otros deudores, preguntar que hacer.
    this.showSpinner = true;
    // this.cargando=true
    let mes_actual
    let mes_menos
    if (this.participacion_deudores_obj === undefined ) {
    //Mes actual
    let mensual = new Date();
    let mes_actual_ultimo = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);
    let mes1 = new Date(mes_actual_ultimo)
    let y1 = mes1.getFullYear();
    let m1:any = mes1.getMonth()+1;
    let d1:any = mes1.getDate();
    if(m1<10){m1="0"+m1}
    if(d1<10){d1="0"+d1}
    mes_actual = y1+"-"+m1+"-"+d1

    //Mes -6
    let mesmenos=new Date(mes_actual_ultimo.getTime() - (3650*60*60*1000)*1);
    let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
    let mes6 = new Date(ultimomenos)
    let y2 = mes6.getFullYear();
    let m2:any = mes6.getMonth()+1;
    let d2:any = mes6.getDate();
    if(m2<10){m2="0"+m2}
    if(d2<10){d2="0"+d2}
    mes_menos = y2+"-"+m2+"-"+d2
  } else {
    mes_actual = this.participacion_deudores_obj.end
    mes_menos = this.participacion_deudores_obj.start
  }
    this.actual = mes_actual
    this.menos = mes_menos



    // FIXME: Cambiar el id de la company
    // ! cambiar id compnay por this.id_company = 3547
    try {
      const body_data: any = await this.settingsService.get_query(1, 'tablaProvedorxCompras/'+this.id_company+'/'+"?inicio="+mes_menos+"&fin="+mes_actual);
      const cartera = body_data._body
      //this.tableDataDeudores = cartera.resumen
      this.tableDataDeudores = this.setOrderDesc(cartera['resumen']);
      this.totalDeuda = cartera["total"]["monto"];
      this.dataSource = new MatTableDataSource(this.tableDataDeudores);
      this.dataSource.paginator = this.paginator;
      this.totalPorcentaje = Math.round(this.getTotalPorcentaje(this.tableDataDeudores));
      this.showSpinner = false;
      if (this.tableDataDeudores.length > 0) {
        this.noInfo = false;
      } else {
        this.noInfo = true;
      }
    } catch (error:any) {
      this.error = error;
      this.noInfo = false;
      this.showSpinner = false;
    }
  }

  getTotalPorcentaje(data:any) {
    return data.reduce((acc:any, cv:any) => acc + cv.porcentaje, 0);
  }

  setOrderDesc(data:any) {
    return data.sort((a:any, b:any) => b.monto - a.monto);
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) {}
  }

  onOptionSelected(deudor:any) {
 
    if (deudor.value === '' || deudor.value === undefined || deudor.value === null) {
      this.selectedValue = '';
      console.log("1")
      this.dataSource = new MatTableDataSource(this.tableDataDeudores);
      this.dataSource.paginator = this.paginator;
    } else {
      console.log( this.selectedValue.business_name)
      const newData = this.tableDataDeudores.filter((item:any) => item.business_name === this.selectedValue.business_name);
      console.log("2",newData)
      this.dataSource = new MatTableDataSource(newData);
      this.dataSource.paginator = this.paginator;
    }
  }
}

