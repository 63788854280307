<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
  </div>
  
  <div mat-dialog-content>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn animate__faster" *ngIf="step == 1">

      <div class="empty-msg">
  
  
        <div style="padding-top: 50px;text-align: center;margin-top: 15px;margin-bottom: 15px;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;">
              ¡Estás a punto de agregar nuevas facturas!
            </div>

            <div class="label-parrafo">Si lo haces, la simulación volverá a ser evaluada,</div>
            <div class="label-parrafo">considerando el reajuste en el listado de facturas.</div>
  

  
          </mat-label>
        </div>
  
        <div class="row" style="margin-top: 50px;">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  
            <div class="btn-simpli-container">
              <button class="secondary-button" mat-dialog-close  mat-flat-button>Cancelar</button>
  
              <button class="primary-button"  (click)="step = 2" mat-flat-button>Agregar facturas</button>
            </div>
            
          </div>
        </div>
  
      </div>
  
  
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn animate__faster" *ngIf="step == 2">

      <div class="empty-msg">
  
  
        <div style="text-align: center;margin-top: 15px;margin-bottom: 15px;">
          <mat-label class="label-bienvenida">
            <div class="label-table">
              Selecciona una forma de agregar tus facturas
            </div>
          </mat-label>
        </div>
  
        <div class="row" style="margin-top: 60px;margin-bottom: 60px;">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  
            <div class="box-options">

                <div class="box-option1" (click)="facturassii()">
                  <img style="margin-left: 25px;margin-bottom: 20px;" src="../../../../../assets/simpli/cesion/siilogo.svg" height="35px">
                  <div class="label-parrafo">Agregar a</div>
                  <div class="label-parrafo">través del SII</div>
                </div>

                <div class="box-option2" (click)="open_agregar_facturas_dialog()">
                  <div><i style="font-size: 37px;margin-bottom: 20px;color:var(--simpli-secondary);"  class="fa-duotone fa-solid fa-file-plus-minus"></i></div>
                  <div class="label-parrafo">Agregar</div>
                  <div class="label-parrafo">manualmente</div>
                </div>

            </div>
            
          </div>
        </div>
  
      </div>

    </div>
  
  </div>