import { Component, OnInit, ViewChild } from '@angular/core';

import { SettingsService } from 'src/app/settings/settings.service';;
import { AddCta } from '../../payments/pago-seleccion/add-cta';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { EditarFacturasCompraComponent } from '../editar-facturas-compra/editar-facturas-compra.component';

declare var $ :any;


@Component({
  selector: 'app-detalle-proveedores',
  templateUrl: './detalle-proveedores.html',
  styleUrls: ['./detalle-proveedores.less']
})
export class DetalleProveedores implements OnInit {

  simulation_id:any;
  is_not_done:any;

  cargando:boolean=true;
  simulation: any;
  has_accounts:boolean=false;
  accounts = []
  id_banco_selected: any;


  constructor(
    private dialog: MatDialog,
    fb: FormBuilder,
    public settings: SettingsService, 
    public snackBar: MatSnackBar) { 

  }

  ngOnInit() {
    this.get_detalle()
  }

  ELEMENT_DATA_GIRO = [];
  displayedColumnsGiro: string[] = ['cuentas','acreedorName','bankName','CuentaRut','CuentaName','TipoCuenta','totalGiro', 'status'];
  dataSourceGiro:any = new MatTableDataSource<any>(this.ELEMENT_DATA_GIRO);
  ELEMENT_DATA: Facturas[]=[];
  displayedColumns: string[] = ['folio','acreedorName','bankName','CuentaRut','CuentaName','TipoCuenta','totalGiro', 'status'];
  dataSource = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  url:any;
  total_tabla=0;
  async get_detalle() {
    try {

      this.cargando = true;

      const bd: any = await this.settings.get_query(1,  'ComprasPagoAcreedor/' + this.simulation_id + '/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          this.simulation = body_data.simulation;
          this.ELEMENT_DATA_GIRO = body_data.giro;
          if (body_data.giro[0].cuentas.length == 0) {
            this.has_accounts = false;        
          } else {
            this.has_accounts = true;  
            this.accounts = body_data.giro[0].cuentas
          }
          this.id_banco_selected = body_data.giro[0].has_account; 
          console.log(this.id_banco_selected)
          

          this.ELEMENT_DATA = body_data.facturas;
          this.url = body_data.csv;

          let suma=0;
          for(let i in body_data.facturas){
            suma = suma + body_data.facturas[i].mnt_total
          }

          this.total_tabla = suma;


          setTimeout(() => {

  
            this.dataSource=new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;

            this.dataSourceGiro=new MatTableDataSource(this.ELEMENT_DATA_GIRO);
            this.dataSourceGiro._updateChangeSubscription();
            // this.dataSourceGiro.paginator = this.paginatorGiro;
  
          });

          this.cargando = false;


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando = false;
    }
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

  download_csv(){
      window.open(this.url, "blank")
  }

  open_compras(){
    let dialogRef = this.dialog.open(EditarFacturasCompraComponent, {
      width: '1300px', 
    });
    dialogRef.componentInstance.id_simulacion = this.simulation_id;
    dialogRef.afterClosed().subscribe((result:any) => {
        if(result == true){
          this.get_detalle()
        }
    });
  }


  async eliminar(id_compras:any) {
    try {
      const bd: any = await this.settings.get_query(4, 'RecalculateCompras/' + this.simulation_id + '/?compras='+id_compras);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        if (body_data.error != null) {          
          this.snackBar.open(body_data.error, "Aceptar", {
            duration: 5000,
            panelClass: ['JP-snackbar']
          });
          return false;
        } 

        this.get_detalle()     

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando = false;
    }
  }

  download_file(has_link:any){
    if (!has_link) {
      return false;
    }

    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = `${SettingsService.API_ENDPOINT}media/${has_link}`;
    link.click();
  }

  create_account(has_account:any)
  {
    let dialogRef = this.dialog.open(AddCta);
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = this.simulation.company.id;
    dialogRef.componentInstance.dialog_open = false;
    dialogRef.componentInstance.rut_acreedor = this.simulation.company.rut;
    dialogRef.componentInstance.nombre_acreedor = this.simulation.company.bussiness_name;
    dialogRef.componentInstance.is_compras = true;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        console.log(result)
      //try {
        console.log("has_account", has_account)
          if (!has_account) {
            this.change_bank(null, result.address.id)
          } else { 
            this.get_detalle();
          }       
        //} catch {

        //}
      }
    });   
  }

  async change_bank($event:any, id?:any) {
    this.cargando = true;
    let value = null;
    if (id) {
      value = id;
    } else {
      value = $event.value
    }
    let resultado: any = (await this.settings.get_query(3, 'cuentaGiroCliente/' + this.simulation_id + '/', {"cuenta": value}));
    let body_data = resultado._body;
    if (resultado) {
      this.get_detalle();
    }
  }


}


export interface Facturas{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}