import { Component, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { SettingsService } from 'src/app/settings/settings.service';


@Component({
  selector: 'app-detalle-excedentes',
  templateUrl: './detalle-excedentes.html',
  styleUrls: ['./detalle-excedentes.less'],

})

export class DetalleExcedentesModal implements OnInit {

  facturas:any;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public settings:SettingsService, private bottom:MatBottomSheet){
    this.facturas = data;
    console.log("data", this.facturas)
  }

  ngOnInit(): void {
      this.get_detalle_excedentes();
  }

  cerrar(){
    this.bottom.dismiss();
  }

  @Input() id_company:any=localStorage.getItem('company_id');
  @Input() id_factura:any;
  @Input() id_operacion:any;
  
  page:any=1;
  length:any=0;
  pageSize:any=10;
  detalle_excedentes:any[]=[];
  loading:boolean=false;
  async get_detalle_excedentes() {
    try {
      this.loading = true;

      const bd: any = await this.settings.get_query(1,'/getexcedente/' + this.id_company + '/?pageNumber=' + this.page + '&factura='+this.facturas.numero_factura+'&operacion='+this.facturas.numero_operacion+'&mobile=1');
      const body_data = bd._body;
      switch (bd.status) {
        case 200:


            this.detalle_excedentes = body_data.excedentes;
            this.loading = false;
            this.length = body_data.total;


          break;
        default:
          break;
      }
    } catch (bd: any) {
      console.log("error", bd)

    }
  }


}