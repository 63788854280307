<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>

    <h1 *ngIf="step == 1 && solicitar_solo_clave == false" style="text-align: center;" class="titulo-simpli">Ingresa la clave SII de tu empresa </h1>
    <small *ngIf="step == 1 && solicitar_solo_clave == false" style="display: block;text-align: center;" class="subtitulo-simpli">Completa este paso para evitar errores<br> de autenticación al ceder.</small>

    <h1 *ngIf="step == 2 && files.length == 0 && solicitar_solo_clave == false" style="text-align: center;" class="titulo-simpli">Ingresa las credenciales autorizadas</h1>
    <small *ngIf="step == 2 && files.length == 0 && solicitar_solo_clave == false" style="display: block;text-align: center;position: relative;" class="subtitulo-simpli">
      Agrega los datos de una persona que esté autorizada<br>a realizar la cesión con los AEC.
      <span style="margin-left: 10px;font-size: 20px;color:#924eff;" [mdePopoverTriggerFor]="appPopovertable2" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger"><i class="fa-sharp fa-light fa-shield-keyhole"></i></span>

      <mde-popover #appPopovertable2="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
        <mat-card class="popover-card" style="max-width: 370px;position: relative;">


          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="row-detail-popover" style="font-weight: bold;">Tus datos están protegidos por nuestra politica de privacidad.</div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px;margin-bottom: 10px;">
              <div class="row-detail-popover"><a style="color:white;" href="https://simplilatam.com/politica-de-privacidad/" target="blank">Ver politica de privacidad <span><i style="margin-left: 10px;" class="fa-regular fa-chevron-right"></i></span></a></div>
            </div>
          </div>


        </mat-card>
      </mde-popover>
    </small>

    <h1 *ngIf="step == 2 && files.length > 0 && solicitar_solo_clave == false" style="text-align: center;" class="titulo-simpli">Ingresa la clave del Certificado Digital </h1>
    <small *ngIf="step == 2 && files.length > 0 && solicitar_solo_clave == false" style="display: block;text-align: center;" class="subtitulo-simpli">Estas a un paso de completar la validación de indentidad.</small>

    <h1 *ngIf="solicitar_solo_clave == true && step == 0" style="text-align: center;" class="titulo-simpli">Ingresa la clave de acceso al SII</h1>
    <small *ngIf="solicitar_solo_clave == true && step == 0" style="display: block;text-align: center;" class="subtitulo-simpli">Este dato es necesario para realizar la cesión con los AEC.</small>

    <h1 *ngIf="step == 3" style="text-align: center;" class="titulo-simpli">Ingresa la clave del Certificado Digital</h1>
    <small *ngIf="step == 3" style="display: block;text-align: center;" class="subtitulo-simpli">Este dato es necesario para realizar la cesión con los AEC.</small>

    <h1 *ngIf="step == 0 && solicitar_solo_clave == false" style="text-align: center;" class="titulo-simpli">Elige cómo vas a acceder</h1>
    <small *ngIf="step == 0 && solicitar_solo_clave == false" style="display: block;text-align: center;" class="subtitulo-simpli">Puedes acceder como empresa o persona natural.</small>

  </div>
</div>



<mat-dialog-content style="min-height: 445px;">

    <!--clave sii-->
    <div style="text-align: center;" *ngIf="step == 1 && solicitar_solo_clave == false">

      <form  [formGroup]="valForm" style="margin-top: 30px;margin-bottom: 30px;">

          <div class="row">



              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">


                <mat-form-field appearance="fill"  style="position: relative;">
                  <mat-label>Clave SII</mat-label>
                  <input formControlName="sii_pass" matInput type="password" required >
                  <mat-hint>Ingresa la clave de acceso al SII (clave tributaria) <strong>de la empresa.</strong></mat-hint>
                  <mat-error *ngIf="valForm.controls['sii_pass'].hasError('incorrect')">
                      La clave ingresada no es válida.
                  </mat-error>
                  <mat-error *ngIf="valForm.controls['sii_pass'].hasError('bloqueada')">
                      Clave SII bloqueada.
                </mat-error>
                  <mat-error *ngIf="valForm.controls['sii_pass'].hasError('required')">
                      Clave SII es requerida.
                  </mat-error>

                  <span *ngIf="loading_sii == false" matSuffix class="icon-credencial" [mdePopoverTriggerFor]="appPopovertable" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger">
                    <i class="fa-sharp fa-light fa-shield-keyhole"></i>
                  </span>

                  <span *ngIf="loading_sii == true" matSuffix class="icon-credencial">
                    <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                  </span>
                </mat-form-field>

                <mde-popover #appPopovertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                  <mat-card class="popover-card" style="max-width: 370px;position: relative;">


                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row-detail-popover" style="font-weight: bold;">Tus datos están protegidos por nuestra politica de privacidad.</div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px;margin-bottom: 10px;">
                        <div class="row-detail-popover"><a style="color:white;" href="https://simplilatam.com/politica-de-privacidad/" target="blank">Ver politica de privacidad <span><i style="margin-left: 10px;" class="fa-regular fa-chevron-right"></i></span></a></div>
                      </div>
                    </div>


                  </mat-card>
                </mde-popover>

              </div>



          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <div class="btn-simpli-container" style="margin-top: 140px;">
                <button [disabled]="loading_sii" class="primary-button" mat-flat-button (click)="post_credenciales($event, valForm.value)">
                  <span *ngIf="!loading_sii">Continuar</span>
                  <span *ngIf="loading_sii"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                </button>

                <button class="secondary-button" mat-flat-button mat-dialog-close >
                  Volver
                </button>
              </div>

            </div>
          </div>



      </form>

    </div>

    <!--certificado-->
    <div style="text-align: center;" *ngIf="step == 2 && solicitar_solo_clave == false">

      <form  [formGroup]="valFormCertificado" style="margin-top: 30px;margin-bottom: 30px;">

        <div class="row">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

            <mat-form-field color="primary" appearance="fill" style="position: relative;">
              <mat-label>9999999-K</mat-label>
              <input required matInput formControlName="rut_autorizado" (keyup)="rutKey($event)"  type="text">
              <mat-hint>Ingresa el RUT de la persona autorizada por la empresa</mat-hint>
              <mat-error *ngIf="valFormCertificado.controls['rut_autorizado'].hasError('incorrect')">
                  Rut autorizado es inválido.
              </mat-error>
              <mat-error *ngIf="valFormCertificado.controls['rut_autorizado'].hasError('required')">
                  Rut autorizado requerido.
              </mat-error>
              <span matSuffix class="icon-credencial">
                <i class="fa-light fa-circle-info"></i>
              </span>
            </mat-form-field>

          </div>


            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

              <mat-form-field color="primary" appearance="fill" style="position: relative;">
                <mat-label>Agregar Certificado Digital</mat-label>
                <input required matInput formControlName="file" readonly (click)="triger_click()" style="cursor: pointer;" >
                <mat-hint>Solo se admite el formato ".pfx" y un peso menor a "50MB".</mat-hint>
                <mat-error *ngIf="valFormCertificado.controls['file'].hasError('incorrect')">
                    El Certificado Digital es inválido.
                </mat-error>
                <mat-error *ngIf="valFormCertificado.controls['file'].hasError('required')">
                    El Certificado Digital es requerido.
                </mat-error>
                <span *ngIf="files.length == 0" matSuffix class="icon-credencial">
                  <i class="fa-light fa-paperclip-vertical"></i>
                </span>
                <span style="cursor: pointer;" (click)="deleteFile()" *ngIf="files.length > 0" matSuffix class="icon-credencial">
                  <i class="fa-light fa-trash-can"></i>
                </span>
              </mat-form-field>

              <input type="file" accept=".pfx" (change)="handleFiles($event.target.files)" style="display: none;" id="fileInput2" #fileInput2 />

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="files.length > 0">


              <mat-form-field appearance="fill"  style="position: relative;">
                <mat-label>Clave Certificado Digital</mat-label>
                <input formControlName="cert_pass" matInput type="password" required >
                <mat-hint>Ingresa la clave de acceso al SII (clave tributaria)</mat-hint>
                <mat-error *ngIf="valFormCertificado.controls['cert_pass'].hasError('incorrect')">
                    La clave ingresada no es válida.
                </mat-error>
                <mat-error *ngIf="valFormCertificado.controls['cert_pass'].hasError('required')">
                    La Clave del Certificado Digital es requerida.
                </mat-error>

                <span matSuffix class="icon-credencial" [mdePopoverTriggerFor]="appPopovertable3" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger">
                  <i class="fa-sharp fa-light fa-shield-keyhole"></i>
                </span>

              </mat-form-field>

              <mde-popover #appPopovertable3="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                <mat-card class="popover-card" style="max-width: 370px;position: relative;">


                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="row-detail-popover" style="font-weight: bold;">Tus datos están protegidos por nuestra politica de privacidad.</div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px;margin-bottom: 10px;">
                      <div class="row-detail-popover"><a style="color:white;" href="https://simplilatam.com/politica-de-privacidad/" target="blank">Ver politica de privacidad <span><i style="margin-left: 10px;" class="fa-regular fa-chevron-right"></i></span></a></div>
                    </div>
                  </div>


                </mat-card>
              </mde-popover>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="files.length > 0">

              <div style="color: #924eff;margin-top: 15px;cursor: pointer;">
                <span style="color:#924eff;margin-right: 10px;"><i class="fa-regular fa-circle-info"></i></span>
                <span [mdePopoverTriggerFor]="appPopovertable6" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger">¿Cómo recuperar la clave del Certificado?</span>
                <mde-popover #appPopovertable6="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                  <mat-card class="popover-card" style="max-width: 370px;position: relative;">


                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row-detail-popover">Solicita la ayuda de tu contador para acceder <br> a ella o adquiere un nuevo certificado.</div>
                      </div>
                    </div>


                  </mat-card>
                </mde-popover>
              </div>

              <section class="example-section" style="margin-top: 20px;">
                <mat-checkbox color="primary" class="example-margin" formControlName="need_saved">
                  Autorizo la <span style="color: #924eff;text-decoration: underline;" (click)="open_sincronizacion($event)">sincronización de credenciales</span>
                </mat-checkbox>
              </section>
            </div>



        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="primary-button" mat-flat-button *ngIf="files.length == 0"  style="background: #adadad !important;color:white;border: solid 1px gray;">
                Adjuntar Certificado
              </button>

              <button [disabled]="loading_upload" (click)="post_certificado($event, valFormCertificado.value)" class="primary-button" mat-flat-button  *ngIf="files.length > 0" >
                <span *ngIf="!loading_upload">Generar AEC</span>
                <span *ngIf="loading_upload"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
              </button>
            </div>

          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="files.length == 0">
            <a style="color:#924eff;text-decoration: underline;cursor: pointer;" (click)="que_es_un_adc()">Prefiero cargar los AEC desde mi equipo</a>
          </div>
        </div>



      </form>

    </div>

    <!--solo clave sii-->
    <div style="text-align: center;" *ngIf="solicitar_solo_clave == true || step == 0">

      <form  [formGroup]="valFormClave" style="margin-top: 30px;margin-bottom: 30px;min-height: 330px;">

          <div class="row">


              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">


                <mat-form-field appearance="fill"  style="position: relative;">
                  <mat-label>Clave SII</mat-label>
                  <input formControlName="sii_pass" matInput type="password" required >
                  <mat-hint>Ingresa la clave de acceso al SII (clave tributaria)</mat-hint>
                  <mat-error *ngIf="valFormClave.controls['sii_pass'].hasError('incorrect')">
                      La clave ingresada no es válida.
                  </mat-error>
                  <mat-error *ngIf="valFormClave.controls['sii_pass'].hasError('bloqueada')">
                      Clave SII bloqueada.
                </mat-error>
                  <mat-error *ngIf="valFormClave.controls['sii_pass'].hasError('required')">
                      Clave SII es requerida.
                  </mat-error>

                  <span *ngIf="loading_sii == false" matSuffix class="icon-credencial" [mdePopoverTriggerFor]="appPopovertable" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger">
                    <i class="fa-sharp fa-light fa-shield-keyhole"></i>
                  </span>

                  <span *ngIf="loading_sii == true" matSuffix class="icon-credencial">
                    <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                  </span>
                </mat-form-field>

                <mde-popover #appPopovertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                  <mat-card class="popover-card" style="max-width: 370px;position: relative;">


                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row-detail-popover" style="font-weight: bold;">Tus datos están protegidos por nuestra politica de privacidad.</div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px;margin-bottom: 10px;">
                        <div class="row-detail-popover"><a style="color:white;" href="https://simplilatam.com/politica-de-privacidad/" target="blank">Ver politica de privacidad <span><i style="margin-left: 10px;" class="fa-regular fa-chevron-right"></i></span></a></div>
                      </div>
                    </div>


                  </mat-card>
                </mde-popover>

              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 40px;">
                <section class="example-section" style="margin-top: 20px;">
                  <mat-checkbox color="primary" class="example-margin" formControlName="need_saved">
                    Autorizo la <span style="color: #924eff;text-decoration: underline;" (click)="open_sincronizacion($event)">sincronización de credenciales</span>
                  </mat-checkbox>
                </section>
              </div>


          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <div class="btn-simpli-container" style="margin-top: 80px;">
                <button [disabled]="loading_sii" class="primary-button" mat-flat-button (click)="post_credenciales_only_pass($event, valFormClave.value)">
                  <span *ngIf="!loading_sii">Continuar</span>
                  <span *ngIf="loading_sii"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                </button>

                <button class="secondary-button" mat-flat-button mat-dialog-close >
                  Volver
                </button>
              </div>

            </div>
          </div>



      </form>

    </div>

    <!--solo clave certificado-->
    <div style="text-align: center;" *ngIf="step == 3">

      <form  [formGroup]="valFormClaveCert" style="margin-top: 30px;margin-bottom: 30px;min-height: 330px;">

          <div class="row">


            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

              <mat-form-field appearance="fill"  style="position: relative;">
                <mat-label>Clave Certificado Digital</mat-label>
                <input formControlName="cert_pass" matInput type="password" required >
                <mat-hint>Ingresa la clave de acceso al SII (clave tributaria)</mat-hint>
                <mat-error *ngIf="valFormClaveCert.controls['cert_pass'].hasError('incorrect')">
                    La clave ingresada no es válida.
                </mat-error>
                <mat-error *ngIf="valFormClaveCert.controls['cert_pass'].hasError('required')">
                    La Clave del Certificado Digital es requerida.
                </mat-error>

                <span matSuffix class="icon-credencial" [mdePopoverTriggerFor]="appPopovertable7" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger">
                  <i class="fa-sharp fa-light fa-shield-keyhole"></i>
                </span>

              </mat-form-field>

              <mde-popover #appPopovertable7="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                <mat-card class="popover-card" style="max-width: 370px;position: relative;">


                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="row-detail-popover" style="font-weight: bold;">Tus datos están protegidos por nuestra politica de privacidad.</div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px;margin-bottom: 10px;">
                      <div class="row-detail-popover"><a style="color:white;" href="https://simplilatam.com/politica-de-privacidad/" target="blank">Ver politica de privacidad <span><i style="margin-left: 10px;" class="fa-regular fa-chevron-right"></i></span></a></div>
                    </div>
                  </div>


                </mat-card>
              </mde-popover>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 40px;">
              <section class="example-section" style="margin-top: 20px;">
                <mat-checkbox color="primary" class="example-margin" formControlName="need_saved">
                  Autorizo la <span style="color: #924eff;text-decoration: underline;" (click)="open_sincronizacion($event)">sincronización de credenciales</span>
                </mat-checkbox>
              </section>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

              <div style="color: #924eff;margin-top: 15px;cursor: pointer;">
                <span style="color:#924eff;margin-right: 10px;"><i class="fa-regular fa-circle-info"></i></span>
                <span [mdePopoverTriggerFor]="appPopovertable6" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger">¿Cómo recuperar la clave del Certificado?</span>
                <mde-popover #appPopovertable6="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                  <mat-card class="popover-card" style="max-width: 370px;position: relative;">


                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row-detail-popover">Solicita la ayuda de tu contador para acceder <br> a ella o adquiere un nuevo certificado.</div>
                      </div>
                    </div>


                  </mat-card>
                </mde-popover>
              </div>

            </div>


          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <div class="btn-simpli-container" style="margin-top: 80px;">
                <button [disabled]="loading_pass_cert" class="primary-button" mat-flat-button (click)="post_credenciales_only_pass_cert($event, valFormClaveCert.value)">
                  <span *ngIf="!loading_pass_cert">Generar AEC</span>
                  <span *ngIf="loading_pass_cert"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                </button>

                <button class="secondary-button" mat-flat-button mat-dialog-close >
                  Volver
                </button>
              </div>

            </div>
          </div>



      </form>

    </div>



</mat-dialog-content>
