import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { EditarFacturasCompraComponent } from 'src/app/home/operaciones-proveedores/editar-facturas-compra/editar-facturas-compra.component';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.less']
})
export class ComprasComponent {
  @Input() id:any;
  @Input() done:any;
  @Input() clicked:any;
  simulation_id:any;
  is_not_done:any;
  cargando:boolean=true;
  simulation: any;
  @Output() edited = new EventEmitter<any>();

  ELEMENT_DATA_COMPRAS: FacturasCompra[]=[];
  displayedColumnsCompras: string[] = ['folio','acreedorName','bankName','totalGiro', 'status'];
  dataSourceCompras = new MatTableDataSource<FacturasCompra>(this.ELEMENT_DATA_COMPRAS);
  @ViewChild(MatPaginator) paginator_compras!: MatPaginator;
  url:any;
  total_tabla=0;

  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  totalCompras = 0;

  valForm: FormGroup;
  filter = '';

  constructor(    
    private dialog: MatDialog,
    public settings: SettingsService, 
    public snackBar: MatSnackBar,
    fb: FormBuilder) { 

      this.valForm = fb.group({
        'filter': ['']
      });
  

  }

  ngOnChanges(changes: SimpleChanges) {
    const changedProp = changes['clicked'];
    const to = JSON.stringify(changedProp.currentValue);    
    if (to === 'true') {        
      this.simulation_id = this.id;
      this.is_not_done = this.done;
      this.get_detalle(false)  
    } 
  }

  async get_detalle(update:any) {
    this.cargando = true;
    let resultado: any = (await this.settings.get_query(1,'ComprasPagoAcreedor/' + this.simulation_id + '/?pageNumber=' + this.page + '&accion=2'+'&filter='+this.filter));
    let body_data = resultado._body;
    if (resultado) {
      this.ELEMENT_DATA_COMPRAS = body_data.facturas;
      this.totalCompras = body_data.totalCompras;
      this.length = body_data.total;
      setTimeout(() => {
        this.dataSourceCompras=new MatTableDataSource(this.ELEMENT_DATA_COMPRAS);
        this.dataSourceCompras._updateChangeSubscription();
        // this.dataSourceCompras.paginator = this.paginator_compras;
      });
      this.cargando = false;
      if (update) {
        this.edit();
      }
    } 
  }

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.get_detalle(false);
  }

  filtrar(){
    this.filter = this.valForm.controls['filter'].value;
    this.paginator_compras.firstPage();
    this.get_detalle(false)
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

  open_compras(){
    let dialogRef = this.dialog.open(EditarFacturasCompraComponent, {
      width: '1400px', 
    });
    dialogRef.componentInstance.id_simulacion = this.simulation_id;
    dialogRef.afterClosed().subscribe((result:any) => {
        if(result == true){
          this.get_detalle(true);
        }
    });
  }

  async eliminar_compras(id_compras:any){
    this.cargando = true;
    let resultado: any = (await this.settings.get_query(4,'RecalculateCompras/' + this.simulation_id + '/?compras='+id_compras));
    let body_data = resultado._body;
    if (resultado) {
      if (body_data.error != null) {          
        // this.snackBar.open(body_data.error, "Aceptar", {
        //   duration: 5000,
        //   panelClass: ['JP-snackbar']
        // });
        this.open_input_dialog("Error", "", "¡Oops! "+body_data.error,"","info","#FA454C");
        this.cargando = false;
        return false;
      } 
      this.get_detalle(true);
    }
  }

  download_file(has_link:any){
    if (!has_link) {
      return false;
    }

    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = `${SettingsService.API_ENDPOINT}media/${has_link}`;
    link.click();
  }

  //DIALOG INPUT
  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

    console.log("se abrio dialog")
    const dialogRef = this.dialog.open(InputDialog,{
      width:'700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
  
  
    dialogRef.afterClosed().subscribe((result:any) => {
      // this.load_table()
    });
  
  }

  edit() {
    this.edited.emit({edited: true});
  }
}

export interface FacturasCompra {  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}
