import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-tabla-detalle',
  templateUrl: './tabla-detalle.component.html',
  styleUrls: ['./tabla-detalle.component.less'],
})

export class TablaDetalleComponent implements OnInit, OnChanges {
  
  @Input() tableDataCesiones: [] = [];
  @Input() showSpinner:any;
  @Input() filterValue:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public displayedColumns: string[] = [];
  public noData: boolean=false;
  public dataSource:any;
  public facturas:any;
  public selectedValue:any;
  public totalDeudaNeta:any;
  public factura = new FormControl('');
  filteredOptions!: Observable<string[]>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['tableDataCesiones'] !== undefined) {
        this.getChangesInTable(changes['tableDataCesiones'].currentValue);
      }
      if(changes['filterValue'] !== undefined) {
        this.applyFilter(changes['filterValue'].currentValue)
      }
  }

  ngOnInit() {
  }

  getChangesInTable(data:any) {
    if (data === undefined) return;
    if (data.length > 0) {
      this.getDisplayedColumns(data);
      this.getUniqueFromArray(data);
      this.totalDeudaNeta = this.getTotalCost(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    } else {
      this.noData = true;
    }
  }

  getTotalCost(data:any) {
    return data
      .map((detail:any) => Number(detail.monto_total))
      .reduce((acc:any, cv:any) => acc + cv, 0);
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) {}
  }

  getUniqueFromArray(list:any) {
    const onlyNames = list.map((u:any) => u.nombre_cedente);
    const unique = [];
    for (let i of onlyNames) {
      if (unique.indexOf(i) === -1) {
        unique.push(i);
      }
    }
    this.facturas = unique;
  }

  applyFilter(filterValue: string) {
    if(this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  getDisplayedColumns(dataTable:any) {
    const headers = Object.keys(dataTable[0]);
    this.displayedColumns = headers;
  }
}
