import { Component, Input } from '@angular/core';

@Component({
  selector: 'sin-registros',
  templateUrl: './sin-registros.html',
  styleUrls: ['./sin-registros.scss']
})
export class SinRegistros {

  @Input() spin: boolean = true;
  @Input() text: string = "No hay información";

  constructor() { }

}
