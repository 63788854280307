import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Meta } from "@angular/platform-browser";
import { NavigationExtras, Router } from "@angular/router";
import { SettingsService } from "../settings/settings.service";
import { register } from 'swiper/element/bundle';
import { InfoModalOnboarding } from "./info-modal/info-modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
register();

declare var $: any;

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.html',
  styleUrls: ['./onboarding.less']
})
export class OnBoardingComponent implements OnInit {


  valForm: FormGroup;
  valFormFacturador:FormGroup;

  business_name:any=localStorage.getItem('business_name')

  constructor(private _formBuilder: FormBuilder, public settings: SettingsService, public dialog: MatDialog, public route:Router ) { 
    
    this.valForm = this._formBuilder.group({
      'clave_sii': ['',  Validators.compose([Validators.required])],
    });

    this.valFormFacturador = this._formBuilder.group({
      'facturador': ['',  Validators.compose([Validators.required])],
      'otro': [''],
    });

  }

  hide: boolean = true;
  step:any=1;
  id_company=localStorage.getItem('company_id');
  ngOnInit() {

    this.get_facturadores();

  }

  open_modal_info(step:any) {
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.step = step;

  }
  

  navegar_a(url:string){
    window.open(url, "_blank")
  }


  loading:boolean=false;
  pasar_al_paso_2(){
    this.loading = true;
    setTimeout(() => {
      this.loading = false;

      this.step = 3;
    }, 1500);

  }

  facturador_seleccionado:any=null;
  selecionar_facturador($event:any){
    const value = Number($event.value);
    this.facturador_seleccionado = value;
  }

  disable_btn:boolean=true;
  validate_key($event:any){
    if($event.target.value != undefined && $event.target.value != null && $event.target.value != ""){
      this.disable_btn = false;
    }else{
      this.disable_btn = true;
    }
  }

  loading_sii:boolean=false;
  success_sii:boolean=false;
  async verificar_guardar_clave_sii(validate?:any){
    try {


      let value:any = {"sii_key": this.valForm.controls['clave_sii'].value}
      if (validate) {
        value["validate"] = true;
      }
  
      this.loading_sii = true;
      this.disable_btn = true;
      this.success_sii = false;


      const bd: any = await this.settings.get_query(3, `cliente/validate-clave-sii/${this.id_company}/`,value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          this.disable_btn = false;
          this.loading_sii = false;
          this.success_sii = true;




        break;
        default:

        break;
      }

    } catch (bd:any) {



      this.valForm.controls['clave_sii'].setErrors({'incorrect': true});
      this.disable_btn = false;
      this.loading_sii = false;
      this.success_sii = true;

    }
  }

  facturadores:any[]=[];
  async get_facturadores(){
    try {



      const bd: any = await this.settings.get_query(1, `cliente/facturador-electronico/`, );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.facturadores = body_data.success.info;
        console.log(this.facturadores)


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  name_facturador_otro:string="";
  disabled_facturador:boolean=false;
  async put_facturador(){
    try {
      this.disabled_facturador = true;
      this.loading = true;
      let value: any = { 'facturador_id': this.facturador_seleccionado };
  
      if (this.facturador_seleccionado == 7) {
        value['facturador_otro'] = this.valFormFacturador.controls['otro'].value;
      }

      const bd: any = await this.settings.get_query(3, `cliente/facturador-electronico-individual/${this.id_company}/`, value);
      const body_data: any = bd._body;
  

      switch (bd.status) {
        case 200:
        
          //AQUI VAS A MOSTRAR EL PASO 4
          this.step = 4;
          this.disabled_facturador = false;
          this.loading = false;

          break;
  

      }
  
    } catch (bd: any) {

      this.disabled_facturador = false;
      this.loading = false;

    }
  }

  omitir(){
    this.route.navigate(['/home/onboarding/omitir']);
  }

}