<div mat-dialog-title>
    <h1 class="titulo-simpli">Mi Dicom</h1>
    <small *ngIf="diasRestantes <= 0" class="subtitulo-simpli">Revisa el estado de tu Dicom mensual</small>
    <small *ngIf="diasRestantes > 0">{{ diasRestantes > 0 ? 'Días restantes para tu proxima consulta.' : ''}}</small>
</div>

<div mat-dialog-content>



    <div class="row" *ngIf="dataSource.data.length == 0 && loading == false">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

          <div class="empty-msg">
        
                
            <div style="padding-top: 50px;text-align: center;">
              <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
            </div>
      
            <div style="padding-top: 50px;text-align: center;">
              <mat-label class="label-bienvenida">
                <div class="label-empty">
                    ¿No encontraste la información que buscabas?
                </div>
      
                <div class="label-empty" style="margin-top: 20px;">
                    Contáctanos al +56 9 3467 7786 y gestionaremos tu requerimiento a la brevedad.
                </div>
      
              </mat-label>
            </div>
      
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
                <div class="btn-simpli-container">
                  <button class="primary-button" (click)="contactar()" mat-flat-button>Contactar</button>
                </div>
                
              </div>
            </div>
      
          </div>

        </div>

    </div>

    <div class="row animate__animated animate__fadeIn" *ngIf="dataSource.data.length == 0 && loading == true">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="sinRegistros" style="text-align: center;">
                <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                  <div style="text-align: center;">
                    <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                </div>
                </small>
            </div>
        </div>

    </div>


    <div class="row" *ngIf="dataSource.data.length > 0 && loading == false;">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

            <div class="table-responsive-simpli" >


                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="file">
                        <th mat-header-cell *matHeaderCellDef>Archivo</th>
                        <td mat-cell *matCellDef="let element">
                            <input class="no-input" value="{{element.name}}" readonly>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="dateCreated">
                        <th mat-header-cell *matHeaderCellDef>Fecha</th>
                        <td mat-cell *matCellDef="let element">
                            <input class="no-input" value="{{element.date}}" readonly>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Descargar</th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            <span matTooltip="Descargar" style="cursor: pointer;color:#924eff" (click)="descargar(element.link)"><i class="fa-solid fa-download"></i></span>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
        
            </div>

        </div>

    </div>


</div>

<div mat-dialog-actions style="margin-top: 10px;margin-bottom: 10px;">
    <button mat-flat-button class="secondary-button" style="margin-left: 0px;" mat-dialog-close>Cerrar</button>
    <button [disabled]="disabled" (click)="solicitarDicom()" style="margin-left: 20px;" mat-flat-button class="primary-button">
        <span *ngIf="disabled == false">Consultar Dicom</span>
        <span *ngIf="disabled == true"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span>
    </button>
</div>