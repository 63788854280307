import { Component,ViewChild, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { OnDestroy } from '@angular/core'
import { DetalleProveedores } from './detalle-proveedores/detalle-proveedores';
import { SimulationOp } from './simulation/simulation';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatAccordion } from '@angular/material/expansion';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

declare var $ :any;


@Component({
  selector: 'app-op-proveedores',
  templateUrl: './op-proveedores.html',
  styleUrls: ['./op-proveedores.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OpProveedores implements OnInit, OnDestroy {

  panelOpenState = false;
  @ViewChild('accordion') Accordion!: MatAccordion;
  element_opened:any;
  id_expanded:any;

  id_company:any;
  simulaciones:any[]=[];
  ELEMENT_DATA: Array<any> = []
  url:any;
  has_file:any
  has_simulation:boolean=true;
  show_spinner_loading:boolean=true;

  expandedElement!: Nominas | null;

  @Input() ocultar_filter:boolean=true;

  @Input() subtitle="Revisa tus simulaciones y comienza el proceso de cesión";

  @Input() titulo = "Simulaciones"
  

  displayedColumns: string[] = ['position'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  @Input() selected:any="";
  disabled:boolean=false;
  filter:any="";
  display_spinner:boolean=false;

  status = new FormControl();
  statusList: any[] = [{'value': '0', 'name':'Creada'}, {'value': '1', 'name':'En proceso'}, {'value': '2', 'name':'Girada'}, {'value': '', 'name':'Limpiar'}];
  value_status_filter: string = "";

  constructor(fb: FormBuilder,public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService){ 

      this.url = SettingsService.API_ENDPOINT + '/media/simulaciones/'
    }

  myObserver:any;

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngOnInit() {
    this.myObserver = this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
      this.load_all(false)
      };
    });
    this.load_all(false)
  }

  status_filter(value:any) {
    this.value_status_filter = value.join()
    this.load_simulations(1, 0);
  }

  load_all(update:any)
  {
    this.id_company = localStorage.getItem("company_id")
    this.load_simulations(1, update)

  }





  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  page = 1

  //table 2.0
  ELEMENT_DATA_2: Nominas[]=[];
  displayedColumns2: string[] = ['id','date','monto','estado','giro', 'accion'];
  dataSource2 = new MatTableDataSource<Nominas>(this.ELEMENT_DATA_2);
  @ViewChild(MatPaginator) paginator2!: MatPaginator;
  async load_simulations(page:any, update:any) {
    try {

      this.page = page
      this.has_simulation = true;
      this.show_spinner_loading = true;

      const bd: any = await this.settings.get_query(1, 'CabeceraPagoAcreedor/' +this.id_company + '/?status='+this.value_status_filter);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.simulaciones = body_data.cabecera;

        this.simulaciones= this.simulaciones.map(sim => {
          let isExpanded = false;
          let was_clicked = false;

          return { ...sim, was_clicked: was_clicked, isExpanded: isExpanded, isDisabled: false, dif_giro_pago_prov: `$ ${this.formatMoney(sim.dif_giro_pago_prov)}`}
        });


        if(body_data.cabecera.length > 0){
          this.has_simulation = false;
          this.show_spinner_loading = false;
          
        }else{
          this.show_spinner_loading = false;
          this.has_simulation = true;
        }


       this.ELEMENT_DATA = this.simulaciones

       this.display_spinner = false;

       this.ELEMENT_DATA_2 = this.simulaciones;
       this.dataSource2=new MatTableDataSource(this.ELEMENT_DATA_2);
       this.dataSource2.paginator = this.paginator2;
       this.dataSource2._updateChangeSubscription()


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  open_compras(id:any, status:any){
    let done = true;
    if (status == "2") {
      done = false;
    }
    let dialogRef = this.dialog.open(DetalleProveedores, {
      width: '1300px',
  
    });
    dialogRef.componentInstance.simulation_id = id;
    dialogRef.componentInstance.is_not_done = done;
    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_all(false)
    });
  }

  open_simulation(id:any, status:any){
    let done = true;
    if (status == "2") {
      done = false;
    }
    let dialogRef = this.dialog.open(SimulationOp, {
      width: '1300px',
  
    });
    dialogRef.componentInstance.simulation_id = id;
    dialogRef.componentInstance.is_not_done = done;
    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_all(0)
    });
  }

  expand(element:any) {

    this.id_expanded = element.id;
    this.dataSource2.data.forEach(row => {      
        if (element.id != row.id) {
          row.was_clicked = false;
        }
    });
    element.was_clicked = !element.was_clicked;
  }

  beforePanelClosed(panel:any){
    panel.isExpanded = false;
    console.log("Panel going to close!");
  }

  beforePanelOpened(panel:any){
    panel.isExpanded = true;
    console.log("Panel going to  open!");
  }

  afterPanelClosed(){
    console.log("Panel closed!");
  }

  afterPanelOpened(){
    console.log("Panel opened!");
  }

  closeAllPanels(){
    this.Accordion.closeAll();
  }

  openAllPanels(){
    this.Accordion.openAll();
  }

  onEditNotResponsive(was_edited: any) {
    // this.element_opened = this.dataSource2.data.filter(element => element.id == was_edited.id);
    // console.log(this.element_opened)
    // this.load_all(1);
    console.log(was_edited.giro, this.id_expanded);
    // let opened = this.dataSource2.data.filter(element => element.id == this.id_expanded);
    // opened.map(e => { return { ...e, dif_giro_pago_prov: was_edited.giro.edited}})

    let opened:any = this.dataSource2.data.find(element => element.id == this.id_expanded);
    opened.dif_giro_pago_prov = `$ ${this.formatMoney(was_edited.giro.edited)}`;

    // this.dataSource2.data = this.dataSource2.data.map(element => {
    //   let giro = element.dif_giro_pago_prov;
    //   if (element.id == this.id_expanded) {
    //     giro = was_edited.giro.edited;
    //   }
    //   return {
    //     ...element,
    //     dif_giro_pago_prov: giro
    //   }
    // });
  }

  onEditResponsive(was_edited: any) {
    // this.element_opened = this.dataSource2.data.filter(element => element.id == was_edited.id);
    // console.log(this.element_opened)
    // this.load_all(2);
  }



  open_credenciales(){
    this.router.navigate(['/', 'pago-proveedores'])
    .then(nav => {


    }, err => {
      console.log(err) // when there's an error
    });
  }

}

export interface Nominas{  
  ejecutivo: any,
  n:any,
  nomina:any,
  fecha:any,
  monto:any,
  estado:any,
  giro:any,
  was_clicked:boolean,
  id:any,
  isDisabled:boolean,
  isExpanded:boolean,
  dif_giro_pago_prov: any
}
