import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import * as Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import { FacturasVentasManuales } from '../facturas-venta/facturas-venta-manual/facturas-ventas-manuales';
import { EditarFacturasVentaComponent } from '../editar-facturas-venta/editar-facturas-venta.component';
import { MatSort } from '@angular/material/sort';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AddCta } from '../../payments/pago-seleccion/add-cta';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-facturas-compra-manual',
  templateUrl: './facturas-compra-manual.component.html',
  styleUrls: ['./facturas-compra-manual.component.less']
})
export class FacturasCompraManualComponent implements OnInit  {


  @ViewChild('uploadResultPaginator', {read: MatPaginator}) uploadResultpaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('MatSort2', {read: MatSort}) sort2!: MatSort;
  firstFormGroup: FormGroup;
  firstFormGroup2: FormGroup;


  ELEMENT_DATA: any[]=[];
  displayedColumns: string[] = ['business_name','conteo','monto','porcentaje'];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  len_info: number = 0;
  @ViewChild('paginator') paginator!: MatPaginator;

  formFechas: FormGroup;
  cargando:Boolean=true
  info:Boolean=false
  infocesiones:Boolean=false;
  clave:number=0;

  valForm :FormGroup;

  @Input() id_simulacion:any;

  constructor(public dialogRef:MatDialogRef<FacturasCompraManualComponent>,private cd: ChangeDetectorRef,public settings: SettingsService, private fb: FormBuilder, public dialog:MatDialog,public snackBar: MatSnackBar) { 
    this.formFechas = this.fb.group({
      fechaI: ['', Validators.required],
      fechaF: ['', Validators.required]
    });

    this.valForm = fb.group({
      desde: '',
      hasta: '',
      filter:'',
    });

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      checkall:false
    });

    this.firstFormGroup2 = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      checkall:false
    });

  }

  company_id:any;
  ngOnInit() {
    this.company_id = Number(localStorage.getItem("company_id"))
    this.get_simulation()
  }
  
  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  //DIALOG INPUT
  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

  console.log("se abrio dialog")
  const dialogRef = this.dialog.open(InputDialog,{
    width:'700px'
  });
  dialogRef.componentInstance.title = title;
  dialogRef.componentInstance.subtitle = subtitle;
  dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
  dialogRef.componentInstance.mensajenegrita = mensajenegrita;
  dialogRef.componentInstance.icon = icon;
  dialogRef.componentInstance.coloricon = coloricon;


  dialogRef.afterClosed().subscribe((result:any) => {
    // this.load_table()
  });

  }


  //CARGA MANUAL
  public hotRegisterer = new HotTableRegisterer();
  id = 'hotInstance';
  hotSettings: Handsontable.default.GridSettings = {
      startRows: 10,
      colHeaders: true,
      //colWidths: [150,200,150,150],
      rowHeights: [25],
      width: '100%',
      height: 'auto',
      columns: [
        {
          type: 'numeric'
        }
      ],

  }
  data: any[] = [
    [null, null,null,null,null, null]
  ]
  
  new_row(){
    this.hotRegisterer.getInstance(this.id).alter('insert_row_below', this.data.length+1)

  }

  delete_row(){
    try {
      this.hotRegisterer.getInstance(this.id).alter('remove_row', this.data.length+1)
    } catch {

    } 
  }


  ELEMENT_DATA_VISTAPREVIA: Array<any>=[];
  displayedColumnsVistaPrevia: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
  dataSourceVistaPrevia:any = new MatTableDataSource<Facturas>(this.ELEMENT_DATA_VISTAPREVIA);
  @ViewChild('VistaPrevia') vistaprevia!: MatPaginator;

  //ERROR
  ELEMENT_DATA_VISTAPREVIA_ERROR: VISTAPREVIA[]=[];
  // displayedColumnsVistaPreviaError: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
  // dataSourceVistaPreviaError = new MatTableDataSource<Facturas>(this.ELEMENT_DATA_VISTAPREVIA_ERROR);
  // @ViewChild('VistaPreviaError') vistapreviaerror: MatPaginator;

  hay_facturas_con_error:boolean=false;
  
  mostrar_resumen_facturas:boolean=false;
  async listoparaenviar() {

    this.ELEMENT_DATA_VISTAPREVIA = [];
    this.ELEMENT_DATA_VISTAPREVIA_ERROR = [];

    var index = this.data.indexOf("");

    if (~index) {
        this.data[index] = null;
    }
    this.mostrar_resumen_facturas = false;

    let valid = this.validate_cargar_manual(this.data);

    if(valid == true){

      let value = {'datosRevisar':this.data}

      try {
        const bd: any = await this.settings.get_query(2, 'ComprasVentasManuales/'+this.company_id +'/?accion=0', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
  
                if (body_data.arrayFront.length == 0){
                this.open_input_dialog("Error", "Errores", "¡Oops! No existen facturas válidas","","info","#FA454C")
                return false;
              }

              for(let i in body_data.arrayFront){
                if(body_data.arrayFront[i].errorCompras == true){
                  this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i])
                }else if(body_data.arrayFront[i].errorCompras == false){
                  this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i])
                }
              }

              if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
                this.hay_facturas_con_error = true;
              }else{
                this.hay_facturas_con_error = false;
              }


              for(let i in this.ELEMENT_DATA_VISTAPREVIA){
                this.mostrar_resumen_facturas = true;
                this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
                if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
                    this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
                }

              }

              for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
                this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
                if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
                    this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
                }

              }

              
              let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

              this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

              



              setTimeout(() => {
                this.dataSourceVistaPrevia=new MatTableDataSource(array3);
                this.dataSourceVistaPrevia.paginator = this.vistaprevia;
                this.dataSourceVistaPrevia._updateChangeSubscription();
              });

              this.open_emitir_pago_previo()
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }


  }

  async  capture_change(column:any,index:any,value:any, tipo_error:any,folio?:any) {
    try {
      let valor_enviar = {'column':column, 'value':value,  'index':index}
      const bd: any = await this.settings.get_query(3, 'ComprasVentasManuales/'+this.company_id +'/?folio='+folio+'&accion=0', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:



        if(body_data.error == false){
          if(body_data.id_date == true){
            let date = new Date(body_data.valor)
            this.ELEMENT_DATA_VISTAPREVIA[index][column] = date;
          }else{
            this.ELEMENT_DATA_VISTAPREVIA[index][column] = body_data.valor;
          }

          this.ELEMENT_DATA_VISTAPREVIA[index][tipo_error] = false;
        }

        if(this.ELEMENT_DATA_VISTAPREVIA[index]['errorCompras'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorFechaEmision'] == false && 
        this.ELEMENT_DATA_VISTAPREVIA[index]['errorFechaVencimiento'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorFolio'] == false &&
        this.ELEMENT_DATA_VISTAPREVIA[index]['errorMonto'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorRut'] == false){
          this.ELEMENT_DATA_VISTAPREVIA[index]['error'] = false;
        }

        this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  capture_date_error($e: any, index:any, column:any, tipo_error:any) {

    let value = new Date($e.value);
    let yy = value.getFullYear();
    let mm = value.getMonth()+1;
    let dd = value.getDate();
    let date = dd+'/'+mm+'/'+yy;
    this.capture_change(column,index,date, tipo_error)

  }

  capture_date_error2($e: any, index:any, column:any, tipo_error:any) {

    let value = new Date($e.value);
    let yy = value.getFullYear();
    let mm = value.getMonth()+1;
    let dd = value.getDate();
    let date = dd+'/'+mm+'/'+yy;
    this.capture_change(column,index,date, tipo_error)

  }

  no_continue:boolean=true;
  check_errors(array:any){
    this.no_continue = true;
    for(let i in array){
      if(array[i]['error'] == true){
          this.no_continue = true;
          break;
      }else{
          this.no_continue = false;
      }
    }
  }

  clone_array(arr:any)
  {
    let aa =  JSON.stringify(arr);
    return  JSON.parse(aa)
  }

  //LOGICA DE CUENTAS BANCARIAS
  facturas:any;
  loading_scrapping:boolean=true;
  esta_todo_listo:boolean=false;
  total=0;
  @ViewChild('MatPaginator2') paginator2!: MatPaginator;
  @ViewChild('MatPaginator3') paginator3!: MatPaginator;

  async scrapping() {
    try {
      this.loading_scrapping = true;

      const bd: any = await this.settings.get_query(1, 'get-libro-confirming/'+this.company_id+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.loading_scrapping = false;

        this.post_facturas()


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  ELEMENT_DATA2: Facturas2[]=[];
  dataSource2 = new MatTableDataSource<Facturas2>(this.ELEMENT_DATA2);
  dataSource3 = new MatTableDataSource<Facturas2>(this.ELEMENT_DATA2);
  displayedColumns2: string[] = ['cta','folio','receptoracreedor_name','receptoracreedor_rut','mnt_total','fecha_emision'];
  displayedColumns3: string[] = ['cta','receptoracreedor_name','receptoracreedor_rut','estado'];
  siguiente:boolean=true;

  async  post_facturas(olvidar_pasito?:any) {
    try {

            this.cargando = true;


            let value = {'facturas':this.facturas}


            const bd: any = await this.settings.get_query(2, 'EmitirPagoConfirmigAcreedores/'+this.company_id+'/?procesado=0', value);
            var body_data: any =bd._body;
            switch (bd.status) {
              case 200:

              this.ELEMENT_DATA2 = body_data.facturas;

              let suma = 0
              let valid:boolean=false;
              for(let i=0; i<body_data.facturas.length;i++){
                suma = suma + body_data.facturas[i].mnt_total;
                if(body_data.facturas[i].validBanco == true){
                  this.desactivar_siguiente = false;
                  valid = true
                  this.siguiente = false;
                }else{
                  this.desactivar_siguiente = true;
                  valid = false;
                  this.siguiente = true;
                  break;
                }
              }
              this.total = suma;

              var distinc = [], output: Array<any> = [], l = this.ELEMENT_DATA2.length, i;
              for( i=0; i<l; i++) {
                  if( distinc[this.ELEMENT_DATA2[i].ultimorutacreedor]) continue;
                  distinc[this.ELEMENT_DATA2[i].ultimorutacreedor] = true;
                  output.push(this.ELEMENT_DATA2[i]);
              }
          


              setTimeout(() => {
                this.dataSource2=new MatTableDataSource(this.ELEMENT_DATA2);
                this.dataSource2._updateChangeSubscription();
                this.dataSource2.paginator = this.paginator2;

                this.dataSource3=new MatTableDataSource(output);
                this.dataSource3._updateChangeSubscription();
                this.dataSource3.paginator = this.paginator3;

              });

              
              this.cargando = false;
              this.cargando_spinner_manual = false;
              this.no_continue = false;

              if(olvidar_pasito != true){
                $("#pasitodos").trigger('click');
              }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


  displayspinner:boolean=false;
  mostrar_descargar:boolean=false;
  url_csv:any;
  //1 para agregar facturas
  //0 para ver si tengo que agregar ventas
  deuda_total:any;
  debe_agregar_ventas:boolean=false;

  async download_csv() {
    try {

      let value = {'facturas':this.ELEMENT_DATA2}

      const bd: any = await this.settings.get_query(2, 'RecalculateCompras/' + this.id_simulacion + '/?final=0', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.deuda_total = body_data.totalDeuda;
        if(body_data.ventas == false){
          this.agregar_facturas_venta()
        }else{
          this.debe_agregar_ventas = true;

          setTimeout(() => {
            $("#elfulanito").trigger('click');
          });

        }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


  async agregar_facturas_venta() {
    try {
      let value = {'facturas':this.ELEMENT_DATA2}

      const bd: any = await this.settings.get_query(2, 'RecalculateCompras/' + this.id_simulacion + '/?final=1', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.open_input_dialog("Actualización", "La simulación fue actualizada", 
        "¡Todo listo! Se añadieron las nuevas facturas y se recalculo la simulación",
        "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Proveedores","check_circle","#3BA46F")

       this.dialogRef.close(true)
       this.esta_todo_listo = true;


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  create_account(id:any,rut:any,nombre:any) {
    let dialogRef = this.dialog.open(AddCta,{
      disableClose:true
    });
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = id;
    dialogRef.componentInstance.dialog_open = true;
    dialogRef.componentInstance.rut_acreedor = rut;
    dialogRef.componentInstance.nombre_acreedor = nombre;
    dialogRef.afterClosed().subscribe((result:any) => { 
      console.log("ADD CTA", result)

      if(result != undefined){
        this.asignar_banco(result.address.rut, result.address.id)
      }

      this.check_bank()
      
    });
  }

  seleccionar_banco($e: any, ultimorutacreedor:any){

    //this.ELEMENT_DATA[index]['id_bank'] = Number($e.target.value)
    let id_bank = Number($e.target.value)
    this.asignar_banco(ultimorutacreedor, id_bank)

  }

  bloquear_siguiente:boolean=true;
  asignar_banco(ultimorutacreedor:any, id_bank:any){


    this.ELEMENT_DATA2.forEach(item => {
      if(item.ultimorutacreedor == ultimorutacreedor){
        item.id_bank = id_bank;
      }
    })


    for(let i=0; i<this.ELEMENT_DATA2.length;i++){
      if(this.ELEMENT_DATA2[i].validBanco == true && this.ELEMENT_DATA2[i].id_bank != null){
        this.esta_todo_listo = true;
      }else{
        this.esta_todo_listo = false;
        break;
      }
      
    }
    
    console.log("assing", this.ELEMENT_DATA2)

  }

  controlar_cuentas(){
    console.log("elementdata2", this.ELEMENT_DATA2)
    this.ELEMENT_DATA2.forEach(item => {

      let id_bank = item.bancos[0].id;
      let ultimorutacreedor = item.ultimorutacreedor;
      this.asignar_banco(ultimorutacreedor, id_bank)
    })

    console.log("finish", this.ELEMENT_DATA2)

  }

  desactivar_siguiente:boolean=true;
  check_bank(){

    let valid:boolean=false;
    for(let i=0; i<this.ELEMENT_DATA2.length;i++){
      if(this.ELEMENT_DATA2[i].id_bank != null){
        this.desactivar_siguiente = false;
        valid = true;
        this.siguiente = false;
      }else{
        this.desactivar_siguiente = true;
        valid = false;
        this.siguiente = true;
        break;
      }
    }
    
  }
  siguiente_paso:boolean=true;
  check_vencimiento(){
    for(let i=0; i<this.ELEMENT_DATA2.length;i++){
      if(this.ELEMENT_DATA2[i].fecha_vencimiento != null && this.ELEMENT_DATA2[i].fecha_vencimiento != undefined){
        this.siguiente_paso = false;
      }else{
        this.siguiente_paso = true;
        break;
      }
    }

  }

  cargando_spinner_manual:boolean=false;
  open_emitir_pago(){

    var deuda=0;
    let array = [];
    for(let i in this.ELEMENT_DATA_VISTAPREVIA){
      deuda = deuda + this.ELEMENT_DATA_VISTAPREVIA[i].mnt_total;
    }
    // if (deuda < 500000){
    //   this.snackBar.open("El monto total de las facturas debe superar los $ 500.000", "Aceptar", {
    //     duration: 5000,
    //     panelClass: ['JP-snackbar']
    //   });
    //   return false;
    // }
    
    this.cargando_spinner_manual = true;
    this.no_continue = true;

    

    array = this.clone_array(this.ELEMENT_DATA_VISTAPREVIA)


    for(let i=0;i<array.length;i++){
      let fecha_emision = new Date(array[i].fecha_emision)
      if(array[i].fecha_vencimiento != null && array[i].fecha_vencimiento != undefined){
        let fecha_vencimiento = new Date(array[i].fecha_vencimiento);
        let dd = fecha_vencimiento.getDate();
        let mm = fecha_vencimiento.getMonth()+1;
        let yy = fecha_vencimiento.getFullYear();
        let new_date = dd+'-'+mm+'-'+yy
        array[i].fecha_vencimiento = new_date;
      }
      let dd = fecha_emision.getDate();
      let mm = fecha_emision.getMonth()+1;
      let yy = fecha_emision.getFullYear();
      let new_date = dd+'-'+mm+'-'+yy
      array[i].fecha_emision = new_date;
    }

    this.facturas = this.clone_array(array)
    

    this.scrapping()



  }

  open_libro_ventas(){
    const dialogRef = this.dialog.open(EditarFacturasVentaComponent, {
      width:'1300px',
      disableClose: true,
    });
    dialogRef.componentInstance.id_simulation = this.id_simulacion;
    dialogRef.componentInstance.deuda = this.total;
    dialogRef.componentInstance.deuda_total = this.deuda_total;
    dialogRef.componentInstance.simulacion_recibida = this.simulation;
    dialogRef.componentInstance.facturas_venta = this.facturas_ventas;
    dialogRef.componentInstance.facturas = this.ELEMENT_DATA2;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result==true){
        this.dialogRef.close(true)
      }
    });
  }

  open_carga_manual(){
      
    const dialogRef = this.dialog.open(FacturasVentasManuales, {
      width:'1300px',
      disableClose: true,
    });
    dialogRef.componentInstance.deuda = this.total;
    dialogRef.componentInstance.deuda_total = this.deuda_total;
    dialogRef.componentInstance.id_simulation = this.id_simulacion;
    dialogRef.componentInstance.manual_ventas = true;    
    dialogRef.componentInstance.simulacion_recibida = this.simulation;
    dialogRef.componentInstance.facturas_venta = this.facturas_ventas;
    dialogRef.componentInstance.facturas = this.ELEMENT_DATA2;
    
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result==true){
        this.dialogRef.close(true)
      }
    });
  }

  anticipo=95;
  comision=0;
  dias_promedio=30;
  gastos=0;
  interes_total=0;
  iva_operacion=0;
  monto_recibir=0;
  tasa_interes=0;
  total_2=0;
  valor_anticipo_total=0;
  loading:boolean=true;
  deuda_total_2=0;
  por_pagar=0;
  debe_pagar:boolean=false;
  simulation={'anticipo':0,'vencimiento':0,'comision':0,'gastosOperacionales':0,'iva_operaciones':0,'interesTotal':0,'liquidoRecibir':0, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':0.95,'tasa_interes':0, 'gastos':0};
  facturas_ventas:any;
  porc_anticipo=0;
  tasa_int=0.02;
  gastos_operacionales=30000;
  async get_simulation() {
    try {

      this.loading = true;

      const bd: any = await this.settings.get_query(1, 'controldtedetailsimulation/'+this.id_simulacion +'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


              this.anticipo= body_data.simulation.anticipo;
              this.comision=body_data.simulation.comision;
              this.dias_promedio=body_data.simulation.dias_promedio;
              this.gastos=body_data.simulation.gastos;
              this.interes_total=body_data.simulation.interes_total;
              this.iva_operacion=body_data.simulation.iva_operacion;
              this.monto_recibir=body_data.simulation.monto_recibir;
              this.tasa_interes=body_data.simulation.tasa_interes;
              this.total_2=body_data.simulation.total;
              this.valor_anticipo_total=body_data.simulation.valor_anticipo_total;
              this.deuda_total_2 = body_data.total_compras;
              this.porc_anticipo = (body_data.simulation.anticipo / 100);
              this.tasa_int = (body_data.simulation.tasa_interes / 100);
              this.gastos_operacionales = body_data.simulation.gastos;

              this.simulation={'anticipo':this.valor_anticipo_total,'vencimiento':this.dias_promedio,'comision':this.comision,'gastosOperacionales':this.gastos,
              'iva_operaciones':this.iva_operacion,'interesTotal':this.interes_total,'liquidoRecibir':this.monto_recibir, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':this.porc_anticipo,
              'tasa_interes':this.tasa_int, 'gastos':this.gastos_operacionales};

              this.facturas_ventas = body_data.ventas;

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loading = false;
    }
  }

  async descargar_file() {
    this.disabled_subir_file=true;    
    let resultado: any = (await this.settings.get_query(1, 'ComprasVentasManuales/' + this.company_id + '/'));
    let body_data = resultado._body;
    if (resultado) {
      window.open(body_data.nameArchivo, "blank")
      this.disabled_subir_file=false;
    }    
  }

  subir(){
    $("#file_but").trigger("click");
  }

  success:boolean=false;
  warning:boolean=false;
  disabled_subir_file:boolean=false;
  async subir_file($e: any) {

    this.success=false;
    this.warning=false;
    this.disabled_subir_file=true;
    this.cargando_spinner_manual = true;

    this.reset_tabla();

    let formData: FormData = new FormData();
    let file_input = $e.target.files;

    for(let i=0;i<file_input.length;i++){
      let name = file_input[i].name
      formData.append('cv_'+i, file_input[i], name);
    }

    formData.append("id_simulacion", this.id_simulacion)

    let resultado: any = (await this.settings.get_query(2, 'ComprasVentasManuales/' + this.company_id + '/?accion=0', formData));
    let body_data = resultado._body;
    if (resultado) {
      this.cargando_spinner_manual = false;
      try {
        if (body_data.error.length > 0) {
          this.warning = true;
          this.disabled_subir_file=false;
          $("#file_but").val("")
          // this.snackBar.open("Hay algunos errores en los registros, verifique el formato en la Planilla.", "Aceptar", {
          //   duration: 5000,
          //   panelClass: ['JP-snackbar']
          // });
          this.open_input_dialog("Error", "Errores en los registros", "¡Oops! Hay algunos errores en los registros, verifique el formato en la Planilla.","","info","#FA454C")
          return false;
        } else {
          this.success = true;
          this.reset_file()
        }
      } catch {
        this.success = true;
        this.reset_file()
      }

      if (body_data.arrayFront.length == 0) {
        this.warning = true;
        this.disabled_subir_file=false;
        $("#file_but").val("")
        return false;
      }
      

      for(let i in body_data.arrayFront){
        if(body_data.arrayFront[i].errorCompras == true){
          this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i])
        }else if(body_data.arrayFront[i].errorCompras == false){
          this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i])
        }
      }

      if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
        this.hay_facturas_con_error = true;
      }else{
        this.hay_facturas_con_error = false;
      }


      for(let i in this.ELEMENT_DATA_VISTAPREVIA){
        this.mostrar_resumen_facturas = true;
        this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
        if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
            this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
        }

      }

      for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
        this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
        if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
            this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
        }

      }

      
      let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

      this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

      



      setTimeout(() => {
        this.dataSourceVistaPrevia=new MatTableDataSource(array3);
        this.dataSourceVistaPrevia.paginator = this.vistaprevia;
        this.dataSourceVistaPrevia._updateChangeSubscription();
        this.mostrar_resumen_facturas = true;
      });

      this.open_emitir_pago_previo()
      $("#siguiente_step").trigger('click')
    }
    
    this.disabled_subir_file=false;
    $("#file_but").val("")

  }

  reset_file() {
    $("#file_but").val("")
    this.success=false;
    this.warning=false;   
    this.disabled_subir_file=false;
  }

  reset_tabla() {
    this.ELEMENT_DATA_VISTAPREVIA = []
    this.ELEMENT_DATA_VISTAPREVIA_ERROR = []

    this.dataSourceVistaPrevia=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA);
    this.dataSourceVistaPrevia.paginator = this.vistaprevia;
    this.dataSourceVistaPrevia._updateChangeSubscription();
  }

  validate_cargar_manual(data:any) {
    let valid = false;

    if (data.length == 0) {
      // this.snackBar.open("Debe agregar al menos una factura.", "Aceptar", {
      //   duration: 5000,
      //   panelClass: ['JP-snackbar']
      // });
      this.open_input_dialog("Error", "Errores", "¡Oops! Debe agregar al menos una factura","","info","#FA454C")
      return false;
    }

    let puntos = /\./gi;
    let pesos = /\$/gi;
    let spaces = /\ /gi;
    let guion = /\-/gi;
    var b = "-";
    var saltos = /(\r\n|\n|\r)/gm;
    var coma = /,/g;

    for(let i=0;i<data.length;i++){
      if(data[i][5] != null){
        data[i][5] = data[i][5].replace(saltos,"").trim()
        data[i][5] = data[i][5].replace(coma,"").trim()
        data[i][5] = data[i][5].replace(puntos,"")
        data[i][5] = data[i][5].replace(pesos,"")
        data[i][5] = data[i][5].replace(spaces,"")      
        data[i][5] = parseInt(data[i][5])
        data[i][5] = data[i][5].toString();
      }

      if(data[i][1] != null){
        data[i][1] = data[i][1].replace(saltos,"").trim()
        data[i][1] = data[i][1].replace(puntos,"")
        data[i][1] = data[i][1].replace(guion,"")
        data[i][1] = ([data[i][1].slice(0, -1), b, data[i][1].slice(-1)].join('')).toUpperCase();
      }

      if(data[i][0] != null){
        data[i][0] = data[i][0].replace(saltos,"").trim()
        data[i][0] = parseInt(data[i][0])
        data[i][0] = data[i][0].toString();
      }

      if(data[i][4] != null){
        data[i][4] =  data[i][4].replace(saltos,"").trim()
      }

      if(data[i][3] != null){
        data[i][3] =  data[i][3].replace(saltos,"").trim()
        data[i][3] = data[i][3].replace(spaces,"").trim()
      }

      if(
        data[i][0] == null 
        || data[i][1] == null 
        || data[i][3] == null 
        || data[i][5] == null
        || data[i][0] == "" 
        || data[i][1] == "" 
        || data[i][3] == "" 
        || data[i][5] == ""
        || !(Number.isInteger(parseInt(data[i][0]))) 
        || !(Number.isInteger(parseInt(data[i][5])))
        ){
        // this.snackBar.open("El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.", "Aceptar", {
        //   duration: 5000,
        //   panelClass: ['JP-snackbar']
        // });
        this.open_input_dialog("Error", "Errores", "¡Oops! El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.","","info","#FA454C")
        valid = false;
        break;
      }else{
        valid = true;
      }
    }

    return valid;
  }

  open_emitir_pago_previo() {
    var deuda=0;
    let array = [];
    for(let i in this.ELEMENT_DATA_VISTAPREVIA){
      deuda = deuda + this.ELEMENT_DATA_VISTAPREVIA[i].mnt_total;
    }

    // if (deuda < 500000){
    //   // this.snackBar.open("El monto total de las facturas debe superar los $ 500.000", "Aceptar", {
    //   //   duration: 5000,
    //   //   panelClass: ['JP-snackbar']
    //   // });
    //   this.open_input_dialog("Error", "", "¡Oops! El monto total de las facturas debe superar los $ 500.000","","info","#FA454C")
    //   return false;
    // }

    let folios_map = []
    folios_map =  this.ELEMENT_DATA_VISTAPREVIA.map((factura) => {
      return factura.folio
    });
    let folios = folios_map.join(",");
    this.aceptacionReclamo(folios);
  }

  async aceptacionReclamo(folio:any) {
    this.cargando = true;
    let resultado: any = (await this.settings.get_query(1, 'ppaceptacionyreclamo/' + this.company_id + '/?folios='+folio));
    let body_data = resultado._body;
    if (resultado) {
      let res = body_data.resultado;
      let folios_con_errores: Array<any> = []
      res.forEach((element:any) => {
        if (element.data != null) {
          if (element.data.tieneReclamos == true) {
            folios_con_errores.push(element.data.dhdrFolio);
          }
        }
      });
      if (folios_con_errores.length > 0){
        let mensaje = "Intenta continuar sin esas facturas o seleciona nuevas facturas.";              
        this.open_input_dialog("Información", "Facturas de compra", 
        "¡Oops! Las siguientes facturas fueron desmarcadas porque tienen reclamos: "+folios_con_errores.join(", "),
        mensaje,"highlight_off","#ff933b");
        
        let ids_documentos_excluir = []

        for (let index = 0; index < this.ELEMENT_DATA_VISTAPREVIA.length; index++) {
          let element = this.ELEMENT_DATA_VISTAPREVIA[index];
          if (folios_con_errores.includes(element.folio)) {
            ids_documentos_excluir.push(index);
          }
        }

        for (let index = 0; index < ids_documentos_excluir.length; index++) {
          let i = ids_documentos_excluir[index];
          this.ELEMENT_DATA_VISTAPREVIA.splice(this.ELEMENT_DATA_VISTAPREVIA.indexOf(this.ELEMENT_DATA_VISTAPREVIA[i]), 1);
          this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].errorCompras = true;
          this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(this.ELEMENT_DATA_VISTAPREVIA[i])        
        }

        if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
          this.hay_facturas_con_error = true;
        }else{
          this.hay_facturas_con_error = false;
        }


        for(let i in this.ELEMENT_DATA_VISTAPREVIA){
          this.mostrar_resumen_facturas = true;
          this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
          if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
              this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
          }    
        }

        for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
          this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
          if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
              this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
          }

        }

        let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

        this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

        setTimeout(() => {
          this.dataSourceVistaPrevia=new MatTableDataSource(array3);
          this.dataSourceVistaPrevia.paginator = this.vistaprevia;
          this.dataSourceVistaPrevia._updateChangeSubscription();
        });
      } else {
        $("#siguiente_step").trigger('click');
        this.cargando = false;
      }      
    } 
  }

}

export interface Facturas{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
}

export interface Facturas2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any,
  bancos:any;
}

export interface VISTAPREVIA{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  errorCompras:boolean,
}
