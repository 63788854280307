
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { SettingsService } from 'src/app/settings/settings.service';;
import { FacturadorExterno } from '../facturador-externo/facturador-externo';
import { QueEsunAEC } from '../que_es_un_aec/que_es_un_aec';
import { AecGeneradados } from '../aec-generados/aec-generados';
import { IMessage, WebSocketService } from 'src/app/services/websocket.service';
import { CredencialesCesion } from '../../credenciales-cesion/credenciales-cesion';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertErrorCesion } from '../alert-error-cesion/alert-error-cesion';
import { DialogoSuccess } from '../../facturas-del-sii/dialogo-success/dialogo-success';

declare var $: any;


@Component({
  selector: 'app-cargar-certificado',
  templateUrl: './cargar-certificado.html',
  styleUrls: ['./cargar-certificado.less']
})
export class CargarCertificado implements OnInit {


  @Input() id_sim: any;
  @Input() id_operation: any;
  @Input() id_company: any;
  @Input() data: any;
  @Input() es_mipyme: boolean = false;
  @Input() cantidad_facturas = 0;
  @Input() recargar_aec: boolean = false;
  @Input() has_valid_aecs: boolean = false;
  @Input() onCederByAEC: boolean = false;

  parent: any;
  step = 1;
  open_as_sim: boolean = false;

  title: string = "¡Cesión realizada con éxito!";
  subtitle: string = "Revisa el estado de esta etapa de la operación, con sus datos actualizados."

  valForm: FormGroup;

  constructor(public settings: SettingsService, private snackBar: MatSnackBar, private _formBuilder: FormBuilder, public dialog: MatDialog,
    public dialogRef: MatDialogRef<CargarCertificado>, public router: Router,
    private ws: WebSocketService) {

    this.id_company = localStorage.getItem('company_id');

    this.valForm = this._formBuilder.group({
      clave_certificado: ['', Validators.required],
      rut_autorizado: ['', Validators.required],
    });

  }

  sii_key: string = ''
  is_responsive: boolean = false;
  socketOnline: boolean = false;
  folio_generated: string = "";

  contadorRespuestas = 0;
  suscriptionEvent: any;
  ngOnInit() {


    this.contadorRespuestas = 0
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.socketOnline = this.ws.isConnected$.value
    this.ws.isConnected().subscribe((online: any) => {
      this.socketOnline = online
    })



    if (this.recargar_aec) {
      this.generar_los_aec()
    }


    this.subscribeSocketEvents()

  }


  subscribeSocketEvents() {
    this.suscriptionEvent = this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'pdf_factura_generated':

          this.contadorRespuestas++;
          console.log(`pdf_factura_generated`);
          this.incrementarProgressBar();

          break;
        case 'finish_process_aec':
          console.log(`finish_process_aec`)
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.vista_aec();


          break;
        case 'finish_process_aec_manual':
          console.log(`finish_process_aec_manual`)
          this.loading_upload = false;
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.vista_aec(true);
          break;

        case 'init_process_aec_manual':
          console.log(`init_process_aec_manual`)
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.step = 4;
          break;

        case 'invalid_permission_certf':
          console.log('credenciales pre-cargadas error certificado/clave/rut-autorizado');
          this.manageErrorCredenciales(msg)
          break;



        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this.contadorRespuestas = 0;
    this.valor_progress_bar = 0;
    this.suscriptionEvent.unsubscribe()
  }


  manageErrorCredenciales(msg: IMessage) {

    // manager para los errores de credenciales una vez tenga enrol el cliente, si no tiene enrol no haremos nada para no tener que subscribe a los eventos

    if (!this.has_enrol) {
      return
    }

    if (msg.args.error == "La clave del certificado es incorrecta") {
      console.log("cai en clave incorrecta")
    }

    if (msg.args.error == "El certificado esta vencido") {
      console.log("cai en vencido")
      this.certificado_vencido(1);
    }

    if (msg.args.error == "El usuario no representa a la empresa" || msg.args.error == "No tienes permisos como representante de la empresa") {
      this.certificado_vencido(2);
    }

    if (msg.args.error == "No tienes los permisos necesarios para firmar documentos") {
      this.certificado_vencido(3);
    }

    this.loading_upload = false;
  }


  valor_progress_bar: number = 0;
  incrementarProgressBar() {
    if (this.valor_progress_bar < 100) {
      const incremento = 100 / this.cantidad_facturas;
      this.valor_progress_bar += incremento;
    }
  }

  ver_estado() {
    this.dialogRef.close();
    this.parent.change_step('step-simpli3');
  }

  go_info() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['home/info-certificado'])
    );

    console.log("URL", url)

    window.open(url, '_blank');
  }

  // FIXME: nuevo proceso cesion
  loading: boolean = false
  async callCesionBpc() {
    try {
      this.loading = true
      const body_data: any = await this.settings.get_query(2, `simulaciones/cesion/${this.id_sim}/ceder-facturas/`)
      const body: any = body_data._body.success

      if(body.ok) {
        this.dialogRef.close('ceder_por_simpli');

        if(this.is_responsive == true){
          const dialogRef = this.dialog.open(DialogoSuccess, {
            width: '100%',
            height: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            hasBackdrop: false,
            panelClass: 'dialogo-full-screen-responsive-white',
            autoFocus: false,
            disableClose: true,
          });



          dialogRef.afterClosed().subscribe((result:any) => {

          });

        }else{

          const dialogRef = this.dialog.open(DialogoSuccess, {
            width: '650px',
            disableClose: true,
            autoFocus: false,
          });



          dialogRef.afterClosed().subscribe((result:any) => {

          });
        }

      }

      this.loading = false
    } catch (error: any) {
      this.loading = false
      const errors = error.error.error.success.errors
      throw new Error(error)
    }
  }


  ceder_loading: boolean = false;
  async cederFacturaBpcSimpli() {
    const divButton = document.getElementById('button-ceder-simpli')
    try {
      this.ceder_loading = true
      divButton?.classList.add('disabled-div-button')
      const creds = await this.validarCredenciales()
      this.change_certificado(creds.pantalla)
      this.ceder_loading = false
      divButton?.classList.remove('disabled-div-button')
    } catch (error) {
      console.error(error)
      this.ceder_loading = false
      divButton?.classList.remove('disabled-div-button')
    }
  }


  //Certificado
  change_certificado(has_valid_cred: Number) {

    if (has_valid_cred == 1) {
      this.callCesionBpc()
      return
    }

    const dialogRef = this.dialog.open(CredencialesSII, {
      width: '600px',
      autoFocus:false
    })

    dialogRef.componentInstance.id_company = this.id_company
    dialogRef.componentInstance.token = localStorage.getItem('token');
    dialogRef.componentInstance.id_sim = this.id_sim;

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
        this.validarCredenciales()
      }
    });


  }

  listo_para_ceder: boolean = false;
  async validarCredenciales() {
    try {
      const bd: any = await this.settings.get_query(1, '/cliente/cesion-comprobar/' + this.id_company + '/?simulation_id=' + this.id_sim,);
      return bd._body.success.info
    } catch (bd: any) {
      throw new Error(bd)
    }
  }


  ceder_por_otra() {
    const dialogRef = this.dialog.open(FacturadorExterno, {
      width: '600px',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.componentInstance.id_company = this.id_company
    dialogRef.componentInstance.id_sim = this.id_sim;



    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
        this.pasar_sim_a_cediendo();
      }
    });
  }

  async pasar_sim_a_cediendo() {

    try {

      const bd: any = await this.settings.get_query(3, '/cliente/simulacion-gestionar-status/' + this.id_sim + '/?status=14',);
      var body_data: any = bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          this.dialogRef.close();
          this.parent.change_step('step-simpli3');


          break;
        default:

          break;
      }

    } catch (bd: any) {

      switch (bd.status) {
        case 500:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          break;

        case 400:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          break;
        default:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });

          break;
      }
    }


  }

  generar_aec() {
    this.step = 3;
  }

  has_enrol: boolean = false;
  async validar_credenciales(manual?: any, company: number = 0) {

    this.loading_primario = true;
    try {


      const bd: any = await this.settings.get_query(1, `/cliente/cesiones/${this.id_company}/validate-enrol/?company=${company}`);
      var body_data: any = bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.has_enrol = data.has_enrol

          if (this.has_enrol == true) {
            if (this.has_valid_aecs == true) {
              this.vista_aec();
            } else {
              if (manual == "manual") {
                this.step = 5;
              } else {
                this.generar_los_aec();
              }
            }
          } else {
            if (manual == "manual") {
              if (data.has_sii_pass_company == true) {
                this.step = 5;
              } else {
                this.solicitar_credenciales('manual');
              }
            } else {
              this.solicitar_credenciales();
            }
            this.loading_primario = false;
          }


          break;
        default:

          break;
      }

    } catch (bd: any) {
      this.loading_primario = false;

    }

  }

  loading_primario: boolean = false;
  generar_aec_final() {
    this.validar_credenciales();
  }

  que_es_un_adc(params?: any) {

    if (this.is_responsive == true) {
      const dialogRef = this.dialog.open(QueEsunAEC, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });

      if (params == 1) {
        dialogRef.componentInstance.show_info = true;
        dialogRef.componentInstance.step = 3;
      }

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == true) {
          this.validar_credenciales();
        }
        if (result == 2) {
          this.cargar_aec_final()
        }
      });
    } else {
      const dialogRef = this.dialog.open(QueEsunAEC, {
        height: "calc(100% - 30px)",
        width: "calc(100% - 30px)",
        maxWidth: "100%",
        maxHeight: "100%",
        autoFocus: false,
      });

      if (params == 1) {
        dialogRef.componentInstance.show_info = true;
        dialogRef.componentInstance.step = 3;
      }

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == true) {
          this.validar_credenciales();
        }
        if (result == 2) {
          this.cargar_aec_final()
        }
      });
    }


  }

  async generar_los_aec() {

    this.loading_primario = true;
    try {


      const bd: any = await this.settings.get_query(1, `/cliente/cesiones/${this.id_company}/generate-aec/?simulation=${this.id_sim}&operation_id=${this.id_operation}`);
      var body_data: any = bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.loading_primario = false;
          this.step = 4;

          break;
        default:

          break;
      }

    } catch (bd: any) {
      this.loading_primario = false;

    }


  }


  programo_una_gestion: boolean = false;
  private rojaSubject = new BehaviorSubject<boolean>(this.programo_una_gestion);
  programo_una_gestion$ = this.rojaSubject.asObservable();

  vista_aec(manual?: any) {

    if (manual) {
      manual = true;
      this.files = [];
      $("#fileInput").val('')
    } else {
      manual = false;
    }

    if (this.is_responsive == true) {
      const dialogRef = this.dialog.open(AecGeneradados, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });

      dialogRef.componentInstance.id_sim = this.id_sim;
      dialogRef.componentInstance.id_operation = this.id_operation;
      dialogRef.componentInstance.manual = manual;
      dialogRef.componentInstance.parent = this.parent
      dialogRef.componentInstance.sii_key = this.sii_key || this.credenciales_manual.sii_pass


      dialogRef.afterClosed().subscribe((result: any) => {
        console.log("cerre", result)
        if (result == 1) {
          this.step = 3
        }

        if (result == 2) {
          this.step = 1
        }

        if (result == 3) {
          // this.step = 4;
          // this.generar_los_aec()
          this.validar_credenciales()
        }

        if (result == 5) {
          this.formData = new FormData();
          this.files = [];
          $("#fileInput").val('');
          this.step = 5
        }

        if (result == "" || result == undefined || result == null) {
          //this.step = 3;
          this.dialogRef.close()
        }

        if (result == 10) {
          this.dialogRef.close()
        }


        dialogRef.componentInstance.programo_una_gestion$.subscribe((roja: any) => {
          if (roja) {
            this.has_valid_aecs = false;
            this.loading_primario = false;
          }
        });

      });
    } else {
      const dialogRef = this.dialog.open(AecGeneradados, {
        width: '1200px',
        autoFocus: false,
      });

      dialogRef.componentInstance.id_sim = this.id_sim;
      dialogRef.componentInstance.id_operation = this.id_operation;
      dialogRef.componentInstance.manual = manual;
      dialogRef.componentInstance.parent = this.parent
      dialogRef.componentInstance.sii_key = this.sii_key || this.credenciales_manual.sii_pass

      dialogRef.afterClosed().subscribe((result: any) => {
        console.log("cerre", result)
        if (result == 1) {
          this.step = 3
        }

        if (result == 2) {
          this.step = 1
        }

        if (result == 3) {
          // this.step = 4;
          // this.generar_los_aec()
          this.validar_credenciales()
        }

        if (result == 5) {
          this.formData = new FormData();
          this.files = [];
          $("#fileInput").val('');
          this.step = 5
        }

        if (result == "" || result == undefined || result == null) {
          //this.step = 3;
          this.dialogRef.close()
        }

        if (result == 10) {
          this.dialogRef.close()
        }

        dialogRef.componentInstance.programo_una_gestion$.subscribe((roja: any) => {
          if (roja) {
            console.log("se detectaron aec eliminados")
            console.log("has_valid", this.has_valid_aecs)
            this.has_valid_aecs = false;
            this.loading_primario = false;
          }
        });
      });
    }



  }

  parsearPorcentaje(valor: number): string {
    const porcentaje = Math.min(Math.max(valor, 0), 100);
    const porcentajeRedondeado = porcentaje.toFixed(0);
    return `${porcentajeRedondeado}%`;
  }

  cargar_aec() {
    this.que_es_un_adc(1)
  }

  cargar_aec_final() {
    this.validar_credenciales('manual', 1)
  }

  get_name_url(url: any) {
    const file_name = url.substring(url.lastIndexOf('/') + 1);
    return file_name
  }

  triger_click() {
    $("#fileInput").trigger('click')
  }


  files: any[] = [];
  formData: any = new FormData();
  loading_upload: boolean = false;
  async cargar_files_aec() {
    this.loading_upload = true;
    try {

      for (let i = 0; i < this.files.length; i++) {
        this.formData.append('files', this.files[i]);
      }

      const bd: any = await this.settings.get_query(2, `/cliente/cesiones/${this.id_company}/generate-aec-manual/?simulation=${this.id_sim}&operation_id=${this.id_operation}`, this.formData);
      var body_data: any = bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:





          break;
        default:

          break;
      }

    } catch (bd: any) {
      this.loading_upload = false;

    }
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  handleDrop(event: any) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.handleFiles(files);
  }

  handleFiles(files: any) {
    if (files) {
      const filesArray = Array.from(files);

      const xmlFiles = filesArray.filter((file: any) => this.hasXmlExtension(file.name));
      this.files = [...this.files, ...xmlFiles];
    }
  }

  hasXmlExtension(fileName: string): boolean {
    const lowerCaseFileName = fileName.toLowerCase();
    return lowerCaseFileName.endsWith('.xml');
  }

  deleteFile(file: any) {
    const index = this.files.findIndex(f => f === file);

    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  credenciales_manual: any = { 'correcto': false, 'sii_pass': null, 'need_saved': false };
  solicitar_credenciales(manual?: any) {

    if (this.is_responsive == true) {
      const dialogRef = this.dialog.open(CredencialesCesion, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });

      dialogRef.componentInstance.id_sim = this.id_sim;
      dialogRef.componentInstance.id_operation = this.id_operation;
      dialogRef.componentInstance.onCederByAEC = this.onCederByAEC;
      if (manual == "manual") {
        dialogRef.componentInstance.solicitar_solo_clave = true;
      }
      dialogRef.afterClosed().subscribe((result: any) => {

        if (result == 1) {
          this.validar_credenciales('manual', 1)
        }


        //solicitar solo clave
        if (result.correcto == true) {
          this.step = 5;
          this.credenciales_manual = result;
        }

        if (result == "clave_certificado_correcta") {
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.step = 4;
        }

        if (result == "invalid_permission_certf") {
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.step = 4;
        }
        if (result.result == "correcto") {
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.sii_key = result.sii_key;
          this.step = 4;
        }

      });
    } else {
      const dialogRef = this.dialog.open(CredencialesCesion, {
        width: '710px',
        autoFocus: false,
      });

      dialogRef.componentInstance.id_sim = this.id_sim;
      dialogRef.componentInstance.id_operation = this.id_operation;
      dialogRef.componentInstance.onCederByAEC = this.onCederByAEC;
      if (manual == "manual") {
        dialogRef.componentInstance.solicitar_solo_clave = true;
      }
      dialogRef.afterClosed().subscribe((result: any) => {

        if (result == 1) {
          this.validar_credenciales('manual', 1)
        }


        if (result.correcto == true) {
          this.step = 5;
          this.credenciales_manual = result;
        }

        if (result == "clave_certificado_correcta") {
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.step = 4;
        }

        if (result == "invalid_permission_certf") {
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.step = 4;
        }

        if (result.result == "correcto") {
          this.contadorRespuestas = 0;
          this.valor_progress_bar = 0;
          this.sii_key = result.sii_key;
          this.step = 4;
        }
      });
    }




  }

  // TODO FIX ME
  certificado_vencido(param?: any) {
    const dialogRef = this.dialog.open(AlertErrorCesion, {
      width: '800px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus: false,
    });

    if (param == 1) {
      dialogRef.componentInstance.paso == 1;
      dialogRef.componentInstance.subtitle = "<strong>¡Importante!</strong> El Certificado Digital está vencido. Actualiza el archivo para continuar. Si necesitas asesoría, contacta a soporte."
    }

    if (param == 2) {
      dialogRef.componentInstance.paso == 2;
      dialogRef.componentInstance.subtitle = "<strong>¡Importante!</strong> El Certificado Digital ingresado no corresponde al RUT autorizado. Para continuar, ingresa el Certificado Digital asociado al RUT autorizado. Si necesitas asesoría, contacta a soporte."

    }

    if (param == 3) {
      dialogRef.componentInstance.paso == 3;
      dialogRef.componentInstance.subtitle = "<strong>¡Importante!</strong> No cuentas con el permiso para generar los AEC. Contacta a soporte para gestionar el caso."
    }

    if (param == 4) {
      dialogRef.componentInstance.paso = 4;
      dialogRef.componentInstance.subtitle = "<strong>¡Algo ocurrió!</strong> El sistema del SII está más lento de lo normal. Inicia el proceso otra vez y si el problema persiste, contáctanos al <strong>+56 9 3467 7786.</strong>"
    }

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.error_certificado = true;
  }



}
