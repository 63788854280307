import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'dialogo-espera',
  templateUrl: './dialogo-espera.html',
  styleUrls: ['./dialogo-espera.less']
})
export class DialogoEspera implements OnInit {

  @Input() texto: string ='Estamos armando una simulación automática con tus facturas de venta';
  id_company: any;


  constructor(private cd: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private _snackBar: MatSnackBar,
    public settings: SettingsService, private fb: FormBuilder, 
    public dialog:MatDialog, 
    public dialogRef:MatDialogRef<DialogoEspera>) { }

  ngOnInit() {
  }

  close_dialog()
  {}

}
