<div class="modal-header">
  <span class="material-icons custom-dismiss" (click)="cancel()">
      arrow_back
  </span>
  Filtrar por clientes
</div>

<div>
  <mat-form-field>
    <input placeholder="Buscar por cliente" matInput (keyup)="searchbarInput($event)">
  </mat-form-field>
</div>

<div class="ion-padding custom-internal-content">


  <mat-selection-list (selectionChange)="checkboxChange($event)" #facturas *ngIf="filteredItems.length > 0">

      <mat-list-option [selected]="isChecked(item.deudor)" *ngFor="let item of filteredItems; trackBy: trackItems" [value]="item.deudor">{{ item.deudor }}</mat-list-option>

  </mat-selection-list>
  

  <div class="total-register-label">
    <mat-label *ngIf="!alternar_msg">Hay {{items.length}} registros.</mat-label>
    <mat-label *ngIf="alternar_msg">Se encontraron {{filteredItems.length}} registros.</mat-label>
  </div>


  <sin-registros *ngIf="filteredItems.length == 0"></sin-registros>



  <div class="btn-container">
    <button mat-stroked-button  class="primary-button"  (click)="confirmChanges()">
        Confirmar
    </button>
  </div>

</div>