import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { register } from 'swiper/element/bundle';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "src/app/settings/settings.service";
import { InfoModalOnboarding } from "../info-modal/info-modal";
import { MatTableDataSource } from "@angular/material/table";
import { AgregarFacturas } from "src/app/home/cliente-new-version/agregar-facturas/agregar-facturas";
import { DetalleFacturas } from "src/app/home/cliente-new-version/detalle-facturas/detalle-facturas";
import { AlertDelete } from "src/app/home/cliente-new-version/documentacion/alert-delete/alert-delete";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AddFacturaInfo } from "src/app/home/cliente-new-version/cesion-facturador/add-factura-info/add-factura-info";
import { AgregarCtaComponent } from "./agregar-cta/agregar-cta.component";
import { DeclaracionJuradaComponent } from "./documentos/declaracion-jurada/declaracion-jurada";
import { SituacionFinancieraComponent } from "./documentos/situacion-financiera/situacion-financiera.component";
import { hasData } from "jquery";
import { VisualizarPdfComponent } from "./documentos/visualizar-pdf/visualizar-pdf.component";

register();

declare var $: any;

interface DocumentConfig {
  displayName: string;
  apiName: string;
  section: string;
  status: {
    hasData: boolean;
    missingFields?: number;
  }
}

@Component({
  selector: 'app-simulacion',
  templateUrl: './simulacion.html',
  styleUrls: ['./simulacion.less'],
})
export class SimulacionOnboarding implements OnInit {
  business_name:any=localStorage.getItem('business_name');
  id_simulation:any;
  accountData: any;
  hasAccounts: boolean | undefined;
  
  totalDocuments: number = 0;
  completedDocuments: number = 0;
  progress: number = 0;

  company_id = localStorage.getItem('company_id');
  isLoading: boolean = true;

  showFinancialStatusCard: any;

  step:any=1;
  id_company=localStorage.getItem('company_id');

  clave_unica:  any = 0;
  valFormNumeroDocumento: FormGroup;

  constructor(
    public settings: SettingsService, 
    public dialog: MatDialog, 
    public router:Router, 
    private route:ActivatedRoute, 
    private snackBar:MatSnackBar,
    fb: FormBuilder,
  ) { 
    this.route.params.subscribe((params:any) => {
      this.id_simulation = +params['id_simulation'];
    });

    this.valFormNumeroDocumento = fb.group({
      numeroDocumento: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });
  }

  ngOnInit() {
    this.get_simulation();
    this.get_facturas();
    this.load_accounts();
    this.get_ejecutivo_dinamico();

  }

  monto_simulado:any=0;
  porcentaje_financiamiento:any=0;
  monto_anticipo:any=0;
  dias_promedio:any=0;
  loading:boolean=false;

  async get_simulation(){
    try {
      this.loading = true;

      const bd: any = await this.settings.get_query(1, `/cliente/simulacion/${this.id_simulation}/`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          console.log("simulation info", body_data)

          let sim = body_data.success.info;
          this.monto_simulado = sim.total;
          this.porcentaje_financiamiento = sim.anticipo;
          this.monto_anticipo = sim.valor_anticipo_total;
          this.dias_promedio = sim.dias_promedio;

          this.loading = false;

        break;

        default:
        break;
      }

    } catch (bd:any) {
    }
  }

  detalle_factura(){
    const dialogRef = this.dialog.open(DetalleFacturas,{
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.componentInstance.id_sim = this.id_simulation;
  }

  cantidad_facturas:any=0;
  loading_cantidad:boolean=false;
  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;
  filtro:any="";
  loading_facturas:boolean=false;

  async get_facturas(){
    try {
      this.loading_facturas = true;
      this.facturas = [];

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_simulation+'/?page='+this.page+'&filtro='+this.filtro);
      var body_data: any =bd._body;
      var data = body_data.success;

      switch (bd.status) {
        case 200:
          this.cantidad_facturas = data.total;
          this.facturas = data.info;
          this.loading_facturas = false;
          
          console.log(this.facturas)

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource._updateChangeSubscription();
          });

        break;
        }
    } catch (bd:any) {

    }
  }

  applyFilter(){
    this.filtro = this.buscar.value;
    this.get_facturas();
  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_facturas();
  }

  open_modal_info(step:any) {
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.step = step;

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'add'){
        //this.open_agregar_facturas();
        this.open_agregar_facturas_dialog();
      }
    });
  }

  home(){
   this.router.navigate(['/home'])
  }

  buscar:any = new FormControl('');
  displayedColumns: string[] = ['deudor', 'factura', 'rut', 'fecha', 'monto', 'accion'];
  ELEMENT_DATA: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  facturas:any[]=[];
  comenzar_simulacion:boolean=false;

  open_agregar_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '530px',
      maxWidth: '95vw',
      disableClose: true,
    });

    dialogRef.componentInstance.agregar_onboarding = true;
    dialogRef.componentInstance.open_as_dialog = true;

    dialogRef.afterClosed().subscribe(result => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result
        console.log(this.facturas)
      }
    });
  }

  async delete_factura(id_factura:any, id_document_simulation:any){
    const elemento = this.facturas.find((el:any) => el.document.document_factura_id === id_factura);

    if (elemento) {
      elemento.isLoading = true;
    }

    try {

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-validar-delete-folio/'+this.id_simulation+'/?factura='+id_document_simulation, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
        elemento.isLoading = false;

          if(data.info == 3){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura!"
            dialogRef.componentInstance.title = "Si lo haces, será borrada de la"
            dialogRef.componentInstance.subtitle = "operación de forma definitiva."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
                if(result == true){
                  this.get_simulation();
                  this.get_facturas();
                }
            });
          }

          if(data.info == 2){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura importante!"
            dialogRef.componentInstance.title = "Si lo haces, el monto total disminuirá por debajo del mínimo"
            dialogRef.componentInstance.subtitle = "necesario para operar ($3.000.000) y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_simulation();
                this.get_facturas();
              }
            });
          }

          if(data.info == 1){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar tu única factura!"
            dialogRef.componentInstance.title = "Considera que ya no quedarán facturas "
            dialogRef.componentInstance.subtitle = "y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_simulation();
                this.get_facturas();
              }
            });
          }
        break;

        default:
        break;
      }

    } catch (bd:any) {
      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
        break;
      }
    }
  }

  async delete(id_factura:any, accion:any){
    try {
      const bd: any = await this.settings.get_query(4, '/cliente/simulacion-documentos/'+id_factura+'/?accion='+accion, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
        break;

        default:
        break;
      }

    } catch (bd:any) {

    }
  }

  open_agregar_facturas_dialog(){
    const dialogRef = this.dialog.open(AddFacturaInfo,{
      width: '600px',
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_sim = this.id_simulation;
    dialogRef.componentInstance.step = 2;
    dialogRef.componentInstance.onboarding = true;

    dialogRef.afterClosed().subscribe((result:any) => {

      if(result == true){
        this.get_facturas();
        this.get_simulation();
      }

    });
  }

  loading_pdf_:boolean=false;
  async download_pdf(){
    try {
      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-detalle-pdf/'+this.id_simulation+'/', );
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:
          window.open(data.respuesta.info, "__blank");
          this.loading_pdf_ = false;

        break;

        default:
        break;
      }

    } catch (bd:any) {
      console.log(bd)

      switch (bd.status) {
        case 500:
            this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400: 
        this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        default:
          this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
        break;
      }
    }
  }

  //Codigo de cuentas bancarias
  stepper_cuentas:any=1;
  isDragOver1 = false;
  isDragOver2 = false;
  uploadedFile1: File | null = null;
  uploadedFile2: File | null = null;
  onDragOver(event: DragEvent, zone: number): void {
    event.preventDefault();
    if (zone === 1) {
      this.isDragOver1 = true;
    } else {
      this.isDragOver2 = true;
    }
  }

  onDragLeave(zone: number): void {
    if (zone === 1) {
      this.isDragOver1 = false;
    } else {
      this.isDragOver2 = false;
    }
  }

  onFileDrop(event: DragEvent, zone: number): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.assignFile(files[0], zone);
    }
    this.onDragLeave(zone);
  }

  onFileSelect(event: Event, zone: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.assignFile(input.files[0], zone);
    }
  }

  triggerFileInput(zone: number): void {
    const fileInput = document.getElementById(`fileInput${zone}`) as HTMLElement;
    fileInput.click();
  }

  assignFile(file: File, zone: number): void {
    if (zone === 1) {
      this.uploadedFile1 = file;
    } else if (zone === 2) {
      this.uploadedFile2 = file;
    }
  }

  subir_archivos(){
    console.log(this.uploadedFile1)
    console.log(this.uploadedFile2)
  }
  
  async load_accounts() {
    this.isLoading = true;
    try {
      const body_data: any = await this.settings.get_query(1, `/cuentasAcreedoresBank/${this.id_company}/`)
      const _body = body_data._body

      this.accountData = _body.accounts && _body.accounts.length > 0 ? _body.accounts[0] : null;
      this.hasAccounts = _body.accounts && _body.accounts.length > 0;
      console.log('Account Data:', this.accountData);

      const primaryAccount = _body.accounts?.find((account: { is_primary: any; }) => account.is_primary);
      const accountIdToEdit = primaryAccount?.id;
    
    } catch (bd:any) {
      this.accountData = null; 
      this.hasAccounts = false;
    } finally {
      this.isLoading = false;
    }
  }
  
  agregarCuentaBancaria(){
    const dialogRef = this.dialog.open(AgregarCtaComponent,{
      width: '500px',
      maxWidth: '95vw',
      autoFocus:false,
      height: 'auto',
      maxHeight: '95vh',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.load_accounts();
    });
  }

  editarCuentaBancaria(id: any) {
    const primaryAccount = this.accountData;
    const dialogRef = this.dialog.open(AgregarCtaComponent, {
      width: '500px',
      maxWidth: '95vw',
      autoFocus: false,
      height: 'auto',
      maxHeight: '95vh',
      data: primaryAccount
    });

    dialogRef.afterClosed().subscribe(result => {
      this.load_accounts();
    });
  }

  readonly  document_config: { [key: string]: DocumentConfig } = {
    declaracionJurada: {
      displayName: 'Declaración jurada',
      apiName: 'declaracion_jurada',
      section: 'Documentos legales',
      status: { hasData: false, missingFields: 4 }
    },
    declaracionPep: {
      displayName: 'Declaración PEP',
      apiName: 'declaracion_PEP',
      section: 'Documentos legales',
      status: { hasData: true }
    },
    estadoSituacionFinanciera: {
      displayName: 'Estado de situación financiera',
      apiName: 'estado_situacion',
      section: 'Documentos legales',
      status: { hasData: false, missingFields: 3 }
    },
    rolAvalista: {
      displayName: 'Rol de avalista',
      apiName: 'rol_avalista',
      section: 'Documentos legales',
      status: { hasData: true }
    },
    cartaPoder: {
      displayName: 'Carta de poder',
      apiName: 'carta_poder',
      section: 'Poderes legales',
      status: { hasData: false }
    },
    poderNotarial: {
      displayName: 'Poder notarial',
      apiName: 'poder_notarial',
      section: 'Poderes legales',
      status: { hasData: false }
    },
    poderesBancarios: {
      displayName: 'Poderes bancarios',
      apiName: 'poderes_bancarios',
      section: 'Poderes legales',
      status: { hasData: false }
    },
    contrato: {
      displayName: 'Contrato',
      apiName: 'contrato',
      section: 'Contrato',
      status: { hasData: true }
    }
  };


  getStatusText(documentKey: string): string {
    const status = this.document_config[documentKey].status;
    if (status.hasData) return 'Completado';
    if (status.missingFields) {
      return status.missingFields === 1 
        ? 'Falta 1 dato'
        : `Faltan ${status.missingFields} datos`;
    }
    return 'Pendiente';
  }


  capitalizeText(text: string): string {
    return text.toLowerCase().split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }
  
  hasPendingDocuments(): boolean {
    const evaluateDocuments = [
      this.document_config["declaracionJurada"],
      this.document_config["estadoSituacionFinanciera"]
    ];
  
    const completeDocuments = evaluateDocuments.some(doc => !doc.status.hasData);
    const poderesLegalesDocuments = [
      this.document_config["cartaPoder"],
      this.document_config["poderNotarial"],
      this.document_config["poderesBancarios"]
    ];
  
    const poderesLegales = poderesLegalesDocuments.some(doc => doc.status.hasData);
    if (poderesLegales) {
      const selectedPoderLegal = poderesLegalesDocuments.find(doc => doc.status.hasData);
      return !selectedPoderLegal?.status.hasData || completeDocuments;
    }
  
    return completeDocuments;
  }

  titleText: string = 'completa la documentación legal con los datos que faltan';
  subtitleText: string = '';  

  calculateProgress() {
    const alwaysCompleted = [
      this.document_config["rolAvalista"],
      this.document_config["declaracionPep"],
      this.document_config["contrato"]
    ];

    const evaluateDocuments = [
      this.document_config["declaracionJurada"],
      this.document_config["estadoSituacionFinanciera"]
    ];

    const poderesLegalesDocuments = [
      this.document_config["cartaPoder"],
      this.document_config["poderNotarial"],
      this.document_config["poderesBancarios"]
    ].filter(doc => doc.status.hasData);

    const documentsToCount = [...alwaysCompleted, ...evaluateDocuments, ...poderesLegalesDocuments];

    this.totalDocuments = documentsToCount.length;
    this.completedDocuments = documentsToCount.filter(doc => doc.status.hasData).length;
    this.progress = Math.round((this.completedDocuments / this.totalDocuments) * 100);

    if (this.progress === 100) {
      this.titleText = 'la documentación legal que debes firmar fue autocompletada';
      this.subtitleText = 'Puedes revisar y descargar los documentos antes de firmarlos.';
    }

    console.log('Document completion status:');
    documentsToCount.forEach(doc => {
      console.log(`${doc.displayName}: ${doc.status.hasData}`);
    });
    console.log(`Total documents: ${this.totalDocuments}, Completed: ${this.completedDocuments}`);
  }

  situacionFinancieraView: any = 1;
  handleDocumentClick(documentKey: string, action: string): void {
    if (action === 'view') {
      if (documentKey === 'estadoSituacionFinanciera' && !this.document_config[documentKey].status.hasData) {
        this.step = 5;
        this.situacionFinancieraView = 1;
      } else {
        this.openDocumentDialog(documentKey);
      }
    } else if (action === 'download') {
      this.downloadDocument(documentKey);
    }
  }
  

  openDocumentDialog(documentKey: string) {
    const document = this.document_config[documentKey];
    let componentToOpen: any;
    let shouldRefresh = true;
  
    if (
      !document.status.hasData && 
      (document.displayName === 'Declaración jurada' || document.displayName === 'Estado de situación financiera')
    ) {
      componentToOpen = document.displayName === 'Declaración jurada' ? 
      DeclaracionJuradaComponent : 
      SituacionFinancieraComponent;
    } else {
      componentToOpen = VisualizarPdfComponent;
      shouldRefresh = false;
    }
  
    const dialogRef = this.dialog.open(componentToOpen, {
      width: '600px',
      maxWidth: '100vw',
      autoFocus: false,
      height: 'auto',
      maxHeight: '100vh',
      data: {
        documentType: document.displayName,
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (shouldRefresh || result === true) {
        this.getDocumentos();
      }
    });
  
    console.log('Documento abierto:', document.displayName);
  }
  

  async downloadDocument(documentKey: string) {
    this.isLoading = true;
    const document = this.document_config[documentKey];

    try {
        const response: any = await this.settings.get_query(1, `/onboarding/data-onboarding/${this.company_id}/?type_document=${document.apiName}`);
        const url = response._body.success.url;
        window.open(url, '_blank');

    } catch (error) {
      console.error('Error al descargar ', document.displayName, ' :', error);
      this.snackBar.open('Error al descargar el documento', 'X', {
          duration: 20000,
          panelClass: 'simpli-snack-error'
      });
    } finally {
        this.isLoading = false;
    }
  }

  dialogSituacionFinanciera(): void {
    this.stepper_cuentas = 2;
    this.step = 4;
    this.openDocumentDialog('estadoSituacionFinanciera');
  }
  
  

  pendingDocs: number = 0;
  async verDocumentos() {
    if (this.hasAccounts) {
      await this.getDocumentos();

      this.pendingDocs = (
        (this.document_config["declaracionJurada"].status.missingFields || 0) +
        (this.document_config["estadoSituacionFinanciera"].status.missingFields || 0)
      );
      console.log('Pending Count:', this.pendingDocs);


      if (this.pendingDocs === 0) {
        this.step = 4;
        this.stepper_cuentas = 2;
        this.getDocumentos();
      } else {
        this.step = 2;
      }
    } else {
      this.step = 4;
      this.stepper_cuentas = 2;
      this.getDocumentos();
    }
  }




  async getDocumentos() {
    this.isLoading = true;
    try {
      const response: any = await this.settings.get_query(1, `/onboarding/data-onboarding/${this.company_id}/`);
      const documentData = response._body.success.info.set_documentos;

      const set1 = documentData.set1;
      this.statusDeclaracionJurada(set1.declaracion_jurada);
      this.statusSituacionFinanciera(set1.informacion_financiera);

      const set2 = documentData.set2;
      this.document_config["cartaPoder"].status.hasData = !!set2?.carta_poder?.link;
      this.document_config["poderNotarial"].status.hasData = !!set2?.poder_notarial?.link;
      this.document_config["poderesBancarios"].status.hasData = !!set2?.poderes_bancarios?.link;

      this.calculateProgress();

    } catch (error) {
      console.error('Error al obtener documentos:', error); 

      // this.document_config["declaracionJurada"].status = {
      //   hasData: false,
      //   missingFields: 4
      // };
    
      // this.document_config["estadoSituacionFinanciera"].status = {
      //     hasData: false,
      //     missingFields: 3
      // };

      // this.calculateProgress();
    } finally {
      this.isLoading = false;
    }
  }

  statusDeclaracionJurada(declaracionJurada: any) {
    let missingCount = 0;
    const sections = ['antecedentes_declarante', 'beneficiarios_finales', 'control_efectivo', 'persona_natural'];

    const hasAntecedentes = !!(
      declaracionJurada.antecedentes_declarante?.domicilio?.direccion &&
      declaracionJurada.antecedentes_declarante?.domicilio?.comuna &&
      declaracionJurada.antecedentes_declarante?.domicilio?.ciudad &&
      declaracionJurada.antecedentes_declarante?.telefono
    );
    if (!hasAntecedentes) missingCount++;

    const hasBeneficiarios = !!(declaracionJurada.beneficiarios_finales?.length);
    if (!hasBeneficiarios) missingCount++;

    const hasControl = !!(declaracionJurada.control_efectivo?.length);
    if (!hasControl) missingCount++;

    const hasPersonaNatural = !!(declaracionJurada.persona_natural?.relacion_pj_declarante);
    if (!hasPersonaNatural) missingCount++;

    this.document_config["declaracionJurada"].status = {
      hasData: missingCount === 0,
      missingFields: missingCount
    };
  }

  statusSituacionFinanciera(situacionFinanciera: any) {
    let missingCount = 0;
    const sections = ['antecedentes_personales', 'antecedentes_conyuge', 'antecedentes_comerciales'];

    const hasAntecedentesPersonales = !!(
      situacionFinanciera.antecedentes_personales?.direccion && 
      situacionFinanciera.antecedentes_personales?.comuna && 
      situacionFinanciera.antecedentes_personales?.ciudad && 
      situacionFinanciera.antecedentes_personales?.telefono && 
      situacionFinanciera.antecedentes_personales?.profesion 
    );
    if (!hasAntecedentesPersonales) missingCount++;

    const hasAntecedentestecedentes = !!(
      situacionFinanciera.antecedentes_conyuge?.nacionalidad &&
      situacionFinanciera.antecedentes_conyuge?.profesion &&
      situacionFinanciera.antecedentes_conyuge?.empleador 
    );
    if (!hasAntecedentestecedentes) missingCount++;

    const hasAntecedentesComerciales = !!(situacionFinanciera.antecedentes_comerciales?.length);
    if (!hasAntecedentesComerciales) missingCount++;

    this.document_config["estadoSituacionFinanciera"].status = {
      hasData: missingCount === 0,
      missingFields: missingCount
    };
  }

  hasDocumentsInSection(section: string): boolean {
    return Object.values(this.document_config).some(doc => doc.section === section);
  }
  
  getDocumentsBySection(section: string): string[] {
    return Object.keys(this.document_config).filter(key => {
      const doc = this.document_config[key];
      if (doc.section === section) {
        if (section === 'Poderes legales') {
          return doc.status.hasData;
        }
        return true;
      }
      return false;
    });
  }
  
  shouldShowSection(section: string): boolean {
    if (section === 'Poderes legales') {
      const poderesLegalesDocs = [
        this.document_config["cartaPoder"],
        this.document_config["poderNotarial"],
        this.document_config["poderesBancarios"]
      ];
      return poderesLegalesDocs.some(doc => doc.status.hasData);
    }
    return true;
  }

  registrarCuenta() {
    // this.step = 4; 
    // this.stepper_cuentas = 1;

    //this.load_accounts();

    this.router.navigate(['home/']);
  }

  volverDesdeRegistro() {
    this.step = 2;
    this.pendingDocs = 0;
  }

  volverDesdeDocumentacion() {
    this.step = 2

    if(this.pendingDocs === 0) {
      this.step = 4;
      this.stepper_cuentas = 1;
      this.load_accounts()
    }
  }

  loading_name: boolean = false;
  name: string = '';
  async get_ejecutivo_dinamico() {
    this.loading_name = true;
    try {
      const bd: any = await this.settings.get_query(1, `/cliente/ejecutivo-representante-empresa/` + this.id_company + '/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:
          this.name =  body_data.success.info.nombre;
          this.loading_name  = false;
          break;

        default:
          break;
      }
    } catch (bd: any) {
    } finally {
      this.loading_name = false;
    }
  } 

  selectedFile: File | null = null;
  isFileSelected: boolean = false;  
  selectedFileName: string = '';

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/pdf' || file.type.startsWith('image/jpeg')) {
        if (file.size <= 50 * 1024 * 1024) {
          this.selectedFile = file;
          this.isFileSelected = true;
          this.selectedFileName = file.name;
        } else {
          this.snackBar.open('El archivo excede el tamaño máximo de 50MB', 'Cerrar', {
            duration: 3000,
            panelClass: 'simpli-snack-onboarding'
          });
        }
      } else {
        this.snackBar.open('Solo se admiten archivos PDF o JPG', 'Cerrar', {
          duration: 3000,
          panelClass: 'simpli-snack-onboarding'
        });
      }
    }
  }

  clearSelectedFile() {
    this.selectedFile = null;
    this.isFileSelected = false;
  }

  claveUnicaUrl: string = '';
  code: string = '';
  async loginClaveUnica() {
    try {
      this.stepper_cuentas = 3;
      this.clave_unica = 1;

      const body = {
        successRedirect: "https://www.google.com",
        failedRedirect: "https://www.esign-la.com/"
      };

      const bd: any = await this.settings.get_query(2, `/onboarding/tokens-request/`, body);
      var body_data: any = bd._body;

      if (body_data.success && body_data.success.ok === true)  {

        this.code = body_data.success.info.code;

        const authResponse: any = await this.settings.get_query(2, `/onboarding/tokens-authorize/${this.code}/`);
        const authData: any = authResponse._body;

        if (authData.success && authData.success.ok === true) {
          this.claveUnicaUrl = authData.success.info.url;
          console.log("URL de ClaveUnica:", this.claveUnicaUrl);
          

          this.autenticarConClaveUnica();


        }
      }
    } catch (bd: any) {
      console.error("Error al solicitar token:", bd);
    }
  }

  accessToken: string = '';
  async autenticarConClaveUnica() {
    try {
      const popupWindow = window.open(
        this.claveUnicaUrl,
        'ClaveUnicaWindow',
        `width=800,height=800,left=200,top=100,resizable=yes,scrollbars=yes,status=yes`
      );
      
      if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
        const snackBarRef = this.snackBar.open(
          'Para continuar, debe habilitar las ventanas emergentes en tu navegador',
          'X',
          {
            duration: 0,
            panelClass: 'simpli-snack-onboarding'
          }
        );
        
        snackBarRef.onAction().subscribe(() => {
          this.autenticarConClaveUnica();
        });
        
        return;
      }
      
      this.snackBar.dismiss();
      
      const maxAttempts = 60;
      let attempts = 0;
      
      
      const checkToken = async () => {
        try {
          const bd: any = await this.settings.get_query(1, `/onboarding/tokens-authorize/${this.code}/`);
          var body_data: any = bd._body;
          
          if (body_data?.success?.info) {
            this.accessToken = body_data.success.info.accessToken;
            
            if (this.accessToken && this.accessToken.trim() !== '') {
              console.log("Token recibido correctamente:", this.accessToken);
              
              this.challengeClaveUnica()
              
              return;
            }
          }
          
          attempts++;
          if (attempts < maxAttempts) {
            console.log(`Esperando token... Intento ${attempts}/${maxAttempts}`);
            setTimeout(checkToken, 2000);
          } else {
            console.log("Tiempo de espera agotado para ingresar su clave única.");
            this.snackBar.open('Tiempo de espera agotado. Por favor, intente nuevamente.', 'X', {
              duration: 5000,
              panelClass: 'simpli-snack-onboarding'
            });
            
            try {
              popupWindow.close();
            } catch (e) {
              console.log("No se pudo cerrar el popup automáticamente");
            }
          }
        } catch (error) {
          console.error("Error al verificar el token:", error);
          if (attempts < maxAttempts) {
            setTimeout(checkToken, 2000); 
          } else {
            this.snackBar.open('Ocurrió un error. Por favor, intente nuevamente.', 'Cerrar', {
              duration: 5000,
              panelClass: 'simpli-snack-onboarding'
            });
            
            try {
              popupWindow.close();
            } catch (e) {
              console.log("No se pudo cerrar el popup automáticamente");
            }
          }
        }
      };
      
      checkToken();
      
    } catch (error) {
      console.error("Error en autenticarConClaveUnica:", error);
      this.snackBar.open('Error al iniciar la autenticación', 'Cerrar', {
        duration: 5000,
        panelClass: 'simpli-snack-onboarding'
      });
    }
  }
  
  async challengeClaveUnica() {
    try {
      const bd: any = await this.settings.get_query(1, `/onboarding/challenge-generar-token/`);
      var body_data: any = bd._body;

      // if (body_data?.success?.info) {
      //   const token = body_data.success.info.token;
      // }

    } catch (error) {
      
    }
  }
  
  async startChallenge() {
    try {
      const body = {
        rut: "26959883-2",
        numeroDocumento: this.valFormNumeroDocumento.value.numeroDocumento,
        nombre: "Maria Carmen de los Angeles",
        apellidoPaterno: "Del rio",
        apellidoMaterno: "Gonzalez",
        email: "maria.delrio@gmail.com",
        fechaNacimiento: "1990-01-01",
        estadoCivil: "Soltero",
        telefono: "987654321",
        token: "1234567890",
      }

      const bd: any = await this.settings.get_query(1, `/onboarding/challenge-ingreso-validacion/`, body);
      var body_data: any = bd._body;
    } catch (error) {
      
    }
  }

  
  preventSpaces(event: KeyboardEvent): void {
    if (event.key === ' ' || event.code === 'Space') {
      event.preventDefault();
    }
  }
  

  questionData = {
    "preguntas": [
      {
        "id": "1",
        "descripcion": "¿En qué mes nació su padre?",
        "alternativas": [
          {
            "id": "1",
            "descripcion": "JULIO"
          },
          {
            "id": "2",
            "descripcion": "FEBRERO"
          },
          {
            "id": "3",
            "descripcion": "AGOSTO"
          },
          {
            "id": "4",
            "descripcion": "DICIEMBRE"
          },
          {
            "id": "5",
            "descripcion": "NINGUNA DE LAS ANTERIORES"
          }
        ]
      },
      {
        "id": "2",
        "descripcion": "¿En cuál de las siguientes regiones cursó Educación básica o media el año 2007?",
        "alternativas": [
          {
            "id": "1",
            "descripcion": "DE LA ARAUCANIA"
          },
          {
            "id": "2",
            "descripcion": "METROPOLITANA DE SANTIAGO"
          },
          {
            "id": "3",
            "descripcion": "DE VALPARAISO"
          },
          {
            "id": "4",
            "descripcion": "DE MAGALLANES Y ANTARTICA CHILENA"
          },
          {
            "id": "5",
            "descripcion": "NINGUNA DE LAS ANTERIORES"
          }
        ]
      },
      {
        "id": "3",
        "descripcion": "¿Cuál es el segundo apellido de tu abuelo paterno?",
        "alternativas": [
          {
            "id": "1",
            "descripcion": "RIVERA"
          },
          {
            "id": "2",
            "descripcion": "VIDAL"
          },
          {
            "id": "3",
            "descripcion": "PIÑERA"
          },
          {
            "id": "4",
            "descripcion": "GONZALEZ"
          },
          {
            "id": "5",
            "descripcion": "NINGUNA DE LAS ANTERIORES"
          }
        ]
      },
      {
        "id": "4",
        "descripcion": "¿En qué ciudad naciste?",
        "alternativas": [
          {
            "id": "1",
            "descripcion": "SANTIAGO"
          },
          {
            "id": "2",
            "descripcion": "VALPARAISO"
          },
          {
            "id": "3",
            "descripcion": "CONCEPCION"
          },
          {
            "id": "4",
            "descripcion": "TEMUCO"
          },
          {
            "id": "5",
            "descripcion": "NINGUNA DE LAS ANTERIORES"
          }
        ]
      }
    ]
  };

  selectedRadioValue: number | null = null;
  currentQuestion: number = 0;
  selectedAnswers: {[key: string]: string} = {};

  parseInt(value: string): number {
    return parseInt(value);
  }

  onRadioChange(questionId: string, answerId: string): void {
    this.selectedAnswers[questionId] = answerId;
    this.selectedRadioValue = parseInt(answerId);
  }

  isAnswerSelected(): boolean {
    const currentQuestionId = this.questionData.preguntas[this.currentQuestion].id;
    return !!this.selectedAnswers[currentQuestionId];
  }

  getFormattedAnswers() {
    const respuestas = Object.keys(this.selectedAnswers).map(questionId => {
      return {
        "pregunta": parseInt(questionId),
        "respuesta": parseInt(this.selectedAnswers[questionId])
      };
    });
    
    return { "respuestas": respuestas };
  }

  nextQuestion() {
    if (this.currentQuestion < this.questionData.preguntas.length - 1) {
      // Guardar la selección actual antes de avanzar
      const currentQuestionId = this.questionData.preguntas[this.currentQuestion].id;
      if (this.selectedRadioValue !== null) {
        this.selectedAnswers[currentQuestionId] = this.selectedRadioValue.toString();
      }
      
      // Avanzar a la siguiente pregunta
      this.currentQuestion++;
      
      // Reiniciar la selección de radio para la nueva pregunta
      this.selectedRadioValue = null;
      
      // Si hay una respuesta previamente guardada para esta pregunta, restaurarla
      const nextQuestionId = this.questionData.preguntas[this.currentQuestion].id;
      if (this.selectedAnswers[nextQuestionId]) {
        this.selectedRadioValue = parseInt(this.selectedAnswers[nextQuestionId]);
      }
    } else {
      // Todas las preguntas respondidas, proceder con el envío de respuestas
      // Guardar la respuesta de la última pregunta
      const currentQuestionId = this.questionData.preguntas[this.currentQuestion].id;
      if (this.selectedRadioValue !== null) {
        this.selectedAnswers[currentQuestionId] = this.selectedRadioValue.toString();
      }
      
      const answersData = this.getFormattedAnswers();
      console.log('Enviando respuestas al backend:', answersData);
      // Implementar la llamada a la API aquí
      this.stepper_cuentas = 3;
      this.clave_unica = 3;
    }
  }
  
  previousQuestion() {
    if (this.currentQuestion > 0) {
      this.currentQuestion--;
      // Restaurar la selección anterior para la interfaz
      const questionId = this.questionData.preguntas[this.currentQuestion].id;
      this.selectedRadioValue = this.selectedAnswers[questionId] ?
        parseInt(this.selectedAnswers[questionId]) : null;
    } else {
      this.selectedRadioValue = null;
      this.selectedAnswers = {};
      this.currentQuestion = 0;
  
      // Navegar al paso anterior
      this.stepper_cuentas = 3;
      this.clave_unica = 1;
    }
  }

  capitalizeTextQuestion(text: string): string {
    const lowercaseWords = ['e', 'y', 'de', 'la', 'el', 'los', 'las', 'del', 'al', 'a', 'con', 'en', 'por', 'para', 'su', 'sus'];
    
    return text.toLowerCase().split(' ').map((word, index) => {
      if (lowercaseWords.includes(word) && index !== 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }
  totalQuestions(): number {
    return this.questionData.preguntas.length;
  }

  progressSteps(): number[] {
    return Array(this.questionData.preguntas.length).fill(0);
  }

  // geolocalizacion
  isGeolocationEnabled: boolean = false;
  userLocation: any = null;

  onGeolocationToggle(event: any): void {
    if (this.isGeolocationEnabled) {
      this.getGeolocation();
    } else {
      console.log('Geolocalización desactivada');
      this.userLocation = null;
    }
  }

  getGeolocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          console.log('Geolocalización activada:', this.userLocation);
          this.getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.error('Error al obtener la ubicación:', error);
          
        }
      );
    } else {
      this.isGeolocationEnabled = false;
    }
  }

  getAddressFromCoordinates(latitude: number, longitude: number): void {
    console.log(`Coordenadas: ${latitude}, ${longitude}`);
  }
}