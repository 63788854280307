import {Component,ChangeDetectionStrategy,Input, Output,EventEmitter, OnInit} from '@angular/core';


@Component({
  selector: 'numbered-pagination',
  templateUrl: './custom-paginator.html',
  styleUrls: ['./custom-paginator.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberedPaginationComponent implements OnInit {
  maxPages: number = 0;

  @Input() index: number = 1;
  @Input() totalCount:any;
  @Input() pageSize: any;
  @Input() rulerLength: number = 5;

  @Output() page: EventEmitter<number> = new EventEmitter<number>();

  @Input() aling_right:boolean=false;

  constructor() {


  }

  ngOnInit(){

 
    this.maxPages = Math.ceil(this.totalCount / this.pageSize);

  }

  ngOnChanges(){
    this.maxPages = Math.ceil(this.totalCount / this.pageSize);

  }

  get pagination(): NumberedPagination {
    const { index, maxPages, rulerLength } = this;
    const pages = ruler(index, maxPages, rulerLength);
    return { index, maxPages, pages } as NumberedPagination;
  }

  disable:boolean=false;
  navigateToPage(pageNumber: number): void {
    if (allowNavigation(pageNumber, this.index, this.maxPages)) {
      this.index = pageNumber;
      this.page.emit(this.index);
    }else{
      console.log(allowNavigation(pageNumber, this.index, this.maxPages))
    }
  }

  allowNavigation2 = (
    pageNumber: number,
    index: number,
    maxPages: number
  ): boolean => {
    return pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
  };

  trackByFn(index: number): number {
    return index;
  }
}

const ruler = (currentIndex: number,maxPages: number,rulerLength: number): number[] => {
  const array = new Array(rulerLength).fill(null);
  const min = Math.floor(rulerLength / 2);

  return array.map((_, index) =>
    rulerFactory(currentIndex, index, min, maxPages, rulerLength)
  );
};

const rulerOption = (
  currentIndex: number,
  min: number,
  maxPages: number
): RulerFactoryOption => {
  return currentIndex <= min
    ? RulerFactoryOption.Start
    : currentIndex >= maxPages - min
    ? RulerFactoryOption.End
    : RulerFactoryOption.Default;
};

const rulerFactory = (
  currentIndex: number,
  index: number,
  min: number,
  maxPages: number,
  rulerLength: number
): number => {
  const factory = {
    [RulerFactoryOption.Start]: () => index,
    [RulerFactoryOption.End]: () => maxPages - rulerLength + index,
    [RulerFactoryOption.Default]: () => currentIndex + index - min,
  };

  // Add 1 to the result to start numbering from 1
  return factory[rulerOption(currentIndex, min, maxPages)]() + 1;
};

const allowNavigation = (
  pageNumber: number,
  index: number,
  maxPages: number
): boolean => {
  return pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
};


export interface NumberedPagination {
  index: number;
  maxPages: number;
  pages: number[];
}


export enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT',
}
