<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" (click)="close()">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>


<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color8)" class="fa-regular fa-circle-exclamation"></i>
        </div>

        <div class="label-dialog">¡Algo ocurrió! Es posible que el archivo este vació o</div>
        <div class="label-dialog">presente errores en los datos. Para continuar, revisa la</div>
        <div class="label-dialog">nómina, corrige la información y vuelve a subir el archivo.</div>



        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button (click)="close()">
                  Volver a subir nómina
                </button>
              </div>
              
            </div>
          </div>

    </div>


    
</mat-dialog-content>