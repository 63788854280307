<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  *ngIf="responsive">
  <mat-card class="mat-card-estado">
    <span>
      <!-- Estado: <strong style="color: #ffffff;">{{estado_text}}</strong>.  -->
      {{descripcion_estado}}.
    </span>
  </mat-card>
</div>
<div class="row">
  
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="responsive">                    
    <div class="row row-menu-pp">
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right" (click)="previous()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </div>
      <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-4 col-menu-pp': true, 'col-menu-pp-active': show_giro}" (click)="tabs(1)">
        <p class="icon-menu-pp"><mat-icon class="example-tab-icon">account_balance_wallet</mat-icon></p>
        <p class="text-menu-pp">Giro</p>
      </div>
      <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-4 col-menu-pp': true, 'col-menu-pp-active': show_sim}" (click)="tabs(2)">
        <p class="icon-menu-pp"><mat-icon class="example-tab-icon">list</mat-icon></p>
        <p class="text-menu-pp">Simulacion</p>
      </div>
      <!-- <div [ngClass]="{'col-lg-2 col-md-2 col-sm-2 col-xs-2 col-menu-pp': true, 'col-menu-pp-active': show_ventas}" (click)="tabs(3)">
        <p class="icon-menu-pp"><mat-icon class="example-tab-icon">receipt</mat-icon></p>
        <p class="text-menu-pp">Ventas</p>
      </div>
      <div [ngClass]="{'col-lg-2 col-md-2 col-sm-2 col-xs-2 col-menu-pp': true, 'col-menu-pp-active': show_compras}" (click)="tabs(4)">
        <p class="icon-menu-pp"><mat-icon class="example-tab-icon">shopping_cart</mat-icon></p>
        <p class="text-menu-pp">Compras</p>
      </div> -->
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left" (click)="next()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <mat-card class="mat-card-simulacion add-factoring" *ngIf="show_sim  || show_giro">
          <mat-card-content>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  *ngIf="!responsive">
                <mat-card class="mat-card-estado">
                  <span>
                    <!-- Estado: <strong style="color: #ffffff;">{{estado_text}}</strong>.  -->
                    {{descripcion_estado}}.
                  </span>
                </mat-card>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="show_sim  || !responsive">                    
                <app-simulacion [id]="id" [done]="done" [clicked]="clicked" [change_giro]="change_giro"></app-simulacion>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="show_giro || !responsive">                    
                <app-giro [id]="id" [done]="done" [clicked]="clicked" [change_giro]="change_giro" (edited)="onEditGiro($event)"></app-giro>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngIf="show_ventas  || !responsive">                    
        <app-ventas [id]="id" [done]="done" [clicked]="clicked" (edited)="onEdit($event)"></app-ventas>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="show_compras  || !responsive">                    
        <app-compras [id]="id" [done]="done" [clicked]="clicked" (edited)="onEdit($event)"></app-compras>
      </div> -->
    </div>
  </div>

</div>