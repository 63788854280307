<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color7)" class="fa-light fa-circle-check"></i>
        </div>

        <div class="label-dialog">¡Documentos cargados! Ahora revisa el estado de</div>
        <div class="label-dialog">tu simulación, con sus datos actualizados.</div>


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button mat-dialog-close>
                  Ver estado
                </button>
              </div>
              
            </div>
          </div>

    </div>


    
</mat-dialog-content>