<div *ngIf="isLoading" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoading">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
      <div class="titulo-onboarding">
        <span *ngIf="documentType !== 'Estado de situación financiera' && documentType !== 'Declaración jurada'">{{documentType}}</span>
        <span *ngIf="documentType === 'Estado de situación financiera' || documentType === 'Declaración jurada'">confirmar los datos del {{documentType}}</span>
      </div>
    </div>
  </div>

  <mat-dialog-content [style.height]="(documentType === 'Estado de situación financiera' || documentType === 'Declaración jurada') ? 'calc(80vh - 180px)' : 'calc(90vh - 180px)'">
    <div class="row" style="height: 100%;">
      <iframe [src]="pdfUrl" width="100%" height="100%" frameborder="0" style="cursor: default;" (load)="onIframeLoad()">
      </iframe>


    </div>
  </mat-dialog-content>
  

  <mat-dialog-actions style="display: flex; flex-direction: column; align-items: stretch; padding-top: 5px !important;">

    <div style="display: flex; flex-direction: column; width: 100%;">
      <div class="btn-container" *ngIf="documentType == 'Estado de situación financiera' || documentType == 'Declaración jurada'">
        <button type="button" mat-stroked-button class="secondary-button" color="primary" (click)="openDocumentDialog(documentType)">Actualizar datos</button>
        <button type="button" mat-stroked-button class="primary-button" color="primary">Confirmar datos</button>
      </div>

      <div *ngIf="documentType === 'Estado de situación financiera' || documentType === 'Declaración jurada'; else defaultContact" style="margin-top: 10px; margin-bottom: 20px;">
        <contact-container></contact-container>
      </div>
      
      <ng-template #defaultContact style="margin-top: 0px !important; display: block;">
        <contact-container [tittle]="'Si necesitas actualizar los datos, contacta a un ejecutivo.'"></contact-container>
      </ng-template>

    </div>
  </mat-dialog-actions>
</div>
