<div class="row row_main" style="margin-bottom: 45%;">

  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Operacion Remuneraciones</h1>
    <small class="subtitulo-simpli">Administra y gestiona tus operaciones de remuneraciones</small>

    <div class="row">
      
      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12" >
        <mat-form-field appearance="fill">
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
          <span class="material-icons" matSuffix style="color: gray;;">
            search
          </span>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-top">

    <mat-card style="margin-top: 20px;margin-right: 10px;">
      <mat-card-content>

          <button mat-menu-item routerLink="/home/operaciones">
              <i class="fa-light fa-money-bill-transfer icon-simpli"></i>
                <span style="color: var(--simpli-color3);">Operaciones Factoring</span>
          </button>

          <button mat-menu-item routerLink="/home/operaciones-proveedores" matTooltip="Operaciones Pago Proveedores">
              <i class="fa-light fa-money-bill-trend-up icon-simpli"></i>
              <span style="color: var(--simpli-color3);">Operaciones Proveedores</span>
          </button>

          <button mat-menu-item style="background: rgba(0,0,0,.04);pointer-events: none;" matTooltip="Operaciones Pago de Remuneraciones">
            <i class="fa-light fa-money-bill-wave icon-simpli"></i>
            <span style="color: var(--simpli-color3);">Operaciones Remuneraciones</span>
        </button>




      </mat-card-content>
  </mat-card>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn add_factoring">

    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div class="sinRegistros" *ngIf="this.has_simulation && show_spinner_loading == false">
          <small class="small-sinregistros" style="text-align: center;margin-top: 40px;padding-top: 40px;padding-bottom: 40px;">
            <div style="text-align: center;margin-bottom: 20px;">
                <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 30px;"></i>
            </div>
            Todavía no hay historial de remuneraciones. Simula un pago de remuneraciones  y simplifica tus finanzas. <br>
            <strong>Pagar Remuneraciones</strong>              
          <br />
          <br />
          <div style="text-align: center;">
            <button  mat-flat-button class="primary-button" (click)="open_credenciales()" type="button">Vamos</button>
          </div>
         
        </small>
        </div>

      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top cajaGrande animate__animated animate__fadeIn"
        *ngIf="show_spinner_loading == true && has_simulation">

        <small class="textoCajaGrande" style="font-size: 1.2em;text-align: center;">
          <mat-spinner class="spinner-simpli" diameter="30"></mat-spinner>
          <!-- <p>Cargando información</p> -->
        </small>
      </div>

      <div *ngIf="!this.has_simulation && show_spinner_loading == false"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">


        <mat-card class=" add_factoring" style="overflow: auto;">
          <mat-card-header>
            <mat-card-title></mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>



            <table mat-table [dataSource]="dataSource2" style="width: 100%;">

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="text-center"> N° </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">


                  <input class="no-input text-center" value="{{element.id}}" readonly>


                </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha Solicitud </th>
                <td mat-cell *matCellDef="let element" style="min-width: 100px;text-align: center;">
                  <input class="no-input text-center" value="{{element.date | date:'dd/MM/yy' }}" readonly>
                </td>
              </ng-container>

              <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Monto </th>
                <td mat-cell *matCellDef="let element" class="text-center" style="min-width: 110px;">
                  <input class="no-input text-center" value="$ {{formatMoney(element.monto)}}" readonly>
                </td>
              </ng-container>


              <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Estado </th>
                <td mat-cell *matCellDef="let element" style="text-align: center !important;min-width: 130px;">

                  <input readonly class="no-input text-center" style="color: #bdbdbd;" value="Solicitada" *ngIf="element.status == 0">
                  <input readonly class="no-input text-center" style="color: #95a5ef;" value="En proceso"
                    *ngIf="element.status == 1">
                  <input readonly class="no-input text-center" style="color: #3BA46F;" value="Girada" *ngIf="element.status == 2">
                </td>
              </ng-container>

              <ng-container matColumnDef="giro">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Giro a tu cuenta </th>
                <td mat-cell *matCellDef="let element" style="min-width: 110px;">
                  <input class="no-input text-center" value="$ {{formatMoney(element.dif_giro_pago_prov)}}"
                    matTooltip="Agregar cuenta bancaria" readonly>

                </td>
              </ng-container>

              <ng-container matColumnDef="accion">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Acción </th>
                <td mat-cell *matCellDef="let element" style="min-width: 100px;text-align: center !important;">

                  <span (click)="open_simulation(element.id, element.status)" matTooltip="{{ element.status == 2 ? 'Ver Simulación' : 'Ver o editar Simulación' }}" >
                    <i style="font-size: 18px;" [ngClass]="{'fa-light': true, 'fa-money-check-dollar-pen':true,'ver-sim-active': element.status != 3, 'ver-sim': element.status == 3}"></i>
                  </span>


                  <span (click)="open_nomina(element.id, element.status)"  matTooltip="{{ element.status == 2 ? 'Ver Nomina' : 'Ver o editar Nomina' }}">
                    <i style="font-size: 18px;" [ngClass]="{'fa-light':true, 'fa-money-check-pen':true,'ver-com-active': element.status != 3, 'ver-com': element.status == 3}"></i>
                  </span>



                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="text-center"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumns2" class="text-center"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No hay registros.</td>
              </tr>
            </table>





          </mat-card-content>

        </mat-card>



      </div>

    </div>



    <mat-paginator style="background: none;" [pageSizeOptions]="[20, 50, 100, 500]"></mat-paginator>




  </div>
</div>