import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AgregarFacturas } from '../agregar-facturas/agregar-facturas';
import { AlertErrorUpload } from './alert-error-upload/alert-error-upload';
import { AlertError } from './alert-error/alert-error';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

declare var $: any;


@Component({
  selector: 'app-agregar-facturas-nomina',
  templateUrl: './agregar-facturas-nomina.html',
  styleUrls: ['./agregar-facturas-nomina.less']
})
export class AgregarFacturasNomina implements OnInit {


  @Input() id_company:any;
  @Input() open_as_dialog:boolean=false;
  @Input() onboarding:boolean=false;
  reemitida_mode:boolean=false;
  simulacion_id:any;

  constructor(private changeDetectorRef: ChangeDetectorRef,public _MatPaginatorIntl: MatPaginatorIntl, public settings: SettingsService, 
    public dialog: MatDialog,public fb:FormBuilder, public snackBar:MatSnackBar, private router:Router, 
    public dialogRef:MatDialogRef<AgregarFacturasNomina> ) {



    this.id_company = localStorage.getItem('company_id');



   }



  is_responsive: boolean = false;
  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this._MatPaginatorIntl.firstPageLabel = 'primera página';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Facturas por página';
    this._MatPaginatorIntl.lastPageLabel = 'ultima página';
    this._MatPaginatorIntl.nextPageLabel = 'siguiente';
    this._MatPaginatorIntl.previousPageLabel = 'anterior'; 

  }

  step:any=1;


  change_step(step_name:string){
    $(".stepper-simpli").removeClass('stepper-simpli-active');
    $("#"+step_name).addClass('stepper-simpli-active');

    switch(step_name) { 
      case 'step-simpli1': { 
         this.step = 1;

          break; 
      } 
      case 'step-simpli2': { 
        this.step = 2;
         break; 
      } 
      case 'step-simpli3': { 
        this.step = 3;
          break; 
     } 
      default: { 
        this.step = 1;
          break; 
      } 
   } 
  }

  async download_nomina(){
          //puede continuar
          try {

    
            const bd: any = await this.settings.get_query(1, '/cliente/descargar-excel-simulacion/');
            var body_data: any =bd._body;
            var data = body_data.success;
            switch (bd.status) {
              case 200:

  
                  window.open(data.info, "_blank")

    
              break;
              default:
    
              break;
            }
      
          } catch (bd:any) {
    
            switch (bd.status) {
              case 500:
                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });

                break;
    
              case 400: 
                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });
                break;
              default:
                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });
    
              break;
            }
          }
  }

  file_name="";
  empty_facturas:boolean=false;
  facturas:any[]=[];
  monto_total=0;
  formData:any;
  async upload_file($e:any){

    let formData: FormData = new FormData();
    let file_input = $e.target.files;

    for(let i=0;i<file_input.length;i++){
      let name =  file_input[i].name
      formData.append('file_'+i, file_input[i], name);
      this.file_name = name;
    }

    this.formData = formData;

  }

  subir_momina(){
    $("#upload_files").trigger('click');
  }

  delete_nomina(){
    $("#upload_files").val('')
    this.file_name = "";
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  //FACTURAS
  delete_factura(folio:any){

    const obje = this.facturas.find((x: any) => x.folio == folio);

    if (obje) {
      this.facturas.splice(this.facturas.indexOf(obje), 1);

      let suma=0;
      for(let i in this.facturas){
        suma = suma + Number(this.facturas[i].monto);
      }

      this.monto_total = suma;

      if(this.facturas.length == 0){
        this.empty_facturas = true;
      }else{
        this.empty_facturas = false;
      }

      for(let i in this.facturas){
        if(this.facturas[i].errorReemitida != null){
          this.error_reemitida = true;
          break;
        }else{
          this.error_reemitida = false;
        }
      }

    }

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.facturas);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();

      this.obs = this.dataSource.connect();
      this.changeDetectorRef.detectChanges();
    });

  }

  has_error:boolean=false;
  loading:boolean=false;
  async pasar_a_revision(){

    try {

      this.loading = true;

      let value = {'facturas': this.formData}

      let endpoint = '/cliente/documentos-simulacion-manual/'+this.id_company+'/';

      if(this.simulacion_id != undefined && this.simulacion_id != '' && this.simulacion_id != null){
        endpoint = '/cliente/documentos-simulacion-manual/'+this.id_company+'/?simulacion_id='+this.simulacion_id
      }

      const bd: any = await this.settings.get_query(2, endpoint, this.formData);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:




            //mas de 7 facturas malas
            if(data.message == "nomina_error"){

              $("#upload_files").val('')
              this.file_name = "";
              
              const dialogRef = this.dialog.open(AlertErrorUpload,{
                width: '600px',
                disableClose: true,
                autoFocus: false,
              });

              dialogRef.componentInstance.link = data.info;

              dialogRef.afterClosed().subscribe((result:any) => {
                if(result == true){
                  this.change_step('step-simpli2');
                  setTimeout(() => {
                    this.subir_momina();
                  });
          
                }
              });
              this.loading = false;

            }else{

              this.facturas = data.info;
              this.facturas.sort(this.dynamicSort("count_errors"));
  
              this.facturas = this.facturas.reverse();
  
  
              if(this.facturas.length == 0){
                this.empty_facturas = true;
              }else{
                this.empty_facturas = false;
              }
  
  
              this.dialogRef.updateSize('700px');
              this.step = 3;
          
              this.check_error();
              this.loading = false;
  
              setTimeout(() => {
                this.dataSource = new MatTableDataSource(this.facturas);
                this.dataSource.paginator = this.paginator;
                this.dataSource._updateChangeSubscription();
          
                this.obs = this.dataSource.connect();
                this.changeDetectorRef.detectChanges();
              });

            }



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          this.loading = false;
          break;

        case 400: 
              const dialogRef = this.dialog.open(AlertError,{
                width: '800px',
                disableClose: true,
                autoFocus: false,
              });

              dialogRef.afterClosed().subscribe((result:any) => {
                if(result == true){
                  $("#upload_files").val('')
                  this.file_name = "";
                }
              });
              this.loading = false;
          break;
        default:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          this.loading = false;

        break;
      }
    }




  }

  error_reemitida:boolean=false;
  check_error(){
    let error=false;
    for(let i in this.facturas){
      if(this.facturas[i].errorFechaEmision != null || this.facturas[i].errorFolio != null || 
        this.facturas[i].errorMonto != null || this.facturas[i].errorRut != null){
          error = true;
          break;
        }else{
          error = false
        }
    }
    this.has_error = error;

    if(this.has_error == false){
      let suma=0;
      for(let i in this.facturas){
        suma = suma + Number(this.facturas[i].monto);
      }

      this.monto_total = suma;
    }

    for(let i in this.facturas){
      if(this.facturas[i].errorReemitida != null){
        this.error_reemitida = true;
        break;
      }else{
        this.error_reemitida = false;
      }
    }


  }

  add_mas_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '530px',
      maxWidth: '95vw',
      disableClose: true,
    });
    dialogRef.componentInstance.facturas = this.facturas;
    dialogRef.componentInstance.se_abrio_desde_carga_masiva = true;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.facturas = result
      }
    });
  }

  go_to_simular(){
    // const options = {skipLocationChange: true,queryParams: {facturas: JSON.stringify(this.facturas)}};
    // this.router.navigate(["/home/comenzar-simulacion"], options);

    this.dialogRef.close(this.facturas)
  }

  rut_key($e:any, index:any){
    let rut = $e.target.value.split('.').join("");

    let st = rut.replace("-", "");
    let arr = st.split("");
    let st_out = "";

    for (let i=0; i < arr.length; i++)
    {
      st_out = st_out + arr[i];

      if (i == arr.length - 2)
      {
        st_out = st_out + "-"
      }
    }

    rut = st_out;

    $("#rut_"+index).val(rut);

    this.facturas[index]['rut'] = rut;

  }

  change_monto($e:any, index:any){
    let m =$e.target.value.replace(/\./g,'')
    let monto = Number(m);

    this.facturas[index]['monto'] = monto;
    //$("#monto_"+index).val(this.formatMoney(monto))
  }

  change_folio($e:any, index:any){
    let m =$e.target.value.replace(/\./g,'')
    let folio = Number(m);
    this.facturas[index]['folio'] = folio;
    $("#folio_"+index).val(folio)
  }

  fecha_individual_emision($e: any, index: any) {
    var date = $e.value;
    let d_o_w = date.getDay();
    if (d_o_w == 0 || d_o_w == 6)
      date = new Date(
        date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7))
      );

    let date_emision = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    this.facturas[index]['fecha_emision'] = date_emision;
    $("#fecha_"+index).val(date_emision)
  }

  montoformat:any;
  format_monto($event:any, index:any){
    this.montoformat = $event.target.value
    if(this.montoformat == "" || this.montoformat == null || this.montoformat == undefined){
      
    }else{
      let st = this.montoformat.toString();
      let st_aux = "";

      for(let j = 0; j < st.length; j++)
      {

        if (!isNaN(parseInt(st[j])))
        {
          st_aux = st_aux + st[j];

        }
      }
  
      let i;
      let t_counter = 0;
      let s_out_aux = "";
  
      for (i = st_aux.length - 1; i >= 0; i--){
        s_out_aux = st_aux[i] + s_out_aux;
        t_counter++;
        if (t_counter == 3)
        {
          s_out_aux =  "." + s_out_aux;
          t_counter=0;
        }
      }
  
      if (s_out_aux[0] == ".")
      {
        s_out_aux = s_out_aux.substring(1, s_out_aux.length);
      }
  
      this.montoformat = s_out_aux;



      if(this.montoformat == null || this.montoformat == undefined || this.montoformat.isNaN){
        this.montoformat = 0;
      }

      $("#monto_"+index).val(this.montoformat);
     
    }
  }

  disabled:boolean=false;
  async validate_facturas(){

    let val = {'facturas': this.facturas}
    try {

      this.disabled = true;

      const bd: any = await this.settings.get_query(2, '/cliente/documentos-simulacion-manual/'+this.id_company+'/', val);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        this.disabled = false;

        this.facturas = data.info;

        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.facturas);
          this.dataSource.paginator = this.paginator;
          this.dataSource._updateChangeSubscription();
    
          this.obs = this.dataSource.connect();
          this.changeDetectorRef.detectChanges();
        });

        this.check_error();

        if(this.has_error == true){
          this.snackBar.open('Debes corregir todos los errores en los datos antes de guardar los cambios.', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack-error'
          });
        }

            

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
          this.disabled = false;
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          this.settings.send_mail_500('Recuperar contraseña', bd);
          break;

        case 400: 
        this.disabled = false;
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          break;
        default:
          this.disabled = false;
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });

        break;
      }
    }
  }

  dynamicSort(property:any) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a:any,b:any) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }


  test_error(){
    const dialogRef = this.dialog.open(AlertErrorUpload,{
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.change_step('step-simpli2');
        setTimeout(() => {
          this.subir_momina();
        });

      }
    });
  }

  length=0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  obs!: Observable<any>;
  dataSource = new MatTableDataSource(this.facturas);

}