
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">

        
        <!--ANALISIS SII-->
        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn ">
                <app-grafico-multivariables-dos (grafico_multivariables)="emit_components($event)" [id]="company_id" [graf_cvnc_obj]="graf_cvnc_obj" [onReloadResumen]='onReloadResumen' (onReload)='listenEventOnReloadFalse($event)'>
                </app-grafico-multivariables-dos>
            </div>

            <div id="cuadro_2" class="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                <app-grafico-participacion-factoring [date_end]="date_end" [date_start]="date_start"  [id]="company_id" [participacion_factoring_obj]="participacion_factoring_obj">
                </app-grafico-participacion-factoring>
            </div>


            <div id="cuadro_1" class="col-lg-7 col-md-6 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn ">
                <app-ranking-cesionario [date_end]="date_end" [date_start]="date_start"  [id]="company_id" [ranking_cesion_obj]="ranking_cesion_obj"></app-ranking-cesionario>
            </div>


            <div class="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                <app-participacion-ventas  [date_end]="date_end" [date_start]="date_start"  [id]="company_id" [participacion_deudores_ventas_obj]="participacion_deudores_ventas_obj"></app-participacion-ventas>
            </div>

            <div class="col-lg-7 col-md-6 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn " style="margin-bottom: 12%;">
                <app-ranking-deudores-ventas  [date_end]="date_end" [date_start]="date_start" [id]="company_id" [ranking_deudores_ventas_obj]="ranking_deudores_ventas_obj">
                </app-ranking-deudores-ventas>
            </div>

        </div>
    </div>
