<div class="chat-icon-container" (click)="toggleChat()">
    <img src="../../assets/simpli/mirai_exterior.png" class="chat-icon">
</div>

<div class="chat-container" [class.active]="chatVisible">
    <div class="chat-header">
        <h3 class="margin-h3">Clara, tu asistente virtual</h3>
        <button mat-icon-button (click)="toggleChat()">
            <mat-icon style="color:var(--simpli-secondary);">close</mat-icon>
        </button>
    </div>
    <div class="chat-content add_factoring" #chatContent>
        <div class="message bot-message"  [@fadeIn]>
            <img src="../../assets/simpli/mirai_interior.png" class="avatar">
            <div class="message-content">
                <p class="bot-name">Clara</p>
                <p>¿Qué necesitas hacer hoy?</p>
                <button class="custom-btn" (click)="selectOption(4, 'Quiero simular una operación'); go_to_simular()">Quiero simular una operación</button>
                <button class="custom-btn" (click)="selectOption(1, 'Quiero conocer los servicios')">Quiero conocer los servicios</button>
                <button class="custom-btn" (click)="selectOption(2, '¿Como funciona el factoring?')">¿Como funciona el factoring?</button>
                <button class="custom-btn" (click)="selectOption(3, '¿Dónde se ceden los documentos?')">¿Dónde se ceden los documentos?</button>
                <div class="timestamp">{{ currentDate | date:'short' }}</div>
            </div>
        </div>

        <div *ngFor="let message of userMessages; let i = index" [@fadeIn]>
            <div class="message user-message" *ngIf="i % 2 === 0">

                <div title="{{full_name}}" class="avatar-user" [ngStyle]="{'background-image': showInitials ? avatarBackground : 'url(' + observation?.avatar + ')'}">
                    <span>{{initials}}</span>
                </div>
                
                <div class="message-content">
                    <div [innerHTML]="message"></div>
                    <div class="timestamp">{{ currentDate | date:'short' }}</div>
                </div>
            </div>
            <div class="message bot-message" *ngIf="i % 2 !== 0">
                <img src="../../assets/simpli/mirai_interior.png" class="avatar">
                <div class="message-content">
                    <p class="bot-name">Clara</p>
                    <div [innerHTML]="message"></div>
                    <div class="timestamp">{{ currentDate | date:'short' }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="escribiendo">
            <div class="message bot-message">
                <img src="../../assets/simpli/mirai_interior.png" class="avatar">
                <div class="message-content">
                    <p class="bot-name">Clara</p>
                    <div>
                        <div class="typing-indicator">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>




    </div>
    <div class="chat-input">
        <input id="inputclara" #inputclara [disabled]="disabled" type="text" [(ngModel)]="userMessage" (keyup.enter)="sendMessage()" placeholder="Escribe un mensaje...">
        <button [disabled]="disabled" mat-icon-button (click)="sendMessage()">
            <mat-icon>send</mat-icon>
        </button>
    </div>
</div>

