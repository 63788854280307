
<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn border-title" style="position: relative;">
  
    <div class="row">

      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <span class="title-dialog-lateral">Factura N°{{docto}}</span>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="text-align: right;">
        <span mat-dialog-close style="cursor: pointer;"> 
          <i class="fa-solid fa-xmark" style="color:#1B0943;font-size: 21px;"></i>
        </span>
      </div>

    </div>

  </div>
</div>

<div mat-dialog-content style="max-height: 90vh !important;" class="animate__animated animate__fadeIn">

  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="detail-title">Detalle de la factura</div>
    </div>


    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Resumen de cobranza
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Estado:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Observación:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{estado}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail" *ngIf="obs_plana == '' || obs_plana == null || obs_plana == undefined">--</div>
                <div (click)="open_detail_obs()" style="word-wrap: break-word;" class="row-detail" *ngIf="obs_plana != '' && obs_plana != null && obs_plana != undefined"> 
                  <span class="see-obs">Ver observación</span>
                </div>
              </div>
            </div>
  
            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Fecha de giro:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                  {{fecha_giro}}
                </div>
              </div>
            </div>
          </mat-card-content>
  
        </mat-card>

      </div>

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Información del deudor
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Deudor:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Rut deudor:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{deudor}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{rut_deudor}}</div>
              </div>
            </div>
  
            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Fecha otorgamiento:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Fecha vencimiento:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{fecha_otorgamiento}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{fecha_vencimiento}}</div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Fecha curse operación:</div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{fecha_curse_operacion}}</div>
              </div>
            </div>

          </mat-card-content>
  
        </mat-card>

      </div>

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
           Montos asociados
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto financiado:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto no financiado:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_monto_financiado)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(excedentes)}}</div>
              </div>
            </div>
  
            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto pagado:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Fecha de pago:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_monto_financiado)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                  <span *ngIf="fecha_pago == null">--</span>
                  <span *ngIf="fecha_pago != null">{{fecha_pago}}</span>
                </div>
              </div>
            </div>

          </mat-card-content>
  
        </mat-card>

      </div>

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Resumen del interés por mora
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Días de plazo:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Días de mora:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{diaz_plazo}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{dias_mora}}</div>
              </div>
            </div>
  
            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Total de la mora:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Mora que falta pagar:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(mora)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_mora_pendiente)}}</div>
              </div>
            </div>

          </mat-card-content>
  
        </mat-card>

      </div>

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Descuentos por montos abonados
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Total:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Abonos realizados:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_abonos)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                  {{cant_abonos}}
                  <span  [mdePopoverTriggerFor]="appPopovertable" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger" class="info-icon-dialog"><i class="fa-light fa-circle-info"></i></span>
                  
                  <mde-popover #appPopovertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                    <mat-card class="popover-card" style="position: relative;">

                      <div class="row">

                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                          <mat-label style="display: block;margin-bottom: 10px;">Revisa el resumen de los abonos que realizaste.</mat-label>

                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align: right;">
                          <span style="cursor: pointer;" (click)="popoverTriggerClose.togglePopover()"> 
                            <i class="fa-solid fa-xmark" style="color:white;font-size: 21px;"></i>
                          </span>
                        </div>

                      </div>



                      <mat-divider style="background: white;"></mat-divider>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="text-align: center;">
                          <div class="row-detail-popover" style="font-weight: 600;">Abono</div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="text-align: center;">
                          <div class="row-detail-popover" style="font-weight: 600;">Fecha pago</div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="text-align: center;">
                          <div class="row-detail-popover" style="font-weight: 600;">Forma pago</div>
                        </div>
                      </div>
                      <mat-divider style="background: white;"></mat-divider>

                      <div style="margin-top: 10px;">
                        <div class="row" *ngFor="let i of abonos.slice(0, 4)">
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="text-align: center;">
                            <div class="row-detail-popover">${{formatMoney(i.monto_abono)}}</div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="text-align: center;">
                            <div class="row-detail-popover">{{i.fecha_pago}}</div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="text-align: center;">
                            <div class="row-detail-popover" style="text-transform: capitalize;">{{i.forma_pago}}</div>
                          </div>
                        </div>


                        <div class="row" *ngIf="abonos.length > 4">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: right;">
                            <div class="row-detail-popover" style="cursor: pointer;" (click)="open_abonos()">Ver más <span style="margin-left: 10px;color:white;margin-right: 10px;"><i class="fa-sharp fa-solid fa-chevron-right"></i></span></div>
                          </div>
                        </div>



                      </div>

                      <div style="margin-top: 10px;" *ngIf="cant_abonos == 0">
                        <div class="row" >
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                            <div class="row-detail-popover">No hay abonos realizados.</div>
                          </div>
                        </div>

                      </div>

                    </mat-card>
                  </mde-popover>
                </div>
              </div>
            </div>
  
          </mat-card-content>
  
        </mat-card>

      </div>

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Resumen de excedentes
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Total de excedentes:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Liberados:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(excedentes)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(excedentes_disponibles)}}</div>
              </div>
            </div>


          </mat-card-content>
  
        </mat-card>

      </div>

      <div style="margin-top: 40px;text-align: center;margin-bottom: 80px;">

        <button mat-flat-button color="primary" class="primary-button" (click)="get_detail(true)">
          <span style="margin-right: 15px;"><i  style="color:var(--simpli-secondary)" class="fa-regular fa-download"></i></span>
          Descargar detalle
        </button>

      </div>

    </div>

  </div>

  
</div>