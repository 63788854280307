<div class="row row-responsive" style="margin-bottom: 50%;">

    <!--new-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <span class="material-icons" (click)="return()">
        keyboard_return
      </span>
    </div>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
      <h1 class="titulo-simpli-responsive">Resumen de tus movimientos</h1>
      <small class="subtitulo-simpli-responsive">Para más información de una operación, revisa el detalle.</small>
  
    </div>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
      <div class="row">
  

        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="margin: 0 auto;">
            <form  [formGroup]="valFormFilter">
  
  
                  <mat-form-field appearance="fill">
                    <mat-label>Buscar</mat-label>
                    <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
                    <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                    <mat-hint>Buscar por monto o número de operación</mat-hint>
                  </mat-form-field>
  
            </form>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align: right;">

            <form [formGroup]="valFormFechas">

                  <button mat-mini-fab style="margin-top: 25px;" class="primary-button" color="primary"  (click)="picker.open()">
                    <mat-icon>event</mat-icon>
                  </button>

                  <mat-form-field appearance="fill" color="primary" style="height: 0px;visibility: hidden;">
                      <mat-label>Fecha</mat-label>
                      <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" >
                          <input matStartDate readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                          <input matEndDate readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
                      </mat-date-range-input>
                      <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker>
                          <mat-date-range-picker-actions style="justify-content: center;">
                              <button class="secondary-button" (click)="reset()" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                              <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="filtrar_por_fechas()">Aplicar</button>
                              </mat-date-range-picker-actions>
                      </mat-date-range-picker>
                    </mat-form-field>
            </form>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align: left;">
            <form>
              <button mat-mini-fab color="primary" style="margin-top: 25px;" class="primary-button"  [disabled]="loading" (click)="get_cartola(true)">
                <mat-icon>file_download</mat-icon>
              </button>
            </form>
        </div>
  
      </div>
      
    </div>
  
    <!--Loading-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">
  
      <div class="container-loading">
  
        
        <div class="img-loading">
          <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
        </div>
  
        <div style="margin-top: 20px;">
          <mat-label class="label-loading">
            <div>La información está siendo procesada.</div>
            <div>Esto no tomará mucho tiempo.</div>
          </mat-label>
        </div>
  
      </div>
  
  
    </div>
  
    <!--TABLA-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length >0" style="margin-top: -20px;">
  
      <mat-card class="card-tabla" style="position: relative;margin-top: 20px;">

        <div class="caja-de-totales">

            <div class="box-totales">
                <div>Monto líquido</div>
                <div class="bold">${{formatMoney(totales.total_monto)}}</div>
            </div>

            <div class="box-totales">
                <div>Mora</div>
                <div class="bold">${{formatMoney(totales.total_mora)}}</div>
            </div>

            <div class="box-totales" style="display: none;">
                <div>Excedentes</div>
                <div class="bold">${{formatMoney(totales.total_excedentes)}}</div>
            </div>

        </div>


        <div *ngFor="let element of ELEMENT_DATA" [ngClass]="{'box-factura':true}">

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                  
                  <div class="row" style="margin-top: 10px;">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">Fecha operación:</div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">N° operación:</div>
                      </div>
                    </div>

                  <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail">{{element.fecha_operacion}}</div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail">{{element.nro_operacion}}</div>
                      </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">Cantidad facturas:</div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">Monto liquido:</div>
                      </div>
                    </div>

                  <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail">{{element.cant_facturas}}</div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail">${{formatMoney(element.total_monto)}}</div>
                      </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">Monto pendiente:</div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">Mora</div>
                      </div>
                    </div>

                  <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail" style="position: relative;">
                          ${{formatMoney(element.total_monto_pendiente)}}
                          <span [mdePopoverTriggerFor]="appMontoPendiente" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" class="i-down"><i class="fa-light fa-circle-info"></i></span>
                          <mde-popover #appMontoPendiente="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                            <mat-card class="popover-card">
                              <mat-label>Correspondiente al saldo pendiente de giro.</mat-label>
                            </mat-card>
                          </mde-popover>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail" style="position: relative;">
                          ${{formatMoney(element.mora)}}
                          <span *ngIf="element.mora > 0" class="a-down"><i class="fa-solid fa-arrow-up-long"></i></span>
                        </div>
                      </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-title">Excedentes:</div>
                      </div>

                    </div>

                  <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="row-detail" style="position: relative;">
                          ${{formatMoney(element.excedentes)}}
                          <span [mdePopoverTriggerFor]="appExcedentes" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" class="i-down"><i class="fa-light fa-circle-info"></i></span>
                          <mde-popover #appExcedentes="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                            <mat-card class="popover-card">
                              <mat-label>Son los excedentes originales de la operación. Este monto está sujeto a la mora.</mat-label>
                            </mat-card>
                          </mde-popover>
                        </div>
                      </div>
                  </div>



                <div class="btn-container">
                  <button mat-flat-button color="primary" class="primary-button" (click)="open_detail_operacion(element.nro_operacion)">Ver detalle</button>
                </div>
              </div>
            </div>
        </div>
              

        
  
      </mat-card>
  
    </div>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length == 0">
        <div class="empty-msg">
        
                
            <div style="padding-top: 50px;text-align: center;">
              <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
            </div>
      
            <div style="padding-top: 50px;text-align: center;">
              <mat-label class="label-bienvenida">
                <div class="label-empty">
                  ¡Ups! Al parecer, no tienes movimientos para visualizar.
                </div>
      
                <div class="label-empty" style="margin-top: 20px;">
                  Realiza una operacion para que puedas comenzar a un seguimiento.
                </div>
      
              </mat-label>
            </div>
      
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
                <div class="btn-simpli-container">
                  <button class="primary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
                </div>
                
              </div>
            </div>
      
        </div>
    </div>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false">
        <numbered-pagination [pageSize]="8"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>

    </div>
  
  </div>
  