<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color6)" class="fa-regular fa-triangle-exclamation"></i>
        </div>

        <div class="label-dialog">Ten en cuenta que el porcentaje de financiamiento estará</div>
        <div class="label-dialog">sujeto a evaluación, <strong>si supera el 95%</strong> (este límite se fija</div>
        <div class="label-dialog">considerando las <strong>condiciones de solicitud de línea</strong>).</div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button mat-dialog-close>
                  Continuar
                </button>
              </div>
              
            </div>
          </div>

    </div>


    
</mat-dialog-content>