import { Component, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import moment, { Moment } from 'moment';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { DetalleExcedentesModal } from './detalle-excedentes/detalle-excedentes';
import { FiltroFacturaComponent } from './filtro-factura/filtro-factura';
import { FiltroClientesComponent } from './filtro-clientes/filtro-clientes';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-excedentes-parrilla',
  templateUrl: './excedentes-parrilla.component.html',
  styleUrls: ['./excedentes-parrilla.component.less'],
  providers: [
    provideMomentDateAdapter(MY_FORMATS),
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ExcedentesParrillaComponent implements OnInit {


  length = 0;
  id_company
  page:number=1
  excedentes:any=[]
  fecha_hoy: any; 
  has_excedentes:boolean=false;
  role:any
  deudores:any=[]
  facturas:any=[]
  mes:any=[]
  year:any=[]
  array_doctos:any=[]
  array_deudores:any=[]
  array_meses:any=[]
  array_year:any=[] 
  selected_value1: any
  selected_value2: any
  selected_value3: any
  selected_value4: any
  status_spinner=true

  excedentes_totales: any
  ultima_fecha: any

  activar_filtro=false;

  buscar = new FormControl('');


  constructor(public dialog:MatDialog,public settings: SettingsService, private router: Router, private _bottomSheet:MatBottomSheet) {

    this.id_company = localStorage.getItem("company_id")


   }

  ngOnInit() {
    this.id_company = localStorage.getItem("company_id")
    this.role=localStorage.getItem("role")

    let hoy = new Date();
    let dia = hoy.getDate()
    let mes = hoy.getMonth() + 1
    let year = hoy.getFullYear()
    let hour = hoy.getHours()
    let min = hoy.getMinutes()
    let min_str = min.toString()

    if (min < 10)
      min_str = "0" + min

    this.fecha_hoy = dia + "." + mes + "." + year + " " + hour +":"+min_str  
    this.get_excedentes()
   
  }

  


  today = new Date()
  solicitar_excedentes:boolean=false;
  excedentes_filtrados:any[]=[];
  loading:boolean=false;

  dataSource = new MatTableDataSource(this.excedentes);
  columnsToDisplay = ['operacion', 'factura'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement!: any | null;
  async get_excedentes() {
    try {

      this.excedentes = [];
      this.excedentes_filtrados = [];

      this.loading = true;

      const bd: any = await this.settings.get_query(1,'/excedentes-redux/' + this.id_company + '/?pageNumber=' + this.page + '&filtro='+this.buscar.value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

              this.excedentes = body_data.excedentes;
              this.excedentes_totales = body_data.excedentes_disponibles;
              this.length = body_data.total;

              this.dataSource = this.excedentes
              this.dataSource._updateChangeSubscription();

              

              this.loading  = false;



          break;
        default:

          break;
      }
    } catch (bd: any) {

      this.loading = false;

    }
  }

  apply_filter(){
    this.get_excedentes();
  }

  cuando_busca_vacio(value:string){
    if(value == ""){
      this.limpiar_filtros();
    }else{
      this.get_excedentes();
    }
  }

  openDetail(items:any): void {
    this._bottomSheet.open(DetalleExcedentesModal,
      { data: items }
    );
  }

  facturas_seleccionadas:any[]=[];
  filtar_por_facturas(){
    const bottom = this._bottomSheet.open(FiltroFacturaComponent,
      { data: {'facturas':this.facturas, 'data': this.facturas_seleccionadas} }
    );

    bottom.afterDismissed().subscribe((result) => {
      if (result) {

        if(result.length > 0){
          this.facturas_seleccionadas = result;
          this.applyFilter();
        }else{
          this.limpiar_filtros();
        }
      }
    });
  }

  clientes_seleccionados:any[]=[];
  filtar_por_clientes(){
    const bottom = this._bottomSheet.open(FiltroClientesComponent,
      { data: {'facturas':this.deudores, 'data': this.clientes_seleccionados} }
    );

    bottom.afterDismissed().subscribe((result) => {
      if (result) {

        if(result.length > 0){
          this.clientes_seleccionados = result;
          this.applyFilter();
        }else{
          this.limpiar_filtros();
        }
      }
    });
  }

  disabled_clean:boolean=false;
  mes_filter:any={'mes':[]};
  year_filter:any={'year':[]};
  limpiar_filtros(){

    this.disabled_clean = true;
    this.buscar.reset();
    this.page = 1;
    this.mes_filter={'mes':[]};
    this.year_filter={'year':[]};
    this.clientes_seleccionados=[];
    this.facturas_seleccionadas=[];

    this.get_excedentes();

  }

  mergeFacturas = (facturas: any)=> {
    const merged: any[] = [];
  
    for (const factura of facturas) {
      const existingFactura = merged.find(
        (f) =>
          f.numero_factura === factura.numero_factura &&
          f.numero_operacion === factura.numero_operacion
      );
    
      if (existingFactura) {
        if (!existingFactura.duplicadas) {
          existingFactura.duplicadas = [];
        }
        existingFactura.duplicadas.push(factura);
      } else {
        merged.push(factura);
      }
    }
    
    return merged;
  };

  async applyFilter() {

    let value:any = {
      'page': this.page,
    }

    if(this.year_filter.year.length > 0){
      value['year'] = this.year_filter.year
    }

    if(this.mes_filter.mes.length > 0){
      value['mes'] = this.mes_filter.mes
    }

    if(this.clientes_seleccionados.length > 0){
      value['deudor'] = this.clientes_seleccionados
    }

    if(this.facturas_seleccionadas.length > 0){
      value['doctos'] = this.facturas_seleccionadas
    }

    try {

      this.excedentes = [];
      this.excedentes_filtrados = [];
      this.loading = true;

      const bd: any = await this.settings.get_query(2, 'getexcedente/' + this.id_company + '/?pageNumber=' + this.page, value);
      const body_data = bd._body;

      if(bd.status == 200){


        this.excedentes = body_data.excedentes;


        this.excedentes_filtrados = this.mergeFacturas(this.excedentes);

        this.length = body_data.total;
        this.has_excedentes = this.excedentes.length > 0;


        this.loading = false;



      }


    } catch (bd: any) {
      this.loading = false;
    }

}


  formatMoney=(amount: any, decimalCount = 2, decimal = ",", thousands = ".")=> {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }
  

  syncCredentials(){
    const dialogRef = this.dialog.open(CredencialesSII,{ 
      width:'600px',
      autoFocus: false 
    }
    );
    dialogRef.componentInstance.id_company = this.id_company;
  }

  loading_excentes:boolean=false;
  async sol_excedentes() {
    try {

      this.loading_excentes = true;

      let value = {'excedentes': this.excedentes_totales}

      const bd: any = await this.settings.get_query(2, 'cliente/solicitud-excedentes/' + this.id_company + '/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          this.loading_excentes = false;

          this.open_input_dialog("Solicitud de Excedentes", "Solicitud Exitosa", 
          "¡Todo listo! Tú solicitud fue enviada a tu ejecutivo.",
          "Revisa el estado de tu solicitud en la sección de operaciones.","assignment_turned_in","#3BA46F")


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loading_excentes = false;
    }
  }


  //DIALOG INPUT
  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

    const dialogRef = this.dialog.open(InputDialog,{
      width:'700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
  
  
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  
  }


  mes_selecionado:any;
  anno_seleccionado:any;
  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('excedentes Company ID changed:', event.detail);
    this.ngOnInit(); 
  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_excedentes();
  }


  readonly date = new FormControl(moment());
  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();


    this.mes_filter = {'mes':[ctrlValue.month()+1]};
    this.year_filter = {'year':[ctrlValue.year()]};

    this.applyFilter();

  }



}


