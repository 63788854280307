import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;

@Component({
  selector: 'app-detalle-cesiones',
  templateUrl: './detalle-cesiones.component.html',
  styleUrls: ['./detalle-cesiones.component.less'],
})
export class DetalleCesionesComponent implements OnInit{
  @HostBinding('class') classes = 'card-content';

  public factoringSelected!:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingsService: SettingsService
  ) {
    this.factoringSelected = this.data.tabSelected.factoring
  }


  private error:any;
  public showSpinner:any;
  public _body:any;
  public titleAndDataTable:any;
  public dataTableSource:any;
  public lenFactoring = 0
  public listFactorings:any;

  public listInputFac:any;
  public filterDeudores: string = '';



  ngOnInit() {
    console.log('init')
    this.getListFactorings(this.data.listFactoring)
    this.getDetalleFacturas(this.data.tabSelected.rut);
  }

  async getDetalleFacturas(rut_factoring:any) {
    console.log('get data')
    try {
      this.showSpinner = true;
      const body_data: any = await this.settingsService.get_query(
        1,
        `ranking-cesionario-detalles/${this.data.id_company}/?fin=${this.data.dateEnd}&inicio=${this.data.dateStart}&rut_factoring=${rut_factoring}`
        );
        this._body = await body_data._body.success;
      if (this._body !== undefined && this._body !== null) {
        console.log(this._body)
        this.dataTableSource = this.orderByTotalAMount(this._body)
        this.showSpinner = false;
      }
    } catch (error) {
      this.error = error;
      console.error(error)
      this.showSpinner = false;
    }

  }

  getListFactorings(listFactoring:any){
    const cleanList = listFactoring.filter((item:any) => item.factoring !== '')
    this.listFactorings = cleanList.map((item:any) => ({rutFactoring: item.rut,factoringName: item.factoring}))
    this.lenFactoring = this.listFactorings.length
  }

  searchInput(){
    const value = this.factoringSelected.toLowerCase()
    this.listInputFac = this.listFactorings.filter( (option:any) => option.factoringName.toLowerCase().includes(value))
  }


  filterDataTableSource(factoring: any) {
    console.log('FILTRADO DE DATA TABLE')
    // console.log(factoringRut)
    this.factoringSelected = factoring.factoringName
    this.data.tabSelected = factoring.rutFactoring
    this.getDetalleFacturas(this.data.tabSelected)
  }


  orderByTotalAMount(data:any) {
    data = data.map( (item:any) => {
      return {
        ...item,
        factoring_name: item.factoring_name === 'ARRAY?N FACTORING S.A.' ? 'ARRAYAN FACTORING S.A.' : item.factoring_name
      }
    })
    return data.sort((a:any, b:any) => b.monto_total - a.monto_total)
  }


  applyFilter( value:any) {
    this.filterDeudores = value
  }


}
