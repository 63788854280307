import { Component, Output, OnInit, EventEmitter, ViewChild, Input, SimpleChanges } from '@angular/core';
import { DetalleCesionesComponent } from './components/detalle-cesiones/detalle-cesiones.component';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;

@Component({
  selector: 'app-ranking-cesionario',
  templateUrl: './ranking-cesionario.component.html',
  styleUrls: ['./ranking-cesionario.component.less']
})
export class RankingCesionarioComponent{
  @Input() id:any;
  @Input() ranking_cesion_obj: any;
  len_info: number = 0;
  ELEMENT_DATA: Cesionario[]=[];
  displayedColumns: string[] = ['factoring', 'monto', 'porcentaje', 'conteocesiones', 'conteodeudores'];
  dataSource = new MatTableDataSource<Cesionario>(this.ELEMENT_DATA);
  @ViewChild('paginator') paginator!: MatPaginator;
  info:Boolean=false
  cargando:Boolean=true
  ancho:string="no-ancho"
  @Output() ranking_cesion = new EventEmitter<string>();
  actual:any;
  menos:any;
  constructor(public settings: SettingsService,public dialog: MatDialog) {

   }


@Input() date_start:any;
@Input() date_end:any;
  ngOnChanges(changes: SimpleChanges) {
    this.informacion()

  }

  hay_data:boolean=false;
  mostrar_spinner:boolean=false;
  loading:boolean=false;
  informacion = async () => {
    this.ancho="no-ancho"
    this.cargando=true
    this.mostrar_spinner = true;
    let mes_actual
    this.loading = true;
    let mes_menos
    if (this.ranking_cesion_obj === undefined ) {
      
        //Mes actual
        let mensual = new Date();
        let mes_actual_ultimo = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);
        let mes1 = new Date(mes_actual_ultimo)
        let y1 = mes1.getFullYear();
        let m1:any = mes1.getMonth()+1;
        let d1:any = mes1.getDate();
        if(m1<10){m1="0"+m1}
        if(d1<10){d1="0"+d1}
        mes_actual = y1+"-"+m1+"-"+d1

        //Mes -6
        let mesmenos=new Date(mes_actual_ultimo.getTime() - (3650*60*60*1000)*1);
        let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
        let mes6 = new Date(ultimomenos)
        let y2 = mes6.getFullYear();
        let m2:any = mes6.getMonth()+1;
        let d2:any = mes6.getDate();
        if(m2<10){m2="0"+m2}
        if(d2<10){d2="0"+d2}
        mes_menos = y2+"-"+m2+"-"+d2
      } else {
        mes_actual = this.ranking_cesion_obj.end
        mes_menos = this.ranking_cesion_obj.start
      }
      this.actual = mes_actual
      this.menos = mes_menos
    let resultado:any = (await this.settings.get_query(1, 'ranking-cesionario/'+this.id+'/'+"?inicio="+this.date_start+"&fin="+this.date_end))      
    let body_data=resultado._body;
    
    if(!body_data['error']){
      this.cargando=false
      this.mostrar_spinner = false;

      if(body_data.resumen.length>0){
        this.info=true
        this.ancho="ancho"
        this.hay_data = true;
      }else{
        this.ancho="no-ancho"
        this.info=false;
        this.hay_data = false;
      }


      this.len_info=body_data.resumen.length
      this.ELEMENT_DATA=body_data.resumen; 
      
      this.checkFirstPage()
      

      this.dataSource=new MatTableDataSource(this.ELEMENT_DATA);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      this.dataSource._updateChangeSubscription()
      this.ranking_cesion.emit('1');
      this.loading = false;
    }
    else{
      this.cargando=false
      this.mostrar_spinner = false;
      this.info=false
      this.ancho="no-ancho"
      //this.settings.empty_local_data();
      this.ranking_cesion.emit('1')
      this.loading = false;
    }
  }

  anotherArray: any[] = [];
  checkFirstPage() {

    if (this.ELEMENT_DATA.length > 0) {

      let removedRow = this.ELEMENT_DATA.pop();

      if (removedRow !== undefined) {
        this.anotherArray.push(removedRow);
      }
    }
    
  }

  open_credenciales(){
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false
    });

    dialogRef.componentInstance.id_company = this.id;

    dialogRef.afterClosed().subscribe((result:any) => {
    });
  }
  
  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  applyFilter = (filterValue: string) => {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }  

  openDetalleCesiones(factoring:any) {
    const dialogRef = this.dialog.open(DetalleCesionesComponent, {
      width: '70vw',
      data :{
        tabSelected : factoring,
        dateStart: this.date_end,
        dateEnd: this.date_start,
        id_company: this.id,
        listFactoring: this.ELEMENT_DATA
      }
    })
  }
}

export interface Cesionario{
  factoring: string,
  monto: number,
  porcentaje: number,
  conteocesiones: number,
  conteodeudores: number,
  total: boolean
}
