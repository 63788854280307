import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SettingsService } from "src/app/settings/settings.service";

@Component({
  selector: 'app-obs',
  templateUrl: './obs.html',
  styleUrls: ['./obs.less'],
  providers: []
})
  
export class ObsSimulation implements OnInit, OnDestroy {

  @Input() id_company:any;
  @Input() id_user:any;
  @Input() parent:any;

  received: any[] = []
  valForm = new FormGroup({
    message: new FormControl('', { nonNullable: true }),
  });
  online: boolean = false;
  last_login: any =  "";

  valFormFilter = new FormGroup({
    filter: new FormControl('', { nonNullable: true }),
  });
  filter: string = "";
  executive: any = '';
  BASE_URL: any = SettingsService.API_ENDPOINT;
  is_alive: boolean = false;
  
  constructor(
    public settings: SettingsService, 
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb: FormBuilder,
    ) {

  }

  ngOnInit(){
    this.is_alive = true;
    this.get_messages();
  }

  ngOnDestroy() {
    this.is_alive = false;
  }

  get_messages=async()=>{
    try {
      const bd: any = await this.settings.get_query(1, `cliente/chat-cliente-messages/${this.id_company}/?filter=${this.filter}`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.received = body_data.success.data;          
          this.executive = body_data.success.executive.executive;
          this.online = body_data.success.executive.online;
          this.last_login = body_data.success.executive.last_login;
          this.go_to_last();
        break;
        default:

        break;
      }
    } catch (bd:any) {
      // this.settings.manage_errors(bd.status, '', bd, false);
    }
  }

  async read_messages() {
    try {
      const bd: any = await this.settings.get_query(2, `cliente/chat-cliente-messages/${this.id_company}/`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.parent.get_messages();
        break;
        default:

        break;
      }
    } catch (bd:any) {
      // this.settings.manage_errors(bd.status, '', bd, false);
    }
  }

  send() {
    const message = this.valForm.controls.message.value;
    if (message == '') {
      return false;
    }
    let info: any = {'message': message};
    // this.ws.messages.next(info);
    this.valForm.reset();
    this.go_to_last();
  }

  reset_filter($event: any) {
    if ($event.target.value == "") {
      this.filter = "";
      this.get_messages();
    }
  }

  apply_filter(value: any) {

    this.valFormFilter.controls['filter'].setValue(value)
    this.filter = value;
    this.get_messages()
  }

  apply_filter_2() {
    this.filter = this.valFormFilter.controls['filter'].value;
    this.valFormFilter.controls['filter'].setValue(this.filter)
    this.get_messages()
  }

  go_to_last() {
    setTimeout(() => {
      let messages: any = document.getElementById('chat-container');
      let shouldScroll = messages.scrollTop + messages.clientHeight === messages.scrollHeight;
      if (!shouldScroll) {
        //messages.scrollTop = messages.scrollHeight;
        messages.scrollTo({top: messages.scrollHeight, behavior: 'smooth'});
      }
    }, 300);
  }

}