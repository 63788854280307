<div class="row" >
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" >  


      <div class="row">


        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
            <create-cuenta-bancaria [load_data]="false" [id_company]="id_company" (funciones)="procesaPropagar($event)"></create-cuenta-bancaria>  
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

          <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
              <mat-card class="shadow-box" style="height: 255px">
                <mat-card-content >
                  <h1 class="titulo-simpli">Carga masiva de cuentas bancarias</h1>
                  <small class="subtitulo-simpli">Sube la plantilla con tus cuentas bancarias todas a la vez</small>
    
                  <div style="margin-top: 40px;">

                    <div class="row">

                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top" style="text-align: center;">
                        <button  mat-flat-button [disabled]="disabled" class="primary-button" style="margin-right: 0px;"  (click)="download()">Descargar Planilla</button> 
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top" style="text-align: center;">
                        <button  mat-flat-button  [disabled]="disabled" class="secondary-button" (click)="upload()">Subir Planilla</button> 
                      </div>
                    </div>

                    <input id="file_but" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" style="display:none;" (change)="upload_file($event)"/>
    
                    <div class="success-msg animate__animated animate__fadeIn" style="margin-top:20px;" *ngIf="success">
                      <i class="fa fa-check"></i>
                      &nbsp; Carga Exitosa.
                    </div>
    
                    <div class="warning-msg animate__animated animate__fadeIn" style="margin-top:20px;" *ngIf="warning">
                      <i class="fas fa-exclamation-circle"></i>
                      &nbsp; Hay algunos errores en los registros, verifique el formato en la Planilla.
                    </div>
                  </div>
    
                  
                </mat-card-content>
              </mat-card>
            </div>


          </div>

          <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 20px;">
              <ctas [id_company]="id_company" [actualizar]="actualizar" ></ctas>
            </div>

          </div>

        </div>
        
      </div>

    </div>
</div>