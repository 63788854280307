<div mat-dialog-content>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli" style="position: relative;">{{mostrando_dialogo_de_espera ? 'Solicitud de Financiamiento' :
      'Facturas seleccionadas automáticamente'}}</h1>
    <small class="subtitulo-simpli">{{mostrando_dialogo_de_espera ? 'Procesando información' : 'En proceso'}}</small>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top w-100"
    style="margin: 0 auto;margin-bottom: 50px;margin-top: 20px;" *ngIf="mostrando_dialogo_de_espera == true">
    <dialogo-espera></dialogo-espera>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top w-100" [hidden]="mostrando_dialogo_de_espera == true"
    style="position: relative;">

    <ng-container *ngIf="displayspinner">
      <div class="velo-inactivo animate__animated animate__fadeIn center__items position__absolute"
        style="background: #ffffffde; height: 100%">
        <mat-spinner diameter="40" class="spinner-simpli"></mat-spinner>
        <div class="show-m mt-1">Estamos creando la solicitud de Financiamiento :)</div>
      </div>
    </ng-container>
    <div *ngIf="this.has_simulation">

      <form [formGroup]="firstFormGroup">

        <ng-template matStepLabel>Simulación</ng-template>



        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
            <div class="warning-msg animate__animated animate__fadeIn" style="margin-top:20px;background: white !important;"
              *ngIf="disabled_next">
              <i class="fa-light fa-circle-exclamation" style="color:#ff933b;"></i>
              &nbsp; Tus facturas de ventas no cubren el total de remuneraciones, para avanzar puedes agregar facturas de venta..
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:10px">
            <mat-card class="shadow-box">
              <mat-card-content>

                <div class="row" style="margin-bottom: 20px;">

                  <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6 col-top">
                    <small class="subtitulo-simpli animate__animated animate__fadeIn">Puedes editar esta simulación seleccionando nuevas facturas</small>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <mat-form-field>
                      <mat-label>Filtra para agregar nuevas facturas a tu solicitud</mat-label>
                      <input matInput formControlName="filter" (change)="reset($event.target.value)">
                      <mat-icon matTooltip="Buscar" matSuffix class="btn_suffix" (click)="filtrar()">search</mat-icon>
                      <mat-hint>Introduce una palabra clave y dale click a la lupa para filtrar.</mat-hint>
                    </mat-form-field>
                  </div>

                </div>



                <div style="width: 100%;" class="groupqlo">


                  <div class="table-responsive ">



                    <table mat-table [dataSource]="dataSource">

                      <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row" style="text-align: center;">

                          <div style="border-radius: 3px;transition: all .3s ease-in-out;">

                            <input type="checkbox" [checked]="row.simulado==true? row.simulado:selection.isSelected(row)"
                              (click)="activa(row.folio, row, $event); $event.stopPropagation()">
                          </div>

                        </td>
                      </ng-container>



                      <ng-container matColumnDef="folio">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Folio </th>
                        <td mat-cell *matCellDef="let element;" style="text-align: center;">
                          <input class="no-input" value="{{element.folio}}" readonly>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="receptoracreedor_name">
                        <th mat-header-cell *matHeaderCellDef> Deudor </th>
                        <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}"
                          style="text-align: center;">
                          <input class="no-input" value="{{element.receptoracreedor_name}}" style="text-align: left;"
                            readonly>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="receptoracreedor_rut">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Rut </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                          <input class="no-input" value="{{element.deudor}}" readonly>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="mnt_total">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Monto </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                          <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="fecha_emision">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Fecha Emisión </th>
                        <td mat-cell *matCellDef="let element;" style="text-align: center;">
                          <input class="no-input" value="{{element.fecha_emision}}" readonly>
                        </td>

                      </ng-container>

                      <ng-container matColumnDef="fecha_vencimiento">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Fecha vencimiento
                          <span matTooltip="Agregar una fecha de vencimiento es opcional">
                            <i style="margin-left: 10px;" class="fa-light fa-info-circle"></i>
                          </span>
                        </th>
                        <td mat-cell *matCellDef="let element; let i=index, let index=index"
                          style="text-align: center;cursor: pointer;min-width: 130px;">

                          <div style="position: relative;" (click)="i.open()">
                            <input
                              [ngClass]="{'no-input':true,
                                                                                      'vencida':element.color === 'rojo',
                                                                                      'porvencer':element.color === 'blanco'}"
                              class="no-input" placeholder="dd/mm/yyyy" value="{{element.fecha_vencimiento}}"
                              readonly />
                          </div>




                          <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                            <mat-label>Fecha de vencimiento</mat-label>
                            <input [min]="minDate" matInput [matDatepicker]="i"
                              (dateChange)="recalculate_fecha($event, index)" (click)="i.open()"
                              formControlName="secondCtrl">
                            <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                            <mat-datepicker #i></mat-datepicker>
                          </mat-form-field>

                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                      </ng-container>



                      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>

                    </table>

                    <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                      (page)="pageCustom($event)"> </mat-paginator>




                  </div>



                </div>


              </mat-card-content>
            </mat-card>
          </div>


          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
            <div class="info-msg animate__animated animate__fadeIn" style="margin-top:20px;position: relative;text-align: center;">
              Generamos la simulación a 30 días de plazo; ¿deseas ajustar el plazo?

              <strong style="text-decoration: underline;" (click)="r.open()">Cambia la fecha aquí</strong>

              <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                <mat-label>Fecha de vencimiento</mat-label>
                <input [min]="minDate" matInput [matDatepicker]="r" (dateChange)="recalculate_fecha($event, true)"
                  (click)="r.open()" formControlName="secondCtrl">
                <mat-datepicker-toggle matSuffix [for]="r"></mat-datepicker-toggle>
                <mat-datepicker #r></mat-datepicker>
              </mat-form-field>
            </div>
          </div>


          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

            <mat-card class="shadow-box">
              <mat-card-content>

                <small class="smalltitle">Simulación automática de factoring</small>

                <!--Anticipo-->
                <div class="row col-top">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <small><input class="no-input" value="95% Anticipo de Factura" readonly></small>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{this.formatMoney(this.simulation.anticipo)}}" readonly
                      style="text-align: right;">
                  </div>

                </div>

                <!--vencimiento promedio-->
                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="Vencimiento promedio" readonly>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="{{simulation.vencimiento}} Días" readonly style="text-align: right;">
                  </div>

                </div>

                <mat-divider class="separador5"></mat-divider>

                <!--Comision-->
                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="Comisión" readonly>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{formatMoney(this.simulation.comision)}}" readonly
                      style="text-align: right;">
                  </div>

                </div>

                <!--Gastos operaciones-->
                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="Gastos Operaciones" readonly>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{formatMoney(this.simulation.gastosOperacionales)}}" readonly
                      style="text-align: right;">
                  </div>

                </div>

                <!--IVA-->
                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="IVA" readonly>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{this.formatMoney(this.simulation.iva_operaciones)}}" readonly
                      style="text-align: right;">
                  </div>

                </div>


                <!--Diferencia de precio-->
                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="Diferencia de Precio" readonly>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                    <input class="no-input" value="$ {{formatMoney(simulation.interesTotal)}}" readonly
                      style="text-align: right;">
                  </div>

                </div>





                <mat-divider class="separador5"></mat-divider>



                  <div class="row" *ngIf="is_not_remuneracion">

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a colaboradores"
                        readonly>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="$ {{formatMoney(deuda)}}" readonly
                        style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                    </div>
                  </div>

                  <div class="row" *ngIf="!is_not_remuneracion">

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a colaboradores"
                        readonly>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="$ {{formatMoney(deuda)}}" readonly
                        style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                    </div>
                  </div>

                  <div class="row">

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input *ngIf="debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input"
                        value="Giro a tu cuenta" readonly>
                      <input *ngIf="!debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input"
                        value="Giro a pagar a Arrayán" readonly>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="$ {{formatMoney(por_pagar)}}" readonly
                        style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">


                    </div>
                  </div>

                                  <!--monto liquido-->
                                  <div class="row">

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                                      <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro Total"
                                        readonly>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                                      <input class="no-input" value="$ {{formatMoney(simulation.liquidoRecibir)}}" readonly
                                        style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                                    </div>
                                  </div>




              </mat-card-content>
            </mat-card>


          </div>

        </div>

        <div class="container__buttons">
          <button mat-raised-button mat-dialog-close class="neumorphism_btn" type="button"
            style="margin-left:0px;">Cerrar</button>
          <div class="container__buttons--end">
            <button mat-raised-button class="neumorphism_btn"  type="button"
              (click)="crear_operacion_remuneraciones()" [disabled]="displayspinner">Finalizar</button>
          </div>


        </div>

        <div style="display: flex;" *ngIf="cargando == true">
          <small>*Estamos sincronizando con el SII para actualizar los acreedores.</small>
          <mat-spinner class="spinner-simpli" style="margin-left: 20px;" diameter="40"></mat-spinner>
        </div>


      </form>

    </div>

    <ng-container *ngIf="!this.has_simulation">

      <div class="row w-100" style="width: 100%;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="box_placeholder" style="margin-top: 7%;cursor:pointer;width:100%; text-align: center;">
            <i class="material-icons ball"> assessment </i>
            <div style="margin-top: -20px;">No tienes facturas disponibles para pagar la deuda, <strong>la deuda actual es
                de ${{formatMoney(deuda)}}</strong></div>
          </div>
        </div>

      </div>
    </ng-container>

    <div *ngIf="!this.has_simulation"
      style="margin-bottom: 40px;margin-top: 20px;display: inline-flex;justify-content: space-between;flex-wrap: wrap; width: 100%;">
      <button mat-raised-button mat-dialog-close class="neumorphism_btn" type="button">Cerrar</button>
      <!-- <button mat-raised-button class="neumorphism_btn" type="button" (click)="sincronizar()">Sicronizar
        SII</button> -->
    </div>

  </div>
</div>
