import { Component, HostListener, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AlertDelete } from './alert-delete/alert-delete';
import { AlertSuccessUpload } from './alert-success-upload/alert-success-upload';
import { FacturasconProblemasService } from '../parrilla-operaciones/detalle-operacion/service/facturas-con-problemas';
import { AlertSuccessCesion } from '../cesion-facturador/alert-success-cesion/alert-success-cesion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


declare var $: any;


@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.html',
  styleUrls: ['./documentacion.less']
})
export class DocumentacionComponent implements OnInit {



  @Input() id_company:any;
  @Input() id_sim:any;
  @Input() id_op:any;
  @Input() caso:any;

  upload_files:any=null;
  is_responsive:boolean=false;

  constructor(public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, 
    public snackBar:MatSnackBar,  private activated_router: ActivatedRoute, public router:Router,
    @Optional() private facturaService: FacturasconProblemasService) {

    this.id_company = localStorage.getItem('company_id');

    this.activated_router.params.subscribe((params:any) => {
      this.id_sim = params['id'];
      this.id_op = params['op'];
      this.caso = params['caso'];
    })
  
   }



  ngOnInit() {
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }
      this.get_facturas();
 
  }

  go_to_detail(){
    this.router.navigate(['home/simulaciones/'])
  }

  volver(){
    this.router.navigate(['home/operation-detail/', this.id_op])
  }

  go_to_info(){
    this.router.navigate(['home/info-documentacion/',this.id_sim])
  }

  cantidad_facturas:any=0;
  facturas:any[]=[];
  loading:boolean=true;
  async get_facturas(){

    this.loading = true;

    try {

      let url = '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1';
      if(this.caso == "2" || this.caso == "4"){
        url = '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1&caso_verificacion='+this.caso;
      }

      const bd: any = await this.settings.get_query(1, url);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.cantidad_facturas = data.total;
          this.facturas = data.info;

          console.log("facturas", data)

          window.scrollTo({top: 0, behavior: 'smooth'});

          let tiene_files:boolean=false;
          for(let i in this.facturas){
            if(this.facturas[i].archivos.length > 0){
              tiene_files = true;
              break;
            }
          }

          if(this.cantidad_facturas <= 7 && tiene_files == false){
            this.upload_files = 1
          }else if(this.cantidad_facturas <= 7 && tiene_files == true){
            this.upload_files = 2
          }else if(this.cantidad_facturas > 7){
            this.get_documentos_sim()
          }




          this.loading = false;


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            this.settings.send_mail_500('Recuperar contraseña', bd);
          break;

        case 400: 

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;

 
      }
    }


  }

  async get_facturas_malas(){

    this.loading = true;
    this.upload_files = 1;


    this.facturas = this.facturaService.getFacturas();
    this.cantidad_facturas = this.facturas.length;

    console.log(this.facturas)

    window.scrollTo({top: 0, behavior: 'smooth'});

    this.loading = false;



  }

  get_name_url(url:any){
    const file_name = url.substring(url.lastIndexOf('/')+1);
    return file_name
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

 
  finalizar(){
    if(this.se_cargaron_files == false){
      if(this.is_responsive == true){
        this.router.navigate(['home/simulaciones-r'])
      }else{
        this.router.navigate(['home/simulaciones'])
      }

    }else{
      if(this.is_responsive == true){
        const dialogRef = this.dialog.open(AlertSuccessCesion,{
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          maxHeight: '100vh',
          hasBackdrop: false,
          panelClass: 'dialogo-full-screen-responsive',
          autoFocus: false,
          disableClose: false,
        });
    
        dialogRef.componentInstance.parent = this;
        dialogRef.componentInstance.title = "¡Los documentos estan en validación!"
        dialogRef.componentInstance.subtitle = "Te notificaremos sobre el resultado. Para seguir el avance de esta etapa, revisa el estado de la operación."
        dialogRef.componentInstance.solo_cerrar = true;
    
  
        dialogRef.afterClosed().subscribe((result:any) => {
          this.router.navigate(['home/operation-detail/', this.id_op_original])
        });
      }else{

        const dialogRef = this.dialog.open(AlertSuccessCesion,{
          width: '600px',
          disableClose: true,
          maxWidth: '95vw',
          autoFocus:false,
        });
    
        dialogRef.componentInstance.parent = this;
        dialogRef.componentInstance.title = "¡Los documentos estan en validación!"
        dialogRef.componentInstance.subtitle = "Te notificaremos sobre el resultado. Para seguir el avance de esta etapa, revisa el estado de la operación."
        dialogRef.componentInstance.solo_cerrar = true;
    
  
        dialogRef.afterClosed().subscribe((result:any) => {
          this.router.navigate(['home/operation-detail/', this.id_op_original])
        });

      }

    }



  }


  //CARGA MASIVA SI TIENE MAS DE 7 archivos

  has_files:boolean=false;
  error: string="";
  dragAreaClass: string="dragarea";

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    $("#draggable").addClass('hover-drag')
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    $("#draggable").addClass('hover-drag')
    console.log("entre a la zona drag")
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    $("#draggable").removeClass('hover-drag')
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    console.log("sali a la zona drag")
    $("#draggable").removeClass('hover-drag')
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    $("#draggable").removeClass('hover-drag')
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  id_factura:any;
  onFileChange(event: any, id_factura?:any) {
    let files: FileList = event.target.files;
    this.id_factura = id_factura;
    this.saveFiles(files, id_factura);
  }

  files:any[]=[];
  formData:any = new FormData();
  desactivar:boolean=true;
  cargo_a_este_exp:boolean=false;
  disabled_btn:boolean=false;

  se_cargaron_files:boolean=false;
  id_op_original:any=null;
  isDeletingFile: boolean = false;
  uploadingFacturaId: any = null;
  async saveFiles(files: any, id_factura?:any) {
    const regex = new RegExp('[^.]+$');

    for(let i=0;i<files.length;i++){
      const ext = files[i].name.match(regex)[0]
      const name = files[i].name;
      this.formData.append(name, files[i], name);
      this.files.push({'id':name, 'name':name});

    }


    if(this.files.length > 0){
      this.has_files = true;
      this.desactivar = false;
    }else{
      this.has_files = false;
      this.desactivar = true;
    }

    if(id_factura){
        try {

          this.uploadingFacturaId = id_factura;
          this.disabled_btn = true;

          let url;
          if(id_factura){
            url = '/cliente/simulacion-archivos/'+this.id_sim+'/?document_id='+id_factura;
          }else{
            url = '/cliente/simulacion-archivos/'+this.id_sim+'/';
          }


          const bd: any = await this.settings.get_query(2, url, this.formData);
          var body_data: any =bd._body;
          var data = body_data.success;
          switch (bd.status) {
            case 200:


              try {

                let url = '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1';
                if(this.caso == "2" || this.caso == "4"){
                  url = '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1&caso_verificacion='+this.caso;
                }

                const bd: any = await this.settings.get_query(1, url);
                var body_data: any =bd._body;
                var data = body_data.success;
                switch (bd.status) {
                  case 200:
        
                    this.files = [];
                    this.formData = new FormData();


                    this.cantidad_facturas = data.total;
                    this.facturas = data.info;

                    if(this.id_op != null){
                      this.id_op_original = this.id_op;
                      this.id_op = null;
                      this.se_cargaron_files = true;
                    }
                    

          
                    let tiene_files:boolean=false;
                    for(let i in this.facturas){
                      if(this.facturas[i].archivos.length > 0){
                        tiene_files = true;
                        break;
                      }
                    }
          
                    if(this.cantidad_facturas <= 7 && tiene_files == false){
                      this.upload_files = 1
                    }else if(this.cantidad_facturas <= 7 && tiene_files == true){
                      this.upload_files = 2
                    }else if(this.cantidad_facturas > 7){
                      this.get_documentos_sim()
                    }

                    this.uploadingFacturaId = null;
                    this.disabled_btn = false;

                    $("#file_"+id_factura).val('');
          
                    this.cargo_a_este_exp = true;

                    this.disabled_btn = false;
          
                  break;
                  default:
                    this.uploadingFacturaId = null;
                    this.disabled_btn = false;
                  break;
                }
          
              } catch (bd:any) {
          
                this.uploadingFacturaId = null;
                this.disabled_btn = false;
              }





            break;
            default:

            break;
          }

        } catch (bd:any) {

          switch (bd.status) {
            case 500:

                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });
                this.settings.send_mail_500('Recuperar contraseña', bd);
              break;

            case 400: 

                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });
              break;
            default:

                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });

            break;


          }
        }
    }else{
      console.log("cai en el else")
    }


  }


  delete(id:any){
    this.formData.delete(id);

    const obje = this.files.find((x: any) => x.id == id);

    if (obje) {
      this.files.splice(this.files.indexOf(obje), 1);
    }

    if(this.files.length > 0){
      this.has_files = true;
      this.desactivar = false;
    }else{
      this.has_files = false;
      this.desactivar = true;

      var input = $("#file2");
      input.replaceWith(input.val('').clone(true));

  
    }
    
  }

  async upload_masive(){
    try {

      this.desactivar = true;

      let url = '/cliente/simulacion-archivos/'+this.id_sim+'/';

      const bd: any = await this.settings.get_query(2, url, this.formData);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

            try {


              const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1');
              var body_data: any =bd._body;
              var data = body_data.success;
              switch (bd.status) {
                case 200:
      
                  this.cantidad_facturas = data.total;
                  this.facturas = data.info;
        
                  if(this.cantidad_facturas > 7){
                    this.upload_files = 3
                  }

                  this.desactivar = false;
        

        
                break;
                default:
        
                break;
              }
        
            } catch (bd:any) {
              this.desactivar = false;

            }

            const dialogRef = this.dialog.open(AlertSuccessUpload,{
              width: '530px',
              maxWidth: '95vw',
              disableClose: true,
              autoFocus:false,
            });
        
            dialogRef.afterClosed().subscribe((result:any) => {
              this.router.navigate(['/home/simulaciones'])
            });

            this.desactivar = false;


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            this.settings.send_mail_500('Recuperar contraseña', bd);
          break;

        case 400: 

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;


      }
    }
  }

  documentos:any[]=[]
  loading_documentos:boolean=true;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [ 'documento','accion'];
  dataSource = new MatTableDataSource(this.documentos);
  async get_documentos_sim(){
    try {

      this.loading_documentos = true;

      this.files = [];
      this.formData = new FormData();

      let url = '/cliente/simulacion-archivos/'+this.id_sim+'/';

      const bd: any = await this.settings.get_query(1, url, this.formData);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


        console.log("documentos,", data)

          this.documentos = data.info;
          this.loading_documentos = false;

          if(this.documentos.length > 0){
            this.upload_files = 4;
          }else{
            this.upload_files = 3;
          }

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.documentos);
            this.dataSource.paginator = this.paginator;
            this.dataSource._updateChangeSubscription();
          });


        break;
        default:

        break;
      }

    } catch (bd:any) {

      this.loading_documentos = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  download_file(file:any){
    window.open(file, "blank")
  }

  async delete_file(id_factura:any){
    const dialogRef = this.dialog.open(AlertDelete,{
      width: '550px',
      maxWidth: '95vw',
      disableClose: true,
      autoFocus:false,
    });

    dialogRef.afterClosed().subscribe(async (result:any) => {

      if(result == true){
        try {

          this.loading_documentos = true;
    
          let url = '/cliente/simulacion-archivos/'+id_factura+'/';
    
          const bd: any = await this.settings.get_query(4, url);
          var body_data: any =bd._body;
          var data = body_data.success;
          switch (bd.status) {
            case 200:
    
              this.get_documentos_sim();
              this.snackBar.open('Documento eliminado', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });
    
            break;
            default:
    
            break;
          }
    
        } catch (bd:any) {
    
          this.loading_documentos = false;
        }
      }

    });
  }

  deletingFileId: string | null = null;

  async delete_file_manual(id_factura:any, file_id: string){
    const dialogRef = this.dialog.open(AlertDelete,{
      width: '550px',
      maxWidth: '95vw',
      disableClose: true,
      autoFocus:false,
    });

    dialogRef.afterClosed().subscribe(async (result:any) => {

      if(result == true){
        try {

          this.deletingFileId = file_id;
          this.loading_documentos = true;
    
          let url = '/cliente/simulacion-archivos/'+id_factura+'/';
    
          const bd: any = await this.settings.get_query(4, url);
          var body_data: any =bd._body;
          var data = body_data.success;
          switch (bd.status) {
            case 200:
    
              this.snackBar.open('Documento eliminado', 'Aceptar', {
                duration: 5000,
                panelClass: 'simpli-snack'
              });


                try {
        
                  let url = '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1';
                  if(this.caso == "2" || this.caso == "4"){
                    url = '/cliente/simulacion-documentos/'+this.id_sim+'/?page=1&caso_verificacion='+this.caso;
                  }
        
                  const bd: any = await this.settings.get_query(1, url);
                  var body_data: any =bd._body;
                  var data = body_data.success;
                  switch (bd.status) {
                    case 200:
          
                      this.cantidad_facturas = data.total;
                      this.facturas = data.info;
            
                      let tiene_files:boolean=false;
                      for(let i in this.facturas){
                        if(this.facturas[i].archivos.length > 0){
                          tiene_files = true;
                          break;
                        }
                      }
            
                      if(this.cantidad_facturas <= 7 && tiene_files == false){
                        this.upload_files = 1
                      }else if(this.cantidad_facturas <= 7 && tiene_files == true){
                        this.upload_files = 2
                      }else if(this.cantidad_facturas > 7){
                        this.get_documentos_sim()
                      }
            
                      this.cargo_a_este_exp = true;
        
                      this.deletingFileId = null;
                    break;
                    default:
                      this.deletingFileId = null;
                    break;
                  }
            
                } catch (bd:any) {
                  this.deletingFileId = null;
                 
                }
   
  
            break;
            default:
              this.deletingFileId = null;
            break;
          }
    
        } catch (bd:any) {
    
          this.deletingFileId = null;
          this.loading_documentos = false;
        }
      }


    });
  }

  loading_zip:boolean=false;
  async donwload_zip(){
    try {

      this.loading_zip = true;

      let url = '/cliente/simulacion-archivos/'+this.id_sim+'/?zip=1';

      const bd: any = await this.settings.get_query(1, url);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          window.open(data.info, "blank")



          setTimeout(async () => {
            try {

              this.loading_documentos = true;
        
              let url = '/cliente/delete-file-zip-simulation/'+this.id_sim+'/';
        
              const bd: any = await this.settings.get_query(4, url);
              var body_data: any =bd._body;
              var data = body_data.success;
              switch (bd.status) {
                case 200:
        
        
        
                break;
                default:
        
                break;
              }
        
            } catch (bd:any) {
        
            }
          }, 5000);
          

          this.loading_zip = false;


        break;
        default:

        break;
      }

    } catch (bd:any) {
        this.loading_zip = false;
    }
  }

  triger_click(id_document:any){
    $("#"+id_document).trigger('click')
  }

}