
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;


@Component({
  selector: 'app-alert-error-upload',
  templateUrl: './alert-error-upload.html',
  styleUrls: ['./alert-error-upload.less']
})
export class AlertErrorUpload implements OnInit {


  link:any;
  id_company = localStorage.getItem('company_id');



  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<AlertErrorUpload>,public settings: SettingsService, public snackBar:MatSnackBar) {



   }



  ngOnInit() {


  }

  close(value:boolean){
    this.dialogRef.close(value)
  }

  disabled:boolean=true;
  async download_nomina(){
    this.disabled = false;
    window.open(this.link, "blank");

    setTimeout(async () => {
    //BORRA EL ERROR
    try {


      const bd: any = await this.settings.get_query(4, '/cliente/documentos-simulacion-manual/'+this.id_company+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

           


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
    },1500);



  }


}