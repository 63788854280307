<div class="row row_main" style="background: white;overflow-y: hidden;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn border">
        <h1 class="titulo-simpli">Relación Ventas/NC/Cesiones</h1>
        <small class="subtitulo-simpli">Últimos {{this.facturas.labels.length}} meses</small>

        <br><br><br><br>

        <div class="sinCargando" *ngIf="cargando">
            <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
        </div>

        <div class="sinRegistros" *ngIf="!info && !cargando">
            <small>No se consiguieron registros.</small>
        </div>

        <div *ngIf="info && !cargando" class="scroll_raking" style="overflow-x: scroll;overflow-y: hidden;display: block; margin: 0 auto; max-height: 650px !important; max-width: 1100px !important; margin-bottom: 2%;" >
            <canvas *ngIf="show_graph"  id="{{id}}" baseChart style="display: block; margin: 0 auto;min-width: 500px;height: 415px;max-height: 415px;min-height: 415px;" [chartType]="'bar'" [datasets]="facturas.datasets" [labels]="facturas.labels" [options]="dOptions" [colors]="dColors"></canvas>
        </div>
    </div>
</div>