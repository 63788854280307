<div mat-dialog-title>
  <h1 class="titulo-simpli">Simulación Generada</h1>
  <small class="subtitulo-simpli">Vista Previa</small>
</div>

  <div mat-dialog-content style="position: relative;">

    <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="loading">
      <mat-spinner class="spinner-simpli" diameter="30" style="margin-top: 150px;"></mat-spinner>
    </div>

    <div class="row">


      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
            
        <!--Anticipo-->
        <div class="row col-top">
  
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
              <small><input class="no-input" value="95% Anticipo de Factura" readonly></small>
          </div> 
  
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
              <input class="no-input" value="$ {{this.formatMoney(this.simulation.anticipo)}}" readonly style="text-align: right;">
          </div>
  
        </div>
  
          <!--vencimiento promedio-->
          <div class="row">
  
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input class="no-input" value="Vencimiento promedio" readonly>
              </div> 
  
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input class="no-input" value="{{simulation.vencimiento}} Días" readonly style="text-align: right;">
              </div>
  
          </div>
  
          <mat-divider class="separador5"></mat-divider>
  
          <!--Comision-->
          <div class="row">
  
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
              <input class="no-input" value="Comisión" readonly>
          </div> 
  
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
              <input class="no-input" value="$ {{formatMoney(this.simulation.comision)}}" readonly style="text-align: right;">
          </div>
  
          </div>
  
          <!--Gastos operaciones-->
          <div class="row">
  
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
              <input class="no-input" value="Gastos Operaciones" readonly>
          </div> 
  
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
              <input class="no-input" value="$ {{formatMoney(this.simulation.gastosOperacionales)}}" readonly style="text-align: right;">
          </div>
  
          </div>
  
          <!--IVA-->
          <div class="row">
  
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input class="no-input" value="IVA" readonly>
              </div> 
      
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input class="no-input" value="$ {{this.formatMoney(this.simulation.iva_operaciones)}}" readonly style="text-align: right;">
              </div>
  
          </div>
  
  
          <!--Diferencia de precio-->
          <div class="row">
  
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input class="no-input" value="Diferencia de Precio" readonly>
              </div> 
      
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input class="no-input" value="$ {{formatMoney(simulation.interesTotal)}}" readonly style="text-align: right;">
              </div>
      
          </div>
  
  
  
  
  
          <mat-divider class="separador5"></mat-divider>
  
  
  
  
          <!--monto liquido-->
          <div class="row">
  
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Monto Liquido a Recibir" readonly>
              </div> 
      
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                  <input  class="no-input" value="$ {{formatMoney(simulation.liquidoRecibir)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
              </div>
          </div>
  
          <div class="row">
  
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Total giro a proveedores" readonly>
            </div> 
  
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                <input  class="no-input" value="$ {{formatMoney(this.deuda_total)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
            </div>
          </div>
  
          <div class="row">
  
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                <input *ngIf="debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Saldo a tu cuenta" readonly>
                <input *ngIf="!debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a pagar a Arrayán" readonly>
            </div> 
  
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                <input  class="no-input" value="$ {{formatMoney(por_pagar)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
  
  
            </div>
          </div>
  
      </div>
  

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 30px;">
  
  
  
          <h1 class="titulo-simpli">Facturas de Venta</h1>
          <small class="subtitulo-simpli">Vista Previa</small>
  
  
      
          <div class="table-responsive col-top" style="margin-top: 30px;" >
  
  
  
            <table mat-table [dataSource]="dataSource">
  
              <ng-container matColumnDef="folio">
                <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Folio </th>
                <td mat-cell *matCellDef="let element; let i=index;" matTooltip="{{element.folio}}" style="min-width: 130px;text-align: center;position: relative;"> 
                  <i *ngIf="is_not_done"  matTooltip="Eliminar" class="fa-light fa-minus-circle deleteicon" [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" ></i>
  
                    <input class="no-input"  value="{{element.folio}}" style="text-align: center;" readonly> 
  
                    <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                      <mat-card>
                        <mat-card-content>
                          <small>¿Eliminar esta factura?</small><br>
  
                          <button  type="button" (click)="eliminar(simulation_id, element.id, i)" style="margin-top: 10px;">Eliminar</button>
  
                        </mat-card-content>
                      </mat-card>
                    </mde-popover>
                </td>
                <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>
          
              <ng-container matColumnDef="receptoracreedor_name">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Deudor </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}" style="min-width: 130px;"> 
                      <input class="no-input"  value="{{element.receptoracreedor_name}}" style="text-align: left;" readonly> 
                  </td>
              </ng-container>
        
              <ng-container matColumnDef="receptoracreedor_rut">
                <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
                <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                    <input class="no-input"  value="{{element.deudor}}" readonly> 
                </td>
              </ng-container>
        
              <ng-container matColumnDef="mnt_total">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                    <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                      <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
                  </td>
              </ng-container>
        
              <ng-container matColumnDef="fecha_emision">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Fecha Emisión </th>
                  <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                  <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
                  </td>
  
              </ng-container>
  
              <ng-container matColumnDef="fecha_vencimiento">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Fecha vencimiento</th>
                <td mat-cell *matCellDef="let element; let i=index, let index=index" style="min-width: 130px;">
  
                  <input   class="no-input" value="{{element.fecha_vencimiento}}" readonly> 
            
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>
  
  
        
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>
  
            </table>
  
            <div class="success-account-msg animate__animated animate__fadeIn" *ngIf="!is_not_done && tipo!= 1">
              &nbsp; No es posible modificar la operación porque está <strong>Terminada</strong>
            </div>
  
            <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" ></mat-paginator>
        
  
  
  
          </div>
  
  
  
  
  
      </div>


    </div>



  </div>



<mat-dialog-actions style="margin-top: 10px;margin-bottom: 10px;">
  <button  mat-flat-button mat-dialog-close class="primary-button" type="button" style="margin-top: 10px;">Cerrar</button>
  <button  *ngIf="is_not_done" mat-flat-button (click)="open_ventas()"  class="primary-button"  type="button" style="margin-top: 10px;">Agregar Ventas</button>
</mat-dialog-actions>