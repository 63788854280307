<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
      <h1 class="titulo-simpli" *ngIf="caso != 3">Facturas con entregas pendientes</h1>
      <h1 class="titulo-simpli" *ngIf="caso == 3">Facturas con multa</h1>
      <small class="subtitulo-simpli" *ngIf="caso != 3">Si entregaste los productos o servicios asociados a estas facturas, cierra la ventana y adjunta la documentación de respaldo. </small>



    </div>
  </div>

<mat-dialog-content>

    <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

              <mat-form-field appearance="fill" *ngIf="paginar_front == true">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)">
                <mat-icon matSuffix class="icon-suffix">search</mat-icon>
                <mat-hint>Buscar por folio o monto</mat-hint>
              </mat-form-field>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="table-responsive-simpli add_factoring desktop-table">

                <table mat-table [dataSource]="dataSource" *ngIf="paginar_front == false">
        
        
                  <ng-container matColumnDef="factura">
                    <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                    <td mat-cell *matCellDef="let element"> 
                      <input class="no-input" readonly value="{{element.folio}}">
                    </td>
                  </ng-container>
                
        
                  <ng-container matColumnDef="monto">
                    <th mat-header-cell *matHeaderCellDef> Monto total</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input" readonly value="${{formatMoney(element.monto)}}">
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="deudor">
                    <th mat-header-cell *matHeaderCellDef> Deudor</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input" readonly value="{{element.deudor}}">
                    </td>
                  </ng-container>
        
                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <table mat-table [dataSource]="dataSource" *ngIf="paginar_front == true">
        
        
                  <ng-container matColumnDef="factura">
                    <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                    <td mat-cell *matCellDef="let element"> 
                      <input class="no-input" readonly value="{{element.folio}}">
                    </td>
                  </ng-container>
                
        
                  <ng-container matColumnDef="monto">
                    <th mat-header-cell *matHeaderCellDef> Monto total</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input" readonly value="${{formatMoney(element.monto)}}">
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="deudor">
                    <th mat-header-cell *matHeaderCellDef> Deudor</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input" readonly value="{{element.deudor}}">
                    </td>
                  </ng-container>
        
                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="facturas.length == 0" style="text-align: center;margin-top: 40px;margin-bottom: 40px;">
                  <i style="color: gray; font-size: 25px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                </div>
                
        
            </div>

            <div class="resposive-table">
              <table mat-table [dataSource]="dataSource" class="table-res">
              
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
        
                    <div [ngClass]="{'box-factura':true}" (click)="$event.stopPropagation()">
        

                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                            <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{element.folio}}</mat-label></div>
                            <div class="container-label-res"><mat-label class="left-label">Monto total:</mat-label><mat-label class="right-label">${{formatMoney(element.monto)}}</mat-label></div>
                            <div class="container-label-res"><mat-label class="left-label">Deudor:</mat-label><mat-label class="right-label">{{element.deudor}}</mat-label></div>
                          </div>
                        </div>
                    </div>
                    
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRes"></tr>
                <tr mat-row *matRowDef="let row; let doc; columns: displayedColumnsRes;" ></tr>
              </table>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
                <numbered-pagination *ngIf="paginar_front == false" [pageSize]="7"  [totalCount]="cantidad_facturas" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
                <mat-paginator *ngIf="paginar_front == true" [pageSizeOptions]="[7, 14, 28, 50]"></mat-paginator>
              </div>
            </div>

        </div>
    </div>

</mat-dialog-content>