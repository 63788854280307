import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-pago-proveedores-resumen',
  templateUrl: './pago-proveedores-resumen.component.html',
  styleUrls: ['./pago-proveedores-resumen.component.less']
})
export class PagoProveedoresResumenComponent implements OnChanges {

  @Input() id:any;
  @Input() done:any;
  @Input() clicked:any;
  @Input() responsive: boolean = false;
  changeLog: string[] = [];
  @Output() was_edited = new EventEmitter<any>();
  @Input() estado:any;
  estado_text:any;
  descripcion_estado:any;
  change_giro: boolean = false;





  show_giro: boolean = false;
  show_sim: boolean = false;
  show_ventas: boolean = false;
  show_compras: boolean = false;

  tab_active:any = null;

  constructor(
    public settings: SettingsService) { 

  }

  ngOnChanges(changes: SimpleChanges) {
    const changedProp = changes['clicked'];
    const to = JSON.stringify(changedProp.currentValue);   
    if (to === 'true') {
      this.setStatusValue();
      if (this.responsive) {
        this.tabs(1);
      } else {
        this.showAll();
      } 
    }
  }

  tabs(tab:any) {
    tab = parseInt(tab);
    this.hideAll();
    switch (tab) {
      case 1:
        this.tab_active = 1;
        this.show_giro = true;
      break;
      case 2:
        this.tab_active = 2;
        this.show_sim = true;
      break;
      // case 3:
      //   this.tab_active = 3;
      //   this.show_ventas = true;
      // break;
      // case 4:
      //   this.tab_active = 4;
      //   this.show_compras = true;
      break;      
      default:
        break;
    }

  }

  previous() {
    let tab = null;
    if (this.tab_active == 1) {
      tab = 2;
    } else {
      tab = this.tab_active - 1;
    }
    this.tabs(tab);
  }

  next() {
    let tab = null;
    if (this.tab_active == 2) {
      tab = 1;
    } else {
      tab = this.tab_active + 1;
    }
    this.tabs(tab);
  }

  hideAll() {
    this.show_giro = false;
    this.show_sim = false;
    this.show_ventas = false;
    this.show_compras = false;
  }

  showAll() {
    this.show_giro = true;
    this.show_sim = true;
    this.show_ventas = true;
    this.show_compras = true;
  }

  setStatusValue() {
    switch (this.estado) {
      case '0':
        this.estado_text = "Solicitada";
        this.descripcion_estado = "Esta simulación está en proceso de evaluación";
        break;
      case '1':
        this.estado_text = "En proceso";
        this.descripcion_estado = "Estamos girando el pago a tus proveedores";
        break;
      case '2':
        this.estado_text = "Girada";
        this.descripcion_estado = "¡Todos los giros fueron realizados con éxito!";
        break;
      default:
        break;
    }
  }

  onEdit(edited: any) {
    this.change_giro = !this.change_giro;
  }

  onEditGiro(giro: any) {
    this.edit(giro);
  }

  edit(giro:any) {
    this.was_edited.emit({giro: giro});
  }
}