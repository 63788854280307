import {Component,OnInit,ViewChild,ComponentFactoryResolver,ViewContainerRef,ChangeDetectorRef, Input,} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;

@Component({
selector: 'dashboard-ventas',
templateUrl: './dashboard-ventas.html',
styleUrls: ['./dashboard-ventas.less'],
})

export class DashboardVentas implements OnInit {

@Input() company_id:any;

dash_update() { 
    this.actualizar()
}


cliente: string = '';
// evento que recibe los valores
events: any[] = [];

rut: any = undefined;
tipo = 'cliente';


folders: Section[] = [
    {
    name: 'Photos',
    updated: new Date('1/1/16'),
    },
    {
    name: 'Recipes',
    updated: new Date('1/17/16'),
    },
    {
    name: 'Work',
    updated: new Date('1/28/16'),
    },
];
notes: Section[] = [
    {
    name: 'Vacation Itinerary',
    updated: new Date('2/20/16'),
    },
    {
    name: 'Kitchen Remodel',
    updated: new Date('1/18/16'),
    },
];
nocargar: boolean = false;
cargando3: boolean = true;
constructor(
    public dialog: MatDialog,
    private router: Router,
    public settings: SettingsService,
    private _cfr: ComponentFactoryResolver,
    fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private activated_router: ActivatedRoute,
    public snackBar: MatSnackBar
) {
    this.valFormFinal = fb.group({
    business_name: '',
    });

    this.valFormDias = fb.group({
    name: '',
    });

    this.range = fb.group({
    start: null,
    end: null,
    });


}

// Companys: Empresas[] = [];
@ViewChild('parent', { read: ViewContainerRef }) parent!: ViewContainerRef;
t:any = 'ARRAYAN FACTORING S.A.';
executive: string = 'Sin ejecutivo asignado';
valFormFinal: FormGroup;
myControl = new FormControl();
options: Empresas[] = [];
filteredOptions!: Observable<Empresas[]>;
genkidama:any[] = [];
// form para recibir las fechas
range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
});

valFormDias: FormGroup;
elegida_fecha = false;
selectedValuefecha:any[] = [];
date_elected:any;
mat_select = true;
boton_activo = true;
busque = false;
top:any[] = [
    {
    name: 'Ranking Cesionario',
    id: '0',
    },
    {
    name: 'Participación factoring/cesión',
    id: '1',
    },
    {
    name: 'Relación cesiones/Ventas',
    id: '2',
    },
    {
    name: 'Relación Ventas/NC/Cesiones',
    id: '3',
    },
    {
    name: 'Cesiones',
    id: '4',
    },
    {
    name: 'Participación deudores/cesión',
    id: '5',
    },
    {
    name: 'Ranking deudores cedidos',
    id: '6',
    },
    {
    name: 'Compras/Ventas/N.C',
    id: '7',
    },
    {
    name: 'Relación Compras/Ventas/NC',
    id: '8',
    },
    {
    name: 'Participación deudores/ventas',
    id: '10',
    },
    {
    name: 'Ranking deudores con ventas',
    id: '11',
    },
    {
    name: 'Todos',
    id: '9',
    },
];

showSpinner3 = false;
dataSource3:any;
displayedColumns3 = [
    'empresa',
    'ejecutivo',
    'estado',
    'fecha_a',
    'fecha_r',
    'detalle',
];
ELEMENT_DATA3: any[] = [];
@ViewChild(MatPaginator) paginator3!: MatPaginator;

cargando: boolean = false;
addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (this.range.value.start != null && this.range.value.end != null) {
    let fecha_inicio = new Date(this.range.value.start);

    let y_i = fecha_inicio.getFullYear();
    let m_i: any = fecha_inicio.getMonth() + 1;
    let d_i: any = fecha_inicio.getDate();

    if (m_i < 10) {
        m_i = '0' + m_i;
    }
    if (d_i < 10) {
        d_i = '0' + d_i;
    }

    let string_i_date = y_i + '-' + m_i + '-' + d_i;

    let fecha_final = new Date(this.range.value.end);

    let y_e = fecha_final.getFullYear();
    let m_e: any = fecha_final.getMonth() + 1;
    let d_e: any = fecha_final.getDate();

    if (m_e < 10) {
        m_e = '0' + m_e;
    }
    if (d_e < 10) {
        d_e = '0' + d_e;
    }

    let string_e_date = y_e + '-' + m_e + '-' + d_e;

    this.date_elected = { start: this.date_start, end: this.date_end };
    this.elegida_fecha = true;
    this.mat_select = false;
    } else {
    this.mat_select = true;
    }
}


@Input() date_end:any;
@Input() date_start:any;
ngOnInit() {

    //this.cargar_empresas();
    this.t = localStorage.getItem('business_name');
    //this.actualizar_historial()
    this.rut = localStorage.getItem('rutCompany');
    this.get_table();
    this.myControl.setValue(this.t);
    this.status_carga();
    this.status_deudafiscal();





}

after_oninit() {
    const id_ = this.company_id;
    for (let i = 0; i < this.options.length; i++) {
    if (this.options[i]['id'] == id_) {
        this.executive = this.options[i]['executive'];
    }
    }
}

cargar_empresas = async () => {
    let resultado: any = await this.settings.get_query(
    1,
    'get-nc-compras-ventas/'
    );
    let body_data = resultado._body;

    if (!body_data['error']) {
    this.options = body_data.success;
    this.after_oninit();

    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((state:any) => this._filter(state))
    );
    } else {
    this.settings.empty_local_data();
    }
};

private _filter(value: string): Empresas[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
    (option) =>
        option.business_name.toLowerCase().includes(filterValue) == true
    );
}
prueba:any[] = [];
epa: any[] = [];
parametro = 0;
filtros(filterValue:any) {
    let validador = false;

    for (let ii = 0; ii < filterValue.value.length; ii++) {
    let element_ = filterValue.value[ii];
    if (element_ == '9') {
        validador = true;
    }
    }

    if (validador) {
    this.events = [];
    this.parametro = 0;
    this.prueba = [];
    this.selectedValuefecha = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
    ];
    for (let i = 0; i < this.selectedValuefecha.length; i++) {
        let element__ = this.selectedValuefecha[i];
        // if (this.prueba.includes(element__) === false) {
        if (!(element__ == '9')) {
        this.parametro = this.parametro + 1;
        this.prueba.push(element__);
        this.events.push({
            start: this.date_start,
            end: this.date_end,
            type: element__,
        });
        }

        // }
    }

    // console.log("numero", this.events, this.parametro)

    this.valFormDias.controls['name'].setValue([]);
    this.valFormDias.controls['name'].setValue([
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
    ]);
    } else {
    this.selectedValuefecha = filterValue.value;

    for (let i = 0; i < filterValue.value.length; i++) {
        let element = filterValue.value[i];
        if (this.prueba.includes(element) === false) {
        this.parametro = this.parametro + 1;
        this.prueba.push(element);
        this.events.push({
            start: this.date_start,
            end: this.date_end,
            type: element,
        });
        }
    }
    }

    this.busque = false;
}

manejo_elementos(objeto: any) {
    const accion = parseInt(objeto.type);
    let nombre;
    switch (accion) {
    case 0:
        nombre = 'Ranking Cesionario';
        break;
    case 1:
        nombre = 'Participación factoring/cesión';
        break;
    case 2:
        nombre = 'Relación cesiones/Ventas';
        break;
    case 3:
        nombre = 'Relación Ventas/NC/Cesiones';
        break;
    case 4:
        nombre = 'Cesiones';
        break;
    case 5:
        nombre = 'Participación deudores/cesión';
        break;
    case 6:
        nombre = 'Ranking deudores cedidos';
        break;
    case 7:
        nombre = 'Compras/Ventas/N.C';
        break;
    case 8:
        nombre = 'Relación Compras/Ventas/NC';
        break;
    case 9:
        nombre = 'Todos';
        break;
    case 10:
        nombre = 'Participación deudores/ventas';
        break;
    case 11:
        nombre = 'Ranking deudores con ventas';
        break;
    default:
        nombre = '';
    }

    const texto = objeto.start + ' / ' + objeto.end + ': ' + nombre;
    return texto;
}
ResetarFiltros() {
    const f = this.events.length;
    this.parametro = f;

    this.events = [];
    this.prueba = [];
    this.selectedValuefecha = [];

    this.valFormDias.controls['name'].setValue([]);

    if (!(this.ranking_cesion_obj === undefined)) {
    this.ranking_cesion_obj = undefined;
    }

    if (!(this.participacion_factoring_obj === undefined)) {
    this.participacion_factoring_obj = undefined;
    }

    if (!(this.relacion_cesiones_obj === undefined)) {
    this.relacion_cesiones_obj = undefined;
    }

    if (!(this.relacion_ventas_obj === undefined)) {
    this.relacion_ventas_obj = undefined;
    }

    if (!(this.participacion_deudores_obj === undefined)) {
    this.participacion_deudores_obj = undefined;
    }

    if (!(this.ranking_deudores_obj === undefined)) {
    this.ranking_deudores_obj = undefined;
    }

    if (!(this.cvnc_obj === undefined)) {
    this.cvnc_obj = undefined;
    }

    if (!(this.graf_cvnc_obj === undefined)) {
    this.graf_cvnc_obj = undefined;
    }

    if (!(this.cesiones_obj === undefined)) {
    this.cesiones_obj = undefined;
    }

    if (!(this.participacion_deudores_ventas_obj === undefined)) {
    this.participacion_deudores_ventas_obj = undefined;
    }

    if (!(this.ranking_deudores_ventas_obj === undefined)) {
    this.ranking_deudores_ventas_obj = undefined;
    }

    this.genkidama = [];
    this.range.setValue({
    start: null,
    end: null,
    });
    this.mat_select = true;
}
emit_components(confirmation: string) {
    this.genkidama.push(confirmation);
    let valor_de_consulta = this.parametro;

    if (valor_de_consulta == 0) {
    valor_de_consulta = 10;
    }

    if (this.genkidama.length >= valor_de_consulta) {
    this.myControl.enable();
    this.cargar_empresas();
    this.range.controls['start'].enable();
    this.range.controls['end'].enable();
    this.boton_activo = true;
    if (this.range.value.start != null && this.range.value.end != null) {
        this.mat_select = false;
    }
    } else {
    this.myControl.disable();
    this.range.controls['start'].disable();
    this.range.controls['end'].disable();
    this.mat_select = true;
    }
}

selected = ($e:any) => {
    const id = parseInt($e.id);
    for (let i = 0; i < this.options.length; i++) {
    if (this.options[i]['id'] == id) {
        this.executive = this.options[i]['executive'];
    }
    }
    this.t = $e.value;
    this.company_id = $e.id;
    this.genkidama = [];
    localStorage.setItem('id_company', this.company_id.toString());
    localStorage.setItem('business_name', this.t);
    this.status_carga();
    this.status_deudafiscal();
};

borrar(indice:any) {
    if (this.busque == true) {
    this.boton_activo = false;
    this.myControl.disable();
    this.range.controls['start'].disable();
    this.range.controls['end'].disable();
    this.mat_select = true;
    }
    if (this.selectedValuefecha.indexOf('9') > -1) {
    this.selectedValuefecha.splice(this.selectedValuefecha.indexOf('9'), 1);
    }

    this.parametro = 0;
    let accion = parseInt(indice.type);
    let i = this.prueba.indexOf(indice.type);
    this.events.splice(i, 1);
    this.prueba.splice(i, 1);
    this.selectedValuefecha.splice(
    this.selectedValuefecha.indexOf(indice.type),
    1
    );

    const f = this.selectedValuefecha;

    if (this.events.length == 0) {
    // console.log("tiene tamaño cero")
    this.selectedValuefecha = [];
    this.valFormDias.controls['name'].setValue([]);
    } else {
    this.valFormDias.controls['name'].setValue([]);
    this.valFormDias.controls['name'].setValue(f);
    }
    switch (accion) {
    case 0:
        if (!(this.ranking_cesion_obj === undefined)) {
        this.ranking_cesion_obj = undefined;
        this.busque == true;
        }
        if (this.busque == true) {
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.ranking_cesion_obj = undefined;
        break;
    case 1:
        if (!(this.participacion_factoring_obj === undefined)) {
        this.participacion_factoring_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }

        break;
    case 2:
        if (!(this.relacion_cesiones_obj === undefined)) {
        this.relacion_cesiones_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.relacion_cesiones_obj = undefined;
        break;
    case 3:
        if (!(this.relacion_ventas_obj === undefined)) {
        this.relacion_ventas_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.relacion_ventas_obj = undefined;
        break;
    case 4:
        if (!(this.cesiones_obj === undefined)) {
        this.cesiones_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.cesiones_obj = undefined;
        break;
    case 5:
        if (!(this.participacion_deudores_obj === undefined)) {
        this.participacion_deudores_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.participacion_deudores_obj = undefined;
        break;
    case 6:
        if (!(this.ranking_deudores_obj === undefined)) {
        this.ranking_deudores_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.ranking_deudores_obj = undefined;
        break;
    case 7:
        if (!(this.cvnc_obj === undefined)) {
        this.cvnc_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.cvnc_obj = undefined
        break;
    case 8:
        if (!(this.graf_cvnc_obj === undefined)) {
        this.graf_cvnc_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.graf_cvnc_obj = undefined
        break;
    case 10:
        if (!(this.participacion_deudores_ventas_obj === undefined)) {
        this.participacion_deudores_ventas_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.participacion_deudores_obj = undefined;
        break;
    case 11:
        if (!(this.ranking_deudores_ventas_obj === undefined)) {
        this.ranking_deudores_ventas_obj = undefined;
        this.busque == true;
        this.boton_activo = false;
        this.myControl.disable();
        this.range.controls['start'].disable();
        this.range.controls['end'].disable();
        this.mat_select = true;
        }
        // this.ranking_deudores_obj = undefined;
        break;
    default:
        this.ranking_cesion_obj = undefined;
        this.participacion_factoring_obj = undefined;
        this.relacion_cesiones_obj = undefined;
        this.relacion_ventas_obj = undefined;
        this.participacion_deudores_obj = undefined;
        this.ranking_deudores_obj = undefined;
        this.participacion_deudores_ventas_obj = undefined;
        this.ranking_deudores_ventas_obj = undefined;
        this.cvnc_obj = undefined;
        this.graf_cvnc_obj = undefined;
        this.cesiones_obj = undefined;
    }

    // if (this.busque == true) {
    //   this.boton_activo = false
    // this.myControl.disable()
    // this.range.controls['start'].disable();
    // this.range.controls['end'].disable();
    // this.mat_select = true
    // }
    this.parametro = 1;
    this.genkidama = [];
}

ranking_cesion_obj:any;
participacion_factoring_obj:any;
relacion_cesiones_obj:any;
relacion_ventas_obj:any;
participacion_deudores_obj:any;
ranking_deudores_obj:any;
cvnc_obj:any;
graf_cvnc_obj:any;
cesiones_obj:any;
participacion_deudores_ventas_obj:any;
ranking_deudores_ventas_obj:any;

filtrartodo() {
    this.busque = true;
    this.parametro = this.events.length;

    for (let i = 0; i < this.events.length; i++) {
    let element = this.events[i];
    let accion = parseInt(element.type);
    switch (accion) {
        case 0:
        if (this.ranking_cesion_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.ranking_cesion_obj = element;
        break;
        case 1:
        if (this.participacion_factoring_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.participacion_factoring_obj = element;
        break;
        case 2:
        if (this.relacion_cesiones_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.relacion_cesiones_obj = element;
        break;
        case 3:
        if (this.relacion_ventas_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.relacion_ventas_obj = element;
        break;
        case 4:
        if (this.cesiones_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.cesiones_obj = element;
        break;
        case 5:
        if (this.participacion_deudores_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.participacion_deudores_obj = element;
        break;
        case 6:
        if (this.ranking_deudores_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.ranking_deudores_obj = element;
        break;
        case 7:
        if (this.cvnc_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.cvnc_obj = element;
        break;
        case 8:
        if (this.graf_cvnc_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.graf_cvnc_obj = element;
        break;
        case 9:
        this.parametro = this.parametro - 1;
        break;
        case 10:
        if (this.participacion_deudores_ventas_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.participacion_deudores_ventas_obj = element;
        break;
        case 11:
        if (this.ranking_deudores_ventas_obj == element) {
            this.parametro = this.parametro - 1;
        }
        this.ranking_deudores_ventas_obj = element;
        break;
        default:
        this.ranking_cesion_obj = undefined;
        this.participacion_factoring_obj = undefined;
        this.relacion_cesiones_obj = undefined;
        this.relacion_ventas_obj = undefined;
        this.participacion_deudores_obj = undefined;
        this.ranking_deudores_obj = undefined;
        this.cvnc_obj = undefined;
        this.graf_cvnc_obj = undefined;
        this.cesiones_obj = undefined;
        this.participacion_deudores_ventas_obj = undefined;
        this.ranking_deudores_ventas_obj = undefined;
    }
    }

    this.genkidama = [];
}

async actualizar() {
    this.cargando = true; 

    $("#super-cargando").removeClass("supercargando");

    $("#disable_ventas").addClass("disable_btn_llamativo");
    $("#show-spinner").removeClass("dis-spinner")

    try {
    const values = await Promise.all([
        this.scrapResumen12Months(),
        this.actualizarLibros()
    ]);

    this.cargando = false;
    $("#super-cargando").addClass("supercargando");
    } catch (error) {
    this.cargando = false;
    $("#super-cargando").addClass("supercargando");
    console.log(error);
    throw new Error('Error al actualizar');
    }
}

listenEventOnReloadFalse(event:any) {
    console.log('set default on dashboard');
    this.onReloadResumen = false;
}

onReloadResumen: boolean = false;
async scrapResumen12Months() {
    try {
    const body_data: any = await this.settings.get_query(
        2,
        `resumen-dtes-sii/${this.company_id}/`
    );

    const _body = body_data._body.success;
    if (_body['ok']) {

        this.onReloadResumen = true;
    }
    } catch (error) {
        this.snackBar.open(
            'La clave tributaria no es válida o el SII no esta disponible en estos momentos, por favor intentelo más tarde.',
            'Aceptar',
            {
                duration: 10000,
                panelClass: "simpli-snack",
            }
            );
    throw new Error('Error al obtener resumen de 12 meses');
    }
}

www = 0;

async actualizarLibros() {
    this.cargando = true;
    try {
    let resultado: any = await this.settings.get_query(
        2,
        'sii-empresa/' + this.company_id + '/'
    );
    let body_data = resultado._body;

    // console.log(body_data)
    if (resultado.status === 400) {
        this.snackBar.open(body_data.success.message, 'Aceptar', {
        panelClass: "simpli-snack",
        duration: 10000,
        });
        this.cargando = false;
        $("#disable_ventas").removeClass("disable_btn_llamativo");
        $("#show-spinner").addClass("dis-spinner")
        
        return;
    }

    if (!body_data['error']) {
        this.ResetarFiltros();
        // this.options = body_data.success;
        this.activated_router.params.subscribe((params:any) => {
        this.ngOnInit();
        let dias = this.modifico_fecha();

        if (this.www == 0) {
            this.ranking_cesion_obj = dias;
            this.participacion_factoring_obj = dias;
            this.relacion_cesiones_obj = dias;
            this.relacion_ventas_obj = dias;
            this.participacion_deudores_obj = dias;
            this.ranking_deudores_obj = dias;
            this.cvnc_obj = dias;
            this.graf_cvnc_obj = dias;
            this.cesiones_obj = dias;
            this.participacion_deudores_ventas_obj = dias;
            this.ranking_deudores_ventas_obj = dias;
            this.www = 1;
        } else {
            this.www = 0;
            this.ranking_cesion_obj = undefined;
            this.participacion_factoring_obj = undefined;
            this.relacion_cesiones_obj = undefined;
            this.relacion_ventas_obj = undefined;
            this.participacion_deudores_obj = undefined;
            this.ranking_deudores_obj = undefined;
            this.cvnc_obj = undefined;
            this.graf_cvnc_obj = undefined;
            this.cesiones_obj = undefined;
            this.participacion_deudores_ventas_obj = undefined;
            this.ranking_deudores_ventas_obj = undefined;
        }
        });


        this.cargando = false;
    } else {
        this.cargando = false;

        this.snackBar.open(
        'Clave SII es invalida, por favor verifique la autenticidad de dicha contraseña',
        'Aceptar',
        {
            duration: 10000,
            panelClass: "simpli-snack",
        }
        );
        // this.settings.empty_local_data();
    }
    this.cargando = false;
    } catch (error) {
    console.log(error);
    this.snackBar.open(
        'Hubo un error por favor intentalo de nuevo.',
        'Aceptar',
        {
        duration: 10000,
        panelClass: "simpli-snack",
        }
    );
    // this.cargando = false;
    // this.settings.empty_local_data();
    }

    this.actualizar_historial();
}


modifico_fecha() {
    let mes_actual;
    let mes_menos;
    //Mes actual
    let mensual = new Date();
    let mes_actual_ultimo = new Date(
    mensual.getFullYear(),
    mensual.getMonth() + 1,
    0
    );
    let mes1 = new Date(mes_actual_ultimo);
    let y1 = mes1.getFullYear();
    let m1: any = mes1.getMonth() + 1;
    let d1: any = mes1.getDate();
    if (m1 < 10) {
    m1 = '0' + m1;
    }
    if (d1 < 10) {
    d1 = '0' + d1;
    }
    mes_actual = y1 + '-' + m1 + '-' + d1;

    //Mes -6
    let mesmenos = new Date(
    mes_actual_ultimo.getTime() - 3650 * 60 * 60 * 1000 * 1
    );
    let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
    let mes6 = new Date(ultimomenos);
    let y2 = mes6.getFullYear();
    let m2: any = mes6.getMonth() + 1;
    let d2: any = mes6.getDate();
    if (m2 < 10) {
    m2 = '0' + m2;
    }
    if (d2 < 10) {
    d2 = '0' + d2;
    }
    mes_menos = y2 + '-' + m2 + '-' + d2;

    return { start: this.date_start, end: this.date_end };
}

actualizar_historial = async () => {
    this.cargando = true;
    let resultado: any = await this.settings.get_query(
    2,
    'get-libro/' + this.company_id + '/'
    );
    let body_data = resultado._body;

    // console.log(body_data)

    if (!body_data['error']) {
    this.ResetarFiltros();
    // this.options = body_data.success;
    this.activated_router.params.subscribe((params:any) => {
        this.ngOnInit();
        let dias = this.modifico_fecha();

        if (this.www == 0) {
        this.ranking_cesion_obj = dias;
        this.participacion_factoring_obj = dias;
        this.relacion_cesiones_obj = dias;
        this.relacion_ventas_obj = dias;
        this.participacion_deudores_obj = dias;
        this.ranking_deudores_obj = dias;
        this.cvnc_obj = dias;
        this.graf_cvnc_obj = dias;
        this.cesiones_obj = dias;
        this.participacion_deudores_ventas_obj = dias;
        this.ranking_deudores_ventas_obj = dias;
        this.www = 1;
        } else {
        this.www = 0;
        this.ranking_cesion_obj = undefined;
        this.participacion_factoring_obj = undefined;
        this.relacion_cesiones_obj = undefined;
        this.relacion_ventas_obj = undefined;
        this.participacion_deudores_obj = undefined;
        this.ranking_deudores_obj = undefined;
        this.cvnc_obj = undefined;
        this.graf_cvnc_obj = undefined;
        this.cesiones_obj = undefined;
        this.participacion_deudores_ventas_obj = undefined;
        this.ranking_deudores_ventas_obj = undefined;
        }

        $("#disable_ventas").removeClass("disable_btn_llamativo");
        $("#show-spinner").addClass("dis-spinner")
    });


    } else {
    this.snackBar.open(
        'Clave SII es invalida, por favor verifique la autenticidad de dicha contraseña',
        'Aceptar',
        {
        duration: 10000,
        panelClass: "simpli-snack",
        }
    );
    // this.settings.empty_local_data();
    }
    this.cargando = false;
};

events_obj:any;
events_historial:any[] = [];
status_clave: boolean = false;
status_carga = async () => {
    let resultado: any = await this.settings.get_query(
    1,
    'get-libro/' + this.company_id + '/'
    );
    let body_data = resultado._body;

    if (!body_data['error']) {
    this.events_obj = body_data.libro;
    this.events_historial = body_data.cesion;
    this.status_clave = body_data.status_clave;
    // console.log("EVENTS", this)
    } else {
    this.settings.empty_local_data();
    }
};

Downloadpdf = async () => {
    let resultado: any = await this.settings.get_query(
    1,
    'cert-deuda-fiscal/' + this.company_id + '/'
    );
    let body_data = resultado._body;
    if (!body_data['error']) {
    window.open(body_data.pdf);
    } else {
    this.snackBar.open(body_data.error, 'Aceptar', {
        duration: 10000,
        panelClass: "simpli-snack",
    });
    //
    }
};


fecha = null;
status_deudafiscal = async () => {
    let resultado: any = await this.settings.get_query(
    1,
    'status-deuda-fiscal/' + this.company_id + '/'
    );
    let body_data = resultado._body;
    if (!body_data['error']) {
    this.fecha = body_data.fecha;
    console.log('FECHA', this.fecha);
    } else {
    }
};

regresar() {
    setTimeout(() => {
    this.router.navigate(['clientes']);
    }, 500);
}




tabIndex = 0;

changeTab(event:any) {
    this.tabIndex = event.index;
}

get_table = async () => {
    const resultado: any = await this.settings.get_query(
    1,
    'visitasrealizadasempresa/' + this.company_id + '/'
    );
    let body_data = resultado._body;
    if (!body_data.error) {
    if (body_data.success.length > 0) {
        this.ELEMENT_DATA3 = body_data.success;
        // this.show_msg = false;
        setTimeout(() => {
        this.dataSource3 = new MatTableDataSource(this.ELEMENT_DATA3);
        this.dataSource3._updateChangeSubscription();
        this.dataSource3.paginator = this.paginator3;
        this.cargando3 = false;
        });
    } else {
        // this.show_msg = true;
        setTimeout(() => {
        this.dataSource3 = new MatTableDataSource(this.ELEMENT_DATA3);
        this.dataSource3._updateChangeSubscription();
        this.dataSource3.paginator = this.paginator3;
        this.cargando3 = false;
        });
    }
    }
};




}

export interface Empresas {
id: number;
business_name: string;
executive: string;
}

export interface Section {
name: string;
updated: Date;
}

export interface Deudores {
id?: any;
file: any;
date: any;
}

export interface OP {
id?: any;
file: any;
date: any;
}
  