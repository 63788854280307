import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { DocumentPreviewComponent } from '../document-preview/document-preview';
import { DialogDeleteAec } from './dialog-delete-aec/dialog-delete-aec';
import { AlertSuccessCesion } from '../alert-success-cesion/alert-success-cesion';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';



declare var $: any;


@Component({
  selector: 'app-aec-generados',
  templateUrl: './aec-generados.html',
  styleUrls: ['./aec-generados.less']
})
export class AecGeneradados implements OnInit {


  @Input() id_company:any;
  @Input() parent:any;
  @Input() id_sim:any;
  @Input() id_operation:any;
  @Input() manual:boolean=false;
  @Input() sii_key:string='';


  constructor(public _MatPaginatorIntl: MatPaginatorIntl,public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder,
    public snackBar:MatSnackBar, private router:Router, public dialogRef:MatDialogRef<AecGeneradados> ) {



    this.id_company = localStorage.getItem('company_id');



   }

  facturas:any[]=[];
  displayedColumns: string[] = ['operacion','folio','monto','factura','aec','delete'];
  displayedColumnsRes: string[] = ['operacion'];
  dataSource = new MatTableDataSource(this.facturas);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  is_responsive:boolean=false;
  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this._MatPaginatorIntl.firstPageLabel = 'primera página';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Facturas por página';
    this._MatPaginatorIntl.lastPageLabel = 'ultima página';
    this._MatPaginatorIntl.nextPageLabel = 'siguiente';
    this._MatPaginatorIntl.previousPageLabel = 'anterior';

    this.get_aec();

  }

  programo_una_gestion:boolean=false;
  private rojaSubject = new BehaviorSubject<boolean>(this.programo_una_gestion);
  programo_una_gestion$ = this.rojaSubject.asObservable();

  show_error_mensaje:boolean=false;
  async get_aec(){


    try {

      const bd: any = await this.settings.get_query(1, '/cliente/cesiones/'+this.id_sim+'/info-aec-simulation/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          console.log("get aec", data)

          this.facturas = data.data;

          for(let i in this.facturas){
            if(this.facturas[i].aec_file == null){
              this.show_error_mensaje = true;
              break;
            }else{
              this.show_error_mensaje = false;
            }
          }


          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource.paginator = this.paginator;
            this.dataSource._updateChangeSubscription()
          });




        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  download_aec(aec:any){
    window.open(aec), "__blank"
  }


  loading_delete:boolean=false;
  async delete_aec(aec_id:any){
    try {
      this.loading_delete = true;
      const bd: any = await this.settings.get_query(3, 'cliente/cesiones/'+this.id_sim+'/aec-simulation/?aec_id='+aec_id);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.loading_delete = false;


          this.programo_una_gestion = true;
          this.rojaSubject.next(this.programo_una_gestion);


          this.get_aec();




        break;
        default:

        break;
      }

    } catch (bd:any) {
      this.loading_delete = false;
      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  show_preview(url:any){
    if(this.is_responsive == true){
      let dialogRef = this.dialog.open(DocumentPreviewComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive-white',
        autoFocus: false,
        disableClose: false,
      });
      dialogRef.componentInstance.url = url;

      dialogRef.afterClosed().subscribe((result:any) => {

      });
    }else{
      let dialogRef = this.dialog.open(DocumentPreviewComponent, {
        minWidth: '95vw',
        maxWidth: '95vw',
        maxHeight: '95vh',
        minHeight: '95vh',
        autoFocus: false,
      });
      dialogRef.componentInstance.url = url;

      dialogRef.afterClosed().subscribe((result:any) => {

      });
    }

  }

  delete_aec_dialog(aec_id:any){

    const dialogRef = this.dialog.open(DialogDeleteAec,{
      width: '600px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.title = "¡Estás a punto de eliminar un AEC!"
    dialogRef.componentInstance.subtitle = "Todas las factura deben contar con un AEC asociado. Si eliminas el archivo, no podrás realizar la cesión."

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.delete_aec(aec_id)
      }
    });
  }

  loading_zip:boolean=false;;
  async download_zip(){
    try {

      this.loading_zip = true;
      const bd: any = await this.settings.get_query(1, '/cliente/cesiones/'+this.id_sim+'/aec-compress/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        this.loading_zip = false;

        window.open(data.data.url_file, "__blank")

        break;
        default:

        break;
      }

    } catch (bd:any) {
      this.loading_zip = false;
      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  loading_delete_all:boolean=false;;
  async delete_all_aec(){
    try {

      this.loading_delete_all = true;
      const bd: any = await this.settings.get_query(3, '/cliente/cesiones/'+this.id_sim+'/aec-simulation/?aec_id=0');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        this.loading_delete_all = false;

        this.programo_una_gestion = true;
        this.rojaSubject.next(this.programo_una_gestion);

        this.get_aec();

        break;
        default:

        break;
      }

    } catch (bd:any) {
      this.loading_delete_all = false;
      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  delete_all_aec_dialog(){

    const dialogRef = this.dialog.open(DialogDeleteAec,{
      width: '600px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.title = "¡Estás a punto de eliminar todos los AEC!"
    dialogRef.componentInstance.subtitle = "Si lo haces, no podrás realizar la cesión. Cierra la ventana para volver o reemplaza los AEC cargados."

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){

        this.delete_all_aec()

        const dialogRef = this.dialog.open(DialogDeleteAec,{
          width: '600px',
          disableClose: true,
          maxWidth: '95vw',
          autoFocus:false,
        });

        dialogRef.componentInstance.title = "¡Los AEC fueron eliminados!"
        dialogRef.componentInstance.subtitle = "Carga nuevos AEC para retomar la cesión o elige otro método para ceder."
        dialogRef.componentInstance.show_icon_delete = true;

        dialogRef.afterClosed().subscribe((result:any) => {
          if(result == 1){
            this.dialogRef.close(1)
          }

          if(result == 2){
            this.dialogRef.close(2)
          }
        });
      }
    });
  }

  volver_a_generar_aec(){
    this.dialogRef.close(3)
  }

  cargar_aec(){
      this.dialogRef.close(5)
  }

  ceder_por_otro_meotodo(){
      this.dialogRef.close(2)

  }

  ceder_facturas(){
    this.dialogRef.close(10)
    this.ceder_por_la_chucha();

    const dialogRef = this.dialog.open(AlertSuccessCesion,{
      width: '600px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.title = "¡La cesión está en validación!"
    dialogRef.componentInstance.subtitle = "Para conocer el avance de esta etapa, mira el estado de la operación."

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.router.navigate(['home/detalle-operacion/',this.id_sim])
      }
    });
  }

  async ceder_por_la_chucha(){
    try {


      const payload = {
        'sii_pass': this.sii_key
      }

      const bd: any = await this.settings.get_query(2, `/cliente/cesiones/${this.id_company}/ceder-facturas-aec/?simulation=${this.id_sim}&operation_id=${this.id_operation}`,payload);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          console.log("cesion response", body_data)


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

}
