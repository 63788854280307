import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import * as Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AddCta } from '../../payments/pago-seleccion/add-cta';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

declare var $: any;


@Component({
  selector: 'app-facturas-venta',
  templateUrl: './facturas-venta.html',
  styleUrls: ['./facturas-venta.less']
})

export class FacturasVenta implements OnInit {

  
  id_company;

  @Input() deuda:any;
  @Input() deuda_total:any;
  @Input() id_simulation:any;
  @Input() manual_ventas:boolean=false;    
  @Input() simulacion_recibida:any;
  @Input() facturas_venta:any;
  @Input() facturas:any;

  ELEMENT_DATA: Array<any>=[];
  displayedColumns: string[] = ['select','folio','receptoracreedor_name','receptoracreedor_rut','mnt_total','fecha_emision','fecha_vencimiento'];

  dataSource = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);

  @ViewChild('MatPaginator2') paginator2!: MatPaginator;
  @ViewChild('MatPaginator3') paginator3!: MatPaginator;

  firstFormGroup: FormGroup;
  secondFormGroup!: FormGroup;
  optionalLabelText!: string;
  optionalLabelTextChoices: string[] = ['Option 1', 'Option 2', 'Option 3'];

  constructor(public snackBar: MatSnackBar,public settings: SettingsService,
     private fb: FormBuilder, public dialog:MatDialog, public dialogRef:MatDialogRef<FacturasVenta>) { 

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      filter: ['']
    });

    this.id_company = localStorage.getItem('company_id')



  }


  ngOnInit() {

    this.get_facturas_venta()

  }


  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];


  cargando:boolean=true;
  esta_todo_listo:boolean=false;
  total=0;
  agregar_vencimiento:boolean=false;

  simulation={'anticipo':0,'vencimiento':0,'comision':0,'gastosOperacionales':0,'iva_operaciones':0,'interesTotal':0,'liquidoRecibir':0, 'porPagar':false,'porPagarMonto':0};
  has_simulation:boolean=false;
  por_pagar=0;
  debe_pagar:boolean=false;

  //simulation
  operacionAnticipo=0;
  gastosOperacionales=0;
  tasa_porc=0;
  anticipo_porc=0;
  vencimiento=30;

  recalcular:boolean=true;
  async get_facturas_venta(filter?:any) {
    try {
      this.cargando = true;

      const bd: any = await this.settings.get_query(1, 'PagoproveedoresSimulation/'+this.id_company +'/?pageNumber=' +this.page + '&deuda='+ this.deuda + '&filter='+this.filter + '&id_simulation=' + this.id_simulation);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.has_simulation = body_data.simulationSuccess;
        this.ELEMENT_DATA = body_data.ventas;

        if (filter != "" && filter != null) {
          this.has_simulation = true;
        }

        let array: Array<any>=[]

        let date_hoy = new Date();
        date_hoy.setDate(date_hoy.getDate() + 30);

        if(this.recalcular == true){
          this.simulation = body_data.simulation;
          this.operacionAnticipo = body_data.simulation.operacionAnticipo;
          this.gastosOperacionales = Number(body_data.simulation.gastosOperacionales);
          this.anticipo_porc = Number(body_data.simulation.anticipo_porc);
          this.tasa_porc = Number(body_data.simulation.tasa_porc);
          this.vencimiento = Number(body_data.simulation.vencimiento);
          this.por_pagar = body_data.simulation.porPagarMonto;
          this.debe_pagar = body_data.simulation.porPagar;


          this.ELEMENT_DATA.forEach(item => {
            if(item.simulado == true){
              array.push(item.id)
              this.activa(item.id, item)
            }
          })
        }

        for(let i=0; i < this.ELEMENT_DATA.length; i++){          
          if(array.includes(this.ELEMENT_DATA[i].id)){}
          else{
            this.ELEMENT_DATA[i].simulado=false
          }

          for(let l=0; l < this.arr_documentos_to_simulate.length; l++){
            if (this.arr_documentos_to_simulate[l].id == this.ELEMENT_DATA[i].id && this.arr_documentos_to_simulate[l].simulado == true) {
              this.ELEMENT_DATA[i].simulado = true
              this.activa(this.ELEMENT_DATA[i].id, this.ELEMENT_DATA[i])
              array.push(this.ELEMENT_DATA[i].id)
            }
          } 
        }

        this.length = body_data.totalVentas;

        this.fecha_todas_simulation(date_hoy)

        setTimeout(() => {
          this.dataSource=new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource._updateChangeSubscription();
        });

        this.recalcular = false;
        this.cargando = false;


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando = false;
    }
  }

  filter="";
  filtrar(){
    this.filter = this.firstFormGroup.controls['filter'].value;
    if(this.filter != ""){
      this.get_facturas_venta(true)
    }
  }

  reset(filter:any){
    if(filter == "" || filter == null){
      this.filter = "";
      this.firstFormGroup.controls['filter']
      this.get_facturas_venta()
    }
  }

  

  volver(){
    this.reset("")
  }

  Borrar_Filtros(){
    this.filter = "";
    this.get_facturas_venta(true)
  }

  loading_scrapping:boolean=true;
  async scrapping() {
    try {
      this.loading_scrapping = true
      const bd: any = await this.settings.get_query(1, 'get-libro-confirming/'+this.id_company+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.loading_scrapping = false;

        this.post_facturas()


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loading_scrapping = false
    }
  }

  ELEMENT_DATA2: Array<any>=[];
  dataSource2 = new MatTableDataSource<Facturas2>(this.ELEMENT_DATA2);
  dataSource3 = new MatTableDataSource<Facturas2>(this.ELEMENT_DATA2);
  displayedColumns2: string[] = ['cta','folio','receptoracreedor_name','receptoracreedor_rut','mnt_total','fecha_emision'];
  displayedColumns3: string[] = ['cta','receptoracreedor_name','receptoracreedor_rut','estado'];
  siguiente:boolean=true;
 
  async post_facturas() {
    try {
      this.cargando = true;
      let value = {'facturas':this.facturas}

      const bd: any = await this.settings.get_query(2, 'EmitirPagoConfirmigAcreedores/'+this.id_company+'/?procesado=0',value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.ELEMENT_DATA2 = body_data.facturas;

        let suma = 0
        let valid:boolean=false;
        for(let i=0; i<body_data.facturas.length;i++){
          suma = suma + body_data.facturas[i].mnt_total;
          if(body_data.facturas[i].validBanco == true){
            this.desactivar_siguiente = false;
            valid = true
            this.siguiente = false;
          }else{
            this.desactivar_siguiente = true;
            valid = false;
            this.siguiente = true;
            break;
          }
        }
        this.total = suma;

        var distinc = [], output: Array<any> = [], l = this.ELEMENT_DATA2.length, i;
        for( i=0; i<l; i++) {
            if( distinc[this.ELEMENT_DATA2[i].ultimorutacreedor]) continue;
            distinc[this.ELEMENT_DATA2[i].ultimorutacreedor] = true;
            output.push(this.ELEMENT_DATA2[i]);
        }
    


        setTimeout(() => {
          this.dataSource2=new MatTableDataSource(this.ELEMENT_DATA2);
          this.dataSource2._updateChangeSubscription();
          this.dataSource2.paginator = this.paginator2;

          this.dataSource3=new MatTableDataSource(output);
          this.dataSource3._updateChangeSubscription();
          this.dataSource3.paginator = this.paginator3;

        });

        
        this.cargando = false

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


  displayspinner:boolean=false;
  mostrar_descargar:boolean=false;
  url_csv:any;
  async download_csv() {
    try {

      this.displayspinner = true;
      this.getFechasVenc();
      let value = {'facturas':this.ELEMENT_DATA2, 'simulation':this.simulation, 'ventas':this.arr_documentos_to_simulate, 'rol':0}

      const bd: any = await this.settings.get_query(2, 'EmitirPagoConfirmigAcreedores/'+this.id_company+'/?procesado=1', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.displayspinner = false;

        this.open_input_dialog("Actualización", "La simulación fue creada exitosamente", 
          "¡Todo listo! Se añadieron las nuevas facturas y se recalculo la simulación",
          "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Proveedores","check_circle","#3BA46F")
  
        this.dialogRef.close()



          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando = false;
    }
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  create_account(id:any,rut:any,nombre:any) {
    let dialogRef = this.dialog.open(AddCta,{
      disableClose:true
    });
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = id;
    dialogRef.componentInstance.dialog_open = true;
    dialogRef.componentInstance.rut_acreedor = rut;
    dialogRef.componentInstance.nombre_acreedor = nombre;
    dialogRef.afterClosed().subscribe((result:any) => { 
      console.log("ADD CTA", result)

      if(result != undefined){
        this.asignar_banco(result.address.rut, result.address.id)
      }

      this.check_bank()
      
    });
  }

  seleccionar_banco($e: any, ultimorutacreedor:any){

    //this.ELEMENT_DATA[index]['id_bank'] = Number($e.target.value)
    let id_bank = Number($e.target.value)
    this.asignar_banco(ultimorutacreedor, id_bank)

  }

  bloquear_siguiente:boolean=true;
  asignar_banco(ultimorutacreedor:any, id_bank:any){


    this.ELEMENT_DATA2.forEach(item => {
      if(item.ultimorutacreedor == ultimorutacreedor){
        item.id_bank = id_bank;
      }
    })


    for(let i=0; i<this.ELEMENT_DATA2.length;i++){
      if(this.ELEMENT_DATA2[i].validBanco == true && this.ELEMENT_DATA2[i].id_bank != null){
        this.esta_todo_listo = true;
      }else{
        this.esta_todo_listo = false;
        break;
      }
      
    }
    
    console.log("assing", this.ELEMENT_DATA2)

  }

  controlar_cuentas(){
    console.log("elementdata2", this.ELEMENT_DATA2)
    this.ELEMENT_DATA2.forEach(item => {

      let id_bank = item.bancos[0].id;
      let ultimorutacreedor = item.ultimorutacreedor;
      this.asignar_banco(ultimorutacreedor, id_bank)
    })

    console.log("finish", this.ELEMENT_DATA2)

  }

  desactivar_siguiente:boolean=true;
  check_bank(){

    let valid:boolean=false;
    for(let i=0; i<this.ELEMENT_DATA2.length;i++){
      if(this.ELEMENT_DATA2[i].id_bank != null){
        this.desactivar_siguiente = false;
        valid = true;
        this.siguiente = false;
      }else{
        this.desactivar_siguiente = true;
        valid = false;
        this.siguiente = true;
        break;
      }
    }
    
  }

  
  siguiente_paso:boolean=true;
  check_vencimiento(){
    for(let i=0; i<this.ELEMENT_DATA2.length;i++){
      if(this.ELEMENT_DATA2[i].fecha_vencimiento != null && this.ELEMENT_DATA2[i].fecha_vencimiento != undefined){
        this.siguiente_paso = false;
      }else{
        this.siguiente_paso = true;
        break;
      }
    }

  }

  minDate:any = new Date()
  fecha_individual($e: any, index:any){

    console.log("fila", this.ELEMENT_DATA[index])

    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    console.log("dias", diffDays)
    console.log("date", date_set)

    this.ELEMENT_DATA[index]["fecha_vencimiento"] = date_set
    this.ELEMENT_DATA[index]["dias"] = diffDays;



    console.log("simulate",this.arr_documentos_to_simulate)
    console.log("after elementdata", this.ELEMENT_DATA[index])

    this.recalculate_change();
    // this.check_vencimiento()

  }

  recalculate($e: any){

    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    console.log(diffDays, date_set)

    this.vencimiento = diffDays;


    //HACER SIMULACION

    let acumuladorVentas=0;
    let comision=50000;
    let iva_operaciones=0;
    let interesTotal=0;
    let liquidoRecibir=0;
    let porPagar:boolean=false;
    let porPagarMonto=0;

    for(let i in this.arr_documentos_to_simulate){
      acumuladorVentas=acumuladorVentas+this.arr_documentos_to_simulate[i].mnt_total;
      comision=0.005*(acumuladorVentas*this.operacionAnticipo);

      this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
      this.arr_documentos_to_simulate[i]["dias"] = diffDays

      if(comision < 50000){
        comision=50000
      }

      iva_operaciones=(Number(this.gastosOperacionales) + Number(comision))*0.19;
      //30 es cantidad de dias
      let dias_vencimiento_default = 30;
      if(this.arr_documentos_to_simulate[i].fecha_vencimiento != null && this.arr_documentos_to_simulate[i].fecha_vencimiento != undefined){
        dias_vencimiento_default = this.arr_documentos_to_simulate[i].dias;
      }
      interesTotal= this.arr_documentos_to_simulate[i].mnt_total * this.anticipo_porc * this.tasa_porc / 30 * dias_vencimiento_default;
      liquidoRecibir=(acumuladorVentas*this.operacionAnticipo)-Number(comision)-Number(this.gastosOperacionales)-Number(iva_operaciones)-Number(interesTotal);

      // if(liquidoRecibir > this.deuda){
      //   break;
      // }

    }

    if(Number(liquidoRecibir) > this.deuda){
      this.debe_pagar = true;
      this.por_pagar = Number(liquidoRecibir) - this.deuda;
      this.disabled_next = false;
    }else{
      //SI SE CUMPLE AQUI NO DEJAR CONTINUAR.
      this.por_pagar = this.deuda - Number(liquidoRecibir);
      this.debe_pagar = false;
      this.disabled_next = true;
    }

    let valorAnticipo=Number(acumuladorVentas*this.operacionAnticipo);


    this.simulation={'anticipo':valorAnticipo,'vencimiento':this.vencimiento,'comision':comision,'gastosOperacionales':this.gastosOperacionales,'iva_operaciones':iva_operaciones,
    'interesTotal':interesTotal,'liquidoRecibir':Number(liquidoRecibir), 'porPagar':this.debe_pagar,'porPagarMonto':this.por_pagar};

    
  }

  recalculate_change(){


    //HACER SIMULACION

    let acumuladorVentas=0;
    let comision=50000;
    let iva_operaciones=0;
    let interesTotal=0;
    let liquidoRecibir=0;
    let porPagar:boolean=false;
    let porPagarMonto=0;
    let suma=0;
    for(let i in this.arr_documentos_to_simulate){
      acumuladorVentas=acumuladorVentas+this.arr_documentos_to_simulate[i].mnt_total;
      comision=0.005*(acumuladorVentas*this.operacionAnticipo)
      if(comision < 50000){
        comision=50000
      }

      iva_operaciones=(Number(this.gastosOperacionales) + Number(comision))*0.19;
      //30 es cantidad de dias
      let dias_vencimiento_default = 30;
      console.log("original position", this.arr_documentos_to_simulate[i].fecha_vencimiento)
      if(this.arr_documentos_to_simulate[i].fecha_vencimiento != null && this.arr_documentos_to_simulate[i].fecha_vencimiento != undefined){
        dias_vencimiento_default = this.arr_documentos_to_simulate[i].dias;
      }

      console.log("dias vencimiento", dias_vencimiento_default)
      interesTotal= acumuladorVentas * this.anticipo_porc * this.tasa_porc / 30 * dias_vencimiento_default;
      liquidoRecibir=(acumuladorVentas*this.operacionAnticipo)-Number(comision)-Number(this.gastosOperacionales)-Number(iva_operaciones)-Number(interesTotal);

      suma = dias_vencimiento_default + suma;

      // if(liquidoRecibir > this.deuda){
      //   break;
      // }

    }

    let prom_dias = suma / this.arr_documentos_to_simulate.length;

    this.vencimiento = Math.round(prom_dias);

    if(Number(liquidoRecibir) > this.deuda){
      this.debe_pagar = true;
      this.por_pagar = Number(liquidoRecibir) - this.deuda;
      this.disabled_next = false;
    }else{
      //SI SE CUMPLE AQUI NO DEJAR CONTINUAR.
      this.por_pagar = this.deuda - Number(liquidoRecibir);
      this.debe_pagar = false;
      this.disabled_next = true;
    }

    let valorAnticipo=Number(acumuladorVentas*this.operacionAnticipo);


    this.simulation={'anticipo':valorAnticipo,'vencimiento':this.vencimiento,'comision':comision,'gastosOperacionales':this.gastosOperacionales,'iva_operaciones':iva_operaciones,
    'interesTotal':interesTotal,'liquidoRecibir':Number(liquidoRecibir), 'porPagar':this.debe_pagar,'porPagarMonto':this.por_pagar};

  }

  fecha_todas_simulation(value:any){

      var date = value;
      let d_o_w  = date.getDay()
      if (d_o_w == 0 || d_o_w ==6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
      
      let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()
  
      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      this.ELEMENT_DATA.forEach(item => {
        if(item.simulado == true){
          item['fecha_vencimiento'] = date_set
          item["dias"] = diffDays
        }
      })
  
  
    
  }

  fecha_todas($e: any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    
    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.ELEMENT_DATA2){
        this.ELEMENT_DATA2[i]['fecha_vencimiento'] = date_set
        this.ELEMENT_DATA2[i]["dias"] = diffDays
    }


    this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
    this.dataSource2._updateChangeSubscription();
    this.dataSource2.paginator = this.paginator2;

    this.check_vencimiento()


  }

  capturar_fecha_por_dias($e: any){
      let dias = ($e.target.value - 1);
      let new_date = this.addDays(Number(dias))
      let fecha_calculada = new Date(new_date)

      console.log("FECHA CALCULADA", fecha_calculada)
      this.firstFormGroup.controls['secondCtrl'].setValue(fecha_calculada)
      this.fecha_todas_por_dias(fecha_calculada)

  }

  addDays(days:any) {
    let fecha_hoy = new Date()
    let fecha_condias = new Date()
    fecha_condias.setDate(fecha_hoy.getDate()+days);
    return fecha_condias;
  }

  fecha_todas_por_dias(date:any){
      var date = date
      let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

      if (d_o_w == 0 || d_o_w == 6)
        date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

      let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()

      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      for(var i in this.ELEMENT_DATA2){
          this.ELEMENT_DATA2[i]['fecha_vencimiento'] = date_set
          this.ELEMENT_DATA2[i]["dias"] = diffDays
      }

      this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
      this.dataSource2._updateChangeSubscription();
      this.dataSource2.paginator = this.paginator2;

      this.check_vencimiento()

  }


  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.get_facturas_venta()
  }

  objeto_nelson = [];
  arr_documentos_to_simulate: Array<any> = [];
  arr_simulation: Array<any> = []
  disabled_enviar:boolean=true;
  total_total=0;

  disabled_next:boolean=true;
  activa(id:any, objeto:any, evt?:any) {

        if(evt!=undefined){
          console.log(evt.target.checked)
          objeto.simulado=evt.target.checked
        }

        const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);

        if (obje === undefined) {
          this.arr_documentos_to_simulate.push(objeto);
        } else {
          if(objeto.simulado==false){
            this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
          }
        }


        if (this.arr_simulation.includes(id)) {
          this.arr_simulation.splice(this.arr_simulation.indexOf(id), 1);
        } else {
          this.arr_simulation.push(id);
        }

        //HACER SIMULACION
        let acumuladorVentas=0;
        let comision=50000;
        let iva_operaciones=0;
        let interesTotal=0;
        let liquidoRecibir=0;
        let porPagar:boolean=false;
        let porPagarMonto=0;

        console.log("this")

        console.log(this.arr_documentos_to_simulate)

        for(let i in this.arr_documentos_to_simulate){
          acumuladorVentas=acumuladorVentas+this.arr_documentos_to_simulate[i].mnt_total;
          comision=0.005*(acumuladorVentas*this.operacionAnticipo)
          if(comision < 50000){
            comision=50000
          }

          iva_operaciones=(Number(this.gastosOperacionales) + Number(comision))*0.19;
          //30 es cantidad de dias
          interesTotal= this.arr_documentos_to_simulate[i].mnt_total * this.anticipo_porc * this.tasa_porc / 30 * this.vencimiento;
          liquidoRecibir=(acumuladorVentas*this.operacionAnticipo)-Number(comision)-Number(this.gastosOperacionales)-Number(iva_operaciones)-Number(interesTotal);


        }

        if(Number(liquidoRecibir) > this.deuda){
          this.debe_pagar = true;
          this.por_pagar = Number(liquidoRecibir) - this.deuda;
          this.disabled_next = false;

          console.log("aqiuiiiii")

        }else{
          //SI SE CUMPLE AQUI NO DEJAR CONTINUAR.
          this.disabled_next = true
          this.por_pagar = this.deuda - Number(liquidoRecibir);
          this.debe_pagar = false;

          console.log("aqiuiiiii  222222")

        }

        let valorAnticipo=Number(acumuladorVentas*this.operacionAnticipo);


        this.simulation={'anticipo':valorAnticipo,'vencimiento':this.vencimiento,'comision':comision,'gastosOperacionales':this.gastosOperacionales,'iva_operaciones':iva_operaciones,
        'interesTotal':interesTotal,'liquidoRecibir': Number(liquidoRecibir), 'porPagar':this.debe_pagar,'porPagarMonto':this.por_pagar};

        console.log("activa", this.arr_documentos_to_simulate)

  }

  selection = new SelectionModel<Facturas>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event:any) {
    console.log($event)
    !$event.checked  ?
    this.selection.clear() :
    this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

    //CARGA MANUAL
    private hotRegisterer2 = new HotTableRegisterer();
    id2 = 'hotInstance2';
    hotSettings2: Handsontable.default.GridSettings = {
        startRows: 1,
        colHeaders: true,
        //colWidths: [150,200,150,150],
        rowHeights: [25],
        width: '100%',
        height: '450px',
        columns: [
          {
            type: 'numeric'
          }
        ],
  
    }
    data2: any[] = [
      [null, null,null,null,null, null]
    ]
    
    new_row(){
      this.hotRegisterer2.getInstance(this.id2).alter('insert_row_below', this.data2.length+1)
  
    }
  
    delete_row(){
      this.hotRegisterer2.getInstance(this.id2).alter('remove_row', this.data2.length+1)
    }
  
  
    ELEMENT_DATA_VISTAPREVIA2: Array<any>=[];
    displayedColumnsVistaPrevia2: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
    dataSourceVistaPrevia2 = new MatTableDataSource<VISTAPREVIA2>(this.ELEMENT_DATA_VISTAPREVIA2);
    @ViewChild('VistaPrevia2') vistaprevia2!: MatPaginator;
  
    //ERROR
    ELEMENT_DATA_VISTAPREVIA_ERROR2: VISTAPREVIA2[]=[];
    // displayedColumnsVistaPreviaError2: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
    // dataSourceVistaPreviaError2 = new MatTableDataSource<VISTAPREVIA2>(this.ELEMENT_DATA_VISTAPREVIA_ERROR2);
    // @ViewChild('VistaPreviaError2') vistapreviaerror2: MatPaginator;
  
    hay_facturas_con_error:boolean=true;
    async listoparaenviar() {

      this.ELEMENT_DATA_VISTAPREVIA2 = [];
      this.ELEMENT_DATA_VISTAPREVIA_ERROR2 = [];
  
      var index = this.data2.indexOf("");
  
      if (~index) {
          this.data2[index] = null;
      }
  
      let puntos = /\./gi;
      let pesos = /\$/gi;
      let spaces = /\ /gi;
      let guion = /\-/gi;
      var b = "-";
      var saltos = /(\r\n|\n|\r)/gm;
      var coma = /,/g;
  
      for(let i=0;i<this.data2.length;i++){
        if(this.data2[i][5] != null){
          this.data2[i][5] = this.data2[i][5].replace(saltos,"").trim()
          this.data2[i][5] = this.data2[i][5].replace(coma,"").trim()
          this.data2[i][5] = this.data2[i][5].replace(puntos,"")
          this.data2[i][5] = this.data2[i][5].replace(pesos,"")
          this.data2[i][5] = this.data2[i][5].replace(spaces,"")
        }
  
        if(this.data2[i][1] != null){
          this.data2[i][1] = this.data2[i][1].replace(saltos,"").trim()
          this.data2[i][1] = this.data2[i][1].replace(puntos,"")
          this.data2[i][1] = this.data2[i][1].replace(guion,"")
          this.data2[i][1] = ([this.data2[i][1].slice(0, -1), b, this.data2[i][1].slice(-1)].join('')).toUpperCase();
        }
  
        if(this.data2[i][0] != null){
          this.data2[i][0] = this.data2[i][0].replace(saltos,"").trim()
        }
  
        if(this.data2[i][4] != null){
          this.data2[i][4] =  this.data2[i][4].replace(saltos,"").trim()
        }
  
        if(this.data2[i][3] != null){
          this.data2[i][3] =  this.data2[i][3].replace(saltos,"").trim()
        }
  
      }
  
      this.hotRegisterer2.getInstance(this.id2).render()

      let valid = false;
      for(let i in this.data2){
        if(this.data2[i][5] == null || this.data2[i][0] == null || this.data2[i][3] == null || this.data2[i][0] == null){
          this.open_input_dialog("Error", "Errores", "¡Oops! Debe agregar al menos una factura, los montos no deben estar vacios.","","info","#FA454C")
          valid = false;
          break;
        }else{
          valid = true;
        }
      }
    
      if(valid == true){
        try {

          let value = {'datosRevisar':this.data2}

          const bd: any = await this.settings.get_query(2, 'ComprasVentasManuales/'+this.id_company +'/?accion=1', value);
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:
    
            if (body_data.arrayFront.length == 0){
              // this.snackBar.open("No existen facturas válidas", "Aceptar", {
              //   duration: 5000,
              //   panelClass: ['JP-snackbar']
              // });
              this.open_input_dialog("Error", "Errores", "¡Oops! No existen facturas válidas.","","info","#FA454C")
              return false;
            }
  
            for(let i in body_data.arrayFront){
              if(body_data.arrayFront[i].errorVentas == true){
                this.ELEMENT_DATA_VISTAPREVIA_ERROR2.push(body_data.arrayFront[i])
              }else if(body_data.arrayFront[i].errorVentas == false){
                this.ELEMENT_DATA_VISTAPREVIA2.push(body_data.arrayFront[i])
              }
            }
  
            if(this.ELEMENT_DATA_VISTAPREVIA_ERROR2.length > 0){
              this.hay_facturas_con_error = true;
            }else{
              this.hay_facturas_con_error = false;
            }
  
  
            for(let i in this.ELEMENT_DATA_VISTAPREVIA2){
              this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_emision)
              if(this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_vencimiento != null){
                  this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_emision)
              }
  
            }
  
            for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR2){
              this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_emision)
              if(this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_vencimiento != null){
                  this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_emision)
              }
  
            }

            console.log("ORIGINAL", this.ELEMENT_DATA_VISTAPREVIA2)
  
            let array3 = this.ELEMENT_DATA_VISTAPREVIA2.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR2)
  
            this.check_errors(this.ELEMENT_DATA_VISTAPREVIA2)


  
            setTimeout(() => {
              this.dataSourceVistaPrevia2=new MatTableDataSource(array3);
              this.dataSourceVistaPrevia2.paginator = this.vistaprevia2;
              this.dataSourceVistaPrevia2._updateChangeSubscription();
  
              // this.dataSourceVistaPreviaError2=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA_ERROR2);
              // this.dataSourceVistaPreviaError2.paginator = this.vistapreviaerror2;
              // this.dataSourceVistaPreviaError2._updateChangeSubscription();
            });
  
            $("#siguiente_step2").trigger('click')
    
          
    
              break;
            default:
    
              break;
          }
        } catch (bd: any) {
    
        }
      }
    
    }
  
    async capture_change(column:any,index:any,value:any, tipo_error:any,folio?:any) {
      try {
        let valor_enviar = {'column':column, 'value':value,  'index':index}

        const bd: any = await this.settings.get_query(3, 'ComprasVentasManuales/'+this.id_company +'/?folio='+folio+'&accion=1', valor_enviar);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
                if(body_data.error == false){
                  if(body_data.id_date == true){
                    let date = new Date(body_data.valor)
                    this.ELEMENT_DATA_VISTAPREVIA2[index][column] = date;
                  }else{
                    console.log("cayo en el else", body_data.valor )
                    this.ELEMENT_DATA_VISTAPREVIA2[index][column] = body_data.valor;
                  }
        
                  this.ELEMENT_DATA_VISTAPREVIA2[index][tipo_error] = false;
                }
        
                if(this.ELEMENT_DATA_VISTAPREVIA2[index]['errorCompras'] == false && this.ELEMENT_DATA_VISTAPREVIA2[index]['errorFechaEmision'] == false && 
                  this.ELEMENT_DATA_VISTAPREVIA2[index]['errorFechaVencimiento'] == false && this.ELEMENT_DATA_VISTAPREVIA2[index]['errorFolio'] == false &&
                  this.ELEMENT_DATA_VISTAPREVIA2[index]['errorMonto'] == false && this.ELEMENT_DATA_VISTAPREVIA2[index]['errorRut'] == false){
                  this.ELEMENT_DATA_VISTAPREVIA2[index]['error'] = false;
                }
        
                this.check_errors(this.ELEMENT_DATA_VISTAPREVIA2)
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }
  
    capture_date_error($e: any, index:any, column:any, tipo_error:any) {
  
      let value = new Date($e.value);
      let yy = value.getFullYear();
      let mm = value.getMonth()+1;
      let dd = value.getDate();
      let date = dd+'/'+mm+'/'+yy;
      this.capture_change(column,index,date, tipo_error)
  
    }
  
    capture_date_error2($e: any, index:any, column:any, tipo_error:any) {
  
      let value = new Date($e.value);
      let yy = value.getFullYear();
      let mm = value.getMonth()+1;
      let dd = value.getDate();
      let date = dd+'/'+mm+'/'+yy;
      this.capture_change(column,index,date, tipo_error)
  
    }
  
    no_continue:boolean=true;
    check_errors(array:any){
      this.no_continue = true;
      for(let i in array){
        if(array[i]['error'] == true){
            this.no_continue = true;
            break;
        }else{
            this.no_continue = false;
        }
      }
    }

    recalculate_manual(){

      let array_2 = []

      array_2 = this.ELEMENT_DATA_VISTAPREVIA2;

      for(let i=0;i<array_2.length;i++){
        let fecha_emision = new Date(array_2[i].fecha_emision)
        if(array_2[i].fecha_vencimiento != null && array_2[i].fecha_vencimiento != undefined){
          let fecha_vencimiento = new Date(array_2[i].fecha_vencimiento);
          let dd = fecha_vencimiento.getDate();
          let mm = fecha_vencimiento.getMonth()+1;
          let yy = fecha_vencimiento.getFullYear();
          let new_date = dd+'-'+mm+'-'+yy
          array_2[i].fecha_vencimiento = new_date;
        }
        let dd = fecha_emision.getDate();
        let mm = fecha_emision.getMonth()+1;
        let yy = fecha_emision.getFullYear();
        let new_date = dd+'-'+mm+'-'+yy
        array_2[i].fecha_emision = new_date;
      }
    

      this.ELEMENT_DATA_VISTAPREVIA2.forEach(item => {
        if(item.simulado == true){
          this.activa(item.id, item)
        }
      })
    }

    paso_1:boolean=true;
    paso_2:boolean=false;

    array_seleccion_clone:Array<any>=[];
    array_ventas_clone:Array<any>=[];
    array_consolidado:Array<any>=[];

    arreglo_simulacion:Array<any>=[]
    arreglo_simulacion_clone:Array<any>=[]

    clone_array(arr:any)
    {
      let aa =  JSON.stringify(arr);
      return  JSON.parse(aa)
    }

    agregar_facturas(){
      console.log("facturas seleccionadas", this.arr_documentos_to_simulate)
      console.log("facturas de simulacion", this.facturas_venta)

      if (this.arr_documentos_to_simulate.length == 0) {
        return false;
      }

      this.arreglo_simulacion.push(this.simulacion_recibida)

      this.array_seleccion_clone = this.clone_array(this.arr_documentos_to_simulate);
      this.array_ventas_clone = this.clone_array(this.facturas_venta)
      this.arreglo_simulacion_clone = this.clone_array(this.arreglo_simulacion)

      var re = "/"
      for(let i in this.array_seleccion_clone){
        this.array_seleccion_clone[i].fecha_vencimiento = this.array_seleccion_clone[i].fecha_vencimiento.replace(re, '-')
        this.array_seleccion_clone[i].fecha_vencimiento = this.array_seleccion_clone[i].fecha_vencimiento.replace(re, '-')
      }

      this.array_consolidado = this.array_seleccion_clone.concat(this.array_ventas_clone);

      this.esto_recalcula_muy_bien()



      
    }


    vencimiento_operacion=30;
    ELEMENT_DATA_2: Facturas_2[]=[];
    displayedColumns_2: string[] = ['folio','receptoracreedor_name','mnt_total','fecha_emision','fecha_vencimiento'];
    dataSource_2 = new MatTableDataSource<Facturas_2>(this.ELEMENT_DATA_2);
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    esto_recalcula_muy_bien(){

      console.log("Simulacion recibida", this.arreglo_simulacion_clone)
 

        //HACER SIMULACION
        let acumuladorVentas=0;
        let comision=50000;
        let iva_operaciones=0;
        let interesTotal=0;
        let liquidoRecibir=0;
        let porPagar:boolean=false;
        let porPagarMonto=0;
        let suma=0;
        let acumulador_interes=0;


        let gastos = this.arreglo_simulacion[0].gastos;
        let tasa_interes = this.arreglo_simulacion[0].tasa_interes;
        let porcentaje_anticipo = this.arreglo_simulacion[0].porc_anticipo;

        for(let i in this.array_consolidado){
          acumuladorVentas=acumuladorVentas+Number(this.array_consolidado[i].mnt_total);
          let dias_vencimiento_default = 30;
          if(this.array_consolidado[i].fecha_vencimiento != null && this.array_consolidado[i].fecha_vencimiento != undefined){
            dias_vencimiento_default = this.array_consolidado[i].dias;
          }

          interesTotal= Number(this.array_consolidado[i].mnt_total) * porcentaje_anticipo * tasa_interes / 30 * dias_vencimiento_default;
          suma = dias_vencimiento_default + suma;
          acumulador_interes = acumulador_interes + interesTotal;

          }


          comision=0.005*(acumuladorVentas*porcentaje_anticipo)
          if(comision < 50000){
            comision=50000
          }

          iva_operaciones=(Number(gastos) + Number(comision))*0.19;
          liquidoRecibir=(acumuladorVentas*porcentaje_anticipo)-Number(comision)-Number(gastos)-Number(iva_operaciones)-Number(acumulador_interes);
          let prom_dias = suma / this.array_consolidado.length;
          this.vencimiento_operacion = Math.round(prom_dias);

        if(Number(liquidoRecibir) > this.deuda_total){
          this.debe_pagar = true;
          this.por_pagar = Number(liquidoRecibir) - this.deuda_total;
          this.disabled_next = false;

        }else{
          //SI SE CUMPLE AQUI NO DEJAR CONTINUAR.
          this.disabled_next = true
          this.por_pagar = this.deuda_total - Number(liquidoRecibir);
          this.debe_pagar = false;

        }

        let valorAnticipo=Number(acumuladorVentas*this.operacionAnticipo);


        this.simulacion_recibida={'anticipo':Math.round(valorAnticipo),'vencimiento':this.vencimiento_operacion,'comision':Math.round(comision),'gastosOperacionales':gastos,'iva_operaciones':Math.round(iva_operaciones),
        'interesTotal':Math.round(acumulador_interes),'liquidoRecibir': Math.round(Number(liquidoRecibir)), 'porPagar':this.debe_pagar,'porPagarMonto':Math.round(this.por_pagar)};

        console.log("activa", this.array_consolidado)
        console.log("simulacion realizada", this.simulacion_recibida)

        this.ELEMENT_DATA_2 = this.array_consolidado;


        setTimeout(() => {
          this.dataSource_2=new MatTableDataSource(this.ELEMENT_DATA_2);
          this.dataSource_2.paginator = this.paginator;
          this.dataSource_2._updateChangeSubscription();
        });

        this.titulo="Simulación Generada"
        this.paso_1 = false;
        this.paso_2 = true;
  

    }

    titulo="Libro de Ventas"
    anterior(){
      this.titulo="Libro de Ventas"
      this.paso_2 = false;
      this.paso_1 = true;
    }

    disabled:boolean=false;
    cerrar_y_continuar()
    {
      this.disabled = true;
      this.agregar_facturas_compras(this.facturas, this.arr_documentos_to_simulate)
    }

    //DIALOG INPUT
    open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

    console.log("se abrio dialog")
    const dialogRef = this.dialog.open(InputDialog,{
      width:'700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
  
  
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  
    }

    async agregar_facturas_compras(compras:any, ventas:any) {
      try {
        let value = {'facturas':compras}

        const bd: any = await this.settings.get_query(1, 'RecalculateCompras/' + this.id_simulation + '/?final=1', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
                this.guardar_facturas_venta(ventas)
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }


    async guardar_facturas_venta(ventas:any) {
      try {
        let value = {'documentos':ventas}

        const bd: any = await this.settings.get_query(3, 'controldteadddocumentsimulation/'+this.id_simulation+'/', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
            this.open_input_dialog("Información", "La simulación fue creada exitosamente", 
            "¡Todo listo! Pronto te contactará un Ejecutivo",
            "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Proveedores","check_circle","#3BA46F")
            this.dialogRef.close(true)
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }

    getFechasVenc() {
      for (let i = 0; i < this.arr_documentos_to_simulate.length; i++) {
        if (this.arr_documentos_to_simulate[i].fecha_vencimiento == null || this.arr_documentos_to_simulate[i].fecha_vencimiento == "") {
          this.arr_documentos_to_simulate[i].fecha_vencimiento = this.getFechaVencimiento30()
        }    
      }
    }
  
    getFechaVencimiento30(){
      var date = new Date();
      var cont = 0
      while(cont < 30) {
        date.setDate(date.getDate() + 1);
        let d_o_w  = date.getDay()
        if (d_o_w != 0 && d_o_w !=6) {
          cont = cont +1;
        }      
      }
      var date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()    
      return date_set;  
    }

    validate_cargar_manual(data:any) {
      let valid = false;
  
      if (data.length == 0) {
        // this.snackBar.open("Debe agregar al menos una factura.", "Aceptar", {
        //   duration: 5000,
        //   panelClass: ['JP-snackbar']
        // });
        this.open_input_dialog("Error", "Errores", "¡Oops! Debe agregar almenos una factura.","","info","#FA454C")
        return false;
      }
  
      let puntos = /\./gi;
      let pesos = /\$/gi;
      let spaces = /\ /gi;
      let guion = /\-/gi;
      var b = "-";
      var saltos = /(\r\n|\n|\r)/gm;
      var coma = /,/g;
  
      for(let i=0;i<data.length;i++){
        if(data[i][5] != null){
          data[i][5] = data[i][5].replace(saltos,"").trim()
          data[i][5] = data[i][5].replace(coma,"").trim()
          data[i][5] = data[i][5].replace(puntos,"")
          data[i][5] = data[i][5].replace(pesos,"")
          data[i][5] = data[i][5].replace(spaces,"")      
          data[i][5] = parseInt(data[i][5])
          data[i][5] = data[i][5].toString();
        }

        if(data[i][1] != null){
          data[i][1] = data[i][1].replace(saltos,"").trim()
          data[i][1] = data[i][1].replace(puntos,"")
          data[i][1] = data[i][1].replace(guion,"")
          data[i][1] = ([data[i][1].slice(0, -1), b, data[i][1].slice(-1)].join('')).toUpperCase();
        }

        if(data[i][0] != null){
          data[i][0] = data[i][0].replace(saltos,"").trim()
          data[i][0] = parseInt(data[i][0])
          data[i][0] = data[i][0].toString();
        }

        if(data[i][4] != null){
          data[i][4] =  data[i][4].replace(saltos,"").trim()
        }

        if(data[i][3] != null){
          data[i][3] =  data[i][3].replace(saltos,"").trim()
        }
  
        if(
          data[i][5] == null 
          || data[i][0] == null 
          || data[i][3] == null 
          || data[i][0] == null 
          || data[i][4] == null
          || data[i][5] == "" 
          || data[i][0] == "" 
          || data[i][3] == "" 
          || data[i][0] == "" 
          || data[i][4] == ""
          || !(Number.isInteger(parseInt(data[i][0]))) 
          || !(Number.isInteger(parseInt(data[i][5])))
          ){
          // this.snackBar.open("El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.", "Aceptar", {
          //   duration: 5000,
          //   panelClass: ['JP-snackbar']
          // });
          this.open_input_dialog("Error", "Errores", "¡Oops! El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.","","info","#FA454C")
          valid = false;
          break;
        }else{
          valid = true;
        }
      }
  
      return valid;
    }

}

export interface Facturas{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}

export interface Facturas2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any,
  bancos:any;
}

export interface VISTAPREVIA2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  simulado:any,
}

export interface Facturas_2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}