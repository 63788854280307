import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatSnackBar } from '@angular/material/snack-bar';




declare var $: any;


@Component({
  selector: 'app-dialogo-success',
  templateUrl: './dialogo-success.html',
  styleUrls: ['./dialogo-success.less']
})
export class DialogoSuccess implements OnInit {


@Input() id_op:any; 
@Input() id_sim:any; 
step:any=1;


title= "¡Las facturas están en validación!";
subtitle = "Respetaremos los criterios de financiamiento<br> y vencimiento asignados en la simulación.<br> Para saber más, revisa tus operaciones."
show_extra:any=false;
is_responsive:boolean=false;
  constructor(private dialogRef:MatDialogRef<DialogoSuccess>, private router:Router, private dialog:MatDialog, private settings:SettingsService, private snackBar:MatSnackBar) { }




  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }

  ver_operaciones(){
    this.dialogRef.close()
    this.router.navigate(['home/operaciones/'])
  }

  ver_estado(){
    this.dialogRef.close()
    this.router.navigate(['home/operation-detail/', this.id_op])
  }




}