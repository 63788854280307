<!-- <mat-tab-group>

  <mat-tab label="Lista de Facturas">  -->

    <app-facturas-venta
      [deuda]="deuda"
      [deuda_total]="deuda_total"
      [id_simulation]="id_simulation"
      [manual_ventas]="manual_ventas"    
      [simulacion_recibida]="simulacion_recibida"
      [facturas_venta]="facturas_venta"
      [facturas]="facturas"></app-facturas-venta>
<!-- 
  </mat-tab>

  <mat-tab label="Carga Manual"> 
    
    <app-facturas-ventas-manuales
      [deuda]="deuda"
      [deuda_total]="deuda_total"
      [id_simulation]="id_simulation"
      [manual_ventas]="manual_ventas"    
      [simulacion_recibida]="simulacion_recibida"
      [facturas_venta]="facturas_venta"
      [facturas]="facturas"></app-facturas-ventas-manuales>
    
  </mat-tab>

</mat-tab-group>  -->
