
<div mat-dialog-title style="position: relative;">
    <h1 class="titulo-simpli">{{title}}</h1>
    <small class="subtitulo-simpli">{{subtitle}}</small>
</div>

<div mat-dialog-content style="overflow: hidden !important;">



    <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">



            <div class="textoCajaGrande" style="font-size: 17px;">

                    <span [ngStyle]="{'color':this.coloricon}" class="material-icons icon-style animate__animated animate__bounceIn">
                        {{icon}}
                    </span>
                
        
                
                    <div class="animate__animated animate__fadeIn" style="font-weight: 600;color: #4d4d4d;font-size: 1.1em;margin-top: 40px;" [innerHTML]="mensajeprincipal"></div>


                
                    <div style="display: block;margin-top: 10px;"  class="animate__animated animate__fadeIn" [innerHTML]="mensajenegrita"></div>
            </div>



        </div>

    </div>

</div>

<div mat-dialog-actions style="margin-top: 5px;text-align: center;margin-bottom: 5px;" align="center">
    <button  mat-flat-button color="primary" type="button" (click)="close_dialog()" class="secondary-button">Cerrar</button>
</div>