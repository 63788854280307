<div mat-dialog-title>
  <h1 class="titulo-simpli" style="position: relative;">Nómina</h1>
  <small class="subtitulo-simpli">Vista previa</small>
</div>

<div mat-dialog-content style="position: relative;">

  <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
      <mat-spinner class="spinner-simpli" diameter="30"></mat-spinner>
  </div>


  <div class="row row-giro">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <small class="subtitulo-2">Giro a tu cuenta</small>
    </div>
    
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
      <div class="not-account-msg animate__animated animate__fadeIn" *ngIf=" !cargando && !has_accounts">
        &nbsp; <strong>No tiene una cuenta bancaria vinculada</strong>, dale click al icono amarillo y agrega una Cuenta Bancaria.
      </div>
    
      <div class="not-account-msg animate__animated animate__fadeIn" *ngIf=" !cargando && has_accounts && !id_banco_selected">
        &nbsp; El giro a tu cuenta <strong>no tiene una cuenta bancaria vinculada</strong>, dale click al icono amarillo y agrega una nueva o selecciona una Cuenta Bancaria.
      </div>
    
      <div class="success-account-msg animate__animated animate__fadeIn" *ngIf=" !cargando && has_accounts && id_banco_selected">
        &nbsp; El giro a tu cuenta <strong>tiene una cuenta bancaria vinculada</strong>, dale click al icono verde y agrega una nueva o selecciona otra si deseas cambiar tu Cuenta Bancaria.
      </div>
    
      <div class="table-responsive">
    
    
    
        <table mat-table class="table-cuentas" [dataSource]="dataSourceGiro">
    
    
          <ng-container matColumnDef="cuentas">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Cuenta </th>
            <td mat-cell *matCellDef="let element" matTooltip="Cuenta" style="min-width: 130px;text-align: center;position: relative;"> 
              <span *ngIf="element.has_account == false && !has_accounts && !id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta">
                <i class="fa-light fa-exclamation-circle not-account-icon"></i>
              </span>


              <span *ngIf="element.has_account != false && has_accounts && id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta">
                <i class="fa-light fa-plus-circle add-icon"></i>
              </span>


              <span *ngIf="element.has_account == false && has_accounts && !id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta">
                <i class="fa-light fa-exclamation-circle not-account-icon"></i>
              </span>
                  
              <mat-form-field class="selector-bank">
                <mat-label>Giro a tu cuenta</mat-label> 
                <mat-select [(value)]="id_banco_selected" (selectionChange)="change_bank($event)" >
                  <mat-option *ngFor="let account of element.cuentas" [value]="account.id" [disabled]="!is_not_done">
                    {{account.bank.name + ' | ' + account.tipo_cuenta + ' ' + account.number}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
      
          <ng-container matColumnDef="acreedorName">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Acreedor Legal </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.acreedorName}}" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.acreedorName}}" style="text-align: left;" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="CuentaRut">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.CuentaRut}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="CuentaName">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Nombre Cuenta </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"   value="{{element.CuentaName}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="NumberCuenta">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Tipo Cuenta </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"   value="{{element.NumberCuenta}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
    
          <ng-container matColumnDef="bankName">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Banco </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.bankName}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="totalGiro">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
    
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Estado </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px; justify-content: center;" matTooltip="{{ element.status==0 ? 'Creado' : element.status==1 ? 'En proceso' : 'Pagado' }}"> 
              
              <table style="width: 90%;">
                <tr>
                  <td style="text-align: right;">
                    <span matTooltip="">
                      <i [ngClass]="{'fa-light': true, 
                        'fa-check-circle': element.status==2 || element.status==0,
                        'fa-spinner': element.status==1,
                        'done-status': element.status==2, 
                        'on-process-status': element.status==1, 
                        'created-status': element.status==0}"></i>
                    </span>
                  </td>
                  <td style="text-align: left;">
                    <span (click)="download_file(element.link)" matTooltip="">
                      <i [ngClass]="{'fa-light': true, 
                        'fa-file-download': true,
                        'done-status-link': element.link!=null,
                        'created-status-link': element.link==null}"></i>
                    </span>
                   </td>
                </tr>
              </table>
                          
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumnsGiro; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsGiro"></tr>
    
        </table>
    
      </div>
    
    </div>
  </div>

  <div class="row">
    
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <form [formGroup]="valForm">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <mat-form-field>
              <mat-label>Filtrar</mat-label>
              <input formControlName="filter" (change)="filtrar()" matInput placeholder="Filtrar">
            </mat-form-field>
    
          </div>  
    
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <mat-form-field>
              <mat-label>Filtro por giro</mat-label>
              <mat-select formControlName="girado" [(value)]="selected" (selectionChange)="filtrar()">
                <mat-option value="0" >Girado</mat-option>
                <mat-option value="1" >No girado</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <div class="table-responsive ">

        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef> RUT</th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="{{element.rut}}" readonly>
            </td>
            <td mat-footer-cell *matFooterCellDef></td> 
          </ng-container>
      
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="{{element.name}}" readonly>
            </td>   
            <td mat-footer-cell *matFooterCellDef></td>                     
          </ng-container> 
      
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> Nro. de Cuenta </th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="{{element.number_hidden}}" readonly>
            </td>     
            <td mat-footer-cell *matFooterCellDef></td>                   
          </ng-container> 

          <ng-container matColumnDef="bank">
            <th mat-header-cell *matHeaderCellDef> Banco </th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="{{element.bankName}}" readonly>
            </td>      
            <td mat-footer-cell *matFooterCellDef></td>                  
          </ng-container> 

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Tipo de cuenta </th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="{{element.tipoCuenta}}" readonly>
            </td>     
            <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">Total: </td>                   
          </ng-container>        

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="{{element.email}}" readonly>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let element; let index=index; let i=index;">
              <input class="no-input" value="${{formatMoney(element.amount)}}" readonly>
            </td> 
            <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">$ {{formatMoney(total_tabla)}} </td>
          </ng-container>


          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Estado </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px; justify-content: center;" matTooltip="{{ element.status==0 ? 'Creado' : element.status==1 ? 'En proceso' : 'Pagado' }}"> 
              
              <table style="width: 90%;">
                <tr>
                  <td style="text-align: right;">
                    <span matTooltip="">
                      <i [ngClass]="{'fa-light': true, 
                        'fa-check-circle': element.status==2 || element.status==0,
                        'fa-spinner': element.status==1,
                        'done-status': element.status==2, 
                        'on-process-status': element.status==1, 
                        'created-status': element.status==0}"></i>
                    </span>
                  </td>
                  <td style="text-align: left;">
                    <span (click)="download_file(element.link)" matTooltip="">
                      <i [ngClass]="{'fa-light': true, 
                        'fa-file-download': true,
                        'done-status-link': element.link!=null,
                        'created-status-link': element.link==null}"></i>
                    </span>
                   </td>
                </tr>
              </table>
                          
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

        </table>
        
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageCustom($event)"></mat-paginator>

      </div>
      
    </div>
  </div>

</div>

<div mat-dialog-actions style="margin-top: 10px;">
  <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" >Cerrar</button>
</div>

