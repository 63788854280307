<mat-card class="add_factoring">
  <mat-card-title>Facturas de compra</mat-card-title>  
  <div class="row m-0">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-0">
      <mat-card-subtitle>Pago a tus proveedores</mat-card-subtitle>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-0 icon-add" *ngIf="done">
      <span>Agregar facturas</span>
      <button mat-icon-button (click)="open_compras()" [disabled]="cargando">
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content>
    <div class="row w-100"style="position: relative">

      <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
        <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <form [formGroup]="valForm">
          <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input formControlName="filter" matInput (change)="filtrar()" #input>
          </mat-form-field>
        </form>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        
        <table mat-table [dataSource]="dataSourceCompras" class="w-100">
  
  
          <ng-container matColumnDef="folio">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Folio </th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element.folio}}" style="min-width: 130px;text-align: center;position: relative;"> 
                <span 
                  *ngIf="is_not_done" matTooltip="Eliminar" [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger">
                  <i class="fa-light fa-minus-circle deleteicon" ></i>
                </span>
                <input class="no-input"  value="{{element.folio}}" style="text-align: center;" readonly> 

                <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                  <mat-card class="add_factoring">
                    <mat-card-content>
                      <small>¿Eliminar esta factura?</small><br>

                      <button  type="button" (click)="eliminar_compras(element.id_compras)" style="margin-top: 10px;">Eliminar</button>

                    </mat-card-content>
                  </mat-card>
                </mde-popover>
            </td>
            <td mat-footer-cell *matFooterCellDef style="text-align: center;"></td>
          </ng-container>

          <ng-container matColumnDef="acreedorName">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Acreedor Legal </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.acreedorName}}" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.acreedorName}}" style="text-align: left;" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="CuentaRut">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.CuentaRut}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

          <ng-container matColumnDef="bankName">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Banco </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.bankName}}" readonly 
                [mdePopoverTriggerFor]="bankPopover" 
                mdePopoverTriggerOn="hover" 
                #popoverTrigger="mdePopoverTrigger">

                <mde-popover #bankPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                    <mat-card class="shadow-box card-bank">
                    <mat-card-header>
                      <h1 class="card-bank-title">Nombre: {{ element.CuentaName }}</h1>                             
                    </mat-card-header>
                    <mat-card-content class="text-center">
                        <span class="card-bank-span">Tipo de cuenta: {{element.NumberCuenta}}</span>             
                    </mat-card-content>
                    </mat-card>
                </mde-popover>
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="totalGiro">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">$ {{formatMoney(totalCompras)}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Estado </th>
          <td mat-cell *matCellDef="let element" style="min-width: 130px; justify-content: center;" matTooltip="{{ element.status==0 ? 'Creado' : element.status==1 ? 'En proceso' : 'Pagado' }}"> 
            
            <table style="width: 90%;">
              <tr>
                <td style="text-align: right;">
                  <span matTooltip="">
                    <i [ngClass]="{'fa-light': true, 
                      'fa-check-circle': element.status==2 || element.status==0,
                      'fa-spinner': element.status==1,
                      'done-status': element.status==2, 
                      'on-process-status': element.status==1, 
                      'created-status': element.status==0}"></i>
                  </span>
                </td>
                <td style="text-align: left;">
                  <span (click)="download_file(element.link)" matTooltip="">
                    <i [ngClass]="{'fa-light': true, 
                      'fa-file-download': true,
                      'done-status-link': element.link!=null,
                      'created-status-link': element.link==null}"></i>
                  </span>
                 </td>
              </tr>
            </table>
                                    
          </td>
          <td mat-footer-cell *matFooterCellDef>  </td>
        </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumnsCompras; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsCompras"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsCompras"></tr>

        </table>

        <span class="span-leyenda">Tu factura puede estar pagada o en proceso de pago. Puede descargar el comprobante cuando esté disponible.</span>

        <mat-paginator #paginator_compras [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageCustom($event)">
        </mat-paginator>
    

      </div>
    </div>
  </mat-card-content>
</mat-card>
