<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
          <i style="font-size: 65px;color:var(--simpli-color7)" class="fa-regular fa-circle-check"></i>
        </div>

        <div class="label-dialog-strong">{{title}}</div>
        <div class="label-dialog" [innerHTML]="subtitle"></div>


        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button style="width: 230px;" (click)="ver_operaciones()" *ngIf="step == 1">
                  Ver mis operaciones
                </button>

                <button class="primary-button" mat-flat-button style="width: 230px;" (click)="ver_estado()" *ngIf="step == 2">
                  Ver estado
                </button>

              </div>
              
            </div>


        </div>



    </div>


    
</mat-dialog-content>
