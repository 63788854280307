import { Component, OnInit,Input } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { CuentasBancarias } from '../ctas';
import { SettingsService } from 'src/app/settings/settings.service';;
import { CompanyDeleteAccDialog } from '../deleteacc.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';



declare var $ :any;

export interface Banco {
    id: string;
    name: string;
  }
  
  export interface Cuenta {
    id: string;
    name: string;
  }


  @Component({
    selector: 'updateacc-component',
    templateUrl: './updateacc.component.html',
    styleUrls: ['./updateacc.component.less']
  })

@Injectable()
export class UpdateAccountCompanyDialog  implements OnInit {

    public id: any;
    public banco: any;

    @Input() id_company:any;
    @Input() token:any;

    public load_data:any;

    public email: any;

    public star_class: any;
    public star: any;

    public is_primary_text: any;
    public proveedor:any;
    disabled_delete:any;

    dis:any;

    disabledButton:boolean= false;
    showSpinner:boolean = false;

    public info!: CuentasBancarias;

    bancos: Banco[] = [];
    
    cuentas: Cuenta[] = [];

    public run: any;
    public full_name: any;
    public number: any;
    public is_tercero: boolean = false;

    bank_selected: any;
    account_selected:any;

    sw_edit:any;

    valForm: FormGroup;
    valFormB: FormGroup;
    //mio
    myControl = new FormControl();
    filteredOptions!: Observable<Banco[]>;
    
    
    constructor( public snackBar: MatSnackBar, fb: FormBuilder, private router: Router, public dialog: MatDialog,  public settings: SettingsService) 
    {
        let rutValid = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{7,10}[-][0-9K]$')]));
        let accNumber = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{6,12}$')]));

        this.valForm = fb.group({
            'bank': ['', Validators.compose([Validators.required])],
            'type': ['', Validators.compose([Validators.required])],
            'rut': rutValid,
            'number': accNumber,
            'name': ['', Validators.compose([Validators.required])],
            'tercero': new FormControl(),
            'email': new FormControl('')

          });

        this.valFormB = fb.group({
            'bank':  new FormControl({ value: '', disabled: !this.sw_edit }),
            'type':  new FormControl({ value: '', disabled: !this.sw_edit }),
            'number':  new FormControl({ value: '', disabled: !this.sw_edit }),
            'rut': new FormControl({ value: '', disabled: !this.sw_edit }),
            'name': new FormControl({ value: '', disabled: !this.sw_edit }),
            'email': new FormControl({ value: '', disabled: !this.sw_edit }),
            
          });

          this.token = localStorage.getItem('token')
    }

    initial_load()
    {}

    async set_principal() {
        try {
            this.star = "star";
            this.star_class = "add_primary";

          const bd: any = await this.settings.get_query(3, 'account-company-primary/' + this.id_company + '/account/' + this.id + '/', null);
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:
                this.dis = false;
                this.info.reloadAccounts();
            
    
    
    
              break;
            default:
    
              break;
          }
        } catch (bd: any) {
    
        }
      }

    transform_number(){
        let regex = /[^0-9]+/;
        let phone =  String(this.valForm.controls['number'].value).replace(regex, '');
        if(phone != ''){
          return this.valForm.controls['number'].setValue(phone)
        }else{
          this.valForm.controls['number'].reset();
        }
      }

    
      private _filter(name: string): Banco[] {
        const filterValue = name.toLowerCase();
    
        return this.bancos.filter(option => option.name.toLowerCase().includes(filterValue) === true);
      }
    
    

    async load_bancos() {
        try {
          const bd: any = await this.settings.get_query(1, 'bank/');
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:
    
    
            console.log("Bancos");
            console.log(body_data);

            for (let i = 0; i < body_data.banks.length; i++)
            {
                this.bancos.push(body_data.banks[i])
            }

            this.filteredOptions = this.valForm.controls["bank"].valueChanges
            .pipe(
                startWith(''),
                map((value:any) => typeof value === 'string' ? value : value.name),
                map((name:any) =>  name ? this._filter(name) : this.bancos.slice())
            );
    
              break;
            default:
    
              break;
          }
        } catch (bd: any) {
    
        }
      }
     
    filtrar_banco($e:any){
        this.bank_selected = $e.id

        let cuenta = {id: "2", name: "Cuenta RUT"}
        if (this.bank_selected != "26"){
            if (this.cuentas.indexOf(cuenta) != -1) {
                this.cuentas.splice(this.cuentas.indexOf(cuenta), 1);
            }
        } else {
            this.cuentas.push(cuenta)
        }
    }

    async load_cuentas() {
        try {
          const bd: any = await this.settings.get_query(1, 'account-type/');
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:
    
    
            for (let i = 0; i < body_data.accounts.length; i++)
            {
                if (i != 1) {
                    this.cuentas.push(body_data.accounts[i]);
                }                    
            }
    
    
              break;
            default:
    
              break;
          }
        } catch (bd: any) {
    
        }
      }

    delete_add()
    {
       let dialogRef = this.dialog.open(CompanyDeleteAccDialog);
        dialogRef.componentInstance.id_acc = this.id
        dialogRef.componentInstance.info = this.info;
    }

    ngOnInit() {
        this.filteredOptions = this.valForm.controls["bank"].valueChanges
      .pipe(
        startWith(''),
        map((value:any) => typeof value === 'string' ? value : value.name),
        map((name:any) =>  name ? this._filter(name) : this.bancos.slice() )
      );
        this.load_bancos();
        this.load_cuentas();

        if (this.sw_edit == false)
        {
            this.valForm = this.valFormB
        }


            this.valForm.controls['rut'].setValue(this.run);
            this.valForm.controls['number'].setValue(this.number);
            this.valForm.controls['name'].setValue(this.full_name);
            this.valForm.controls['bank'].setValue(this.banco);
            this.valForm.controls['tercero'].setValue(this.is_tercero);
            this.valForm.controls['email'].setValue(this.email);

   


        
    }

    change_run()
    {
      let st = this.run.replace("-", "");
      let arr = st.split("");
      let st_out = "";
  
      for (let i=0; i < arr.length; i++)
      {
        st_out = st_out + arr[i];
  
        if (i == arr.length - 2)
        {
          st_out = st_out + "-"
        }
      }
  
      this.run=st_out;
    }


    async submitForm($ev:any, value: any) {

        $ev.preventDefault();

        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
    
        if (this.valForm.valid) {
            value["bank"] = this.bank_selected;
            try {
                const bd: any = await this.settings.get_query(3, 'account/' + this.id + '/',value);
                var body_data: any =bd._body;
                switch (bd.status) {
                  case 200:
          
                  this.disabledButton = false;
                  this.showSpinner = false;

                  let sb = this.snackBar.open("¡Cuenta actualizada!", "Aceptar", {
                      duration: 3000,
                      panelClass: ['snackbar-out-main-success']
                  });

                  this.banco = body_data.account.bank.name
                  this.proveedor = body_data.account.name;
          
          
                    break;
                  default:
          
                    break;
                }
              } catch (bd: any) {
          
              }
        }


      }

}
