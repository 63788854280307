import { Component, Input, model, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SettingsService } from "src/app/settings/settings.service";


declare var $: any;

@Component({
  selector: 'app-agendar',
  templateUrl: './agendar.html',
  styleUrls: ['./agendar.less']
})
export class AgendarOnboarding implements OnInit {

  @Input() step:any=1;
  id_company = localStorage.getItem('company_id');
  selected:any = new Date;

  constructor(private dialogRef: MatDialogRef<AgendarOnboarding>, public settings: SettingsService, public dialog: MatDialog, private metaTagService: Meta, public route:Router ) { 


    }

  ngOnInit() {

    this.get_feriados();
    this.get_bloques();


  }


  dateFilter: (date: Date) => boolean = (date: Date) => {
    const day = date.getDay();
    const isWeekend = day === 0 || day === 6; 
    const isHoliday = this.holidayDates.some((holiday:any) => 
      holiday.getFullYear() === date.getFullYear() &&
      holiday.getMonth() === date.getMonth() &&
      holiday.getDate() === date.getDate()
    );

    return !isWeekend && !isHoliday;
  };

  selecciono_fecha:boolean=false;
  sel_fecha($event:any){
    console.log($event)
    this.selecciono_fecha = true;
  }

  loading_feriados:boolean=false;
  feriados:any[]=[];
  holidayDates:any;
  async get_feriados(){


    try {

      this.feriados = [];
      this.loading_feriados = true;
      const bd: any = await this.settings.get_query(1, '/get-dias-feriados/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          console.log("feriados", data)
          this.feriados = data.info;
          this.holidayDates = this.feriados.map(holiday => new Date(holiday.fecha));
          this.loading_feriados = false;
          


        break;
        }

    } catch (bd:any) {

      this.loading_feriados = false;

    }

  }

  bloques:any[]=[];
  loading_bloques:boolean=false;
  selectedIndex: number | null = null; 
  hora_seleccionada:any=null;
  fecha_mandar:any;
  selectHour(index: number, hora:any): void {
    this.hora_seleccionada = hora;
    this.selectedIndex = index; 

    if (this.selected instanceof Date && !isNaN(this.selected.getTime())) {
      const day = this.selected.getDate().toString().padStart(2, "0");
      const month = (this.selected.getMonth() + 1).toString().padStart(2, "0"); 
      const year = this.selected.getFullYear().toString();
  
      this.fecha_mandar = `${day}/${month}/${year}`;
    }

  }

  async get_bloques(){


    try {

      this.bloques = [];
      this.loading_bloques = true;
      const bd: any = await this.settings.get_query(1, '/onboarding/get-horarios/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          this.bloques = data.info;
          this.loading_bloques = false;

          


        break;
        }

    } catch (bd:any) {

      this.loading_bloques = false;

    }

  }

  loading:boolean=false;
  async agendar(){


    try {

      let value = {
        "company": this.id_company,
        "fecha": this.fecha_mandar,
        "hora":this.hora_seleccionada,
        "accion": 1
      }

      this.loading = true;
      const bd: any = await this.settings.get_query(2, '/onboarding/onboarding-llamada/', value);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 

          this.loading = false;
          this.step = 2;
          


        break;
        }

    } catch (bd:any) {

      this.loading = false;

    }

  }


  continuar(){
    this.dialogRef.close(true)
  }
}