<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
  </div>
  
  <div mat-dialog-content>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn animate__faster">

      <div class="empty-msg">
  
        
        <div style="padding-top: 50px;text-align: center;">
          <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
        </div>
  
        <div style="padding-top: 50px;text-align: center;margin-top: 15px;margin-bottom: 15px;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;">
              Agrega facturas individualmente o 
            </div>
  
            <div class="label-empty" style="font-weight: bold;">
              carga una nómina completa. ¡Tú eliges!
            </div>
  
          </mat-label>
        </div>
  
        <div class="row" style="margin-top: 50px;">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  
            <div class="btn-simpli-container">
              <button class="secondary-button" style="margin-right: 15px;" (click)="open_agregar_facturas()"  mat-flat-button>Agregar factura</button>
  
              <button class="secondary-button"  (click)="open_agregar_facturas_by_nomina();" mat-flat-button>Cargar nómina</button>
            </div>
            
          </div>
        </div>
  
      </div>
  
  
    </div>

  
  </div>