

<mat-card class="shadow-box" style="position: relative;min-height: 500px;">

    <mat-card-header style="display: block;padding: 0;">
        <h1 class="titulo-simpli">Ranking Cesionario</h1>
        <small class="subtitulo-simpli">{{ this.date_start}} / {{ this.date_end}}</small>
      </mat-card-header>

    <mat-card-content>



        <div class="row" *ngIf="hay_data == true && loading == false">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
                <mat-form-field  *ngIf="hay_data == true && loading == false">
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)">
                </mat-form-field>
            </div>

        </div>

        <div class="row" *ngIf="hay_data == true && loading == false">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                <div class="table-responsive-simpli">
                    <table class="table" mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="factoring">
                            <th mat-header-cell *matHeaderCellDef> Factoring </th>
                            <td mat-cell *matCellDef="let element">
                                <input matTooltip="{{element.factoring}}" (click)='openDetalleCesiones(element)' readonly class="no-input" value="{{element.factoring.substring(0, 30)}}">
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">Total</td>
                        </ng-container>
            
                        <ng-container matColumnDef="monto">
                            <th mat-header-cell *matHeaderCellDef> Monto </th>
                            <td mat-cell *matCellDef="let element">
                                 <input readonly class="no-input" value="${{formatMoney(element.monto)}} ">
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">${{formatMoney(anotherArray[0]?.monto)}}</td>
                        </ng-container>
            
                        <ng-container matColumnDef="porcentaje">
                            <th mat-header-cell *matHeaderCellDef> % </th>
                            <td mat-cell *matCellDef="let element"><input readonly class="no-input" value="{{element.porcentaje}}%"></td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">{{anotherArray[0]?.porcentaje}}</td>
                        </ng-container>
            
                        <ng-container matColumnDef="conteocesiones">
                            <th mat-header-cell *matHeaderCellDef> Facturas </th>
                            <td mat-cell *matCellDef="let element"><input readonly class="no-input" value="{{element.conteocesiones}}"></td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">{{anotherArray[0]?.conteocesiones}}</td>
                        </ng-container>
            
                        <ng-container matColumnDef="conteodeudores">
                            <th mat-header-cell *matHeaderCellDef> Clientes </th>
                            <td mat-cell *matCellDef="let element"><input readonly class="no-input" value="{{element.conteodeudores}}"></td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold;text-align: center;">{{anotherArray[0]?.conteodeudores}}</td>
                        </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let element; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    </table>
                    <mat-paginator *ngIf="info && !cargando" #paginator [length]="this.len_info" [pageSize]="5"></mat-paginator>
                </div>
            </div>

        </div>

        <div class="row" *ngIf="hay_data == false && loading == false">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    
              <div class="empty-msg">
            
                    
                <div style="padding-top: 50px;text-align: center;">
                  <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                </div>
          
                <div style="padding-top: 50px;text-align: center;">
                  <mat-label class="label-bienvenida">
                    <div class="label-empty">
                      ¡Ups! Al parecer no estas sincronizado.
                    </div>
          
                    <div class="label-empty" style="margin-top: 20px;">
                      Cargar tus credenciales y dale click al botón sincronizar información en la parte superior.
                    </div>
          
                  </mat-label>
                </div>
          
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          
                    <div class="btn-simpli-container">
                      <button class="primary-button" (click)="open_credenciales()" mat-flat-button>Cargar credenciales</button>
                    </div>
                    
                  </div>
                </div>
          
              </div>
    
            </div>
    
        </div>

        <div class="row animate__animated animate__fadeIn" *ngIf="hay_data == false && loading == true">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="sinRegistros" style="text-align: center;">
                    <small class="small-sinregistros" style="font-size: 15px;margin-top: 20px;">
                      <div style="text-align: center;">
                        <i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                    </div>
                    </small>
                </div>
            </div>
    
        </div>



    </mat-card-content>
</mat-card>  

