<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close style="display: none;">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <span *ngIf="!sii_lento"><i style="font-size: 65px;color:var(--simpli-color7)" class="fa-regular fa-circle-check"></i></span>
            <span *ngIf="sii_lento"><i style="font-size: 65px;color:var(--simpli-color8)" class="fa-regular fa-circle-exclamation"></i></span>
        </div>

        <div class="label-dialog-strong">{{title}}</div>
        <div class="label-dialog">{{subtitle}}</div>


        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container">
              <button *ngIf="!sii_lento" class="primary-button" mat-flat-button (click)="ver_estado()">
                Ver estado
              </button>

              <button *ngIf="sii_lento" class="primary-button" mat-flat-button (click)="retry()">
                Reintentar
              </button>
            </div>
            
          </div>
        </div>

    </div>


    
</mat-dialog-content>