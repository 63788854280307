import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SettingsService } from "src/app/settings/settings.service";
import { AgendarOnboarding } from "../agendar/agendar";


declare var $: any;

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.html',
  styleUrls: ['./info-modal.less']
})
export class InfoModalOnboarding implements OnInit {

  @Input() step:any;
  id_company = localStorage.getItem('company_id');

  constructor(private dialogRef: MatDialogRef<InfoModalOnboarding>, public settings: SettingsService, public dialog: MatDialog, private metaTagService: Meta, public route:Router ) { 


    }

  ngOnInit() {

    if(this.step == 12){
      this.get_deudores_calificados();
    }


  }

  ingresar_al_sii(){
    window.open("https://zeusr.sii.cl//AUT2000/InicioAutenticacion/IngresoRutClave.html?https://misiir.sii.cl/cgi_misii/siihome.cgi", "_blank")
  }

  agendar() {
    console.log("a")
    let dialogRef = this.dialog.open(AgendarOnboarding, {
      width: '700px',
      minHeight: '600px',
      autoFocus: false,
    });


  }

  continue(){
    this.dialogRef.close(true)
  }

  reintentar(){
    this.dialogRef.close('retry')
  }

  continuar_agregar(){
    this.dialogRef.close('add')
  }

  continue_six(){
    this.dialogRef.close('six')
  }

  open_modal_info(step:any) {
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.step = step;


  }

  deudores:any[]=[];
  loading_deudores:boolean=false;
  async get_deudores_calificados(){


    try {

      this.deudores = [];
      this.loading_deudores = true;
      const bd: any = await this.settings.get_query(1, '/onboarding/deudores-calificados/'+this.id_company+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          this.deudores = data.info
          this.loading_deudores = false;
          


        break;
        }

    } catch (bd:any) {

      this.loading_deudores = false;

    }

  }


}