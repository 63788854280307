<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
        <h1 class="titulo-simpli">Cesión asistida</h1>
      </div>
    </div>
  </div>
</div>



<div *ngIf="loading" class="center-items">
    <mat-spinner diameter="40" class="spinner-arrayan-general centerabsolute"></mat-spinner>
</div>


<div class="simpli-floating-box" *ngIf="successConfirmCesionAsistida && !companyWithCesionAsistida">
  <div class="simpli-floating-box-body">
    <div class="simpli-floating-box-logo text-center">
      <i style="color:#3BA46F;font-size: 65px;" class="fa-light fa-circle-check"></i>
    </div>
      <div class="text-center">
        <span>
          <strong style="font-size: 16px;color:#1b0943;">¡Recibimos tu confirmación!</strong><br><br>
          Te notificaremos cuando tu ejecutivo<br>
          termine de realizar la cesión.
        </span>
        <input routerLink="/login" type="button" value="Salir" class="simpli-floating-box-button">
      </div>
    </div>
</div>

<div class="simpli-floating-box" *ngIf="companyWithCesionAsistida">
  <div class="simpli-floating-box-body">
    <div class="simpli-floating-box-logo text-center">
      <i style="color:#3BA46F;font-size: 65px;" class="fa-light fa-circle-check"></i>
    </div>
      <div class="text-center">
        <span>
          <strong style="font-size: 16px;color:#1b0943;">¡Ya cuentas con cesión asistida!</strong><br><br>
          Tu ejecutivo ya puede realizar cesiones por ti<br>
          Comunicate con el si tienes alguna duda.
        </span>
        <input routerLink="/login" type="button" value="Salir" class="simpli-floating-box-button">
      </div>
    </div>
</div>



<div class="simpli-floating-box" *ngIf="show_expired && !loading && !successConfirmCesionAsistida && !companyWithCesionAsistida">
  <div class="simpli-floating-box-body">
    <div class="simpli-floating-box-logo text-center">
      <i style="color:#924eff;font-size: 65px;" class="fa-light fa-circle-info"></i>
    </div>
      <div class="text-center">
        <span>
          <strong style="font-size: 16px;color:#1b0943;">Este enlace caducó</strong><br><br>
          Para autorizar la cesión asistida, solicita<br>
          un nuevo enlace de confirmación.
        </span>
        <input (click)="solicitar_enlace()" type="button" value="Solicitar enlace" class="simpli-floating-box-button">
      </div>
    </div>
</div>






