
import { Component, OnInit } from '@angular/core';


declare var $: any;


@Component({
  selector: 'app-pantalla-info-sync',
  templateUrl: './pantalla-info-sync.html',
  styleUrls: ['./pantalla-info-sync.less']
})
export class InfoSyncComponent implements OnInit {



  constructor() {



   }



  ngOnInit() {


  }




}