import { Component, Input, OnInit } from '@angular/core';
declare var $ :any;

@Component({
  selector: 'app-editar-facturas-venta',
  templateUrl: './editar-facturas-venta.component.html',
  styleUrls: ['./editar-facturas-venta.component.less']
})
export class EditarFacturasVentaComponent implements OnInit {

  deuda:any;
  deuda_total:any;
  id_simulation:any;
  manual_ventas:any;    
  simulacion_recibida:any;
  facturas_venta:any;
  facturas:any;

  constructor() { }

  ngOnInit() {
  }

}
