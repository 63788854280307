<div class="row row_main" style="padding-bottom: 70%;">

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <div class="row">
      <div id="columna_titulo" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h1  class="titulo-simpli">Cartera vigente</h1>
        <small class="subtitulo-simpli">Vista Previa</small><br>
      </div>
    </div>


    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div class="container-box">

          <div [ngClass]="{'box-cartera': true, 'box1-active':hay_box1 == true}">

            <div (click)="apply_filter_box('vig')"  [ngClass]="{'gestion-box': true}">
              <div class="label-box" style="color:#3BA46F;">
                  Vigente
              </div>
              <div style="padding-right: 10px;padding-left: 10px;width: 100%;">
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[1].total_monto)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[1].total_saldo)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[1].total_doctos}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Doc tot.</div> 
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[1].saldo_vs_global}}%
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo/Global</div>
                </div>
    
              </div>
            </div>
    
          </div>

          <div [ngClass]="{'box-cartera': true, 'box1-active':hay_box2 == true}">

            <div (click)="apply_filter_box('1-30')" [ngClass]="{'gestion-box': true}">
  
              <div class="label-box" style="color:#caa800;">
                1-30
              </div>
              <div style="padding-right: 10px;padding-left: 10px;width: 100%;">
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[2].total_monto)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[2].total_saldo)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[2].total_doctos}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Doc tot.</div> 
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[2].saldo_vs_global}}%
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo/Global</div>
                </div>
    
              </div>
            </div>
    
          </div>

          <div [ngClass]="{'box-cartera': true, 'box1-active':hay_box3 == true}">

            <div (click)="apply_filter_box('31-60')" [ngClass]="{'gestion-box': true}">
  
              <div class="label-box" style="color:#ff933b;">
                31-60
              </div>
  
              <div style="padding-right: 10px;padding-left: 10px;width: 100%;">
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[3].total_monto)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[3].total_saldo)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[3].total_doctos}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Doc tot.</div> 
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[3].saldo_vs_global}}%
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo/Global</div>
                </div>
    
              </div>
            </div>
    
          </div>

          <div [ngClass]="{'box-cartera': true, 'box1-active':hay_box4 == true}">

            <div (click)="apply_filter_box('61-90')" [ngClass]="{'gestion-box': true}">
              <div class="label-box" style="color:#fb595e;">
                61-90
              </div>
              <div style="padding-right: 10px;padding-left: 10px;width: 100%;">
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[4].total_monto)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[4].total_saldo)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[4].total_doctos}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Doc tot.</div> 
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[4].saldo_vs_global}}%
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo/Global</div>
                </div>
    
              </div>
            </div>
    
          </div>

          <div [ngClass]="{'box-cartera': true, 'box1-active':hay_box5 == true}">

            <div (click)="apply_filter_box('>90')" [ngClass]="{'gestion-box': true}">
  
              <div class="label-box" style="color:#a90005;">
                >90
              </div>
              <div style="padding-right: 10px;padding-left: 10px;width: 100%;">
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[5].total_monto)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{formatMoney(metricas[5].total_saldo)}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo tot.</div>
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[5].total_doctos}}
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Doc tot.</div> 
                </div>
    
                <div class="row " >
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 firma-obs">
                    @if(loading_metricas){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{metricas[5].saldo_vs_global}}%
                    }
                  </div><div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Saldo/Global</div>
                </div>
    
              </div>
            </div>
    
          </div>

        </div>

      </div>










    </div>
    
 

</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div class="row">

          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-top">
            <form [formGroup]="valForm">
        
              <mat-form-field appearance="fill">
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup.enter)="add_filter($event.target.value);$event.stopPropagation();" (change)="add_filter($event.target.value);$event.stopPropagation();" formControlName="filter">
                <mat-icon matSuffix style="color:#924eff;cursor: pointer;">search</mat-icon>
                <mat-hint>Filtrar por palabra clave</mat-hint>
              </mat-form-field>
        
            </form>
          </div>
        
          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-top" style="text-align: right;">
        

                <button  type="button" class="primary-button" (click)="reset_filter()" mat-flat-button color="primary">Limpiar Filtros</button>
        
                <button color="primary" class="primary-button" [mdePopoverTriggerFor]="filter" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" mat-flat-button>
                  Columnas 
                </button>
            
                <mde-popover #filter="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapEnabled]="false" [mdePopoverCloseOnClick]="false">
                  <mat-card style="padding: 0 !important;">
                      <mat-card-content style="padding: 0;">
                          <section class="example-section">
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_mora">Mora</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_ejecutiva">Cobrador(a)</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_codigo">Codigo</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_saldo">Saldo</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_anticipo">Anticipo</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_monto">Monto</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_f_prorroga">Fecha prorroga</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_n_op">N° operación</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_f_op">Fecha operación</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_v_p">Fecha vencimiento prorroga</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_vencimiento">Fecha vencimiento</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_emision">Fecha emisión</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_folio">Folio</mat-checkbox>
                              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="show_t_doc">Tipo documento</mat-checkbox>
    
                          </section>
                      </mat-card-content>
                  </mat-card>
                </mde-popover>

                <button color="primary" [disabled]="diasbleex"  type="button" class="primary-button" (click)="down()" mat-flat-button>
                  Descargar
                  <span *ngIf="diasbleex == false"><i style="margin-left: 10px;" class="fa-duotone fa-download"></i></span>
                  <span *ngIf="diasbleex == true"><i style="margin-left: 10px;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                </button>


        
      
        
          </div>
        
        
        
        
        
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        
              <mat-chip-listbox aria-label="Fish selection">
                <mat-chip-option [ngClass]="{'animate__animated':true, 'animate__fadeIn':true}" style="background: white;" *ngFor="let i of filter_list" (removed)="remove_filter(i)">
                  <span *ngIf="i != 'vig' && i != '1-30' && i != '31-60' && i != '61-90' && i != '>90'">{{i}}</span>
                  <span *ngIf="i == 'vig'" style="color:#00caae;">Vigente</span>
                  <span *ngIf="i == '1-30'" style="color:#caa800;">1-30</span>
                  <span *ngIf="i == '31-60'" style="color:#ff933b;">31-60</span>
                  <span *ngIf="i == '61-90'" style="color:#fb595e;">61-90</span>
                  <span *ngIf="i == '>90'" style="color:#a90005;">>90</span>
        
                  <button matChipRemove >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>

              </mat-chip-listbox>
        
          </div>
        
        </div>

        <!--desktop detail-->
        <div *ngIf="loading_table == false && ELEMENT_DATA2.length > 0"  class="table-responsive-desktop2 invisible-scroll grabbable"  #elemt2 (mousedown)="startDragging2($event, false, elemt2)" (mouseup)="stopDragging2($event, false)"
              (mouseleave)="stopDragging2($event, false)" (mousemove)="moveEvent2($event, elemt2)">


                <table mat-table [dataSource]="dataSource2"  matSortActive="name" [matSortStart]="sortDirection2" (matSortChange)="sortData2($event)" matSort>
    
    
                  <ng-container matColumnDef="deudor">
                    <th mat-header-cell *matHeaderCellDef>Deudor</th>
                    <td mat-cell *matCellDef="let data" style="min-width: 300px;" matTooltip="{{data.deudor}}"> 
                      <input class="no-input" readonly value="{{data.deudor}}" style="width: 95%;margin: 0 auto;text-align: left;">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="tipo_doc">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_t_doc" style="min-width: 90px !important;max-width: 90px !important; width: 90px !important;">Tipo doc</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_t_doc" style="min-width: 90px !important;max-width: 90px !important; width: 90px !important;"> 
                      <input matTooltip="Factura" *ngIf="data.tipo_doc_descripcion == 'FACTURA'" class="no-input" readonly value="FAC">
                      <input matTooltip="Factura" *ngIf="data.tipo_doc_descripcion == 'FACTURA ELECTRONICA'" class="no-input" readonly value="FAC">
                      <input matTooltip="Cheque" *ngIf="data.tipo_doc_descripcion == 'CHEQUE'" class="no-input" readonly value="CHE">
                      <input matTooltip="Pagares" *ngIf="data.tipo_doc_descripcion == 'PAGARES'" class="no-input" readonly value="PAG">
                      <input matTooltip="Cheque de Pago" *ngIf="data.tipo_doc_descripcion == 'CHEQUE DE PAGO'" class="no-input" readonly value="CHEP">
                      <input matTooltip="Confirming" *ngIf="data.tipo_doc_descripcion == 'CONFIRMING'" class="no-input" readonly value="CONF">
                      <input matTooltip="Credito Directo CR" *ngIf="data.tipo_doc_descripcion == 'CRÉDITO DIRECTO CR'" class="no-input" readonly value="CRED">
    
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="folio" sticky>
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_folio" style="min-width: 100px !important;max-width: 100px !important; width: 100px !important;">folio</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_folio" style="min-width: 100px !important;max-width: 100px !important; width: 100px !important;"> 
                      <input class="no-input" readonly value="{{data.docto}}">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="me" >
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_me" style="min-width: 60px !important;max-width: 60px !important; width: 60px !important;">M.E.</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_me" style="text-align: center;min-width: 60px !important;max-width: 60px !important; width: 60px !important;"> 
                      <span *ngIf="data.has_merito == false"><i style="color:#FA454C" class="fa-sharp fa-regular fa-xmark"></i></span>
                      <span *ngIf="data.has_merito == true"><i style="color:#3BA46F;" class="fa-sharp fa-regular fa-check"></i></span>
                    </td>
                  </ng-container>
    
    
                  <ng-container matColumnDef="emision">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_emision" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">F. Emisión</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_emision" style="min-width: 140px !important;max-width: 140px !important; width: 100140pxpx !important;"> 
                      <input class="no-input" readonly value="{{data.fecha_emision}}">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="vencimiento">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_vencimiento" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">F. Vto.</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_vencimiento" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;" > 
                      <input class="no-input" readonly value="{{data.fecha_v}}">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="v_prorroga">
                    <th mat-header-cell *matHeaderCellDef  [hidden]="!show_v_p" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Ven. Prorroga</th>
                    <td mat-cell *matCellDef="let data"   [hidden]="!show_v_p" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;"> 
                      <input class="no-input" readonly value="--">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="f_operacion">
                    <th mat-header-cell *matHeaderCellDef  [hidden]="!show_f_op" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Fecha Op.</th>
                    <td mat-cell *matCellDef="let data"   [hidden]="!show_f_op" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;"> 
                      <input class="no-input" readonly value="--">
                    </td>
                  </ng-container>
    
    
                  <ng-container matColumnDef="n_operacion">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_n_op" style="min-width: 100px !important;max-width: 100px !important; width: 100px !important;">N° Op.</th>
                    <td mat-cell *matCellDef="let data"  [hidden]="!show_n_op" style="min-width: 100px !important;max-width: 100px !important; width: 100px !important;"> 
                      <input class="no-input" readonly value="--">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="f_prorroga">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_f_prorroga" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Fecha Prorroga</th>
                    <td mat-cell *matCellDef="let data"  [hidden]="!show_f_prorroga" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;"> 
                      <input *ngIf="data.fecha_pr != null" class="no-input" readonly value="{{data.fecha_pr}}">
                      <input *ngIf="data.fecha_pr == null" class="no-input" readonly value="--">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="monto">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_monto" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Monto</th>
                    <td mat-cell *matCellDef="let data"  [hidden]="!show_monto" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;"> 
                      <input class="no-input" readonly value="${{formatMoney(data.monto)}}">
                    </td>
                  </ng-container>
    
                  
                  <ng-container matColumnDef="anticipo">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_anticipo" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Anticipo</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_anticipo" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;" > 
                      <input class="no-input" readonly value="${{formatMoney(data.monto_ant)}}">
                    </td>
                  </ng-container>
    
    
    
                  <ng-container matColumnDef="saldo">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_saldo" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Saldo</th>
                    <td mat-cell *matCellDef="let data"  [hidden]="!show_saldo" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;"> 
                      <input class="no-input" readonly value="${{formatMoney(data.saldo_docto)}}">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_codigo" style="min-width: 240px !important;max-width: 240px !important; width: 240px !important;">Cód.</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_codigo" style="min-width: 240px !important;max-width: 240px !important; width: 240px !important;"> 
                      <input class="no-input" readonly matTooltip="{{data.codigo_docto_descripcion}}" value="{{data.codigo_docto_descripcion}}">
                    </td>
                  </ng-container>
    
    
                  <ng-container matColumnDef="mora">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_mora" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Mora</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_mora" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;"> 
                      <input class="no-input" readonly value="--">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="ejecutivo">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_ejecutiva" style="min-width: 240px !important;max-width: 240px !important; width: 240px !important;">Ejecutivo</th>
                    <td mat-cell *matCellDef="let data" [hidden]="!show_ejecutiva" style="min-width: 240px !important;max-width: 240px !important; width: 240px !important;"> 
                      <input class="no-input" readonly value="{{data.cobrador}}">
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="fondo">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!show_fondo" style="min-width: 140px !important;max-width: 140px !important; width: 140px !important;">Fondeo</th>
                    <td mat-cell *matCellDef="let data" style="min-width: 100px !important;max-width: 140px !important; width: 140px !important;" [hidden]="!show_fondo" [mdePopoverTriggerFor]="history_fondos" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger"> 
                      <input matTooltip="ST CAPITAL S.A." *ngIf="data.fondo == 'ST CAPITAL S.A.'" class="no-input" readonly value="ST.CAP">
                      <input matTooltip="LATAM TRADE CAPITAL" *ngIf="data.fondo == 'LATAM TRADE CAPITAL'" class="no-input" readonly value="LATAM">
                      <input matTooltip="FONDO DE INVERSIONES PRIVADO DEUDA Y FACTURA 1" *ngIf="data.fondo == 'FONDO DE INVERSIONES PRIVADO DEUDA Y FACTURA 1'" class="no-input" readonly value="PDF">
                      <input *ngIf="data.fondo == 'FINAMERIS S.A.'" class="no-input" readonly value="FINAMERIS">
                      <input matTooltip="BPC SERVICIOS Y NEGOCIOS S.A." *ngIf="data.fondo == 'BPC SERVICIOS Y NEGOCIOS S.A.'" class="no-input" readonly value="BPC">
                      <input *ngIf="data.fondo == 'BTG'" class="no-input" readonly value="BTG">
                      <input matTooltip="ADMINISTRADORA DE BENEFICIOS PHARMA BENEFITS CHILE LTDA." *ngIf="data.fondo == 'ADMINISTRADORA DE BENEFICIOS PHARMA BENEFITS CHILE LTDA.'" class="no-input" readonly value="PHARMA">
                      <input matTooltip="CORDADA DE FONDO DE INVERSION PRIVADO DEUDA Y FACTURAS I" *ngIf="data.fondo == 'CORDADA DE FONDO DE INVERSION PRIVADO DEUDA Y FACTURAS I'" class="no-input" readonly value="CORDADA">
                      <input *ngIf="data.fondo == 'SIMPLILATAM'" class="no-input" readonly value="SIMPLI">
                      <input matTooltip="FITZ ROY CAPITAL PREFERENTE (ENDURANCE)" *ngIf="data.fondo == 'FITZ ROY CAPITAL PREFERENTE (ENDURANCE)'" class="no-input" readonly value="FITZ">
                      <input *ngIf="data.fondo == 'BCI'" class="no-input" readonly value="BCI">
                      <input matTooltip="FONDO DE INVERSION PRIVADO FACTURAS NACIONALES" *ngIf="data.fondo == 'FONDO DE INVERSION PRIVADO FACTURAS NACIONALES'" class="no-input" readonly value="PFN">
                      <input matTooltip="FONDO DE INVERSIÓN PRIVADO DEUDA I (CORDADA)" *ngIf="data.fondo == 'FONDO DE INVERSIÓN PRIVADO DEUDA I (CORDADA)'" class="no-input" readonly value="CORDADA">
                      <input matTooltip="FONDO DE INVERSIONES PRIVADOS FACTURAS (BTG)" *ngIf="data.fondo == 'FONDO DE INVERSIONES PRIVADOS FACTURAS (BTG)'" class="no-input" readonly value="BTG">
                      <input matTooltip="SIMPLI LATAM" *ngIf="data.fondo == 'SIMPLI LATAM'" class="no-input" readonly value="SIMPLI">
    
                      <mde-popover #history_fondos="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapEnabled]="false" [mdePopoverCloseOnClick]="false">
                        <mat-card>
                            <mat-card-content>
                                <section class="example-section" *ngIf="data.history_fondo.length > 0">
                                  <div *ngFor="let h of data.history_fondo">-{{h}}</div>
                                </section>
    
                                <section class="example-section" *ngIf="data.history_fondo.length == 0">
                                  <mat-label>No hay registro historico de fondos.</mat-label>
                                </section>
                            </mat-card-content>
                        </mat-card>
                      </mde-popover>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="obs" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef >Acción</th>
                    <td mat-cell *matCellDef="let data" style="text-align: center;cursor: pointer;"> 


                      <button mat-icon-button [matMenuTriggerFor]="menu">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      </button>

                      <mat-menu #menu="matMenu">

                        <button mat-menu-item (click)="observaciones_get(data.id)">
                          <mat-icon>chat</mat-icon>
                          <span>Observaciones</span>
                        </button>

                        <button mat-menu-item *ngIf="data.sesion_url != null" (click)="openPreviewDocument(data.sesion_url)">
                          <mat-icon>check</mat-icon>
                          <span>Primera cesión</span>
                        </button>

                        <button mat-menu-item  *ngIf="data.segunda_sesion_url != null"  (click)="openPreviewDocument(data.segunda_sesion_url)">
                          <mat-icon>done_all</mat-icon>
                          <span>Segunda cesión</span>
                        </button>

                      </mat-menu>


                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="estado_docto__ordenamiento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 150px !important;">Estado</th>
                    <td mat-cell *matCellDef="let data" style="text-align: center;cursor: pointer;min-width: 150px !important;"> 
                      <mat-label *ngIf="data.estado_docto == 'MOROSO'" class="estado-box-morosa">Morosa</mat-label>
                      <mat-label *ngIf="data.estado_docto == 'PRORROGADO'" class="estado-box-prorrogado">Prorrogado</mat-label>
                      <mat-label *ngIf="data.estado_docto == 'PROTESTADO'" class="estado-box-protestado">Protestado</mat-label>
                      <mat-label *ngIf="data.estado_docto == 'VIGENTE'" class="estado-box-vigente">Vigente</mat-label>
                    </td>
                  </ng-container>
    
    
    
                  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns2;" style="background: white !important;border-radius: 4px !important;"></tr>
    
                </table>

        </div>

        <!--EMPTY-->
        <div class="row animate__animated animate__fadeIn" *ngIf="loading_table == false && ELEMENT_DATA2.length == 0">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster">
    
            <div class="empty-msg" style="height: 300px;">
  
              
              <div style="padding-top: 50px;text-align: center;">
                <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
              </div>
      
              <div style="padding-top: 50px;text-align: center;">
                <mat-label class="label-bienvenida">
                  <div class="label-empty">
                    
                      No hay información cargada en tu cartera vigente. <br><br>Si tienes dudas o preguntas contacta a tu ejecutivo. 
                  </div>
  

  
                </mat-label>
              </div>
  
      
            </div>
      
      
      </div>
  
        </div>

        <div class="row animate__animated animate__fadeIn" *ngIf="loading_table == true && ELEMENT_DATA2.length == 0">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  
              <div class="container-loading">
          
                  
                  <div class="img-loading">
                  <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                  </div>
          
                  <div style="margin-top: 20px;">
                  <mat-label class="label-loading">
                      <div>La información está siendo procesada.</div>
                      <div>Esto no tomará mucho tiempo.</div>
                  </mat-label>
                  </div>
          
              </div>
          
          
          </div>
        </div>
        

        <!--PAGINADOR INTERNO-->
        <div style="margin-top: 20px;margin-bottom: 20px;">
          <paginador-onboarding
          [pageSize]="10"
          [totalElements]="pageLength2"
          [currentPage]="page2"
          (pageChange)="pageEvent2($event)">
        </paginador-onboarding>
        </div>
</div>
