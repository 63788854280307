import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { CargaManualRemuneracionesComponent } from '../components/carga-manual-remuneraciones/carga-manual-remuneraciones.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;

@Component({
  selector: 'app-pago-remuneraciones-page',
  templateUrl: './pago-remuneraciones-page.component.html',
  styleUrls: ['./pago-remuneraciones-page.component.less']
})
export class PagoRemuneracionesPageComponent implements OnInit, OnDestroy {
  disabled_download_file: boolean = false;
  hasPreviousNomina: boolean = false;
  company_id!: any;
  nominaList: Array<NominaType> = [];
  accountType: any;
  bancos: any;
  showSpinner: boolean = false;

  countDownload : number = 0;

  message = 'Para visualizar la información debes crear tu primera nómina.'
  submessage = 'Baja el archivo, crea tu nómina y sube tu información.'

  getCompanyIdFromLocalStorage() {
    this.company_id = localStorage.getItem('company_id');
    console.log(this.company_id);
  }

  constructor(
    public router: Router, 
    private settings: SettingsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }
  myObserver: any;

  ngOnInit() {
    this.initComponent();

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.initComponent();
      };
    });

  }


  initComponent() {
    this.getCompanyIdFromLocalStorage();
    this.getNomina();
  }

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }


  async getNomina() {
    this.showSpinner = true;
    try {
      const body_data: any = await this.settings.get_query(1, `remuneraciones-cliente-nomina/${this.company_id}/`);
      const _body = body_data._body.success;
      if (_body.nomina.length > 0) {
        this.hasPreviousNomina = true;
        this.nominaList = _body.nomina;
      } else {
        this.hasPreviousNomina = false;
      }      
      this.accountType = _body.accountType;
      this.bancos = _body.bancos;
      this.showSpinner = false;
    } catch (error: any) {
      this.showSpinner = false;
      console.log(error);
      throw new Error(error);
    }
  }




  async descargar_file() {
    this.disabled_download_file = true;
    try {
      const body_data: any = await this.settings.get_query(
        1,
        `pagoRemuneracionesManuales/${this.company_id}/`
      );
      const _body = body_data._body
      if (body_data) {
        window.open(_body.nameArchivo, "blank");
        this.disabled_download_file = false;
      }
      this.countDownload++;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  openDialogFile() {
    const dialogRef = this.dialog.open(CargaManualRemuneracionesComponent, {
      width: "800px",
      data: {
        company_id: this.company_id,
      },
    });
    dialogRef.componentInstance.company_id = this.company_id;
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(result)
      if (result) {
        this.nominaList = [...result]
        this.hasPreviousNomina = true;
      }
    });
  }
}





export interface NominaType {
  id: number;
  name: string;
  rut: string;
  number: string;
  email: string;
  amount: number;
  bank_id: number;
  id_bank?: number;
  type_id: number;
  id_type?: number;
  error: boolean;
  errorRut: boolean;
  errorName: boolean;
  errorNumber: boolean;
  errorBank: boolean;
  errorType: boolean;
  errorEmail: boolean;
  errorAmount: boolean;
  errorRemuneraciones: boolean;
  arrayError: [];
  bank?: number;
  type?: number;
}
