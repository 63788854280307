<mat-card class="example-card" appearance="outlined">
  <mat-card-header>
    <div mat-card-avatar 
    class="example-header-image" 
    [ngStyle]="avatar ? {'background-image': 'url(' + avatar + ')'} : {}">
 <span *ngIf="!avatar">{{ getInitials(executive) }}</span>
</div>

    <mat-card-title>{{executive}}</mat-card-title>
    <mat-card-subtitle>Ejecutivo</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="margin-top: 20px;">

    <div>Correo: <span class="linkk">{{mail}}</span></div>
    <div>Teléfono: <span class="linkk">{{phone}}</span></div>

    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>

    <div>Encargado del aréa: {{jp}}</div>
    <div>Teléfono: <span class="linkk l2">{{jp_number}}</span></div>
    <div>Correo: <span class="linkk l2">{{jp_mail}}</span></div>

    <div style="margin-top: 10px;margin-bottom: 10px;"></div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button class="primary-button" mat-dialog-close>Cerrar</button>
  </mat-card-actions>
</mat-card>