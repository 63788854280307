
<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <h1 style="text-align: center;visibility: hidden;" class="titulo-simpli">Revisa los AEC que generaste</h1>

  </div>
</div>

<div mat-dialog-content  class="animate__animated animate__fadeIn" style="max-height: 80vh !important;margin-top: 10px;">


  <ngx-doc-viewer style="margin-top: 20px;" [url]="url" [viewer]="viewer" style="height:80vh;"></ngx-doc-viewer>

  
</div>