import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;

@Component({
selector: 'dialog-input',
templateUrl: './dialog-input.html',
styleUrls: ['./dialog-input.less']
})

export class InputDialog implements OnInit {


title:string="Loading Title";
subtitle:string="Vista Previa";
mensajeprincipal:string="";
mensajenegrita:string="";
error_array:any=[]
icon:string="autorenew";
coloricon:string="#ff4081";
boton_text:string="aceptar"
boton_confirm:boolean=false
colorBoton:string="#3BA46F"


constructor(public snackBar: MatSnackBar,
public dialog: MatDialog,
public settings: SettingsService,
public dialogRef:MatDialogRef<InputDialog>) {


}


ngOnInit(){
    setTimeout(()=>{                         
       this.dialogRef.close(true)
   }, 100000);
}

close_dialog(){
    this.dialogRef.close(true)
}

confirmButton(){
    this.dialogRef.close(true)
}



}//endclass
