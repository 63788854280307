import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { registerLocaleData, CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { HotTableModule } from '@handsontable/angular';
import { JoyrideModule } from 'ngx-joyride';
import { CesionAsistida } from './cesion-asistida/cesion-asistida';
import { Certificado } from './credenciales-sii/certificado-sii/certificado';
import { ClaveSIIComponent } from './credenciales-sii/clave-sii/clavesii';
import { CredencialesSII } from './credenciales-sii/credenciales-sii';
import { FacturadorEle } from './credenciales-sii/facturador/facturador';
import { DialogErrors } from './dialog-errors/dialog-errors';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotFound } from './home/404/notfound';
import { ChatClienteComponent } from './home/chat-cliente/chat-app.component';
import { ParrillaCartolaResponsiveComponent } from './home/cliente-new-responsive/parrilla-cartola-responsive/parrilla-cartola-responsive';
import { ParrillaFacturasResponsiveComponent } from './home/cliente-new-responsive/parrilla-facturas-responsive/parrilla-facturas-responsive';
import { ParrillaSimulacionsResponsiveComponent } from './home/cliente-new-responsive/parrilla-simulaciones-responsive/parrilla-simulaciones-responsive.component';
import { SimularFacturasResponsive } from './home/cliente-new-responsive/simular-facturas-responsive/simular-facturas-responsive';
import { addFechaVencimiento } from './home/cliente-new-version/add-fecha-vencimiento/add-fecha-vencimiento';
import { AgregarFacturasNomina } from './home/cliente-new-version/agregar-facturas-nomina/agregar-facturas-nomina';
import { AlertErrorUpload } from './home/cliente-new-version/agregar-facturas-nomina/alert-error-upload/alert-error-upload';
import { AlertError } from './home/cliente-new-version/agregar-facturas-nomina/alert-error/alert-error';
import { AgregarFacturas } from './home/cliente-new-version/agregar-facturas/agregar-facturas';
import { BienvenidaComponent } from './home/cliente-new-version/bienvenida.component';
import { AddFacturaInfo } from './home/cliente-new-version/cesion-facturador/add-factura-info/add-factura-info';
import { AecGeneradados } from './home/cliente-new-version/cesion-facturador/aec-generados/aec-generados';
import { DialogDeleteAec } from './home/cliente-new-version/cesion-facturador/aec-generados/dialog-delete-aec/dialog-delete-aec';
import { AlertErrorCesion } from './home/cliente-new-version/cesion-facturador/alert-error-cesion/alert-error-cesion';
import { AlertSuccessCesion } from './home/cliente-new-version/cesion-facturador/alert-success-cesion/alert-success-cesion';
import { CargarCertificado } from './home/cliente-new-version/cesion-facturador/cargar-certificado/cargar-certificado';
import { InfoCertificadoComponent } from './home/cliente-new-version/cesion-facturador/cargar-certificado/info-certificado/info-certificado';
import { CesionFacturadorComponent } from './home/cliente-new-version/cesion-facturador/cesion-facturador.component';
import { DocumentPreviewComponent } from './home/cliente-new-version/cesion-facturador/document-preview/document-preview';
import { FacturadorExterno } from './home/cliente-new-version/cesion-facturador/facturador-externo/facturador-externo';
import { FacturasDelSii } from './home/cliente-new-version/cesion-facturador/facturas-del-sii/facturas-del-sii';
import { InfoSyncComponent } from './home/cliente-new-version/cesion-facturador/pantalla-info-sync/pantalla-info-sync';
import { QueEsunAEC } from './home/cliente-new-version/cesion-facturador/que_es_un_aec/que_es_un_aec';
import { RechazarCondicionesDialog } from './home/cliente-new-version/cesion-facturador/rechazar-condiciones/rechazar-condiciones';
import { ChatBubbleComponent } from './home/cliente-new-version/chat-bubble/chat-bubble.component';
import { CredencialesCesion } from './home/cliente-new-version/credenciales-cesion/credenciales-cesion';
import { DetalleFacturas } from './home/cliente-new-version/detalle-facturas/detalle-facturas';
import { DetalleOperacionesComponent } from './home/cliente-new-version/detalle-operaciones/detalle-operaciones.component';
import { AlertDelete } from './home/cliente-new-version/documentacion/alert-delete/alert-delete';
import { AlertSuccessUpload } from './home/cliente-new-version/documentacion/alert-success-upload/alert-success-upload';
import { DocumentacionComponent } from './home/cliente-new-version/documentacion/documentacion';
import { InfoDocumentacionComponent } from './home/cliente-new-version/documentacion/info-documentacion/info-documentacion';
import { DialogoSuccess } from './home/cliente-new-version/facturas-del-sii/dialogo-success/dialogo-success';
import { FacturasdelSii } from './home/cliente-new-version/facturas-del-sii/facturas-del-sii';
import { ObsSimulation } from './home/cliente-new-version/observaciones/obs';
import { DetalleObs } from './home/cliente-new-version/parrilla-cartola/detalle-obs/detalle-obs';
import { FacturaDetailDialog } from './home/cliente-new-version/parrilla-cartola/factura-detail/factura-detail';
import { ParrillaCartola } from './home/cliente-new-version/parrilla-cartola/parrilla-cartola';
import { DialogoResumenFacturas } from './home/cliente-new-version/parrilla-cartola/resumen-facturas/dialogo-resumen-facturas/dialogo-resumen-facturas';
import { ResumenAbonosComponent } from './home/cliente-new-version/parrilla-cartola/resumen-facturas/resumen-abonos/resumen-abonos';
import { ResumenFacturasComponent } from './home/cliente-new-version/parrilla-cartola/resumen-facturas/resumen-facturas';
import { DetalleFacturasMalas } from './home/cliente-new-version/parrilla-operaciones/detalle-operacion/detalle-facturas/detalle-facturas';
import { DetailOperationComponent } from './home/cliente-new-version/parrilla-operaciones/detalle-operacion/detalle-operacion';
import { FacturasconProblemasService } from './home/cliente-new-version/parrilla-operaciones/detalle-operacion/service/facturas-con-problemas';
import { ContinuarOperacionComponent } from './home/cliente-new-version/parrilla-operaciones/dialog-cesion-parcial/continuar-operacion/continuar-operacion';
import { DetailOperationParcialComponent } from './home/cliente-new-version/parrilla-operaciones/dialog-cesion-parcial/detalle-operacion-parcial/detalle-operacion-parcial';
import { DialogCesionParcial } from './home/cliente-new-version/parrilla-operaciones/dialog-cesion-parcial/dialog-cesion-parcial';
import { QueEsReemitir } from './home/cliente-new-version/parrilla-operaciones/dialog-cesion-parcial/que_es_reemitir/que_es_reemitir';
import { ParrillaOperaciones } from './home/cliente-new-version/parrilla-operaciones/operaciones';
import { TabOperacionesComponent } from './home/cliente-new-version/parrilla-operaciones/tabs-operaciones/tab.operaciones';
import { ParrillaSimulacionsComponent } from './home/cliente-new-version/parrilla-simulaciones/parrilla-simulaciones.component';
import { AgregarFacturasDialog } from './home/cliente-new-version/simular-facturas/agregar-facturas-dialog/agregar-facturas-dialog';
import { SimularFacturas } from './home/cliente-new-version/simular-facturas/simular-facturas';
import { AlertAnticipo } from './home/cliente-new-version/start-simulation/alert-anticipo/alert-anticipo';
import { AlertSuccess } from './home/cliente-new-version/start-simulation/alert-success/alert-success';
import { StartSimulationComponent } from './home/cliente-new-version/start-simulation/start-simulation';
import { VerSimulacionRechazadaComponent } from './home/cliente-new-version/ver-simulacion-rechazada/ver-simulacion-rechazada';
import { VerSimulacionComponent } from './home/cliente-new-version/ver-simulacion/ver-simulacion';
import { ClienteNuevoComponent } from './home/cliente-nuevo/clientenuevo.component';
import { ConcentracionFactoring } from './home/dashboard/concentracion-factoring/concentracion.factoring';
import { Dashboard } from './home/dashboard/dashboard';
import { DashboardCompras } from './home/dashboard/dashboard-compras/dashboard-compras';
import { DashboardVentas } from './home/dashboard/dashboard-ventas/dashboard-ventas';
import { DialogoVerDetalles } from './home/dashboard/dashboard-ventas/detalle-grafico/verDetalles.component';
import { GraficoParticipacionFactoringComponent } from './home/dashboard/dashboard-ventas/grafico-participacion-factoring/grafico-participacion-factoring.component';
import { ParticipacionVentasComponent } from './home/dashboard/dashboard-ventas/participacion-ventas/participacion-ventas.component';
import { PerfildeudorIframeComponent } from './home/dashboard/dashboard-ventas/perfildeudor-iframe/perfildeudor-iframe.component';
import { DetalleCesionesComponent } from './home/dashboard/dashboard-ventas/ranking-cesionario/components/detalle-cesiones/detalle-cesiones.component';
import { TablaDetalleComponent } from './home/dashboard/dashboard-ventas/ranking-cesionario/components/tabla-detalle/tabla-detalle.component';
import { RankingCesionarioComponent } from './home/dashboard/dashboard-ventas/ranking-cesionario/ranking-cesionario.component';
import { RankingDeudoresVentasComponent } from './home/dashboard/dashboard-ventas/ranking-deudores-ventas/ranking-deudores-ventas.component';
import { ResumenLibrosTableComponent } from './home/dashboard/dashboard-ventas/resumen-libros-sii-table/resumen-libros-sii-table.component';
import { DicomComponent } from './home/dashboard/dicom/dicom.component';
import { GraficoRelacionVentasComponent } from './home/dashboard/grafico-relacion-ventas/grafico-relacion-ventas.component';
import { GraficoDeudorxComprasComponent } from './home/dashboard/participacion-compras/participacion-compras.component';
import { TablaConcentracionesProveedor } from './home/dashboard/tabla-proveedores/tabla-concentraciones.component';
import { NumberedPaginationComponent } from './home/detalle-cartera/custom-paginator/custom-paginator';
import { DetalleCarteraComponent } from './home/detalle-cartera/detalle-cartera.component';
import { ObservacionesCartera } from './home/detalle-cartera/obs-cartera/obs-cartera';
import { ObsCartera } from './home/detalle-cartera/observacioens-cartera/obs';
import { ObservationDisplayComponent } from './home/detalle-cartera/observacioens-cartera/obs-display/obs-display';
import { ExcedentesParrillaComponent } from './home/excedentes-parrilla/excedentes-parrilla.component';
import { FacturasArrayanComponent } from './home/facturas-arrayan/facturas-arrayan.component';
import { FacturasPagadasComponent } from './home/facturas-pagadas/facturas-pagadas.component';
import { UpdateAccountCompanyDialog } from './home/info/ctas-bancarias/ctas/actualizar-ctas/updateacc.component';
import { CreateAccCompanyDialog } from './home/info/ctas-bancarias/ctas/createacc.component';
import { CuentasBancarias } from './home/info/ctas-bancarias/ctas/ctas';
import { CompanyDeleteAccDialog } from './home/info/ctas-bancarias/ctas/deleteacc.component';
import { CuentasBancariasComponent } from './home/info/ctas-bancarias/cuentas-bancarias';
import { Documentos } from './home/info/documentos-company/documentos';
import { DocumentosComponent } from './home/info/files/files.component';
import { InfoComponent } from './home/info/info.component';
import { PasswordComponent } from './home/info/password/password.component';
import { LiquidacionesComponent } from './home/liquidaciones/liquidaciones.component';
import { ListaEmpresasComponent } from './home/lista-empresas/lista-empresas.component';
import { OperacionesAlianzadosComponent } from './home/operaciones-alianzados/operaciones-alianzados.component';
import { DetalleProveedores } from './home/operaciones-proveedores/detalle-proveedores/detalle-proveedores';
import { EditarFacturasCompraComponent } from './home/operaciones-proveedores/editar-facturas-compra/editar-facturas-compra.component';
import { EditarFacturasVentaComponent } from './home/operaciones-proveedores/editar-facturas-venta/editar-facturas-venta.component';
import { FacturasCompraManualComponent } from './home/operaciones-proveedores/facturas-compra-manual/facturas-compra-manual.component';
import { FacturasCompra } from './home/operaciones-proveedores/facturas-compra/facturas-compra';
import { FacturasVenta } from './home/operaciones-proveedores/facturas-venta/facturas-venta';
import { FacturasVentasManuales } from './home/operaciones-proveedores/facturas-venta/facturas-venta-manual/facturas-ventas-manuales';
import { OpProveedores } from './home/operaciones-proveedores/op-proveedores';
import { SimulationOp } from './home/operaciones-proveedores/simulation/simulation';
import { DetalleNominaComponent } from './home/operaciones-remuneraciones/detalle-nomina/detalle-nomina.component';
import { OperacionesRemuneracionesComponent } from './home/operaciones-remuneraciones/operaciones-remuneraciones.component';
import { DialogoEspera } from './home/payments/dialogo-espera/dialogo-espera';
import { PPFacturasPagadasComponent } from './home/payments/facturas-pagadas/pp-facturas-pagadas.component';
import { ComprasComponent } from './home/payments/pago-proveedores-resumen/compras/compras.component';
import { GiroComponent } from './home/payments/pago-proveedores-resumen/giro/giro.component';
import { PagoProveedoresResumenComponent } from './home/payments/pago-proveedores-resumen/pago-proveedores-resumen.component';
import { SimulacionComponent } from './home/payments/pago-proveedores-resumen/simulacion/simulacion.component';
import { VentasComponent } from './home/payments/pago-proveedores-resumen/ventas/ventas.component';
import { DialogCargaManualOnlyComponent } from './home/payments/pago-proveedores/dialog-carga-manual-only/dialog-carga-manual-only.component';
import { PagoProveedores } from './home/payments/pago-proveedores/pago-proveedores';
import { AddCta } from './home/payments/pago-seleccion/add-cta';
import { PagoSeleccion } from './home/payments/pago-seleccion/pago-seleccion';
import { ProveedoresFechasComponent } from './home/payments/proveedores-fechas/proveedores-fechas.component';
import { CargaManualRemuneracionesComponent } from './home/payments/remuneraciones/components/carga-manual-remuneraciones/carga-manual-remuneraciones.component';
import { DragDropComponentComponent } from './home/payments/remuneraciones/components/drag-drop-component/drag-drop-component.component';
import { EmptyMessageComponent } from './home/payments/remuneraciones/components/empty-message/empty-message.component';
import { PreviousNominaComponent } from './home/payments/remuneraciones/components/previous-nomina/previous-nomina.component';
import { SimulacionRemuneracionesComponent } from './home/payments/remuneraciones/components/simulacion-remuneraciones/simulacion-remuneraciones.component';
import { PagoRemuneracionesPageComponent } from './home/payments/remuneraciones/page/pago-remuneraciones-page.component';
import { InputDialog } from './input-dialog/dialog-input';
import { CambiarEmpresa } from './layout/cambiar-empresa/cambiar-empresa';
import { NotificationsDialog } from './layout/dialog-notifications.component';
import { ConfirmDialog } from './layout/dialog.component';
import { LayoutComponent } from './layout/layout.component';
import { MenuSimpli } from './layout/menu-simpli/menu.simpli';
import { MyExecutive } from './layout/my-executive/my-executive';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material/material.module';
import { MiraiCliente } from './mirai-cliente/mirai-cliente';
import { PuenteClienteComponent } from './puente-cliente/puente-cliente.component';
import { RecoverPasswordComponent, DialogMessage } from './recover-password/recover-password.component';
import { RegistroEmpresaComponent, WelcomeModalLogin } from './registro-empresa/registro-empresa.component';
import { PushNotificationService } from './services/push-notification.service';
import { WebSocketService } from './services/websocket.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MdePopoverModule } from '@muntazir86/material-extended';
import localeEs from '@angular/common/locales/es';
import { registerAllModules } from 'handsontable/registry';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RichTextComponent } from './home/cliente-new-version/rich-text/rich-text.component';
import { RelacionCompraVentaComponent } from './home/dashboard/dashboard-ventas/grafico-multivariables/grafico-multivariables.component';
import { VerDetalleDialogoComponent } from './home/dashboard/dashboard-ventas/grafico-multivariables/ver-detalle-dialogo/ver-detalle-dialogo.component';
import { ResumenLibrosSiiTableComponent } from './home/dashboard/dashboard-ventas/grafico-multivariables/resumen-libros-sii-table/resumen-libros-sii-table.component';
import { SinRegistros } from './home/componentes-externos/sin-registros/sin-registros';
import { DetalleExcedentesModal } from './home/excedentes-parrilla/detalle-excedentes/detalle-excedentes';
import { FiltroFacturaComponent } from './home/excedentes-parrilla/filtro-factura/filtro-factura';
import { CustomPaginator } from './custom-paginator/custom-paginator';
import { SinRegistrosCustom } from './sin registros/sin-registros';
import { LoadingInterno } from './loading-interno/loading-interno';
import { FiltroClientesComponent } from './home/excedentes-parrilla/filtro-clientes/filtro-clientes';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { OnBoardingComponent } from './onboarding/onboarding';
import { InfoModalOnboarding } from './onboarding/info-modal/info-modal';
import { CrearSimulacionOnboarding } from './onboarding/crear-simulacion/crear-simulacion';
import { PaginadorOnboarding } from './onboarding/custom-paginator/custom-paginator';

import { AgendarOnboarding } from './onboarding/agendar/agendar';
import { LandingComponent } from './landing/landing.component'; 
import { SimulacionOnboarding } from './onboarding/simulacion/simulacion';


registerLocaleData(localeEs);
registerAllModules();

@NgModule({
  declarations: [
    CesionAsistida,
    AppComponent,
    DialogErrors,
    LoginComponent,
    ForgotPasswordComponent,
    RegistroEmpresaComponent,
    RecoverPasswordComponent,
    LayoutComponent,
    ConfirmDialog,
    MyExecutive,
    CambiarEmpresa,
    MenuSimpli,
    PuenteClienteComponent,
    CredencialesSII,
    FacturadorEle,
    InputDialog,
    ClaveSIIComponent,
    Certificado,
    ClienteNuevoComponent,
    Dashboard,
    TablaConcentracionesProveedor,
    GraficoDeudorxComprasComponent,
    GraficoRelacionVentasComponent,
    AddCta,
    PagoSeleccion,
    ConcentracionFactoring,
    DashboardCompras,
    DashboardVentas,
    DialogoVerDetalles,
    RelacionCompraVentaComponent,
    VerDetalleDialogoComponent,
    ResumenLibrosSiiTableComponent,
    GraficoParticipacionFactoringComponent,
    ParticipacionVentasComponent,
    PerfildeudorIframeComponent,
    RankingCesionarioComponent,
    TablaDetalleComponent,
    DetalleCesionesComponent,
    RankingDeudoresVentasComponent,
    ResumenLibrosTableComponent,
    InfoComponent,
    PasswordComponent,
    DocumentosComponent,
    Documentos,
    CreateAccCompanyDialog,
    CuentasBancarias,
    CompanyDeleteAccDialog,
    UpdateAccountCompanyDialog,
    CuentasBancariasComponent,
    NotFound,
    PuenteClienteComponent,
    DialogErrors,
    CredencialesSII,
    Certificado,
    FacturadorEle,
    CambiarEmpresa,
    MyExecutive,
    MenuSimpli,
    PagoProveedores,
    ProveedoresFechasComponent,
    SimulationOp,
    FacturasVentasManuales,
    FacturasVenta,
    DetalleProveedores,
    FacturasCompra,
    OpProveedores,
    InputDialog,
    DialogErrors,
    ClienteNuevoComponent,
    LayoutComponent,
    FacturasCompraManualComponent,
    EditarFacturasCompraComponent,
    EditarFacturasVentaComponent,
    OperacionesRemuneracionesComponent,
    DetalleNominaComponent,
    PagoProveedoresResumenComponent,
    GiroComponent,
    ComprasComponent,
    VentasComponent,
    SimulacionComponent,
    ProveedoresFechasComponent,
    DialogCargaManualOnlyComponent,
    PagoRemuneracionesPageComponent,
    PPFacturasPagadasComponent,
    PagoRemuneracionesPageComponent,
    CargaManualRemuneracionesComponent,
    PreviousNominaComponent,
    EmptyMessageComponent,
    DragDropComponentComponent,
    SimulacionRemuneracionesComponent,
    ClaveSIIComponent,
    DialogoEspera,
    ExcedentesParrillaComponent,
    OperacionesAlianzadosComponent,
    DetalleCarteraComponent,
    FacturasPagadasComponent,
    FacturasArrayanComponent,
    LiquidacionesComponent,
    ListaEmpresasComponent,
    ObservacionesCartera,
    DicomComponent,
    WelcomeModalLogin,
    DialogMessage,


    //CLIENTE 3.0
    BienvenidaComponent,
    DetalleOperacionesComponent,
    CesionFacturadorComponent,
    NumberedPaginationComponent,
    SimularFacturas,
    NotificationsDialog,
    AgregarFacturas,
    StartSimulationComponent,
    AlertAnticipo,
    AlertSuccess,
    AgregarFacturasNomina,
    AlertError,
    ParrillaSimulacionsComponent,
    AgregarFacturasDialog,
    VerSimulacionComponent,
    VerSimulacionRechazadaComponent,
    ObsSimulation,
    DocumentacionComponent,
    InfoDocumentacionComponent,
    AlertErrorUpload,
    DetalleFacturas,
    AlertDelete,
    AlertSuccessUpload,
    AddFacturaInfo,
    FacturasDelSii,
    addFechaVencimiento,
    RechazarCondicionesDialog,
    AlertSuccessCesion,
    AlertErrorCesion,
    CargarCertificado,
    InfoCertificadoComponent,
    FacturadorExterno,
    ObsCartera,

    //Cartola
    ParrillaCartola,
    FacturaDetailDialog,
    ResumenFacturasComponent,
    DialogoResumenFacturas,
    ResumenAbonosComponent,
    DetalleObs,

    //AEC
    QueEsunAEC,
    AecGeneradados,
    DocumentPreviewComponent,
    DialogDeleteAec,
    CredencialesCesion,
    InfoSyncComponent,

    //OPERACIONES
    ParrillaOperaciones,
    TabOperacionesComponent,
    DetailOperationComponent,
    DetalleFacturasMalas,
    DialogCesionParcial,
    DetailOperationParcialComponent,
    QueEsReemitir,
    FacturasdelSii,
    DialogoSuccess,
    ContinuarOperacionComponent,

    //REPONSIVE SIMPLI
    SimularFacturasResponsive,
    ParrillaSimulacionsResponsiveComponent,
    ParrillaCartolaResponsiveComponent,
    ParrillaFacturasResponsiveComponent,
    
    // CHAT V2
    ChatClienteComponent,
    ChatBubbleComponent,
    ObservationDisplayComponent,

    //MIRAI CLIENTE
    MiraiCliente,
    RichTextComponent,

    //EXTERNAL
    SinRegistros,

    //EXCEDENTES
    DetalleExcedentesModal,
    FiltroFacturaComponent,
    FiltroClientesComponent,
    CustomPaginator,
    SinRegistrosCustom,
    LoadingInterno,
    SkeletonLoaderComponent,


    //ONBOARDING
    OnBoardingComponent,
    InfoModalOnboarding,
    CrearSimulacionOnboarding,
    PaginadorOnboarding,
    AgendarOnboarding,
    LandingComponent,
    SimulacionOnboarding,


    
  ],
  imports: [
    MatSidenavModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    MdePopoverModule,
    FormsModule,
    NgxDocViewerModule,
    HotTableModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: true,
      onSameUrlNavigation: 'reload',
    }),
    JoyrideModule.forRoot(),
  ],
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(),
    MatPaginatorIntl,
    FacturasconProblemasService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES',
    },

    {
      provide: LOCALE_ID,
      useValue: 'es',
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    PushNotificationService,
    WebSocketService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
