import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;


@Component({
  selector: 'app-agregar-facturas',
  templateUrl: './agregar-facturas.html',
  styleUrls: ['./agregar-facturas.less']
})
export class AgregarFacturas implements OnInit {


  valFormFilter!: FormGroup
  is_responsive: boolean = false;
  @Input() se_abrio_desde_carga_masiva:boolean=false;
  @Input() open_as_dialog:boolean=false;
  reemitida_mode:boolean=false;
  simulacion_id:any;

  agregar_onboarding:boolean=false;
  @Input() onboarding:boolean=false;


  constructor(public _MatPaginatorIntl: MatPaginatorIntl, private changeDetectorRef: ChangeDetectorRef, 
    public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, 
    public snackBar:MatSnackBar, private router:Router, public dialogRef:MatDialogRef<AgregarFacturas> ) {

      this.valFormFilter = this.fb.group({
        folio: [null, Validators.compose([Validators.required],), ],
        rut: [null, Validators.compose([Validators.required])],
        deudor: [null, Validators.compose([Validators.required])],
        monto: [null, Validators.compose([Validators.required])],
        fecha_emision_utc: [null, Validators.compose([Validators.required])],
        fecha_emision: [null, Validators.compose([Validators.required])],
      });

    this.valFormFilter.controls['deudor'].disable();

    this.id_company = localStorage.getItem('company_id');

   }


   //1 agregar facturas
   //2 vista previa
   step = 1;
   id_company:any;
   empty_facturas:boolean=false;
  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }
    
    this._MatPaginatorIntl.firstPageLabel = 'primera página';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Facturas por página';
    this._MatPaginatorIntl.lastPageLabel = 'ultima página';
    this._MatPaginatorIntl.nextPageLabel = 'siguiente';
    this._MatPaginatorIntl.previousPageLabel = 'anterior'; 


  }


  facturas:any[]=[];
  monto_total=0;
  error_reemitida:boolean=false;
  loading_process:boolean=false;
  async submitForm($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valFormFilter.controls) {
      this.valFormFilter.controls[c].markAsTouched();
    }
    if (this.valFormFilter.valid) {

      this.loading_process = true;

      value['monto'] = Number(value['monto'].replace(/\./g,''));
      value['deudor'] = this.valFormFilter.controls['deudor'].value;

      let val = {'facturas': value}

      try {

        let endpoint = '/cliente/documentos-simulacion-manual/'+this.id_company+'/';

        if(this.simulacion_id != undefined && this.simulacion_id != '' && this.simulacion_id != null){
          endpoint = '/cliente/documentos-simulacion-manual/'+this.id_company+'/?simulacion_id='+this.simulacion_id
        }

        const bd: any = await this.settings.get_query(2, endpoint, val);
        var body_data: any =bd._body;
        var data = body_data.success;
        switch (bd.status) {
          case 200:



              if(this.se_abrio_desde_carga_masiva == true){
                this.facturas.push(data.info[0]);
                this.dialogRef.close(this.facturas)
                this.length = this.facturas.length;
              }else{

                this.facturas.push(data.info[0]);

                this.valFormFilter.reset();
                this.montoformat = 0;
                this.step = 2;
  
                this.dialogRef.updateSize('700px');
  
  
                let suma=0;
                for(let i in this.facturas){
                  suma = suma + Number(this.facturas[i].monto);
                }

                for(let i in this.facturas){
                  if(this.facturas[i].errorReemitida != null){
                    this.error_reemitida = true;
                    break;
                  }else{
                    this.error_reemitida = false;
                  }
                }
  
                this.monto_total = suma;

                this.length = this.facturas.length;

              }

              

              setTimeout(() => {
                this.dataSource = new MatTableDataSource(this.facturas);
                this.dataSource.paginator = this.paginator;
                this.dataSource._updateChangeSubscription();

                this.obs = this.dataSource.connect();
                this.changeDetectorRef.detectChanges();
              });



              this.loading_process = false;





              

          break;
          default:

          break;
        }
  
      } catch (bd:any) {

        this.loading_process = false;

        switch (bd.status) {
          case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            break;

          case 400: 
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            break;
          default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;
        }
      }
    }
  }

  spinner_rut:boolean=false;
  async validate_rut() {
    let rut = this.valFormFilter.controls['rut'].value;

    try {
      this.spinner_rut = true;

      const bd: any = await this.settings.get_query(1, '/cliente/informacion-sii/?rut=' + rut);
      var body_data: any =bd._body;
      var data = body_data; 
      switch (bd.status) {
        case 200:
  


            if(data.success.info.razon_social == null || data.success.info.razon_social == ""){
              this.valFormFilter.controls['deudor'].enable();
            }else{
              this.valFormFilter.controls['deudor'].setValue(data.success.info.razon_social);
            }



            this.spinner_rut = false;
        break;
        default:
            this.spinner_rut = false;
        break;
      }

    } catch (bd:any) {


      switch (bd.status) {
        case 500:
          this.snackBar.open('Ocurrio un error, intentalo de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });

          this.valFormFilter.controls['rut'].reset();
          this.spinner_rut = false;

          break;

        case 400: 

          this.valFormFilter.controls['deudor'].reset();
          this.valFormFilter.controls['deudor'].disable();
          this.valFormFilter.controls['rut'].setErrors({invalid: true});
          this.spinner_rut = false;

          break;
        default:
          this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });

        break;
      }
    }


  }

  rut_key($e:any){
    let rut = $e.target.value.split('.').join("");

    let st = rut.replace("-", "");
    let arr = st.split("");
    let st_out = "";

    for (let i=0; i < arr.length; i++)
    {
      st_out = st_out + arr[i];

      if (i == arr.length - 2)
      {
        st_out = st_out + "-"
      }
    }

    rut = st_out

    this.valFormFilter.controls['rut'].setValue(rut);

  }

  change_date($event:any){
    let date = new Date($event.value);
    let yyyy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();

    var fecha_emision = yyyy+'-'+mm+'-'+dd;
    this.valFormFilter.controls['fecha_emision'].setValue(fecha_emision);



  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  montoformat:any=0;
  format_monto($event:any){
    this.montoformat = $event.target.value;
    if(this.montoformat == ""){
      this.montoformat = 0;
    }

    if(this.montoformat != 0){
      if(this.montoformat == "" || this.montoformat == null || this.montoformat == undefined){
      
      }else{
        let st = this.montoformat.toString();
        let st_aux = "";
  
        for(let j = 0; j < st.length; j++)
        {
  
          if (!isNaN(parseInt(st[j])))
          {
            st_aux = st_aux + st[j];
  
          }
        }
    
        let i;
        let t_counter = 0;
        let s_out_aux = "";
    
        for (i = st_aux.length - 1; i >= 0; i--){
          s_out_aux = st_aux[i] + s_out_aux;
          t_counter++;
          if (t_counter == 3)
          {
            s_out_aux =  "." + s_out_aux;
            t_counter=0;
          }
        }
    
        if (s_out_aux[0] == ".")
        {
          s_out_aux = s_out_aux.substring(1, s_out_aux.length);
        }
    
        this.montoformat = s_out_aux;
        return this.valFormFilter.controls['monto'].setValue(this.montoformat)
      }
    }else{
      this.valFormFilter.controls['monto'].setErrors({invalid: true});
      
    }

  }

  delete_factura(folio:any){

    const obje = this.facturas.find((x: any) => x.folio == folio);

    if (obje) {
      this.facturas.splice(this.facturas.indexOf(obje), 1);

      let suma=0;
      for(let i in this.facturas){
        suma = suma + Number(this.facturas[i].monto);
      }

      this.monto_total = suma;

      if(this.facturas.length == 0){
        this.empty_facturas = true;
      }else{
        this.empty_facturas = false;
      }

      for(let i in this.facturas){
        if(this.facturas[i].errorReemitida != null){
          this.error_reemitida = true;
          break;
        }else{
          this.error_reemitida = false;
        }
      }

    }

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.facturas);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();

      this.obs = this.dataSource.connect();
      this.changeDetectorRef.detectChanges();
    });

  }

  find_duplicates(){

    let folio = this.valFormFilter.controls['folio'].value;

    const obje = this.facturas.find((x: any) => x.folio == folio);

    if (obje) {
        this.valFormFilter.controls['folio'].setErrors({invalid: true});
        return false;
    }else{
        return true;
    }


  }

  go_to_simular(){
    // const options = {skipLocationChange: true,queryParams: {facturas: JSON.stringify(this.facturas)}};
    // this.router.navigate(["/home/comenzar-simulacion"], options);

    this.dialogRef.close(this.facturas)
  }

  add_mas_facturas(){
    this.step = 1;
    this.empty_facturas = false;
    this.dialogRef.updateSize('530px');
  }

  length=0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  obs!: Observable<any>;
  dataSource = new MatTableDataSource(this.facturas);
}