import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../settings/settings.service';
import { Meta, Title } from '@angular/platform-browser';
import { UrlService } from '../services/url.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $ :any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent implements OnInit {
 
  valForm: FormGroup;
  disabledButton: boolean = false;
  hide:boolean=true;
  role:any;
  showSpinner: boolean = false;

  params:any="";

  constructor(private titleService: Title,  private urlService: UrlService,
    private metaTagService: Meta , fb: FormBuilder, public snackBar: MatSnackBar, private router: Router, public settings: SettingsService, 
    private change:ChangeDetectorRef,private activated_router: ActivatedRoute) {

    this.valForm = fb.group({
      'username': ['', Validators.compose([Validators.required, CustomValidators.email])],
      'password': ['', Validators.compose([Validators.required])]
    });


    this.activated_router.queryParams.subscribe(params => {

    this.params = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
      
    });


  }

  randomImage: string = '';
  private images: string[] = [
    '../../assets/images/33.jpg',
    '../../assets/images/3.jpg',
    '../../assets/images/4.jpg',
    '../../assets/images/7.jpg',
    '../../assets/images/9.jpg',
    '../../assets/images/12.jpg',
    '../../assets/images/14.jpg',
    '../../assets/images/17.jpg',
    '../../assets/images/23.jpg',
    '../../assets/images/2.jpg',
    '../../assets/images/15.jpg',
    '../../assets/images/26.jpg',
  ];

  ngOnInit() {

    this.setRandomBackground();

    this.titleService.setTitle("Clientes Simpli"); 
    this.metaTagService.addTags([  
      { name: 'description', content: 'Recibe liquidez para tu empresa, paga tus compromisos y accede a un ecosistema de soluciones financieras. Solo debes registrarte e ingresar a nuestra plataforma 100% digital.' },
      { name: 'keywords', content: 'factoring, financiamiento, simulaciones, operaciones, liquidez' },  
      { name: 'author', content: 'Simpli S.A.' },  
    ]);  


    this.settings.verify_out();
    this.settings.set_initial_overflow();
    this.change.detectChanges();
    


  }

  setRandomBackground(): void {
    const randomIndex = Math.floor(Math.random() * this.images.length);
    this.randomImage = this.images[randomIndex];
  }
  


  async submitForm($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      try {
        this.showSpinner = true;


        let param = localStorage.getItem('param');
        if(param != null && param != undefined && param != ''){
          value['utm_source'] = param;
        }

        if(this.params != null && this.params != "" && this.params != undefined){
          
          value['utm_source'] = this.params;
        }


        const bd: any = await this.settings.get_query(2, 'cliente/login/', value, true);
        var body_data: any =bd._body;
        var data = body_data.success.info.datos;
        switch (bd.status) {
          case 200:



            localStorage.setItem('token', data.token);
            if(data.role == "conf"){
              localStorage.setItem('role', 'clie');
            }else{
              localStorage.setItem('role', data.role);
            }


            const originalUrl = this.urlService.getOriginalUrl();

            let ruta =  'home' //data.ruta;


            if(data?.informacion_company?.company_id != undefined){
              
              localStorage.setItem('company_id', data.informacion_company.company_id);
              localStorage.setItem('business_name', data.informacion_company.business_name);
              localStorage.setItem('rut', data.informacion_company.rut);

              if(originalUrl == ''){
                this.router.navigate([ruta]);
              }else if(ruta == "portales"){
                this.router.navigate([ruta]);
              }else{
                this.router.navigateByUrl(originalUrl);
              }
  
  
  
              localStorage.removeItem('param');
  
              this.change.detectChanges();

            }else{
              console.log("llamara a emoresas relaconadas")
              this.empresasRelacionadas(ruta)
            }



 

          break;
          default:
            this.showSpinner = false;
          break;
        }
  
      } catch (bd:any) {
        console.log("error", bd)
        this.showSpinner = false;
        switch (bd.status) {
          case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            this.settings.send_mail_500('Recuperar contraseña', bd);
            break;

          case 400: 
            this.snackBar.open('Ocurrió un error, verifica que el email ingresado y la contraseña esten correctas', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            this.valForm.controls['password'].setErrors({invalid: true});
            break;
          default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;
        }
      }
    }
  }

  async empresasRelacionadas(ruta:any) {
    try {
      const bd: any = await this.settings.get_query(1, 'cliente/empresas-relacionadas/?limit=1');
      var body_data: any =bd._body;
      var data = body_data.success.info;
      switch (bd.status) {
        case 200:
          if (data.length > 0) {

  
            let empresa_obj = data[0];

            localStorage.setItem('company_id', empresa_obj.company_id.toString());
            localStorage.setItem('business_name', empresa_obj.business_name)

            const originalUrl = this.urlService.getOriginalUrl();


            if(originalUrl == ''){
              this.router.navigate([ruta]);
            }else if(ruta == "portales"){
              this.router.navigate([ruta]);
            }else{
              this.router.navigateByUrl(originalUrl);
            }



            localStorage.removeItem('param');

            this.change.detectChanges();
          }
        break;
        default:

        break;
      }
    } catch (bd:any) {
      this.settings.manage_errors(bd.status, 'Set empresa local storage', bd, false);
    }
  }
}
