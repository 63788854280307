import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, HostListener, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { ParrillaOperaciones } from '../operaciones';
import { SettingsService } from 'src/app/settings/settings.service';;
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogCesionParcial } from '../dialog-cesion-parcial/dialog-cesion-parcial';
declare var $ :any;

@Component({
  selector: 'tab-operaciones',
  templateUrl: './tab.operaciones.html',
  styleUrls: ['./tab.operaciones.less'],
})

export class TabOperacionesComponent implements OnInit, AfterViewInit {

  token:any=localStorage.getItem('token');
  role:any=localStorage.getItem('role');
  @Input() id_company:any=localStorage.getItem('company_id');

  valFormFacturas!:FormGroup;

  valFormFechas!: FormGroup

  valFormEstados!: FormGroup

  @Input() tabs:any=null;
  @Input() parent:any;
  @Input() open_as_dialog:boolean=false;
  @Input() id_op:any=null;
  
  show_emision: boolean = true;
  show_monto: boolean = true;
  show_me: boolean = false;
  show_ejecutivo: boolean = false;
  show_rutdeudor: boolean = false;
  show_deudor: boolean = true;
  show_rutcliente: boolean = false;
  show_cliente: boolean = false;
  show_estado :boolean= true;
  show_ug: boolean = true;
  show_pg: boolean = false;
  show_uo: boolean = true;
  show_verificadora:boolean=false;
  @Input() show_select:boolean=true;
  show_date_created:boolean=false;

  constructor(public router:Router,public settings: SettingsService,public snackBar: MatSnackBar,private fb: FormBuilder, public dialog:MatDialog, @Optional() private homeComponent: ParrillaOperaciones){

    this.valFormFacturas = this.fb.group({
        'filter': new FormControl(''),
    });

    this.valFormFechas = this.fb.group({
      fecha_ini: '',
      fecha_fin: '',
    });
  
    this.valFormEstados = this.fb.group({
      status: '',
    });


   }


  ngOnInit(): void {

    this.id_company = localStorage.getItem("company_id");

    if(this.tabs != 1){
      this.show_verificadora = true;
    }

    //filtrar automatico desde el ceo por op id
    if(this.id_op != null){
      this.add_filter('op '+this.id_op)
    }

    this.get_facturas();

  }

  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('Company ID changed:', event.detail);
    this.id_company = localStorage.getItem('company_id');
    this.ngOnInit(); 
  }

  ngAfterViewInit(){
    // logica para recargar al cambiar de tab
    this.homeComponent?.updateContent.subscribe((index: number) => {
      if (this.tabs === index) {
        setTimeout(() => {
          this.recharge_tab();
        }, 500);

      }
    });

    if(this.tabs == 4){
      this.show_select = false;
    }
  }

    //DRAG EXTERNO
    mouseDown = false;
    startX: any;
    scrollLeft: any;
    slider = document.querySelector<HTMLElement>('.table-responsive-desktop2');
    startDragging(e:any, flag:any, el:any) {
        this.mouseDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
    }

    stopDragging(e:any, flag:any) {
        this.mouseDown = false;
    }

    moveEvent(e:any, el:any) {
        e.preventDefault();
        if (!this.mouseDown) {
        return;
        }

        const x = e.pageX - el.offsetLeft;
        const scroll = x - this.startX;
        el.scrollLeft = this.scrollLeft - scroll;
    }

    pageIndex = 0;
    pageLength = 0;
    pageSize = 7;
    page = 1;
    pageEvent(pageNumber: number): void {
        this.page = pageNumber;
        this.get_facturas();
    }

    formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? '-' : '';
            const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            const j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
        } catch (e) {
        }
    }
    

    verDescargar:Boolean=false
    loading:boolean=false;
    ELEMENT_DATA:any[]=[];
    displayedColumns: string[] = ['id','status','monto_girar','excedentes','detalle'];
    displayedColumnsRes: string[] = ['data'];
    dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    status:any[]=[];
    async get_facturas(){

        try {
    
          this.ELEMENT_DATA = [];
          this.loading = true;


          const bd: any = await this.settings.get_query(1, '/cliente/curse-operaciones/'+this.id_company+'/?page='+this.page+'&filter='+this.filter+ '&columna=' + this.columna + '&orientacion=' + this.orientacion + '&tabs='+this.tabs + '&status='+this.status_id);
          var body_data: any =bd._body;
          var data = body_data.success;
          switch (bd.status) {
            case 200:
              
    
            this.ELEMENT_DATA = data.info;
            this.pageLength = data.total;
    
    
    
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource._updateChangeSubscription();

            console.log(this.ELEMENT_DATA)
        
    
    
            this.loading = false;
              
    
            break;
            default:
    
            break;
          }
    
        } catch (bd:any) {

    
          switch (bd.status) {
            case 500:
                this.loading = false;
                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });

              break;
    
            case 400: 
                this.loading = false;
                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });
              break;
            default:
                this.loading = false;
                this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                  duration: 20000,
                  panelClass: 'simpli-snack'
                });
    
            break;
          }
        }
    
    
    }

    async recharge_tab(){

      this.arr_facturas = [];
      this.arr_facturas_delete = [];
      this.arr_facturas_id = [];
      this.arr_folios = [];

      try {
  

        const bd: any = await this.settings.get_query(1, '/verificacion/parrillaFacturas/?page='+this.page+'&filter='+this.filter+ '&columna=' + this.columna + '&orientacion=' + this.orientacion + '&tabs='+this.tabs + '&date_ini='+this.fecha_inicio+'&date_end='+this.fecha_fin+'&status='+this.status_id);
        var body_data: any =bd._body;
        var data = body_data.success;
        switch (bd.status) {
          case 200:
            
    
          this.selection.clear();
  
          this.ELEMENT_DATA = data.info;
          this.pageLength = data.total;
  
          for(let i in this.ELEMENT_DATA){
            if(this.ELEMENT_DATA[i].vinculo_contacto == true){
              this.ELEMENT_DATA[i]['checked'] = true;
            }
          }
  
  
          this.ELEMENT_DATA.forEach((element: any) => {
            let obje = this.arr_facturas.find((x: any) => x.docto_id == element.id);        
            if (obje) {
              element.checked = true;
              this.selection.toggle(element);  
            }
          }); 

          this.status = data.status;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource._updateChangeSubscription();
      
  

  
          break;
          default:
  
          break;
        }
  
      } catch (bd:any) {
  
        switch (bd.status) {
          case 500:
              this.loading = false;
              this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });

            break;
  
          case 400: 
              this.loading = false;
              this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });
            break;
          default:
              this.loading = false;
              this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });
  
          break;
        }
      }
  
  
  }

    status_id:any="";
    filtrar_por_estado($event:any){

      if($event.value != undefined){
        this.status_id = $event.value;
      }else{
        this.status_id = "";
      }

      this.get_facturas();
    }

    filter_list:any[]=[];
    filter:any="";
    add_filter(filter:any){
  
      this.filter = filter.toLowerCase();

  


        if(this.filter != ''){
          this.page = 1;
          this.get_facturas();
        }else{
          this.filter = '';
          this.page = 1;
          this.get_facturas();
        }
  
  
  
    }

    get_filter(){
      let filter:any="";
      for( let i in this.filter_list){
        filter = filter +','+ this.filter_list[i];
      }
      filter = filter.substring(1);
      this.filter = filter;
  
      this.get_facturas();
    }

    remove_filter(filter:any){
      const obje = this.filter_list.find((x: any) => x == filter);
  
      if (obje) {
        this.filter_list.splice(this.filter_list.indexOf(obje), 1);
      }
  
  
      this.get_filter();
  
    }

    selection = new SelectionModel<any>(true, []);
    arr_folios:Array<any> = [];
    arr_facturas: Array<any> = [];
    arr_facturas_id: Array<any> = [];
    arr_facturas_delete: Array<any> = [];
    total_seleccionado:any;
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }
  
    masterToggle($event: any) {
        if(!$event.checked) {
            this.selection.clear()
            this.ELEMENT_DATA.forEach((element: any) => {
                element.checked = false;
            });
        }else{
            this.ELEMENT_DATA.forEach((element: any) => {  
                let object = this.arr_facturas.find((x: any) => x.docto_id === element.id);
                if(object){
                    element.checked = true;
                    this.selection.select(element);   
                }
            });
        }
    }
  
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
            return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
    
    selectOneElement(docto: any, objeto: any) {
        const obje = this.arr_facturas.find((x: any) => x.docto_id == objeto.id);
    
        if (obje) {
            this.arr_facturas.splice(this.arr_facturas.indexOf(obje), 1);
            this.arr_folios.splice(this.arr_folios.indexOf(obje), 1);
        
        } else {
          this.arr_facturas.push({"docto_id": objeto.id});
          this.arr_folios.push(objeto)
        }
    
        this.total_seleccionado = 0;
        var group = this.groupBy(this.arr_facturas);
        this.total_seleccionado = Object.keys(group).length;


  
    }
    
    selectAllElements(){
      if (this.isAllSelected()) {
          for(let i=0;i<this.dataSource.filteredData.length;i++){
              const obje = this.arr_facturas.find((x: any) => x.docto_id == this.dataSource.filteredData[i].id);        
              if (obje) {
                  this.arr_facturas.splice(this.arr_facturas.indexOf(obje), 1);
                  this.arr_folios.splice(this.arr_folios.indexOf(obje), 1);
              }
          }
    
      } else {
          for(let i=0;i<this.dataSource.filteredData.length;i++){
              const obje = this.arr_facturas.find((x: any) => x.docto_id == this.dataSource.filteredData[i].id);        
              if (obje) {
              } else {
                this.arr_facturas.push({"docto_id": this.dataSource.filteredData[i].id});
                this.arr_folios.push(this.dataSource.filteredData[i])
              }
          }
      }
    
      this.total_seleccionado = 0;
      var group = this.groupBy(this.arr_facturas);
      this.total_seleccionado = Object.keys(group).length;
    
    
    }
    
    groupBy(array: any) {
        return array.reduce((result: any, currentValue: any) => {(result[currentValue["id"]] = result[currentValue["id"]] || []).push(
            currentValue
            );
            return result;
        }, {}); 
    }

    columna = "";
    orientacion = "";
    sortDirection:any="asc"
    sortData(sort: Sort) {
  
  
      this.columna = sort.active;
      this.orientacion = sort.direction;
  
  
      if(sort.direction == "asc"){
        this.sortDirection = "desc"
      }else if(sort.direction == "desc"){
        this.sortDirection = "asc";
      }else{
        this.sortDirection = "";
      }
  
      this.get_facturas()
  
  
    }

    fecha_inicio:any="";
    fecha_fin:any="";
    date_change_start($event:any){
  
      let date_start = new Date(this.valFormFechas.controls['fecha_ini'].value);
      let dia, mes, ano;
      dia = String(date_start.getDate())
      mes = String(date_start.getMonth() + 1)
      ano = String(date_start.getFullYear())
      if (mes.length == 1) {
        mes = '0' + mes
      }
      if (dia.length == 1) {
        dia = '0' + dia
      }
  
      let start = dia + '-' + mes + '-' + ano;
      let start2 = dia + '-' + mes + '-' + ano;
  
      this.fecha_inicio = start;
      this.fecha_fin = start2;
  
  
    }
  
    date_change_end($event:any){
  
      if(this.valFormFechas.controls['fecha_fin'].value == null){
  
        this.fecha_inicio = this.fecha_inicio;
  
        let date_end = new Date(this.valFormFechas.controls['fecha_ini'].value);
        let dia2, mes2, ano2;
        dia2 = String(date_end.getDate())
        mes2 = String(date_end.getMonth() + 1)
        ano2 = String(date_end.getFullYear())
        if (mes2.length == 1) {
          mes2 = '0' + mes2
        }
        if (dia2.length == 1) {
          dia2 = '0' + dia2
        }
    
        let end = dia2 + '-' + mes2 + '-' + ano2;
        this.fecha_fin = end
  
      }else{
        let date_end = new Date(this.valFormFechas.controls['fecha_fin'].value);
        let dia2, mes2, ano2;
        dia2 = String(date_end.getDate())
        mes2 = String(date_end.getMonth() + 1)
        ano2 = String(date_end.getFullYear())
        if (mes2.length == 1) {
          mes2 = '0' + mes2
        }
        if (dia2.length == 1) {
          dia2 = '0' + dia2
        }
    
        let end = dia2 + '-' + mes2 + '-' + ano2;
        this.fecha_inicio = this.fecha_inicio;
        this.fecha_fin = end
      }
  
    }
  
    filtrar_por_fechas(){
      this.pageIndex = 0;
      this.page = 1;
      this.get_facturas();
    }

    reset(){
      this.valFormFechas.reset();
      this.fecha_fin = "";
      this.fecha_inicio = "";
      this.get_facturas();
    }

    refrescar(){
      this.fecha_fin = "";
      this.fecha_inicio = "";
      this.page = 1;
      this.filter = "";
      this.columna = "";
      this.orientacion = "";
      this.sortDirection="asc";
      this.status_id="";
      this.filter_list = [];
      this.arr_facturas = [];
      this.arr_folios = [];

      this.valFormEstados.controls['status'].setValue(undefined)
      this.valFormFechas.reset();
      this.valFormEstados.reset();
      this.valFormFacturas.reset();
      this.get_facturas();

    }

    go_to_detail(id_op:any, id_sim?:any, status?:any, cesion_parcial?:boolean){
      //ya borro las facturas malas y va a continuar
      if(status == 16 && cesion_parcial == true){
        this.router.navigate(['home/continuar-operacion/',id_sim])
      }else if(status == 16 && cesion_parcial == false){
      //tambien ya borro las facturas y va a continuar, pero tambien agrego facturas nuevas o quiere agregar nuevas (?)
        this.router.navigate(['home/continue-operation-detail-parcial/',id_op,id_sim, cesion_parcial])
      }else{
        this.router.navigate(['home/operation-detail/',id_op])
      }

    }


    simular(){

        this.router.navigate(['home']);
        setTimeout(() => {
          $("#caja_excel").trigger('click')
        });
      
  
    }


    open_dialog_cesion_parcial(id_op:any, id_sim:any, cesion_parcial?:boolean){

      if(cesion_parcial == true){
        this.router.navigate(['home/continue-operation-detail-parcial/',id_op, id_sim, cesion_parcial])
      }else{
        const dialogRef = this.dialog.open(DialogCesionParcial,{
          width: '650px',
          disableClose: true,
          autoFocus: false,
        });
    
        dialogRef.componentInstance.id_op = id_op;
        dialogRef.componentInstance.id_sim = id_sim;
  
    
        dialogRef.afterClosed().subscribe((result:any) => {
          if(result == true){
            this.refrescar()
          }
        });
      }
    }



 
}

