import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $ :any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {

  valForm: FormGroup;
  errorMsg: string = "";
  show_spinner:boolean=false;

  constructor(fb: FormBuilder, public snackBar: MatSnackBar, public settings: SettingsService, private router: Router, private change:ChangeDetectorRef) {

    this.valForm = fb.group({
      'email': ['', Validators.compose([Validators.required, CustomValidators.email])],
    });

   }

  ngOnInit() {
    this.settings.set_initial_overflow();
    this.change.detectChanges();
    setTimeout(() => {
      $(".gatblac-new").css('display','none');
    });
  }
  
  ngAfterViewChecked() {
    setTimeout(() => {
      $("#gbChatbot").css('display', 'none')
    });
  }

  // Back nuevo app cliente
  async submitForm($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      try {
        this.show_spinner = true;
        const bd: any = await this.settings.get_query(3, 'cliente/forgot-password/', value, true);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
            this.show_spinner = false;
            this.snackBar.open("Hemos enviado un enlace de recuperación a tu correo. Tendrás 15 min para acceder a él, antes de que caduque.", "Aceptar", {
              panelClass: "simpli-snack",
              duration: 10000
            });            
            this.router.navigate(['/login']);
          break;
          default:
            this.show_spinner = false;
          break;
        }
  
      } catch (bd: any) {
        this.show_spinner = false;
        switch (bd.status) {
          case 400:
            this.valForm.controls['email'].setErrors({invalid: true});
            this.errorMsg = "Hemos enviado un enlace de recuperación a tu correo. Tendrás 15 min para acceder a él, antes de que caduque."
          break;
          case 404:
            this.valForm.controls['email'].setErrors({invalid: true});
            this.errorMsg = "El correo electrónico ingresado no está registrado"
          break;
          case 500:
            this.snackBar.open("Ocurrió un error, intenta de nuevo", "Aceptar", {
              duration: 20000,
              panelClass: "simpli-snack"
            });
            this.settings.send_mail_500("Recuperar contraseña", bd);
          break;
          default:
            
          break;
        }
      }
    }
  }
  // Back nuevo app cliente
}
