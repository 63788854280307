<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content style="max-height: 100% !important;">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <h1 class="title-label">¿Cómo reemitir una factura?</h1>
  </div>



  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <div class="row">

          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <div class="p-label">
                Reproduce el video y conoce el paso a paso para volver a emitir una factura.
              </div>

              <div style="margin-top: 30px;">
                  <video controls class="video-simpli">
                    <source src="https://simpliassets12daacw.s3.us-east-2.amazonaws.com/tutorial_reemision_facturas_sii.mp4" type="video/mp4">
                    Tu navegador no soporta el tag de video.
                  </video>
              </div>


              <div class="en_responsive" style="margin-top: 60px;margin-bottom: 60px;">


                <button class="secondary-button" mat-flat-button mat-dialog-close>
                  Volver
                </button>
              </div>
          </div>

    </div>

  </div>





</mat-dialog-content>