<div [ngClass]="{'row':true, 'row_main':step != 2 && step != 4 && step != 0 && step != 5 && step != 3}">

   
  <!--Bienvenida-->
  @if(step == 1){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn" style="margin-top: 50px;">
      <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="titulo-onboarding">
            finánciate y monitorea tu negocio en tiempo real
          </div>
          <div class="subtitulo-onboarding">
            <strong>Sincroniza tu empresa</strong> y cursa <span  (click)="open_modal_info(6)">operaciones de factoring</span> mientras sigues el progreso de tu negocio.
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="swiper-container">
            <swiper-container id="swiper" #swiper 
            speed="500" autoplay="false" css-mode="false" loop="true"
            navigation="false" pagination="true" scrollbar="false" virtual="false" >
  
  
              <swiper-slide>

                <div class="row">

                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="swipper-content">
                      <div>
                        <img src="../../assets/iconografia/swiper1.svg" />
                      </div>
                      <div>
                        Accede a tu dashboard<br>contable
                      </div>
      
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="swipper-content">
                      <div>
                        <img src="../../assets/iconografia/swiper2.svg" />
                      </div>
                      <div>
                        Indentifica a tus<br>principales clientes
                      </div>
    
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="swipper-content">
                      <div>
                        <img src="../../assets/iconografia/swiper3.svg" />
                      </div>
                      <div>
                        consulta el Dicom de<br>tu empresa
                      </div>
      
                    </div>
                  </div>

                </div>
      

              </swiper-slide>


              <swiper-slide>

                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="swipper-content">
                      <div>
                        <img src="../../assets/iconografia/swiper4.svg" />
                      </div>
                      <div>
                        Revisa tus facturas<br>pagadas
                      </div>
      
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="swipper-content">
                      <div>
                        <img src="../../assets/iconografia/swiper5.svg" />
                      </div>
                      <div>
                        Gestiona tus<br>proveedores
                      </div>
      
                    </div>
                  </div>
                </div>


      

              </swiper-slide>





            </swiper-container>
          </div>




        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="subtitulo-onboarding">
            Descubre <span (click)="open_modal_info(1)">qué es la sincronización</span> y conoce sus <span (click)="open_modal_info(2)">requisitos.</span>
          </div>

          <div class="caja-botones">

            <button mat-stroked-button class="primary-button" (click)="open_modal_info(7)">Sincronizar mi empresa</button>
            <button mat-stroked-button class="secondary-button" (click)="step = 0">Omitir por ahora</button>
          </div>
        </div>


      </div>

    </div>
  }

  <!--Clave sii-->
  @if(step == 2){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

      <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-pink ">

          <div class="stepper-container">

            <div class="stepper">

              <div class="circulo-stepper-active">1</div>
              <div class="linea-stepper"></div>
              <div class="circulo-stepper">2</div>
  
            </div>
  
            <div class="texto-stepper">
  
              <div class="titulo-floating-onboarding">ingresa la clave SII<br>de tu empresa</div>
  
              <div class="subtitulo-floating-onboarding">
                Al <span (click)="open_modal_info(3)">vincularnos con el SII</span>, podremos evaluar<br>tu empresa para ofrecerte opciones de<br>financiamiento personalizadas.
              </div>
    
              <div class="contenedor-imagen">
                  <img src="../../assets/iconografia/candado-sii.svg">
              </div>
  
            </div>

          </div>



        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-white">

          @if(loading == false){

            <div class="stepper-container2">
              <mat-card class="card-sii">
                <mat-card-content>
                  <div class="titulo-onboarding">clave SII de tu empresa</div>
  
                  <div class="formfield" style="position: relative;">
                    <span [mdePopoverTriggerFor]="appMontoPendiente" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger" class="icon-shield"><i class="fa-light fa-shield-keyhole"></i></span>
                    <form  [formGroup]="valForm">
                      <mat-form-field>
                        <input matInput 
                        placeholder="Clave SII empresa" [type]="hide ? 'password' : 'text'"
                        (keyup)="validate_key($event)"
                        formControlName="clave_sii"
                        >
                        <mat-error *ngIf="valForm.controls['clave_sii'].hasError('incorrect')">
                          Clave SII inválida.
                        </mat-error>
                        <mat-error *ngIf="valForm.controls['clave_sii'].hasError('required')">
                            Clave SII requerida.
                        </mat-error>
                        <mat-hint>Ingresa la clave asociada a tu RUT empresa</mat-hint>
                        <span matSuffix class="material-icons" style="margin-right: 15px;" (click)="hide = !hide">
                          @if(!hide){
                            <span><i class="fa-light fa-eye"></i></span>
                          }@else{
                            <span><i class="fa-light fa-eye-slash"></i></span>
                          }
    
                        </span>
                      </mat-form-field>
                    </form>
                    
  
                    <mde-popover #appMontoPendiente="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                      <mat-card class="popover-card">
                        <mat-label>Tus datos estan protegidos por nuestra politica de<br>privacidad</mat-label>
                        <div class="politica" (click)="navegar_a('https://simplilatam.com/politica-de-privacidad/')"><span>Ver politica de privacidad</span><i class="fa-solid fa-chevron-right"></i></div>
                      </mat-card>
                    </mde-popover>
  
                  </div>
  
                  <div class="subtitulo-container">
                    <div class="subtitulo-onboarding">
                      Descubre <span (click)="open_modal_info(4)">cómo evitar errores</span> al ingresar tu clave.
                    </div>
                  </div>
                  
                  <div class="caja-botones">
  
                    @if(success_sii == false){
                      <button [disabled]="disable_btn" (click)="verificar_guardar_clave_sii(true)" mat-stroked-button class="primary-button">
                        <span *ngIf="loading_sii == false">Validar</span>
                        <span *ngIf="loading_sii == true"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
                      </button>
                    }@else{
                      <button mat-stroked-button class="primary-button" (click)="pasar_al_paso_2()">Continuar</button>
                    }
                    <button mat-stroked-button class="secondary-button" (click)="step = 1">Volver</button>
                  </div>
  
                </mat-card-content>
              </mat-card>
  
              <div class="contact-container">
                <div> Si tienes preguntas, contacta a un ejecutivo.</div>
                <div class="icons">
                  <span (click)="agendar()"><i class="fa-light fa-video"></i>Videollamada</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span><i class="fa-sharp fa-solid fa-headset"></i>[+5693333333]</span>
                </div>
              </div>
  
            </div>

          }@else{

            <div class="stepper-container2">
              <mat-card class="card-sii no-card">
                <mat-card-content>

                  <loading-interno></loading-interno>
  
                </mat-card-content>
              </mat-card>
  

  
            </div>

          }


        </div>

      </div>

    </div>
  }


  <!--Facturador-->
  @if(step == 3){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

      <div class="row">

        <div [ngClass]="{'col-blue':facturador_seleccionado == 1, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-pink':true}">

          <div class="stepper-container">

            <div class="stepper">

              <div class="circulo-stepper">1</div>
              <div class="linea-stepper"></div>
              <div class="circulo-stepper-active">2</div>
  
            </div>
  
            <div class="texto-stepper">
  
              <div class="titulo-floating-onboarding">
                @if(facturador_seleccionado != 1){
                  selecciona el sistema de<br>facturación que utilizas
                }@else{
                  ventajas de utilizar Mipyme<br>(SII)
                }

              </div>
  
              <div class="subtitulo-floating-onboarding">
                @if(facturador_seleccionado != 1){
                  Si emites o cedes facturas por el <span (click)="open_modal_info(5)">sistema de<br>facturación</span> del SII, debes selecciona la<br>opción <strong>Mipyme</strong>.
                }@else{
                  Con Mipyme podrás acceder a la opción de<br>ceder las facturas por esta plataforma, cada vez<br> que realices una <span (click)="open_modal_info(6)">operación de factoring</span>.
                }
               
              </div>
    
              <div class="contenedor-imagen">
                @if(facturador_seleccionado != 1){
                  <img src="../../assets/iconografia/candado-sii.svg">
                }@else{
                  <img src="../../assets/iconografia/facturador.svg">
                }
                  
              </div>
  
            </div>

          </div>



        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-white">

          @if(loading == false){

            <div class="stepper-container2">
              <mat-card class="card-sii">
                <mat-card-content>
                  <div class="titulo-onboarding">sistema de facturación</div>
  
                  <div class="formfield" style="position: relative;">

                    <form  [formGroup]="valFormFacturador">

                      <mat-form-field>
                        <mat-select formControlName="facturador" (selectionChange)="selecionar_facturador($event)" placeholder="Sistema o software de facturación">
                          <mat-option *ngFor="let i of facturadores" [value]="i.id">{{i.name}}</mat-option>
                        </mat-select>
                        <mat-hint>Selecciona el sistema que usas para ceder</mat-hint>
                      </mat-form-field>


                      <mat-form-field *ngIf="facturador_seleccionado == 7">
                        <input matInput 
                        placeholder="Nombre facturador"
                        formControlName="otro">
                        <mat-error *ngIf="valFormFacturador.controls['otro'].hasError('required')">
                          Este campo es requerido.
                        </mat-error>
                        <mat-hint>Indica el nombre del sistema que usas para ceder</mat-hint>
                      </mat-form-field>

                    </form>
  

                  </div>
  
                  <div class="subtitulo-container">
                    <div class="subtitulo-onboarding">
                      <span (click)="open_modal_info(5)">¿Qué es un sistema de facturación?</span>.
                    </div>
                  </div>
                  
                  <div class="caja-botones">
  
                    @if(this.facturador_seleccionado == 7){
                      <button [disabled]="this.valFormFacturador.controls['otro'].value == ''" mat-stroked-button class="primary-button" (click)="put_facturador()">Finalizar</button>
                    }@else{
                      <button [disabled]="this.valFormFacturador.controls['facturador'].value == ''" mat-stroked-button class="primary-button" (click)="put_facturador()">Finalizar</button>
                    }
                    <button mat-stroked-button class="secondary-button" (click)="step = 2">Volver</button>

                  </div>
  
                </mat-card-content>
              </mat-card>
  
              <div class="contact-container">
                <div> Si tienes preguntas, contacta a un ejecutivo.</div>
                <div class="icons">
                  <span (click)="agendar()"><i class="fa-light fa-video"></i>Videollamada</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span><i class="fa-sharp fa-solid fa-headset"></i>[+5693333333]</span>
                </div>
              </div>
  
            </div>

          }@else{

            <div class="stepper-container2">
              <mat-card class="card-sii no-card">
                <mat-card-content>

                  <loading-interno></loading-interno>
  
                </mat-card-content>
              </mat-card>
  

  
            </div>

          }


        </div>

      </div>

    </div>
  }

  <!--Finalizar-->
  @if(step == 4){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn"  style="position: relative;">


      <mat-card class="centerabsolute">
        <mat-card-content>

          <div class="container-final">
            <div class="container-titulos">
              <div class="label-negro">¡Felicidades {{business_name}}!</div>
              <div class="label-azul">Ya puedes financiarte con nosotros</div>
              <div class="label-container">Revisa tu simulación automática de financiamiento y<br>haz tu primera <span (click)="open_modal_info(6)">operación de factoring</span> en Simpli</div>

              <div class="caja-botones">
                  <button mat-stroked-button class="primary-button" (click)="open_modal_info(12)">Ver simulación</button>
              </div>
            </div>
            <div class="imagen-final">
              <img src="../../assets/iconografia/basurero.svg">
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-pink">




        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-white">




        </div>

      </div>

    </div>
  }

  <!--Finalizar pero cliente mal evaluado-->
  @if(step == 5){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn"  style="position: relative;">


      <mat-card class="centerabsolute">
        <mat-card-content>

          <div class="container-final">
            <div class="container-titulos">
              <div class="label-negro">¡Atención {{business_name}}!</div>
              <div class="label-azul">Tu empresa requiere una evaluación<br>personalizada</div>
              <div class="label-container">Necesitamos un poco más de tiempo para revisar los<br>datos de tu empresa. <strong>Un ejecutivo te contactará</strong> para<br>continuar el proceso.</div>

              <div class="contact-container">
                <div> Si lo prefieres, puedes contactar directamente a un ejecutivo.</div>
                <div class="icons">
                  <span (click)="agendar()"><i class="fa-light fa-video"></i>Videollamada</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span><i class="fa-sharp fa-solid fa-headset"></i>[+5693333333]</span>
                </div>
              </div>

            </div>
            <div class="imagen-final">
              <img src="../../assets/iconografia/Iconografia - Development.png">
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-pink">




        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-white">




        </div>

      </div>

    </div>
  }

  <!--omitio el onboarding-->
  @if(step == 0){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


      <mat-card class="centerabsolute">
        <mat-card-content>

          <div class="container-final">
            <div class="container-titulos">
              <div class="label-negro">¡Te damos la bienvenida a Simpli!</div>
              <div class="label-azul">Descubre si tu empresa califica para un financiamiento</div>
              <div class="label-container">Solicita tu evaluación y te responderemos en breve</div>

              <div class="caja-botones">
                  <button mat-stroked-button class="primary-button" (click)="step = 1">Solicitar evaluación</button>
                  <button mat-stroked-button class="secondary-button" (click)="omitir()">Omitir por ahora</button>
              </div>
            </div>
            <div class="imagen-final">
              <img src="../../assets/iconografia/bienvenido.svg">
            </div>
          </div>

        </mat-card-content>
      </mat-card>



    </div>
  }

  <!--Esperando la sim automatica-->
  @if(step == 6){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


      <mat-card class="centerabsolute" style="max-width: 700px;">
        <div class="titulo-card">Te damos la bienvenida a Simpli</div>
        <mat-card-content>

          <div class="container-final" style="flex-direction: column;">

            <div class="imagen-final">
              <img src="../../assets/iconografia/Iconografia - Agency.png" style="height: 200px !important;">
            </div>

            <div class="container-titulos" style="margin-top: 20px;margin-bottom: 10px;">
              <div class="label-container">Estamos procensando la información.<br>Esto no tomará mucho tiempo.</div>
            </div>

            <div class="spinner-circle">
              <span><i class="fa-solid fa-circle-notch fa-spin"></i></span>
            </div>

          </div>

        </mat-card-content>
      </mat-card>



    </div>
  }
  

  
</div>

<div style="position: absolute;top:0">
  <input placeholder="ir al paso" style="    border-radius: 4px;
  border: solid 1px #f0f0f0;
  background: none;" (change)="ir_al_paso($event)">
</div>