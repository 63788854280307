
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


declare var $: any;


@Component({
  selector: 'app-rechazar-condiciones',
  templateUrl: './rechazar-condiciones.html',
  styleUrls: ['./rechazar-condiciones.less']
})
export class RechazarCondicionesDialog implements OnInit {



  confirmar:boolean=false;
  parent:any;

  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<RechazarCondicionesDialog>) {



   }



  ngOnInit() {


  }

  rechazar(){
    this.confirmar = true;
    this.parent.rechazar_condiciones();
  }


}