import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;


declare var $: any;


export interface Banco {
    id: string;
    name: string;
  }

  export interface Cuenta {
    id: string;
    name: string;
  }

@Component({
    selector: 'create-cuenta-bancaria-dos',
    template: `


        <div mat-dialog-title>Nueva Cuenta Bancaria</div>
        <div mat-dialog-content>
        <form  [formGroup]="valForm">
        <div style="min-height: 35vh;">
            <mat-form-field class="input-form-out">
                <input  type="text" placeholder="Banco" panelClass="elleryaqui"   required formControlName="bank" matInput   [matAutocomplete]= "auto" />
                <mat-autocomplete #auto = "matAutocomplete" (optionSelected)="filtrar_banco($event.option)">
                <mat-option data-id="{{option.id}}" *ngFor="let option of filteredOptions | async" [value]="option.name">
                {{option.name}}
                </mat-option>
            </mat-autocomplete>
                <mat-hint>Indique el banco</mat-hint>
                <mat-error *ngIf="valForm.controls['bank'].hasError('required') && (valForm.controls['bank'].dirty || valForm.controls['bank'].touched)">
                        El Banco es <strong>requerido</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="input-form-out">
                <mat-select placeholder="Tipos de Cuenta" formControlName="type"  required>
                    <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.id">
                    {{cuenta.name}}
                    </mat-option>
                </mat-select>
                <mat-hint>Indique el tipo de cuenta</mat-hint>
                <mat-error *ngIf="valForm.controls['type'].hasError('required') && (valForm.controls['type'].dirty || valForm.controls['type'].touched)">
                    El tipo de cuenta es <strong>requerido</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="input-form-out" style="margin-top: 20px;">
                <input matInput placeholder="Nombre y Apellido" formControlName="name"  required>
                <mat-hint>Indique el nombre de la cuenta</mat-hint>
                <mat-error *ngIf="valForm.controls['name'].hasError('required') && (valForm.controls['name'].dirty || valForm.controls['name'].touched)">
                    El nombre es <strong>requerido</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="input-form-out" style="margin-top: 20px;">
                <input matInput placeholder="Número de cuenta" formControlName="number" type="number"  required>
                <mat-hint>Indique el número de cuenta</mat-hint>
                <mat-error *ngIf="valForm.controls['number'].hasError('required') && (valForm.controls['number'].dirty || valForm.controls['number'].touched)">
                    El número de cuenta es <strong>requerido</strong>
                </mat-error>
                <mat-error *ngIf="valForm.get('number').hasError('pattern') && (valForm.get('number').dirty || valForm.get('number').touched)">
                    El número de cuenta debe ser numerico y contener de 6 a 12 digitos
                </mat-error>
            </mat-form-field>

            <mat-slide-toggle  formControlName="tercero" style="margin-top: 20px; display: flex; font-size: 12px;"> Cuenta de terceros</mat-slide-toggle>


            <mat-form-field class="input-form-out" style="margin-top: 20px;">
                <input matInput placeholder="RUT" formControlName="rut" [(ngModel)]="run" (keyup)="change_run()" required>
                <mat-hint>Indique el RUT del número de cuenta</mat-hint>
                <mat-error *ngIf="valForm.controls['rut'].hasError('required') && (valForm.controls['rut'].dirty || valForm.controls['rut'].touched)">
                    El RUT es <strong>requerido</strong>
                </mat-error>
                <mat-error *ngIf="valForm.get('rut').hasError('pattern') && (valForm.get('rut').dirty || valForm.get('rut').touched)">
                    El RUT debe ser en formato DDDDDDD-D Ejm: 17983599-8
                </mat-error>
            </mat-form-field>

            <mat-form-field class="input-form-out" style="margin-top: 20px;">
                <input matInput placeholder="Email" formControlName="email">
                <mat-hint>Indique el correo electronico de la cuenta</mat-hint>
            </mat-form-field>
        </div>

    </form>
    </div>
    <div mat-dialog-actions>
        <button type="button" mat-stroked-button mat-dialog-close class="stroked-btn">Cerrar</button>
        <button [disabled]="disabledButton" type="button" (click)="submitForm($event, valForm.value)" mat-stroked-button  color="primary" class="stroked-btn">Guardar</button>
        <mat-spinner class="spinner-arrayan-general" *ngIf="showSpinner" diameter="40" style="margin-left:10px"></mat-spinner>
    </div>



    `,
})
export class AddCta implements OnInit, AfterViewInit {

    run:string="";
    full_name:string="";
    dialog_open:boolean=false;
    is_compras:boolean=false;


    rut_acreedor:any;
    nombre_acreedor:any;

    parent:any;

    @Input() id_company:any;
    @Output() funciones = new EventEmitter<number>();

    disabledButton:boolean= false;
    showSpinner:boolean = false;

    bancos: Banco[] = [];

    cuentas: Cuenta[] = [];

    valForm: FormGroup;

    is_tercero: boolean= false;

    //autocomplete select
    myControl = new FormControl();
    filteredOptions!: Observable<Banco[]>;
    bank_selected: any;

    constructor( fb: FormBuilder, public snackBar: MatSnackBar, public settings: SettingsService, public dialogRef:MatDialogRef<AddCta>) {
        let rutValid = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{7,10}[-][0-9K]$')]));
        // let accMail = new FormControl (null, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}')]));
        let accNumber = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{4,12}$')]));

        this.valForm = fb.group({
            'bank': ['', Validators.compose([Validators.required])],
            'type': ['', Validators.compose([Validators.required])],
            'rut': rutValid,
            'number': accNumber,
            'name': ['', Validators.compose([Validators.required])],
            'tercero': new FormControl(''),
            'email': new FormControl(''),
          });
          this.valForm.controls["tercero"].setValue(false);
    }


    async submitForm($ev:any, value: any) {

        this.disabledButton = true;
        this.showSpinner = true;

        $ev.preventDefault();

        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid){
            try {
                value["bank"] = this.bank_selected;
                const bd: any = await this.settings.get_query(2, 'account-company/' + this.id_company + "/", value);
                var body_data: any =bd._body;
                switch (bd.status) {
                  case 200:

                  console.log("SE crea el banco", body_data)

                  this.disabledButton = false;
                  this.showSpinner = false;

                  let sb = this.snackBar.open("¡Cuenta agregada!", "Aceptar", {
                      duration: 20000,
                      panelClass: "simpli-snack"
                  });

                  //this.info.reloadAccounts();

                  if(this.dialog_open == true){
                      this.parent.facturasComprasBancos()
                      this.dialogRef.close(body_data)
                  }else{
                      if (this.is_compras) {
                          this.dialogRef.close(body_data)
                      } else {
                          this.funciones.emit(1)
                      }
                  }


                    break;
                  default:

                    break;
                }
              } catch (bd: any) {

              }
        }


      }

    filtrar_banco($e:any){
        this.bank_selected = $e.id
        console.log("algo del filtar",$e.id);
        let cuenta = {id: "2", name: "Cuenta RUT"}
        if (this.bank_selected != "26"){
            if (this.cuentas.indexOf(cuenta) != -1) {
                this.cuentas.splice(this.cuentas.indexOf(cuenta), 1);
            }
        } else {
            this.cuentas.push(cuenta)
        }
    }

    ngOnInit()
    {

        this.get_personal_data();
        this.load_bancos();
        this.load_cuentas();

        this.valForm.controls['rut'].setValue(this.rut_acreedor);
        this.valForm.controls['name'].setValue(this.nombre_acreedor);
    }

    ngAfterViewInit() {

    }

    async load_bancos() {
        try {
          const bd: any = await this.settings.get_query(1, 'bank/');
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:


            console.log("Bancos");
            console.log(body_data);

            for (let i = 0; i < body_data.banks.length; i++)
            {
                this.bancos.push(body_data.banks[i])
            }

            this.filteredOptions = this.valForm.controls["bank"].valueChanges
            .pipe(
                startWith(''),
                map((value:any) => typeof value === 'string' ? value : value.name),
                map((name:any) =>  name ? this._filter(name) : this.bancos.slice())
            );

              break;
            default:

              break;
          }
        } catch (bd: any) {

        }
      }

    // displayFn(bancos) {
    //     return bancos && bancos.name ? bancos.name : '';
    //   }

      private _filter(name: string): Banco[] {
        const filterValue = name.toLowerCase();

        return this.bancos.filter(option => option.name.toLowerCase().includes(filterValue) === true);
      }


    async load_cuentas() {
        try {
          const bd: any = await this.settings.get_query(1, 'account-type/');
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:


            for (let i = 0; i < body_data.accounts.length; i++)
            {
                if (i != 1) {
                    this.cuentas.push(body_data.accounts[i]);
                }
            }


              break;
            default:

              break;
          }
        } catch (bd: any) {

        }
      }

    //cambiar por la información de la empresa

    async get_personal_data() {
        try {
          const bd: any = await this.settings.get_query(1, 'company/' + this.id_company + "/");
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:


            this.full_name = body_data.company.business_name;
            this.run = this.rut_acreedor;

              break;
            default:

              break;
          }
        } catch (bd: any) {

        }
      }

    change_run()
    {
      let st = this.run.replace("-", "");
      let arr = st.split("");
      let st_out = "";

      for (let i=0; i < arr.length; i++)
      {
        st_out = st_out + arr[i];

        if (i == arr.length - 2)
        {
          st_out = st_out + "-"
        }
      }

      this.run=st_out;
    }
}
