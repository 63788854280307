import { Component, Input } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-drag-drop-component',
  templateUrl: './drag-drop-component.component.html',
  styleUrls: ['./drag-drop-component.component.less']
})
export class DragDropComponentComponent   {

  
  disabled: boolean = false;
  process: boolean = false;
  hay_facturas_con_error: boolean = false;
  todas_las_facturas_ok: boolean = false;
  mensaje_error: string = "";  
  disabled_button: boolean = true;
  label_boton_financiar: string = "Continuar";
  disabled_subir_file: boolean = false;
  show_descargar_plantilla: boolean = true;

  @Input() company_id!: any;

  colaboradores = [];

  constructor(private settings: SettingsService) { }

  async onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map((key: any) => pFileList[key]);

    let formData: FormData = new FormData();
    let file_input = pFileList;

    for (let i = 0; i < file_input.length; i++) {
      let name = file_input[i].name;
      formData.append("cv_" + i, file_input[i], name);
    }

    this.disabled = true;
    this.hay_facturas_con_error = false;
    this.todas_las_facturas_ok = false;
    this.show_descargar_plantilla = false;
    this.colaboradores = [];

    try {
      let resultado: any = await this.settings.get_query(
        2,
        `pagoRemuneracionesManuales/${this.company_id}/`,
        formData
      );
      let body_data = resultado._body;
      if (resultado) {
        this.reset_file();
        this.disabled = false;
        if (body_data.arrayFront.length == 0) {
          this.hay_facturas_con_error = true;
          this.mensaje_error =
            "No existen colaboradores";
          this.disabled_button = true;
        } else {
          // si hay al menos uno
          this.reset_file();
          this.disabled = false;
          this.todas_las_facturas_ok = true;
          this.disabled_button = false;

          this.colaboradores = body_data.arrayFront;
        }
      }
    } catch {
      this.reset_file();
      this.disabled = false;
      this.hay_facturas_con_error = true;
      this.mensaje_error =
        "Error en los datos";
      this.disabled_button = true;
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDropSuccess(event: any) {
    event.preventDefault();

    this.uploadFileDrag(event.dataTransfer.files);
  }

  onChange(event: any) {
    this.uploadFileDrag(event.target.files);
  }

  private uploadFileDrag(files: Array<any>) {
    if (files.length) {
      this.process = true;
      this.onFileChange(files);
      this.process = false;
    }
  }

  financiar() {
    console.log('facturas remuneraciones');
  }

  reset_file() {
    $("#file_but").val("");
  }

  public files: Array<any> = [];


  subir() {
    $("#file_but").trigger("click");
  }

}
