import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SettingsService } from "src/app/settings/settings.service";


declare var $: any;

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.html',
  styleUrls: ['./info-modal.less']
})
export class InfoModalOnboarding implements OnInit {

  @Input() step:any;

  constructor(private router: Router, public settings: SettingsService, public dialog: MatDialog, private metaTagService: Meta, public route:Router ) { 


    }

  ngOnInit() {



  }


}