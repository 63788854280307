<div class="row row_main" style="padding-bottom: 70%;">


<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <h1 class="titulo-simpli">Liquidaciones</h1>
    <small class="subtitulo-simpli">Todo el historial de liquidaciones encuéntralo aquí.</small>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <mat-form-field>
                <input #inputFilter matInput (keyup)="applyFilter($event.target.value)" placeholder="Numero de operación">
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <mat-form-field>
                <input matInput #inputDate #fechas [matDatepicker]="picker" placeholder="Fecha de operacion" (dateChange)="changeFecha(fechas.value)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>


    <div *ngIf="!liqui_spinner">


        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="!this.has_liquidacion">
    
              <div class="empty-msg" style="height: 300px;">
    
                
                <div style="padding-top: 50px;text-align: center;">
                  <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                </div>
        
                <div style="padding-top: 50px;text-align: center;">
                  <mat-label class="label-bienvenida">
                    <div class="label-empty">
                      
                        Todavía no hay historial de liquidaciones. <br><br>Simula una operación para simplificar tus finanzas. 
                    </div>
    

    
                  </mat-label>
                </div>
    
        
              </div>
        
        
        </div>



        <div class="row" *ngIf="this.has_liquidacion">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div *ngFor="let i of this.ELEMENT_DATA; let index=index" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="responsive-container" style="width: 100%;">
                        <div  class="box-prospecto-responsive" style="background: white !important;position: relative;border-radius: 4px; " >

                            <div class="box-prospecto-responsive-head" style="cursor:pointer;margin-top: 12px;" (click)="open_more_box(i.id, index)">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-col top-col" style="text-align:center">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="font-size:16px;">
                                                Descargar
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="margin-top:5px;">
                                                    <span>
                                                        <!-- <mat-spinner *ngIf="show_spinner"  class="spinner-simli animate__animated animate__fadeIn slow spinner" diameter="30"></mat-spinner> -->
                                            
                                                    </span>
                                                    <button (click)="print(i.id)" matTooltip="Descargar PDF" mat-mini-fab class="primary-button">
                                                        <!-- <i class="material-icons" style="font-size: 14px;color: #fff;cursor:pointer;"> attach_file </i> -->
                                                        <i class="fas fa-print" style="font-size: 16px;color: var(--simpli-secondary);cursor:pointer;"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 top-col bottom-col" style="text-align:center">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="font-weight:400; color:858489;">
                                                    N° Operación
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="font-weight:600">
                                                {{i.numero_operacion}}
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-m-3 col-xs-6 top-col bottom-col" style="text-align:center">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <span class="span-region">Monto Operación</span>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="font-weight:600">
                                                <span>{{currency(i.monto_operacion)}}</span>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 bottom-col" style="text-align:center">
                                            <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <span class="span-region">Fecha Operación</span>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="font-weight:600">
                                                <span>{{i.fecha_operacion}}</span>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6  bottom-col" style="text-align:center">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <span class="span-region">Tipo Documento</span>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="font-weight:600">
                                                <span>
                                                    <span *ngIf="i.tipo_documento=='FA' || i.tipo_documento=='FE'">
                                                    Factura Electronica
                                                    </span>
                                                    <span *ngIf="i.tipo_documento=='CH'">
                                                    Cheque
                                                    </span>
                                                    <span *ngIf="i.tipo_documento=='CF'">
                                                    Confirming
                                                    </span>
                                                    
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    
                                
                                </div>
        
 
                            </div>
                            <div class="menu-box-container" style="margin-top: -22px;">           
                                <div (click)="open_more_box(i.id, index)" class="button-box hid-arrow ">
                                    <i id="sel_arrow_{{i.id}}" class="material-icons boton_arrow_box {{i.class_arrow}}" style="color: black; cursor:pointer;">
                                    keyboard_arrow_down
                                    </i>
                                </div>
                            </div>
                    
                            <div id="body_box_{{i.id}}" class="box-prospecto-responsive-body {{i.class}}" (click)="$event.stopPropagation" style="background: white;width: 100% !important;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 print-button print1" style="margin-top:20px;margin-right: 20px;">

                                    <div class="row">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div class="row">
                    
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <span>
                                                        <mat-spinner *ngIf="show_spinner" class="spinner-simpli animate__animated animate__fadeIn slow spinner" diameter="30"></mat-spinner>
                                                    </span>
                                                    <button (click)="print(i.id)" matTooltip="Descargar PDF" mat-fab style="background:rgb(211 116 239);color:white;">
                                                        <!-- <i class="material-icons" style="font-size: 14px;color: #fff;cursor:pointer;"> attach_file </i> -->
                                                        <i class="fas fa-print" style="font-size: 16px;color: #fff;cursor:pointer;"></i>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 liquidacion_section">

                                <mat-tab-group  class="liquidacion-body" style="width:100%" mat-align-tabs="start">
                                    <mat-tab label="Resumen Liquidación" (click)="$event.stopPropagation">
                                        <!-- <div class="row liq_width"> -->
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top:0px;">
                                                <div class="liquidacion-body">
                                                <div id="print-page-{{i.numero_operacion}}" class="print-page">
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 " style="display:flex; justify-content:center;">
                                                    <div class="row" style="width:100%;margin-top: 40px;">
                                                        
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 arra-logo">
                                                            <img style="height: 75px;" src="../../../assets/simpli/simplilogo2.svg" alt="logo-arrayan">
                                                            
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top:10px;">
                                            
                                                                <div class="row">

                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bordes-caja cambio-caja">
                                                                        <mat-card class="card-home bordes-caja">
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                                                                <mat-card-header class="mat-card-header-tubo">
                                                                                <mat-card-title>
                                                                                    <h1  class="titulo2" style="color:#1b0943;"> Liquidación de Operación &nbsp; N°{{i.numero_operacion}}</h1> 
                                                                                </mat-card-title>
       
                                                                                </mat-card-header>
                                                                                <hr class="linea-baja" />
                                                        
                                                                                <mat-card-content>
                                                                                <div class="row">
                                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-left:31px;">
                                                                                        <div class="row" style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Cliente</span>
                                                                                                </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado"> {{i.company.business_name}}</span>
                                                                                                </div>
                                                                                        </div>
        
                                                                                        <div class="row" style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                                                <span class="propiedad">RUT</span>
                                                                                                </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  resultados" >
                                                                                                <span class="resultado">{{i.company.rut}}</span>
                                                                                                </div>
                                                                                        </div>
        
                                                                                        <div class="row" style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">N° Operación</span>
                                                                                                </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">{{i.numero_operacion}}</span>
                                                                                                </div>
                                                                                        </div>
        
                                                                                        <div class="row" style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Fecha de otorgamiento</span>
                                                                                                </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">{{i.fecha_otorgamiento}}</span>
                                                                                                </div>
                                                                                        </div>
        
                                                                                        <div class="row" style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Tipo de documento</span>
                                                                                                </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">{{i.fecha_otorgamiento}}</span>
                                                                                                </div>
                                                                                        </div>
        
                                                                                        <div class="row" style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad"> Cantidad de documentos</span>
                                                                                                </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6resultados">
                                                                                                <span class="resultado"> {{i.cantidad_documentos}}</span>
                                                                                                </div>
                                                                                        </div>
        
                                                                                    </div>
                                                                                </div>
                                                                                </mat-card-content>
                                                                            </div>
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                                                                    <mat-card-header class="mat-card-header-tubo">
                                                                                        <mat-card-title > 
                                                                                            <h1  class="titulo2" style="color:#1b0943;"> Detalles de la operación </h1> 
                                                                                        </mat-card-title>
                                                                                    </mat-card-header>
                                                                                    
                                                                                    <hr class="linea-baja" />
                                                                                    <mat-card-content>
                                                                                    
                                                                                    <div class="row">
                                                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="padding-left:31px;">
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Monto operación</span>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">${{formatMoney(i.monto_operacion)}}</span>
                                                                                            </div>
                                                                                        </div>
                
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Monto no financiado*</span>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">${{formatMoney(i.monto_operacion-i.monto_anticipado)}}</span>
                                                                                            </div>
                                                                                        </div>
                
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Monto anticipado</span>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado"> ${{formatMoney(i.monto_anticipado)}}</span>
                                                                                            </div>
                                                                                        </div>
                
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Porcentaje de anticipo</span>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">{{i.porcentaje_anticipacion}}%</span>
                                                                                            </div>
                                                                                        </div>
                
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                                                <span class="propiedad">Tasa operación</span>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados">
                                                                                                <span class="resultado">{{i.tasa}}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </mat-card-content>
                                                                            </div>

                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                <mat-card-header class="mat-card-header-tubo">
                                                                                    <mat-card-title>
                                                                                        <h1  class="titulo2" style="color:#1b0943;"> Descuentos de la operación </h1> 
                                                                                    </mat-card-title>
                                                                                </mat-card-header>
                                                                                <hr class="linea-baja" />
                                                            
                                                                                <mat-card-content>
                                                                                    <div class="row">
                                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="padding-left:31px;">
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Diferencia de Precio</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados "><span class="resultado">${{formatMoney(i.diferencia_precio)}}</span></div>
                                                                                        </div>
            
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Gastos</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.gastos)}}</span></div>
                                                                                        </div>
            
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Iva gastos</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.iva_gastos)}}</span></div>
                                                                                        </div>
            
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Aplicaciones cuentas por cobrar</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.aplica_cxc)}}</span></div>
                                                                                        </div>
            
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Aplicaciones documentos</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.aplica_documentos)}}</span></div>
                                                                                        </div>
            
                                                                                        
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Monto adelantado</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.monto_adelantado)}}</span></div>
                                                                                        </div>
            
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Comisión fija</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.comision)}}</span></div>
                                                                                        </div>
            
                                                                                        <div class="row"  style="padding-bottom: 0px;">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><span class="propiedad">Iva comisió</span></div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><span class="resultado">${{formatMoney(i.iva_comision)}}</span></div>
                                                                                        </div>
            
                                                                                    </div>
                                                                                    </div>
                                                                                </mat-card-content>
                                                                            </div>


                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="padding-left:31px;">

                                                                                <hr style="width: 99%;
                                                                                margin-left: -1px;
                                                                                border: 1px solid #0000001f;"/>
                                                                                <div class="row">
                                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="padding-left:0px;">
                                                                                    <div class="row"  style="padding-bottom: 0px;">
                                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"> <h1  class="titulo3" style="margin-left: 6px;font-size: 19px;color:#1b0943;"> Monto a girar</h1> </div>
                                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 resultados"><h1 style="margin-top: 3px;
                                                                                            font-size: 26px;font-family: 'DM Sans' !important;"> ${{formatMoney(i.total_girar)}}</h1></div>
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
        
                                                                            </div>
                                                                        </mat-card>

                                                                        <div class="row liqui-row">
                                                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                        
                                                                                <div class="liqui-bottom-text" style="margin-top: 20px;">
                                                                                    *El monto no financiado, corresponde a los excedentes que se generán una vez pagada las facturas por parte de los deudores
                                                                                    Nota: <br>
                                                                                    1) Este es un documento de respaldo para representar un gasto contable. <br>
                                                                                    2) Esta liquidación no reemplaza la factura. <br>
                                                                                    3) Este resumen de liquidación fue descargado el {{this.fecha_hoy}}
                                                                                </div>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>


                                                        </div>
                                                    </div>
                                                    
                                                </div>
            
                                                </div>
                                                </div>
                                            </div>

                                    </mat-tab>
                                    <mat-tab label="Detalle operación" (click)="$event.stopPropagation" style="height:60px !important; overflow-x:hidden;">
                                        <div class="row" style="margin-top:50px; width:99%;">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                                                <div class="row">
                                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                                                        <h1  class="titulo2" style="margin-left:18px;font-size: 19px;color:#1b0943;">Detalle de Otorgamiento de Operación  <strong class="noperacion">N°{{i.numero_operacion}}</strong></h1> 
                                                                    
                                                                    </div>
                                                        
                                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 table_operations">
                                                                <div *ngIf="i.operaciones!=null || i.operaciones!=undefined">
                                     


                                                                    <div class="sinRegistros" style="text-align: center;"  *ngIf="i.operaciones.operaciones.length==0">
                                                                        <small class="small-sinregistros" style="margin-top: 3px!important;width: 95%;">
                                                                        <div style="text-align: center;"><span style="font-size: 30px;" class="material-icons">info</span></div>
                                                                                No existen detalle de otorgamiento de operación
                                                                        </small>
                                                                    </div>
                                                                    <table *ngIf="i.operaciones.operaciones.length>0" border="0" width="100%" cellspacing="0" class="operaciones" style="width:99%;font-size: 14px;  border-spacing:0px; font-family:'arial'; margin-left:1%; margin-top:10px " >
                        
                                                                        <tbody>
                                                                            <tr style="border:1px solid #000; background-color:#fff; color:#1b0943; border-bottom:1px solid #707f6f">
                                                                            <td align="center" style="border:0px; width:140px; min-width:140px; border-bottom:1px solid #707f6f; padding-bottom:5px;color:#1b0943;">
                                                                                Deudor
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:80px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Rut
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:50px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                F. Emisión
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:50px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                F. Vcto.
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:50px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Dias
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:50px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                N° doc.
                                                                            </td> 
                                                                            <td align="center" style="border:0px; width:60px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Mto. doc.
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:100px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Mto. no finan.
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:80px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Mto. finan*
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:60px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Dif.precio
                                                                            </td>
                                                                            <td align="center" style="border:0px; width:100px; border-bottom:1px solid #707f6f; padding-bottom:5px; text-align:center;color:#1b0943;">
                                                                                Sub. total doc.
                                                                            </td>
                                                                        </tr >
                                                                            <tr *ngFor="let j of i.operaciones.operaciones" style="color:#141414" class="operation_row">
                                                                                <td align="center" style="text-align:left;">
                                                                                    {{j.nombre_deudor }}
                                                                                </td>
                                                                                <td align="left" style="text-align:center">
                                                                                    {{j.rut_deudor}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    {{j.fecha_emision}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    {{j.fecha_vencimiento}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    {{j.dias_vencimiento}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    {{j.documento}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    ${{j.monto_documento}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    ${{j.monto_no_anticipado}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    ${{j.monto_anticipado}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    ${{j.diferencia_precio}}
                                                                                </td>
                                                                                <td align="center" style="text-align:center;">
                                                                                    ${{j.subtotal}}
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row liqui-row">
                                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <hr style="border:1px solid #A5A49D;">
                                                            <div class="liqui-bottom-text">
                                                                *El monto no financiado, corresponde a los excedentes que se generán una vez pagada las facturas por parte de los deudores
                                                                Nota: <br>
                                                                1) Este es un documento de respaldo para representar un gasto contable. <br>
                                                                2) Esta liquidación no reemplaza la factura. <br>
                                                                3) Este resumen de liquidación fue descargado el {{this.fecha_hoy}}
                                                            </div>
                                                        </div>
                                                </div> 
                    
                                        
                                            </div>
                                        </div>

                                    </mat-tab>
                                </mat-tab-group>
                                
                            
                                </div>
                            </div>
                    </div>
                </div> 
            </div>
    
            <mat-paginator style="background: none;" [length]="length"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions"
                        (page)="pageCustom($event)">
                        </mat-paginator>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="liqui_spinner">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

            <div class="container-loading">
        
                
                <div class="img-loading">
                <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                </div>
        
                <div style="margin-top: 20px;">
                <mat-label class="label-loading">
                    <div>La información está siendo procesada.</div>
                    <div>Esto no tomará mucho tiempo.</div>
                </mat-label>
                </div>
        
            </div>
        
        
        </div>
    </div>


    

</div>

</div>
      


