import { Component,ViewChild, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { SimulationOp } from '../operaciones-proveedores/simulation/simulation';
import { DetalleNominaComponent } from './detalle-nomina/detalle-nomina.component';
import { SettingsService } from 'src/app/settings/settings.service';;
import { FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
declare var $ :any;



@Component({
  selector: 'app-operaciones-remuneraciones',
  templateUrl: './operaciones-remuneraciones.component.html',
  styleUrls: ['./operaciones-remuneraciones.component.less']
})
export class OperacionesRemuneracionesComponent implements OnInit, OnDestroy {

  id_company:any;
  simulaciones:any[]=[];
  ELEMENT_DATA = []
  url;
  has_file:any
  has_simulation:boolean=true;
  show_spinner_loading:boolean=true;

  @Input() ocultar_filter:boolean=true;

  @Input() subtitle="Revisa tus simulaciones y comienza el proceso de cesión";

  @Input() titulo = "Simulaciones"
  

  displayedColumns: string[] = ['position'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  @Input() selected:any="";
  disabled:boolean=false;
  filter:any="";
  display_spinner:boolean=false;

  status = new FormControl();
  statusList: any[] = [{'value': '0', 'name':'Solicitada'}, {'value': '1', 'name':'En proceso'}, {'value': '2', 'name':'Girada'}];
  value_status_filter: string = "";

  constructor(private cdRef:ChangeDetectorRef,private activated_router: ActivatedRoute, fb: FormBuilder,public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService){ 

      this.url = SettingsService.API_ENDPOINT + '/media/simulaciones/'
    }


  myObserver:any

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngOnInit() {
    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all()
        
      };
     });

     this.load_all()
  }

  status_filter(value:any) {
    this.value_status_filter = value.join()
    this.load_simulations(1);
  }

  load_all()
  {
    this.id_company = localStorage.getItem("company_id")
    this.load_simulations(1)


  }





  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  page = 1

  //table 2.0
  ELEMENT_DATA_2: Nominas[]=[];
  displayedColumns2: string[] = ['id','date','monto','estado','giro', 'accion'];
  dataSource2 = new MatTableDataSource<Nominas>(this.ELEMENT_DATA_2);
  @ViewChild(MatPaginator) paginator2!: MatPaginator;
  async load_simulations(page:any){

    this.page = page
    this.has_simulation = true;
    this.show_spinner_loading = true;


    let resultado: any = (await this.settings.get_query(1, 'pagoRemuneracionesSimulation/' + this.id_company + '/'));
    let body_data = resultado._body;
    if (resultado) {
      if(body_data.cabecera.length > 0){
        this.has_simulation = false;
        this.show_spinner_loading = false;
        
      }else{
        this.show_spinner_loading = false;
        this.has_simulation = true;
      }


      this.ELEMENT_DATA = body_data.cabecera

      this.display_spinner = false;

      this.ELEMENT_DATA_2 = body_data.cabecera;
      this.dataSource2=new MatTableDataSource(this.ELEMENT_DATA_2);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource2._updateChangeSubscription()


    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  open_nomina(id:any, status:any){
    let done = true;
    if (status == "2") {
      done = false;
    }
    let dialogRef = this.dialog.open(DetalleNominaComponent, {
      width: '1300px',
  
    });
    dialogRef.componentInstance.simulation_id = id;
    dialogRef.componentInstance.is_not_done = done;
    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_all()
    });
  }

  open_simulation(id:any, status:any){
    let done = true;
    if (status == "2") {
      done = false;
    }
    let dialogRef = this.dialog.open(SimulationOp, {
      width: '1300px',
  
    });
    dialogRef.componentInstance.simulation_id = id;
    dialogRef.componentInstance.is_not_done = false;
    dialogRef.componentInstance.tipo = 1;
    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_all()
    });
  }

  open_credenciales(){
    this.router.navigate(['/', 'pago-proveedores'])
    .then(nav => {


    }, err => {
      console.log(err) // when there's an error
    });
  }

}



export interface Nominas{  
  ejecutivo: any,
  n:any,
  nomina:any,
  fecha:any,
  monto:any,
  estado:any,
  giro:any
}

