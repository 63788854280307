
<div class="row row_main animate__animated animate__fadeIn">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <h1 class="title-label">¿Qué es la “documentación de respaldo” y para qué sirve?</h1>

  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <div class="row">

          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div class="p-label">
                Son documentos necesarios para agilizar la evaluación de una operación, ya que contienen<br>
                información relevante que permite validar la emisión de tus facturas y optimizar el proceso de<br>
                cesión. Entre los más utilizados está la Orden de Compra (OC), la Orden de Trabajo (OT), Migo,<br>
                la Hoja de Entrada de Servicios (HES) y la Hoja de Entrada de Material (HEM). 
              </div>
          </div>


          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div style="width: 100%;">
                <img src="../../../../../assets/simpli/cesion/info-files.svg" class="img-files">
              </div>
          </div>

    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div class="btn-simpli-container">
          <button style="margin-left: 0px;" class="primary-button"  (click)="backClicked()" mat-flat-button>Volver</button>
        </div>
        
      </div>
    </div>

  </div>

</div>