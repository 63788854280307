
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-dialog-delete-aec',
  templateUrl: './dialog-delete-aec.html',
  styleUrls: ['./dialog-delete-aec.less']
})
export class DialogDeleteAec implements OnInit {


  @Input() id_sim:any;
  @Input() id_company:any;
  parent:any;
  show_icon_delete:boolean=false;

  open_as_sim:boolean=false;

  title:string="¡Cesión realizada con éxito!";
  subtitle:string="Revisa el estado de esta etapa de la operación, con sus datos actualizados."

  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<DialogDeleteAec>, public router:Router) {

    this.id_company = localStorage.getItem('company_id');

   }


  is_responsive:boolean=false;
  ngOnInit() {
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }

  ver_estado(){
    this.dialogRef.close();
  }

  eliminar_aec(){
    this.dialogRef.close(true)
  }

  nuevos_aec(){
    this.dialogRef.close(1)
  }

  por_otro_metodo(){
    this.dialogRef.close(2)
  }

}