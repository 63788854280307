import { Component, Input, OnInit } from '@angular/core';
declare var $ :any;

@Component({
  selector: 'app-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.less']
})
export class EmptyMessageComponent implements OnInit {

  @Input() message!: string;
  @Input() submessage!: string;
  @Input() parent!: any;
  @Input() disabled_download_file!: any;

  constructor() { }

  ngOnInit() {
  }



}
