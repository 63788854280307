import { Component, inject, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SettingsService } from '../settings/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { WelcomeModalLogin } from '../registro-empresa/registro-empresa.component';
import { MatDialog } from '@angular/material/dialog';

declare var grecaptcha: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.less',
})
export class LandingComponent implements AfterViewInit, OnInit {
  valForm: FormGroup;
  valFormCompany: FormGroup;

  paso: any = 1;

  constructor(
    private fb: FormBuilder,
    private settings: SettingsService,
    public snackBar: MatSnackBar, 
    private router: Router,
    public dialog: MatDialog,
    private activated_router: ActivatedRoute,
    private change:ChangeDetectorRef
  ) {
    let password = new FormControl(
      null,
      Validators.compose([Validators.required, Validators.minLength(6)])
    );
    let cPassword = new FormControl(
      null,
      Validators.compose([
        Validators.required,
        CustomValidators.equalTo(password),
      ])
    );

    this.valForm = this.fb.group({
      password: password,
      password_confirmation: cPassword,
      username: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
    });

    this.valFormCompany = this.fb.group({
      nombre: [null, Validators.compose([Validators.required])],
      rut: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{7,10}[-][0-9Kk]$'),
        ]),
      ],
      telefono: [null, Validators.compose([Validators.required])],
    });

    this.activated_router.params.subscribe((params:any) => {
      this.id_alianza = params['id_alianza']
      console.log('Ally detected')
    })
    
  }

  param:any="";
  ngOnInit(){
    let name = this.activated_router.snapshot.queryParamMap.get('gclid')
    let parametros_compite: any;
    let compite = false;
    this.activated_router.queryParamMap.subscribe((queryParams:any) => {
      console.log(queryParams);
      name = queryParams.get('gclid');
      let params = queryParams.get('params');
      if (this.id_alianza != null && this.id_alianza != undefined) {
        //if(this.id_alianza=='compite'){
        parametros_compite = params;
        compite = true;
        //}
      }
      this.parametros_compite = parametros_compite;
      if (this.parametros_compite != undefined) {
        this.valForm.controls['rut'].setValue(this.parametros_compite['rut_cliente']);
        this.valForm.controls['username'].setValue(this.parametros_compite['email']);
        this.valForm.controls['telefono'].setValue(this.parametros_compite['telefono']);
        this.valForm.controls['nombre'].setValue(this.parametros_compite['contacto']);
        this.checkRut(this.parametros_compite['rut_cliente']);
      }
      console.log(this.parametros_compite)
      this.compite = compite;

    });
    if (name != null) {
      this.code_adwords = name;
    }

    this.activated_router.queryParams.subscribe(params => {

      this.param = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    });

    if(this.param != undefined && this.param != null && this.param != ""){
      localStorage.setItem('param', this.param)
    }

    this.change.detectChanges();
  }

  ngAfterViewInit() {
    const animatedElements = document.querySelectorAll('.scroll-animate');

    animatedElements.forEach((element) => {
      (element as HTMLElement).style.opacity = '0';
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            (entry.target as HTMLElement).style.opacity = '1';
            entry.target.classList.add(
              'animate__animated',
              'animate__fadeInUp',
              'animate__slow'
            );
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.4 }
    );

    animatedElements.forEach((element) => {
      observer.observe(element);
    });

    this.animateNumber(0, 900, 2000);
    this.animateNumber2(0, 5000, 2000);
    this.animateNumber3(0, 32000, 2000);
  }

  scrollToForm() {
    const element = document.getElementById('registro-form');
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  hide: boolean = true;
  hideRe: boolean = true;
  show_spinner_mail: boolean = false;
  async changeMail($e: any) {
    let email = $e.target.value;
    localStorage.removeItem('param');
    this.valForm.controls['username'].markAsTouched();
    if (this.valForm.controls['username'].valid) {
      await this.onSubmit(email);
    }
  }

  captchaValidated: boolean = false;
  show_spinner: boolean = false;
  submitFormCompany($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (!this.captchaValidated) {
      this.snackBar.open('No pudimos validar tu solicitud. Por favor, intenta nuevamente', 'Aceptar', {
          duration: 20000,
          panelClass: 'simpli-snack',
      });
      return;
  }
    if (this.valForm.valid) {
      this.setPaso(2);
    } else {
      this.show_spinner = false;
    }
  }

  setPaso(paso: Number) {
    this.paso = paso;
  }

  id_alianza: any;
  code_adwords: any;
  name_empresa: string = '';
  compite = false;
  parametros_compite: any;

  async submitForm($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valFormCompany.controls) {
      this.valFormCompany.controls[c].markAsTouched();
    }
    if (this.valFormCompany.valid) {
      try {
        this.show_spinner = true;

        value['nombre_empresa'] = this.name_empresa;
        value['username'] = this.valForm.controls['username'].value;
        value['password'] = this.valForm.controls['password'].value;
        value['password_confirmation'] =
          this.valForm.controls['password_confirmation'].value;
        if (this.id_alianza != undefined) {
          value['id_alianza'] = this.id_alianza;
        }
        if (this.code_adwords != undefined) {
          value['code_adwords'] = this.code_adwords;
        }
        if (this.compite == true) {
          value['datos_compite']['tasa'] = this.parametros_compite['tasa'];
          value['datos_compite']['interes_total'] =
            this.parametros_compite['interes_total'];
          value['datos_compite']['monto_anticipado'] =
            this.parametros_compite['monto_anticipado'];
          value['datos_compite']['gastos'] = this.parametros_compite['gastos'];
          value['datos_compite']['comision'] =
            this.parametros_compite['comision'];
          value['datos_compite']['dias_vencimiento'] =
            this.parametros_compite['dias_vencimiento'];
          value['datos_compite']['iva'] = this.parametros_compite['iva'];
        }

        if (this.param != '' && this.param != null && this.param != undefined) {
          value['utm_source'] = this.param;
        }

        const bd: any = await this.settings.get_query(
          2,
          'cliente/signup/',
          value,
          true
        );
        var body_data: any = bd._body;

        switch (bd.status) {
          case 200:
            localStorage.setItem('token', body_data?.success?.info?.token);
            localStorage.setItem('role', body_data?.success?.info?.role);
            localStorage.setItem(
              'company_id',
              body_data?.success?.info?.company_id
            );

            this.show_spinner = false;
            this.setPaso(3);

            break;
          default:
            this.show_spinner = false;

            break;
        }
      } catch (bd: any) {
        this.show_spinner = false;
      }
    }
  }

  display_spinner: boolean = false;
  async checkRut($e: any) {
    let rut =
      $e.target != undefined ? String($e.target.value).toLocaleUpperCase() : $e;

    try {
      let value = { rut: rut };
      this.display_spinner = true;
      const bd: any = await this.settings.get_query(
        2,
        'cliente/check-signup/',
        value,
        true
      );
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:
          this.name_empresa = body_data.success.info.business_name;
          this.display_spinner = false;

          break;
        default:
          this.valFormCompany.controls['rut'].setErrors({ invalid: true });
          this.display_spinner = false;
          break;
      }
    } catch (bd: any) {
      this.display_spinner = false;
      this.valFormCompany.controls['rut'].setErrors({ invalid: true });
    }
  }

  rutKey($e: any) {
    let rut = $e.target.value.split('.').join('');
    let st = rut.replace('-', '');
    let arr = st.split('');
    let st_out = '';
    for (let i = 0; i < arr.length; i++) {
      st_out = st_out + arr[i];
      if (i == arr.length - 2) {
        st_out = st_out + '-';
      }
    }
    rut = st_out;
    this.valFormCompany.controls['rut'].setValue(rut);
  }

  checkPhone($e: any) {
    this.valFormCompany.controls['telefono'].markAsTouched();
    if (this.valFormCompany.controls['telefono'].valid) {
      const rgx = new RegExp(/^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/, 'i');
      if (!rgx.test($e.target.value)) {
        this.valFormCompany.controls['telefono'].setErrors({ invalid: true });
      }
    }
  }

  cajas = [
    {
      icon: 'fa-regular fa-calendar-check',
      titulo: 'horarios extendidos',
      bajada:
        'Hacemos factoring de lunes a viernes, hasta las 17:00 horas, ¡Y con giro el mismo día!',
    },
    {
      icon: 'fa-solid fa-handshake',
      titulo: 'transparencia total',
      bajada: 'Conocerás todos los costos operacionales desde el principio.',
    },
    {
      icon: 'fa-regular fa-clock',
      titulo: 'tu tiempo vale oro',
      bajada: 'Por lo que nosotros nos encargamos de la cobranza.',
    },
    {
      icon: 'fa-solid fa-check',
      titulo: 'asegura tus licitaciones',
      bajada: 'Somos expertos en financiamiento para proveedores del estado.',
    },
  ];
  

  async onSubmit(email: string) {
    const siteKey = '6Ld17YUqAAAAABsHjRFmEbWHBd9amiZgsC0oH51C';

    grecaptcha.ready(() => {
      grecaptcha
        .execute(siteKey, { action: 'submit_form' })
        .then(async (token: string) => {
          console.log('recaptcha:', token);
          try {
            this.show_spinner_mail = true;
            const captchaData = { recaptcha: token };
            const response: any = await this.settings.get_query(
              2,
              'cliente/landing-captcha/',
              captchaData,
              true
            );

            if (response.status === 200) {
              await this.validar_email(email);
            }
          } catch (error: any) {
            console.error('Error validating reCAPTCHA:', error);

            if (error.status === 401 || error.status === 403) {
              this.snackBar.open('No pudimos validar tu solicitud. Por favor, intenta nuevamente', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack',
              });

              this.valForm.controls['username'].reset();

            }
          }
        });
    });
  }

  async validar_email(email: string) {
    try {
      let value = { username: email };
      this.show_spinner_mail = true;
      const bd: any = await this.settings.get_query(
        2,
        'cliente/check-signup/',
        value,
        true
      );
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:
          this.captchaValidated = true;
          this.show_spinner_mail = false;
          break;
        default:
          this.captchaValidated = false;
          this.show_spinner_mail = false;
          break;
      }
    } catch (bd: any) {
      this.captchaValidated = false;
      this.show_spinner_mail = false;
      this.valForm.controls['username'].setErrors({ incorrect: true });
    }
  }

  dialog_success() {
    this.router.navigate(['/home/onboarding'])
  }

  animatedNumber: number = 0;
  animatedNumber2: number = 0;
  animatedNumber3: number = 0;
  animateNumber(start: number, end: number, duration: number) {
    const increment = (end - start) / (duration / 10);
    let currentNumber = start;

    const interval = setInterval(() => {
      currentNumber += increment;
      if (currentNumber >= end) {
        currentNumber = end;
        clearInterval(interval);
      }
      this.animatedNumber = Math.floor(currentNumber);
    }, 10);
  }

  animateNumber2(start: number, end: number, duration: number) {
    const increment = (end - start) / (duration / 10);
    let currentNumber = start;

    const interval = setInterval(() => {
      currentNumber += increment;
      if (currentNumber >= end) {
        currentNumber = end;
        clearInterval(interval);
      }
      this.animatedNumber2 = Math.floor(currentNumber);
    }, 10);
  }

  animateNumber3(start: number, end: number, duration: number) {
    const increment = (end - start) / (duration / 10);
    let currentNumber = start;

    const interval = setInterval(() => {
      currentNumber += increment;
      if (currentNumber >= end) {
        currentNumber = end;
        clearInterval(interval);
      }
      this.animatedNumber3 = Math.floor(currentNumber);
    }, 10);
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) {}
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }
}
