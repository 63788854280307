import { Component, Output, OnInit, EventEmitter, ViewChild, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;

@Component({
  selector: 'app-ranking-deudores-ventas',
  templateUrl: './ranking-deudores-ventas.component.html',
  styleUrls: ['./ranking-deudores-ventas.component.less']
})

export class RankingDeudoresVentasComponent {

  @Input() id:any;
  @Input() ranking_deudores_ventas_obj: any;
  len_info: number = 0;
  ELEMENT_DATA: Deudores[]=[];
  displayedColumns: string[] = ['business_name', 'conteo', 'monto', 'porcentaje'];
  dataSource = new MatTableDataSource<Deudores>(this.ELEMENT_DATA);
  @ViewChild('paginator') paginator!: MatPaginator;
  info:Boolean=false
  cargando:Boolean=true
  ancho:string="no-ancho"
  @Output() ranking_deudor_ventas = new EventEmitter<string>();
  actual:any;
  menos:any;

  constructor(public settings: SettingsService, private dialog:MatDialog) { 

  }

  ngOnChanges(changes: SimpleChanges) {
    this.informacion()
  }

  @Input() date_start:any;
  @Input() date_end:any;

  loading:boolean=false;
  firstRow: any;

  informacion = async () => {
    this.ancho="no-ancho"
    this.cargando=true
    let mes_actual
    let mes_menos
    if (this.ranking_deudores_ventas_obj === undefined ) {
    //Mes actual
    let mensual = new Date();
    let mes_actual_ultimo = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);
    let mes1 = new Date(mes_actual_ultimo)
    let y1 = mes1.getFullYear();
    let m1:any = mes1.getMonth()+1;
    let d1:any = mes1.getDate();
    if(m1<10){m1="0"+m1}
    if(d1<10){d1="0"+d1}
    mes_actual = y1+"-"+m1+"-"+d1

    //Mes -6
    let mesmenos=new Date(mes_actual_ultimo.getTime() - (3650*60*60*1000)*1);
    let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
    let mes6 = new Date(ultimomenos)
    let y2 = mes6.getFullYear();
    let m2:any = mes6.getMonth()+1;
    let d2:any = mes6.getDate();
    if(m2<10){m2="0"+m2}
    if(d2<10){d2="0"+d2}
    mes_menos = y2+"-"+m2+"-"+d2
  } else{
    mes_actual = this.ranking_deudores_ventas_obj.end
    mes_menos = this.ranking_deudores_ventas_obj.start
  }

  this.actual = mes_actual
  this.menos = mes_menos

  this.loading = true;
  this.ELEMENT_DATA = []

    let resultado:any = (await this.settings.get_query(1, 'ventas-analisis/'+this.id+'/'+"?inicio="+this.date_start+"&fin="+this.date_end))      
    let body_data=resultado._body;
    
    if(!body_data['error']){
      console.log("DEUDORESVENTAS", body_data)
      this.cargando=false

      if(body_data.resumen.length>0){
        this.info=true
        this.ancho="ancho" 
      }else{
        this.info=false
        this.ancho="no-ancho"
      }

      this.len_info=body_data.resumen.length
      this.ELEMENT_DATA=body_data.resumen;  
      
      this.checkFirstPage();


      setTimeout(() => {
        this.dataSource=new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator
        this.dataSource._updateChangeSubscription()

      });

      // this.ranking_deudor_venta.emit('11')
      this.loading = false;



    }
    else{
      this.ELEMENT_DATA = []
      this.cargando=false
      this.info=false
      this.ancho="no-ancho"
      // this.ranking_deudor_venta.emit('11')
      //this.settings.empty_local_data();
      this.loading = false;
    }
  }

  anotherArray: any[] = [];
  checkFirstPage() {

      if (this.ELEMENT_DATA.length > 0) {
        const firstRow = this.ELEMENT_DATA.shift();
        this.anotherArray.push(firstRow);
      }

    
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  applyFilter = (filterValue: string) => {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


 
}

export interface Deudores{
  id: number ,
  business_name: string,
  conteo: number,
  monto: number,
  porcentaje: number,
  rut_deudor: string,
  total: number
}
