import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
declare var $: any;


@Component({
  selector: 'app-detalle-operaciones',
  templateUrl: './detalle-operaciones.component.html',
  styleUrls: ['./detalle-operaciones.component.less']
})
export class DetalleOperacionesComponent implements OnInit {



  valFormFilter!: FormGroup

  constructor(private router: Router, public settings: SettingsService, public dialog: MatDialog, public fb:FormBuilder ) { 

    this.valFormFilter = this.fb.group({
      filter: '',
      fecha_ini: '',
      fecha_fin: '',
    });

  }

  @Input() id_company:any
  @Input() parent:any;

  ngOnInit() {


    this.get_operaciones();

    window.scrollTo({top: 0, behavior: 'smooth'});
  }


  por_ceder:any[]=[];
  cedidas:any[]=[];
  descartadas:any[]=[];
  loading:boolean=true;

  async get_operaciones() {

    let tab:any=1;
    if(this.tab1 == true){
      tab = 1;
    }else if(this.tab2 == true){
      tab = 2;
    }else if(this.tab3 == true){
      tab = 3;
    } 

    try {
      const bd: any = await this.settings.get_query(1, `/cliente/parrillaOperaciones/${this.id_company}/?pestania=${tab}&pageNumber=${this.page}&filter=${this.filter}&fecha_ini=${this.fecha_inicio}&fecha_fin=${this.fecha_fin}`);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          if(this.tab1 == true){
            this.por_ceder = data.info;
            this.pageLength = data.total;

          }

          if(this.tab2 == true){
            this.cedidas = data.info;
            this.pageLength = data.total;

          }

          if(this.tab3 == true){
            this.descartadas = data.info;
            this.pageLength = data.total;

          }

          setTimeout(() => {
            this.loading = false;
          });





          break;
        default:
            //this.loading = false;
          break;
      }
    } catch (bd: any) {
      this.loading = false;
      this.por_ceder = [];
      this.cedidas = [];
      this.descartadas = [];
      this.settings.manage_errors(bd.status, "operacionesPorCeder", bd, false);
    }
  }


  tab1:boolean=true;
  tab2:boolean=false;
  tab3:boolean=false;
  switch_tab(tab_name:string){
    $(".tab-label").removeClass('tab-label-active');
    $("#"+tab_name).addClass('tab-label-active');

    switch(tab_name) { 
      case 'tab_ceder': { 
         this.tab1 = true;
         this.tab2 = false;
         this.tab3 = false;

         this.por_ceder = [];
         this.cedidas = [];
         this.descartadas = [];
         this.loading = true;
         this.page = 1;

         this.get_operaciones();

          break; 
      } 
      case 'tab_cedidas': { 
        this.tab1 = false;
        this.tab2 = true;
        this.tab3 = false;

        this.por_ceder = [];
        this.cedidas = [];
        this.descartadas = [];
        this.loading = true;
        this.page = 1;

        this.get_operaciones();

         break; 
      } 
      case 'tab_descartadas': { 
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = true;

        this.por_ceder = [];
        this.cedidas = [];
        this.descartadas = [];
        this.loading = true;
        this.page = 1;

        this.get_operaciones();

          break; 
     } 
      default: { 
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;

        this.por_ceder = [];
        this.cedidas = [];
        this.descartadas = [];
        //this.loading = false;
         break; 
      } 
   } 
  }


  pageLength = 0;
  pageSize = 8;
  pageIndex = 0;
  page = 1;
  @ViewChild(MatPaginator, {static : false}) paginator!: MatPaginator;
  pageChangeEvent(event:any) {
    console.log("event", event)
    this.page = event.pageIndex + 1;
    this.pageIndex = event.pageIndex;
    this.get_operaciones()
  }

  simular(){
    this.parent.simular();
  }

  ver_detalle(id:any){
    this.router.navigate(['home/detalle-operacion/',id])
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }


  fecha_inicio:any="";
  fecha_fin:any="";
  filter:any="";
  date_change_start($event:any){

    let date_start = new Date(this.valFormFilter.controls['fecha_ini'].value);
    let dia, mes, ano;
    dia = String(date_start.getDate())
    mes = String(date_start.getMonth() + 1)
    ano = String(date_start.getFullYear())
    if (mes.length == 1) {
      mes = '0' + mes
    }
    if (dia.length == 1) {
      dia = '0' + dia
    }

    let start = ano + '-' + mes + '-' + dia + ' 00:00:00';
    let start2 = ano + '-' + mes + '-' + dia + ' 23:59:59';

    this.fecha_inicio = start;
    this.fecha_fin = start2;

    console.log(this.fecha_inicio)
    console.log(this.fecha_fin)

  }

  date_change_end($event:any){

    if(this.valFormFilter.controls['fecha_fin'].value == null){

      this.fecha_inicio = this.fecha_inicio;

      let date_end = new Date(this.valFormFilter.controls['fecha_ini'].value);
      let dia2, mes2, ano2;
      dia2 = String(date_end.getDate())
      mes2 = String(date_end.getMonth() + 1)
      ano2 = String(date_end.getFullYear())
      if (mes2.length == 1) {
        mes2 = '0' + mes2
      }
      if (dia2.length == 1) {
        dia2 = '0' + dia2
      }
  
      let end = ano2 + '-' + mes2 + '-' + dia2 + ' 23:59:59';
      this.fecha_fin = end

    }else{
      let date_end = new Date(this.valFormFilter.controls['fecha_fin'].value);
      let dia2, mes2, ano2;
      dia2 = String(date_end.getDate())
      mes2 = String(date_end.getMonth() + 1)
      ano2 = String(date_end.getFullYear())
      if (mes2.length == 1) {
        mes2 = '0' + mes2
      }
      if (dia2.length == 1) {
        dia2 = '0' + dia2
      }
  
      let end = ano2 + '-' + mes2 + '-' + dia2 + ' 23:59:59';
      this.fecha_inicio = this.fecha_inicio;
      this.fecha_fin = end
    }

  }

  //FILTER
  applyFilter(value:any) {
    this.pageIndex = 0;
    this.page = 1;
    
    this.valFormFilter.controls['filter'].setValue(value)
    this.filter = value;
    this.get_operaciones()
  }

  applyFilter2() {
    this.pageIndex = 0;
    this.page = 1;

    this.filter = this.valFormFilter.controls['filter'].value;
    this.valFormFilter.controls['filter'].setValue(this.filter)

    this.get_operaciones()
  }

  load_dates(){
    this.pageIndex = 0;
    this.page = 1;
    this.get_operaciones()
  }

  reset(){
    this.fecha_fin = "";
    this.fecha_inicio = "";
    this.filter = "";
    this.valFormFilter.reset();
    this.page = 0;
    this.pageIndex = 0;

    this.switch_tab('tab_ceder')


  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    console.log("page event", pageNumber);
    this.get_operaciones();
  }


}