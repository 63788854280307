<div class="row row_main" style="margin-bottom: 45%;">

    <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli">Operacion de Proveedores</h1>
        <small class="subtitulo-simpli">Administra y gestiona las operaciones relacionadas con tus Proveedores</small>

        <div class="row">

          <div *ngIf="show_spinner_loading == false" class="col-lg-2 col-md-6 col-sm-12 col-xs-12" >
            <mat-form-field appearance="fill">
              <mat-label>Estado</mat-label>
              <mat-select [formControl]="status" multiple (selectionChange)="status_filter($event.value)">
                <mat-option *ngFor="let status of statusList" [value]="status.value">{{status.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-top">

      <mat-card style="margin-top: 20px;margin-right: 10px;">
        <mat-card-content>

            <button mat-menu-item routerLink="/home/operaciones">
                <i class="fa-light fa-money-bill-transfer icon-simpli"></i>
                  <span style="color: var(--simpli-color3);">Operaciones Factoring</span>
            </button>

            <button mat-menu-item style="background: rgba(0,0,0,.04);pointer-events: none;" matTooltip="Operaciones Pago Proveedores">
                <i class="fa-light fa-money-bill-trend-up icon-simpli"></i>
                <span style="color: var(--simpli-color3);">Operaciones Proveedores</span>
            </button>

            <button mat-menu-item routerLink="/home/operaciones-remuneraciones" matTooltip="Operaciones Pago de Remuneraciones">
              <i class="fa-light fa-money-bill-wave icon-simpli"></i>
              <span style="color: var(--simpli-color3);">Operaciones Remuneraciones</span>
          </button>




        </mat-card-content>
    </mat-card>

    </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn add_factoring" style="padding-bottom: 50px;">
    <div class="row">
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">


        <div class="sinRegistros"  style="text-align: center;margin-top: 40px;padding-top: 40px;padding-bottom: 40px;"  *ngIf="this.has_simulation && show_spinner_loading == false">
          <small class="small-sinregistros" style="text-align: center;">
            <div style="text-align: center;margin-bottom: 20px;">
                <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 30px;"></i>
            </div>
            Todavía no hay historial de pago de proveedores. Simula un pago de proveedores y simplifica tus finanzas. <br>
            <strong>Pagar a Proveedores</strong>              
          <br />
          <br />
          <div style="text-align: center;">
            <button  mat-flat-button class="primary-button" (click)="open_credenciales()" type="button">Vamos</button>
          </div>
         
        </small>
        </div>


      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top cajaGrande animate__animated animate__fadeIn" *ngIf="show_spinner_loading == true && has_simulation" >
        <small class="textoCajaGrande" style="font-size: 1.2em;text-align: center;">
          <mat-spinner class="spinner-simpli" diameter="30"></mat-spinner>
          <!-- <p>Cargando información</p> -->
        </small>
      </div>

      <div *ngIf="!this.has_simulation && show_spinner_loading == false" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" >
        <mat-card class="add_factoring" style="overflow: auto;">
          <mat-card-header>
            <mat-card-title></mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="responsive-container">
              <mat-accordion  
              [multi]="true"
              #accordion="matAccordion">
                <mat-expansion-panel class="panel-op"                
                  [disabled]="panel.isDisabled"
                  [expanded]="panel.isExpanded"
                  [hideToggle]="false"
                  (opened)="beforePanelOpened(panel)"
                  (closed)="beforePanelClosed(panel)"
                  (afterCollapse)="afterPanelClosed($event)"
                  (afterExpand)="afterPanelOpened($event)"
                  *ngFor="let panel of this.dataSource2.data" 
                  #mapanel="matExpansionPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <strong>Operación N° {{panel.id}} </strong>&nbsp; del {{panel.date | date:'dd/MM/yy' }}
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="no-input" style="color: #bdbdbd;" value="Solicitada" *ngIf="panel.status == 0">Solicitada</span>
                      <span class="no-input" style="color: #95a5ef;" value="En proceso" *ngIf="panel.status == 1">En proceso</span>
                      <span class="no-input" style="color: #3BA46F;" value="Girada" *ngIf="panel.status == 2">Girada</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <app-pago-proveedores-resumen (was_edited)="onEditResponsive($event)" [estado]="panel.status" [id]="panel.id" [done]="panel.status == 2 ? false : true" [clicked]="panel.isExpanded" [responsive]="true"></app-pago-proveedores-resumen>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

            <div class="table-not-responsive">
                <table mat-table multiTemplateDataRows [dataSource]="dataSource2">
                
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> N° </th>
                  <td mat-cell *matCellDef="let element" class="columnTable"> 
                    <span class="no-input">{{element.id}}</span>
                    <!-- <class class="no-input" value="{{element.id}}" readonly>   -->                          
                  </td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha Solicitud </th>
                  <td mat-cell *matCellDef="let element" class="columnTable"> 
                    <span class="no-input">{{element.date | date:'dd/MM/yy' }}</span>
                    <!-- <input class="no-input" value="{{element.date | date:'dd/MM/yy' }}" readonly>   -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Monto </th>
                  <td mat-cell *matCellDef="let element" class="columnTable">
                    <span class="no-input">$ {{formatMoney(element.monto)}}</span>
                    <!-- <input class="no-input" value="$ {{formatMoney(element.monto)}}" readonly>   -->
                  </td>
                </ng-container>


                <ng-container matColumnDef="estado">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Estado </th>
                  <td mat-cell *matCellDef="let element" class="columnTable">                          
                    <span class="no-input" style="color: #bdbdbd;" value="Solicitada" *ngIf="element.status == 0">Solicitada</span>
                    <span class="no-input" style="color: #95a5ef;" value="En proceso" *ngIf="element.status == 1">En proceso</span>
                    <span class="no-input" style="color: #3BA46F;" value="Girada" *ngIf="element.status == 2">Girada</span>
                    <!-- <input readonly class="no-input" style="color: #bdbdbd;" value="Solicitada" *ngIf="element.status == 0">
                    <input readonly class="no-input" style="color: #95a5ef;" value="En proceso" *ngIf="element.status == 1">
                    <input readonly class="no-input" style="color: #3BA46F;" value="Girada" *ngIf="element.status == 2"> -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="giro">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Giro a tu cuenta </th>
                  <td mat-cell *matCellDef="let element" class="columnTable">
                    <!-- <span class="no-input">$ {{formatMoney(element.dif_giro_pago_prov)}}</span> -->
                    <input class="no-input text-center" [(ngModel)]="element.dif_giro_pago_prov" matTooltip="Agregar cuenta bancaria" readonly> 
                  </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Acción </th>
                  <td mat-cell *matCellDef="let element" class="columnTable"> 
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns2.length">
                    <div class="example-element-detail" [@detailExpand]="(element == expandedElement || element.was_clicked == true) ? 'expanded' : 'collapsed'">
                      <app-pago-proveedores-resumen (was_edited)="onEditNotResponsive($event)" [estado]="element.status" [id]="element.id" [done]="(element.status == 2 ? false : true)" [clicked]="element.was_clicked" [responsive]="false"></app-pago-proveedores-resumen>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="text-center"></tr>
                <!-- <tr mat-row *matRowDef="let row; let element; columns: displayedColumns2"></tr> -->
                <tr mat-row *matRowDef="let element; columns: displayedColumns2;"
                  class="example-element-row text-center" 
                  [class.example-expanded-row]="expandedElement === element || element.was_clicked == true"
                  (click)="expandedElement = expandedElement === element ? null : element; expand(element);">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No hay registros.</td>
                </tr>
              </table>

            </div>
          </mat-card-content>

        </mat-card>
      </div>
    </div>
    <mat-paginator style="background: none;" [pageSizeOptions]="[20, 50, 100, 500]"></mat-paginator>
  </div>
</div>


