<div>
    <mat-spinner *ngIf="showSpinner" diameter="40" class="spinner-arrayan-general centerabsolute" style="margin-top: 20%;"></mat-spinner>
    <div *ngIf="noInfo && !showSpinner" class="box_placeholder" style="margin-top: 20%; overflow: hidden">
        <i class="material-icons ball"> assessment </i>
        <div style="margin-top: -20px">No hay información.</div>
    </div>
    <!-- <mat-form-field> -->
    <div *ngIf="!noInfo && !showSpinner">
        <mat-form-field>
            <mat-select panelClass='my-select' [(ngModel)]="selectedValue" (selectionChange)="onOptionSelected($event)" placeholder='Proveedor'>
                <mat-option>Limpiar</mat-option>
                <mat-option *ngFor="let deudor of tableDataDeudores" [value]="deudor">
                    {{ deudor.business_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- </mat-form-field> -->

        <table mat-table [dataSource]="dataSource" class="table__style">
            <ng-container matColumnDef="proveedor">
                <th mat-header-cell *matHeaderCellDef>Proveedor</th>
                <td mat-cell *matCellDef="let element" style="text-align: left" matTooltip="{{ element.business_name }}">
                    <input class="no-input" style="text-align: left" value="{{ element.business_name | slice: 0:25 }}" readonly />
                </td>
                <td mat-footer-cell *matFooterCellDef>Total</td>
            </ng-container>

            <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef>Monto</th>
                <td mat-cell *matCellDef="let element">$ {{ formatMoney(element.monto) }}</td>
                <td mat-footer-cell *matFooterCellDef>$ {{ formatMoney(totalDeuda) }}</td>
            </ng-container>

            <ng-container matColumnDef="porcentaje">
                <th mat-header-cell *matHeaderCellDef>Porcentaje</th>
                <td mat-cell *matCellDef="let element">{{ element.porcentaje }} %</td>
                <td mat-footer-cell *matFooterCellDef>{{ totalPorcentaje }} %</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[7]"></mat-paginator>
</div>