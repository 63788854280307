import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';


declare var $ :any;

@Component({
  selector: 'my-executive',
  templateUrl: './my-executive.html',
  styleUrls: ['./my-executive.less']
})


export class MyExecutive implements OnInit {
   


  constructor(public snackBar: MatSnackBar, public dialog: MatDialog,  
    public settings: SettingsService,
    private changeDetector:ChangeDetectorRef ){

  }

  ngOnInit() {

    this.get_executive_info()


  }



  executive:any="";
  phone:any="";
  mail:any="";
  avatar:any=null;
  jp:any="";
  jp_number:any="";
  jp_mail:any="";
  async get_executive_info() {
    try {
      let id_company = localStorage.getItem('company_id')

      const bd: any = await this.settings.get_query(1, '/cliente/data-executive/' + id_company + '/');
      var body_data: any =bd._body.success.info;
      switch (bd.status) {
        case 200:

        this.executive = body_data.name;
        this.phone = body_data.phone;
        this.avatar = body_data.avatar;
        this.mail = body_data.mail;
        this.jp = body_data.jp.name;
        this.jp_number = body_data.jp.phone
        this.jp_mail = body_data.jp.mail;



          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  getInitials(name: string): string {
    if (!name) return '';
    const initials = name.split(' ').map(n => n[0]).join('').toUpperCase();
    return initials;
  }

}


