<div class="container">

  <div class="label-container" style="text-align: center;">
    <div class="titulo-contact">{{tittle}}</div>
  </div>

  <div class="contact-container animate__animated animate__fadeIn">
    <div class="contact-row">
      <div class="videollamada">
        <span (click)="agendar()" class="videollamada-icons"><i class="fa-light fa-video"></i>Videollamada</span>
      </div>
      <div class="llamar-container">
        <div class="llamar">
          <a *ngIf="!loading_name" class="animate__animated animate__fadeIn llamar-icons" [href]="'tel:' + contacto"
            style="text-decoration: none; color: inherit;">
            <i class="fa-sharp fa-solid fa-headset"></i>+{{contacto}}
          </a>
        </div>
        <div class="ejecutivo-container">
          <span *ngIf="!loading_name" class="ejecutivo-info">Ejecutivo: {{name}}</span>
        </div>
      </div>
    </div>
  </div>

</div>
