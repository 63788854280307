@if(step == 1) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Declaración jurada'">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">antecedentes de la persona o estructura jurídica declarante</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep1">

          <mat-form-field>
            <mat-label>Domicilio</mat-label>
            <input type="text" class="text-capitalize" formControlName="direccion" matInput required />
            <mat-hint>Ingresa el domicilio de la persona o estructura jurídica</mat-hint>
            <mat-error>El domicilio es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Comuna</mat-label>
            <input type="text" class="text-capitalize" formControlName="comuna" matInput required />
            <mat-hint>Ingresa la comuna de residencia de la persona o estructura jurídica</mat-hint>
            <mat-error>La comuna es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ciudad</mat-label>
            <input type="text" class="text-capitalize" formControlName="ciudad" matInput required />
            <mat-hint>Ingresa la ciudad de residencia de la persona o estructura jurídica</mat-hint>
            <mat-error>La ciudad es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Teléfono (9 0000 0000)</mat-label>
            <input type="text" formControlName="telefono" matInput required maxlength="11"
              (input)="formatPhone($event)" />
            <mat-hint>Ingresa el teléfono de la persona o estructura jurídica</mat-hint>
            <mat-error>El teléfono es <strong>requerido</strong></mat-error>
          </mat-form-field>

        </form>

      </div>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary"
        [disabled]="!valFormStep1.valid || buttonLoading" (click)="putStep1()">
        Guardar datos
      </button>
    </div>


  </mat-dialog-actions>
</div>

} @else if(step == 2) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Declaración jurada'; valFormStep2.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">beneficiarios finales</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep2">

          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" class="text-capitalize" formControlName="nombre" matInput required />
            <mat-hint>Ingresa el nombre del beneficiario final</mat-hint>
            <mat-error>El nombre es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Domicilio</mat-label>
            <input type="text" class="text-capitalize" formControlName="direccion" matInput required />
            <mat-hint>Ingresa el domicilio del beneficiario final</mat-hint>
            <mat-error>La domicilio es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ciudad</mat-label>
            <input type="text" class="text-capitalize" formControlName="ciudad" matInput required />
            <mat-hint>Ingresa la ciudad de residencia del beneficiario final</mat-hint>
            <mat-error>La ciudad es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>País</mat-label>
            <input type="text" class="text-capitalize" formControlName="pais" matInput required />
            <mat-hint>Ingresa el país de residencia del beneficiario final</mat-hint>
            <mat-error>El país es <strong>requerido</strong></mat-error>
          </mat-form-field>

        </form>

      </div>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary" [disabled]="!valFormStep2.valid || buttonLoading"
        (click)="putStep2()">
        Guardar datos
      </button>
    </div>


  </mat-dialog-actions>
</div>

} @else if(step == 3) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Declaración jurada'; valFormStep3.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">persona que ejerce control efectivo de la persona o estructura jurídica declarante
      </div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep3">

          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" class="text-capitalize" formControlName="nombre" matInput required />
            <mat-hint>Ingresa el nombre de la persona que ejerce control efectivo</mat-hint>
            <mat-error>El nombre es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Domicilio</mat-label>
            <input type="text" class="text-capitalize" formControlName="direccion" matInput required />
            <mat-hint>Ingresa el domicilio de la persona que ejerce control efectivo</mat-hint>
            <mat-error>La domicilio es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ciudad</mat-label>
            <input type="text" class="text-capitalize" formControlName="ciudad" matInput required />
            <mat-hint>Ingresa la ciudad de residencia de la persona que ejerce control efectivo</mat-hint>
            <mat-error>La ciudad es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>País</mat-label>
            <input type="text" class="text-capitalize" formControlName="pais" matInput required />
            <mat-hint>Ingresa el país de residencia de la persona que ejerce control efectivo</mat-hint>
            <mat-error>El país es <strong>requerido</strong></mat-error>
          </mat-form-field>

        </form>

      </div>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary" [disabled]="!valFormStep3.valid || buttonLoading"
        (click)="putStep3()">
        Guardar datos
      </button>
    </div>


  </mat-dialog-actions>
</div>

} @else if (documentType === 'Declaración jurada') {
<div *ngIf="isLoading" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoading">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 20px;">

      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
      <div class="titulo-onboarding">
        actualiza la Declaración jurada para la identificación de beneficiariosfinales de personas y/o estructuras
        jurídicas
      </div>
      <div class="subtitulo-onboarding">Selecciona la categoría y edita los campos que necesitas actualizar.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <div style="display: flex; align-items: center;">
          <div class="container-cuenta">
            <mat-accordion multi>

              <!-- STEP 1 -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true" disabled (click)="step = 1" class="expansion-responsive">
                  <mat-expansion-panel-header class="expansion-responsive">
                    <mat-panel-title>
                      <div
                        [ngClass]="documentStatus.antecedentes_declarante ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i class="fa-light"
                          [ngClass]="documentStatus.antecedentes_declarante ? 'fa-circle-check' : 'fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Antecedentes de la persona o estructura jurídica declarante</div>
                    </mat-panel-title>
                    <span (click)="step = 1">
                      <i class="toggle-icon"
                        [ngClass]="documentStatus.antecedentes_declarante ? 'fa-regular fa-sharp fa-light fa-pen' : 'fa-regular fa-chevron-right'"></i>
                    </span>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>

                <span
                  matTooltip="Son personas y estructuras jurídicas (PJ) que cumplen los requisitos de la Circular Nº 57/2017 de la Unidad de Análisis Financiero."
                  matTooltipPosition="left" matTooltipClass="tooltip-doc">
                  <i class="fa-light fa-circle-info info-icon"></i>
                </span>
              </div>

              <!-- STEP 2 -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div
                        [ngClass]="documentStatus.beneficiarios_finales ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i
                          [class]="documentStatus.beneficiarios_finales ? 'fa-light fa-circle-check' : 'fa-light fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Beneficiarios finales</div>
                    </mat-panel-title>
                    <span>
                      <i [class]="documentStatus.beneficiarios_finales ? 'fa-regular fa-sharp fa-light fa-pen toggle-icon' : 'fa-regular fa-chevron-right toggle-icon'"
                        [class.expanded]="panel.expanded" (click)="panel.toggle()"></i>
                    </span>
                  </mat-expansion-panel-header>

                  <mat-form-field class="editable-field" *ngFor="let beneficiario of beneficiarios">
                    <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                      <i class="fa-light fa-user"></i>
                    </span>
                    <input type="text" class="text-capitalize" [value]="beneficiario.nombre" matInput readonly>
                    <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;"
                      (click)="selectBeneficiario(beneficiario)">
                      <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i>
                    </span>
                  </mat-form-field>

                  <div style="display: flex; align-items: center; margin-top: 16px;" (click)="step = 2">
                    <span style="padding-left: 12px; padding-right: 5px; font-size: 20px;">
                      <i class="fa-light fa-circle-plus"></i>
                    </span>
                    <span *ngIf="!documentStatus.beneficiarios_finales">Agregar beneficiario</span>
                    <span *ngIf="documentStatus.beneficiarios_finales">Agregar otro beneficiario</span>
                  </div>

                </mat-expansion-panel>
                <span
                  matTooltip="Son personas naturales que tienen una participación igual o mayor al 10% en la persona o estructura jurídica declarante."
                  matTooltipPosition="left" matTooltipClass="tooltip-doc">
                  <i class="fa-light fa-circle-info info-icon"></i>
                </span>
              </div>

              <!-- STEP 3 -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div [ngClass]="documentStatus.control_efectivo ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i
                          [class]="documentStatus.control_efectivo ? 'fa-light fa-circle-check' : 'fa-light fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Control efectivo</div>
                    </mat-panel-title>
                    <span>
                      <i class="toggle-icon"
                        [ngClass]="documentStatus.control_efectivo ? 'fa-regular fa-sharp fa-light fa-pen' : 'fa-regular fa-chevron-right'"></i>
                    </span>
                  </mat-expansion-panel-header>

                  <mat-form-field class="editable-field" *ngFor="let control of controlEfectivo">
                    <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                      <i class="fa-light fa-user"></i>
                    </span>
                    <input type="text" class="text-capitalize" [value]="control.nombre" matInput readonly>
                    <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;"
                      (click)="step = 3; selectControlEfectivo(control)">
                      <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i>
                    </span>
                  </mat-form-field>


                  <div style="display: flex; align-items: center; margin-top: 16px;" (click)="step = 3">
                    <span style="padding-left: 12px; padding-right: 5px; font-size: 20px;">
                      <i class="fa-light fa-circle-plus"></i>
                    </span>
                    <span *ngIf="!documentStatus.beneficiarios_finales">Agregar control efectivo</span>
                    <span *ngIf="documentStatus.beneficiarios_finales">Agregar otro control efectivo</span>
                  </div>

                </mat-expansion-panel>
                <span
                  matTooltip="Son personas naturales que ejercen control efectivo de la persona o estructura jurídica declarante, sin importar si su paticipación (directa o indirecta) es inferior al 10%."
                  matTooltipPosition="left" matTooltipClass="tooltip-doc">
                  <i class="fa-light fa-circle-info info-icon"></i>
                </span>
              </div>

              <!-- STEP 4 -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div [ngClass]="documentStatus.persona_natural ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i
                          [class]="documentStatus.persona_natural ? 'fa-light fa-circle-check' : 'fa-light fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Antecedentes de la persona natural</div>
                    </mat-panel-title>
                    <span>
                      <i class="toggle-icon"
                        [ngClass]="documentStatus.persona_natural ? 'fa-regular fa-sharp fa-light fa-pen' : 'fa-regular fa-chevron-right'"></i>
                    </span>
                  </mat-expansion-panel-header>

                  <form [formGroup]="valFormStep4">

                    <mat-form-field class="editable-field">
                      <mat-label>Relación con la persona o estructura jurídica</mat-label>
                      <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                        <i class="fa-light fa-building-columns"></i></span>
                      <input type="text" class="custom-placeholder text-capitalize"
                        formControlName="relacion_pj_declarante" matInput>
                      <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;">
                        <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i></span>
                      <mat-hint>Señala tu relación con la persona o estructura jurídica</mat-hint>
                      <mat-error>La persona jurídica declarante es <strong>requerida</strong></mat-error>
                    </mat-form-field>

                  </form>

                  <div style="padding-top: 20px;">
                    <div style="padding-top: 20px;">
                      <button type="button" mat-stroked-button class="primary-button" color="primary"
                        [disabled]="!valFormStep4 || buttonLoading" (click)="putStep4()">Guardar datos
                      </button>
                    </div>

                  </div>

                </mat-expansion-panel>
              </div>


            </mat-accordion>
          </div>

        </div>


      </div>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="secondary-button" color="primary" (click)="cerrarDialogo()">
        Volver
      </button>
      <!-- <button type="button" mat-stroked-button class="primary-button" color="primary"
        [disabled]="!allDocumentsCompleted" (click)="step = 5">Finalizar</button> -->
    </div>


  </mat-dialog-actions>
</div>
}