<div class="pagination" style="position: relative;">

  @if(totalPages > 0){
    <div class="position-pages">
      <span class="total-register-label-interno">Página {{currentPage}} de {{totalPages}}</span>
    </div> 
  }

  
  <div (click)="onPageChange(page)" *ngFor="let page of displayedPages" class="page-item page-item2 animate__faster animate__animated animate__backInRight" [class.active]="page === currentPage">
    <div class="page-link">
      {{ page }}
    </div>
  </div>

</div>
