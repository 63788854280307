import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
declare var require: any;
import Boost from 'highcharts/modules/boost';
import NoData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';

// Inicializa los módulos
Boost(Highcharts);
NoData(Highcharts);
More(Highcharts);
import { SelectionModel } from '@angular/cdk/collections';
import * as Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { PagoSeleccion } from '../../payments/pago-seleccion/pago-seleccion';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/settings/settings.service';;



@Component({
  selector: 'app-concentracion-factoring',
  templateUrl: './concentracion.factoring.html',
  styleUrls: ['./concentracion.factoring.less']
})
export class ConcentracionFactoring implements OnInit {
  @ViewChild('uploadResultPaginator', {read: MatPaginator}) uploadResultPaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('MatSort2', {read: MatSort}) sort2!: MatSort;
  firstFormGroup: FormGroup;

  classificationData:any[] = []
  elementData:any[] = [];
  deudores:any = {
    "deductions": [],
    "currency": "Monto"
  };

  cesiones:any = {
    "deductions": [],
    "currency": "Monto"
  };


  options: any = { 
    accessibility: {
      enabled: false
    },
    useHTML: true,
    caption: {
      useHMTL: true,
    },
    colors: [
      "#ff81f1",
      "#924eff",
      "#ff2982",
      '#0038e3', 
      '#b175ff', 
      '#002bbf', 
      '#ff9df4', 
      '#ff559f', 
      '#7a3fcc', 
      '#001f99', 
      '#cc68d8', 
      '#e60066', 
      '#c5a3ff',
      '#669fff', 
      '#ffa0e4'  
    ],
    chart: { 
      type: 'pie'
    },    
    title: { text: ''},
    tooltip:{
      pointFormat: '',
      useHTML: true,
      shadow: false,
    },
    plotOptions: { 
      pie: { 

     }
    },
    series: [{
      name: '',
      data: this.classificationData,

      dataLabels: {
        style: { },
      } 
    }, 
    
    {
      name: this.deudores.currency,
      data: [],
    }]
  }

  ELEMENT_DATA: any[]=[];
  displayedColumns: string[] = ['business_name','conteo','monto','porcentaje'];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  len_info: number = 0;
  @ViewChild('paginator') paginator!: MatPaginator;

  formFechas: FormGroup;
  cargando:Boolean=true
  info:Boolean=false
  infocesiones:Boolean=false;
  clave:number=0;

  valForm :FormGroup;

  constructor(private changeDetect:ChangeDetectorRef,private router: Router,private cd: ChangeDetectorRef,public settings: SettingsService, private fb: FormBuilder, public dialog:MatDialog,public snackBar: MatSnackBar) { 
    this.formFechas = this.fb.group({
      fechaI: ['', Validators.required],
      fechaF: ['', Validators.required]
    });

    this.valForm = fb.group({
      desde: '',
      hasta: '',
      filter:'',
    });

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      checkall:false
    });

  }

  myObserver:any

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  @Input() company_id:any;
  @Input() date_start:any;
  @Input() date_end:any;

  ngOnInit() {

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        console.log("cambio ruta")
        this.load_all();
      }
     });

     this.load_all()
  }

  load_all(){
    this.company_id = Number(localStorage.getItem("company_id"))

    setTimeout(() => {
      this.validarClave()
    });

    this.changeDetect.detectChanges()
  }

  update_dashboard(){
    this.load_all()
  }
  
  async validarClave() {
    try {
      let company_id = Number(localStorage.getItem("company_id"))
      const bd: any = await this.settings.get_query(1, 'ValidarClaveProveedoresConfirmig/'+company_id+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          if(body_data.status_clave==true){
            this.clave=1
          }
          else{
            this.clave=0
          }
          this.informacionSII()  
          this.informacionCesion()   


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  fecha_desde:any="";
  filter_chart1($e:any) {

    let dia_actual_ope = new Date($e.value);
    let a_y = dia_actual_ope.getFullYear();
    let a_m = dia_actual_ope.getMonth()+1;
    let a_d = dia_actual_ope.getDate();
    this.fecha_desde = a_y+"-"+a_m+"-"+a_d

    this.informacionSII()
    this.informacionCesion()

  }

  fecha_hasta:any="";
  mostrar_spinner:boolean=false;
  hay_data:boolean=false;
  filter_chart2($e:any) {
    let dia_ultimo_ope = new Date($e.value)
    let a_y_ul = dia_ultimo_ope.getFullYear();
    let a_m_ul = dia_ultimo_ope.getMonth()+1;
    let a_d_ul = dia_ultimo_ope.getDate();
    this.fecha_hasta = a_y_ul+"-"+a_m_ul+"-"+a_d_ul

      this.informacionSII()
      this.informacionCesion()
  }

  grafico_de_arriba:boolean=false;
  loading_compras:boolean=true;
  cantidad_compras:any=0;
  monto_compras:any=0;
  async informacionSII() {
    try {

      $("#container-participacion-deudores").hide()

      this.cargando = true;
      this.mostrar_spinner = true;
      this.loading_compras = true;

      let mensual = new Date();
      let start, end
      if(this.fecha_hasta == '' && this.fecha_desde == ''){
        start = new Date(mensual.getFullYear(), mensual.getMonth(), 1);
        end = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);   
  
        let dia_actual_ope = new Date(start)
        let a_y = dia_actual_ope.getFullYear();
        let a_m = dia_actual_ope.getMonth()+1;
        let a_d = dia_actual_ope.getDate();
        this.fecha_desde = a_y+"-"+a_m+"-"+a_d
  
        //ultimo dia
        let dia_ultimo_ope = new Date(end)
        let a_y_ul = dia_ultimo_ope.getFullYear();
        let a_m_ul = dia_ultimo_ope.getMonth()+1;
        let a_d_ul = dia_ultimo_ope.getDate();
        this.fecha_hasta = a_y_ul+"-"+a_m_ul+"-"+a_d_ul
      }

      const bd: any = await this.settings.get_query(1, 'ConcentracionProveedoresConfirmig/'+this.company_id+'/'+"?inicio="+this.date_start+"&fin="+this.date_end+"&clave="+this.clave);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

                this.cargando=false

                /************************Table*****************************/
                this.len_info=body_data.ranking.length
                this.ELEMENT_DATA = body_data.ranking;
                setTimeout(() => {
                  this.dataSource=new MatTableDataSource(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator
                  this.dataSource.sort = this.sort
                  this.dataSource._updateChangeSubscription();
                });

                if(body_data.grafico.length>0){
                  this.info=true
                  $("#container-participacion-deudores").show()
                  this.hay_data = true
                  this.grafico_de_arriba = true;
                }else{
                  this.info=false
                  $("#container-participacion-deudores").hide()
                  this.hay_data = false
                  this.grafico_de_arriba = false;
                }
                this.deudores.deductions=[]
          
                let deductions_total=0
                let totalFacturas=0
                for(let i=0; i< body_data.grafico.length; i++){
                  if(body_data.grafico[i].total == false){          
                    this.deudores.deductions.push({"ele": "<span style='font-weight: 200;font-size: 14px;color: #858489;font-family: DM Sans;'>"+body_data.grafico[i].business_name.substring(0, 20)+"</span> <br> <span style='font-family: 'DM Sans;font-size: 20px;'>"+body_data.grafico[i].porcentaje+" %</span>", "val": body_data.grafico[i].porcentaje})

                  }
                  else{
                    deductions_total = body_data.grafico[i].monto
                    totalFacturas = body_data.grafico[i].conteo
                  }
                }
          
                let array=[]
          
                for (let i = 0; i < this.deudores.deductions.length; i += 1) {
                  array.push({ "name": this.deudores.deductions[i].ele, "y": this.deudores.deductions[i].val});
                };
            
                this.classificationData[0] = {
                  name: "",

                  y: deductions_total,
                  color: 'white',
                  nrElements: this.deudores.deductions.length
                };

                this.cantidad_compras = totalFacturas;
                this.monto_compras = this.formatMoney(deductions_total);
                
                this.elementData=array
                this.options.series[1].data=this.elementData
          
                Highcharts.chart('container-participacion-deudores', this.options);
                this.mostrar_spinner = false;

                $("#disable_ventas").removeClass("disable_btn_llamativo");

                this.changeDetect.detectChanges()
                this.loading_compras = false;


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loading_compras = false;
      this.cargando=false
      this.info=false
      this.mostrar_spinner = false;
      this.ELEMENT_DATA = [];
    }
  }

  reset_filters_graficos(){
    this.formFechas.reset()
    this.fecha_desde = "";
    this.fecha_hasta = "";
    this.informacionSII()
    this.informacionCesion()
  }

  ELEMENT_DATA_CESIONES: any[]=[];
  displayedColumnsCesiones: string[] = ['factoring','rut','monto','porcentaje'];
  dataSourceCesiones = new MatTableDataSource<any>(this.ELEMENT_DATA_CESIONES);
  @ViewChild('paginatorCesiones') paginatorCesiones!: MatPaginator;
  no_tengo_datos:boolean=false;
  loading_cesiones:boolean=true;

  cant_cesiones:any=0;
  total_cesiones:any=0;
  len_info_cesiones:any=0;
  async informacionCesion() {
    try {

      this.mostrar_spinner = true;
      this.loading_cesiones = true;
      let mensual = new Date();
      let start, end
      if(this.fecha_hasta == '' && this.fecha_desde == ''){
        start = new Date(mensual.getFullYear(), mensual.getMonth(), 1);
        end = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);   
  
        let dia_actual_ope = new Date(start)
        let a_y = dia_actual_ope.getFullYear();
        let a_m = dia_actual_ope.getMonth()+1;
        let a_d = dia_actual_ope.getDate();
        this.fecha_desde = a_y+"-"+a_m+"-"+a_d
  
        //ultimo dia
        let dia_ultimo_ope = new Date(end)
        let a_y_ul = dia_ultimo_ope.getFullYear();
        let a_m_ul = dia_ultimo_ope.getMonth()+1;
        let a_d_ul = dia_ultimo_ope.getDate();
        this.fecha_hasta = a_y_ul+"-"+a_m_ul+"-"+a_d_ul
      }

      const bd: any = await this.settings.get_query(1, 'ranking-cesionario/'+this.company_id+'/'+"?inicio="+this.date_start+"&fin="+this.date_end);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

                this.cargando=false


                this.ELEMENT_DATA_CESIONES = body_data.resumen;
                this.len_info_cesiones = body_data.resumen.length;

                this.checkFirstPage()

                setTimeout(() => {
                  this.dataSourceCesiones=new MatTableDataSource(this.ELEMENT_DATA_CESIONES);
                  this.dataSourceCesiones.paginator = this.paginatorCesiones;
                  this.dataSourceCesiones.sort = this.sort2
                  this.dataSourceCesiones._updateChangeSubscription();
                });


                if(body_data.resumen.length>0){
                  this.infocesiones=true
                  //$("#container-participacion-cesiones").show()
                  this.hay_data = true
                  this.no_tengo_datos = true;
                }else{
                  this.infocesiones=false
                  $("#container-participacion-cesiones").hide()
                  this.hay_data = false
                  this.no_tengo_datos = false
                  console.log("no hya data,", this.no_tengo_datos)
                }
                this.cesiones.deductions=[]
          
                let deductions_total=0
                let totalFacturas=0
                for(let i=0; i< body_data.resumen.length; i++){
                  if(body_data.resumen[i].total == false){          
                    this.cesiones.deductions.push({"ele": "<span style='font-weight: 200;font-size: 14px;color: #858489;font-family: DM Sans;'>"+body_data.resumen[i].factoring+"</span> <br> <span style='font-family: 'DM Sans;font-size: 20px;color: #1b0943;'>"+body_data.resumen[i].porcentaje+" %</span>", "val": body_data.resumen[i].porcentaje})

                  }
                  else{
                    deductions_total = body_data.resumen[i].monto
                    totalFacturas = body_data.resumen[i].conteocesiones
                  }
                }
          
                let array=[]
          
                for (let i = 0; i < this.cesiones.deductions.length; i += 1) {
                  array.push({ "name": this.cesiones.deductions[i].ele, "y": this.cesiones.deductions[i].val});
                };
            
                // this.classificationData[0] = {
                //   name: "",

                //   y: deductions_total,
                //   color: 'white',
                //   nrElements: this.cesiones.deductions.length
                // };
                
                this.elementData=array
                this.options.series[1].data=this.elementData;

                this.cant_cesiones= this.anotherArray[0].conteocesiones;
                this.total_cesiones=this.formatMoney(this.anotherArray[0].monto);

          
                Highcharts.chart('container-participacion-cesiones', this.options);

                $("#disable_cesion1").removeClass("disable_btn_llamativo");
                $("#disable_cesion2").removeClass("disable_btn_llamativo");

                this.mostrar_spinner = false;

                this.loading_cesiones = false;
                this.changeDetect.detectChanges()


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando=false
      this.infocesiones=false
      this.mostrar_spinner = false;
      this.loading_cesiones = false;
      this.ELEMENT_DATA_CESIONES = []

      $("#disable_cesion1").removeClass("disable_btn_llamativo");
      $("#disable_cesion2").removeClass("disable_btn_llamativo");
    }
  }

  anotherArray: any[] = [];
  checkFirstPage() {

    if (this.ELEMENT_DATA_CESIONES.length > 0) {

      let removedRow = this.ELEMENT_DATA_CESIONES.pop();

      if (removedRow !== undefined) {
        this.anotherArray.push(removedRow);
      }
    }
    
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 
  //0 no, 1 si
  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];

  excel_download=0;
  ELEMENT_DATA_FACTURAS: any[]=[];
  displayedColumnsFacturas: string[] = ['select','folio','ultimorutacreedor','ultimoacreedor','receptoracreedor__business_name','mnt_total','fecha_emision','fecha_vencimiento','estado', 'pagado', 'status'];
  dataSourceFacturas = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);
  @ViewChild('paginatorCompras') paginatorCompras!: MatPaginator;

  desde='';
  hasta='';
  filter='';
  infotable:boolean=false;

  total_seleccionado:string="Total"
  total_tabla=0;
  show_msg_acreedor_legal:boolean=false;
  async load_table() {
    try {

      this.show_msg_acreedor_legal = false;
      this.disabled = true;

      const bd: any = await this.settings.get_query(1, 'ConfirmigCompras/'+this.company_id+'/?excel=' + this.excel_download + '&pageNumber=' + this.page + '&fecha_ini=' +this.date_start + '&fecha_fin=' + this.date_end + '&filter=' + this.filter + '&ordenamiento=' + this.ordenamiento + '&columna=' + this.columna);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.ELEMENT_DATA_FACTURAS = body_data.facturas;
        this.length = body_data.lenFacturas;
        this.disabled = false;
        let sma = 0
        if(this.arr_documentos_to_simulate.length > 0){
          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            sma = sma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = sma;
        }else{
          this.total_total = body_data.montoTotal;
          this.total_tabla = body_data.montoTotal;
        }

        if(this.ELEMENT_DATA_FACTURAS.length >0){
          this.infotable = true;
        }else{
          this.infotable = false;
        }

        let is_all = true;

        for (let i = 0; i < body_data.facturas.length; i++) {
          if (body_data.facturas[i].historialAcreedores.length > 0) {
            this.show_msg_acreedor_legal = true;
          }
          if (this.arr_simulation.includes(body_data.facturas[i].id)) {

            body_data.facturas[i]['checked'] = true
            this.selection.isSelected(body_data.facturas[i])
            
          } else {
            body_data.facturas[i]['checked'] = false
            if (!body_data.facturas[i].pagado) {
              is_all = false;
            }            
          }
        }

        this.checkall = is_all;
        this.firstFormGroup.controls['checkall'].setValue(is_all);
        

        setTimeout(() => {
          this.dataSourceFacturas=new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
          this.dataSourceFacturas._updateChangeSubscription();
        });


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  pageCustom($e:any) {
    this.page = $e.pageIndex + 1;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.load_table();


  }

  columna = "";
  ordenamiento = "";
  sortData($e:any) {
    this.columna = $e.active;
    this.ordenamiento = $e.direction;
    this.load_table()

  }

  disabled:boolean=false;
  async exportar() {
    try {
      this.disabled = true;

      const bd: any = await this.settings.get_query(1, 'ConfirmigCompras//'+this.company_id+'/?excel=' + '1' + '&pageNumber=' + this.page + '&fecha_ini=' +this.date_start + '&fecha_fin=' + this.date_end + '&filter=' + this.filter);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.disabled= false;

        window.open(body_data.nameArchivo, "blank")

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  async sincronizar() {
    try {
      let value = {}
      this.disabled = true;

      const bd: any = await this.settings.get_query(2, 'sii-empresa/'+this.company_id+'/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


            this.open_input_dialog("Sincronización", "Sincronización Exitosa", 
            "¡Todo listo! Nos hemos sincronizado con el SII",
            "Hemos actualizado los ultimos 6 meses con tus libros de compras, ventas y notas de credito!","sync","#3BA46F")

            this.informacionSII()
            this.informacionCesion()

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.disabled= false;
    }
  }

  //DIALOG INPUT
  open_input_dialog(title:any,subtitle:any,mensajeprincipal:any,mensajenegrita:any,icon:any,coloricon:any){

  console.log("se abrio dialog")
  const dialogRef = this.dialog.open(InputDialog,{
    width:'700px',
    autoFocus: false,
  });
  dialogRef.componentInstance.title = title;
  dialogRef.componentInstance.subtitle = subtitle;
  dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
  dialogRef.componentInstance.mensajenegrita = mensajenegrita;
  dialogRef.componentInstance.icon = icon;
  dialogRef.componentInstance.coloricon = coloricon;


  dialogRef.afterClosed().subscribe((result:any) => {
    this.load_table()
  });

  }

  filter1($e:any) {

    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    this.desde = year + '-' + mes + '-' + dia;

    this.load_table()

  }

  filter2($e:any) {
      let date = new Date($e.value);
      let mes = date.getMonth() + 1;
      let dia = date.getDate();
      let year = date.getFullYear();
      this.hasta = year + '-' + mes + '-' + dia;

      this.load_table()
  }

  filtrar(){
    this.filter = this.valForm.controls['filter'].value;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    if(this.filter == null){
      this.filter = "";
    }
      this.load_table();
  }

  reset(filter:any){
    if(filter == ""){
      this.filter = "";
      this.load_table()
    }
  }

  reset_filters(){
    this.filter = "";
    this.desde = "";
    this.hasta = "";
    this.columna = "";
    this.ordenamiento = "";
    this.valForm.reset()
    this.load_table()
  }

  selection = new SelectionModel<Facturas>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturas.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event:any) {
    console.log($event)
    !$event.checked  ?
    this.selection.clear() :
    this.dataSourceFacturas.filteredData.forEach(row => {
      if (!row.pagado){
        this.selection.select(row)
      }
    });
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  arr_documentos_to_simulate:any[] = [];
  arr_simulation:any[] = []
  disabled_enviar:boolean=true;
  total_total=0;
  insertar_vencimiento:boolean=false;
  activa(id:any, nombre:any, $e:any, objeto:any, index:any) {



    if ($e) $e.stopPropagation();

    console.log("objeto", objeto)


        const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);

        if (obje === undefined) {
          this.arr_documentos_to_simulate.push(objeto);
        } else {
          this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
        }

        if (this.arr_simulation.includes(id)) {
          this.arr_simulation.splice(this.arr_simulation.indexOf(id), 1);
        } else {
          this.arr_simulation.push(id);
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.insertar_vencimiento = true;
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }
  }

  se_activo_check:boolean=false;
  activa_final(){
    this.checkall =  this.firstFormGroup.controls['checkall'].value;

    if(this.checkall == false){


        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
          if (!this.dataSourceFacturas.filteredData[i].pagado){
            if (this.arr_simulation.indexOf(this.dataSourceFacturas.filteredData[i].id) == -1){
              this.arr_simulation.push(this.dataSourceFacturas.filteredData[i].id)
            }
            if (this.arr_documentos_to_simulate.indexOf(this.dataSourceFacturas.filteredData[i]) == -1){
              this.arr_documentos_to_simulate.push(this.dataSourceFacturas.filteredData[i])
            }
          }
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento= true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }


    
    }else if(this.checkall == true){

        // this.arr_simulation = []
        // this.arr_documentos_to_simulate = [];
        this.selection.clear()

        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
          this.dataSourceFacturas.filteredData[i].checked = false;
          if (!this.dataSourceFacturas.filteredData[i].pagado){
            this.arr_simulation.splice(this.arr_simulation.indexOf(this.dataSourceFacturas.filteredData[i].id), 1);
            this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(this.dataSourceFacturas.filteredData[i]), 1);
          }
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento = true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    }

    console.log("check all", this.arr_documentos_to_simulate)

  }

  seleccionar() {
    console.log(this.arr_documentos_to_simulate)
    var deuda=0;
    for(let i in this.arr_documentos_to_simulate){
      deuda = deuda + this.arr_documentos_to_simulate[i].mnt_total
    }

    let folios_map = []

    folios_map =  this.arr_documentos_to_simulate.map((factura) => {
      return factura.folio
    });

    let folios = folios_map.join(",");

    console.log(folios);

    this.aceptacionReclamo(folios, true)
  }

  seleccionar_2(){
    
    var deuda=0;
    for(let i in this.arr_documentos_to_simulate){
      deuda = deuda + this.arr_documentos_to_simulate[i].mnt_total
    }

    const dialogRef = this.dialog.open(PagoSeleccion, {
      maxHeight: '80vh',
      width:'1300px',
      disableClose: true
    });

   

    dialogRef.componentInstance.facturas = this.arr_documentos_to_simulate;
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.componentInstance.deuda = deuda

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log('The dialog was closed');

        this.valForm.reset()
        this.insertar_vencimiento = false;
        this.arr_documentos_to_simulate = [];
        this.arr_simulation = []
        this.disabled_enviar = true;
        this.se_activo_check = false;
        this.checkall = false;
        this.firstFormGroup.controls['checkall'].setValue(false);
        this.total_seleccionado = "Total"
        this.load_table()

    });
  }

  dash_update(){
    console.log("call scraping to concentracion")
    this.scrapping();
  }

  async scrapping() {

    this.mostrar_spinner = true;

    this.cargando = true; 
    $("#disable_cesion1").addClass("disable_btn_llamativo");
    $("#disable_cesion2").addClass("disable_btn_llamativo");
    $("#disable_ventas").addClass("disable_btn_llamativo")

    try {
      const bd: any = await this.settings.get_query(1, 'get-libro-confirming/'+this.company_id+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.validarClave()


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando = false;
      this.mostrar_spinner = false;
    }
  }

  checkall:boolean=false;


  minDate:any = new Date()
  fecha_individual($e:any, index:any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.ELEMENT_DATA_FACTURAS[index]["fecha_vencimiento"] = date_set
    this.ELEMENT_DATA_FACTURAS[index]["dias"] = diffDays;


    this.put_table(index)

    // this.arr_documentos_to_simulate[index]["fecha_vencimiento"] = date_set
    // this.arr_documentos_to_simulate[index]["dias"] = diffDays;


  }

  fecha_todas($e:any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.arr_documentos_to_simulate){
        this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
        this.arr_documentos_to_simulate[i]["dias"] = diffDays


    }


    this.put_table()



  }

  capturar_fecha_por_dias($e:any){
      let dias = ($e.target.value - 1);
      let new_date = this.addDays(Number(dias))
      let fecha_calculada = new Date(new_date)

      console.log("FECHA CALCULADA", fecha_calculada)
      this.firstFormGroup.controls['secondCtrl'].setValue(fecha_calculada)
      this.fecha_todas_por_dias(fecha_calculada)

  }

  addDays(days:any) {
    let fecha_hoy = new Date()
    let fecha_condias = new Date()
    fecha_condias.setDate(fecha_hoy.getDate()+days);
    return fecha_condias;
  }

  fecha_todas_por_dias(date:any){
      var date = date
      let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

      if (d_o_w == 0 || d_o_w == 6)
        date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

      let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      for(var i in this.arr_documentos_to_simulate){
          this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
          this.arr_documentos_to_simulate[i]["dias"] = diffDays

      }

      this.put_table()




  }

  async put_table(index?:any) {
    try {

      let value = {}
      if(index != undefined && index != null){
            value = {'facturas': [this.ELEMENT_DATA_FACTURAS[index]]}
      }else{
            value = {'facturas':this.arr_documentos_to_simulate}
      }

      const bd: any = await this.settings.get_query(3, 'ConfirmigCompras/'+this.company_id +'/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.load_table()


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  tabs($e:any) {
    // tab carga manual
    if ($e == 1) {
      this.new_row()
      this.delete_row()
    }
  }

  //CARGA MANUAL
  private hotRegisterer = new HotTableRegisterer();
  id = 'hotInstance';
  hotSettings: Handsontable.default.GridSettings = {
      startRows: 10,
      colHeaders: true,
      //colWidths: [150,200,150,150],
      rowHeights: [25],
      width: '99%',
      height: '100px',
      columns: [
        {
          type: 'numeric'
        }
      ],

  }
  data: any[] = [
    [null, null,null,null,null, null]
  ]
  
  new_row(){
    this.hotRegisterer.getInstance(this.id).alter('insert_row_below', this.data.length+1)
    // this.reset_carga_manual()
  }

  delete_row(){
    try {
      this.hotRegisterer.getInstance(this.id).alter('remove_row', this.data.length+1) 
    } catch {

    } 
  }


  ELEMENT_DATA_VISTAPREVIA: any[]=[];
  displayedColumnsVistaPrevia: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
  dataSourceVistaPrevia = new MatTableDataSource<Facturas>(this.ELEMENT_DATA_VISTAPREVIA);
  @ViewChild('VistaPrevia') vistaprevia!: MatPaginator;

  //ERROR
  ELEMENT_DATA_VISTAPREVIA_ERROR: VISTAPREVIA[]=[];


  hay_facturas_con_error:boolean=true;
  async listoparaenviar() {

    this.ELEMENT_DATA_VISTAPREVIA = [];
    this.ELEMENT_DATA_VISTAPREVIA_ERROR = [];
    this.no_listo = true;
    this.mostrar_resumen_facturas = false;

    var index = this.data.indexOf("");

    if (~index) {
      this.data[index] = null;
    }


    let valid = this.validate_cargar_manual(this.data);

    if(valid == true){

      let value = {'datosRevisar':this.data}

      try {
        const bd: any = await this.settings.get_query(2, 'ComprasVentasManuales/'+this.company_id +'/?accion=0', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
              if (body_data.arrayFront.length == 0){
                this.snackBar.open("No existen facturas válidas", "Aceptar", {
                  duration: 10000,
                  panelClass: ['simpli-snack']
                });
                this.no_listo = false;
                return false;
              }

              for(let i in body_data.arrayFront){
                if(body_data.arrayFront[i].errorCompras == true){
                  this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i])
                }else if(body_data.arrayFront[i].errorCompras == false){
                  this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i])
                }
              }

              if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
                this.hay_facturas_con_error = true;
              }else{
                this.hay_facturas_con_error = false;
              }


              for(let i in this.ELEMENT_DATA_VISTAPREVIA){
                this.mostrar_resumen_facturas = true;
                this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
                if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
                    this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
                }

              }

              for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
                this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
                if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
                    this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
                }

              }

              let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

              this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

              setTimeout(() => {
                this.dataSourceVistaPrevia=new MatTableDataSource(array3);
                this.dataSourceVistaPrevia.paginator = this.vistaprevia;
                this.dataSourceVistaPrevia._updateChangeSubscription();
                this.no_listo = false;
              });

              $("#siguiente_step").trigger('click')
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }

      

    }else{

    }


  }

  async capture_change(column:any,index:any,value:any, tipo_error:any,folio?:any) {
    try {
      let valor_enviar = {'column':column, 'value':value,  'index':index}

      const bd: any = await this.settings.get_query(3, 'ComprasVentasManuales/'+this.company_id +'/?folio='+folio+'&accion=0',valor_enviar);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        if(body_data.error == false){
          if(body_data.id_date == true){
            let date = new Date(body_data.valor)
            this.ELEMENT_DATA_VISTAPREVIA[index][column] = date;
          }else{
            this.ELEMENT_DATA_VISTAPREVIA[index][column] = body_data.valor;
          }

          this.ELEMENT_DATA_VISTAPREVIA[index][tipo_error] = false;
        }

        if(this.ELEMENT_DATA_VISTAPREVIA[index]['errorCompras'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorFechaEmision'] == false && 
        this.ELEMENT_DATA_VISTAPREVIA[index]['errorFechaVencimiento'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorFolio'] == false &&
        this.ELEMENT_DATA_VISTAPREVIA[index]['errorMonto'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorRut'] == false){
          this.ELEMENT_DATA_VISTAPREVIA[index]['error'] = false;
        }

        this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  capture_date_error($e:any, index:any, column:any, tipo_error:any) {

    let value = new Date($e.value);
    let yy = value.getFullYear();
    let mm = value.getMonth()+1;
    let dd = value.getDate();
    let date = dd+'/'+mm+'/'+yy;
    this.capture_change(column,index,date, tipo_error)

  }

  capture_date_error2($e:any, index:any, column:any, tipo_error:any) {

    let value = new Date($e.value);
    let yy = value.getFullYear();
    let mm = value.getMonth()+1;
    let dd = value.getDate();
    let date = dd+'/'+mm+'/'+yy;
    this.capture_change(column,index,date, tipo_error)

  }

  no_continue:boolean=true;
  check_errors(array:any){
    this.no_continue = true;
    for(let i in array){
      if(array[i]['error'] == true){
          this.no_continue = true;
          break;
      }else{
          this.no_continue = false;
      }
    }
  }



  clone_array(arr:any)
  {
    let aa =  JSON.stringify(arr);
    return  JSON.parse(aa)
  }

  mostrar_resumen_facturas:boolean=false;
  no_listo:boolean=false;
  
  async descargar_file() {
    this.disabled_subir_file=true;    
    let resultado: any = (await this.settings.get_query(1, 'ComprasVentasManuales/' + this.company_id + '/'));
    let body_data = resultado._body;
    if (resultado) {
      window.open(body_data.nameArchivo, "blank")
      this.disabled_subir_file=false;
    }    
  }

  subir(){
    $("#file_but").trigger("click");
  }

  success:boolean=false;
  warning:boolean=false;
  disabled_subir_file:boolean=false;
  async subir_file($e:any) {

    this.no_listo = true;
    this.success=false;
    this.warning=false;
    this.disabled_subir_file=true;

    this.reset_tabla();

    let formData: FormData = new FormData();
    let file_input = $e.target.files;

    for(let i=0;i<file_input.length;i++){
      let name = file_input[i].name
      formData.append('cv_'+i, file_input[i], name);
    }

    let resultado: any = (await this.settings.get_query(2, 'ComprasVentasManuales/' + this.company_id + '/?accion=0', formData));
    let body_data = resultado._body;
    if (resultado) {
      try {
        if (body_data.error.length > 0) {
          this.warning = true;
          this.disabled_subir_file=false;
          $("#file_but").val("")
          this.snackBar.open("Hay algunos errores en los registros, verifique el formato en la Planilla.", "Aceptar", {
            duration: 10000,
            panelClass: ['simpli-snack']
          });
          return false;
        } else {
          this.success = true;
          this.reset_file()
        }
      } catch {
        this.success = true;
        this.reset_file()
      }

      if (body_data.arrayFront.length == 0) {
        this.warning = true;
        this.disabled_subir_file=false;
        $("#file_but").val("")
        return false;
      }
      

      for(let i in body_data.arrayFront){
        if(body_data.arrayFront[i].errorCompras == true){
          this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i])
        }else if(body_data.arrayFront[i].errorCompras == false){
          this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i])
        }
      }

      if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
        this.hay_facturas_con_error = true;
      }else{
        this.hay_facturas_con_error = false;
      }


      for(let i in this.ELEMENT_DATA_VISTAPREVIA){
        this.mostrar_resumen_facturas = true;
        this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
        if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
            this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
        }

      }

      for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
        this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
        if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
            this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
        }

      }

      
      let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

      this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

      



      setTimeout(() => {
        this.dataSourceVistaPrevia=new MatTableDataSource(array3);
        this.dataSourceVistaPrevia.paginator = this.vistaprevia;
        this.dataSourceVistaPrevia._updateChangeSubscription();
        this.mostrar_resumen_facturas = true;
      });

      $("#siguiente_step").trigger('click')
    }
    
    this.disabled_subir_file=false;
    $("#file_but").val("")

  }

  reset_file() {
    $("#file_but").val("")
    this.success=false;
    this.warning=false;   
    this.disabled_subir_file=false;
  }

  reset_tabla() {
    this.ELEMENT_DATA_VISTAPREVIA = []
    this.ELEMENT_DATA_VISTAPREVIA_ERROR = []

    this.dataSourceVistaPrevia=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA);
    this.dataSourceVistaPrevia.paginator = this.vistaprevia;
    this.dataSourceVistaPrevia._updateChangeSubscription();
  }

  async pagar(pagado:any, id_compra:any){
    if (pagado) {
      return false;
    }
    this.reset_selection()
    let resultado: any = (await this.settings.get_query(3, 'PagarCompras/' + id_compra + '/', {'pagado': !pagado}));
    let body_data = resultado._body;
    if (resultado) {
      if (!body_data.error) {
        this.load_table();
      }
    } 

  }

  reset_selection() {
    this.valForm.reset()
    this.insertar_vencimiento = false;
    this.arr_documentos_to_simulate = [];
    this.arr_simulation = []
    this.disabled_enviar = true;
    this.se_activo_check = false;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.total_seleccionado = "Total"
    this.load_table()
  }

  validate_cargar_manual(data:any) {
    let valid = false;

    if (data.length == 0) {
      this.no_listo = false;
      this.snackBar.open("Debe agregar al menos una factura.", "Aceptar", {
        duration: 10000,
        panelClass: ['simpli-snack']
      });
      return false;
    }

    let puntos = /\./gi;
    let pesos = /\$/gi;
    let spaces = /\ /gi;
    let guion = /\-/gi;
    var b = "-";
    var saltos = /(\r\n|\n|\r)/gm;
    var coma = /,/g;

    for(let i=0;i<data.length;i++){
      if(data[i][5] != null){
        data[i][5] = data[i][5].replace(saltos,"").trim()
        data[i][5] = data[i][5].replace(coma,"").trim()
        data[i][5] = data[i][5].replace(puntos,"")
        data[i][5] = data[i][5].replace(pesos,"")
        data[i][5] = data[i][5].replace(spaces,"")      
        data[i][5] = parseInt(data[i][5])
        data[i][5] = data[i][5].toString();
      }

      if(data[i][1] != null){
        data[i][1] = data[i][1].replace(saltos,"").trim()
        data[i][1] = data[i][1].replace(puntos,"")
        data[i][1] = data[i][1].replace(guion,"")
        data[i][1] = ([data[i][1].slice(0, -1), b, data[i][1].slice(-1)].join('')).toUpperCase();
      }

      if(data[i][0] != null){
        data[i][0] = data[i][0].replace(saltos,"").trim()
        data[i][0] = parseInt(data[i][0])
        data[i][0] = data[i][0].toString();
      }

      if(data[i][4] != null){
        data[i][4] =  data[i][4].replace(saltos,"").trim()
      }

      if(data[i][3] != null){
        data[i][3] =  data[i][3].replace(saltos,"").trim()
        data[i][3] = data[i][3].replace(spaces,"").trim()
      }

      if(
        data[i][0] == null 
        || data[i][1] == null 
        || data[i][3] == null 
        || data[i][5] == null
        || data[i][0] == "" 
        || data[i][1] == "" 
        || data[i][3] == "" 
        || data[i][5] == ""
        || !(Number.isInteger(parseInt(data[i][0]))) 
        || !(Number.isInteger(parseInt(data[i][5])))
        ){
        this.snackBar.open("El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.", "Aceptar", {
          duration: 5000,
          panelClass: ['simpli-snack']
        });
        valid = false;
        break;
      }else{
        valid = true;
      }
    }

    return valid;
  }

  async aceptacionReclamo(folio:any, is_masivo?:any) {
    // try {
      this.disabled = true;
      let resultado: any = (await this.settings.get_query(1, 'ppaceptacionyreclamo/' + this.company_id + '/?folios='+folio));
      let body_data = resultado._body;
      if (resultado) {
        console.log(body_data)
        let res = body_data.resultado;
        let folios_con_errores:any[] = []
        res.forEach((element:any) => {
          if (element.data != null) {
            if (element.data.tieneReclamos == true) {
              folios_con_errores.push(element.data.dhdrFolio);
            }
          }
        });
        if (is_masivo) {
          if (folios_con_errores.length > 0){
            let mensaje = "Intenta continuar sin esas facturas o seleciona nuevas facturas.";
            let ids_documentos_excluir = []

            for (let index = 0; index < this.arr_documentos_to_simulate.length; index++) {
              let element = this.arr_documentos_to_simulate[index];
              if (folios_con_errores.includes(element.folio)) {
                ids_documentos_excluir.push(index);
              }
            }

            for (let index = 0; index < ids_documentos_excluir.length; index++) {
              let i = ids_documentos_excluir[index];
              this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(this.arr_documentos_to_simulate[i]), 1);             
            } 

            this.arr_simulation = this.arr_documentos_to_simulate.map(factura => {return factura.id})            
            
            this.open_input_dialog("Información", "Facturas de compra", 
            "¡Oops! Las siguientes facturas fueron desmarcadas porque tienen reclamos: "+folios_con_errores.join(", "),
            mensaje,"highlight_off","#ff933b")
            this.load_table();
          } else {
            this.seleccionar_2();
          }
        } else {
          this.load_table();
        }
      }

  }

  open_credenciales(){
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false
    });
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.afterClosed().subscribe((result:any) => {
      this.scrapping()
    });
  }

}

export interface Facturas{  
  id:any,
  checked: any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  pagado: any,
  status: any
}

export interface VISTAPREVIA{  
  id:any,
  checked: any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  pagado: any,
  status: any
}
