import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Certificado } from './certificado-sii/certificado';
import { FacturadorEle } from './facturador/facturador';
import { ClaveSIIComponent } from './clave-sii/clavesii';
import { SettingsService } from '../settings/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
declare var $ :any;



@Component({
  selector: 'app-credenciales-sii',
  templateUrl: './credenciales-sii.html',
  styleUrls: ['./credenciales-sii.less']
})


export class CredencialesSII implements OnInit {
   
  @Input() id_company:any;
  @Input() se_abrio_como_dialogo = true;
  @Input() token:any;
  @Input() id_sim:any;
  @Input() parent:any;

  valForm: FormGroup;
  data: any;
  business_name:any="";

  constructor(public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService,private _formBuilder: FormBuilder,
    private changeDetector:ChangeDetectorRef ){


      this.valForm = this._formBuilder.group({
        clave_sii: [''],
        certificado_sii: [''],
        clave_certificado: [''],
        rut_autorizado: [''],
        facturador: [''],
      });

      this.token = localStorage.getItem('token');

  }

  ngOnInit() {

    console.log("llamando oninit")

    this.business_name = localStorage.getItem('business_name')

    console.log(this.business_name)

    this.credencialessii();
    this.status_carga();
    this.changeDetector.detectChanges()
  }



  vencio_certificado:boolean=false;
  rut:any;
  clave_pfx:any;
  clave_sii:any;
  file:any;

  success_sii:boolean=false;
  success_certificado:boolean=false;
  success_facturador:boolean=false;
  requiere_completar:boolean=false;
  fecha_vencimiento:any;

  es_mi_pyme:any=null;

  async credencialessii(){
    try {



      const bd: any = await this.settings.get_query(1, `cliente/obtener-credenciales-sii/${this.id_company}/`, );
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:



        this.data = body_data.success.info;



        this.rut = this.data.rut_autorizado
        this.clave_pfx = this.data.clave_pfx
        this.clave_sii = this.data.clave_sii
        this.vencio_certificado = this.data.activate_certificado;
        this.file = this.data.archivo_pfx;
        this.fecha_vencimiento = this.data.certificado_fecha;

        if(this.clave_sii != null && this.clave_sii != "" && this.clave_sii != undefined){
            this.valForm.controls['clave_sii'].setValue(this.clave_sii)
            this.success_sii = true;
        }

        if(this.file != null && this.file != "" && this.file != undefined && this.clave_pfx != null 
            && this.clave_pfx != "" && this.clave_pfx != undefined 
            && this.rut != null && this.rut != "" && this.rut != undefined){
            this.success_certificado = true;
            this.requiere_completar = false
        }else{
            this.requiere_completar = true
        }

        if(this.file != null && this.file != "" && this.file != undefined){
            this.valForm.controls['certificado_sii'].setValue(this.file)
        }


        if(this.data.facturador != null){
            this.valForm.controls['facturador'].setValue(this.data.facturador.name)
            this.success_facturador = true;


            if(this.data.facturador.name == "MiPyme"){
              this.es_mi_pyme = true;
            }else{
              this.es_mi_pyme = false;
            }

        }



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  //CLAVE SII
  dialogSii:any;
  change_sii() {
    this.dialogSii = this.dialog.open(ClaveSIIComponent,{
      width: '600px',
      minHeight: '630px',
      autoFocus: false,
    });
    this.dialogSii.componentInstance.id_company = this.id_company
    this.dialogSii.componentInstance.data = this.data;
    this.dialogSii.componentInstance.token = this.token;
    this.dialogSii.componentInstance.parent = this;


    this.dialogSii.afterClosed().subscribe((result:any) => {
      this.credencialessii()
    });
  }

  //Certificado
  change_certificado() {
    const dialogRef = this.dialog.open(Certificado,{
      width: '600px',
      minHeight: '630px',
      autoFocus: false,
    });
    dialogRef.componentInstance.id_company = this.id_company
    dialogRef.componentInstance.data = this.data;
    dialogRef.componentInstance.token = this.token;
    dialogRef.componentInstance.id_sim = this.id_sim;

    dialogRef.afterClosed().subscribe((result:any) => {
      this.credencialessii()
    });
  }



  //Facturador
  change_facturador() {
    const dialogRef = this.dialog.open(FacturadorEle,{
      width: '600px',
      minHeight: '630px',
      autoFocus: false,
    });
    dialogRef.componentInstance.id_company = this.id_company
    dialogRef.componentInstance.data = this.data
    dialogRef.componentInstance.token = this.token;


    dialogRef.afterClosed().subscribe((result:any) => {
      this.credencialessii();
    });
  }

  status_clave:boolean=false;
  loading:boolean=false;
  async status_carga() {
    try {
      this.loading = true;
      const bd: any = await this.settings.get_query(1,`/analisisSII/informacionConsultasSII/${this.id_company}/`);
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:

          this.status_clave = body_data.success.info.valid_clave;
          this.loading = false;

          break;
        default:

        break;
      }
    } catch (bd: any) {
      this.loading = false;
      switch (bd.status) {
        default:

        break;
      }
    }
  }

}

