<div class="empty-card" style="width: 80%;margin: auto;padding: 20px;">
  <small style="font-size: 15px;color: gray;">
    <div style="text-align: center;margin-bottom: 20px;">
      <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 30px;"></i>
    </div>
    <p>
      {{message}}
    </p>
    <p>
      {{submessage}}
    </p>
    <br />
    <br />
    <div style="text-align: center;" >


      <button mat-mini-fab matTooltip="Cargar nómina"  [disabled]="disabled_download_file" class="primary-button" (click)="parent.openDialogFile()" type="button">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>

  </small>

</div>