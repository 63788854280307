
<div class="row">
    <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <mat-card class="animate__animated animate__fadeIn" style="border: none;box-shadow: none;height: 500px;">

            <mat-card-content>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;position: inherit;">
                    <h1 class="titulo-simpli">Detalle de Cesiones</h1>
                    <small class="subtitulo-simpli">Consulta el detalle de tus cesiones</small>
                </div>




                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                    <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
                            <mat-form-field appearance="fill">
                                <input type="text" placeholder="Factoring" aria-label="Number" matInput [(ngModel)]="factoringSelected" (input)="searchInput()" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)='filterDataTableSource($event.option.value)'>
                                    <mat-option *ngFor="let factoring of listInputFac" [value]="factoring">
                                        {{factoring.factoringName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
                            <mat-form-field appearance="fill">
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="#docto, fecha emisión, fecha vencimiento">
                            </mat-form-field>
                        </div>

                    </div>


                
                
                    <app-tabla-detalle [showSpinner]='showSpinner' [tableDataCesiones]="dataTableSource" [filterValue]='filterDeudores'></app-tabla-detalle>

                    

                </div>




            </mat-card-content>
          </mat-card>
    </div>
</div>

<mat-dialog-actions style="margin-top: 10px;margin-bottom: 10px;">

        <button mat-dialog-close mat-flat-button class="primary-button" type="button" style="margin-top: 10px;">Cerrar</button>

</mat-dialog-actions>