<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Pago de Remuneraciones</h1>
    <small class="subtitulo-simpli">Puedes cargar una nómina de colaboradores nueva. Si ya cargaste información, puedes editar la existente.</small>
  </div>




  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;">

    <ng-container *ngIf="showSpinner" >

      <div  class="velo-inactivo animate__animated animate__fadeIn center__items"  style="background: #ffffffde; height: 100%">
          <mat-spinner diameter="40" class="spinner-simpli" ></mat-spinner>
        <div class="show-m mt-1">Estamos cargando la información :)</div>
      </div>
    </ng-container>


    <mat-card class="shadow-box card-tabs">
      <mat-card-content>
        <div class="w-100">

          <ng-container *ngIf="!hasPreviousNomina && countDownload <= 0">
            <app-empty-message [message]="message"
              [submessage]="submessage" [parent]="this" [disabled_download_file]="disabled_download_file">
            </app-empty-message>
          </ng-container>

          
          <ng-container *ngIf="hasPreviousNomina">
            <app-previous-nomina [nomina]="nominaList" [accountType]="accountType" [bancos]="bancos"
              [company_id]="company_id" [showSpinner]="showSpinner"></app-previous-nomina>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>

  </div>



</div>