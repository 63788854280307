import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';

@Component({
  selector: 'loading-interno',
  templateUrl: './loading-interno.html',
  styleUrls: ['./loading-interno.scss'],
})


export class LoadingInterno implements OnInit {



  constructor(
    private settings:SettingsService, 
  ) { 




  }





  ngOnInit(): void {



    
  }




}

