<div class="row row_main" >



    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli">Dashboard</h1>
        <small class="subtitulo-simpli">Revisa como se comportan tus ventas de tu empresa mes a mes. </small>

    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

      <div class="row">

        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12" style="margin:0 auto;margin-top: 20px;">

          <div class="container-box">

            <div class="neumorphism2 excelbox" style="pointer-events: none;background: var(--simpli-primary);">
              <i style="color: var(--simpli-secondary);" class="fa-light fa-chart-line excelicon"></i>
              <br>
              <small style="color: var(--simpli-secondary);">Dashboard ventas</small>
            </div>
    
            <div class="neumorphism2 excelbox" routerLink="/home/dashboard-compras">
              <i class="fa-light fa-filter-circle-dollar excelicon"></i>
              <br>
              <small>Dashboard compras</small>
            </div>
    
            <div class="neumorphism2 excelbox" (click)="dash_update()">
              <i class="fa-light fa-arrows-rotate excelicon"></i>
              <br>
              <small>Sincronizar información</small>
            </div>
    
            <div class="neumorphism2 excelbox" (click)="dicom()">
              <i class="fa-light fa-down-from-dotted-line excelicon"></i>
              <br>
              <small>Consulta Dicom</small>
            </div>
    
          </div>

        </div>

      </div>




    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <form [formGroup]="formFechas" style="margin-top: 29px;" >
        <div class="row row_main" style="padding-left: 0;">
          <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-top">
            <mat-form-field>
              <mat-label>Desde</mat-label>
              <input matInput formControlName="fechaI"  [matDatepicker]="picker" (dateChange)="fecha_desde($event)" style="text-decoration: none !important;">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
  
          <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-top">
            <mat-form-field>
              <mat-label>Hasta</mat-label>
              <input matInput formControlName="fechaF"  [matDatepicker]="picker1" (dateChange)="fecha_hasta($event)" style="text-decoration: none !important;">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>          
          </div>

          <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-top">
            <button [disabled]="d_btn" mat-flat-button style="margin-top: 20px;" class="secondary-button" (click)="clear()" type="button">Restablecer Fechas</button>       
          </div>
  
        </div>
      </form>
    </div>
    


    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" style="position: relative;">

      <div id="super-cargando" class="velo-inactivo animate__animated animate__fadeIn supercargando" style="background: #ffffffde;height: 100%;">

        <mat-spinner diameter="40" class="spinner-simpli" style="margin-top: 200px;"></mat-spinner>

        <div class="show-m">
          Tus datos se están sincronizando con el SII. ¡En unos minutos simplificaremos tus finanzas!
        </div>
    
      </div>


        <div class="row">

            <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
              <dashboard-ventas [company_id]="company_id" [date_end]="date_end" [date_start]="date_start"></dashboard-ventas>
            </div>


        </div>

    </div>


</div>

