import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { EditarFacturasVentaComponent } from 'src/app/home/operaciones-proveedores/editar-facturas-venta/editar-facturas-venta.component';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.less']
})
export class VentasComponent {

  @Input() id:any;
  @Input() done:any;
  @Input() clicked:any;
  simulation_id:any;
  is_not_done:any;
  @Output() edited = new EventEmitter<any>();
  id_company:any;
  ventas:any;
  facturas:any;
  tipo = 0;
  simulation_venta={'anticipo':0,'vencimiento':0,'comision':0,'gastosOperacionales':0,'iva_operaciones':0,'interesTotal':0,'liquidoRecibir':0, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':0.95,'tasa_interes':0, 'gastos':0};
  ELEMENT_DATA_VENTAS: FacturasVenta[]=[];
  displayedColumnsVentas: string[] = ['folio','receptoracreedor_name','receptoracreedor_rut','mnt_total','fecha_emision','fecha_vencimiento'];
  dataSourceVentas = new MatTableDataSource<FacturasVenta>(this.ELEMENT_DATA_VENTAS);
  @ViewChild(MatPaginator) paginator_ventas!: MatPaginator;
  anticipo=95;
  comision=0;
  dias_promedio=30;
  gastos=0;
  interes_total=0;
  iva_operacion=0;
  monto_recibir=0;
  tasa_interes=0;
  total=0;
  valor_anticipo_total=0;
  cargando:boolean=true;
  deuda_total=0;
  por_pagar=0;
  debe_pagar:boolean=false;
  porc_anticipo=0;
  tasa_int=0.02;
  gastos_operacionales=30000;

  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  totalCompras = 0;

  valForm: FormGroup;
  filter = '';

  constructor(    
    private dialog: MatDialog,
    public settings: SettingsService, 
    public snackBar: MatSnackBar,
    fb: FormBuilder) { 

      this.valForm = fb.group({
        'filter': ['']
      });
  

  }

  ngOnChanges(changes: SimpleChanges) {
    const changedProp = changes['clicked'];
    const to = JSON.stringify(changedProp.currentValue);
    if (to === 'true') {            
      // simulation ventas
      this.simulation_id = this.id;
      this.is_not_done = this.done;
      this.load_all(false)  
    } 
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

  load_all(update:any)
  {
    this.id_company = localStorage.getItem("company_id");
    this.get_simulation(update)
  }

  async get_simulation(update:any){
    this.cargando = true;
    let resultado: any = (await this.settings.get_query(1, 'controldtedetailsimulation/'+this.simulation_id +'/?pageNumber=' + this.page + '&tipo='+this.tipo+'&accion=2'+'&filter='+this.filter));
    let body_data = resultado._body;
    if (resultado) {

      this.anticipo= body_data.simulation.anticipo;
      this.comision=body_data.simulation.comision;
      this.dias_promedio=body_data.simulation.dias_promedio;
      this.gastos=body_data.simulation.gastos;
      this.interes_total=body_data.simulation.interes_total;
      this.iva_operacion=body_data.simulation.iva_operacion;
      this.monto_recibir=body_data.simulation.monto_recibir;
      this.tasa_interes=body_data.simulation.tasa_interes;
      this.total=body_data.simulation.total;
      this.valor_anticipo_total=body_data.simulation.valor_anticipo_total;
      this.deuda_total = body_data.total_compras;
      this.porc_anticipo = (body_data.simulation.anticipo / 100);
      this.tasa_int = (body_data.simulation.tasa_interes / 100);
      this.gastos_operacionales = body_data.simulation.gastos;


      this.simulation_venta={'anticipo':this.valor_anticipo_total,'vencimiento':this.dias_promedio,'comision':this.comision,'gastosOperacionales':this.gastos,
      'iva_operaciones':this.iva_operacion,'interesTotal':this.interes_total,'liquidoRecibir':this.monto_recibir, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':this.porc_anticipo,
      'tasa_interes':this.tasa_int, 'gastos':this.gastos_operacionales};

      this.ELEMENT_DATA_VENTAS = body_data.ventas;

      this.ventas = body_data.ventas;
      this.facturas = body_data.facturas;

      if(Number(this.monto_recibir) > this.deuda_total){
        this.debe_pagar = true;
        this.por_pagar = Number(this.monto_recibir) - this.deuda_total;
      }else{
        this.por_pagar = this.deuda_total - Number(this.monto_recibir);
        this.debe_pagar = false;
      }

      this.length = body_data.total;

      setTimeout(() => {
        this.dataSourceVentas=new MatTableDataSource(this.ELEMENT_DATA_VENTAS);
        // this.dataSourceVentas.paginator = this.paginator_ventas;
        this.dataSourceVentas._updateChangeSubscription();
      });

      if (update) {
        this.edit();
      }

      this.cargando = false;

    }
  } 

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.get_simulation(false);
  }

  filtrar(){
    this.filter = this.valForm.controls['filter'].value;
    this.paginator_ventas.firstPage();
    this.get_simulation(false)
  }

  open_ventas(){
    const dialogRef = this.dialog.open(EditarFacturasVentaComponent, {
      width:'1300px',
      disableClose: true,
    });
    let total = this.facturas.reduce((acc:any, cv:any) => Number(cv.mnt_total) + acc , 0);
    dialogRef.componentInstance.id_simulation = this.simulation_id;
    dialogRef.componentInstance.deuda = 0;
    dialogRef.componentInstance.deuda_total = total;
    dialogRef.componentInstance.simulacion_recibida = this.simulation_venta;
    dialogRef.componentInstance.facturas_venta = this.ventas;
    dialogRef.componentInstance.facturas = this.facturas;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result==true){
        this.load_all(true)
      }
    });
  }

  async eliminar_venta(id_simulacion:any,id_factura:any,index:any) { 
    let resultado: any = (await this.settings.get_query(4, 'RecalculateVentas/' + id_simulacion + '/' + id_factura + '/'));
    let body_data = resultado._body;
    if (resultado) {
      if (body_data.error != null) {          
        // this.snackBar.open(body_data.error, "Aceptar", {
        //   duration: 7000,
        //   panelClass: ['JP-snackbar']
        // });

        this.open_input_dialog("Error", "", "¡Oops! "+body_data.error,"","info","#FA454C");
        this.cargando = false;
        return false;
      } 
      this.load_all(true) 
    }    
  }

  //DIALOG INPUT
  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

    const dialogRef = this.dialog.open(InputDialog,{
      width:'700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
  
  
    dialogRef.afterClosed().subscribe((result:any) => {
      // this.load_table()
    });
  
  }

  edit() {
    this.edited.emit({edited: true});
  }

}

export interface FacturasVenta {  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}