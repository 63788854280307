import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { CreateAccCompanyDialog } from '../../info/ctas-bancarias/ctas/createacc.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
declare var $ :any;

@Component({
  selector: 'app-detalle-nomina',
  templateUrl: './detalle-nomina.component.html',
  styleUrls: ['./detalle-nomina.component.less']
})
export class DetalleNominaComponent implements OnInit {

  simulation_id:any;
  is_not_done:any;

  cargando:boolean=true;
  simulation: any;
  has_accounts:boolean=false;
  accounts = []
  id_banco_selected: any;
  selected:any

  page:number=1
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];

  valForm :FormGroup;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }


  constructor(
    fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    public settings: SettingsService, 
    public snackBar: MatSnackBar) { 
      this.valForm = fb.group({
        filter: '',
        girado: '0,1,2'
      });
  }

  ngOnInit() {
    this.get_detalle()
  }

  pageCustom=($e: any)=>
  {
    this.page=$e.pageIndex + 1
    this.resetPag();
    this.get_detalle();
  }

  resetPag() {
    this.ELEMENT_DATA = []
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource._updateChangeSubscription();
  }

  filtrar() {
    this.resetPag();
    this.paginator.firstPage();
    this.get_detalle();
  }

  ELEMENT_DATA_GIRO = [];
  displayedColumnsGiro: string[] = ['cuentas','acreedorName','bankName','CuentaRut','CuentaName','NumberCuenta','totalGiro', 'status'];
  dataSourceGiro:any = new MatTableDataSource<any>(this.ELEMENT_DATA_GIRO);

  ELEMENT_DATA =[];
  displayedColumns: string[] = ['rut', 'name', 'number', 'bank', 'type', 'email', 'amount', 'status'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  url:any;
  total_tabla=0;
  async get_detalle(){
      this.cargando = true;
      let resultado: any = (await this.settings.get_query(1, `pagoRemuneraciones/${this.simulation_id}/?pageNumber=${this.page}&filter=${this.valForm.controls['filter'].value}&girado=${this.valForm.controls['girado'].value}`));
      let body_data = resultado._body;
      if (resultado) {

          this.simulation = body_data.simulation;
          
          this.ELEMENT_DATA_GIRO = body_data.giro;
          if (body_data.giro[0].cuentas.length == 0) {
            this.has_accounts = false;        
          } else {
            this.has_accounts = true;  
            this.accounts = body_data.giro[0].cuentas
          }
          this.id_banco_selected = body_data.giro[0].has_account; 
          

          this.ELEMENT_DATA = body_data.nomina;
          this.url = body_data.csv;

          let suma=0;
          for(let i in body_data.nomina){
            suma = suma + parseInt(body_data.nomina[i].amount)
          }

          this.total_tabla = suma;


          setTimeout(() => {

  
            this.dataSource=new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource._updateChangeSubscription();
            // this.dataSource.paginator = this.paginator;
            this.length=body_data.total;

            this.dataSourceGiro=new MatTableDataSource(this.ELEMENT_DATA_GIRO);
            this.dataSourceGiro._updateChangeSubscription();
            // this.dataSourceGiro.paginator = this.paginatorGiro;
  
          });

          this.cargando = false;
        }
    
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

  // download_csv(){
  //     window.open(this.url, "blank")
  // }

  // open_compras(){
  //   let dialogRef = this.dialog.open(EditarFacturasCompraComponent, {
  //     width: '1300px', 
  //   });
  //   dialogRef.componentInstance.id_simulacion = this.simulation_id;
  //   dialogRef.afterClosed().subscribe((result) => {
  //       if(result == true){
  //         this.get_detalle()
  //       }
  //   });
  // }

  // eliminar(id_compras){

  //   var endpoint = SettingsService.API_ENDPOINT + 'RecalculateCompras/' + this.simulation_id + '/?compras='+id_compras;
  //   let token = this.settings.get_token();
  //   const headers = new Headers();
  //   headers.append('Authorization', 'JWT ' + token);
  //   const options = new RequestOptions({ headers: headers });
  //   this.http.delete(endpoint, options).subscribe(
  //     (data: any) => {
  //
  
  //       console.log(body_data)

  //       if (body_data.error != null) {          
  //         this.snackBar.open(body_data.error, "Aceptar", {
  //           duration: 5000,
  //           panelClass: ['JP-snackbar']
  //         });
  //         return false;
  //       } 

  //       this.get_detalle()        


  //     },
  //     (error) => {
  //       this.cargando = false;
  //       //this.settings.empty_local_data();
  //     }
  //   );
  // }

  download_file(has_link:any){
    if (!has_link) {
      return false;
    }

    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = `${SettingsService.API_ENDPOINT}media/${has_link}`;
    link.click();
  }

  create_account(has_account:any)
  {
    let dialogRef = this.dialog.open(CreateAccCompanyDialog);
    dialogRef.componentInstance.id_company = this.simulation.company.id;
    // dialogRef.componentInstance.se_abrio_de_confirming = false;
    // dialogRef.componentInstance.se_abrio_de_giro_pp = true;
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        console.log(result)
        try {
          if (!has_account) {
            this.change_bank(null, result.address.id)
          } else {
            this.get_detalle();
          }       
        } catch {

        }
      }
    });   
  }

  async change_bank($event:any, id?:any) {
    this.cargando = true;
    let value = null;
    if (id) {
      value = id;
    } else {
      value = $event.value
    }
    let resultado: any = (await this.settings.get_query(3, 'cuentaGiroCliente/' + this.simulation_id + '/', {"cuenta": value}));
    let body_data = resultado._body;
    if (resultado) {
      this.get_detalle();
    }
  }

}
