import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;



@Component({
  selector: 'app-perfildeudor-iframe',
  template:`<iframe id="miframe" [src]="url" width="100%" height="100%" style="border:none;overflow-scrolling: touch;overflow-y: hidden;"></iframe>`,
})
export class PerfildeudorIframeComponent implements OnInit {
	id_company:any;
	url:any;
	pdfSrc:any;
  constructor(private sanitizer:DomSanitizer){}


  ngOnInit() {
	//?sw_frame=1&token=el.token.aqui&role=aqui.el.rol
	let token = localStorage.getItem('token');
	let role = localStorage.getItem('role');


	//http://18.224.64.20/portal-deudor//#/home/info-company/33627?sw_frame=1
  	//this.pdfSrc =`http://18.224.64.20/portal-deudor//#/home/info-company/${this.id_company}?sw_frame=1&token=${token}&role=${role}`
  	this.pdfSrc =`${SettingsService.lemu_base}/portal-deudor//#/home/info-company/${this.id_company}?sw_frame=1&token=${token}&role=${role}`
  	this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);


    // var iframe = document.querySelector('#miframe'); 
    //     iframe.setAttribute('src', this.url); 
    

  }

}
