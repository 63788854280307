import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AlertAnticipo } from '../start-simulation/alert-anticipo/alert-anticipo';
import { AlertSuccess } from '../start-simulation/alert-success/alert-success';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


declare var $: any;


@Component({
  selector: 'app-add-fecha-vencimiento',
  templateUrl: './add-fecha-vencimiento.html',
  styleUrls: ['./add-fecha-vencimiento.less']
})
export class addFechaVencimiento implements OnInit {



  @Input() id_company:any;
  @Input() facturas:any[]=[];

  @Input() id_sim:any;

  @Input() onboarding:boolean=false;

  valFormFilter!: FormGroup


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  obs!: Observable<any>;
  dataSource = new MatTableDataSource(this.facturas);

  constructor(public _MatPaginatorIntl: MatPaginatorIntl, private changeDetectorRef: ChangeDetectorRef,private dialogRef:MatDialogRef<addFechaVencimiento>,public settings: SettingsService, 
    public dialog: MatDialog,public fb:FormBuilder, public snackBar:MatSnackBar, private activatedRoute: ActivatedRoute) {

    this.id_company = localStorage.getItem('company_id');
    //this.activatedRoute.queryParamMap.subscribe((params:any) => this.facturas = params.getAll('facturas'));

    this.valFormFilter = this.fb.group({
      tipo_vencimiento: [null, Validators.compose([Validators.required])],
      fecha_vencimiento_utc: [null],
      fecha_vencimiento: [null],
      dias_vencimiento: [null],
    });

   }


  single_factura:boolean=false;
  monto_total=0;
  ngOnInit() {



    if(this.facturas.length == 1 || this.facturas.length > 10){
      this.single_factura = true;
    }else{
      this.single_factura = false;
    }

    var suma=0;
    for(let i in this.facturas){
      suma = suma + Number(this.facturas[i].monto);
    }

    this.monto_total = suma;

    $(window).scrollTop(0);

  }

  step:any=1;


  change_step(step_name:string){
    $(".stepper-simpli").removeClass('stepper-simpli-active');
    $("#"+step_name).addClass('stepper-simpli-active');

    switch(step_name) { 
      case 'step-simpli1': { 
         this.step = 1;

          break; 
      } 
      case 'step-simpli2': { 
        this.step = 2;
        this.set_vencimiento = 0;
         break; 
      } 
      case 'step-simpli3': { 
        this.step = 3;
        this.set_vencimiento = 0;
          break; 
     } 
      default: { 
        this.step = 1;
        this.set_vencimiento = 0;
          break; 
      } 
   } 
  }


  selected:any;
  statuschange($event:any){
    this.selected = Number($event.value);
  }

  minDate:any = new Date()
  change_date($event:any){
    let date = new Date($event.value);
    let yyyy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();

    var fecha_vencimiento = yyyy+'-'+mm+'-'+dd;
    this.valFormFilter.controls['fecha_vencimiento'].setValue(fecha_vencimiento);



  }

  fecha_todas($e:any){

    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    
    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.facturas){
        this.facturas[i]['fecha_vencimiento'] = date_set
        this.facturas[i]["dias"] = diffDays
    }




  }


  capturar_fecha_por_dias($e: any) {
    let dias = Number($e.target.value);
    
    if (dias < 1) dias = 1;
    if (dias > 360) dias = 360;
  
    let new_date = this.addDays(dias - 1);
    let fecha_calculada = new Date(new_date);
  
    this.fecha_todas_por_dias(fecha_calculada);
  }

  validate_days($event:any){
    let dias:any = Number($event.target.value);
    
    if (dias < 1) dias = null;
    if (dias > 360) dias = 360;

    this.valFormFilter.controls['dias_vencimiento'].setValue(dias);
  }

  addDays(days:any) {
    let fecha_hoy = new Date()
    let fecha_condias = new Date()
    fecha_condias.setDate(fecha_hoy.getDate()+days);
    return fecha_condias;
  }

  fecha_todas_por_dias(date:any){
    var date = date
    //ver si date es sabado o domingo y correr la fecha al siguiente lunes
    //date tiene q ser igual al siguiente lunes

    let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

    if (d_o_w == 0 || d_o_w == 6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.facturas){
        this.facturas[i]['fecha_vencimiento'] = date_set
        this.facturas[i]["dias"] = diffDays
    }


  }

  fecha_individual($e: any, index: any){


    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    this.facturas[index]["fecha_vencimiento"] = date_set
    this.facturas[index]["dias"] = diffDays



  }

  dias_individual($e:any, index:any){
    let dias = ($e.target.value - 1);
    let new_date = this.addDays(Number(dias))
    let fecha_calculada = new Date(new_date)

    this.set_dias_individual(fecha_calculada, index)
  }

  set_dias_individual(date:any, index:any){
    var date = date
    let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

    if (d_o_w == 0 || d_o_w == 6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

    let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.facturas[index]['fecha_vencimiento'] = date_set
    this.facturas[index]["dias"] = diffDays



  }

  desactivar_hasta:boolean=true;
  //SUBMIT ALL AND VALIDATE DATES
  async validate_date(){
    let error = []
    for(let i=0;i<this.facturas.length;i++){

      error.push(this.facturas[i]['fecha_vencimiento'])
    }


    if(error.includes(null) == true || error.includes(undefined) == true){

      this.snackBar.open('Todas las facturas deben tener la fecha de vencimiento indicada', 'Aceptar', {
        duration: 20000,
        panelClass: 'simpli-snack-error'
      });

    }else{

      try {

        this.wait_to_send = true;
  
        let data_to_send = {'facturas': this.facturas}
  
        const bd: any = await this.settings.get_query(3, '/cliente/simulacion-documentos/'+this.id_sim+'/', data_to_send);
        var body_data: any =bd._body;
        var data = body_data.success;
        switch (bd.status) {
          case 200:
  
            this.dialogRef.close(true)

            if(data.duplicadas == true){
              this.snackBar.open('¡Se detectaron facturas duplicadas! Para evitar confusiones, serán descartadas.', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack-alert'
              });
            }

            this.wait_to_send = false;

  
            const dialogRef = this.dialog.open(AlertSuccess,{
              width: '530px',
              disableClose: true,
              maxWidth: '95vw',
              autoFocus:false,
            });
  
            dialogRef.componentInstance.id_sim = this.id_sim;
            dialogRef.componentInstance.title = "¡Facturas agregadas con éxito!";
            dialogRef.componentInstance.subtitle = "Se volverá a evaluar la operación y el resultado será enviado a tu correo y dispositivo móvil."
            dialogRef.componentInstance.open_as_sim = true;
        
            dialogRef.afterClosed().subscribe((result:any) => {
        
            });
  
          break;
          default:
  
          break;
        }
  
      } catch (bd:any) {
  
        switch (bd.status) {
          case 500:
              this.wait_to_send = false;
              this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });

            break;
  
          case 400: 
          this.wait_to_send = false;
              this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });
            break;
          default:
              this.wait_to_send = false;
              this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                duration: 20000,
                panelClass: 'simpli-snack'
              });
  
          break;
        }
      }

    }


  }

  


  //SUBMIT SINGLE
  simulation:any={
    "total": 0,
    "tasa_interes": 0,
    "anticipo": 0,
    "comision": 0,
    "excedentes": 0,
    "valor_anticipo_total": 0,
    "iva_operacion": 0,
    "interes_total": 0,
    "monto_recibir": 0,
    "gastos": 0,
    "dias_promedio": 0,
    "tasa_efectiva": 0,
    "status": 0
  };
  set_vencimiento=0;
  post_final:any;
  loading:boolean=false;
  async submitForm($ev: any, value: any) {
    $ev.preventDefault();

    for (let c in this.valFormFilter.controls) {
      this.valFormFilter.controls[c].markAsTouched();
    }


    //dias para todas
    if(this.selected == 5 || this.selected == 3){
      let dias = this.valFormFilter.controls['dias_vencimiento'].value;

      if(dias == null || dias == undefined || dias == ""){
        this.valFormFilter.controls['dias_vencimiento'].setErrors({invalid: true});
      }else{
        this.valFormFilter.controls['fecha_vencimiento_utc'].setValue(new Date())
        this.valFormFilter.controls['dias_vencimiento'].clearValidators();
        this.valFormFilter.controls['dias_vencimiento'].clearAsyncValidators();
      }
    }

    //fechas para todas
    if(this.selected == 6 || this.selected == 1){
      let fecha = this.valFormFilter.controls['fecha_vencimiento_utc'].value;
      if(fecha == null || fecha == undefined || fecha == ""){
        this.valFormFilter.controls['fecha_vencimiento_utc'].setErrors({invalid: true});
      }else{

        this.valFormFilter.controls['dias_vencimiento'].setValue(1)
        this.valFormFilter.controls['fecha_vencimiento_utc'].clearValidators();
        this.valFormFilter.controls['fecha_vencimiento_utc'].clearAsyncValidators();
      }
    }

    if(this.selected == 2 || this.selected == 4){

      this.valFormFilter.controls['dias_vencimiento'].setValue(1)
      this.valFormFilter.controls['fecha_vencimiento_utc'].clearValidators();
      this.valFormFilter.controls['fecha_vencimiento_utc'].clearAsyncValidators();

      this.valFormFilter.controls['fecha_vencimiento_utc'].setValue(new Date())
      this.valFormFilter.controls['dias_vencimiento'].clearValidators();
      this.valFormFilter.controls['dias_vencimiento'].clearAsyncValidators();

    }

    if (this.valFormFilter.valid) {

  

        if(this.selected == 2){
          this.step = 1
          //1 es fecha
          this.set_vencimiento = 1;
          $("#card-simulation").addClass("card-simulation");
          this.dialogRef.updateSize('1300px');

          

        }

        if(this.selected == 4){
          this.step = 1
          //2 es dias
          this.set_vencimiento = 2;
          $("#card-simulation").addClass("card-simulation");
          this.dialogRef.updateSize('1300px');

          

        }

        if(this.selected != 2 && this.selected != 4){

          try {


            this.loading = true;
            this.wait_to_send = true;
      
            let data_to_send = {'facturas': this.facturas}
      
            const bd: any = await this.settings.get_query(3, '/cliente/simulacion-documentos/'+this.id_sim+'/', data_to_send);
            var body_data: any =bd._body;
            var data = body_data.success;
            switch (bd.status) {
              case 200:
      

                if(data.duplicadas == true){
                  this.snackBar.open('¡Se detectaron facturas duplicadas! Para evitar confusiones, serán descartadas.', 'Aceptar', {
                    duration: 20000,
                    panelClass: 'simpli-snack-alert'
                  });
                  
                }

                this.wait_to_send = false;

                this.dialogRef.close(true)
      
                const dialogRef = this.dialog.open(AlertSuccess,{
                  width: '530px',
                  disableClose: true,
                  maxWidth: '95vw',
                  autoFocus:false,
                });
      
                dialogRef.componentInstance.id_sim = this.id_sim;
                dialogRef.componentInstance.title = "¡Facturas agregadas con éxito!";
                dialogRef.componentInstance.subtitle = "Se volverá a evaluar la operación y el resultado será enviado a tu correo y dispositivo móvil."
                dialogRef.componentInstance.open_as_sim = true;
                dialogRef.componentInstance.onboarding = this.onboarding;

                dialogRef.afterClosed().subscribe((result:any) => {

                });

                this.loading = false;
      
              break;
              default:
      
              break;
            }
      
          } catch (bd:any) {

            this.loading = false;
      
            switch (bd.status) {
              case 500:
                  this.wait_to_send = false;
                  this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                    duration: 20000,
                    panelClass: 'simpli-snack'
                  });

                break;
      
              case 400: 
              this.wait_to_send = false;
                  this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                    duration: 20000,
                    panelClass: 'simpli-snack'
                  });
                break;
              default:
                  this.wait_to_send = false;
                  this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
                    duration: 20000,
                    panelClass: 'simpli-snack'
                  });
      
              break;
            }
          }

        }



    }else{
      console.log("valform invalid")
    }

  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  //ALERT >95% anticipo
  alert_anticipo(){
    let anticipo = Number(this.valFormFilter.controls['anticipo'].value);
    if(anticipo > 95){

      const dialogRef = this.dialog.open(AlertAnticipo,{
        width: '530px',
        maxWidth: '95vw',
        disableClose: true,
        autoFocus:false,
      });
  
      dialogRef.afterClosed().subscribe((result:any) => {
  
      });

    }

  }

  wait_to_send:boolean=false;
  async alert_success(){


    try {

      this.wait_to_send = true;

      let data_to_send = {'facturas': this.facturas, 'simulacion': this.simulation}

      const bd: any = await this.settings.get_query(2, '/cliente/simulacion/'+this.id_company+'/', data_to_send);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          this.wait_to_send = false;

          let id_sim = data.info.id;

          const dialogRef = this.dialog.open(AlertSuccess,{
            width: '530px',
            disableClose: true,
            maxWidth: '95vw',
            autoFocus:false,
          });

          dialogRef.componentInstance.id_sim = id_sim;
      
          dialogRef.afterClosed().subscribe((result:any) => {
      
          });

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.wait_to_send = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            this.settings.send_mail_500('Recuperar contraseña', bd);
          break;

        case 400: 
        this.wait_to_send = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.wait_to_send = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  loading_pdf_:boolean=false;
  async download_pdf(){
    try {
      
      this.loading_pdf_ = true;

      let data_to_send = {'facturas': this.facturas, 'simulacion': this.simulation}

      const bd: any = await this.settings.get_query(2, '/cliente/simulacionNoRegistrada-pdf/'+this.id_company+'/', data_to_send);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          if (body_data.respuesta && body_data.respuesta.info) {
            window.open(body_data.respuesta.info, "_blank");
          } else if (data && data.info) {
            window.open(data.info, "_blank");
          } else {
            this.snackBar.open('No se encontró la URL del PDF en la respuesta del servidor', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
          }
          // this.downloadPDF(data.info);
          this.loading_pdf_ = false;

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400: 
        this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
          this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  downloadPDF(pdf:any) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "vct_illustration.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }



}