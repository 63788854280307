import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;


@Component({
  selector: 'app-info-certificado',
  templateUrl: './info-certificado.html',
  styleUrls: ['./info-certificado.less']
})
export class InfoCertificadoComponent implements OnInit {



  @Input() id_company:any;
  @Input() id_sim:any;

  constructor(private _location: Location, public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, public snackBar:MatSnackBar,  private activated_router: ActivatedRoute, public router:Router) {

    this.id_company = localStorage.getItem('company_id');

    this.activated_router.params.subscribe((params:any) => {
      this.id_sim = params['id']
    })
  
   }



  ngOnInit() {

  }

  go_to_detail(){
    this.router.navigate(['home/ver-detalle/',this.id_sim])
  }

  backClicked() {
    //this._location.back();
    window.close();
  }


}