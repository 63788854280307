<div class="toolbar">
  <button (click)="new_obs()" title="Nueva observación"><i class="fa-regular fa-file-circle-plus"></i></button>
  <button title="Deshacer" (click)="undo()"><i class="fa-solid fa-undo"></i></button>
  <button title="Rehacer" (click)="redo()"><i class="fa-solid fa-redo"></i></button>
  <button title="Negrita" (click)="format('bold')"><i class="fa-solid fa-bold"></i></button>
  <button title="Italica" (click)="format('italic')"><i class="fa-solid fa-italic"></i></button>
  <button title="Subrayar" (click)="format('underline')"><i class="fa-solid fa-underline"></i></button>
  <button title="Lista" (click)="format('insertUnorderedList')"><i class="fa-solid fa-list"></i></button>
  <button title="Lista numerica" (click)="format('insertOrderedList')"><i class="fa-solid fa-list-ol"></i></button>
  <button title="Agrandar" (click)="changeFontSize('increase')"><i class="fa-solid fa-text-size"></i> +</button>
  <button title="Achicar" (click)="changeFontSize('decrease')"><i class="fa-solid fa-text-size"></i> -</button>
  <button title="Hipervinculo" (click)="format('createLink')"><i class="fa-solid fa-link"></i></button>
  <button title="Seleccionar todo" (click)="selectAllContent()"><span>Seleccionar todo</span></button>
  <button title="Copiar texto" (click)="copyContent()"><i class="fa-solid fa-copy"></i></button>
  <button title="Insertar imagen" (click)="selectFile()"><i class="fa-solid fa-image"></i></button>
  <input id="simg" type="file" (change)="onFileSelected($event)" accept="image/*" style="display: none;"/>
  <button title="Borrar todo" (click)="clear()"><i class="fa-solid fa-broom-wide"></i></button>
  <input title="Color del texto" type="color" [(ngModel)]="selectedColor" (change)="applyColor()" style="height: 29px;"/>
</div>

<div #editor class="editor" contenteditable="true" (input)="onEditorInput()" (paste)="onEditorPaste($event)">
</div>
