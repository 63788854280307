
<div [ngClass]="{'row-responsive': is_responsive == true, 'row':true, 'row_main':is_responsive == false, 'animate__animated':true, 'animate__fadeIn':true}">

  <div *ngIf="is_responsive == true" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <span class="material-icons" (click)="return()">
      keyboard_return
    </span>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <h1 class="titulo-simpli">Revisa el detalle de tu simulación</h1>

  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">


    <div class="row">
      <div  class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="box-error">
          <div class="row">
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                  <div style="margin-top:8px;">
                      <i style="font-size: 25px;" class="fa-regular fa-triangle-exclamation"></i>
                  </div>
              </div>
    
              <div class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                En esta ocasión, tu simulación no fue aprobada. Mira el detalle para conocer el motivo. Si tienes preguntas, contacta a tu ejecutivo.
              </div>
    
          </div>
    
        </div>
      </div>
    </div>

    <div class="row">

          <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12"></div>

          <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12">
            <mat-card id="card-simulation" class="card-tabla">



                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-white">

                        <div class="contenedor-sim add_factoring">
  
                          <div class="forfacturas" >

                                <div class="rowfacturas2">
                                  <div class="label-facturas">N° Simulación:</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="strong-facturas" style="margin-left: 50px;letter-spacing: normal;">{{id_sim}}</div>
                                </div>

                          </div>

                          <div class="forfacturas" style="position: relative;">

                            <i class="fa-regular fa-circle-xmark error-icon"></i>

                            <div class="rowfacturas2">
                              <div class="label-facturas">Motivo:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;letter-spacing: normal;">{{comentario_descarte}}</div>
                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-rosa animate__animated animate__fadeIn" *ngIf="cantidad_facturas <= 7 && loading == false">

                        <h1 class="titulo-interno">Facturas simuladas</h1>

                        <div class="contenedor-facturas add_factoring">
  
                          <div class="forfacturas" *ngFor="let i of facturas; let index=index">

                                <div class="rowfacturas2">
                                  <div class="label-facturas">N° Factura {{index+1}}:</div>
                                  <div class="strong-facturas">{{i.document.folio}}</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="label-facturas" style="margin-left: 50px;">Monto total:</div>
                                  <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(i.document.monto_total)}}</div>
                                </div>

                          </div>
  
                        </div>

                      </div>

                      <div class="caja-rosa animate__animated animate__fadeIn" *ngIf="cantidad_facturas > 7 && loading == false">

                        <h1 class="titulo-interno">Facturas agregadas</h1>

                        <div style="margin-top: 25px;">
  
                          <div>

                              <div class="label-many">
                                Para visualizar mejor los datos, abre el detalle.
                              </div>

                              <div>
                                <mat-label class="pink-label" (click)="detalle_factura()">Abrir detalle <i class="fa-solid fa-angle-right"></i></mat-label>
                              </div>

                          </div>
  
                        </div>

                      </div>

                      <div class="caja-rosa animate__animated animate__fadeIn" style="padding-left: 0px;" *ngIf="cantidad_facturas == 0 && loading == true">

                        <div style="text-align: center;margin-top: 20px;margin-bottom: 20px;">
                          <i style="color: gray; font-size: 25px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-rosa">

                        <h1 class="titulo-interno">Montos asociados</h1>

                        <div class="contenedor-sim add_factoring">
  
                          <div class="forfacturas" >

                                <div class="rowfacturas2">
                                  <div class="label-facturas">Monto total a simular:</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="strong-facturas" style="margin-left: 50px;">${{total}}</div>
                                </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">% Financiamiento:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">{{anticipo}}%</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Monto de anticipo:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{valor_anticipo_total}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Vencimiento: </div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">{{dias_promedio}} días</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Tasa de interés:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">{{tasa_interes}}%</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Monto total de interés:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{interes_total}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Comisión:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{comision}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Gastos operacionales:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{gastos}}</div>
                            </div>

                          </div>


                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">IVA:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{iva_operacion}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Excedentes:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{excedentes}}</div>
                            </div>

                          </div>
  
                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-rosa" style="padding-top: 5px;padding-bottom: 5px;">

                          <div class="forfacturas" style="margin-bottom: 0px;">

                                <div class="rowfacturas2">
                                  <div class="label-facturas2">Monto líquido</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="strong-facturas2" style="margin-left: 50px;">${{monto_recibir}}</div>
                                </div>

                          </div>
  
                      </div>

                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;padding-right: 10px;margin-top: 30px;">
                      <mat-label (click)="download_pdf()" class="label-download">Descargar</mat-label>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              
                      <div class="btn-simpli-container">
                        <button class="primary-button" mat-flat-button (click)="volver_simular()">
                          <mat-label>Volver a simular</mat-label>
                        </button>

                        <button  class="secondary-button" mat-flat-button (click)="observaciones_get()">
                          <mat-label>Contactar a mi ejecutivo</mat-label>
                        </button>
                      </div>
                      
                    </div>
                  </div>
                </div>



            </mat-card>
          </div>

          <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12"></div>

    </div>

  </div>

</div>