import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerDetalleDialogoComponent } from '../ver-detalle-dialogo/ver-detalle-dialogo.component';
// import { DialogoVerDetalles } from '../detalle-grafico/verDetalles.component';
@Component({
  selector: 'app-resumen-libros-sii-table-dos',
  templateUrl: './resumen-libros-sii-table.component.html',
  styleUrls: ['./resumen-libros-sii-table.component.less']
})
export class ResumenLibrosSiiTableComponent implements OnInit {

  @Input() promedios: any;
  @Input() dataSource: any;
  displayedColumns: any[] = [
    'periodo',
    'facEmitidas',
    'ventas',
    'compras',
    'notasCredito',
    'ventaEfectiva',
    'ventasiva',
    'ventasExportacion'
  ];
  @Input() hasInfo!: boolean;
  @Input() company_id!: boolean;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  formatMoney = (amount: any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) {}
  };

  verDetalles = (filtro: any, fecha: any, monto: any) => {
    if (fecha) {
    }

    if (monto != 0) {
      let dialogRef = this.dialog.open(VerDetalleDialogoComponent, {
        maxHeight: '90vh',
        width: '90%',
        maxWidth: '90vw',
        data: {
          filtro: filtro,
          fecha: fecha,
          monto: monto,
          company: this.company_id,
        },
      });
    }
  };

  date12MonthsAgo() {
    let date = new Date();
    date.setMonth(date.getMonth() - 12);
    return date;
  }

  formatPeriodo(date: Date) {
    const mes = date.getMonth() + 1;
    const anio = date.getFullYear();
    return `${anio}-${mes}`;
  }


}
