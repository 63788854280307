<mat-card>
  <mat-card-header>
      <mat-card-title>Relación Compras / Ventas / NC</mat-card-title>
      <mat-card-subtitle>Últimos  meses</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

      <div class="row animate__animated animate__fadeIn">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
              <!-- <canvas *ngIf="show_graph"  id="{{id}}" baseChart [chartType]="'bar'" [datasets]="deudores.datasets" [labels]="deudores.labels" [options]="dOptions" [colors]="dColors"></canvas> -->
              <div style="height: 500px;" id="arreglamoslawea"></div>
          </div>          
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
              <app-resumen-libros-sii-table-dos [dataSource]="dataSource" [hasInfo]="hasInfo" [company_id]='id' [promedios]="averages"></app-resumen-libros-sii-table-dos>                    
          </div>
      </div>
      
  </mat-card-content>
</mat-card>