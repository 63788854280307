<!-- ANTECEDENTES PERSONALES -->
@if(step == 1) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">antecedentes personales</div>
      <div class="subtitulo-onboarding">Completa los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep1">

          <mat-form-field>
            <mat-label>Dirección particular</mat-label>
            <input type="text" class="text-capitalize" formControlName="direccion" matInput required />
            <mat-hint>Ingresa tu dirección particular</mat-hint>
            <mat-error>La dirección es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>N° Departamento</mat-label>
            <input type="text" class="text-capitalize" formControlName="departamento" matInput />
            <mat-hint>Ingresa el número de tu departamento (solo si aplica)</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Comuna</mat-label>
            <input type="text" class="text-capitalize" formControlName="comuna" matInput required />
            <mat-hint>Ingresa tu comuna de residencia actual</mat-hint>
            <mat-error>La comuna es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ciudad</mat-label>
            <input type="text" class="text-capitalize" formControlName="ciudad" matInput required />
            <mat-hint>Ingresa la ciudad de residencia de la persona o estructura jurídica</mat-hint>
            <mat-error>La ciudad es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Teléfono (9 0000 0000)</mat-label>
            <input type="text" formControlName="telefono" matInput maxlength="11" (input)="formatPhone($event)"
              required />
            <mat-hint>Ingresa el teléfono de la persona o estructura jurídica</mat-hint>
            <mat-error>El teléfono es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Profesión</mat-label>
            <input type="text" class="text-capitalize" formControlName="profesion" matInput />
            <mat-hint>Ingresa tu profesión</mat-hint>
          </mat-form-field>

        </form>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary"
        [disabled]="!valFormStep1.valid || buttonLoading" (click)="putStep('antecedentesPersonales')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>

<!-- ANTECEDENTES DEL CONYUGE -->
} @else if(step == 2) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'">        
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">antecedentes del conyuge</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep2">

          <mat-form-field>
            <mat-label>Nacionalidad</mat-label>
            <input type="text" class="text-capitalize" formControlName="nacionalidad" matInput required />
            <mat-hint>Ingresa la nacionalidad de tu conyuge</mat-hint>
            <mat-error>La nacionalidad es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Actividad o profesión</mat-label>
            <input type="text" class="text-capitalize" formControlName="profesion" matInput required />
            <mat-hint>Ingresa la actividad o profesión de tu conyuge</mat-hint>
            <mat-error>La actividad o profesión es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Empleador del conyuge</mat-label>
            <input type="text" class="text-capitalize" formControlName="empleador" matInput />
            <mat-hint>Ingresa la empresa (empleador) en donde trabaja tu conyuge</mat-hint>
          </mat-form-field>

        </form>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary" [disabled]="!valFormStep2.valid || buttonLoading"
      (click)="putStep('antecedentesConyuge')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>

<!-- ANTECEDENTES COMERCIALES -->
} @else if(step == 3) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'; valFormStep3.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">ingresa los datos de la cuenta bancaria</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep3">

          <mat-form-field>
            <mat-label>Banco</mat-label>
            <input type="text" (change)="validate_error($event)" formControlName="bank" matInput
              [matAutocomplete]="auto" required />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filtrar_banco($event.option)">
              <mat-option data-id="{{option.id}}" *ngFor="let option of filteredOptions | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-hint>Selecciona el banco</mat-hint>
            <mat-error>El banco es <strong>requerido</strong></mat-error>
          </mat-form-field>


          <mat-form-field>
            <mat-label>N° de Cuenta</mat-label>
            <input type="number" formControlName="numero_cuenta" matInput (keyup)="formatNumCuenta($event)" required />
            <mat-hint>Ingresa el número de cuenta</mat-hint>
            <mat-error>El número de cuenta es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Línea de crédito disponible</mat-label>
            <input type="text" formControlName="linea_credito_disponible" matInput
              (keyup)="formatMonto($event, 'linea_credito_disponible')" required />
            <mat-hint>Ingresa el total en pesos de la línea de crédito disponible</mat-hint>
            <mat-error>Total línea de crédito disponible es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Línea de crédito utilizada</mat-label>
            <input type="text" formControlName="linea_credito_utilizada" matInput
              (keyup)="formatMonto($event, 'linea_credito_utilizada')" required />
            <mat-hint>Ingresa el total en pesos de la línea de crédito utilizada</mat-hint>
            <mat-error>Total línea de crédito utilizada es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Tarjeta de crédito disponible</mat-label>
            <input type="text" formControlName="tarjeta_credito_disponible" matInput
              (keyup)="formatMonto($event, 'tarjeta_credito_disponible')" required />
            <mat-hint>Ingresa el total en pesos de la tarjeta de crédito utilizada</mat-hint>
            <mat-error>Total tarjeta de crédito disponible es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Tarjeta de crédito utilizada</mat-label>
            <input type="text" formControlName="tarjeta_credito_utilizada" matInput
              (keyup)="formatMonto($event, 'tarjeta_credito_utilizada')" required />
            <mat-hint>Ingresa el total en pesos de la tarjeta de crédito utilizada</mat-hint>
            <mat-error>Total tarjeta de crédito utilizada es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>¿Tienes un crédito hipotecario?</mat-label>
            <mat-select formControlName="credito_hipotecario" required>
              <mat-option [value]="true">Sí</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            <mat-hint>Selecciona una opción como respuesta</mat-hint>
            <mat-error>Esta selección es <strong>requerida</strong></mat-error>
          </mat-form-field>

        </form>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary" [disabled]="!valFormStep3.valid"
      (click)="putStep('antecedentesComerciales')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>

<!-- BIENES RAICES -->
} @else if(step == 4 && substep == 1) {
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'; valFormStep4_1a.reset(); valFormStep4_1b.reset(); valFormStep4_1c.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">ingresa los datos del bien raíz</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="tree-container" mat-icon-button matTreeNodeToggle>
              <span class="custom-circle">{{node.number}}</span>
              <span class="titulo-activos">{{node.name}}</span>
            </div>
            <div class="nested-node animate__animated animate__fadeIn"
              [class.tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>

          <mat-tree-node *matTreeNodeDef="let node">
            <form *ngIf="node.formType === 'individualizacion'" [formGroup]="valFormStep4_1a">
              <mat-form-field>
                <mat-label>Calle y número</mat-label>
                <input type="text" class="text-capitalize" formControlName="calle" matInput (keydown)="allowSpaces($event)" required/>
                <mat-hint>Ingresa la calle y número de la propiedad</mat-hint>
                <mat-error>La calle y número es <strong>requerida</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Comuna</mat-label>
                <input type="text" class="text-capitalize" formControlName="comuna" matInput (keydown)="allowSpaces($event)" required />
                <mat-hint>Ingresa la comuna en donde se ubica la propiedad</mat-hint>
                <mat-error>La comuna es <strong>requerida</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Ciudad</mat-label>
                <input type="text" class="text-capitalize" formControlName="ciudad" matInput (keydown)="allowSpaces($event)" required />
                <mat-hint>Ingresa la ciudad en donde se ubica la propiedad</mat-hint>
                <mat-error>La ciudad es <strong>requerida</strong></mat-error>
              </mat-form-field>
            </form>

            <form *ngIf="node.formType === 'inscripcion'" [formGroup]="valFormStep4_1b">

              <mat-form-field>
                <mat-label>N° de Fojas</mat-label>
                <input type="number" formControlName="fojas" matInput required />
                <mat-hint>Ingresa el número de fojas de la inscripción</mat-hint>
                <mat-error>El número de fojas es <strong>requerida</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Año</mat-label>
                <input type="number" formControlName="ano" matInput required />
                <mat-hint>Ingresa el año de la inscripción de la propiedad</mat-hint>
                <mat-error>El año es <strong>requerida</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>N° Rol</mat-label>
                <input type="number" formControlName="rol" matInput required />
                <mat-hint>Ingresa el número de Rol</mat-hint>
                <mat-error>El rol es <strong>requerida</strong></mat-error>
              </mat-form-field>

            </form>


            <form *ngIf="node.formType === 'otros'" [formGroup]="valFormStep4_1c">

              <mat-form-field>
                <mat-label>Avalúo Fiscal (0.000.000)</mat-label>
                <input type="text" formControlName="avaluo_fiscal" matInput
                  (keyup)="formatMonto($event, 'avaluo_fiscal')" required />
                <mat-hint>Ingresa el total (pesos) del Avalúo Fiscal de la propiedad</mat-hint>
                <mat-error>El avalúo fiscal es <strong>requerido</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Valor comercial M(0.000.000)</mat-label>
                <input type="text" formControlName="valor_comercial" matInput
                  (keyup)="formatMonto($event, 'valor_comercial')" required />
                <mat-hint>Ingresa el total (pesos) del valor comercial de la propiedad</mat-hint>
                <mat-error>El valor comercial es <strong>requerido</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Hipoteca a favor de</mat-label>
                <input type="text" formControlName="hipoteca" matInput (keyup)="formatMonto($event, 'hipoteca')"
                  required />
                <mat-hint>Ingresa la garantía de la hipoteca</mat-hint>
                <mat-error>La garantía de la hipoteca es <strong>requerida</strong></mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>¿Tienes seguro?</mat-label>
                <mat-select formControlName="seguro" required>
                  <mat-option [value]="true">Sí</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-hint>Selecciona una opción como respuesta</mat-hint>
                <mat-error>Esta selección es <strong>requerida</strong></mat-error>
              </mat-form-field>

            </form>
          </mat-tree-node>
        </mat-tree>



      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary"
        [disabled]="!valFormStep4_1a.valid || !valFormStep4_1b.valid || !valFormStep4_1c.valid || buttonLoading"
        (click)="putStep('bienRaiz')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>

<!-- VEHICULOS -->
} @else if(step == 4 && substep == 2){
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'; valFormStep4_2.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">datos del vehículo</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep4_2">

          <mat-form-field>
            <mat-label>Marca</mat-label>
            <input type="text" class="text-capitalize" formControlName="marca" matInput required/>
            <mat-hint>Ingresa la marca del vehículo</mat-hint>
            <mat-error>La marca es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Modelo</mat-label>
            <input type="text" class="text-capitalize" formControlName="modelo" matInput required/>
            <mat-hint>Ingresa el modelo del vehículo</mat-hint>
            <mat-error>El modelo es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Año</mat-label>
            <input type="number" formControlName="ano" matInput required required/>
            <mat-hint>Ingresa el año del vehículo</mat-hint>
            <mat-error>El año es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Patente</mat-label>
            <input type="text" class="text-uppercase" formControlName="patente" matInput required/>
            <mat-hint>Ingresa la patente del vehículo</mat-hint>
            <mat-error>La patente es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Valor comercial</mat-label>
            <input type="text" formControlName="valor_comercial_vehiculo" matInput
              (keyup)="formatMonto($event, 'valor_comercial_vehiculo')" required/>
            <mat-hint>Ingresa el valor comercial del vehículo</mat-hint>
            <mat-error>El valor comercial es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Prenda a favor de</mat-label>
            <input type="text" class="text-capitalize" formControlName="prenda" matInput required/>
            <mat-hint>Ingresa la prenda que tienes de garantía</mat-hint>
            <mat-error>La prenda es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>¿Tienes seguro?</mat-label>
            <mat-select formControlName="seguro_vehiculo" required>
              <mat-option [value]="true">Sí</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            <mat-hint>Selecciona una opción como respuesta</mat-hint>
            <mat-error>Esta selección es <strong>requerida</strong></mat-error>
          </mat-form-field>

        </form>

      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary" 
      [disabled]="!valFormStep4_2.valid || buttonLoading" (click)="putStep('vehiculos')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>

<!-- MAQUINARIA Y EQUIPOS -->
} @else if(step == 4 && substep == 3){
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'; valFormStep4_3.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">datos de la maquinaria</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep4_3">

          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" class="text-capitalize" formControlName="descripcion" matInput required/>
            <mat-hint>Ingresa una breve descripción de la maquinaria</mat-hint>
            <mat-error>La descripción es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Marca</mat-label>
            <input type="text" class="text-capitalize" formControlName="marca" matInput required/>
            <mat-hint>Ingresa la marca de la maquinaria</mat-hint>
            <mat-error>La marca es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Modelo</mat-label>
            <input type="text" class="text-uppercase" formControlName="modelo" matInput required/>
            <mat-hint>Ingresa el modelo de la maquinaria</mat-hint>
            <mat-error>El modelo es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Antigüedad</mat-label>
            <input
              type="text"
              [value]="antiguedadValue"
              (input)="transformValue($event)"
              matInput required/>
            <mat-hint>Ingresa la antigüedad en años de la maquinaria</mat-hint>
            <mat-error>La antigüedad es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Valor comercial</mat-label>
            <input type="text" formControlName="valor_comercial_maquinaria" matInput
              (keyup)="formatMonto($event, 'valor_comercial_maquinaria')" required/>
              <mat-hint>Ingresa el valor comercial de la maquinaria</mat-hint>
              <mat-error>El valor comercial es <strong>requerido</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Prenda a favor de</mat-label>
            <input type="text" class="text-capitalize" formControlName="prenda" matInput required/>
            <mat-hint>Ingresa la prenda que tienes de garantía</mat-hint>
            <mat-error>La prenda es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>¿Tienes seguro?</mat-label>
            <mat-select formControlName="seguro_maquinaria" required>
              <mat-option [value]="true">Sí</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            <mat-hint>Selecciona una opción como respuesta</mat-hint>
            <mat-error>Esta selección es <strong>requerida</strong></mat-error>
          </mat-form-field>

        </form>

      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary"
      [disabled]="!valFormStep4_3.valid || buttonLoading" (click)="putStep('maquinaria')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>

<!-- OTROS ACTIVOS -->
} @else if(step == 4 && substep == 4){
<div *ngIf="isLoadingStep" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>
<div *ngIf="!isLoadingStep">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="volver-dialog" (click)="step = 0; documentType = 'Estado de situación financiera'; valFormStep4_4.reset()">
        <i class="fa-solid fa-chevron-left"></i>
        <span>Volver</span>
      </div>
      <div class="titulo-onboarding">datos del activo</div>
      <div class="subtitulo-onboarding">Debes completar todos los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <form [formGroup]="valFormStep4_4">

          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" formControlName="descripcion" matInput required />
            <mat-hint>Ingresa una breve descripción del activo</mat-hint>
            <mat-error>La descripción es <strong>requerida</strong></mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Valor comercial</mat-label>
            <input type="text" formControlName="valor_comercial_activo" matInput
              (keyup)="formatMonto($event, 'valor_comercial_activo')" required />
            <mat-hint>Ingresa el valor comercial del activo</mat-hint>
            <mat-error>El valor comercial es <strong>requerido</strong></mat-error>
          </mat-form-field>

        </form>

      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="primary-button" color="primary"
      [disabled]="!valFormStep4_4.valid || buttonLoading" (click)="putStep('otrosActivos')">
        Guardar datos
      </button>
    </div>
  </mat-dialog-actions>
</div>


} @else if (documentType === 'Estado de situación financiera') {
<div *ngIf="isLoading" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoading">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 20px;">

      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
      <div class="titulo-onboarding">
        completa el Estado de situación financiera
      </div>
      <div class="subtitulo-onboarding">Selecciona la categoría y completa los campos solicitados.</div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

        <div style="display: flex; align-items: center;">
          <div class="container-cuenta">
            <mat-accordion multi>

              <!-- STEP 1 / Antecedentes personales -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true" disabled (click)="step = 1">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div
                        [ngClass]="documentStatus.antecedentes_personales ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i class="fa-light"
                          [ngClass]="documentStatus.antecedentes_personales ? 'fa-circle-check' : 'fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Antecedentes personales</div>
                    </mat-panel-title>
                    <span (click)="step = 1">
                      <i class="toggle-icon"
                        [ngClass]="documentStatus.antecedentes_personales ? 'fa-regular fa-sharp fa-light fa-pen' : 'fa-regular fa-chevron-right'"></i>
                    </span>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
              </div>

              <!-- STEP 2 / Antecedentes del conyuge -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true" disabled (click)="step = 2">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div
                        [ngClass]="documentStatus.antecedentes_conyugue ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i class="fa-light"
                          [ngClass]="documentStatus.antecedentes_conyugue ? 'fa-circle-check' : 'fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Antecedentes del conyuge</div>
                    </mat-panel-title>
                    <span (click)="step = 2">
                      <i class="toggle-icon"
                        [ngClass]="documentStatus.antecedentes_conyugue ? 'fa-regular fa-sharp fa-light fa-pen' : 'fa-regular fa-chevron-right'"></i>
                    </span>
                  </mat-expansion-panel-header>

                </mat-expansion-panel>
              </div>

              <!-- STEP 3 / Antecedentes comerciales -->
              <div style="display: flex; display: row; align-items: center;">
                <mat-expansion-panel #panel [hideToggle]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div
                        [ngClass]="documentStatus.antecedentes_comerciales ? 'status-cuenta listo' : 'status-cuenta falta'">
                        <i class="fa-light"
                          [ngClass]="documentStatus.antecedentes_comerciales ? 'fa-circle-check' : 'fa-clock'"></i>
                      </div>
                      <div class="titulo-container">Antecedentes comerciales</div>
                    </mat-panel-title>
                    <span (click)="step = 3">
                      <i class="toggle-icon"
                        [ngClass]="documentStatus.antecedentes_comerciales ? 'fa-regular fa-sharp fa-light fa-pen' : 'fa-regular fa-chevron-right'"></i>
                    </span>
                  </mat-expansion-panel-header>

                  <ng-container *ngIf="documentStatus.antecedentes_comerciales">
                    <mat-form-field class="editable-field" *ngFor="let antecedente of antecedentes_comerciales">
                      <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                        <i class="fa-light fa-building-columns"></i></span>
                        <input type="text" [value]="antecedente.bank.charAt(0).toUpperCase() + antecedente.bank.slice(1).toLowerCase()" matInput readonly>
                      <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;" 
                      (click)="selectCheckbox('Antecedentes comerciales', antecedente)">
                        <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i></span>
                    </mat-form-field>
                  </ng-container>

                  <div style="display: flex; align-items: center;" (click)="step = 3">
                    <span style="padding-left: 12px; padding-right: 5px; font-size: 20px;">
                      <i class="fa-light fa-circle-plus"></i>
                    </span>
                    <span *ngIf="!documentStatus.antecedentes_comerciales">Agregar cuenta bancaria</span>
                    <span *ngIf="documentStatus.antecedentes_comerciales">Agregar otra cuenta bancaria</span>
                  </div>

                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>

        </div>

        <!-- STEP 4 / Checkbox-->
        <div>
          <div class="titulo-activos checkbox">¿Cuentas con alguno de estos activos?</div>

          <div class="container-checkbox">
            <section class="section-checkbox">
              <mat-checkbox [checked]="selectedTitulos.includes('Bienes raíces')"
                (change)="onCheckboxChange($event, 'Bienes raíces')">
                Bienes raíces
              </mat-checkbox>
              <mat-checkbox [checked]="selectedTitulos.includes('Maquinaria y equipos')"
                (change)="onCheckboxChange($event, 'Maquinaria y equipos')">
                Maquinaria y equipos
              </mat-checkbox>
            </section>
            <section class="section-checkbox">
              <mat-checkbox [checked]="selectedTitulos.includes('Vehículos')"
                (change)="onCheckboxChange($event, 'Vehículos')">
                Vehículos
              </mat-checkbox>
              <mat-checkbox [checked]="selectedTitulos.includes('Otros activos')"
                (change)="onCheckboxChange($event, 'Otros activos')">
                Otros activos
              </mat-checkbox>
            </section>
          </div>

          <div class="acordion-checkbox">
            <mat-accordion multi>
              <mat-expansion-panel *ngFor="let titulo of selectedTitulos" [hideToggle]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div [ngClass]="getStatusInfo(titulo, 'class')">
                      <i [ngClass]="getStatusInfo(titulo, 'icon')"></i>
                    </div>
                    <div class="titulo-container">{{ titulo }}</div>
                  </mat-panel-title>
                  <span>
                    <i [ngClass]="getStatusInfo(titulo, 'toggle')"></i>
                  </span>
                </mat-expansion-panel-header>

                <ng-container *ngIf="getStatusInfo(titulo, 'class').includes('listo')">
                  <ng-container [ngSwitch]="titulo">

                    <!-- Bienes raíces -->
                    <ng-container *ngSwitchCase="'Bienes raíces'">
                      <mat-form-field class="editable-field" *ngFor="let bienRaiz of bienes_raices">
                        <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                          <i class="fa-light fa-building-columns"></i>
                        </span>
                        <input type="text" [value]="bienRaiz.calle + ' - ' + bienRaiz.comuna" class="text-capitalize" matInput readonly>
                        <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;" 
                        (click)="selectCheckbox('Bienes raíces', bienRaiz)">
                          <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i>
                        </span>
                      </mat-form-field>
                    </ng-container>
            
                    <!-- Vehículos -->
                    <ng-container *ngSwitchCase="'Vehículos'">
                      <mat-form-field class="editable-field" *ngFor="let vehiculo of vehiculos">
                        <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                          <i class="fa-sharp fa-light fa-truck"></i>
                        </span>
                        <input type="text" [value]="vehiculo.marca + ' - ' + vehiculo.modelo" class="text-capitalize" matInput readonly>
                        <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;" 
                        (click)="selectCheckbox('Vehículos', vehiculo)">
                          <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i>
                        </span>
                      </mat-form-field>
                    </ng-container>
            
                    <!-- Maquinaria -->
                    <ng-container *ngSwitchCase="'Maquinaria y equipos'">
                      <mat-form-field class="editable-field" *ngFor="let maquina of maquinaria_equipos">
                        <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                          <i class="fa-sharp fa-light fa-truck"></i>
                        </span>
                        <input type="text" [value]="maquina.descripcion" class="text-capitalize" matInput readonly>
                        <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;" 
                        (click)="selectCheckbox('Maquinaria y equipos', maquina)">
                          <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i>
                        </span>
                      </mat-form-field>
                    </ng-container>
            
                    <!-- Otros activos -->
                    <ng-container *ngSwitchCase="'Otros activos'">
                      <mat-form-field class="editable-field" *ngFor="let activo of otros_activos">
                        <span matTextPrefix style="padding-right: 10px; font-size: 16px; color: #00022C;">
                          <i class="fa-sharp fa-light fa-truck"></i>
                        </span>
                        <input type="text" [value]="activo.descripcion" class="text-capitalize" matInput readonly>
                        <span matSuffix style="padding-right: 12px; font-size: 16px; color: #00022C;" 
                        (click)="selectCheckbox('Otros activos', activo)">
                          <i class="fa-regular fa-sharp fa-light fa-pen edit-icon"></i>
                        </span>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <div style="display: flex; align-items: center;" (click)="substepCheckbox(titulo)">
                  <span style="padding-left: 12px; padding-right: 5px; font-size: 20px;">
                    <i class="fa-light fa-circle-plus"></i>
                  </span>
                  <ng-container [ngSwitch]="titulo" (click)="substepCheckbox(titulo)">
                    <span *ngSwitchCase="'Bienes raíces'">
                      {{documentStatus.bien_raiz ? 'Agregar otro bien raíz' : 'Agregar bien raíz'}}
                    </span>
                    <span *ngSwitchCase="'Vehículos'">
                      {{documentStatus.vehiculo ? 'Agregar otro vehículo' : 'Agregar vehículo'}}
                    </span>
                    <span *ngSwitchCase="'Maquinaria y equipos'">
                      {{documentStatus.maquinaria ? 'Agregar otra maquinaria' : 'Agregar maquinaria'}}
                    </span>
                    <span *ngSwitchCase="'Otros activos'">
                      {{documentStatus.activos ? 'Agregar otro activo' : 'Agregar activo'}}
                    </span>
                  </ng-container>
                </div>

              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>






      </div>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

    <div style="display: flex; justify-content: center;">
      <button type="button" mat-stroked-button class="secondary-button" color="primary" (click)="cerrarDialogo()">
        Volver
      </button>
      <!-- <button type="button" mat-stroked-button class="primary-button" color="primary"
      (click)="step = 5">Finalizar</button> -->
    </div>


  </mat-dialog-actions>
</div>
}