@if(paso == 1){
  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <h1 class="titulo-simpli">Selecciona las facturas para tu simulación</h1>
          <small class="subtitulo-simpli">Cuentas con <strong>${{formatMoney(suma_facturas)}}</strong> para simular la operación con las facturas que selecciones.</small>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

          <form  [formGroup]="valFormFilter">

              <div class="row" style="width: 100%;">

                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top" >
                  <form [formGroup]="valFormFechas">

                        <mat-form-field appearance="fill" color="primary">
                            <mat-label>Fecha</mat-label>
                            <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" >
                                <input matStartDate readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                                <input matEndDate readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
                            </mat-date-range-input>
                            <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker>
                                <mat-date-range-picker-actions style="justify-content: center;">
                                  <button class="secondary-button" (click)="reset()" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                                  <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="filtrar_por_fechas()">Aplicar</button>
                                    </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                          </mat-form-field>
                  </form>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top" >
                  <mat-form-field appearance="fill">
                    <mat-label>Buscar</mat-label>
                    <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
                    <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                    <mat-hint>Buscar por monto o número de operación</mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top" >

                  <button matTooltip="Esto actualizara tu lista de facturas con la información más reciente del SII." [disabled]="loading" (click)="loadDocumentsScrap()" style="margin-top: 20px;margin-left: 20px;" type="button" class="secondary-button" mat-stroked-button>
                    Actualizar facturas
                  </button>

                  <button matTooltip="Agrega tus facturas aquí, en caso de no encontrarlas en el listado." style="margin-top: 20px;" type="button" class="primary-button" mat-stroked-button (click)="open_agregar_facturas_dialog()">
                    Agregar facturas
                  </button>

                </div>

              </div>

          </form>
        </div>
      </div>
    </div>

    @if(ELEMENT_DATA.length > 0 && loading == false){
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div class="table-responsive-simpli add_factoring">

          <mat-card class="card-onboarding">
            <mat-card-content>
              <table mat-table [dataSource]="dataSource">


                <ng-container matColumnDef="check">
                  <th mat-header-cell *matHeaderCellDef style="width: 30px !important;text-align: center;">      
                    <mat-checkbox type="checkbox" matTooltip="Seleccionar página" color="primary"
                    (change)="$event ? masterToggle($event) : null" [checked] = "selection.hasValue() && isAllSelected()" (click)="selectAllElements()">
                    </mat-checkbox>
                  </th>
        
                  <td mat-cell *matCellDef="let element" style="width: 30px !important;text-align: center;"> 
        
                    <div style="border-radius: 3px;transition: all .3s ease-in-out;" (click)="$event.stopPropagation()">
                      <input type="checkbox" (click)="selectOneElement(element.venta_id, element); $event.stopPropagation()" 
                      (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                    </div>
        
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="deudor">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 200px !important;"> Deudor (tu cliente) </th>
                  <td mat-cell *matCellDef="let element" style="min-width: 200px !important;"> 
                    <input class="no-input" readonly value="{{element.receptoracreedor.business_name}}">
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="factura">
                  <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                  <td mat-cell *matCellDef="let element"> 
                    <input class="no-input" readonly value="{{element.folio}}">
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="rut">
                  <th mat-header-cell *matHeaderCellDef> RUT </th>
                  <td mat-cell *matCellDef="let element">
                    <input class="no-input" readonly value="{{element.receptoracreedor.rut}}">
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef> Fecha de emisión </th>
                  <td mat-cell *matCellDef="let element"> 
                    <input class="no-input" readonly value="{{element.fecha_emision}}">
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef> Monto </th>
                  <td mat-cell *matCellDef="let element"> 
                    <input class="no-input" readonly value="${{formatMoney(element.mnt_total)}}">
                  </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </mat-card-content>
          </mat-card>

        
        </div>

      </div>
    }

    @if(ELEMENT_DATA.length == 0 && loading == true){
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <loading-interno></loading-interno>
      </div>
    }

    @if(loading == false){
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <paginador-onboarding
        [pageSize]="7"
        [totalElements]="pageLength"
        [currentPage]="page"
        (pageChange)="pageEvent($event)">
        </paginador-onboarding>
      </div>
    }

    @if(ELEMENT_DATA.length == 0 && loading == false){
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <no-hay-registros [text]="'No se encontraron facturas.'"></no-hay-registros>
      </div>
    }

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <div id="footer-options-bar" class="row animate__animated  animate__faster animate__backInUp" *ngIf="!simular_disabled">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;height: 10px;display: none;">
          <i class="fa-solid fa-caret-up" style="font-size: 30px;margin-top: -10px;color:var(--simpli-color6)"></i>
        </div>

        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 col-top">

              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <input class="no-input-simpli" readonly value="Clientes seleccionados:">
                </div>
                <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <input class="no-input-simpli-strong" readonly value="{{total_proveedores}}">
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <input class="no-input-simpli" readonly value="Facturas seleccionadas:">
                </div>
                <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <input class="no-input-simpli-strong" readonly value="{{ arr_documentos_to_simulate.length }}">
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <input class="no-input-simpli" readonly value="Monto total:">
                </div>
                <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <input class="no-input-simpli-strong" readonly value="${{total_facturas}}">
                </div>
              </div>


        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
            <button class="primary-button" [disabled]="simular_disabled"  mat-stroked-button style="margin-top: 15px;" (click)="simular()">Iniciar simulación</button>
        </div>

      </div>
    </div>

    
    
  </div>
}

@if(paso == 2){
  <app-start-simulation [facturas]="facturas" [id_company]="id_company"></app-start-simulation>
}


