<div class="row bottom-responsive">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <h1 class="titulo-simpli">Revisa el estado de tus cesiones</h1>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" >


      <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 animate__animated animate__fadeIn" >

          <div class="tab-container">
            <div id="tab_ceder" class="tab-label tab-label-active" (click)="switch_tab('tab_ceder')">Por ceder</div>
            <div id="tab_cedidas" class="tab-label" (click)="switch_tab('tab_cedidas')">Cedidas</div>
            <div id="tab_descartadas" class="tab-label" (click)="switch_tab('tab_descartadas')">Descartadas</div>
          </div>
          
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 animate__animated animate__fadeIn" style="text-align: right;">

          <form  [formGroup]="valFormFilter">

            <div class="row">


              <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12 col-top"></div>


              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-top">
                <mat-form-field appearance="fill" color="primary">
                    <mat-label>Fecha</mat-label>
                    <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" >
                        <input matStartDate readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                        <input matEndDate readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
                    </mat-date-range-input>
                    <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker>
                        <mat-date-range-picker-actions>
                            <button class="secondary-button" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                            <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="load_dates()">Aplicar</button>
                            </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                  </mat-form-field>
              </div>


              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-top" >
                <mat-form-field appearance="fill">
                  <mat-label>Buscar</mat-label>
                  <input matInput (keyup.enter)="applyFilter($event.target.value)" formControlName="filter">
                  <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                  <mat-hint>Buscar por monto o número de operación</mat-hint>
                </mat-form-field>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-top" style="text-align: center;">
                <button style="margin-top: 20px;" class="primary-button" (click)="reset()"  mat-flat-button>Limpiar</button>
              </div>


            </div>





          </form>

        </div>

      </div>

      <!--TAB CONTENT-->
      <div class="row">
        <div *ngIf="tab1" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" >
          
          <div class="row" style="margin-top: 15px;">

            <!--Loading-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && por_ceder.length == 0">

              <div class="container-loading">
        
                
                <div class="img-loading">
                  <img src="../../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                </div>
        
                <div style="margin-top: 20px;">
                  <mat-label class="label-loading">
                    <div>La información está siendo procesada.</div>
                    <div>Esto no tomará mucho tiempo.</div>
                  </mat-label>
                </div>
        
              </div>
        
        
            </div>

            <!--content-->
            <div class="col-xs col-box" *ngFor="let _ of por_ceder">

              <div class="box-operation">

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">N° Simulación</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.id}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Fecha creación</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.date_created}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Monto</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">${{formatMoney(_.monto_operacion)}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">N° de factura</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.facturas}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Estado</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <span><i class="fa-regular fa-file-circle-info success-icon"></i></span>
                    <mat-label class="label-secondary">Por ceder</mat-label>
                  </div>
                </div>


                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;margin-top: 15px;">
                    <mat-label (click)="ver_detalle(_.simulacion_id, _.id)" class="label-primary2">Ver Detalle</mat-label><span>
                      <i style="margin-top: 10px;margin-left: 10px;color: var(--simpli-secondary);" class="fa-solid fa-chevron-right"></i></span>
                  </div>
                </div>

              </div>

            </div>

            <!--empty msg-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && por_ceder.length == 0">

              <div class="empty-msg">
        
                
                <div style="padding-top: 50px;text-align: center;">
                  <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                </div>
        
                <div style="padding-top: 50px;text-align: center;">
                  <mat-label class="label-bienvenida">
                    <div class="label-empty">
                      ¡Ups! Al parecer, no tienes operaciones para visualizar.
                    </div>

                    <div class="label-empty" style="margin-top: 20px;">
                      Realiza una simulación para que puedas comenzar a operar.
                    </div>

                  </mat-label>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
                    <div class="btn-simpli-container">
                      <button class="primary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
                    </div>
                    
                  </div>
                </div>
        
              </div>
        
        
            </div>

            <!--paginator-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false && por_ceder.length > 0">


              <numbered-pagination [pageSize]="8"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>



            </div>

          </div>

        </div>

        <div *ngIf="tab2" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" >


          <div class="row" style="margin-top: 15px;">

            <!--Loading-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && cedidas.length == 0">

              <div class="container-loading">
        
                
                <div class="img-loading">
                  <img src="../../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                </div>
        
                <div style="margin-top: 20px;">
                  <mat-label class="label-loading">
                    <div>La información está siendo procesada.</div>
                    <div>Esto no tomará mucho tiempo.</div>
                  </mat-label>
                </div>
        
              </div>
        
        
            </div>

            <div class="col-xs col-box" *ngFor="let _ of cedidas">

              <div class="box-operation" style="border-top: solid 4px var(--simpli-color7);">

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">N° Simulación</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.id}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Fecha creación</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.date_created}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Monto</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">${{formatMoney(_.monto_operacion)}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">N° de factura</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.facturas}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Estado</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <span><i class="fa-regular fa-circle-check success-icon"></i></span>
                    <mat-label class="label-secondary">Cedida</mat-label>
                  </div>
                </div>


                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;margin-top: 15px;">
                    <mat-label (click)="ver_detalle_cedidas(_.id)" class="label-primary2">Ver Detalle</mat-label><span>
                      <i style="margin-top: 10px;margin-left: 10px;color: var(--simpli-secondary);" class="fa-solid fa-chevron-right"></i></span>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && cedidas.length == 0">

              <div class="empty-msg">
        
                
                <div style="padding-top: 50px;text-align: center;">
                  <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                </div>
        
                <div style="padding-top: 50px;text-align: center;">
                  <mat-label class="label-bienvenida">
                    <div class="label-empty">
                      ¡Ups! Al parecer, no tienes operaciones para visualizar.
                    </div>

                    <div class="label-empty" style="margin-top: 20px;">
                      Realiza una simulación para que puedas comenzar a operar.
                    </div>

                  </mat-label>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
                    <div class="btn-simpli-container">
                      <button class="primary-button" (click)="pagar_proveedores()" mat-flat-button>Simular Operación</button>
                    </div>
                    
                  </div>
                </div>
        
              </div>
        
        
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false && cedidas.length > 0">
              <numbered-pagination  [pageSize]="8"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>


            </div>

          </div>

        </div>

        <div *ngIf="tab3" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" >


          <div class="row" style="margin-top: 15px;">

            <!--Loading-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && descartadas.length == 0">

              <div class="container-loading">
        
                
                <div class="img-loading">
                  <img src="../../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                </div>
        
                <div style="margin-top: 20px;">
                  <mat-label class="label-loading">
                    <div>La información está siendo procesada.</div>
                    <div>Esto no tomará mucho tiempo.</div>
                  </mat-label>
                </div>
        
              </div>
        
        
            </div>

            <div class="col-xs col-box" *ngFor="let _ of descartadas">

              <div class="box-operation" style="border-top: solid 4px var(--simpli-color8);">

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">N° Simulación</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.id}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Fecha creación</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.date_created}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Monto</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">${{formatMoney(_.monto_operacion)}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">N° de factura</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <mat-label class="label-secondary">{{_.facturas}}</mat-label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: justify;">
                    <mat-label class="label-primary">Estado</mat-label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                    <span><i style="color: var(--simpli-color8)" class="fa-regular fa-circle-exclamation success-icon"></i></span>
                    <mat-label class="label-secondary">Descartada</mat-label>
                  </div>
                </div>


                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;margin-top: 15px;">
                    <mat-label (click)="ver_detalle(_.simulacion_id, _.id)" class="label-primary2">Ver Detalle</mat-label><span>
                      <i style="margin-top: 10px;margin-left: 10px;color: var(--simpli-secondary);" class="fa-solid fa-chevron-right"></i></span>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && descartadas.length == 0">

              <div class="empty-msg">
        
                
                <div style="padding-top: 50px;text-align: center;">
                  <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                </div>
        
                <div style="padding-top: 50px;text-align: center;">
                  <mat-label class="label-bienvenida">
                    <div class="label-empty">
                      ¡Ups! Al parecer, no tienes operaciones para visualizar.
                    </div>

                    <div class="label-empty" style="margin-top: 20px;">
                      Realiza una simulación para que puedas comenzar a operar.
                    </div>

                  </mat-label>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
                    <div class="btn-simpli-container">
                      <button class="primary-button" (click)="pagar_proveedores()" mat-flat-button>Simular Operación</button>
                    </div>
                    
                  </div>
                </div>
        
              </div>
        
        
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false && descartadas.length > 0">
              <numbered-pagination [pageSize]="8"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>


            </div>

          </div>

        </div>

      </div>




    </div>

</div>