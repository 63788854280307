
<div class="row row_main bottom-responsive">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
      <h1 class="titulo-simpli">Selecciona las facturas y agrégalas</h1>
      <small class="subtitulo-simpli">Si no encuentras las facturas en el listado, selecciona la opción "Agregar más facturas"</small>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="ELEMENT_DATA.length > 0">
    <form [formGroup]="valFormFechas">
        <div class="row" style="width: 100%;">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12"></div>
            <div class="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                <mat-form-field appearance="fill" color="primary">
                    <mat-label>Fecha</mat-label>
                    <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" >
                        <input matStartDate readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                        <input matEndDate readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
                    </mat-date-range-input>
                    <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker>
                        <mat-date-range-picker-actions style="justify-content: center;">
                            <button class="secondary-button" (click)="reset()" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                            <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="filtrar_por_fechas()">Aplicar</button>
                            </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4 col-xs-12" style="text-align: center;">
                <button matTooltip="Agrega tus facturas aquí, en caso de no encontrarlas en el listado." style="width: 100%;" type="button" class="btn-simpli" mat-flat-button (click)="open_agregar_facturas_dialog()">Facturas</button>
            </div>
        </div>
    </form>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && ELEMENT_DATA.length == 0">

    <div class="container-loading">

        
        <div class="img-loading">
        <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
        </div>

        <div style="margin-top: 20px;">
        <mat-label class="label-loading">
            <div>La información está siendo procesada.</div>
            <div>Esto no tomará mucho tiempo.</div>
        </mat-label>
        </div>

    </div>


    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" *ngIf="loading == false && ELEMENT_DATA.length > 0">

            <mat-card class="card-tabla desktop-table" style="position: relative;">
        
                <div class="table-responsive-simpli add_factoring">
        
                <table mat-table [dataSource]="dataSource">
        
        
                    <ng-container matColumnDef="check">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px !important;">      
                        <mat-checkbox style="display: none;" matTooltip="Seleccionar página" color="primary" (change)="$event ? masterToggle($event) : null" [checked] = "selection.hasValue() && isAllSelected()" (click)="selectAllElements()">
                        </mat-checkbox>
                    </th>
        
                    <td mat-cell *matCellDef="let element" style="width: 50px !important;text-align: justify;padding-left: 22px !important;"> 
        
                        <div style="border-radius: 3px;transition: all .3s ease-in-out;" (click)="$event.stopPropagation()">
                            <input type="checkbox" [disabled]="element.reemitida == false" (click)="selectOneElement(element.venta_id, element); $event.stopPropagation()" 
                            (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                        </div>
        
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="deudor">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 200px !important;"> Deudor </th>
                    <td mat-cell *matCellDef="let element" style="min-width: 200px !important;"> 
                        <input class="no-input" readonly value="{{element.receptoracreedor.business_name}}" style="text-align: justify;">
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="factura">
                    <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                    <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.folio}}">
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="rut">
                    <th mat-header-cell *matHeaderCellDef> RUT </th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input" readonly value="{{element.receptoracreedor.rut}}">
                    </td>
                    </ng-container>
        
                    <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef> Fecha de emisión </th>
                    <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.fecha_emision}}">
                    </td>
                    </ng-container>
        
                    <ng-container matColumnDef="monto">
                    <th mat-header-cell *matHeaderCellDef> Monto </th>
                    <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="${{formatMoney(element.mnt_total)}}">
                    </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                
        
                </div>
        
            </mat-card>

            <mat-card class="card-tabla resposive-table" style="position: relative;margin-top: 20px !important;padding-left: 0px !important;padding-right: 0px !important;">

                <table mat-table [dataSource]="dataSource" class="table-res">
        

                  <ng-container matColumnDef="data">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
                      
                      <div class="box-factura" style="position: relative;" (click)="$event.stopPropagation()">


                            <div style="border-radius: 3px;transition: all .3s ease-in-out;position: absolute;top: 5px;left:5px;" (click)="$event.stopPropagation()">
                                <input type="checkbox" (click)="selectOneElement(element.venta_id, element); $event.stopPropagation()" 
                                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)" style="width: 20px; height: 20px;">
                            </div>

                            <div class="row" style="margin-top: 25px;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                                <div class="container-label-res"><mat-label style="width: 20%;" class="left-label">Deudor:</mat-label><mat-label class="right-label">{{element.receptoracreedor.business_name}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{element.folio}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">RUT:</mat-label><mat-label class="right-label">{{element.receptoracreedor.rut}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Emisión:</mat-label><mat-label class="right-label">{{element.fecha_emision}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.mnt_total)}}</mat-label></div>
            
                                <div><mat-label class="pink-label">Ver detalle <i class="fa-solid fa-angle-right"></i></mat-label></div>
                                </div>
                            </div>

                      </div>
                      
                      </td>
                  </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                  <tr mat-row *matRowDef="let row; let doc; columns: displayedColumns2;" ></tr>
                </table>
                
        
            </mat-card>
        
            <!--Footer-->
            <div id="footer-options-bar" class="row animate__animated animate__fadeIn" *ngIf="!simular_disabled">
        
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;height: 10px;display: none;">
                <i class="fa-solid fa-caret-up" style="font-size: 30px;margin-top: -10px;color:var(--simpli-color6)"></i>
                </div>
        
                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-top">
        
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <input class="no-input-simpli" readonly value="Clientes seleccionados:">
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                        <input class="no-input-simpli-strong" readonly value="{{total_proveedores}}">
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <input class="no-input-simpli" readonly value="Facturas seleccionadas:">
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                        <input class="no-input-simpli-strong" readonly value="{{ arr_documentos_to_simulate.length }}">
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <input class="no-input-simpli" readonly value="Monto total:">
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                        <input class="no-input-simpli-strong" readonly value="${{total_facturas}}">
                        </div>
                    </div>
            
        
                </div>
        
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top">
                    <button class="primary-button" [disabled]="simular_disabled"  mat-flat-button style="width: 100%;margin: 0;margin-top: 15px;" (click)="simular()">Agregar</button>
                </div>
        
            </div>

    </div>


    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && ELEMENT_DATA.length == 0">

        <div class="empty-msg" style="margin-top: 100px;">
    
          
          <div style="padding-top: 50px;text-align: center;">
            <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
          </div>
    
          <div style="padding-top: 50px;text-align: center;">
            <mat-label class="label-bienvenida">
              <div class="label-empty" style="font-weight: bold;">
                Agrega facturas individualmente o 
              </div>
    
              <div class="label-empty" style="font-weight: bold;">
                carga una nómina completa. ¡Tú eliges!
              </div>
    
            </mat-label>
          </div>
    
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
              <div class="btn-simpli-container">
                <button class="secondary-button" (click)="open_agregar_facturas_dialog()"  mat-flat-button>Agregar factura</button>
    
                <button class="secondary-button"  (click)="open_agregar_facturas_by_nomina();" mat-flat-button>Cargar nómina</button>
              </div>
              
            </div>
          </div>
    
        </div>
    
    
      </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length > 0">
        <div class="btn-simpli-container" style="text-align: right;margin-top: 10px;">
            <button class="secondary-button" mat-flat-button (click)="volver()">Volver</button>
        </div>
    </div>
    
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false && ELEMENT_DATA.length > 0">
    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
    </div>





</div>

