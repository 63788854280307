<div class="row w-100" style="position: relative;">

  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Libro de ventas</h1>
    <small class="subtitulo-simpli"></small>
  </div>

  <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
    <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
  </div>
  
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" *ngIf="paso_1">

    <form [formGroup]="firstFormGroup" *ngIf="this.has_simulation">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input matInput formControlName="filter" (change)="reset($event.target.value)">
            <mat-icon matTooltip="Buscar" matSuffix class="btn_suffix" (click)="filtrar()">search</mat-icon>
            <mat-hint>Introduce una palabra clave y dale click a la lupa para filtrar.</mat-hint>
          </mat-form-field>
        </div>

        <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top" style="text-align: right;">
          <button mat-flat-button class="primary-button" (click)="Borrar_Filtros()" type="button" style="margin: top 20px;">Limpiar filtros</button>
          <button mat-flat-button class="primary-button" type="button" style="margin: top 20px;">Buscar</button>
        </div> -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="table-responsive">

            <table mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="text-align: center;">

                  <div style="border-radius: 3px;transition: all .3s ease-in-out;">
        
                    <input [disabled]="row.fecha_vencimiento == null || row.fecha_vencimiento == ''" type="checkbox" [checked]="row.simulado==true? row.simulado:selection.isSelected(row)"
                      (click)="activa(row.id, row, $event); $event.stopPropagation()">
                  </div>

                </td>
              </ng-container>
        

        
              <ng-container matColumnDef="folio">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 110px;"> Folio </th>
                  <td mat-cell *matCellDef="let element;" style="max-width: 110px;" >
                      <input class="no-input" value="{{element.folio}}" readonly> 
                  </td>
              </ng-container>
          
              <ng-container matColumnDef="receptoracreedor_name">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Deudor </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}" style="min-width: 130px;"> 
                      <input class="no-input"  value="{{element.receptoracreedor_name}}" style="text-align: left;" readonly> 
                  </td>
              </ng-container>
        
              <ng-container matColumnDef="receptoracreedor_rut">
                <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
                <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                    <input class="no-input"  value="{{element.deudor}}" readonly> 
                </td>
              </ng-container>
        
              <ng-container matColumnDef="mnt_total">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                    <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                      <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
                  </td>
              </ng-container>
        
              <ng-container matColumnDef="fecha_emision">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Fecha Emisión </th>
                  <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                  <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
                  </td>

              </ng-container>

              <ng-container matColumnDef="fecha_vencimiento">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Fecha vencimiento</th>
                <td mat-cell *matCellDef="let element; let i=index, let index=index" style="text-align: center;cursor: pointer;min-width: 250px;">

                    <div style="position: relative;width: 100%;" (click)="i.open()">
                        <input [ngClass]="{'no-input':true,
                                            'vencida':element.color === 'rojo',
                                            'porvencer':element.color === 'blanco'}"  class="no-input" placeholder="dd/mm/yyyy" value="{{element.fecha_vencimiento}}" readonly/>
                    </div>




                    <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                        <mat-label>Fecha de vencimiento</mat-label>
                        <input  [min]="minDate" matInput [matDatepicker]="i" (dateChange)="fecha_individual($event, index)" (click)="i.open()" formControlName="secondCtrl">
                        <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                        <mat-datepicker #i></mat-datepicker>
                    </mat-form-field>
            
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>


        
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>

            </table>
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageCustom($event)"> </mat-paginator>
        
          </div>
        </div>
      </div>

      <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" >Cerrar</button>
      <button  mat-flat-button (click)="agregar_facturas()" [disabled]="this.arr_documentos_to_simulate.length == 0" class="primary-button" type="button" >Facturas </button>
    </form>

    <div *ngIf="!this.has_simulation && !cargando" class="box_placeholder" style="margin-top: 7%;cursor:pointer;width: 1250px;">
      <i class="material-icons ball"> assessment </i>
      <div style="margin-top: -20px;">No tienes facturas disponibles para pagar la deuda, <strong>la deuda actual es de ${{formatMoney(deuda)}}</strong></div>
    </div>

    <div *ngIf="!this.has_simulation && !cargando" style="margin-bottom: 40px;margin-top: 20px;display: inline-flex;">
      <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" >Cerrar</button>
    </div>

    <div *ngIf="cargando" class="box_placeholder" style="margin-top: 15%;cursor:pointer;width: 1250px; text-align: center;">
      <div style="margin-top: -20px;">Espera unos segundos, estamos actualizando tus facturas</div>
    </div>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" *ngIf="paso_2">

    <div class="row">
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="paso_2">
        <h3 class="titulo-simpli">Facturas Vinculadas</h3>
      
        <mat-card class="shadow-box">
          <mat-card-content>
      
            <div class="table-responsive col-top" style="margin-top: 30px;" >
      
      
      
              <table mat-table [dataSource]="dataSource_2">
      
      
      
                <ng-container matColumnDef="folio">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 110px;"> Folio </th>
                    <td mat-cell *matCellDef="let element;" style="max-width: 110px;" >
                        <input class="no-input" value="{{element.folio}}" readonly> 
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="receptoracreedor_name">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Deudor </th>
                    <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}" style="min-width: 130px;"> 
                        <input class="no-input"  value="{{element.receptoracreedor_name}}" style="text-align: left;" readonly> 
                    </td>
                </ng-container>
          
                <ng-container matColumnDef="receptoracreedor_rut">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
                  <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                      <input class="no-input"  value="{{element.deudor}}" readonly> 
                  </td>
                </ng-container>
          
                <ng-container matColumnDef="mnt_total">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                      <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                        <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
                    </td>
                </ng-container>
          
                <ng-container matColumnDef="fecha_emision">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Fecha Emisión </th>
                    <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                    <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
                    </td>
      
                </ng-container>
      
                <ng-container matColumnDef="fecha_vencimiento">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Fecha vencimiento</th>
                  <td mat-cell *matCellDef="let element; let i=index, let index=index" style="min-width: 130px;">
      
                    <input   class="no-input" value="{{element.fecha_vencimiento}}" readonly> 
              
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
      
      
          
                <tr mat-header-row *matHeaderRowDef="displayedColumns_2; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; let element; columns: displayedColumns_2"></tr>
      
              </table>
      
              <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" ></mat-paginator>
          
      
      
      
            </div>
      
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="paso_2">
        <h3 class="titulo-simpli">Simulación generada</h3>
        <mat-card class="shadow-box">
          <mat-card-content>
      
                <!--Anticipo-->
                <div class="row col-top">
      
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <small><input class="no-input" value="95% Anticipo de Factura" readonly></small>
                  </div> 
            
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="$ {{this.formatMoney(this.simulacion_recibida.anticipo)}}" readonly style="text-align: right;">
                  </div>
            
                  </div>
            
                  <!--vencimiento promedio-->
                  <div class="row">
            
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input class="no-input" value="Vencimiento promedio" readonly>
                      </div> 
            
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input class="no-input" value="{{simulacion_recibida.vencimiento}} Días" readonly style="text-align: right;">
                      </div>
            
                  </div>
            
                  <mat-divider class="separador5"></mat-divider>
            
                  <!--Comision-->
                  <div class="row">
            
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="Comisión" readonly>
                  </div> 
            
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="$ {{formatMoney(this.simulacion_recibida.comision)}}" readonly style="text-align: right;">
                  </div>
            
                  </div>
            
                  <!--Gastos operaciones-->
                  <div class="row">
            
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="Gastos Operaciones" readonly>
                  </div> 
            
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                      <input class="no-input" value="$ {{formatMoney(this.simulacion_recibida.gastosOperacionales)}}" readonly style="text-align: right;">
                  </div>
            
                  </div>
            
                  <!--IVA-->
                  <div class="row">
            
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input class="no-input" value="IVA" readonly>
                      </div> 
              
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input class="no-input" value="$ {{this.formatMoney(this.simulacion_recibida.iva_operaciones)}}" readonly style="text-align: right;">
                      </div>
            
                  </div>
            
            
                  <!--Diferencia de precio-->
                  <div class="row">
            
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input class="no-input" value="Diferencia de Precio" readonly>
                      </div> 
              
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input class="no-input" value="$ {{formatMoney(simulacion_recibida.interesTotal)}}" readonly style="text-align: right;">
                      </div>
              
                  </div>
            
                  <mat-divider class="separador5"></mat-divider>
            
                  <!--monto liquido-->
                  <div class="row">
            
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Monto Liquido a Recibir" readonly>
                      </div> 
              
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                          <input  class="no-input" value="$ {{formatMoney(simulacion_recibida.liquidoRecibir)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                      </div>
                  </div>
            
                  <div class="row">
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Total giro a proveedores" readonly>
                    </div> 
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input  class="no-input" value="$ {{formatMoney(this.deuda_total)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
                    </div>
                  </div>
            
                  <div class="row">
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input *ngIf="debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Saldo a tu cuenta" readonly>
                        <input *ngIf="!debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a pagar a Arrayán" readonly>
                    </div> 
            
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-top" style="margin: 0;">
                        <input  class="no-input" value="$ {{formatMoney(por_pagar)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
            
            
                    </div>
                  </div>
            
      
          </mat-card-content>
        </mat-card>
      
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="paso_2">
        <button  mat-flat-button (click)="anterior()"  class="primary-button"  type="button" >Anterior</button>
        <button  mat-flat-button [disabled]="disabled" (click)="cerrar_y_continuar()"  class="primary-button"  type="button" >Aceptar y Continuar</button>
      </div>
    </div>
  </div>
</div>
