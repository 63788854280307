import { Component, OnInit } from '@angular/core';
declare var $ :any;

@Component({
  selector: 'app-editar-facturas-compra',
  templateUrl: './editar-facturas-compra.component.html',
  styleUrls: ['./editar-facturas-compra.component.less']
})
export class EditarFacturasCompraComponent implements OnInit {

  id_simulacion:any;

  constructor() { }

  ngOnInit() {
  }

}
