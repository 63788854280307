<div mat-dialog-title>
  <div class="titulo-onboarding">

    @if(step == 1){
      Agenda tu reunión de forma inmediata
    }

    @if(step == 2){
      ¡todo listo!, tu reunión esta agendada
    }


    <div mat-dialog-close class="close-dialog-icon"><i class="fa-sharp fa-regular fa-xmark"></i></div>
  </div>
  <div class="subtitulo-onboarding">
      @if(step == 1){
        Asigna la fecha y hora para la videollamada.
      }
  
  </div>
</div>

<div mat-dialog-content>

  @if(step == 1){

    @if(loading_feriados){
      <loading-interno></loading-interno>
    }@else{
      <mat-card class="calendar">
        <mat-calendar [dateFilter]="dateFilter" [(selected)]="selected" (selectedChange)="sel_fecha($event)"></mat-calendar>
      </mat-card>
    }
  
  
    @if(selecciono_fecha){
      <div class="container-hour">
        <div class="hour-sel" [ngClass]="{ 'selected': selectedIndex === idx }" (click)="selectHour(idx, i.hora)" *ngFor="let i of bloques; let idx = index">{{i.hora}}</div>
      </div>
    }
  
    @if(hora_seleccionada != null){
      <div style="margin-top: 30px;text-align: center;">
        <div style="font-size: 15px;">Programaste una reunión para el <strong>{{fecha_mandar}}</strong> a las <strong>{{hora_seleccionada}}</strong> horas.</div>
      </div>
    }

  }



  @if(step == 2){
    <div class="image-container" style="margin-top: 130px;margin-bottom: 40px;">
      <i  style="font-size: 50px;color:var(--simpli-secondary)" class="fa-solid fa-circle-check"></i>
    </div>
  
    <div class="label-container" style="text-align: center;">
      Enviamos el resumen del día y hora marcada a tu correo.
    </div>
  }



</div>

<div mat-dialog-actions style="justify-content: center !important;">
  <div class="caja-botones" style="text-align: center !important;">
    @if(step == 1){
      <button mat-stroked-button [disabled]="loading || hora_seleccionada == null" class="primary-button" (click)="agendar()">
        <span *ngIf="loading == false" style="text-decoration: none;color: inherit">Agendar reunion</span>
        <span *ngIf="loading == true" style="color:var(--simpli-secondary)"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
      </button>
    }@else{
      <button mat-stroked-button class="primary-button" (click)="continuar()">Continuar</button>
    }
    
  </div>
</div>





