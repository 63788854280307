import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { JoyrideService } from 'ngx-joyride';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;


@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.less']
})
export class BienvenidaComponent implements OnInit {


  constructor(
    private router: Router,
    public settings: SettingsService,
    public dialog: MatDialog,
    private readonly joyrideService: JoyrideService) {

    }

  @Input() id_company:any
  @Input() parent:any;
  is_responsive:boolean=false;

  received: Array<any> = [];

  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.get_mensaje();


  }

  //1 =>  <!--nuevo & sincronizado-->
  //2 =>  <!--nuevo & NO sincronizado-->
  //3 =>  <!--viejo & sincronizado & sin automaticas-->
  //4 =>  <!--viejo & sincronizado & con automaticas-->
  //null => loading
  step_to_show:any=null;
  async get_mensaje() {
    try {
      const bd: any = await this.settings.get_query(1, `/cliente/onboarding-message/${this.id_company}/`);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.step_to_show = data.info;


          if(this.step_to_show ==1){
            setTimeout(() => {
              this.onClick()
            }, 2000);
          }

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.settings.manage_errors(bd.status, "operacionesPorCeder", bd, false);
    }
  }

  openDialog() {
    var dialogRef:any;


    if(this.is_responsive == true){
       dialogRef = this.dialog.open(CredencialesSII, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive',
        autoFocus: false,
        disableClose: false,
      });
    }else{
       dialogRef = this.dialog.open(CredencialesSII, {
        width: '600px',
        autoFocus: false
      });
    }


 
    dialogRef.componentInstance.id_company = this.id_company
    dialogRef.componentInstance.token = localStorage.getItem('token');

    dialogRef.afterClosed().subscribe((result:any) => {

      this.step_to_show = 1;
      this.parent.get_credenciales();
      setTimeout(() => {
        this.onClick()
      }, 2000);
    });
  }

  onClick() {

    this.joyrideService.startTour(
        { 
          steps: ['simular', 'confpp', 'pp','credenciales','operaciones','cartola'],
          stepDefaultPosition: 'bottom',
          themeColor: '#757575',
          showCounter: false,
          showPrevButton: false,
        } 
    );

    $("html, body").animate({ scrollTop: 0 }, "slow");

  }

  pagar_proveedores(){
    this.parent.switch_case(6)
  }

  simular(){
    this.parent.switch_case(1)
  }
  
  go_to_simulaciones(){
    if(this.is_responsive == true){
      this.router.navigate(['home/simulaciones-r', this.id_company])
    }else{
      this.router.navigate(['home/simulaciones'])
    }
  }





}