
<div class="pagination">

  <!-- <div class="page-item" [class.disabled]="currentPage === 1">
    <div class="page-link" (click)="onPageChange(currentPage - 1)" aria-label="Previous">
      <span aria-hidden="true"><</span>
    </div>
  </div> -->

  <!-- Páginas visibles -->
  <div *ngFor="let page of displayedPages" class="page-item page-item2 animate__faster animate__animated animate__backInRight" [class.active]="page === currentPage">
    <div class="page-link" (click)="onPageChange(page)">
      {{ page }}
    </div>
  </div>


  <!-- <div class="page-item" [class.disabled]="currentPage === totalPages">
    <div class="page-link" (click)="onPageChange(currentPage + 1)" aria-label="Next">
      <span aria-hidden="true">></span>
    </div>
  </div> -->
</div>