<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli" style="position: relative;">Facturas de Compra</h1>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;min-height: 200px;">

    <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
      <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
    </div>

    <div class="row row-giro">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <small class="subtitulo-2">Giro a tu cuenta</small>
      </div>
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
        <div class="not-account-msg animate__animated animate__fadeIn" *ngIf=" !cargando && !has_accounts">
          &nbsp; <strong>No tiene una cuenta bancaria vinculada</strong>, dale click al icono amarillo y agrega una Cuenta Bancaria.
        </div>
      
        <div class="not-account-msg animate__animated animate__fadeIn" *ngIf=" !cargando && has_accounts && !id_banco_selected">
          &nbsp; El giro a tu cuenta <strong>no tiene una cuenta bancaria vinculada</strong>, dale click al icono amarillo y agrega una nueva o selecciona una Cuenta Bancaria.
        </div>
      
        <div class="success-account-msg animate__animated animate__fadeIn" *ngIf=" !cargando && has_accounts && id_banco_selected">
          &nbsp; El giro a tu cuenta <strong>tiene una cuenta bancaria vinculada</strong>, dale click al icono verde y agrega una nueva o selecciona otra si deseas cambiar tu Cuenta Bancaria.
        </div>
      
        <div class="table-responsive">
      
      
      
          <table mat-table [dataSource]="dataSourceGiro">
      
      
            <ng-container matColumnDef="cuentas">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Cuenta </th>
              <td mat-cell *matCellDef="let element" matTooltip="Cuenta" style="min-width: 130px;text-align: center;position: relative;"> 
                <i *ngIf="element.has_account == false && !has_accounts && !id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta" class="fa-light fa-exclamation-circle not-account-icon"></i>
                <i *ngIf="element.has_account != false && has_accounts && id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta" class="fa-light fa-plus-circle add-icon"></i>
                <i *ngIf="element.has_account == false && has_accounts && !id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta" class="fa-light fa-exclamation-circle not-account-icon"></i>
                    
                <mat-form-field class="selector-bank">
                  <mat-select [(value)]="id_banco_selected" (selectionChange)="change_bank($event)" >
                    <mat-option *ngFor="let account of element.cuentas" [value]="account.id" [disabled]="!is_not_done">
                      {{account.bank.name + ' | ' + account.tipo_cuenta + ' ' + account.number}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
        
            <ng-container matColumnDef="acreedorName">
                <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Acreedor Legal </th>
                <td mat-cell *matCellDef="let element" matTooltip="{{element.acreedorName}}" style="min-width: 130px;"> 
                    <input class="no-input"  value="{{element.acreedorName}}" style="text-align: left;" readonly> 
                </td>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
            <ng-container matColumnDef="CuentaRut">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.CuentaRut}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
            <ng-container matColumnDef="CuentaName">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Nombre Cuenta </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"   value="{{element.CuentaName}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
            <ng-container matColumnDef="TipoCuenta">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Tipo Cuenta </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"   value="{{element.TipoCuenta}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
      
            <ng-container matColumnDef="bankName">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Banco </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.bankName}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
            <ng-container matColumnDef="totalGiro">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
      
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Estado </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px; justify-content: center;" matTooltip="{{ element.status==0 ? 'Creado' : element.status==1 ? 'En proceso' : 'Pagado' }}"> 
                
                <table style="width: 90%;">
                  <tr>
                    <td style="text-align: right;">
                      <i [ngClass]="{fas: true, 
                                    'fa-check-circle': element.status==2 || element.status==0,
                                    'fa-spinner': element.status==1,
                                    'done-status': element.status==2, 
                                    'on-process-status': element.status==1, 
                                    'created-status': element.status==0}"></i>
                    </td>
                    <td style="text-align: left;">
                      <i [ngClass]="{fas: true, 
                                    'fa-file-download': true,
                                    'done-status-link': element.link!=null,
                                    'created-status-link': element.link==null}" (click)="download_file(element.link)"></i>
                    </td>
                  </tr>
                </table>
                            
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumnsGiro; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsGiro"></tr>
      
          </table>
      
        </div>
      
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <div class="table-responsive ">



          <table mat-table [dataSource]="dataSource">
  
  
            <ng-container matColumnDef="folio">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Folio </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.folio}}" style="min-width: 130px;text-align: center;position: relative;"> 
                <i *ngIf="is_not_done" matTooltip="Eliminar" class="fa-light fa-minus-circle deleteicon" [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" ></i>
                
                  <input class="no-input"  value="{{element.folio}}" style="text-align: center;" readonly> 
  
                  <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                    <mat-card>
                      <mat-card-content>
                        <small>¿Eliminar esta factura?</small><br>
  
                        <button  type="button" (click)="eliminar(element.id_compras)" style="margin-top: 10px;">Eliminar</button>
  
                      </mat-card-content>
                    </mat-card>
                  </mde-popover>
              </td>
              <td mat-footer-cell *matFooterCellDef style="text-align: center;"></td>
            </ng-container>
  
        
            <ng-container matColumnDef="acreedorName">
                <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Acreedor Legal </th>
                <td mat-cell *matCellDef="let element" matTooltip="{{element.acreedorName}}" style="min-width: 130px;"> 
                    <input class="no-input"  value="{{element.acreedorName}}" style="text-align: left;" readonly> 
                </td>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
            <ng-container matColumnDef="CuentaRut">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.CuentaRut}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
  
            <ng-container matColumnDef="CuentaName">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Nombre Cuenta </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"   value="{{element.CuentaName}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
  
            <ng-container matColumnDef="TipoCuenta">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Tipo Cuenta </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"   value="{{element.TipoCuenta}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">Total Compras </td>
            </ng-container>
  
            <ng-container matColumnDef="bankName">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Banco </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.bankName}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
      
      
            <ng-container matColumnDef="totalGiro">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">$ {{formatMoney(total_tabla)}} </td>
          </ng-container>
  
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Estado </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px; justify-content: center;" matTooltip="{{ element.status==0 ? 'Creado' : element.status==1 ? 'En proceso' : 'Pagado' }}"> 
              
              <table style="width: 90%;">
                <tr>
                  <td style="text-align: right;">
                    <i [ngClass]="{fas: true, 
                                  'fa-check-circle': element.status==2 || element.status==0,
                                  'fa-spinner': element.status==1,
                                  'done-status': element.status==2, 
                                  'on-process-status': element.status==1, 
                                  'created-status': element.status==0}"></i>
                  </td>
                  <td style="text-align: left;">
                    <i [ngClass]="{fas: true, 
                                  'fa-file-download': true,
                                  'done-status-link': element.link!=null,
                                  'created-status-link': element.link==null}" (click)="download_file(element.link)"></i>
                  </td>
                </tr>
              </table>
                          
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; let element; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  
          </table>

          <div class="success-account-msg animate__animated animate__fadeIn" *ngIf="!is_not_done">
            &nbsp; No es posible modificar la operación porque está <strong>Girada</strong>
          </div>
  
          <mat-paginator #paginator [pageSizeOptions]="[20, 50, 100, 500]"></mat-paginator>
      
  
  
  
        </div>
        
      </div>
    </div>

</div>

<div style="margin-top: 40px;margin-bottom: 20px;">
    <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" >Cerrar</button>
    <button  *ngIf="is_not_done" mat-flat-button class="primary-button"  type="button"  (click)="open_compras()">Facturas</button>
</div>
