<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <h1 style="text-align: center;" class="titulo-simpli">Revisa los AEC que generaste</h1>
    <small style="display: block;text-align: center;" class="subtitulo-simpli">Puedes descargar los AEC y ver la factura asociada a cada archivo.</small>



  </div>
</div>

<mat-dialog-content>

  <div class="row">


      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-7 col-top">

            <mat-form-field appearance="fill" color="primary">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilter($event)">
              <mat-icon matSuffix class="icon-suffix">search</mat-icon>
              <mat-hint>Buscar por folio o monto</mat-hint>
            </mat-form-field>
      </div>

      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-5 col-top">
        <div class="cointainer-fav-btn">
          <button [disabled]="loading_zip" class="icon-fav-btn" mat-mini-fab (click)="download_zip()">
            <span *ngIf="loading_zip == false"><i class="fa-solid fa-download"></i></span>
            <span *ngIf="loading_zip == true"><i style="color:gray;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
          </button>

          <button [disabled]="loading_delete_all" class="icon-fav-btn" mat-mini-fab (click)="delete_all_aec_dialog()">
            <span *ngIf="loading_delete_all == false"><i class="fa-solid fa-trash-can"></i></span>
            <span *ngIf="loading_delete_all == true"><i style="color:gray;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
          </button>
        </div>
      </div>
      


      <div *ngIf="show_error_mensaje" class="col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>

      <div *ngIf="show_error_mensaje"class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <div class="box-error" style="margin-top: 30px;margin-bottom: 50px;" *ngIf="manual == false">
          <div class="row">
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                  <div style="margin-top: 8px;">
                      <i style="font-size: 25px;" class="fa-regular fa-triangle-exclamation"></i>
                  </div>
              </div>
    
              <div  class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                <strong>¡Importante!</strong> Para continuar, debes generar los AEC que faltan. También puedes elegir otro método para ceder.
              </div>

    
          </div>
    
        </div>

        <div class="box-error" style="margin-top: 30px;margin-bottom: 50px;" *ngIf="manual == true">
          <div class="row">
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                  <div style="margin-top: 8px;">
                      <i style="font-size: 25px;" class="fa-regular fa-triangle-exclamation"></i>
                  </div>
              </div>
    
              <div  class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                ¡Reemplaza los AEC con error! Antes de cargar el AEC de reemplazo, verifica que el error no persiste y que el archivo corresponde a la factura.
              </div>

    
          </div>
    
        </div>
      </div>

      <div *ngIf="show_error_mensaje"class="col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>



      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="desktop-mode table-responsive-simpli add_factoring">


              <table mat-table [dataSource]="dataSource">
      
      
                <ng-container matColumnDef="operacion">
                  <th mat-header-cell *matHeaderCellDef> N° Operación </th>
                  <td mat-cell *matCellDef="let element"> 
                    <input class="no-input" readonly value="{{element.operation_id}}">
                  </td>
                </ng-container>
              
      
                <ng-container matColumnDef="folio">
                  <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                  <td mat-cell *matCellDef="let element">
                    <input class="no-input" readonly value="{{element.folio}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef> Monto </th>
                  <td mat-cell *matCellDef="let element"> 
                    <input class="no-input" readonly value="${{formatMoney(element.monto_factura)}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="factura">
                  <th mat-header-cell *matHeaderCellDef> Factura </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;"> 
                    <span *ngIf="element.pdf_file != null" style="cursor: pointer;" (click)="show_preview(element.pdf_file)"><i class="fa-duotone fa-eye" style="font-size: 20px;color:var(--simpli-primary);margin-top: 3px;"></i></span>
                    <span *ngIf="element.pdf_file == null" ><i class="fa-duotone fa-eye-slash" style="font-size: 20px;color:gray;margin-top: 3px;"></i></span>

                  </td>
                </ng-container>

                <ng-container matColumnDef="aec">
                  <th mat-header-cell *matHeaderCellDef> AEC </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;"> 
                    <span style="cursor: pointer;" [mdePopoverTriggerFor]="appPopovertable" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger" *ngIf="element.aec_file == null" style="color: #FA454C;text-decoration: underline;cursor: pointer;">Ver error</span>
                    <span *ngIf="element.aec_file != null" style="cursor: pointer;" (click)="download_aec(element.aec_file)"><i class="fa-duotone fa-download" style="font-size: 20px;color:var(--simpli-primary);margin-top: 3px;"></i></span>

                    <mde-popover #appPopovertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                      <mat-card class="popover-card" style="max-width: 370px;position: relative;">
  
                        <div class="row">
  
                          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                            <mat-label style="display: block;margin-bottom: 10px;">Revisa el desglose de los errores correspondientes a la carga del AEC.</mat-label>
  
                          </div>
  
                        </div>
  
  
  
                        <mat-divider style="background: white;"></mat-divider>

                        <div class="row" style="margin-top: 20px;">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row-detail-popover">-Falta cargar el AEC</div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.rut_cesionario">
                            <div class="row-detail-popover">-RUT cesionario no es el de Simpli.</div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.rut_company">
                            <div class="row-detail-popover">-RUT emisor no es de la empresa con la que esta enrolado.</div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.rut_receptor">
                            <div class="row-detail-popover">-RUT receptor/deudor no coincide con la simulación.</div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.aec_problem">
                            <div class="row-detail-popover">-{{element?.errors?.aec_problem}}</div>
                          </div>
                        </div>

  
                      </mat-card>
                    </mde-popover>
                  </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef > </th>
                  <td mat-cell *matCellDef="let element" style="width: 30px !important;text-align: center;">
                    <span *ngIf="loading_delete && element.aec_file != null"><i style="font-size: 20px;color:gray;margin-top: 3px;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                    <span *ngIf="!loading_delete && element.aec_file != null" style="cursor: pointer;" (click)="delete_aec_dialog(element.aec_file_id)"><i class="fa-light fa-trash-can" style="font-size: 20px;color:#1B0943;margin-top: 3px;"></i></span>

                  </td>
                </ng-container>
      
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'error-row':row.aec_file == null}"></tr>
              </table>

              <div *ngIf="facturas.length == 0" style="text-align: center;margin-top: 40px;margin-bottom: 40px;">
                <i style="color: gray; font-size: 25px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
              </div>
              
      
          </div>

          <div class="mobile-mode table-responsive-simpli add_factoring">

            <table mat-table [dataSource]="dataSource" class="table-res" style="margin-top: 0px;">
      
      
              <ng-container matColumnDef="operacion">
                <th mat-header-cell *matHeaderCellDef style="display: none;"></th>
                <td mat-cell *matCellDef="let element" style="padding: 5px !important;"> 
                  <div class="box-factura" (click)="$event.stopPropagation()">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                        <div class="container-label-res"><mat-label class="left-label">N° Operación:</mat-label><mat-label class="right-label">{{element.operation_id}}</mat-label></div>
                        <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{element.folio}}</mat-label></div>
                        <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.monto_factura)}}</mat-label></div>
                        <div style="margin-top: 10px;" class="container-label-res">
                          <mat-label class="left-label">Factura:
                            <span *ngIf="element.aec_file != null" style="cursor: pointer;margin-left: 10px;" (click)="show_preview(element.pdf_file)"><i class="fa-duotone fa-eye" style="font-size: 20px;color:var(--simpli-primary);margin-top: 3px;"></i></span>
                            <span *ngIf="element.aec_file == null" ><i class="fa-duotone fa-eye-slash" style="font-size: 20px;color:gray;margin-top: 3px;margin-left: 10px;"></i></span>
                          </mat-label>
                          <mat-label class="right-label" style="font-weight: 500 !important;">AEC:
                            <span style="cursor: pointer;" [mdePopoverTriggerFor]="appPopovertableres" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger" *ngIf="element.aec_file == null" style="color: #FA454C;text-decoration: underline;cursor: pointer;margin-left: 10px;">Ver error</span>
                            <span *ngIf="element.aec_file != null" style="cursor: pointer;margin-left: 10px;" (click)="download_aec(element.aec_file)"><i class="fa-duotone fa-download" style="font-size: 20px;color:var(--simpli-primary);margin-top: 3px;"></i></span>
        
                            <mde-popover #appPopovertableres="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                              <mat-card class="popover-card" style="max-width: 370px;position: relative;">
          
                                <div class="row">
          
                                  <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                    <mat-label style="display: block;margin-bottom: 10px;">Revisa el desglose de los errores correspondientes a la carga del AEC.</mat-label>
          
                                  </div>
          
                                </div>
          
          
          
                                <mat-divider style="background: white;"></mat-divider>
        
                                <div class="row" style="margin-top: 20px;">
                                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="row-detail-popover">-Falta cargar el AEC</div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.rut_cesionario">
                                    <div class="row-detail-popover">-RUT cesionario no es el de Simpli.</div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.rut_company">
                                    <div class="row-detail-popover">-RUT emisor no es de la empresa con la que esta enrolado.</div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="element.errors?.rut_receptor">
                                    <div class="row-detail-popover">-RUT receptor/deudor no coincide con la simulación.</div>
                                  </div>
                                </div>
        
          
                              </mat-card>
                            </mde-popover>
                          </mat-label>
                        </div>
  
                        <div>
                          <mat-label *ngIf="loading_delete && element.aec_file != null" class="red-label" (click)="delete_aec_dialog(element.aec_file_id)"><i style="font-size: 20px;color:gray;margin-top: 3px;" class="fa-solid fa-spinner fa-spin-pulse"></i></mat-label>
                          <mat-label *ngIf="!loading_delete && element.aec_file != null" class="red-label" (click)="delete_aec_dialog(element.aec_file_id)">Eliminar AEC <i style="font-size: 20px;" class="fa-light fa-trash-can"></i></mat-label>

                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
            
    

              <tr mat-header-row *matHeaderRowDef="displayedColumnsRes"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsRes;" [ngClass]="{'error-row':row.aec_file == null}"></tr>
            </table>

          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;">
              <mat-paginator [pageSizeOptions]="[7, 14, 28, 50]"></mat-paginator>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container" *ngIf="show_error_mensaje == true">
     
                <button *ngIf="manual == false"  class="primary-button" mat-flat-button (click)="volver_a_generar_aec()">
                  Generar AEC que faltan
                </button>

                <button *ngIf="manual == true"  class="primary-button" mat-flat-button (click)="cargar_aec()">
                  Reemplazar AEC
                </button>

                <button  class="secondary-button" mat-flat-button (click)="ceder_por_otro_meotodo()">
                  Ceder por otro método
                </button>
              </div>

              <div class="btn-simpli-container" *ngIf="show_error_mensaje == false">
     
                <button   class="primary-button" mat-flat-button (click)="ceder_facturas()">
                  Ceder facturas
                </button>


              </div>
              
            </div>
          </div>

      </div>
  </div>

</mat-dialog-content>