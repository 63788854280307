<div mat-dialog-title>

      <h1 class="titulo-simpli" *ngIf="step == 1 || step == 2">Carga tu nómina de facturas</h1>

      <h1 class="titulo-simpli" *ngIf="step == 3">Resumen de las facturas agregadas </h1>
      <small class="subtitulo-simpli" *ngIf="step == 3 && empty_facturas == false">Selecciónalas para ver toda la información.</small>


  </div>
  
  <div mat-dialog-content [ngStyle]="is_responsive ? {'min-height': '85vh'} : {}">

        <!--STEPPER CONTROL-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 1 || step == 2" style="margin-top: 40px;">

            <div class="container-stepper-simpli">

                <div id="step-simpli1" class="stepper-simpli stepper-simpli-active" (click)="change_step('step-simpli1')">
                <i class="fa-regular fa-download"></i>
                <div class="step-text">Descargar plantilla</div>

                <div class="line-step-simpli"></div>
                </div>


                <div id="step-simpli2" class="stepper-simpli" (click)="change_step('step-simpli2')">
                <i class="fa-regular fa-upload"></i>
                <div class="step-text">Subir nómina</div>

                </div>



            </div>

        </div>
  

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 1">
            <div class="l-simpli">Descarga la plantilla excel, ingresa la nómina</div>
            <div class="l-simpli">en ella y evita errores en el proceso.</div>
                
            <div class="l-simpli link-label" (click)="download_nomina()">Descarga la plantilla aquí</div>


            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        
                  <div [ngClass]="{'btn-simpli-container':true}">

                    <button class="secondary-button" mat-dialog-close mat-flat-button>
                      Cerrar
                    </button>

                    <button  mat-flat-button [ngClass]="{'primary-button':true}" (click)="change_step('step-simpli2')">
                      Continuar
                    </button>
                  </div>
                  
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 2">

            <div *ngIf="file_name == ''" class="l-simpli" style="margin-top: 20px;">Sube la plantilla (formato .XLSX),</div>
            <div *ngIf="file_name == ''" class="l-simpli"> con tu nómina ya ingresada</div>
            <div *ngIf="file_name != ''" class="l-simpli estilo-especial" style="margin-top: 20px;">¡Datos cargados! Si necesitas reemplazar el archivo, elimínalo y sube uno nuevo. </div>
                
            <div class="l-simpli link-label2">{{file_name}} <span (click)="delete_nomina()" matTooltip="Eliminar" *ngIf="file_name != ''" style="margin-left: 10px;cursor: pointer;" ><i class="fa-regular fa-xmark"></i></span></div>

            <input  id="upload_files" type="file" (change)="upload_file($event)" style="display: none;"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>


            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        
                  <div *ngIf="file_name == ''" [ngClass]="{'btn-simpli-container':true}">

                    <button class="secondary-button" mat-dialog-close mat-flat-button>
                      Cerrar
                    </button>

                    <button  mat-flat-button [ngClass]="{'primary-button':true}" (click)="subir_momina()">
                      Subir nómina
                    </button>
                  </div>

                  <div *ngIf="file_name != ''" [ngClass]="{'btn-simpli-container':true}">
                    <button [disabled]="loading" mat-flat-button [ngClass]="{'primary-button':true}" (click)="pasar_a_revision()">
                      <mat-label *ngIf="!loading">Continuar</mat-label>
                      <span *ngIf="loading"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                    </button>
                  </div>
                  
                </div>
            </div>
        </div>

        <!--RESUMEN DE LAS FACTURAS AGREGADAS-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 3">

            <div class="box-error" *ngIf="has_error == true">
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                        <div style="margin-top:8px;">
                            <i style="font-size: 25px;" class="fa-regular fa-circle-info"></i>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                      Algunas facturas presentan errores de formato. Corrige los datos dentro de los campos editables y selecciona “Guardar cambios” para continuar. 
                    </div>

                </div>

            </div>


            <div id="container-facturas" class="container-exp add_factoring" *ngIf="empty_facturas == false">
              <mat-accordion multi="true">
      
                <mat-expansion-panel [expanded]="(i.errorReemitida != null || i.errorFechaEmision != null || i.errorFolio != null || i.errorMonto != null || i.errorRut != null)" [ngClass]="{'error-facturas': i.errorFechaEmision != null || i.errorReemitida != null || i.errorFolio != null || i.errorMonto != null || i.errorRut != null, 'mas_alto':i.errorFolio != null && i.errorMonto != null}" style="margin-bottom: 15px;" *ngFor="let i of obs | async; let index=index">
      
                  <mat-expansion-panel-header style="position: relative;">
                    <mat-panel-title>
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="c-exp">
                            <div class="l-exp-i"><input class="no-input l-exp-i error-label-noeditable" readonly value="N° Factura:"></div>
                            <div class="l-exp-d">
                              <input *ngIf="i.errorFolio == null" class="no-input l-exp-d error-label-noeditable" readonly value="{{i.folio}}">

                              <input (click)="$event.stopPropagation();" id="folio_{{index}}" (change)="change_folio($event, index)" type="number" *ngIf="i.errorFolio != null" class="l-exp-d error-label" placeholder="{{i.folio}}">
                              <span matTooltip="{{i.errorFolio}}" *ngIf="i.errorFolio != null" class="material-icons-outlined floaticon">
                                  info
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
      
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="c-exp">
                            <div class="l-exp-i"><input class="no-input l-exp-i error-label-noeditable" readonly value="Monto:"></div>
                            <div class="l-exp-d">
          

                                <input *ngIf="i.errorMonto == null" class="no-input l-exp-d" readonly value="${{formatMoney(i.monto)}}">
                                <input (click)="$event.stopPropagation();" id="monto_{{index}}" (keyup)="format_monto($event, index)" (change)="change_monto($event, index)" type="text" *ngIf="i.errorMonto != null" class="l-exp-d error-label" placeholder="{{i.monto}}">
                                <span matTooltip="{{i.errorMonto}}" *ngIf="i.errorMonto != null" class="material-icons-outlined floaticon">
                                    info
                                </span>
 
                            </div>
                          </div>
                        </div>
                      </div>
      
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="c-exp">
                            <div class="l-exp-i"><input class="no-input l-exp-i error-label-noeditable" readonly value="Deudor:"></div>
                            <div class="l-exp-d"><input class="no-input l-exp-d error-label-noeditable" readonly value="{{i.deudor}}"></div>
                          </div>
                        </div>
                      </div>
                    </mat-panel-title>
      
                    <mat-panel-description class="exp-desc">
                      <div class="icon-mas-alto" style="margin-top: 20px;margin-right: 10px;z-index: 999999999;" matTooltip="Eliminar factura" (click)="delete_factura(i.folio);$event.preventDefault();"><i class="fa-regular fa-trash exp-delete"></i></div>
                    </mat-panel-description>
      
                  </mat-expansion-panel-header>
      
      
      
                      <div class="row" style="margin-top: 20px;">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="c-exp">
                            <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="RUT:"></div>
                            <div class="l-exp-d">
  
                                <input *ngIf="i.errorRut == null" class="no-input l-exp-d" readonly value="{{i.rut}}">
                                <input (click)="$event.stopPropagation();" id="rut_{{index}}" (keyup)="rut_key($event, index)" *ngIf="i.errorRut != null" class="l-exp-d error-label-rut" placeholder="{{i.rut}}">
                                <span matTooltip="{{i.errorRut}}" *ngIf="i.errorRut != null" class="material-icons-outlined floaticonrut">
                                    info
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
      
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="c-exp">
                            <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Fecha emisión:"></div>
                            <div class="l-exp-d">
                                <input *ngIf="i.errorFechaEmision == null" class="no-input l-exp-d" readonly value="{{i.fecha_emision}}">

                                <input (click)="picker2.open();$event.stopPropagation();" id="fecha_{{index}}"  *ngIf="i.errorFechaEmision != null" class="l-exp-d error-label-rut" placeholder="{{i.errorFechaEmision}}">
                                <span matTooltip="{{i.errorFechaEmision}}" *ngIf="i.errorFechaEmision != null" class="material-icons-outlined floaticonrut">
                                    info
                                </span>

                                <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                                <input matInput readonly [matDatepicker]="picker2" (dateChange)="fecha_individual_emision($event, index)">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                            </div>
                          </div>

                          <div class="c-exp" *ngIf="error_reemitida == true">
                            <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Error:"></div>
                            <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="Esta factura no es de reemplazo."></div>
                          </div>
                        </div>
                      </div>
      
      
                </mat-expansion-panel>
      
              </mat-accordion>
      
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
                <mat-paginator [pageSizeOptions]="[6, 12, 24]"></mat-paginator>
              </div>
            </div>


            <div class="sep" *ngIf="empty_facturas == false">
              <mat-label class="label-i">Monto total</mat-label>
              <mat-label class="label-d">${{formatMoney(monto_total)}}</mat-label>
            </div>
      
            <div class="row" *ngIf="empty_facturas == true">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      
                <div class="c-simpli">
      
                    <div style="text-align: center;margin-bottom: 30px;">
                      <i class="fa-regular fa-triangle-exclamation i-simpli"></i>
                    </div>
          
                    <div class="l-simpli">
                      Debes agregar por lo menos 1 factura
                    </div>
          
                    <div class="l-simpli">
                      para realizar la simulación
                    </div>
      
                </div>
      
      
              </div>
            </div>
      
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      
                <div [ngClass]="{'disable-btn':empty_facturas == true, 'btn-simpli-container':true}">
                  <button *ngIf="has_error == false && reemitida_mode == false" mat-flat-button [ngClass]="{'primary-button':true}" (click)="go_to_simular()">
                    <mat-label *ngIf="open_as_dialog == false">Iniciar simulación</mat-label>
                    <mat-label *ngIf="open_as_dialog == true">Continuar</mat-label>
                  </button>
      
                  <button *ngIf="has_error == false && reemitida_mode == false" mat-flat-button (click)="add_mas_facturas()" [ngClass]="{'secondary-button':true}">
                    Agregar más facturas
                  </button>

                  <button [disabled]="error_reemitida" *ngIf="reemitida_mode == true"  mat-flat-button [ngClass]="{'primary-button':true, 'disabled_btn':error_reemitida == true}" (click)="go_to_simular()">
                    Continuar
                  </button>

                  <button [disabled]="disabled" *ngIf="has_error == true && reemitida_mode == false" mat-flat-button (click)="validate_facturas()" [ngClass]="{'primary-button':true}">
                    Guardar cambios
                  </button>
                </div>
                
              </div>
            </div>
      
        </div>

  
  </div>