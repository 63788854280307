import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FacturasVentasManuales } from '../facturas-venta/facturas-venta-manual/facturas-ventas-manuales';
import { EditarFacturasVentaComponent } from '../editar-facturas-venta/editar-facturas-venta.component';
import { SelectionModel } from '@angular/cdk/collections';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AddCta } from '../../payments/pago-seleccion/add-cta';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

declare var $: any;


@Component({
  selector: 'app-facturas-compra',
  templateUrl: './facturas-compra.html',
  styleUrls: ['./facturas-compra.less']
})
export class FacturasCompra implements OnInit {


  @ViewChild('uploadResultPaginator', {read: MatPaginator}) uploadResultpaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('MatSort2', {read: MatSort}) sort2!: MatSort;
  firstFormGroup: FormGroup;
  firstFormGroup2: FormGroup;


  ELEMENT_DATA: any[]=[];
  displayedColumns: string[] = ['business_name','conteo','monto','porcentaje'];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  len_info: number = 0;
  @ViewChild('paginator') paginator!: MatPaginator;

  formFechas: FormGroup;
  cargando:Boolean=true
  info:Boolean=false
  infocesiones:Boolean=false;
  clave:number=0;

  valForm :FormGroup;

  @Input() id_simulacion:any;
  
  paymentMethod = new FormControl();
  paymentMethodList: string[] = ['Cheque', 'Vale vista', 'Transferencia', 'Aplicacion'];


  constructor(public dialogRef:MatDialogRef<FacturasCompra>,private cd: ChangeDetectorRef,public settings: SettingsService, private fb: FormBuilder, 
    public dialog:MatDialog,public snackBar: MatSnackBar) { 
    this.formFechas = this.fb.group({
      fechaI: ['', Validators.required],
      fechaF: ['', Validators.required]
    });

    this.valForm = fb.group({
      desde: '',
      hasta: '',
      filter:'',
    });

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      checkall:false
    });

    this.firstFormGroup2 = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      checkall:false
    });

  }

  company_id:any;
  ngOnInit() {
    this.company_id = Number(localStorage.getItem("company_id"))
    this.load_table()
    this.get_simulation()
  }
  
  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 
  //0 no, 1 si
  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];

  excel_download=0;
  ELEMENT_DATA_FACTURAS: Array<any>=[];
  displayedColumnsFacturas: string[] = ['select','folio','ultimorutacreedor','ultimoacreedor','receptoracreedor__business_name','mnt_total'];
  dataSourceFacturas = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);

  desde='';
  hasta='';
  filter='';
  infotable:boolean=false; 

  total_seleccionado:string="Total"
  total_tabla=0;
  show_msg_acreedor_legal:boolean=false;
  async load_table() {
    try {

      this.show_msg_acreedor_legal = false;
      this.disabled = true;

      const bd: any = await this.settings.get_query(1, 'ConfirmigCompras/'+this.company_id+'/?excel=' + this.excel_download + '&pageNumber=' + this.page + '&fecha_ini=' +this.desde + '&fecha_fin=' + this.hasta + '&filter=' + this.filter + '&ordenamiento=' + this.ordenamiento + '&columna=' + this.columna+ '&simulationId=' + this.id_simulacion);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.ELEMENT_DATA_FACTURAS = body_data.facturas;
        this.length = body_data.lenFacturas;
        this.disabled = false;
        let sma = 0
        if(this.arr_documentos_to_simulate.length > 0){
          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            sma = sma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = sma;
        }else{
          this.total_total = body_data.montoTotal;
          this.total_tabla = body_data.montoTotal;
        }

        if(this.ELEMENT_DATA_FACTURAS.length >0){
          this.infotable = true;
        }else{
          this.infotable = false;
        }

        for (let i = 0; i < body_data.facturas.length; i++) {
          if (body_data.facturas[i].historialAcreedores.length > 0) {
            this.show_msg_acreedor_legal = true;
          }
          if (this.arr_simulation.includes(body_data.facturas[i].id)) {

            body_data.facturas[i]['checked'] = true
          } else {
            body_data.facturas[i]['checked'] = false
          }
        }

        for(let i in this.ELEMENT_DATA_FACTURAS){
          if(this.ELEMENT_DATA_FACTURAS[i].fecha_vencimiento != null){
            let date = new Date(this.ELEMENT_DATA_FACTURAS[i].fecha_vencimiento)
            let dd = date.getDate();
            let mm = date.getMonth()+1;
            let yy = date.getFullYear();
            let new_date = dd+'-'+mm+'-'+yy;
  
            this.ELEMENT_DATA_FACTURAS[i].fecha_vencimiento = new_date
          }


        }

        console.log("TABLE", this.ELEMENT_DATA_FACTURAS)

        setTimeout(() => {
          this.dataSourceFacturas=new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
          this.dataSourceFacturas._updateChangeSubscription();
        });


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.load_table();


  }

  columna = "";
  ordenamiento = "";
  sortData($e: any) {
    this.columna = $e.active;
    this.ordenamiento = $e.direction;
    this.load_table()

  }

  disabled:boolean=false;
  async exportar() {
    try {
      this.disabled = true;

      const bd: any = await this.settings.get_query(1, 'ConfirmigCompras//'+this.company_id+'/?excel=' + '1' + '&pageNumber=' + this.page + '&fecha_ini=' +this.desde + '&fecha_fin=' + this.hasta + '&filter=' + this.filter);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          this.disabled= false;

          window.open(body_data.nameArchivo, "blank")


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.disabled = false;
    }
  }

  async sincronizar() {
    try {
      let value = {}
      this.disabled = true;

      const bd: any = await this.settings.get_query(2, 'sii-empresa/'+this.company_id+'/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.open_input_dialog("Sincronización", "Sincronización Exitosa", 
        "¡Todo listo! Nos hemos sincronizado con el SII",
        "Hemos actualizado los ultimos 6 meses con tus libros de compras, ventas y notas de credito!","sync","#3BA46F")

        this.disabled= false;

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.disabled= false;
    }
  }

  //DIALOG INPUT
  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

  console.log("se abrio dialog")
  const dialogRef = this.dialog.open(InputDialog,{
    width:'700px'
  });
  dialogRef.componentInstance.title = title;
  dialogRef.componentInstance.subtitle = subtitle;
  dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
  dialogRef.componentInstance.mensajenegrita = mensajenegrita;
  dialogRef.componentInstance.icon = icon;
  dialogRef.componentInstance.coloricon = coloricon;


  dialogRef.afterClosed().subscribe((result:any) => {
    this.load_table()
  });

  }

  filter1($e: any) {

    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    this.desde = year + '-' + mes + '-' + dia;

    this.load_table()

  }

  filter2($e: any) {
      let date = new Date($e.value);
      let mes = date.getMonth() + 1;
      let dia = date.getDate();
      let year = date.getFullYear();
      this.hasta = year + '-' + mes + '-' + dia;

      this.load_table()
  }

  filtrar(){
    this.filter = this.valForm.controls['filter'].value;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    if(this.filter != ""){
      this.load_table()
    }
  }

  reset(filter:any){
    if(filter == ""){
      this.filter = "";
      this.load_table()
    }
  }

  reset_filters(){
    this.filter = "";
    this.desde = "";
    this.hasta = "";
    this.columna = "";
    this.ordenamiento = "";
    this.valForm.reset()
    this.load_table()
  }

  selection = new SelectionModel<Facturas>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturas.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event:any) {
    console.log($event)
    !$event.checked  ?
    this.selection.clear() :
    this.dataSourceFacturas.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  objeto_nelson: Array<any> = [];
  arr_documentos_to_simulate: Array<any> = [];
  arr_simulation: Array<any> = []
  disabled_enviar:boolean=true;
  total_total=0;
  insertar_vencimiento:boolean=false;
  activa(id:any, nombre:any, $e: any, objeto:any, index:any) {



    $e.stopPropagation();

    console.log("objeto", objeto)


        const obj = this.objeto_nelson.find(x => x.id == id);
        const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);

        console.log("obje", obje)





        if (obje === undefined) {


          this.arr_documentos_to_simulate.push(objeto);
        } else {

          this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
        }


        if (obj) {
          this.objeto_nelson.splice(this.objeto_nelson.indexOf(obj), 1);
        } else {
          this.objeto_nelson.push({ id: id, nombre: nombre, rut: objeto.rut, ejecutivo: objeto.ejecutivo, contact_mail: objeto.contact_mail });
        }


        console.log("2", this.arr_documentos_to_simulate)

        if (this.arr_simulation.includes(id)) {

          this.arr_simulation.splice(this.arr_simulation.indexOf(id), 1);
        } else {
          this.arr_simulation.push(id);
        }




        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.insertar_vencimiento = true;
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    




  }

  se_activo_check:boolean=false;
  activa_final(){
    this.checkall =  this.firstFormGroup.controls['checkall'].value;

    if(this.checkall == false){


        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
            this.arr_documentos_to_simulate.push(this.dataSourceFacturas.filteredData[i])

            this.arr_simulation.push(this.dataSourceFacturas.filteredData[i].id)
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento= true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }


    
    }else if(this.checkall == true){

        this.arr_simulation = []
        this.objeto_nelson = []
        this.arr_documentos_to_simulate = [];

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento = true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    }

    console.log("check all", this.arr_documentos_to_simulate)

  }

  seleccionar(){

    let total_review = this.arr_documentos_to_simulate.reduce((acc, cv) => Number(cv.mnt_total) + acc , 0);
    total_review = total_review + this.deuda_total_2;
    
    let folios_map = [];
    folios_map =  this.arr_documentos_to_simulate.map((factura) => {
      return factura.folio
    });
    let folios = folios_map.join(",");
    this.aceptacionReclamo(folios, true)
  }

  seleccionar_2(){

    let total_review = this.arr_documentos_to_simulate.reduce((acc, cv) => Number(cv.mnt_total) + acc , 0);
    total_review = total_review + this.deuda_total_2;

    this.disabled = true;
    console.log("facturas seleccionadas", this.arr_documentos_to_simulate)
    this.facturas = this.clone_array(this.arr_documentos_to_simulate)
    this.scrapping();

    this.valForm.reset()
    this.insertar_vencimiento = false;
    this.objeto_nelson = [];
    this.arr_documentos_to_simulate = [];
    this.arr_simulation = []
    this.disabled_enviar = true;
    this.se_activo_check = false;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.total_seleccionado = "Total"

  }



  checkall:boolean=false;


  minDate:any = new Date()
  fecha_individual($e: any, index:any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.ELEMENT_DATA_FACTURAS[index]["fecha_vencimiento"] = date_set
    this.ELEMENT_DATA_FACTURAS[index]["dias"] = diffDays;


    this.put_table(index)

    // this.arr_documentos_to_simulate[index]["fecha_vencimiento"] = date_set
    // this.arr_documentos_to_simulate[index]["dias"] = diffDays;


  }

  fecha_todas($e: any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.arr_documentos_to_simulate){
        this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
        this.arr_documentos_to_simulate[i]["dias"] = diffDays


    }


    this.put_table()



  }

  capturar_fecha_por_dias($e: any){
      let dias = ($e.target.value - 1);
      let new_date = this.addDays(Number(dias))
      let fecha_calculada = new Date(new_date)

      console.log("FECHA CALCULADA", fecha_calculada)
      this.firstFormGroup.controls['secondCtrl'].setValue(fecha_calculada)
      this.fecha_todas_por_dias(fecha_calculada)

  }

  addDays(days:any) {
    let fecha_hoy = new Date()
    let fecha_condias = new Date()
    fecha_condias.setDate(fecha_hoy.getDate()+days);
    return fecha_condias;
  }

  fecha_todas_por_dias(date:any){
      var date = date
      let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

      if (d_o_w == 0 || d_o_w == 6)
        date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

      let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      for(var i in this.arr_documentos_to_simulate){
          this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
          this.arr_documentos_to_simulate[i]["dias"] = diffDays

      }

      this.put_table()




  }

  async put_table(index?:any) {
    try {

      let value = {}
      if(index != undefined && index != null){
            value = {'facturas': [this.ELEMENT_DATA_FACTURAS[index]]}
      }else{
            value = {'facturas':this.arr_documentos_to_simulate}
      }

      const bd: any = await this.settings.get_query(3, 'ConfirmigCompras/'+this.company_id +'/',value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.load_table()


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  clone_array(arr:any)
  {
    let aa =  JSON.stringify(arr);
    return  JSON.parse(aa)
  }


  //LOGICA DE CUENTAS BANCARIAS
  facturas:any;
  loading_scrapping:boolean=true;
  esta_todo_listo:boolean=false;
  total=0;
  @ViewChild('MatPaginator2') paginator2!: MatPaginator;
  @ViewChild('MatPaginator3') paginator3!: MatPaginator;
  async scrapping() {
    try {
      this.loading_scrapping = true
      const bd: any = await this.settings.get_query(1, 'get-libro-confirming/'+this.company_id+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.loading_scrapping = false;

        this.post_facturas()


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loading_scrapping = false
    }
  }

  compras_agrupadas: Array<any> = [];

  compras_no_agrupadas: Facturas2[] = [];
  dataSourceFinalizarStep = new MatTableDataSource<any>([]);
  dataSource3 = new MatTableDataSource<Facturas2>(this.compras_no_agrupadas);
  displayedColumns2: string[] = ['cta', 'folio', 'receptoracreedor_name', 'receptoracreedor_rut', 'mnt_total', 'fecha_emision'];
  displayedColumns3: string[] = ['cta','receptoracreedor_name','receptoracreedor_rut','estado'];
  displayedColumnsFinalizarStep: string[] = ['receptoracreedor_name', 'receptoracreedor_rut', 'total_facturas', 'banco', 'cta'];
  siguiente:boolean=true;
  async post_facturas(olvidar_pasito?:any) {
    try {
      this.cargando = true;
      let value = {'facturas':this.facturas}

      const bd: any = await this.settings.get_query(2, 'EmitirPagoConfirmigAcreedores/'+this.company_id+'/?procesado=0',value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.compras_no_agrupadas = body_data.facturas;
        this.controlar_cuentas();
        let suma = 0;
        let valid: boolean = false;
        for (let i = 0; i < body_data.facturas.length; i++) {
          suma = suma + body_data.facturas[i].mnt_total;
          if (body_data.facturas[i].validBanco == true) {
            this.desactivar_siguiente = false;
            valid = true;
            this.siguiente = false;
          } else {
            this.desactivar_siguiente = true;
            valid = false;
            this.siguiente = true;
          }
        }
        this.total = suma;
        var distinc = [], output = [], l = this.compras_no_agrupadas.length, i;
        for (i = 0; i < l; i++) {
          if (distinc[this.compras_no_agrupadas[i].ultimorutacreedor]) continue;
          distinc[this.compras_no_agrupadas[i].ultimorutacreedor] = true;
          output.push(this.compras_no_agrupadas[i]);
        }

        const groupByRut = this.mapDataToGroupByReceptor(this.compras_no_agrupadas);
        this.mapDataToShowInTableCtas(groupByRut);

        this.dataSourceFinalizarStep = new MatTableDataSource(this.compras_agrupadas);
        this.dataSourceFinalizarStep._updateChangeSubscription();
        this.dataSourceFinalizarStep.paginator = this.paginator2;

        
        this.cargando = false;
        this.disabled = false;

        if(olvidar_pasito != true){
          $("#pasitodos").trigger('click');
        }


        this.load_table()

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


  mapDataToGroupByReceptor(data: any[]) {
    return data.reduce((acc, cv) => {
      if (!acc[cv.receptoracreedor_rut]) {
        acc[cv.receptoracreedor_rut] = [];
      }
      acc[cv.receptoracreedor_rut].push(cv);
      return acc;
    }, {});
  }

  mapDataToShowInTableCtas(data:any) {
    this.compras_agrupadas = [];
    let esta_todo_listo = true;
    for (const rut in data) {
      const facturas = data[rut];

      const total_facturas = facturas.reduce((acc:any, cv:any) => acc + cv.mnt_total, 0);
      const folios = facturas.map((cv:any) => cv.folio);
      const receptoracreedor_name = facturas[0].receptoracreedor_name;
      const receptoracreedor_rut = facturas[0].receptoracreedor_rut;
      const receptoracreedor_id = facturas[0].receptoracreedor_id;
      const bancos = facturas[0].bancos;
      const validBanco = facturas[0].validBanco;
      const id_bank = facturas[0].id_bank;
      const company_id = facturas[0].company_id;

      const obj = {
        receptoracreedor_id,
        receptoracreedor_name,
        receptoracreedor_rut,
        folios,
        total_facturas,
        bancos,
        facturas,
        validBanco,
        id_bank,
        company_id
      };
      this.compras_agrupadas.push(obj);
      if (!facturas[0].validBanco) {
       esta_todo_listo = false;
      }
    }
    this.esta_todo_listo = esta_todo_listo;
  }


  displayspinner:boolean=false;
  mostrar_descargar:boolean=false;
  url_csv:any;
  //1 para agregar facturas
  //0 para ver si tengo que agregar ventas
  deuda_total:any;
  debe_agregar_ventas:boolean=false;
  async download_csv() {

    let value = {'facturas':this.compras_no_agrupadas}

    try {
      const bd: any = await this.settings.get_query(2, 'RecalculateCompras/' + this.id_simulacion + '/?final=0', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


              this.deuda_total = body_data.totalDeuda;
              if(body_data.ventas == false){
                this.agregar_facturas_venta()
              }else{
                this.debe_agregar_ventas = true;

                setTimeout(() => {
                  $("#elfulanito").trigger('click');
                });

              }

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  async agregar_facturas_venta() {
    try {
      let value = {'facturas':this.compras_no_agrupadas}

      const bd: any = await this.settings.get_query(2, 'RecalculateCompras/' + this.id_simulacion + '/?final=1', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.open_input_dialog("Actualización", "La simulación fue actualizada", 
            "¡Todo listo! Se añadieron las nuevas facturas y se recalculo la simulación",
            "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Proveedores","check_circle","#3BA46F")

            this.dialogRef.close(true)
            this.esta_todo_listo = true;


          break;
        default:

          break;
      }
    } catch (bd: any) {
        this.cargando = false;
    }
  }

  create_account(id:any, rut:any, nombre:any) {
    let dialogRef = this.dialog.open(AddCta, {
      disableClose: true
    });
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = id;
    dialogRef.componentInstance.dialog_open = true;
    dialogRef.componentInstance.rut_acreedor = rut;
    dialogRef.componentInstance.nombre_acreedor = nombre;
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  seleccionar_banco(banco:any, ultimorutacreedor:any) {
    let id_bank = Number(banco);
    console.log(id_bank, ultimorutacreedor)
    this.asignar_banco(ultimorutacreedor, id_bank, this.compras_no_agrupadas);
  }


  bloquear_siguiente: boolean = true;
  asignar_banco(ultimorutacreedor:any, id_bank:any, data:any, field = 'ultimorutacreedor') {
    this.compras_no_agrupadas.forEach((item:any) => {
      if (ultimorutacreedor == item[field]) {
        item.id_bank = id_bank;
      }
    });
    for (let i = 0; i < this.compras_no_agrupadas.length; i++) {
      if (this.compras_no_agrupadas[i].validBanco == true && this.compras_no_agrupadas[i].id_bank != null) {
        this.esta_todo_listo = true;
      } else {
        this.esta_todo_listo = false;
        break;
      }
    }
    console.log(this.compras_no_agrupadas)
  }

  controlar_cuentas() {
    this.compras_no_agrupadas.forEach(item => {
      if (item.bancos.length > 0) {
        let id_bank = item.bancos[0].id;
        let ultimorutacreedor = item.ultimorutacreedor;
        this.asignar_banco(ultimorutacreedor, id_bank, this.compras_no_agrupadas);
      }
      
    });
  }

  desactivar_siguiente:boolean=true;
  check_bank(){

    let valid:boolean=false;
    for(let i=0; i<this.compras_no_agrupadas.length;i++){
      if(this.compras_no_agrupadas[i].id_bank != null){
        this.desactivar_siguiente = false;
        valid = true;
        this.siguiente = false;
      }else{
        this.desactivar_siguiente = true;
        valid = false;
        this.siguiente = true;
        break;
      }
    }
    
  }
  siguiente_paso:boolean=true;
  check_vencimiento(){
    for(let i=0; i<this.compras_no_agrupadas.length;i++){
      if(this.compras_no_agrupadas[i].fecha_vencimiento != null && this.compras_no_agrupadas[i].fecha_vencimiento != undefined){
        this.siguiente_paso = false;
      }else{
        this.siguiente_paso = true;
        break;
      }
    }

  }

  open_libro_ventas(){
    const dialogRef = this.dialog.open(EditarFacturasVentaComponent, {
      width:'1300px',
      disableClose: true,
    });
    dialogRef.componentInstance.id_simulation = this.id_simulacion;
    dialogRef.componentInstance.deuda = this.total;
    dialogRef.componentInstance.deuda_total = this.deuda_total;
    dialogRef.componentInstance.simulacion_recibida = this.simulation;
    dialogRef.componentInstance.facturas_venta = this.facturas_ventas;
    dialogRef.componentInstance.facturas = this.compras_no_agrupadas;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result==true){
        this.dialogRef.close(true)
      }
    });
  }

  open_carga_manual(){
      
    const dialogRef = this.dialog.open(FacturasVentasManuales, {
      width:'1300px',
      disableClose: true,
    });
    dialogRef.componentInstance.deuda = this.total;
    dialogRef.componentInstance.deuda_total = this.deuda_total;
    dialogRef.componentInstance.id_simulation = this.id_simulacion;
    dialogRef.componentInstance.manual_ventas = true;    
    dialogRef.componentInstance.simulacion_recibida = this.simulation;
    dialogRef.componentInstance.facturas_venta = this.facturas_ventas;
    dialogRef.componentInstance.facturas = this.compras_no_agrupadas;
    
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result==true){
        this.dialogRef.close(true)
      }
    });
  }

  anticipo=95;
  comision=0;
  dias_promedio=30;
  gastos=0;
  interes_total=0;
  iva_operacion=0;
  monto_recibir=0;
  tasa_interes=0;
  total_2=0;
  valor_anticipo_total=0;
  loading:boolean=true;
  deuda_total_2=0;
  por_pagar=0;
  debe_pagar:boolean=false;
  simulation={'anticipo':0,'vencimiento':0,'comision':0,'gastosOperacionales':0,'iva_operaciones':0,'interesTotal':0,'liquidoRecibir':0, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':0.95,'tasa_interes':0, 'gastos':0};
  facturas_ventas:any;
  porc_anticipo=0;
  tasa_int=0.02;
  gastos_operacionales=30000;
  async get_simulation() {
    try {

      this.loading = true;

      const bd: any = await this.settings.get_query(1, 'controldtedetailsimulation/'+this.id_simulacion +'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


              this.anticipo= body_data.simulation.anticipo;
              this.comision=body_data.simulation.comision;
              this.dias_promedio=body_data.simulation.dias_promedio;
              this.gastos=body_data.simulation.gastos;
              this.interes_total=body_data.simulation.interes_total;
              this.iva_operacion=body_data.simulation.iva_operacion;
              this.monto_recibir=body_data.simulation.monto_recibir;
              this.tasa_interes=body_data.simulation.tasa_interes;
              this.total_2=body_data.simulation.total;
              this.valor_anticipo_total=body_data.simulation.valor_anticipo_total;
              this.deuda_total_2 = body_data.total_compras;
              this.porc_anticipo = (body_data.simulation.anticipo / 100);
              this.tasa_int = (body_data.simulation.tasa_interes / 100);
              this.gastos_operacionales = body_data.simulation.gastos;

              this.simulation={'anticipo':this.valor_anticipo_total,'vencimiento':this.dias_promedio,'comision':this.comision,'gastosOperacionales':this.gastos,
              'iva_operaciones':this.iva_operacion,'interesTotal':this.interes_total,'liquidoRecibir':this.monto_recibir, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':this.porc_anticipo,
              'tasa_interes':this.tasa_int, 'gastos':this.gastos_operacionales};

              this.facturas_ventas = body_data.ventas;

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loading = false;
    }
  }

  async pagar(pagado:any, id_compra:any, payment:any){
    this.reset_selection()
    let resultado: any = (await this.settings.get_query(3, 'PagarCompras/' + id_compra + '/', {'pagado': !pagado, 'mediopago': payment}));
    let body_data = resultado._body;
    if (resultado) {
      if (!body_data.error) {
        this.load_table();
      }
    } 
  }

  reset_selection() {
    this.valForm.reset()
    this.insertar_vencimiento = false;
    this.objeto_nelson = [];
    this.arr_documentos_to_simulate = [];
    this.arr_simulation = []
    this.disabled_enviar = true;
    this.se_activo_check = false;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.total_seleccionado = "Total"
    this.load_table()
  }

  
  async aceptacionReclamo(folio:any, is_masivo?:any) {
    // try {
      this.disabled = true;
      let resultado: any = (await this.settings.get_query(1, 'ppaceptacionyreclamo/' + this.company_id + '/?folios='+folio));
      let body_data = resultado._body;
      if (resultado) {
        console.log(body_data)
        let res = body_data.resultado;
        let folios_con_errores: Array<any> = []
        res.forEach((element:any) => {
          if (element.data != null) {
            if (element.data.tieneReclamos == true) {
              folios_con_errores.push(element.data.dhdrFolio);
            }
          }
        });
        if (is_masivo) {
          if (folios_con_errores.length > 0){
            let mensaje = "Intenta continuar sin esas facturas o seleciona nuevas facturas.";
            let ids_documentos_excluir = []

            for (let index = 0; index < this.arr_documentos_to_simulate.length; index++) {
              let element = this.arr_documentos_to_simulate[index];
              if (folios_con_errores.includes(element.folio)) {
                ids_documentos_excluir.push(index);
              }
            }

            for (let index = 0; index < ids_documentos_excluir.length; index++) {
              let i = ids_documentos_excluir[index];
              this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(this.arr_documentos_to_simulate[i]), 1);             
            } 

            this.arr_simulation = this.arr_documentos_to_simulate.map(factura => {return factura.id})            
            
            this.open_input_dialog("Información", "Facturas de compra", 
            "¡Oops! Las siguientes facturas fueron desmarcadas porque tienen reclamos: "+folios_con_errores.join(", "),
            mensaje,"highlight_off","#ff933b")
            this.load_table();
          } else {
            this.seleccionar_2();
          }
        } else {
          this.load_table();
        }
      }
    // } catch {
    //   alert("Error")
    //   this.disabled = false;
    // }    
  }

}

export interface Facturas{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
}

export interface Facturas2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any,
  bancos:any;
}

export interface VISTAPREVIA{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
}
