<div class="row row_main bottom-responsive">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli">Mira el detalle de tu operación </h1>
        <small class="subtitulo-simpli"><span (click)="que_es_reemitir()" style="cursor: pointer;text-decoration: underline;color:var(--simpli-primary);">Reemite</span> y vuelve a agregar las facturas eliminadas o continúa la operación con las facturas cedidas.</small>
      </div>
    </div>
  </div>


  <!--Stepper simpli-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
    <div class="row" style="margin-top: 40px;">


        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>

        <!--STEP CESION--> 
        <div  class="col-lg-10 col-md-10 col-sm-10 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="step2 && facturas.length > 0">

          <div class="row">

              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                <h2 class="titulo-simpli2">Desglose de los montos</h2>

                <div class="card-simpli-cesion" style="margin-top: 40px;">
                    <mat-label class="label-interno">Información general</mat-label>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                        <mat-label class="label-izquierdo">N° Operación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho" *ngIf="id_operacion == null">{{id_sim}}</mat-label>
                        <mat-label class="label-derecho" *ngIf="id_operacion != null">{{id_operacion}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Cantidad de facturas:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{cantidad_facturas}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Fecha de creación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{date_created}}</mat-label>
                      </div>
                    </div>
                </div>

                <div class="card-simpli-cesion">
                  <mat-label class="label-interno">Montos asociados</mat-label>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total a ceder:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">% Financiamiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{anticipo}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto de anticipo:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{valor_anticipo_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Vencimiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{dias_promedio}} días</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Tasa de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{tasa_interes}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{interes_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Comisión:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Gastos operacionales:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">IVA:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{iva_operacion}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Excedentes:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{excedentes}}</mat-label>
                    </div>
                  </div>



                </div>

                <div class="card-simpli-cesion" style="padding-top: 7px;padding-bottom: 7px;">


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo-monto">Monto líquido:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho-monto">${{monto_recibir}}</mat-label>
                    </div>
                  </div>


                </div>

                <div class="card-simpli-cesion" style="border: none;padding:0 !important;background:none;width: 380px;">
                  <div style="margin-top: 30px;text-align: right;">
                    <mat-label class="add_facturas" (click)="download_pdf()"><i class="fa-regular fa-download facturas-add"></i>Descargar desglose</mat-label>&nbsp;
                  </div>
                </div>



              </div>

              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>

              <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 col-top">

                <h2 class="titulo-simpli2">Resumen de tus facturas por ceder</h2>

                <div class="table-responsive-simpli add_factoring">

                  <table mat-table [dataSource]="dataSource">


                    <ng-container matColumnDef="factura">
                      <th mat-header-cell *matHeaderCellDef> N° Factura </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.document.folio}}">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="deudor">
                      <th mat-header-cell *matHeaderCellDef> Deudor </th>
                      <td mat-cell *matCellDef="let element"  matTooltip="{{element.document.nombre_deudor}}"> 
                        <input class="no-input" readonly value="{{element.document.nombre_deudor}} ">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="rut">
                      <th mat-header-cell *matHeaderCellDef> RUT </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input class="no-input" readonly value="{{element.document.rut_deudor}}">
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef> Monto </th>
                      <td mat-cell *matCellDef="let element">
                        <input class="no-input" readonly value="${{formatMoney(element.document.monto_total)}}">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef> Estado </th>
                      <td mat-cell *matCellDef="let element"> 
                        <input *ngIf="element.document.status == 0" class="no-input" readonly value="Por ceder">
                        <input *ngIf="element.document.status == 1" class="no-input" readonly value="Cediendo">
                        <input *ngIf="element.document.status == 2" class="no-input" readonly value="No cedida">
                        <input *ngIf="element.document.status == 3" class="no-input" readonly value="Cedida">
                      </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  

                </div>

                <mat-card class="card-tabla" style="position: relative;padding: 0px;">

                  <table mat-table [dataSource]="dataSource" class="table-res">
            
                    <ng-container matColumnDef="data">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
            
                        <div [ngClass]="{'box-factura':true}" (click)="$event.stopPropagation()">
            
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                                <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{element.document.folio}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Deudor:</mat-label><mat-label class="right-label">{{element.document.nombre_deudor}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">RUT:</mat-label><mat-label class="right-label">{{element.document.rut_deudor}}</mat-label></div>
                                <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.document.monto_total)}}</mat-label></div>
                                <div class="container-label-res">
                                  <mat-label style="position: relative;color:#3BA46F;" *ngIf="element.document.status == 0" class="right-label">Por ceder</mat-label>
                                  <mat-label style="position: relative;color:var(--simpli-primary);" *ngIf="element.document.status == 1" class="right-label">Cediendo</mat-label>
                                  <mat-label style="position: relative;color:#FA454C;" *ngIf="element.document.status == 2" class="right-label">No cedida</mat-label>
                                  <mat-label style="position: relative;color:#3BA46F;" *ngIf="element.document.status == 3" class="right-label">Cedida</mat-label>
            
                                  <span *ngIf="element.document.status == 1"><i style="color:var(--simpli-primary);" class="fa-regular fa-square-check icom-sim"></i></span>
                                  <span *ngIf="element.document.status == 0"><i style="color:#3BA46F;" class="fa-regular fa-file-check icom-sim"></i></span>
                                  <span *ngIf="element.document.status == 2"><i  style="color:#FA454C;" class="fa-regular fa-circle-xmark icom-sim"></i></span>
                                  <span *ngIf="element.document.status == 3"><i style="color:#3BA46F;" class="fa-regular fa-circle-check icom-sim"></i></span>
                                </div>
            
                              </div>
                            </div>
                        </div>
                        
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsResponsive"></tr>
                    <tr mat-row *matRowDef="let row; let doc; columns: displayedColumnsResponsive;" ></tr>
                  </table>
                  
            
                </mat-card>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
                    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
                  </div>
                </div>
                

                <div style="margin-top: 30px;text-align: right;">
                  <mat-label class="add_facturas" (click)="open_agregar_facturas_dialog()"><i class="fa-regular fa-circle-plus facturas-add"></i> Agregar más facturas</mat-label> &nbsp;
                </div>

                <div class="btn-simpli-container" style="text-align: right;">

                  <button [disabled]="cediendo" class="primary-button" style="width: 250px;" (click)="reemitir()" mat-flat-button>
                    <mat-label *ngIf="!cediendo">Facturas reemitidas</mat-label>
                    <span *ngIf="cediendo"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                  </button>

                  <button [disabled]="cediendo" *ngIf="conti_operacion == true" class="secondary-button" style="width: 250px;" (click)="abrir_dialogo_success()" mat-flat-button>
                    <mat-label *ngIf="!cediendo">Continuar operación</mat-label>
                    <span *ngIf="cediendo"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                  </button>

                  <button [disabled]="cediendo" *ngIf="conti_operacion == false" class="secondary-button" style="width: 250px;" (click)="ceder_facturas()" mat-flat-button>
                    <mat-label *ngIf="!cediendo">Continuar cediendo</mat-label>
                    <span *ngIf="cediendo"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                  </button>

                </div>

              </div>

          </div>

        </div>


        <!--DESCARTADAS SIN FACTURAS-->
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && show_descarte == true">

          <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <mat-card class="card-descarte">

                  <div style="text-align: center;margin-top: 60px;margin-bottom: 50px;">
                    <img src="../../../../assets/simpli/cesion/operación descartada 2.svg" height="100px">
                  </div>

                  <div class="title-descartada">La operación está descartada</div>

                  <div class="p-descartada">Ya no quedan facturas para ceder. Para iniciar 
                    <br>una nueva operación, vuelve a simular.
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              
                      <div class="btn-simpli-container">
                        <button class="primary-button" mat-flat-button (click)="volver_a_simular()">
                          <mat-label>Volver a simular</mat-label>
                        </button>

                        <button  class="secondary-button" mat-flat-button routerLink="/home/">
                          <mat-label>Volver al inicio</mat-label>
                        </button>
                      </div>
                      
                    </div>
                  </div>

                </mat-card>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>

          </div>

        </div>

        <!--Loading-->
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">

          <div class="container-loading">

            
            <div class="img-loading">
              <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
            </div>

            <div style="margin-top: 20px;">
              <mat-label class="label-loading">
                <div>La información está siendo procesada.</div>
                <div>Esto no tomará mucho tiempo.</div>
              </mat-label>
            </div>

          </div>


        </div>

        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>



    </div>




  </div>

</div>