<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close style="display: none;">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color8)" class="fa-light fa-circle-exclamation"></i>
        </div>

        <div class="label-dialog"  [innerHTML]="subtitle"></div>


        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container" *ngIf="paso == 0">
              <button class="primary-button" mat-flat-button (click)="chat_soporte()">
                Contactar a soporte
              </button>

              <button class="secondary-button" mat-flat-button mat-dialog-close routerLink="/home" (click)="close_all()">
                Volver al inicio
              </button>
            </div>

            <div class="btn-simpli-container" *ngIf="paso == 1">
              <button class="primary-button" mat-flat-button (click)="update_cert()">
                Actualizar certificado
              </button>

              <button class="secondary-button" mat-flat-button mat-dialog-close routerLink="/home" (click)="close_all()">
                Volver al inicio
              </button>
            </div>

            <div class="btn-simpli-container" *ngIf="paso == 2">
              <button class="primary-button" mat-flat-button (click)="update_cert()">
                Actualizar certificado
              </button>

              <button class="secondary-button" mat-flat-button  (click)="chat_soporte()">
                Contactar a soporte
              </button>
            </div>

            <div class="btn-simpli-container" *ngIf="paso == 3">
              <button class="primary-button" mat-flat-button (click)="chat_soporte()">
                Contactar a soporte
              </button>

              <button class="secondary-button" mat-flat-button mat-dialog-close routerLink="/home" (click)="close_all()">
                Volver al inicio
              </button>
            </div>

            <div class="btn-simpli-container" *ngIf="paso == 4">
              <button class="primary-button" mat-flat-button (click)="retry()">
                Reintentar
              </button>

              <button class="secondary-button" mat-flat-button mat-dialog-close routerLink="/home" (click)="close_all()">
                Volver al inicio
              </button>
            </div>
            
          </div>
        </div>

    </div>


    
</mat-dialog-content>