import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $ :any;


@Component({
  selector: 'app-menu-simpli',
  templateUrl: './menu-simpli.html',
  styleUrls: ['./menu-simpli.less']
})


export class MenuSimpli implements OnInit {
   

  @Input() menu_cliente:boolean=true;
  @Input() menu_confirming_pronto_pago:boolean=false;
  @Input() id_company:any;
  @Input() mode:any;
  @Input() parent:any;

  constructor(public snackBar: MatSnackBar, 
    public router: Router, public dialog: MatDialog,  
    public settings: SettingsService,private _formBuilder: FormBuilder, private changeDetect:ChangeDetectorRef ){

      this.id_company = localStorage.getItem('company_id');

  }

  set_menu_storage() {
    if (localStorage.getItem('side-menu') == 'true') {
      localStorage.setItem('side-menu', 'false');
      console.log("fijo")
      this.parent.mode = 'over';
      this.parent.opened = true;
      this.parent.open_menu()
    } else {
      console.log("oculto")
      this.parent.mode = 'side';
      this.parent.opened = true;
      localStorage.setItem('side-menu', "true");
    }
  }

  is_responsive:boolean=false;
  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }else{
      this.is_responsive = false;
    }

    setTimeout(() => {
      $('.sub-menu ul').hide();
      $(".sub-menu a").click(function(this:any){
        $(this).parent(".sub-menu").children("ul").slideToggle("100");
        $(this).find(".right").toggleClass("fa-caret-up fa-caret-down");
      });
    });

    this.changeDetect.detectChanges()


  }

  menu(){

    $(this).parent(".sub-menu").children("ul").slideToggle("100");
    $(this).find(".right").toggleClass("fa-caret-up fa-caret-down");

  }

  menu_desplegado:boolean=false;
  mouseenter($event:any){
    if(this.menu_desplegado == false){
      //mostrar
      $("#menupagoproveedores").parent(".sub-menu").children("ul").slideToggle("100");
      $("#menupagoproveedores").find(".right").toggleClass("fa-caret-up fa-caret-down");
      this.menu_desplegado = true;
    }else if(this.menu_desplegado == true){
      //ocultar
      $("#menupagoproveedores").parent(".sub-menu").children("ul").slideToggle("100");
      $("#menupagoproveedores").find(".right").toggleClass("fa-caret-up fa-caret-down");
      this.menu_desplegado = false
    }

  }

  go_to_resumen(){
    setTimeout(() => {
      $("#caja_linea").trigger('click');
    });
  }

}


