<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative; background:#fff;padding-bottom: 10%;">


      <div class="centerabsolute" style="width:75%;">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align:center; padding-top:10px;">
            
            <div style="text-align: center;padding-top:10px">
              <img src="../../assets/simpli/logolineal200px.svg" height="60"> 
            </div>
          </div>
          
        </div>

        <div class="row" style="padding-top:30px;padding-bottom: 30px;">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="rol_cli" style="padding-top:20px;">
            <mat-card class="card-home card-puente" (click)="enterClient()" style="cursor:pointer">
              <div  >

                <div class="col-top" style="padding:10%;text-align: center;padding-top:0;color: #8a8a8a;">
    
                  <h1 style="font-size: 22px;color: #924eff; padding-top:10px; font-weight:400">Portal Cliente</h1>
                  <br>
                  Simplificamos las operaciones financieras más importantes de tu empresa. Simula tu cesión y adelanta el pago de factura, o paga tus proveedores y remuneraciones.
                </div>
    
    
              </div>
           </mat-card>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="rol_conf" style="padding-top:20px;">
            <mat-card class="card-home card-puente" (click)="enterConfirming()" style="cursor:pointer">
              <div  >

                <div class="col-top" style="padding:10%;text-align: center;padding-top:0;color: #8a8a8a;">
    
                  <h1 style="font-size: 22px;color: #924eff; padding-top:10px; font-weight:400">Portal Confirming</h1>
                  <br>
                  Financia a tus proveedores y controla tus nóminas en un mismo lugar. Utiliza nuestro Control DTE para hacer seguimiento del acreedor de factura y nunca cometer errores.
                  ¡La mejor herramienta para Grandes Empresas y Corporativos!
                </div>
    
    
              </div>
           </mat-card>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="rol_prpa" style="padding-top:20px;">
            <mat-card class="card-home card-puente" (click)="enterProntoPago()" style="cursor:pointer">
              <div  >

                <div class="col-top" style="padding:10%;text-align: center;padding-top:0;color: #8a8a8a;">
    
                  <h1 style="font-size: 22px;color: #924eff; padding-top:10px; font-weight:400">Portal Pronto Pago</h1>
                  <br>
                  Realiza todas las operaciones financieras de tu negocio en un mismo lugar. Paga a tus proveedores y visualiza las actualizaciones de tus finanzas en tiempo real.
                </div>
    
    
              </div>
           </mat-card>
          </div>

        </div>
      </div>
     
  </div>
</div>

