import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { SettingsService } from '../settings/settings.service';


@Component({
selector: 'modal-dialog',
templateUrl: './modal.html',
styleUrls: ['./modal.less'],
standalone: true,
imports: [MaterialModule, CommonModule]
})

export class ModalDialog implements OnInit {


title:string="Loading Title";
subtitle:string="Vista Previa";
mensajeprincipal:string="";
mensajenegrita:string="";
error_array:any=[]
icon:string="autorenew";
coloricon:string="#ff4081";
boton_text:string="aceptar"
boton_confirm:boolean=false
colorBoton:string="#4ad991"


constructor(public snackBar: MatSnackBar,
public dialog: MatDialog,
public settings: SettingsService,
public dialogRef:MatDialogRef<ModalDialog>) {


}


ngOnInit(){
    setTimeout(()=>{                         
       this.dialogRef.close(true)
   }, 100000);
}

close_dialog(){
    this.dialogRef.close(true)
}

confirmButton(){
    this.dialogRef.close(true)
}



}//endclass
