import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;

@Component({
  selector: 'app-operaciones',
  templateUrl: './operaciones.html',
  styleUrls: ['./operaciones.less']
})
export class ParrillaOperaciones implements OnInit {

  updateContent: EventEmitter<number> = new EventEmitter<number>();
  tab_on:any=null;
  id_company=localStorage.getItem('company_id')
  onTabChange(event: any) {
    this.tab_on = event.index;
    this.updateContent.emit(event.index + 1);
  }



  constructor(public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, public router:Router, private snackBar:MatSnackBar ) { 

  }

  ngOnInit() {

    localStorage.getItem('company_id');

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });

    this.load_all()

  }

  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('Company ID changed:', event.detail);
    this.id_company = localStorage.getItem('company_id');
    this.ngOnInit(); 
  }


  load_all() {
    this.id_company = localStorage.getItem('company_id');
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  myObserver: any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }





}