
<div class="row row_main animate__animated animate__fadeIn">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <h1 class="titulo-simpli">Documentación de respaldo</h1>

  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="upload_files == 1 && loading == false">

    <div class="row">

          <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12"></div>

          <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12">
              <mat-card class="card-documentos">

                  <div class="icon-file">
                    <i class="fa-regular fa-folder-open"></i>
                  </div>
  
                  <div *ngIf="caso == null" class="title-label"  style="padding-top: 55px;">Adjunta documentación de respaldo para tus facturas</div>
                  <div *ngIf="caso != null" class="title-label"  style="padding-top: 55px;">Adjunta documentación de respaldo</div>
          
                  <div *ngIf="caso == null" class="p-label">OC, OT, HES, HEM, Migo, entre otros, son importantes para<br> la rápida evaluación de tu operación.
                    Si tienes, adjúntalos<br> ahora, sino, puedes revisar el estado de tu simulación.</div>

                  <div *ngIf="caso != null" class="p-label">Documentos como la guía de despacho<br> permiten validar una entrega. Si cuentas con<br>
                     esta documentación, adjúntala para avanzar.</div>



                  <div class="link-label" (click)="go_to_info()">Conoce más sobre la documentación de respaldo</div>
          
          
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          
                      <div class="btn-simpli-container" style="text-align: center;">
                        <button class="primary-button"  mat-flat-button (click)="upload_files = 2">Adjuntar</button>
          
                        <button class="secondary-button" *ngIf="id_op == null" mat-flat-button (click)="go_to_detail()">Ver estado</button>
                        <button class="secondary-button" *ngIf="id_op != null" mat-flat-button (click)="volver()">Volver</button>
                      </div>
                      
                    </div>
                  </div>
            

              </mat-card>
          </div>

          <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12"></div>

    </div>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="upload_files == 2 && loading == false">
    <div class="row">

      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>

      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">

        <mat-card class="card-files">

          <div class="container-exp add_factoring">
            <mat-accordion>
    
              <mat-expansion-panel style="margin-bottom: 15px;" *ngFor="let i of facturas" [expanded]="id_factura == i.document_simulacion_id">
    
                <mat-expansion-panel-header style="position: relative;">
                  <mat-panel-title>
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="c-exp">
                          <div class="exp-izquierdo"><input class="no-input exp-izquierdo" readonly value="N° Factura:"></div>
                          <div class="exp-derecho"><input class="no-input exp-derecho" readonly value="{{i.document.folio}}"></div>

                        </div>
                      </div>

                      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div class="c-exp">
                          <div class="exp-izquierdo"><input class="no-input exp-izquierdo" readonly value="Deudor:"></div>
                          <div class="exp-derecho"><input class="no-input exp-derecho" readonly value="{{i.document.nombre_deudor}}"></div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 files-upload animate__animated animate__headShake" style="text-align: right;" *ngIf="i.archivos.length > 0">
                        <div class="label-success" style="position: relative">
                          <i class="fa-regular fa-circle-check"></i>
                           Documentos cargados
                        </div>
                      </div>
                    </div>
    

    

                  </mat-panel-title>
    
    
                </mat-expansion-panel-header>

                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="margin-top: 20px;">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="c-exp">
                          <div class="exp-izquierdo-2"><input class="no-input exp-izquierdo-2" readonly value="Monto:"></div>
                          <div class="exp-derecho"><input class="no-input exp-derecho" readonly value="${{formatMoney(i.document.monto_total)}}"></div>
                        </div>
                      </div>
                    </div>
    
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="c-exp">
                          <div class="exp-izquierdo-2"><input class="no-input exp-izquierdo-2" readonly value="RUT:"></div>
                          <div class="exp-derecho"><input class="no-input exp-derecho" readonly value="{{i.document.rut_deudor}}"></div>
                        </div>
                      </div>
                    </div>
    
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="c-exp">
                          <div class="exp-izquierdo-2"><input class="no-input exp-izquierdo-2" readonly value="Fecha emisión:"></div>
                          <div class="exp-derecho"><input class="no-input exp-derecho" readonly value="{{i.document.fecha_documento}}"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="text-align: justify;">

                    <div style="position: relative;margin-top: 40px;margin-bottom: 20px;">

                      <i class="fa-regular fa-circle-plus icon-file2"></i>

                      <div class="label-adjunt">
                        Agregar archivos
                        <div class="sub-label-adjunt">El peso no debe superar los 50 MB y solo se pueden<br>
                          cargar los formatos admitidos.
                        </div>
                      </div>


                    </div>


                    <div class="row" *ngFor="let file of i.archivos">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="file-cont">
                          <span class="file-delete" (click)="delete_file_manual(file.id)"><i class="fa-regular fa-xmark"></i></span>
                          <input style="width: 90%;" class="no-input label-filee" readonly value="{{get_name_url(file.file)}}">
                        </div>
                      </div>

                    </div>
                    

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                
                        <div class="btn-simpli-container">
                          <button [disabled]="disabled_btn"  class="secondary-button" mat-flat-button (click)="triger_click('file_'+i.document_simulacion_id)">
                            <mat-label>Adjuntar</mat-label>
                          </button>

                          <input type="file" id="file_{{i.document_simulacion_id}}" #file_{{i.document_simulacion_id}}  [multiple]="true" (change)="onFileChange($event, i.document_simulacion_id)" style="display:none" />
                        </div>


                        
                      </div>
                    </div>


                  </div>

                </div>
    



    
              </mat-expansion-panel>
    
            </mat-accordion>
    
          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div *ngIf="id_op == null" class="btn-simpli-container" style="text-align: center;">
                <button  class="primary-button" mat-flat-button (click)="finalizar()">
                  <mat-label>Finalizar y ver estado</mat-label>
                </button>
              </div>

              <div *ngIf="id_op != null" class="btn-simpli-container" style="text-align: center;">
                <button  class="primary-button" mat-flat-button (click)="finalizar()">
                  <mat-label>Finalizar</mat-label>
                </button>
              </div>
              
            </div>
          </div>

        </mat-card>

      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>

    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="upload_files == 3 && loading == false">
    <div class="row">

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

        <mat-card class="card-files" >

          <div class="cont-masivo">


            <div class="texto-masivo">Sube o arrastra tu documentación </div>

            <div class="container-input-file">

              <div id="draggable" draggable="true" [ngClass]="{'zone-to-drag':true}"  (click)="file2.click()">

                <input type="file" #file2  id="file2" [multiple]="true" (change)="onFileChange($event)" style="display:none" />

              </div>

                <div class="texto-masivo-title" style="position: relative;"><i class="fa-regular fa-circle-plus icon-plus-file"></i>Agregar documentos</div>
                <div class="texto-masivo-p" style="margin-top: 5px;">Los archivos no deben superar los 50 MB y solo se</div>
                <div class="texto-masivo-p">pueden cargar los formatos admitidos. <span matTooltip="Se admiten los formatos: PDF, XLS, DOC, JPG, 
                  JPEG, PNG, TXT, ZIP, XLSX y MSG." style="margin-left: 10px;font-size: 15px;position: relative;z-index: 99999999999999999999999999999;"><i class="fa-regular fa-circle-info"></i></span></div>

            </div>


            <div class="cont-files add_factoring" style="margin-top: 20px;" *ngIf="has_files == true">
              <div class="animate__animated animate__fadeIn" *ngFor="let i of files" class="l-simpli link-label2"><input readonly class="no-input" style="width: 90%;" value="{{i.name}}" matTooltip="{{i.name}}"> <span (click)="delete(i.id)" matTooltip="Eliminar" style="margin-left: 10px;cursor: pointer;" ><i class="fa-regular fa-xmark"></i></span></div>
            </div>

          </div>


          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container" style="text-align: center;">
                <button [disabled]="desactivar" [ngClass]="{'disabled-btn-simpli':desactivar == true, 'primary-button':true}" mat-flat-button (click)="upload_masive()">
                  <mat-label>Adjuntar y ver estado</mat-label>
                </button>
              </div>
              
            </div>
          </div>

        </mat-card>

      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>

    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="upload_files == 4 && loading == false">
    <div class="row">

      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

        <mat-card class="card-files" >

          <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
    
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
    
                  <mat-form-field appearance="fill">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)">
                    <mat-icon matSuffix class="icon-suffix">search</mat-icon>
                    <mat-hint>Buscar por folio o monto</mat-hint>
                  </mat-form-field>
            </div>
    
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
                <div class="table-responsive-simpli add_factoring">
    
                    <table mat-table [dataSource]="dataSource">
            
            
                      <ng-container matColumnDef="documento">
                        <th mat-header-cell *matHeaderCellDef>Documento</th>
                        <td mat-cell *matCellDef="let element"> 
                          <input class="no-input" readonly value="{{element.name}}">
                        </td>
                      </ng-container>
                    
            
                      <ng-container matColumnDef="accion">
                        <th mat-header-cell *matHeaderCellDef> Acción </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                                <span style="cursor: pointer;" (click)="download_file(element.file)"><i style="margin-right:10px;font-size: 17px;" class="fa-regular fa-eye"></i></span>
                                <span style="cursor: pointer;" (click)="delete_file(element.id)"><i style="margin-left:10px;font-size: 17px;" class="fa-regular fa-xmark"></i></span>
                        </td>
                      </ng-container>
            
                    
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    
            
                </div>
    
                <mat-paginator style="margin-top: 20px;" [pageSizeOptions]="[7, 14, 28, 50]"></mat-paginator>
    
            </div>


          </div>


          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container" style="text-align: center;">
                <button [disabled]="loading_zip" matTooltip="Descargar todos los documentos en un archivo comprimido" [ngClass]="{'primary-button':true}" mat-flat-button (click)="donwload_zip()">
                  <mat-label *ngIf="loading_zip == false">Descargar documentos</mat-label>
                  <span *ngIf="loading_zip == true"><i style="color: white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                </button>
              </div>
              
            </div>
          </div>
        </mat-card>

      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>

    </div>
  </div>

  <!--Loading-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && facturas.length == 0">

    <div class="container-loading">

      
      <div class="img-loading">
        <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
      </div>

      <div style="margin-top: 20px;">
        <mat-label class="label-loading">
          <div>La información está siendo procesada.</div>
          <div>Esto no tomará mucho tiempo.</div>
        </mat-label>
      </div>

    </div>


  </div>

</div>