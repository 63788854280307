import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;



@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.html',
  styleUrls: ['./document-preview.less'],
})

export class DocumentPreviewComponent implements OnInit {

  url:any;
  
  viewer:any="google";

  constructor(
              public snackBar: MatSnackBar, 
              public settings: SettingsService, 
              public dialog: MatDialog){

   }


  ngOnInit(): void {

    this.check_is_image(this.url)
   

  }


  check_is_image(file:any)
  {
    if(/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(file)){ 
     
      this.viewer = "url"

    }else{
      this.viewer = "google"
    }
  }

  download(){
    window.open(this.url, "blank")
  }




}
