import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class PushNotificationService {
  messaging: any;

  constructor(public router: Router, public settings: SettingsService) {
    const firebaseConfig:any = environment.configFirebase
    const fire = initializeApp(firebaseConfig);
    this.messaging = getMessaging(fire);
    this.getDeviceToken();
  }

  async getDeviceToken() {
    try {
      // Obtener el token del dispositivo
      const token = await getToken(this.messaging, { vapidKey: 'BHgaDstWud_-tzODNip_28MaU4tRIuyZv30Z-c-y7PhuO5ZOh2ahKYUOJwaxvFy4NtEOXHGcrOgNeqekhye6tEM' });
      if (token) {
        console.log('Device Token:', token);
        localStorage.setItem('device_token', token)
      } else {
        console.error('No se pudo obtener el token');
      }
    } catch (error) {
      console.error('Error al obtener el token del dispositivo:', error);
    }
  }

  async requestPermission() {
    return new Promise(async (resolve, reject) => {
      const permsis = await Notification.requestPermission();
      if (permsis === 'granted') {
        console.log('Notification permission granted.');
        // BHgaDstWud_-tzODNip_28MaU4tRIuyZv30Z-c-y7PhuO5ZOh2ahKYUOJwaxvFy4NtEOXHGcrOgNeqekhye6tEM
        //! OLD: BNpasM2eSInHZgjsELGEMfEXlcZwoqv_fU17tUlMsRXAMGR0CBbi6Msl8g6OLfGjzBb-u4n1vba06iMjW_erxUU
        getToken(this.messaging,
          {vapidKey: 'BHgaDstWud_-tzODNip_28MaU4tRIuyZv30Z-c-y7PhuO5ZOh2ahKYUOJwaxvFy4NtEOXHGcrOgNeqekhye6tEM'}).then((currentToken: any) => {
          if (currentToken) {
            localStorage.setItem('device_token', currentToken)
            this.setTokenSentToServer(currentToken)
            resolve(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err: any) => {
          console.log('An error occurred while retrieving token. ', err);

        });
      } else {
        console.log('Unable to get permission to notify.');
        reject(new Error("No se otorgaron los permisos"))
      }
    })
  }

  async setTokenSentToServer(token: any = null) {
    try {
      const bd: any = await this.settings.get_query(2, 'notificaciones/device/', {token: token, device_type: 3, company_id: localStorage.getItem('company_id')});
      var body_data: any =bd._body;
      var data = body_data.success.info;
      switch (bd.status) {
        case 200:
        break;
        default:
        break;
      }
    } catch (bd:any) {
      this.settings.manage_errors(bd.status, 'Guardar device token', bd, false);
    }
  }

  public messaginObservable = new Observable<MessagePayload>(observe => {
    onMessage(this.messaging, (payload: any) => {
      observe.next(payload);
    });
    return observe;
  })

  receiveMessage() {
    return this.messaginObservable;
  }

  bloquearNotificaciones() {
    deleteToken(this.messaging).then(async (resp) => {
      if(resp === true) {
        try {
          const bd: any = await this.settings.get_query(
            4,
            `notificaciones/device/?device_token=${localStorage.getItem('device_token')}&device_type=3` ,
          )
          localStorage.removeItem('device_token')
        } catch (error) {

        }
      }
      console.log("Token eliminado, notificaciones desactivadas.");
    }).catch((err) => {
      console.error("Error al eliminar el token:", err);
    });
  }
}

interface INotificationPayload {
  body?: string;
  title?: string;
}

interface MessagePayload {
  notification: INotificationPayload,
  data?: { [key: string]: string }
}
