<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <h1 class="titulo-simpli">Selecciona las facturas y agrégalas </h1>
  </div>
</div>


<mat-dialog-content>

  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="tiene_credenciales == true">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
          <form [formGroup]="valFormFechas">
              <mat-form-field appearance="fill" color="primary">
                    <mat-label>Fecha</mat-label>
                    <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" >
                        <input matStartDate readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                        <input matEndDate readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
                    </mat-date-range-input>
                    <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker>
                        <mat-date-range-picker-actions style="justify-content: center;">
                          <button class="secondary-button" (click)="reset()" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                          <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="filtrar_por_fechas()">Aplicar</button>
                            </mat-date-range-picker-actions>
                    </mat-date-range-picker>
              </mat-form-field>
          </form>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top"></div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">

          <form  [formGroup]="valFormFilter">

            <mat-form-field appearance="fill">
              <mat-label>Buscar</mat-label>
              <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
              <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
              <mat-hint>Buscar por monto o número de operación</mat-hint>
            </mat-form-field>

          </form>
        </div>
      </div>
    </div>

    <!--Loading-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && ELEMENT_DATA.length == 0">

      <div class="container-loading">

        
        <div class="img-loading">
          <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
        </div>

        <div style="margin-top: 20px;">
          <mat-label class="label-loading">
            <div>La información está siendo procesada.</div>
            <div>Esto no tomará mucho tiempo.</div>
          </mat-label>
        </div>

      </div>


    </div>

    <!--TABLA-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" *ngIf="loading == false && ELEMENT_DATA.length > 0">


        <div class="table-responsive-simpli add_factoring">

          <table mat-table [dataSource]="dataSource">


            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef style="width: 50px !important;">      
                <mat-checkbox matTooltip="Seleccionar todo" (change)="$event ? masterToggle($event) : null" [checked] = "selection.hasValue() && isAllSelected()" (click)="selectAllElements()">
                </mat-checkbox>
              </th>

              <td mat-cell *matCellDef="let element" style="width: 50px !important;text-align: justify;padding-left: 22px !important;"> 

                <div style="border-radius: 3px;transition: all .3s ease-in-out;" (click)="$event.stopPropagation()">
                  <input type="checkbox" (click)="selectOneElement(element.venta_id, element); $event.stopPropagation()" 
                  (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                </div>

              </td>
            </ng-container>
          
            <ng-container matColumnDef="deudor">
              <th mat-header-cell *matHeaderCellDef style="min-width: 200px !important;"> Deudor </th>
              <td mat-cell *matCellDef="let element" style="min-width: 200px !important;"> 
                <input class="no-input" readonly value="{{element.receptoracreedor.business_name}}" style="text-align: justify;">
              </td>
            </ng-container>
          
            <ng-container matColumnDef="factura">
              <th mat-header-cell *matHeaderCellDef> N° Factura </th>
              <td mat-cell *matCellDef="let element"> 
                <input class="no-input" readonly value="{{element.folio}}">
              </td>
            </ng-container>
          
            <ng-container matColumnDef="rut">
              <th mat-header-cell *matHeaderCellDef> RUT </th>
              <td mat-cell *matCellDef="let element">
                <input class="no-input" readonly value="{{element.receptoracreedor.rut}}">
              </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef> Fecha de emisión </th>
              <td mat-cell *matCellDef="let element"> 
                <input class="no-input" readonly value="{{element.fecha_emision}}">
              </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell *matHeaderCellDef> Monto </th>
              <td mat-cell *matCellDef="let element"> 
                <input class="no-input" readonly value="${{formatMoney(element.mnt_total)}}">
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          

        </div>

        <div style="text-align: center;margin-top: 40px;font-size: 18px;">
          <mat-label>Total: <strong style="margin-left: 20px;">${{total_facturas}}</strong></mat-label>
        </div>

    </div>

    <!--no encontro facturas pero tiene credenciales-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && ELEMENT_DATA.length == 0 && tiene_credenciales == true">

      <div class="empty-msg" style="height: 410px;">

        
        <div style="padding-top: 50px;text-align: center;">
          <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
        </div>

        <div style="padding-top: 50px;text-align: center;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;">
              ¡Por ahora no hay facturas en el sistema!
            </div>

            <div class="label-empty" style="margin-top: 20px;">
              Para simular, agrega una
            </div>
            <div class="label-empty">factura o carga una</div>
            <div class="label-empty">nómina de tus facturas. </div>

          </mat-label>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="secondary-button"  mat-flat-button (click)="open_agregar_facturas()">Agregar factura</button>

              <button class="secondary-button"  mat-flat-button (click)="open_agregar_facturas_by_nomina();">Cargar nómina</button>
            </div>
            
          </div>
        </div>

      </div>


    </div>
    
    <!--no tiene credenciales, es usuario nuevo-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && ELEMENT_DATA.length == 0 && tiene_credenciales == false">

      <div class="empty-msg" style="margin-top: 100px;">

        
        <div style="padding-top: 50px;text-align: center;">
          <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
        </div>

        <div style="padding-top: 50px;text-align: center;">
          <mat-label class="label-bienvenida">
            <div class="label-empty" style="font-weight: bold;">
              Agrega facturas individualmente o 
            </div>

            <div class="label-empty" style="font-weight: bold;">
              carga una nómina completa. ¡Tú eliges!
            </div>

          </mat-label>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="secondary-button" (click)="open_agregar_facturas()"  mat-flat-button>Agregar factura</button>

              <button class="secondary-button"  (click)="open_agregar_facturas_by_nomina();" mat-flat-button>Cargar nómina</button>
            </div>
            
          </div>
        </div>

      </div>


    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false && ELEMENT_DATA.length > 0">
      <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
    </div>

  </div>


  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

      <div class="btn-simpli-container">
        <button class="primary-button" [disabled]="simular_disabled" (click)="simular()"  mat-flat-button>Agregar</button>
      </div>
      
    </div>
  </div>

</mat-dialog-content>
