<div class="row row-responsive" style="margin-bottom: 50%;" *ngIf="!comenzar_simulacion">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <span class="material-icons" (click)="return()">
      keyboard_return
    </span>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="ELEMENT_DATA.length > 0">

    <h1 class="titulo-simpli-responsive">Selecciona las facturas para tu simulación</h1>
    <small class="subtitulo-simpli-responsive">Cuentas con ${{formatMoney(suma_facturas)}} para simular la operación con las facturas que selecciones.</small>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="ELEMENT_DATA.length > 0">

    <div class="row">

      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <form  [formGroup]="valFormFilter">
                <mat-form-field appearance="fill">
                  <mat-label>Buscar</mat-label>
                  <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
                  <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                  <mat-hint>Buscar por monto o número de operación</mat-hint>
                </mat-form-field>
          </form>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align: right;">
        <form>
          <button mat-mini-fab style="margin-top: 25px;" class="primary-button" color="primary" (click)="open_agregar_facturas_dialog()">
            <mat-icon>add</mat-icon>
          </button>
        </form>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align: left;">

              <form [formGroup]="valFormFechas">

                    <button mat-mini-fab color="primary"   style="margin-top: 25px;" class="primary-button" (click)="picker.open()">
                      <mat-icon>event</mat-icon>
                    </button>

                    <mat-form-field appearance="fill" color="primary" style="height: 0px;visibility: hidden;">
                        <mat-label>Fecha</mat-label>
                        <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" >
                            <input matStartDate readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                            <input matEndDate readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
                        </mat-date-range-input>
                        <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker>
                            <mat-date-range-picker-actions style="justify-content: center;">
                              <button class="secondary-button" (click)="reset()" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                              <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="filtrar_por_fechas()">Aplicar</button>
                                </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                      </mat-form-field>
              </form>
      </div>

    </div>
    
  </div>


  <!--Loading-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true && ELEMENT_DATA.length == 0">

    <div class="container-loading">

      
      <div class="img-loading">
        <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
      </div>

      <div style="margin-top: 20px;">
        <mat-label class="label-loading">
          <div>La información está siendo procesada.</div>
          <div>Esto no tomará mucho tiempo.</div>
        </mat-label>
      </div>

    </div>


  </div>

  <!--TABLA-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" *ngIf="loading == false && ELEMENT_DATA.length > 0">

    <mat-card class="card-tabla" style="position: relative;margin-top: 20px !important;">

        <table mat-table [dataSource]="dataSource" class="table-res">

          <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef style="width: 40px !important;text-align: center !important;min-width: 40px !important;max-width: 40px !important;">      
              <mat-checkbox (change)="$event ? masterToggle($event) : null" [checked] = "selection.hasValue() && isAllSelected()" (click)="selectAllElements()">
              </mat-checkbox>
            </th>

            <td mat-cell *matCellDef="let element" style="text-align: center !important;min-width: 40px !important;max-width: 40px !important;width: 40px !important;"> 

              <div style="border-radius: 3px;transition: all .3s ease-in-out;" (click)="$event.stopPropagation()">
                <input type="checkbox" (click)="selectOneElement(element.venta_id, element); $event.stopPropagation()" 
                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
              </div>

            </td>
          </ng-container>

          <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef>Seleccionar todo</th>
              <td mat-cell *matCellDef="let element; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
              
              <div class="box-factura" (click)="$event.stopPropagation()">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                      <div class="container-label-res"><mat-label style="width: 20%;" class="left-label">Deudor:</mat-label><mat-label class="right-label">{{element.receptoracreedor.business_name}}</mat-label></div>
                      <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{element.folio}}</mat-label></div>
                      <div class="container-label-res"><mat-label class="left-label">RUT:</mat-label><mat-label class="right-label">{{element.receptoracreedor.rut}}</mat-label></div>
                      <div class="container-label-res"><mat-label class="left-label">Emisión:</mat-label><mat-label class="right-label">{{element.fecha_emision}}</mat-label></div>
                      <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(element.mnt_total)}}</mat-label></div>

                      <div><mat-label class="pink-label">Ver detalle <i class="fa-solid fa-angle-right"></i></mat-label></div>
                    </div>
                  </div>
              </div>
              
              </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; let doc; columns: displayedColumns;" ></tr>
        </table>
        

    </mat-card>

    <!--Footer-->
    <div id="footer-options-bar" class="row animate__animated animate__fadeIn" *ngIf="!simular_disabled">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;height: 10px;display: none;">
        <i class="fa-solid fa-caret-up" style="font-size: 30px;margin-top: -10px;color:var(--simpli-color6)"></i>
      </div>

      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 col-top">

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <input class="no-input-simpli" readonly value="Clientes seleccionados:">
              </div>
              <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                <input class="no-input-simpli-strong" readonly value="{{total_proveedores}}">
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <input class="no-input-simpli" readonly value="Facturas seleccionadas:">
              </div>
              <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                <input class="no-input-simpli-strong" readonly value="{{ arr_documentos_to_simulate.length }}">
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <input class="no-input-simpli" readonly value="Monto total:">
              </div>
              <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                <input class="no-input-simpli-strong" readonly value="${{total_facturas}}">
              </div>
            </div>
  

      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
          <button class="primary-button" [disabled]="simular_disabled"  mat-flat-button style="margin-top: 15px;" (click)="simular()">Iniciar simulación</button>
      </div>

    </div>

  </div>

  <!--no encontro facturas pero tiene credenciales-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && ELEMENT_DATA.length == 0 && tiene_credenciales == true">

    <div class="empty-msg" style="height: 410px;">

      
      <div style="padding-top: 50px;text-align: center;">
        <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
      </div>

      <div style="padding-top: 50px;text-align: center;">
        <mat-label class="label-bienvenida">
          <div class="label-empty" style="font-weight: bold;">
            ¡Por ahora no hay facturas en el sistema!
          </div>

          <div class="label-empty" style="margin-top: 20px;">
            Para simular, agrega una
          </div>
          <div class="label-empty">factura o carga una</div>
          <div class="label-empty">nómina de tus facturas. </div>

        </mat-label>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="btn-simpli-container">
            <button class="secondary-button"  mat-flat-button (click)="open_agregar_facturas()">Agregar factura</button>

            <button class="secondary-button"  mat-flat-button (click)="open_agregar_facturas_by_nomina();">Cargar nómina</button>
          </div>
          
        </div>
      </div>

    </div>


  </div>
  
  <!--no tiene credenciales, es usuario nuevo-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false && ELEMENT_DATA.length == 0 && tiene_credenciales == false">

    <div class="empty-msg" style="margin-top: 100px;">

      
      <div style="padding-top: 50px;text-align: center;">
        <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
      </div>

      <div style="padding-top: 50px;text-align: center;">
        <mat-label class="label-bienvenida">
          <div class="label-empty" style="font-weight: bold;">
            Agrega facturas individualmente o 
          </div>

          <div class="label-empty" style="font-weight: bold;">
            carga una nómina completa. ¡Tú eliges!
          </div>

        </mat-label>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="btn-simpli-container">
            <button class="secondary-button" (click)="open_agregar_facturas()"  mat-flat-button>Agregar factura</button>

            <button class="secondary-button"  (click)="open_agregar_facturas_by_nomina();" mat-flat-button>Cargar nómina</button>
          </div>
          
        </div>
      </div>

    </div>


  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false && ELEMENT_DATA.length > 0">
    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
  </div>

</div>


<!--START SIMULATION-->
<div class="row row-responsive bottom-responsive" *ngIf="comenzar_simulacion">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <app-start-simulation [facturas]="facturas"></app-start-simulation>
  </div>
</div>
