<section class="area-login animate__animated animate__fadeIn" *ngIf="showForm">
  <mat-card class="login" style="position: relative;">
    <mat-card-content>

      <div class="simpli-floating-box-header">
        <div class="simpli-floating-box-logo text-center">
          <img src="../../assets/simpli/simplilogo2.svg" height="90px">
        </div>
        <div class="simpli-floating-box-title text-center">
          <span class="color-tertiary"> 
            Crea tu nueva contraseña
          </span>
        </div>
      </div>
  
      <div class="simpli-floating-box-body">
        <form [formGroup]="valForm" (submit)="submit_form($event, valForm.value)">
    
          <mat-form-field appearance="fill">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>        
            <button matSuffix mat-icon-button type="button" (click)="hide = !hide" [attr.aria-label]="'Ocultar password'"
              [attr.aria-pressed]="hide">
              <mat-icon class="simpli-color-gris">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>Ingresa tu nueva contraseña</mat-hint>
            <mat-error *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-error *ngIf="valForm.controls['password'].hasError('minlength')">
              Debe contener un mínimo de 6 caracteres
            </mat-error>
          </mat-form-field>
      
          <mat-form-field appearance="fill">
            <mat-label>Repite tu contraseña</mat-label>
            <input matInput formControlName="re_password" [type]="hide2 ? 'password' : 'text'" required>
            <button matSuffix mat-icon-button type="button" (click)="hide2 = !hide2" [attr.aria-label]="'Ocultar password'"
              [attr.aria-pressed]="hide2">
              <mat-icon class="simpli-color-gris">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>Vuelve a ingresar tu nueva contraseña</mat-hint>
            <mat-error *ngIf="valForm.controls['re_password'].hasError('required') && (valForm.controls['re_password'].dirty || valForm.controls['re_password'].touched)">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-error *ngIf="valForm.get('re_password').hasError('equalTo')">
              La contraseña ingresada no coincide
            </mat-error>
          </mat-form-field>

          <div class="btn-container">
            <button class="primary-button" [disabled]="show_spinner" mat-flat-button type="submit" color="primary" style="margin-right: 0px;">
              <mat-label *ngIf="show_spinner == false">Crear contraseña</mat-label>
              <span *ngIf="show_spinner == true"><i class="fa-solid fa-spinner fa-spin-pulse" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
            </button>
          </div>

          <div class="center-div">
            <a mat-button  class="label-button" routerLink="/login">Volver</a>
          </div>
      
        </form>
      </div>

    </mat-card-content>
  </mat-card>
</section>



<section class="area-login animate__animated animate__fadeIn" *ngIf="!showForm">
  <mat-card class="login" style="position: relative;">
    <mat-card-content>

      <div class="simpli-floating-box-body">
        <div class="simpli-floating-box-logo text-center" style="text-align: center;">
          <img src="assets/img/warning.png" alt=""> 
        </div>
          <div class="text-center">
            <span>
              <strong>¡Ups! Algo ocurrió en el proceso</strong> 
              Este enlace ha caducado. Solicita nuevamente la recuperación de contraseña y recibirás uno nuevo.
            </span>
          </div>

          <div class="btn-container">
            <button class="primary-button"  routerLink="/forgot-password" mat-flat-button type="submit" color="primary">
              <mat-label>Volver a solicitar</mat-label>
            </button>
          </div>
      </div>

    </mat-card-content>
  </mat-card>
</section>