<mat-card class="add_factoring">
  <mat-card-title>Giro a tu cuenta</mat-card-title>
  <mat-card-subtitle>La diferencia de dinero de esta operación se girará a la cuenta seleccionada, puedes modificarla si es otra.</mat-card-subtitle>
  <mat-card-content>
    
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"style="position: relative">
          
        <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
          <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
        </div>  
                  
        <table mat-table [dataSource]="dataSourceGiro" class="w-100">         
      
          <ng-container matColumnDef="cuentas">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Cuenta </th>
            <td mat-cell *matCellDef="let element" matTooltip="Cuenta" style="min-width: 130px;text-align: center;position: relative;"> 
              
              <span *ngIf="element.has_account == false && !has_accounts && !id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta">
                <i class="fa-light fa-exclamation-circle not-account-icon"></i>
              </span>


              <span *ngIf="element.has_account != false && has_accounts && id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta">
                <i class="fa-light fa-plus-circle add-icon"></i>
              </span>


              <span *ngIf="element.has_account == false && has_accounts && !id_banco_selected" (click)="create_account(has_account)" matTooltip="Agregar cuenta">
                <i class="fa-light fa-exclamation-circle not-account-icon"></i>
              </span>
              
                  
              <mat-form-field class="selector-bank">
                
                <mat-select [(value)]="id_banco_selected" (selectionChange)="change_bank($event)" >
                  <mat-option *ngFor="let account of element.cuentas" [value]="account.id" [disabled]="!is_not_done">
                    {{account.bank.name + ' | ' + account.tipo_cuenta + ' ' + account.number}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="CuentaRut">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.CuentaRut}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

          <ng-container matColumnDef="CuentaName">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;">Titular de la cuenta </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"   value="{{element.CuentaName}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

          <ng-container matColumnDef="TipoCuenta">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Tipo Cuenta </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"   value="{{element.TipoCuenta}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="bankName">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Banco </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.bankName}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

          <ng-container matColumnDef="totalGiro">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Estado </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px; justify-content: center;" matTooltip="{{ element.status==0 ? 'Creado' : element.status==1 ? 'En proceso' : 'Pagado' }}"> 
              
              <table style="width: 90%;">
                <tr>
                  <td style="text-align: right;">
                    <span matTooltip="">
                      <i [ngClass]="{'fa-light': true, 
                        'fa-check-circle': element.status==2 || element.status==0,
                        'fa-spinner': element.status==1,
                        'done-status': element.status==2, 
                        'on-process-status': element.status==1, 
                        'created-status': element.status==0}"></i>
                    </span>
                  </td>
                  <td style="text-align: left;">
                    <span (click)="download_file(element.link)" matTooltip="">
                      <i [ngClass]="{'fa-light': true, 
                        'fa-file-download': true,
                        'done-status-link': element.link!=null,
                        'created-status-link': element.link==null}"></i>
                    </span>
                   </td>
                </tr>
              </table>
                          
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsGiro; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsGiro"></tr>

        </table>
      
      </div>
    </div>
  </mat-card-content>
</mat-card>

