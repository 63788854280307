<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close *ngIf="!confirmar">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div *ngIf="confirmar" style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color7)" class="fa-light fa-circle-check"></i>
        </div>

        <div *ngIf="!confirmar" class="label-dialog-title">¡Estás a punto de rechazar las condiciones!</div>
        <div *ngIf="!confirmar" class="label-dialog">Si lo haces, la operación será</div>
        <div *ngIf="!confirmar" class="label-dialog">descartada de forma definitiva.</div>

        <div *ngIf="confirmar" class="label-dialog-title">¡Rechazo de condiciones confirmado!</div>
        <div *ngIf="confirmar" class="label-dialog">Tu ejecutivo te contactará para conversar y conocer</div>
        <div *ngIf="confirmar" class="label-dialog">las razones. Queremos escucharte y asesorarte.</div>


        <div class="row" *ngIf="!confirmar">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button mat-dialog-close>
                  Cancelar
                </button>

                <button class="secondary-button" mat-flat-button (click)="rechazar()">
                  Rechazar
                </button>
              </div>
              
            </div>
        </div>

        <div class="row" *ngIf="confirmar">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container">
              <button class="primary-button" mat-flat-button mat-dialog-close routerLink="/home">
                Volver al inicio
              </button>
            </div>
            
          </div>
        </div>

    </div>


    
</mat-dialog-content>