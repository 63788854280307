<div [ngClass]="{'row':true, 'row_main': step != 2}">

  <!--detalle simulación-->
  @if(step == 1){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


      <div style="margin-top: 30px;">
        <div class="titulo-card" style="margin-bottom: 10px;">¡revisa la simulación de tu primera operación en Simpli!</div>
        <div class="label-container" style="text-align: center;">
          Las facturas que elegimos están <span (click)="open_modal_info(10)">preaprobadas</span> para una operación inmediata.
        </div>
      </div>
  
  
  
    </div>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
  
  
        <mat-card class="centerabsolute" style="max-width: 600px;">
        
          <mat-card-content>
  
            <div class="box-info-onboarding">
              <div class="icon"><i class="fa-sharp fa-solid fa-circle-info"></i></div>
              <div>La primera <span (click)="open_modal_info(6)">operación de factoring</span> tiene un costo de apertura de $98.500, que puedes pagar en 2 cuotas.</div>
            </div>
  
            <div class="box-info-facturas">
              <div class="titulo-facturas">Facturas de la simulación</div>
  
              <div class="container-facturas add_factoring">

                <div class="row" style="margin-bottom: 5px;">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Cantidad de facturas:</div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="font-weight: bold;text-align: right;">
                    @if(loading_cantidad){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{cantidad_facturas}}
                    }
                  </div>
  
                </div>

                <div>
                  <div style="text-align: right;">
                    <mat-label class="pink-label" (click)="detalle_factura()">Abrir detalle <i class="fa-solid fa-angle-right"></i></mat-label>
                  </div>
                </div>

              </div>
            </div>
  
            <div class="box-info-facturas">
              <div class="titulo-facturas">Montos asociados</div>
  
              <div>
                <div class="row" style="margin-bottom: 5px;">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto total simulado:</div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;padding-right: 40px;">
                    @if(loading){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{settings.formatMoney(monto_simulado)}}
                    }
                  </div>
                </div>
                <div class="row" style="margin-bottom: 5px;">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Porcentaje de financiamiento:</div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;padding-right: 40px;position: relative;">
                    <span class="edit"><i class="fa-solid fa-pencil"></i></span>
                    @if(loading){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{porcentaje_financiamiento}}%
                    }
                  </div>
                </div>
                <div class="row" style="margin-bottom: 5px;">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto de anticipo:</div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;padding-right: 40px;">
                    @if(loading){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      ${{settings.formatMoney(monto_anticipo)}}
                    }
                  </div>
                </div>
                <div class="row" style="margin-bottom: 5px;">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vencimiento:</div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;padding-right: 40px;position: relative;">
                    <span class="edit"><i class="fa-solid fa-pencil"></i></span>
                    @if(loading){
                      <skeleton-loader></skeleton-loader>
                    }@else{
                      {{dias_promedio}} días
                    }
                  </div>
                </div>
              </div>
  
            </div>
  
            <div class="contact-container" style="margin-top: 10px;">
              <div class="icons">
                <span (click)="step = 3"><i class="fa-sharp fa-light fa-pencil"></i>Editar simulación</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span><i class="fa-sharp fa-light fa-download"></i>Descargar simulación</span>
              </div>
            </div>
  
            <div class="caja-botones">
              <button mat-stroked-button class="primary-button" (click)="step = 2">Continuar</button>
            </div>
  
          </mat-card-content>
  
        </mat-card>
  
  
  
    </div>
  }


  <!--simulacion ok-->
  @if(step == 2){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


      <mat-card class="centerabsolute2" style="max-width: 700px;">
        <div class="titulo-card">Solo falta que formalices tu incorporación como cliente</div>
        <mat-card-content>

          <div class="container-final" style="flex-direction: column;">

            <div class="imagen-final">
              <img src="../../assets/iconografia/formalizar.png" style="height: 200px !important;">
            </div>

            <div class="container-titulos" style="margin-top: 20px;margin-bottom: 10px;">
              <div class="label-container">
                Para seguir adelante con el financiamiento de tu<br>operación, debes <strong>formalizar tu incorporación.</strong>

                <br><br>
                En breve, <strong>Roger Perdomo te contactará</strong><br>para guiarte en este proceso.
              </div>
            </div>

            <div class="caja-botones">
              <button mat-stroked-button class="primary-button" (click)="step = 1">Volver a revisar simulación</button>
              <button mat-stroked-button class="secondary-button" (click)="home()">Ir al inicio de plataforma</button>
            </div>

          </div>

        </mat-card-content>
      </mat-card>



    </div>
  }

  <!--editar simulacion-->
  @if(step == 3){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


      <div style="margin-top: 30px;">
        <div class="titulo-card" style="margin-bottom: 10px;">¡revisa las facturas que seleccionamos para tu simulación!</div>
        <div class="label-container" style="text-align: center;">
          Las siguientes facturas <strong>fueron evaluadas</strong> y ya cuentan con nuestra <span (click)="open_modal_info(10)">preaprobación</span>.
        </div>
      </div>
  
  
  
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
      <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

            <div style="display: flex;align-items: center;">
              <mat-form-field appearance="fill" style="width: 400px;">
                <mat-label>Buscar</mat-label>
                <input matInput [formControl]="buscar" (change)="applyFilter()">
                <mat-icon matSuffix class="icon-suffix">search</mat-icon>
              </mat-form-field>
  
              <button style="margin-left: 20px;" (click)="open_modal_info(11)" mat-stroked-button class="secondary-button">Agregar más facturas</button>
            </div>

          </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

      <div class="row">

        @if(facturas.length > 0 && loading_facturas == false){
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
            <div class="label-table">Puedes eliminar o agregar más facturas antes de operar.</div>

            <mat-card class="card-tabla">
              <mat-card-content>
                <div class="table-responsive-simpli add_factoring">

                  <table mat-table [dataSource]="dataSource">
      
      
                    <ng-container matColumnDef="deudor">
                      <th mat-header-cell *matHeaderCellDef>Deudor</th>
                      <td mat-cell *matCellDef="let element">
                        <input readonly class="no-input" value="{{element.document.nombre_deudor}}">
                      </td>
                    </ng-container>
      
                    <ng-container matColumnDef="factura">
                      <th mat-header-cell *matHeaderCellDef>Factura</th>
                      <td mat-cell *matCellDef="let element">
                        <input readonly class="no-input" value="{{element.document.folio}}">
                      </td>
                    </ng-container>
      
                    <ng-container matColumnDef="rut">
                      <th mat-header-cell *matHeaderCellDef>RUT</th>
                      <td mat-cell *matCellDef="let element">
                        <input readonly class="no-input" value="{{element.document.rut_deudor}}">
                      </td>
                    </ng-container>
      
                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef>Fecha de emisión</th>
                      <td mat-cell *matCellDef="let element">
                        <input readonly class="no-input" value="{{element.document.fecha_documento}}">
                      </td>
                    </ng-container>
      
                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef>Monto</th>
                      <td mat-cell *matCellDef="let element">
                        <input readonly class="no-input" value="${{settings.formatMoney(element.document.monto_total)}}">
                      </td>
                    </ng-container>
      
                    <ng-container matColumnDef="accion">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                        <span *ngIf="!element.isLoading" (click)="delete_factura(element.document.document_factura_id, element.document_simulacion_id)"><i class="fa-regular fa-trash-can"></i></span>
                        <span *ngIf="element.isLoading"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
                      </td>
                    </ng-container>
                  
      
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
      
                </div>
              </mat-card-content>
            </mat-card>

            <div class="caja-botones" style="text-align: right;">
              <button mat-stroked-button class="primary-button" (click)="step = 1">Volver</button>
            </div>


          </div>
        }

        @if(facturas.length == 0 && loading_facturas == true){
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
            <loading-interno></loading-interno>
          </div>
        }
    
        @if(loading_facturas == false){
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
            <paginador-onboarding
            [pageSize]="7"
            [totalElements]="cantidad_facturas"
            [currentPage]="page"
            (pageChange)="pageEvent($event)">
            </paginador-onboarding>
          </div>
        }

        @if(facturas.length == 0 && loading_facturas == false){
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;margin-bottom: 20px;margin-top: 20px;">
            <no-hay-registros [text]="'No se encontraron facturas.'"></no-hay-registros>
          </div>
        }
 
      </div>
    </div>
  }

  
</div>

