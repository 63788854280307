import {Component, HostListener, Inject,OnInit,} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CambiarEmpresa } from './cambiar-empresa/cambiar-empresa';
import { MyExecutive } from './my-executive/my-executive';
import { SettingsService } from '../settings/settings.service';
import { ConfirmDialog } from './dialog.component';
import { PushNotificationService } from '../services/push-notification.service';
import { NotificationsDialog } from './dialog-notifications.component';
import { CredencialesSII } from '../credenciales-sii/credenciales-sii';
import { IMessage, WebSocketService } from '../services/websocket.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
})
export class LayoutComponent implements OnInit {
  events: string[] = [];
  opened: boolean = false;
  is_image: boolean = false;

  full_name: string = '';
  avatar: string =
    'https://cdn.pixabay.com/photo/2017/07/18/23/23/user-2517433_960_720.png';

  selectedOption: string = '';
  showSpinner: boolean = true;
  mobileQuery!: MediaQueryList;
  mode: string = 'side';
  showTool: boolean = true;
  tool_class: string = '';
  tool_title: string = 'Recuerda llenar tus datos personales';
  tool_body: string =
    'Aún no completas tu perfil, es importante conocer tu dirección telefono y cuentas bancarias, para que puedas comenzar a invertir.';
  tool_link: string = '#/home/info';
  tool_link_text: string = 'Completa tu perfil aquí ';

  initials: string = '';

  rol: any;

  cant_sol_lineas: number = 0;
  cant_badges: number = 0;
  cant_sol_balance: number = 0;
  cant_sol_legales: number = 0;
  display_badge3: boolean = false;
  menu_admin: string = '';
  visible: boolean = false;
  display: boolean = true;
  display2: boolean = true;
  rut: any;
  razon_social: any;
  fecha_completa: any;

  Companies: any[] = [];

  //ALL COMPANIES
  stateCtrl = new FormControl();
  filteredStates!: Observable<State[]>;
  toHighlight: string = '';

  name1: string = 'Miguel Rojas';
  name2: string = 'Roger Perdomo';
  name3: string = 'Jose Castro';
  name4: string = 'Rafaela Solis';

  states: State[] = [];
  public selected2: any;

  valFormFinal: FormGroup;
  myControl = new FormControl();
  filteredOptions!: Observable<string[]>;
  options: State[] = [];

  open_menu() {
    this.opened = !this.opened;

    if (!this.opened) {
      $('.logo_home').addClass('logo_home_active');
    } else {
      $('.logo_home').removeClass('logo_home_active');
    }
  }

  mouseleave($event: any) {
    $('#menupagoproveedores').css('display', 'none');
  }

  cuenta() {
    this.router.navigate(['home/info']);
  }

  contrasena() {
    this.router.navigate(['home/change-password']);
  }

  token: any;
  id_company = localStorage.getItem('company_id');
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public settings: SettingsService,
    fb: FormBuilder,
    public snackbar: MatSnackBar,
    private notificacion: PushNotificationService,
    private ws: WebSocketService
  ) {
    this.stateCtrl = new FormControl();

    this.valFormFinal = fb.group({
      business_name: '',
    });

    this.token = localStorage.getItem('token');
    let company_id = localStorage.getItem('company_id');
  }

  is_responsive: boolean = false;
  menu_cliente: boolean = true;
  menu_confirming_pronto_pago: boolean = false;
  ngOnInit() {

    this.id_company = localStorage.getItem('company_id');

    if (localStorage.getItem('side-menu') == 'true') {
      this.mode = 'side';
      this.opened = true;
      this.hasBackdrop = false;
    } else {
      this.mode = 'over';
      this.opened = false;
      this.hasBackdrop = true;
    }

    if ($(window).width() <= 1024) {
      this.is_responsive = true;
    }

    this.verify();
    this.get_personal_data();


    if ($(window).width() < 768) {
      this.mode = 'over';
      this.opened = false;
    }

    this.rol = localStorage.getItem('role');
    if (this.rol == 'clie') {
      this.menu_cliente = true;
      this.menu_confirming_pronto_pago = false;
    } else if (this.rol == 'conf' || this.rol == 'prpa') {
      this.menu_cliente = false;
      this.menu_confirming_pronto_pago = true;
    }

    this.load_info_company();


    // NOTIFICACIONES

    this.getNotificaciones();
    this.notificacion.receiveMessage().subscribe((payload: any) => {
      // this.snackbar.open(payload.data.title, "Aceptar", {
      //   panelClass: "simpli-snack",
      //   duration: 10000
      // });
      this.getNotificaciones();
    });


    this.set_storage_detail_final()


    this.validatePermissionsNotifications()

    this.initWebsocketSubscribe()
  }


  initWebsocketSubscribe() {
    this.ws.onMessage().subscribe((msg: IMessage) => {

      switch (msg.command) {
        case 'connect-callback':

          break;
        default:
          break;
      }

    })
  }

  reloadPage(link: string) {

    if(link.includes(SettingsService.lemu_base)) {
      const _link = link.split('#')[1]

      this.router.navigate([_link])
      return
    }
    window.open(link, '__blank')
  }

  async set_storage_detail_final() {
    try {
      let company_id = localStorage.getItem('company_id')

      const bd: any = await this.settings.get_query(1, "clientcartera/" + company_id + "/");
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          if (body_data.datos != null) {

            localStorage.setItem('rut', body_data.datos.rut)

          }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  notificacionesSimpli: any[] = [];
  // window: any;
  totalNotificaciones!: any;
  loadingNotificaciones: boolean = false;

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent) {

    this.getNotificaciones();
  }

  validatePermissionsNotifications() {

    if ('Notification' in window) {
      // Verificar si el usuario ha dado permisos
      Notification.requestPermission().then(function (permission) {
          if (permission === 'granted') {

          } else if (permission === 'denied' || permission === 'default') {
            // El usuario no ha dado permisos, abrir el diálogo de solicitud

          }
      });
    }
  }

  gestionarNotificaciones() {
    let dialogRef = this.dialog.open(NotificationsDialog, {
      autoFocus: false,
      width: '400px',
      panelClass: 'dialog-rosa',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.selectedOption = result;
      if (this.selectedOption === 'Y') {
        this.notificacion.requestPermission().then((token) => {
          this.snackbar.open('Notificaciones activadas', 'Aceptar', {
            panelClass: 'simpli-snack',
            duration: 10000,
          });
        });
      } else {
        this.notificacion.bloquearNotificaciones();
      }
    });
  }

  async getNotificaciones() {
    this.loadingNotificaciones = true;
    try {
      const bd: any = await this.settings.get_query(
        1,
        `notificaciones/push-notifications/`
      );
      var body_data: any =bd._body.success;
      console.log(body_data)
      switch (bd.status) {
        case 200:
          this.totalNotificaciones = body_data.total;
          this.notificacionesSimpli = body_data.data;
          this.loadingNotificaciones = false;
          break;
        default:
          this.loadingNotificaciones = false;
          break;
      }
    } catch (bd: any) {
      this.loadingNotificaciones = false;
      this.settings.manage_errors(bd.status, 'Get notificaiones', bd, false);
    }
  }

  async readNotifications() {
    try {
      this.loadingNotificaciones = true;
      console.log('click aca uwu')
      const bd: any = await this.settings.get_query(
        2,
        `notificaciones/cliente-notificaciones-push/`
      );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.getNotificaciones();
          break;
        default:
          this.loadingNotificaciones = false;
          break;
      }
    } catch (bd: any) {
      this.loadingNotificaciones = false;
      this.settings.manage_errors(bd.status, 'Get notificaiones', bd, false);
    }
  }

  async learweas(notificacion: any) {
    this.loadingNotificaciones = true;
    try {
      let device_token = localStorage.getItem('device_token')
      const bd: any = await this.settings.get_query(
        3,
        `notificaciones/push-notifications/${notificacion._id}/`,
        { device_token: device_token }
      );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.getNotificaciones();
          this.router.navigateByUrl(notificacion?.link);
          break;
        default:
          this.loadingNotificaciones = false;
          break;
      }
    } catch (bd: any) {
      this.loadingNotificaciones = false;
      this.settings.manage_errors(bd.status, 'Get notificaciones', bd, false);
    }
  }
  // NOTIFICACIONES

  go_info() {
    this.router.navigate(['home/info']);
  }

  verify() {
    if (localStorage.getItem('token') == null) {
      this.router.navigate(['login']);
    }
  }

  activate_chat: boolean = false;
  name: any = '';
  async get_personal_data() {
    try {
      const bd: any = await this.settings.get_query(1, 'current-user/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:


          this.activate_chat = body_data.activate_chat
          this.initials =
            body_data.user.first_name.charAt(0) +
            body_data.user.last_name.charAt(0);
          this.full_name =
            body_data.user.first_name.split(' ')[0] +
            ' ' +
            body_data.user.last_name.split(' ')[0];
          localStorage.setItem(
            'full_name',
            body_data.user.first_name.split(' ')[0]
          );
          localStorage.setItem('user_id', body_data.user.id);
          this.avatar = SettingsService.API_ENDPOINT + body_data.user.avatar;

          this.name = body_data.user.first_name;

          if (body_data.user.type_avatar == 0) this.is_image = false;
          else this.is_image = true;


          break;
        default:

          break;
      }
    } catch (bd: any) {
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      localStorage.clear();
      if (username !== null && password !== null) {
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
      }
      this.router.navigateByUrl('/login');

    }
  }

  close_tool() {
    this.tool_class = '';
  }

  logout() {
    let dialogRef = this.dialog.open(ConfirmDialog, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.selectedOption = result;

      if (this.selectedOption == 'Y') {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('device_token')
        this.router.navigateByUrl('/login');
      }
    });
  }

  filterStates(name: string) {
    this.toHighlight = name;
    return this.states.filter(
      (state) => state.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  async set_storage_detail(company_id: any) {
    try {
      const bd: any = await this.settings.get_query(1, 'clientcartera/' + company_id + '/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          if (body_data.datos != null) {
            localStorage.setItem('rut', body_data.datos.rut);
            localStorage.setItem('razon_social', body_data.datos.nombre);
            localStorage.setItem('fecha', body_data.datos.date);

            this.rut = body_data.datos.rut;
            this.razon_social = body_data.datos.nombre;
            this.fecha_completa = body_data.datos.date;

            localStorage.setItem('company_id', company_id);
          }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  company_selected:any = '';

  loadingCompany:boolean=true;
  async load_info_company() {

    this.company_selected = localStorage.getItem('business_name');
    this.get_executive_info();


  }

  findObjectByKey(array: any, key: any, value: any) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option) => option.name.toLowerCase().includes(filterValue) === true
    );
  }

  nombre_executive: any;
  executive_mail_to: any;
  executive_cel: any;
  executive_mail: any;
  url_qr: any;
  src_image: any;
  cargo_id_company: boolean = false;
  async get_executive_info() {
    try {
      let id_company = localStorage.getItem('company_id');

      const bd: any = await this.settings.get_query(1, 'company/' + id_company + '/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.cargo_id_company = true;

        this.nombre_executive =
          body_data.company.executive.first_name +
          ' ' +
          body_data.company.executive.last_name;
        this.executive_mail_to = 'mailto:' + body_data.company.executive.email;
        this.executive_cel = body_data.company.executive.cel_phone;
        this.executive_mail = body_data.company.executive.email;
        this.url_qr =
          SettingsService.API_ENDPOINT +
          'media/codigos_qr/' +
          String(body_data.company.executive.qr);

        if (body_data.company.executive.avatar != null) {
          this.src_image =
            SettingsService.API_ENDPOINT + body_data.company.executive.avatar;
        }

        this.loadingCompany = false;

          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.loadingCompany = false;
    }
  }

  openExecutiveInfo() {
    const dialogRef = this.dialog.open(MyExecutive, {
      width: "600px",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result:any) => {});
  }

  cambiarEmpresa() {
    const dialogRef = this.dialog.open(CambiarEmpresa, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.element = this;

    dialogRef.afterClosed().subscribe((result:any) => {});
  }

  TextAbstract(text: any, length: any) {
    if (text == null) {
      return '';
    }
    if (text.length <= length) {
      return text;
    }
    text = text.substring(0, length);
    var last = text.lastIndexOf(' ');
    text = text.substring(0, last);
    return text + '..';
  }
  openDialog() {

    var dialogRef: any;
    if (this.is_responsive == true) {
      dialogRef = this.dialog.open(CredencialesSII, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        autoFocus: false,
        disableClose: false,
      });
    } else {
      dialogRef = this.dialog.open(CredencialesSII, {
        width: '600px',
        autoFocus:false,
      });
    }
    dialogRef.componentInstance.id_company = localStorage.getItem('company_id');
    dialogRef.componentInstance.token = localStorage.getItem('token');
    dialogRef.componentInstance.parent = this;

    dialogRef.afterClosed().subscribe((result: any) => {});

  }

  show_snak(){
    this.snackbar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
      duration: 20000,
      panelClass: 'simpli-snack'
    });
  }

  showLabel1!: boolean;
  showLabel2!: boolean;
  showLabel3!: boolean;
  go_to_webinar(){
    window.open("https://www.youtube.com/live/oC1OAxx8zIM?si=XcSwEC9VdGUPDmYa", "_blank")
  }

  get_time_webinar(){
    const now = new Date();
    const march22_2024_10_59AM = new Date(2024, 2, 22, 10, 59);
    const march22_2024_11AM = new Date(2024, 2, 22, 11, 0);
    const march22_2024_14PM = new Date(2024, 2, 22, 14, 0);

    this.showLabel1 = now <= march22_2024_14PM;
    this.showLabel2 = now <= march22_2024_10_59AM;
    this.showLabel3 = now >= march22_2024_11AM && now <= march22_2024_14PM;

  }

  hasBackdrop:boolean=true;
  close_menu($event:any){
    console.log($event)

  }

  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('Company ID changed:', event.detail);
    this.ngOnInit();
  }

}



export interface State {
  id: any;
  name: any;
  rut: any;
}
