<div mat-dialog-title>

    <h1 class="titulo-simpli" *ngIf="step == 1">Ingresa los datos de tu factura</h1>
    <small class="subtitulo-simpli" *ngIf="step == 1 && empty_facturas == false">Completa todos los campos solicitados.</small>

    <h1 class="titulo-simpli" *ngIf="step == 2">Resumen de las facturas agregadas </h1>
    <small class="subtitulo-simpli" *ngIf="step == 2 && empty_facturas == false">Selecciónalas para ver toda la información.</small>

</div>

<div mat-dialog-content [ngStyle]="is_responsive ? {'min-height': '85vh'} : {}">

  <form  [formGroup]="valFormFilter" *ngIf="step == 1" class="animate__animated animate__fadeIn">
    <mat-form-field appearance="fill">
      <mat-label>Número de factura</mat-label>
      <input matInput formControlName="folio" type="number" (change)="find_duplicates()">
      <mat-hint>Ingresa el número de la factura</mat-hint>
      <mat-error *ngIf="valFormFilter.controls['folio'].hasError('required')">
        Este campo es <strong>obligatorio</strong>
      </mat-error>
      <mat-error *ngIf="valFormFilter.controls['folio'].hasError('invalid')">
        ¡Ups! Al parecer, esta factura ya existe
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>RUT</mat-label>
      <input type="text" matInput formControlName="rut" (change)="validate_rut()"  (keyup)="rut_key($event)">
      <mat-hint>Ingresa el RUT de la empresa deudora</mat-hint>
      <mat-spinner *ngIf="spinner_rut" matSuffix diameter="20" style="margin-right: 10px;"></mat-spinner>
      <mat-error *ngIf="valFormFilter.controls['rut'].hasError('required')">
        Este campo es <strong>obligatorio</strong>
      </mat-error>
      <mat-error *ngIf="valFormFilter.controls['rut'].hasError('invalid')">
        El RUT no es válido (verifícalo en la factura)
      </mat-error>
      <mat-icon matSuffix *ngIf="!spinner_rut" matTooltip="Es el RUT de la empresa que debe realizar el pago de la factura (tu cliente)">
        info_outline
      </mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Empresa deudora</mat-label>
      <input type="text" matInput formControlName="deudor">
      <mat-hint>Esta información se cargará al ingresar el RUT</mat-hint>
      <mat-error *ngIf="valFormFilter.controls['deudor'].hasError('required')">
        Este campo es <strong>obligatorio</strong>
      </mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill"  (click)="picker.open()">
      <mat-label>Fecha emisión</mat-label>
      <input type="text" readonly matInput [matDatepicker]="picker" (dateChange)="change_date($event)" formControlName="fecha_emision_utc">
      <mat-hint>Ingresa la fecha de emisión de la factura</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="valFormFilter.controls['fecha_emision'].hasError('required')">
        Este campo es <strong>obligatorio</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Monto factura</mat-label>
      <input matInput type="text" formControlName="monto" (keyup)="format_monto($event)">
      <mat-hint>Ingresa el monto total de la factura</mat-hint>
      <mat-error *ngIf="valFormFilter.controls['monto'].hasError('required')">
        Este campo es <strong>obligatorio</strong>
      </mat-error>
      <mat-error *ngIf="valFormFilter.controls['monto'].hasError('invalid')">
        El monto debe ser mayor a 0
      </mat-error>
    </mat-form-field>

    <div class="sep">
      <mat-label class="label-i">Monto total</mat-label>
      <mat-label class="label-d">${{montoformat}}</mat-label>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div class="btn-simpli-container">

          <button class="secondary-button" mat-dialog-close mat-flat-button>
            Cerrar
          </button>

          <button class="primary-button" (click)="submitForm($event, valFormFilter.value)" mat-flat-button>
            Agregar factura
          </button>
        </div>
        
      </div>
    </div>


  </form>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step == 2">

      <div class="container-exp add_factoring" *ngIf="empty_facturas == false">
        <mat-accordion>

          <mat-expansion-panel  [expanded]="(i.errorReemitida != null)" [ngClass]="{'error-facturas': i.errorReemitida != null}"  style="margin-bottom: 15px;" *ngFor="let i of obs | async">

            <mat-expansion-panel-header style="position: relative;">
              <mat-panel-title>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="c-exp">
                      <div class="l-exp-i"><input class="no-input l-exp-i error-label-noeditable" readonly value="N° Factura:"></div>
                      <div class="l-exp-d"><input class="no-input l-exp-d error-label-noeditable" readonly value="{{i.folio}}"></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="c-exp">
                      <div class="l-exp-i"><input class="no-input l-exp-i error-label-noeditable" readonly value="Monto:"></div>
                      <div class="l-exp-d"><input class="no-input l-exp-d error-label-noeditable" readonly value="${{formatMoney(i.monto)}}"></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="c-exp">
                      <div class="l-exp-i"><input class="no-input l-exp-i error-label-noeditable" readonly value="Deudor:"></div>
                      <div class="l-exp-d"><input class="no-input l-exp-d error-label-noeditable" readonly value="{{i.deudor}}"></div>
                    </div>
                  </div>
                </div>
              </mat-panel-title>

              <mat-panel-description class="exp-desc">
                <div style="margin-top: 20px;margin-right: 10px;z-index: 999999999;" matTooltip="Eliminar factura" (click)="delete_factura(i.folio); $event.preventDefault();"><i class="fa-regular fa-trash exp-delete"></i></div>
              </mat-panel-description>

            </mat-expansion-panel-header>



                <div class="row" style="margin-top: 20px;">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="c-exp">
                      <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="RUT:"></div>
                      <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="{{i.rut}}"></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="c-exp">
                      <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Fecha emisión:"></div>
                      <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="{{i.fecha_emision}}"></div>
                    </div>
                    <div class="c-exp" *ngIf="error_reemitida == true">
                      <div class="l-exp-i"><input class="no-input l-exp-i" readonly value="Error:"></div>
                      <div class="l-exp-d"><input class="no-input l-exp-d" readonly value="Esta factura no es de reemplazo."></div>
                    </div>
                  </div>
                </div>


          </mat-expansion-panel>

        </mat-accordion>

      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="facturas.length > 0">
          <mat-paginator [pageSizeOptions]="[6, 12, 24]"></mat-paginator>
        </div>
      </div>

      <div class="sep" *ngIf="empty_facturas == false">
        <mat-label class="label-i">Monto total</mat-label>
        <mat-label class="label-d">${{formatMoney(monto_total)}}</mat-label>
      </div>

      <div class="row" *ngIf="empty_facturas == true">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

          <div class="c-simpli">

              <div style="text-align: center;margin-bottom: 30px;">
                <i class="fa-regular fa-triangle-exclamation i-simpli"></i>
              </div>
    
              <div class="l-simpli">
                Debes agregar por lo menos 1 factura
              </div>
    
              <div class="l-simpli">
                para realizar la simulación
              </div>

          </div>


        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

          <div [ngClass]="{'disable-btn':empty_facturas == true, 'btn-simpli-container':true}">
            <button *ngIf="open_as_dialog == false && reemitida_mode == false"  mat-flat-button [ngClass]="{'primary-button':true}" (click)="go_to_simular()">
              Iniciar simulación
            </button>

            <button *ngIf="open_as_dialog == true && reemitida_mode == false"  mat-flat-button [ngClass]="{'primary-button':true}" (click)="go_to_simular()">
              Continuar
            </button>

            <button [disabled]="error_reemitida" *ngIf="reemitida_mode == true"  mat-flat-button [ngClass]="{'primary-button':true, 'disabled_btn':error_reemitida == true}" (click)="go_to_simular()">
              Continuar
            </button>

            <button  mat-flat-button (click)="add_mas_facturas()" [ngClass]="{'secondary-button':true}">
              Agregar más facturas
            </button>
          </div>
          
        </div>
      </div>

  </div>

</div>