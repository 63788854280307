
<mat-card class="shadow-box" style="min-height: 350px;">
   <mat-card-content>
    <h1 class="titulo-simpli">Cuentas Registradas</h1>
    <small class="subtitulo-simpli">Visualiza y gestiona cada cuenta bancaria registrada</small>

    <div style="width: 100%;margin-bottom: 20px;">
    <form [formGroup]="valForm">
        <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input matInput  #input id="ctabancaria" (change)="reset_cta($event)" formControlName="filter_cta">
            <mat-hint>Dale clic a la lupa para comenzar a buscar</mat-hint>
            <mat-icon style="cursor:pointer" (click)="buscar_cta()" matSuffix>search</mat-icon>
        </mat-form-field>
    </form>

    </div>

    <div style="position: relative;" style="max-height: 400px;overflow: auto;min-height: 300px;" class="add_factoring"> 
        <button *ngIf="se_abrio_como_dialogo" type="button" (click)="create_account()" matTooltip="Agregar nueva cuenta" mat-mini-fab color="primary" style="position: absolute; right: 10px; bottom: 5px; z-index: 1;"><i
            class="material-icons"> add </i></button>
    

            <div class="sinRegistros" style="text-align: center;" *ngIf="this.has_accounts">
                <small class="small-sinregistros">
                  <div style="text-align: center;"><span style="font-size: 30px;" class="material-icons">info</span></div>
                  No tienes cuentas bancarias agregadas.
                  </small>
            </div>

    
        <mat-accordion id="parent_accounts" class="accordion_hide">
            <span #parent_accounts></span>
        </mat-accordion>
    </div>

   </mat-card-content> 
</mat-card>



    
