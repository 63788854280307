
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  <h1 class="titulo-simpli">Pago Proveedores</h1>
  <small class="subtitulo-simpli">Selecciona las facturas de proveedores que deseas pagar</small>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" >
  <mat-stepper labelPosition="bottom" #stepper linear>

    <ng-template matStepperIcon="edit">
      <mat-icon >check</mat-icon>
    </ng-template>


    <mat-step>
      <ng-template matStepLabel>Seleccion</ng-template>


      <div style="position: relative;" > 

        <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="disabled">
          <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
        </div>

        <form [formGroup]="firstFormGroup">

          <div class="row">
            <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

              <form [formGroup]="valForm">
                <div class="row">

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <mat-form-field>
                      <mat-label>Filtrar</mat-label>
                      <input matInput formControlName="filter" (change)="reset($event.target.value)">
                      <mat-icon matTooltip="Buscar" matSuffix class="btn_suffix" (click)="filtrar()">search</mat-icon>
                      <mat-hint>Introduce una palabra clave y dale click a la lupa para filtrar.</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <mat-form-field>
                      <mat-label>Desde</mat-label>
                      <input matInput [matDatepicker]="picker3" (dateChange)="filter1($event)" formControlName="desde">
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <mat-form-field>
                      <mat-label>Hasta</mat-label>
                      <input matInput [matDatepicker]="picker4" (dateChange)="filter2($event)" formControlName="hasta">
                      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>
                  </div>

                  

                </div>
              </form>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;"> 


              <button  mat-flat-button  class="primary-button" (click)="reset_filters()" type="button"  style="margin-top: 10px;">Limpiar filtros</button>

              <button  mat-flat-button [disabled]="disabled" class="primary-button" (click)="sincronizar()" type="button"  style="margin-top: 10px;">Sincronizar con SII</button>


            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="insertar_vencimiento" style="display:none">

              <div class="row">

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" >
                  <div class="info-msg">
                    <i class="fa fa-info-circle"></i>
                    &nbsp; Puede seleccionar una fecha de vencimiento para las facturas selecionadas, <strong>esto es opcional.</strong> Puede continuar con el pago sin ningún problema.
                  </div>
                </div> 

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                      <mat-form-field>
                          <mat-label>Fecha de vencimiento común</mat-label>
                          <input matInput formControlName="secondCtrl"  [min]="minDate"  [matDatepicker]="picker2" (dateChange)="fecha_todas($event)" (click)="picker2.open()" style="text-decoration: none !important;">
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                          <mat-hint>*Opcional</mat-hint>
                      </mat-form-field>
                </div>
    
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                      <mat-form-field style="line-height: unset !important;height: auto !important;">
                          <mat-label>Plazo de vencimiento</mat-label>
                          <input matInput #fecha_todas_dias id="fecha_todas_dias"  (change)="capturar_fecha_por_dias($event)">
                          <mat-hint>*Opcional</mat-hint>
                      </mat-form-field>

                </div>

              </div>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 20px;" *ngIf="infotable" >

              <table mat-table [dataSource]="dataSourceFacturas" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" style="width: 100%">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef matTooltip="Se marcarán todas facturas de la página en visualización, de 20 en 20.">

                    <mat-checkbox (change)="$event ? masterToggle($event) : null"  (checked)="checkall" formControlName="checkall" (click)="activa_final(); $event.stopPropagation()">
                    </mat-checkbox>
                    


                  </th>
                  <td mat-cell *matCellDef="let row, let index=index" style="text-align: center;">
                    <div style="border-radius: 3px;transition: all .3s ease-in-out;">
          
                      <input type="checkbox" [disabled]="row.pagado || row.aceptacionReclamo == 0 || row.estado == 'Contado/Gratuito'" [checked]="row.checked==true? row.checked:selection.isSelected(row)"
                        (click)="activa(row.id,row.nombre, $event, row); $event.stopPropagation();index">
                    </div>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
        

                <ng-container matColumnDef="folio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center !important;"> Folio </th>
                    <td mat-cell *matCellDef="let element;" style="min-width: 80px;max-width: 80px;text-align: center !important;" >
                        <input class="no-input" value="{{element.folio}}" readonly> 
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                <ng-container matColumnDef="ultimorutacreedor">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center !important;"> Rut </th>
                  <td mat-cell *matCellDef="let element" style="min-width: 130px;text-align: center !important;"> 
                      <input class="no-input"  value="{{element.ultimorutacreedor}}" readonly> 
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
            
                <ng-container matColumnDef="ultimoacreedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acreedor Legal </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left;min-width: 130px;padding-left: 10px;padding-right: 10px;"> 
                        <input *ngIf="element.historialAcreedores.length > 0" class="no-input" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger" value="{{element.ultimoacreedor}}" style="    text-align: left;
                        color: #15a83f;
                        font-weight: 400;
                        text-decoration: underline;
                        cursor: pointer;" readonly> 
                        <input *ngIf="element.historialAcreedores.length <= 0" class="no-input" value="{{element.ultimoacreedor}}" style="text-align: left;" readonly> 


                        <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                          <mat-card style="max-width: 500px;padding:10px !important;background: #fafaff;" class="shadow-box">
                            <mat-card-content style="text-align: center;">

                              <h1 style="font-size: 15px;
                              color: #8581bb;
                              text-align: left;
                              margin-bottom: 10px;
                              margin-top: 10px;font-weight: 400;">Historial de Acreedores</h1>
                    
                              <table cellspacing="0" >
                                <tr>
                                  <td style="width: 300px;text-align: left;font-weight: bold;border-bottom: solid 1px #dedede;">Acreedor</td>
                                  <td style="width: 300px;font-weight: bold;border-bottom: solid 1px #dedede;">Fecha</td>
                                </tr>
                                <tr *ngFor="let i of element.historialAcreedores" >
                                  <td style="width: 300px;text-align: left;">
                                    {{i.acreedor}}
                                  </td>
                    
                                  <td style="width: 300px;">
                                    {{i.fecha}}
                                  </td>
                  
                                </tr>
                              </table>
                            </mat-card-content>
                          </mat-card>
                        </mde-popover>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>



                </ng-container>


                <ng-container matColumnDef="receptoracreedor__business_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Emisor Documento </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left;min-width: 130px;padding-left: 10px;padding-right: 10px;"> 
                      <input class="no-input"  value="{{element.receptoracreedor_name}}" style="text-align: left;" readonly> 
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
        
                <ng-container matColumnDef="mnt_total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center !important;"> Monto </th>
                      <td mat-cell *matCellDef="let element" style="min-width: 130px;text-align: center !important;"> 
                        <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">$ {{formatMoney(total_tabla)}} </td>
                </ng-container>
        
                <ng-container matColumnDef="fecha_emision">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center !important;"> Fecha Emisión </th>
                    <td mat-cell *matCellDef="let element;" style="min-width: 130px;text-align: center;">
                    <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>


                <ng-container matColumnDef="fecha_vencimiento">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header>Fecha vencimiento</th>
                  <td mat-cell *matCellDef="let element; let i=index, let index=index" style="text-align: center;cursor: pointer;min-width: 130px;">

                      <div style="position: relative;">

                          <input [ngClass]="{'inputtable':true,
                                              'vencida':element.color === 'rojo',
                                              'porvencer':element.color === 'blanco'}"  class="no-input" placeholder="dd/mm/yyyy" (click)="i.open()" value="{{element.fecha_vencimiento}}" readonly/>
                      </div>




                      <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                          <mat-label>Fecha de vencimiento</mat-label>
                          <input  [min]="minDate" matInput [matDatepicker]="i" (dateChange)="fecha_individual($event, index)" (click)="i.open()" formControlName="secondCtrl">
                          <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                          <mat-datepicker #i></mat-datepicker>
                      </mat-form-field>
              
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> {{total_seleccionado}} </td>
                </ng-container>



                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef > Estado </th>
                    <td mat-cell *matCellDef="let element;" style="min-width: 130px;" >
                      <input  class="no-input" value="{{element.estado}}" style="font-weight: bold;color:#433da9;" readonly> 
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                
                <ng-container matColumnDef="pagado">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 70px;text-align: center;" > Pagado </th>
                  <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 70px;text-align: center;">
                  <i matTooltip="Pagado {{element.fechapagado  | date:'dd/MM/yy'}} {{element.mediopago ? element.mediopago : ''}}" *ngIf="element.pagado" style="color:#3BA46F;cursor: pointer;" class="fa-light fa-dollar-sign"></i>
                  <i matTooltip="Marcar como Pagado" *ngIf="!element.pagado" style="color:gray;cursor: pointer;" class="fa-light fa-dollar-sign" [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger"></i>
                  <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                      <mat-card>
                      <mat-card-content>
                          <small>{{element.pagado ? '¿Marcar como no pagado?' : '¿Marcar como pagado?'}}</small><br>


                          <mat-form-field *ngIf="true">
                            <mat-label>Medio de pago</mat-label>
                            <mat-select multiple [formControl]="paymentMethod">
                              <mat-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod">{{paymentMethod}}</mat-option>
                            </mat-select>
                          </mat-form-field>

                        <button mat-button class="pagar-btn" type="button" (click)="pagar(element.pagado, element.id, this.paymentMethod.value)">Aceptar</button>

                      </mat-card-content>
                      </mat-card>
                  </mde-popover>
                  
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>

              <!-- 0 es malo 1 es bueno 2 es no consultada -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;text-align: center;" > Aceptación </th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;text-align: center;">


                <i style="color:#3BA46F;" [mdePopoverTriggerFor]="reclamoPopover" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger" *ngIf="element.aceptacionReclamo == 1" class="fa-light fa-check-circle"></i>
                <i style="color: gray;" [mdePopoverTriggerFor]="reclamoPopover" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger" *ngIf="element.aceptacionReclamo == 0" class="fa-light fa-info-circle"></i>
                <i style="color:gray" [mdePopoverTriggerFor]="reclamoPopover" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger" *ngIf="element.aceptacionReclamo != 0 && element.aceptacionReclamo != 1" class="fa-light fa-info-circle"></i>
                <i style="color: gray;cursor: pointer;margin-left: 10px;" matTooltip="Comprueba si está factura ha cambiado su estado de aceptación o reclamo" class="fa-light fa-sync-alt"  (click)="aceptacionReclamo(element.folio)" ></i>


                <mde-popover #reclamoPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                    <mat-card class="shadow-box card-reclamos">
                    <mat-card-header>
                        <h1 class="card-reclamos-title">{{ element.aceptacionReclamo == 1 ? 'Aceptada': element.aceptacionReclamo == 0 ? 'Rechadaza' : 'Sin información'  }}</h1>                             
                    </mat-card-header>
                    <mat-card-content class="text-center">
                        <div *ngIf="element.reclamos.length > 0">                       
                        <table class="w-100" cellspacing="0" >
                        <tr>
                            <td class="card-reclamos-table-header">{{ element.aceptacionReclamo == 1 ? 'Eventos': element.aceptacionReclamo == 0 ? 'Reclamos' : 'Detalles'  }}</td>
                        </tr>
                        <tr *ngFor="let i of element.reclamos" >
                            <td class="card-reclamos-table-td">
                            {{i}}
                            </td>                          
                        </tr>
                        </table>
                        </div>                                        
                    </mat-card-content>
                    </mat-card>
                </mde-popover>

                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturas; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsFacturas"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumnsFacturas"></tr>

              </table>


              <div class="row">

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-msg">
                  <small *ngIf="show_msg_acreedor_legal" style="color: #15a83f; margin-top: 20px;display: block;">*Pasa el cursor sobre el Acreedor Legal para ver el Historial de Acreedores recientes.</small>
                  <!-- <small style="color: #ff933b;display: block;">*Factura por vencer.</small>
                  <small style="color: #FA454C;display: block;">*Factura vencida.</small> -->
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                  (page)="pageCustom($event)">
                  </mat-paginator>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 30px;margin-bottom: 30px;">
        
                </div>

                <div class="container__buttons">
                  <div class="container__buttons">
                    <button  mat-flat-button mat-dialog-close class="primary-button" type="button"  style="margin-top: 10px;">Cerrar</button>
                  </div>
                  <div class="container__buttons--end">
                    <button  mat-flat-button [disabled]="disabled_enviar" class="primary-button" (click)="seleccionar()" type="button"  style="margin-top: 10px;">Continuar</button>
                  </div>
                  <button matStepperNext  class="primary-button" id="pasitodos" type="button" style="display: none;" ></button>
                </div>

              </div>


            </div>

            <div class="sinRegistros" *ngIf="!infotable">
              <small>No se consiguieron registros.</small>
            </div>
      


          </div> 
        
        </form>


      </div>





    </mat-step>


    <mat-step>
      <ng-template matStepLabel>Cuentas</ng-template>
      <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="warning-msg animate__animated animate__fadeIn" style="margin-top:20px;background: white !important;"
            *ngIf="!esta_todo_listo">
            <i class="fa-light fa-circle-exclamation" style="color:#ff933b;"></i>
            &nbsp; Agrega información de la cuenta de destino.
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <h3>Total: $ {{formatMoney(total)}}</h3>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSourceFinalizarStep">

              <ng-container matColumnDef="cta">
                <th mat-header-cell *matHeaderCellDef>Cta. Bancaria</th>
                <td mat-cell *matCellDef="let element" style="text-align: center;max-width: 110px;">
                  <span matTooltip="Agrega la cuenta bancaria de tu proveedor"
                    (click)="create_account(element.company_id,element.receptoracreedor_rut, element.receptoracreedor_name)">
                    <i class="fa-light fa-circle-exclamation" *ngIf="!element.validBanco" style="color:#ff933b"></i>
                  </span>
                  <span matTooltip="Agrega un cuenta bancaria"
                    (click)="create_account(element.company_id,element.receptoracreedor_rut, element.receptoracreedor_name)">
                    <i matTooltip="Agregar nueva Cuenta Bancaria"  *ngIf="element.validBanco" class="fa-light fa-circle-plus"
                      style="color:#3BA46F;"></i>
                  </span>
                </td>
              </ng-container>



              <ng-container matColumnDef="receptoracreedor_name">
                <th mat-header-cell *matHeaderCellDef> Acreedor Legal </th>
                <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}"
                  style="min-width: 130px; text-align: center;"> 
                  <input class="no-input" value="{{element.receptoracreedor_name}}" style="text-align: left;"
                    readonly>
                </td>
              </ng-container>

              <ng-container matColumnDef="receptoracreedor_rut">
                <th mat-header-cell *matHeaderCellDef> Rut </th>
                <td mat-cell *matCellDef="let element">
              <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
              <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
              <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
              <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
              <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
                  <input class="no-input" style="text-align: center;" value="{{element.receptoracreedor_rut}}"
                    readonly>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> Total</td>
              </ng-container>

              <ng-container matColumnDef="total_facturas">
                <th mat-header-cell *matHeaderCellDef> Monto </th>
                <td mat-cell *matCellDef="let element">
                  <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
                  <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
                  <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
                  <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
                  <td mat-cell *matCellDef="let element"> 
                <td mat-cell *matCellDef="let element">
                  <input class="no-input" style="text-align: center;"
                    value="$ {{formatMoney(element.total_facturas)}}" readonly>
                </td>
              </ng-container>


              <ng-container matColumnDef="banco">
                <th mat-header-cell *matHeaderCellDef style="min-width: 300px;"> Seleccionar Cta. </th>
                <td mat-cell *matCellDef="let element;let index=index" style="text-align: center;min-width: 300px;">
                  <mat-form-field *ngIf="element.bancos.length > 0">
                    <mat-select (selectionChange)="seleccionar_banco($event.value, element.receptoracreedor_rut)" [(value)]="element.id_bank">
                      <mat-option *ngFor="let i of element.bancos" [value]="i.id">{{i.banco + ' | ' + i.tipo + ' ' +
                        i.numero}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </td>

              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizarStep; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsFinalizarStep"
                [ngClass]="{'trbackgroud':element.validBanco == false}"></tr>


            </table>
            <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]"></mat-paginator>
          </div>
        </div>

       

        <div class="container__buttons">
          <div class="container__buttons">
            <button mat-flat-button mat-dialog-close class="primary-button" type="button">Cerrar</button>
          </div>
          <div class="container__buttons--end">
            <button  mat-flat-button matStepperPrevious  class="primary-button"  type="button" style="margin-top: 10px;">Anterior</button>

            <button mat-flat-button [disabled]="!esta_todo_listo" class="primary-button" type="button"
              style="margin-top: 10px;" (click)="download_csv()">Finalizar</button>
          </div>
          <button  mat-flat-button matStepperNext id="elfulanito" class="primary-button" style="display: none;"  type="button" >Libro de Ventas</button>
        </div>
      </div>


    </mat-step>


    

    <mat-step *ngIf="debe_agregar_ventas == true" class="animate__animated animate__fadeIn">
      <ng-template matStepLabel>Extra: Agregar Ventas</ng-template>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn slow">
                <div style="width: 70%;text-align: center;margin:0 auto;margin-top: 20px;">
        La deuda es mayor al monto de la simulación por lo cual debes agregar facturas de venta para poder pagar tu Deuda total de: <strong style="font-size: 19px;">$ {{formatMoney(deuda_total)}}</strong>

        <div style="text-align: center;margin-bottom: 30px;margin-top: 30px;">
          ¿Desea agregar facturas desde el libro de ventas o ingresarlas de manera manual?

          <div style="margin-top: 30px;margin-bottom: 30px;">
              <button  mat-flat-button (click)="open_libro_ventas()"  class="primary-button"  type="button" style="background:#433da9;color:white;">Libro de Ventas</button>
              <!-- <button  mat-flat-button (click)="open_carga_manual()"  class="primary-button"  type="button" style="background:#433da9;color:white;">Ingreso Manual</button> -->
          </div>

        </div>

                </div>
      </div>



      <div style="margin-bottom: 30px;margin-top: 30px;display: inline-flex;">        
        <button  mat-flat-button matStepperPrevious  class="primary-button"  type="button" style="margin-top: 10px;">Anterior</button>
        <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" style="margin-top: 10px;">Cerrar</button>
      </div>


    </mat-step>

  </mat-stepper>
</div>