import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';
import { map, startWith } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PoderSimpleComponent } from './poder-simple/poder-simple.component';

export interface Banco {
  id: string;
  name: string;
}

export interface Cuenta {
  id: string;
  name: string;
}

@Component({
  selector: 'app-agregar-cta',
  templateUrl: './agregar-cta.component.html',
  styleUrl: './agregar-cta.component.less',
})
export class AgregarCtaComponent implements OnInit {

  valForm: FormGroup;

  run: any;
  bancos: Banco[] = [];
  cuentas: Cuenta[] = [];

  is_tercero: boolean = false;
  email: any;
  file: any;

  //autocomplete select
  myControl = new FormControl();
  filteredOptions: Observable<Banco[]> | undefined;
  bank_selected: any;

  id_company = localStorage.getItem('company_id');
  companyRut: string | null = null;
  formAppearance: 'fill' | 'outline' = 'fill';
  isLoading: boolean = false;
  disabledButton: boolean = false;
  accountData: any;
  hasAccounts: any;
  primaryAccount: any;


  constructor(
    fb: FormBuilder,
    public snackBar: MatSnackBar,
    public settings: SettingsService,
    public dialog: MatDialog,
  ) {
    let rutValid = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{7,10}[-][0-9kK]$'),]));
    let accNumber = new FormControl(null, Validators.compose([ Validators.required, Validators.pattern('^[0-9]{4,12}$'), ]) );

    this.valForm = fb.group({
      bank: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      rut: rutValid,
      number: accNumber,
      name: ['', Validators.compose([Validators.required])],
      tercero: new FormControl(''),
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),]),],
      file: [''],
      placeholderFile: new FormControl(''),
    });

    this.valForm.controls['tercero'].setValue(false);

  }

  ngOnInit() {
    this.getInfoCompany();
    this.load_bancos();
    this.load_cuentas();
    this.load_accounts();
  }

  async getInfoCompany() {
    try {
      const bd: any = await this.settings.get_query(1, '/company/' + this.id_company + '/');
      var body_data: any = bd._body;
      this.companyRut = body_data.company.rut;
    } catch (bd: any) {
    }
  }

  async load_accounts() {
    this.isLoading = true;
    try {
      const body_data: any = await this.settings.get_query(1, `/cuentasAcreedoresBank/${this.id_company}/`)
      const _body = body_data._body

      this.accountData = _body.accounts && _body.accounts.length > 0 ? _body.accounts[0] : null;
      this.hasAccounts = _body.accounts && _body.accounts.length > 0;
      console.log('Account Data:', this.accountData);

      const primaryAccount = _body.accounts?.find((account: { is_primary: any; }) => account.is_primary);

      if (this.accountData) {
        this.valForm.patchValue({
          id: this.accountData.id,
          bank: this.accountData.bank?.name,
          type: this.accountData.type?.id,
          rut: this.accountData.rut,
          number: this.accountData.number,
          name: this.accountData.name,
          email: this.accountData.email || '',
          tercero: this.accountData.is_tercero,
        });
        
        this.bank_selected = this.accountData.bank_id;
        
        this.run = this.accountData.rut;
      }
    
    } catch (bd:any) {
      this.accountData = null; 
      this.hasAccounts = false;
    } finally {
      this.isLoading = false;
    }
  }

  async submitForm() {
    this.isLoading = true;
    this.disabledButton = true;

    try {

      let method: number;
      let formData: any;

      if (this.hasAccounts) {
        method = 3;
        formData = {
            rut: this.valForm.value.rut,
            name: this.valForm.value.name,
            number: this.valForm.value.number,
            email: this.valForm.value.email,
            is_tercero: this.valForm.value.tercero,
            type_id: this.valForm.value.type,
            bank_id: this.bank_selected,
            account_id: this.accountData?.id 
        };
    } else {
        method = 2;
        formData = {
            rut: this.valForm.value.rut,
            name: this.valForm.value.name,
            number: this.valForm.value.number,
            email: this.valForm.value.email,
            is_tercero: this.valForm.value.tercero,
            type: this.valForm.value.type,
            bank: this.bank_selected
        };
      }

      const bd: any = await this.settings.get_query(method, `/cuentasAcreedoresBank/${this.id_company}/?proceso=onboarding`, formData);
      var body_data: any = bd._body;

      console.log('Form Data actualizacion :', formData);
      
      switch (bd.status) {
        case 201:
        case 200: {

          const successMessage = this.hasAccounts ? 'La cuenta fue actualizada' : 'La cuenta fue registrada';
          this.snackBar.open(successMessage, 'X', {
            duration: 20000,
            panelClass: 'simpli-snack-onboarding',
          });

          this.is_tercero = false;
          this.valForm.get('placeholderFile')?.clearValidators();
          this.valForm.get('placeholderFile')?.updateValueAndValidity();

          this.ngOnInit();
          this.dialog.closeAll(); 

          break;
        }
        default: {
          break;
        }
      }
    } catch (bd: any) {
      console.error('Error al registrar la cuenta', bd);

      const errorMessage = this.hasAccounts ? 
      'Error al actualizar la cuenta, intente nuevamente' : 
      'Error al registrar la cuenta, intente nuevamente';
    
      this.snackBar.open(errorMessage, 'X', {
        duration: 20000,
        panelClass: 'simpli-snack-onboarding',
      });
    } finally {
      this.isLoading = false;
      this.disabledButton = false;
    }
  }

  validate_error($event: any) {
    if (this.bank_selected == undefined) {
      this.valForm.controls['bank'].setValue('');
      this.valForm.controls['bank'].setErrors({ required: true });
    }
  }

  filtrar_banco($e: any) {
    this.bank_selected = $e.id;
    let cuenta = { id: '2', name: 'Cuenta RUT' };
    if (this.bank_selected != '26') {
      if (this.cuentas.indexOf(cuenta) != -1) {
        this.cuentas.splice(this.cuentas.indexOf(cuenta), 1);
      }
    } else {
      this.cuentas.push(cuenta);
    }
  }

  transform_number() {
    let regex = /[^0-9]+/;
    let phone = String(this.valForm.controls['number'].value).replace(
      regex,
      ''
    );
    if (phone != '') {
      return this.valForm.controls['number'].setValue(phone);
    } else {
      this.valForm.controls['number'].reset();
    }
  }

  async load_bancos() {
    try {
      const bd: any = await this.settings.get_query(1, '/bank/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          let body_data = bd._body;

          for (let i = 0; i < body_data.banks.length; i++) {
            this.bancos.push(body_data.banks[i]);
          }

          this.filteredOptions = this.valForm.controls[
            'bank'
          ].valueChanges.pipe(
            startWith(''),
            map((value: { name: any }) =>
              typeof value === 'string' ? value : value.name
            ),
            map((name: string) =>
              name ? this._filter(name) : this.bancos.slice()
            )
          );
          break;

        default:
          break;
      }
    } catch (bd: any) {}
  }

  private _filter(name: string): Banco[] {
    const filterValue = name.toLowerCase();

    return this.bancos.filter(
      (option) => option.name.toLowerCase().includes(filterValue) === true
    );
  }

  async load_cuentas() {
    try {
      const bd: any = await this.settings.get_query(1, '/account-type/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          var body_data: any = bd._body;

          for (let i = 0; i < body_data.accounts.length; i++) {
            if (i != 1) {
              this.cuentas.push(body_data.accounts[i]);
            }
          }
          break;

        default:
          break;
      }
    } catch (bd: any) {}
  }

  change_run() {
    let st = this.run.replace('-', '');
    let arr = st.split('');
    let st_out = '';

    for (let i = 0; i < arr.length; i++) {
      st_out = st_out + arr[i];
      if (i == arr.length - 2) {
        st_out = st_out + '-';
      }
    }

    if (
      this.companyRut?.toUpperCase() != st_out.toUpperCase() &&
      st_out.length > 8
    ) {
      this.valForm.get('tercero')?.setValue(true);
      this.is_tercero = true;
    } else {
      this.valForm.get('tercero')?.setValue(false);
      this.is_tercero = false;
    }


    this.run = st_out;
  }

  toggleTerceroValidation() {
    if (this.is_tercero == true) {
      this.valForm.get('placeholderFile')?.setValidators([Validators.required]);
    } else {
      this.valForm.get('placeholderFile')?.clearValidators();
    }
    this.valForm.get('placeholderFile')?.updateValueAndValidity();
  }

  change_toggle($event: any) {
    console.log($event.checked);
    if ($event.checked == true) {
      this.is_tercero = true;
      this.toggleTerceroValidation();
    } else {
      this.is_tercero = false;
      this.toggleTerceroValidation();
    }
  }

  poderSimple() {
    const dialogRef = this.dialog.open(PoderSimpleComponent, {
      width: '500px',
      maxWidth: '95vw',
      autoFocus: false,
      height: 'auto',
      maxHeight: '95vh',
      data: {
        formValues: this.valForm.value,
      },
    });
  }
}
