<div mat-dialog-title class="chat-header-container" style="position: relative;">

  <div class="close-dialog" mat-dialog-close>
    <i class="fa-regular fa-xmark"></i>
  </div>

  <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

        <div class="avatar-chat animate__animated animate__fadeIn">
          <span class="first">{{room_selected?.profile_user?.first_name.charAt(0)}}</span><span class="last">{{room_selected?.profile_user?.last_name.charAt(0)}}</span>
        </div>

      </div>

      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">

        <div>
            <div class="label-ejecutivo animate__animated animate__fadeIn">
              {{ room_selected?.profile_user?.full_name }}
            </div>
            <div class="label-online animate__animated animate__fadeIn" *ngIf="room_selected?.profile_user?.active && !loadStatus">
              <span class="online-icon"><i class="fa-solid fa-circle"></i></span>Online
            </div>
            <div class="label-last-seen animate__animated animate__fadeIn" *ngIf="room_selected?.profile_user?.last_seen !== null && !room_selected?.profile_user?.active && !loadStatus">
              Ult. vez visto : {{ room_selected?.profile_user?.last_seen_format }}
            </div>

        </div>

      </div>

  </div>
</div>


<mat-dialog-content class="chat-dialog-container" #supercontenedor id="chat-container">

  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="animate__animated animate__fadeIn" *ngIf="room_selected && !spinnerMessages">

        <div class="row" >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
            <div class="flex-items flex-start-items" *ngFor="let message of messagesChat" [class.align_right]="message.isCurrent" #messageElement>
              <div class="max-width-80 min-width-20">
                <div>
                  <div class="chat-text-container" [class.current_user_bg]="message.isCurrent">
                    {{message.text}}
                  </div>
                  <div class="time-text">
                    <small style="color: gray">{{message.date_created}} </small>
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
  
        </div>
  
      </div>
  
      <div class="row chat-bg-no-info animate__animated animate__fadeIn" *ngIf="!room_selected || spinnerMessages || spinnerContact">
        <div class=" chat-container add_factoring">
          <app-sin-registros [spin]="spinnerMessages" [text]="emptyMessage"></app-sin-registros>
        </div>
      </div>
  
    </div>

  </div>




</mat-dialog-content>

<mat-dialog-actions class="chat-actions" *ngIf="room_selected && !spinnerMessages">

  <form [formGroup]="valForm" (ngSubmit)="send()" style="width: 95%;margin: 0 auto;">
    <mat-form-field appearance="fill" color="primary">
      <textarea matInput formControlName="message" (input)="onTextareaInput($event)" style="resize: none; max-height: 55px;" rows="1"></textarea>
      <button mat-icon-button matSuffix style="top: -5px; color: #924eff;"  type="submit" >
        <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
      </button>
    </mat-form-field>
  </form>

</mat-dialog-actions>
