
<div class="row row_main">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                <h1 class="titulo-simpli" style="position: relative;">Configuración</h1>
                <small class="subtitulo-simpli">Actualiza tu información, sube documentación y gestiona tus credenciales</small>
            </div>



        </div>
    </div>


    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" stretchTabs="false" mat-align-tabs="start" dynamicHeight="true" fitInkBarToContent="false" preserveContent="false">

            <mat-tab label="Mis datos">

                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                        <form [formGroup]="valForm">


                            <div class="row">
        
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Correo Electrónico</mat-label>
                                        <input formControlName="email" matInput type="email" readonly>
                                        <mat-hint>El Correo Electrónico no se puede modificar</mat-hint>
                                    </mat-form-field>
                                </div>
        
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>RUT</mat-label>
                                        <input matInput formControlName="run" readonly>
                                        <mat-hint>El RUT no se puede modificar</mat-hint>
        
                                        <mat-error
                                            *ngIf="valForm.get('run').hasError('pattern') && (valForm.get('run').dirty || valForm.get('run').touched)">
                                            El RUT debe ser en formato DDDDDDD-D Ejm: 17983599-8
                                        </mat-error>
        
                                        <mat-error
                                            *ngIf="valForm.controls['run'].hasError('incorrect') && (valForm.get('run').dirty || valForm.get('run').touched)">
                                            Este RUT ya está en uso
                                        </mat-error>
                                    </mat-form-field>
                                </div>
        
                            </div>
        
        
                            <div class="row">
        
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Nombre</mat-label>
                                        <input  formControlName="first_name" matInput>
                                        <mat-hint>Introduce tu Nombre</mat-hint>
                                        <mat-error
                                            *ngIf="valForm.controls['first_name'].hasError('required') && (valForm.controls['first_name'].dirty || valForm.controls['first_name'].touched)">
                                            Nombre es <strong>requerido</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
        
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Apellido</mat-label>
                                        <input formControlName="last_name" matInput>
                                        <mat-hint>Introduce tu Apellido</mat-hint>
                                        <mat-error
                                            *ngIf="valForm.controls['last_name'].hasError('required') && (valForm.controls['last_name'].dirty || valForm.controls['last_name'].touched)">
                                            Apellido es <strong>requerido</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
        
                            </div>
        
                            <div class="success-msg animate__animated animate__fadeIn" style="margin-top: 20px;" *ngIf="showsuccess1">
                                <i class="fa fa-check"></i>
                                &nbsp; Datos Actualizados.
                            </div>
        
        
                            <div style="width: 100%;margin-top: 40px;margin-bottom: 10px;">
                                <button [disabled]="disable_btn" mat-stroked-button color="primary" type="button" (click)="submitForm($event, valForm.value)" class="primary-button">Actualizar</button>
                            </div>
                        </form>
                    </div>
                </div>


            </mat-tab>

            <mat-tab label="Datos de mi empresa">

                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                        <form [formGroup]="valFormCompany">

                            <div class="row">

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Nombre Empresa</mat-label>
                                        <input formControlName="business_name" matInput type="text" readonly>
                                        <mat-hint>El Nombre de la Empresa no se puede modificar</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>RUT Empresa</mat-label>
                                        <input matInput formControlName="run2" readonly>
                                        <mat-hint>El RUT no se puede modificar</mat-hint>

                                        <mat-error
                                            *ngIf="valFormCompany.get('run2').hasError('pattern') && (valFormCompany.get('run2').dirty || valFormCompany.get('run2').touched)">
                                            El RUT debe ser en formato DDDDDDD-D Ejm: 17983599-8
                                        </mat-error>

                                        <mat-error
                                            *ngIf="valFormCompany.controls['run2'].hasError('incorrect') && (valFormCompany.get('run2').dirty || valFormCompany.get('run2').touched)">
                                            Este RUT ya está en uso
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Giro Empresa</mat-label>
                                        <input formControlName="giro" matInput type="text" readonly>
                                        <mat-hint>El Giro de la Empresa se actualiza automaticamente.</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Sitio WEB</mat-label>
                                        <input formControlName="web" matInput type="text" (change)="update_web($event)" placeholder="Web">
                                        <mat-hint>Modificar Sitio WEB</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-12S col-xs-12 col-top">


                                    <mat-form-field appearance="fill">
                                        <mat-label>Correo Electrónico</mat-label>
                                        <input formControlName="email" matInput type="text" (change)="update_mail($event)">
                                        <mat-hint>Modificar Correo Electrónico</mat-hint>

                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="success-msg animate__animated animate__fadeIn" style="margin-top: 20px;" *ngIf="showsuccess">
                                <i class="fa fa-check"></i>
                                &nbsp; Datos Actualizados.
                            </div>


                            <div style="width: 100%;margin-top: 40px;margin-bottom: 10px;">
                                <button [disabled]="disable_btn" mat-stroked-button color="primary" type="button" (click)="recargar_sii()" class="primary-button">Actualizar</button>
                            </div>

                        </form>
                    </div>
                </div>

            </mat-tab>

            <mat-tab label="Sincronización de credenciales">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top" style="margin-top:20px">
                        <app-credenciales-sii [id_company]="id_company" [se_abrio_como_dialogo]="false"></app-credenciales-sii>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Cambiar contraseña">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top"  style="margin-top:20px">

                        <app-password [se_abrio_como_dialogo]="false"></app-password>

                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Mis cuentas bancarias">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                        <app-cuentas-bancarias-component></app-cuentas-bancarias-component>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Mis documentos">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                        <new-documentos [id_company]="id_company"></new-documentos>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>





</div>
