import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';



@Component({
    selector: 'confirm-dialog',
    template: `
        <div mat-dialog-title>
            <h1 class="titulo-simpli">Cerrar Sesión</h1>
        </div>
        <div mat-dialog-content>
            <p>¿Estas seguro que deseas cerrar sesión?</p>
        </div>
        <div mat-dialog-actions>
          <button mat-button class="label-button" (click)="dialogRef.close('Y')">Si</button>
          <button mat-button class="label-button" (click)="dialogRef.close('N')">No</button>
        </div>
    `,
})
export class ConfirmDialog  {
    constructor(public dialogRef: MatDialogRef<ConfirmDialog>) { }
}
