<div mat-dialog-title>
<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn close">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
  </div>
</div>
</div>

<mat-dialog-content>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h1 class="titulo-simpli position-absolute">Conversa con tu ejecutivo</h1>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 animate__animated animate__fadeIn online">
      <small class="subtitulo-simpli"><small *ngIf="online"><i style="color:var(--simpli-color7)" class="fa-solid fa-circle"></i></small> {{ executive }} {{ online ? 'está en línea' : '' }} </small>
      <small *ngIf="!online && last_login" class="subtitulo-simpli">&nbsp; Ult. vez visto : {{ last_login }}</small>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <form  [formGroup]="valFormFilter">
        <mat-form-field appearance="fill">
          <mat-label>Buscar</mat-label>
          <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="apply_filter($event.target.value)">
          <mat-icon (click)="apply_filter_2()" matSuffix class="icon-suffix">search</mat-icon>
          <mat-hint>Buscar un mensaje en el chat</mat-hint>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="row chat-bg">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top chat-container add_factoring" id="chat-container">
      <div class="row animate__animated animate__fadeIn" *ngFor="let o of received" [ngStyle]="{'justify-content': id_user == o.sent_by ? 'flex-end' : ''}">
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-top">
          <div class="row" [ngStyle]="{'flex-direction': id_user == o.sent_by ? 'row-reverse' : 'row'}">
            <div *ngIf="o.avatar; else noimg" class="col-lg-1 col-md-1 col-sm-1 col-xs-1 align-center">
              <img class="chat-img" src="{{BASE_URL}}{{o.avatar}}" value="{{o.avatar}}"/>
            </div>
            <ng-template #noimg>
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 align-center">
                <div class="chat-img"><span [matTooltip]="o.user_name">{{o.user_name.charAt(0)}}</span></div>
              </div>
            </ng-template>
            
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
              <div class="chat-text-container">
                {{o.message}}
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <small style="color: gray">{{o.date}} </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
      <form [formGroup]="valForm" (ngSubmit)="send()">
        <mat-form-field appearance="fill" color="primary">
          <input matInput type="text" placeholder="" formControlName="message">
          <button *ngIf="this.valForm.controls.message.value != ''" type="submit" matSuffix class="text-center p-10 button-color-plain"><mat-icon class="color-primary">send</mat-icon></button>
        </mat-form-field>
      </form>
    </div>
  </div>  
</mat-dialog-content>