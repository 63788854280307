<div mat-dialog-title>
    ¡Todo listo!
</div>

<div mat-dialog-content>
    <p>{{ msg }}</p>
</div>

<div mat-dialog-actions>
    <button mat-dialog-close type="button" routerLink="/login" class="primary-button" mat-stroked-button>Ingresar a mi cuenta</button>
</div>