<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <span *ngIf="show_icon_delete"><i style="font-size: 65px;color:var(--simpli-color7)" class="fa-light fa-circle-check"></i></span>
            <span *ngIf="!show_icon_delete"><i style="font-size: 65px;color:var(--simpli-color6)" class="fa-light fa-triangle-exclamation"></i></span>
        </div>

        <div class="label-dialog-strong">{{title}}</div>
        <div class="label-dialog">{{subtitle}}</div>


        <div class="row" *ngIf="!show_icon_delete">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container">
              <button  class="primary-button" mat-flat-button (click)="ver_estado()">
                Cancelar
              </button>

              <button  class="secondary-button" mat-flat-button (click)="eliminar_aec()">
                Eliminar AEC
              </button>
            </div>
            
          </div>
        </div>

        <div class="row" *ngIf="show_icon_delete">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container">
              <button  class="primary-button" mat-flat-button (click)="nuevos_aec()">
                Cargar nuevos AEC
              </button>

              <button  class="secondary-button" mat-flat-button (click)="por_otro_metodo()">
                Ceder por otro método
              </button>
            </div>
            
          </div>
        </div>

    </div>


    
</mat-dialog-content>