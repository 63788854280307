
<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn border-title" style="position: relative;">
  
    <div class="row">

      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <span class="title-dialog-lateral">Operación N°{{id_operacion}}</span>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="text-align: right;">
        <span mat-dialog-close style="cursor: pointer;"> 
          <i class="fa-solid fa-xmark" style="color:#1B0943;font-size: 21px;"></i>
        </span>
      </div>

    </div>

  </div>
</div>

<div mat-dialog-content style="max-height: 90vh !important;" class="animate__animated animate__fadeIn">

  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="detail-title">Detalle de la operación</div>
    </div>


    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Información general
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Cliente:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Cantidad deudores:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{cliente}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{cant_deudores}}</div>
              </div>
            </div>
  
            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Fecha operación:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Facturas pagadas:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                    {{fecha_operacion}}
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{cant_facturas_pagadas}}</div>
              </div>
            </div>
          </mat-card-content>
  
        </mat-card>

      </div>

      <div>

        <mat-card class="card-dialog-lateral">
          <mat-card-title class="title-card-dialog">
            Montos asociados
          </mat-card-title>
  
          <mat-card-content>
            <div class="row" style="margin-top: 15px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto operación:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Porcentaje anticipo:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_monto)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">{{porcentaje_anticipo}}%</div>
              </div>
            </div>
  
            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto financiado:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto no financiado:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_monto_anticipo)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(excedentes)}}</div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Diferencia precio:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Comisión:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                  ${{formatMoney(diferencia_precio)}}
                  <span [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger" class="info-icon-dialog"><i class="fa-light fa-circle-info"></i></span>
                  <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                    <mat-card class="popover-card" style="width: 300px;">
                      <mat-label>Es el monto asociado a los intereses. Se calcula considerando la tasa de interés aplicada.</mat-label>
                    </mat-card>
                  </mde-popover>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(comision)}}</div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Gastos operacionales:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">IVA:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(gastos_operacionales)}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(iva)}}</div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Descuentos:</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto líquido:</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                  ${{formatMoney(descuentos)}}
                  <span [mdePopoverTriggerFor]="appPopovertable" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger" class="info-icon-dialog"><i class="fa-light fa-circle-info"></i></span>
                  <mde-popover #appPopovertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                    <mat-card class="popover-card" style="max-width: 370px;position: relative;">

                      <div class="row">

                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                          <mat-label style="display: block;margin-bottom: 10px;">Revisa el desglose de los descuentos correspondientes a esta operación.</mat-label>

                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align: right;">
                          <span style="cursor: pointer;" (click)="popoverTriggerClose.togglePopover()"> 
                            <i class="fa-solid fa-xmark" style="color:white;font-size: 21px;"></i>
                          </span>
                        </div>

                      </div>



                      <mat-divider style="background: white;"></mat-divider>
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <div class="row-detail-popover" style="font-weight: 600;">Descuentos</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <div class="row-detail-popover" style="font-weight: 600;">Monto</div>
                        </div>
                      </div>
                      <mat-divider style="background: white;"></mat-divider>

                      <div style="margin-top: 10px;">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover">Cuentas por pagar:</div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover" style="position: relative;">
                              ${{formatMoney(cxp)}}
                              <span matTooltip="Es una deuda pendiente generada por un acuerdo de pago previo." class="icon-popover"><i class="fa-regular fa-circle-info"></i></span>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover">Documentos aplicados:</div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover" style="position: relative;">
                              ${{formatMoney(aplicaciones)}}
                              <span matTooltip="Son documentos (facturas, cheques, etc) pendientes de pago, que se descuentan de una operación." class="icon-popover"><i class="fa-regular fa-circle-info"></i></span>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover">Saldo pendiente:</div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover" style="position: relative;">
                              ${{formatMoney(saldo_pendiente)}}
                              <span matTooltip="Es un saldo generado por mora acumulada o por el pago incompleto de una factura." class="icon-popover"><i class="fa-regular fa-circle-info"></i></span>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover" style="font-weight: 600;">Total</div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row-detail-popover" style="font-weight: 600;">${{formatMoney(total)}}</div>
                          </div>
                        </div>

                      </div>

                    </mat-card>
                  </mde-popover>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">${{formatMoney(total_giro)}}</div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;" *ngIf="total_giro == 0">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-title">Monto no girado:</div>
              </div>
            </div>

            <div class="row" *ngIf="total_giro == 0">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="row-detail">
                  ${{formatMoney(total_monto_sin_giro)}}
                  <span [mdePopoverTriggerFor]="apphex" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger" class="info-icon-dialog"><i class="fa-light fa-circle-info"></i></span>
                  <mde-popover #apphex="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                    <mat-card class="popover-card" style="width: 300px;">
                      <mat-label>Es el monto que fue utilizado para regularizar otras operaciones en curso.</mat-label>
                    </mat-card>
                  </mde-popover>
                </div>
              </div>
            </div>
          </mat-card-content>
  
        </mat-card>

      </div>

      <div style="margin-top: 20px;cursor: pointer;" [ngClass]="{'disable-action':loading}" (click)="get_detail(true)">
        <span style="margin-right: 15px;" ><i style="color:#1B0943;" class="fa-regular fa-download"></i></span><span class="descargar-label">Descargar detalle</span>
      </div>

      <div style="margin-top: 40px;text-align: center;margin-bottom: 40px;">

        <button mat-flat-button color="primary" class="primary-button" (click)="go_to_detail()">Ver facturas</button>

      </div>

    </div>

  </div>

  
</div>