
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IMessage, WebSocketService } from 'src/app/services/websocket.service';
import { SettingsService } from 'src/app/settings/settings.service';;
import { QueEsunAEC } from '../cesion-facturador/que_es_un_aec/que_es_un_aec';
import { AlertErrorCesion } from '../cesion-facturador/alert-error-cesion/alert-error-cesion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

declare var $: any;


@Component({
  selector: 'app-credenciales-cesion',
  templateUrl: './credenciales-cesion.html',
  styleUrls: ['./credenciales-cesion.less']
})
export class CredencialesCesion implements OnInit {


  @Input() id_company:any=localStorage.getItem('company_id');
  @Input() token:any=localStorage.getItem('token');
  @Input() id_sim:any;
  @Input() id_operation:any;
  @Input() onCederByAEC: boolean = false;

  valForm: FormGroup;
  valFormCertificado: FormGroup;
  valFormClave: FormGroup;
  valFormClaveCert: FormGroup;
  step:any=1;
  solicitar_solo_clave:boolean=false;

  constructor(private snackBar:MatSnackBar,private ws: WebSocketService,public settings: SettingsService,public dialog: MatDialog, public dialogRef:MatDialogRef<CredencialesCesion>, public router:Router,private _formBuilder: FormBuilder) {

    this.id_company = localStorage.getItem('company_id');



    this.valForm = this._formBuilder.group({
      'sii_pass': ['',  Validators.compose([Validators.required])]
    });

    this.valFormCertificado = this._formBuilder.group({
      'file': ['',  Validators.compose([Validators.required])],
      'rut_autorizado': ['', Validators.compose([Validators.required])],
      'cert_pass': ['', Validators.compose([Validators.required])],
      'sii_pass': null,
      'need_saved': false
    });

    this.valFormClave = this._formBuilder.group({
      'sii_pass': ['',  Validators.compose([Validators.required])],
      'need_saved': false
    });

    this.valFormClaveCert = this._formBuilder.group({
      'cert_pass': ['',  Validators.compose([Validators.required])],
      'need_saved': false
    });

   }


  is_responsive:boolean=false;
  suscriptionEvent:any;
  socketOnline: boolean = false;
  ngOnInit() {
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.socketOnline = this.ws.isConnected$.value
    this.ws.isConnected().subscribe((online:any) => {
      this.socketOnline = online
    })

    this.suscriptionEvent = this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'sii_clave_incorrecta':
          console.log("sii_clave_incorrecta")
          this.valForm.controls['sii_pass'].setErrors({'incorrect': true});
          this.loading_sii = false;

          break;

        case 'sii_clave_bloqueada':
          console.log("sii_clave_bloqueada")
          this.valForm.controls['sii_pass'].setErrors({'bloqueada': true});
          this.loading_sii = false;
        break;

        case 'upload_certificado':
          console.log("upload_certificado")
          this.loading = false;
          this.loading_sii = false;
          this.step = 2;

        break;

        case 'need_clave_certificado':
          console.log("need_clave_certificado")
          this.loading = false;
          this.loading_sii = false;
          this.step = 3;

        break;

        case 'invalid_permission_certf':
          if(msg.args.error == "La clave del certificado es incorrecta"){
            console.log("cai en clave incorrecta")
            this.valFormCertificado.controls['cert_pass'].setErrors({'incorrect': true});
          }

          if(msg.args.error == "El certificado esta vencido"){
            console.log("cai en vencido")
            this.certificado_vencido(1);
          }

          if(msg.args.error == "El usuario no representa a la empresa"){
            this.certificado_vencido(2);
          }

          if(msg.args.error == "No tienes los permisos necesarios para firmar documentos"){
            this.certificado_vencido(3);
          }

          this.loading_upload = false;

        break;

        case 'start_with_upload_cert':
          console.log("start_with_upload_cert")
          this.dialogRef.close({result: 'correcto', sii_key: this.valForm.get('sii_pass')?.value})
        break;

        case 'start_download_aec_cert_centralizado':
            this.dialogRef.close("clave_certificado_correcta")
          break;

        case 'error_sii_request':
          this.certificado_vencido(4);
        break;

        default:
          break;
      }
    });

  }




  ngOnDestroy() {
    this.suscriptionEvent.unsubscribe()
  }

  rutKey($e: any){
    let rut = $e.target.value.split('.').join('');
    let st = rut.replace('-', '');
    let arr = st.split('');
    let st_out = '';
    for (let i=0; i < arr.length; i++) {
      st_out = st_out + arr[i];
      if (i == arr.length - 2) {
        st_out = st_out + '-'
      }
    }
    rut = st_out
    this.valFormCertificado.controls['rut_autorizado'].setValue(rut)
  }

  loading:boolean=false;
  loading_sii:boolean=false;
  async post_credenciales($ev: any, value: any){


    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid){
      try {

        this.loading = true;
        this.loading_sii = true;

        const bd: any = await this.settings.get_query(2, '/cliente/cesiones/'+this.id_company+'/validate-user-credentials/?simulation='+this.id_sim, value);
        console.log("body", bd)
        switch (bd.status) {
          case 200:

            this.loading = false;



          break;

          default:

          break;
        }

      } catch (bd:any) {
        this.loading = false;
        switch (bd.status) {
          case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            this.loading_sii = false;
            this.loading = false;
            break;


          case 400:
              this.valForm.controls['rut_autorizado'].setErrors({'incorrect': true});
              this.loading_sii = false;
            break;
          default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            break;
        }

      }
    }


  }

  async post_credenciales_only_pass($ev: any, value: any){


    $ev.preventDefault();
    for (let c in this.valFormClave.controls) {
      this.valFormClave.controls[c].markAsTouched();
    }

    if (this.valFormClave.valid){
      try {

        this.loading = true;
        this.loading_sii = true;

        const bd: any = await this.settings.get_query(2, `/cliente/cesiones/${this.id_company}/validate-login-company/?simulation=${this.id_sim}&operation_id=${this.id_operation}`, value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
            console.log("body", body_data)
            this.loading = false;
            this.loading_sii = false;
            if(body_data.success.data.password_valid == false){
              this.valFormClave.controls['sii_pass'].setErrors({'incorrect': true});
            }else{
              let value = {'correcto':true, 'sii_pass':this.valFormClave.controls['sii_pass'].value, 'need_saved':this.valFormClave.controls['need_saved'].value}
              this.dialogRef.close(value)
            }



          break;

          default:

          break;
        }

      } catch (bd:any) {
        this.loading = false;
        switch (bd.status) {
          case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            this.loading_sii = false;
            this.loading = false;
            break;


          case 400:
              this.valFormClave.controls['sii_pass'].setErrors({'incorrect': true});
              this.loading_sii = false;
            break;
          default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            break;
        }

      }
    }


  }

  loading_pass_cert:boolean=false;
  async post_credenciales_only_pass_cert($ev: any, value: any){


    $ev.preventDefault();
    for (let c in this.valFormClaveCert.controls) {
      this.valFormClaveCert.controls[c].markAsTouched();
    }

    if (this.valFormClaveCert.valid){
      try {

        this.loading_pass_cert = true;

        value['rut_autorizado'] = this.valForm.controls['rut_autorizado'].value;
        value['sii_pass'] = this.valForm.controls['sii_pass'].value;

        const bd: any = await this.settings.get_query(2, '/cliente/cesiones/'+this.id_company+'/get-aec-firma-centralizada/?simulation='+this.id_sim, value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
            console.log("body", body_data)




          break;

          default:

          break;
        }

      } catch (bd:any) {
        this.loading_pass_cert = false;
        switch (bd.status) {
          case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            break;


          case 400:
              this.valFormClaveCert.controls['cert_pass'].setErrors({'incorrect': true});
              this.loading_pass_cert = false;
            break;
          default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            break;
        }

      }
    }


  }



  files:any[]=[];
  loading_upload:boolean=false;
  triger_click() {
    $("#fileInput2").trigger('click')
  }

  handleFiles(files: any) {
    if (files) {
      const filesArray = Array.from(files);

      const xmlFiles = filesArray.filter((file:any) => this.hasPfxExtension(file.name));
      this.files = [...this.files, ...xmlFiles];
      this.valFormCertificado.controls['file'].setValue(this.files[0].name)
    }
  }

  hasPfxExtension(fileName: string): boolean {
    const lowerCaseFileName = fileName.toLowerCase();
    return lowerCaseFileName.endsWith('.pfx');
  }

  deleteFile() {
    this.files = [];
    $("#fileInput2").val("");
    this.valFormCertificado.reset();
  }

  que_es_un_adc() {
    const dialogRef = this.dialog.open(QueEsunAEC, {
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      autoFocus:false,
    });

    dialogRef.componentInstance.step = 3;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.dialogRef.close(1)
      }
    });
  }

  open_sincronizacion(event: Event){
    event.stopPropagation();

    const dialogRef = this.dialog.open(QueEsunAEC, {
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      autoFocus:false,
    });

    dialogRef.componentInstance.step = 4;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){

      }
    });
  }

  async post_certificado($ev: any, value: any){


    $ev.preventDefault();
    for (let c in this.valFormCertificado.controls) {
      this.valFormCertificado.controls[c].markAsTouched();
    }

    if (this.valFormCertificado.valid){
      try {

        this.loading_upload = true;

        let check = this.valFormCertificado.controls['need_saved'].value;
        if(check == null){
          check = false;
        }

        const formData = new FormData();
        formData.append('file', this.files[0])
        formData.append('cert_pass', this.valFormCertificado.controls['cert_pass'].value)
        formData.append('need_saved', check)
        formData.append('rut_autorizado', this.valFormCertificado.controls['rut_autorizado'].value)
        formData.append('sii_pass', this.valForm.controls['sii_pass'].value)

        const bd: any = await this.settings.get_query(2, `/cliente/cesiones/${this.id_company}/validate-user-certificado/?simulation=${this.id_sim}&operation_id=${this.id_operation}`, formData);
        console.log("body", bd)
        switch (bd.status) {
          case 200:





          break;

          default:

          break;
        }

      } catch (bd:any) {
        console.log("error",bd)
        this.loading_upload = false;
        const self = this
        switch (bd.status || bd.status) {
          case 500:
            self.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
            break;

          case 400:
              self.valFormCertificado.controls['rut_autorizado'].setErrors({'incorrect': true});
              self.loading_sii = false;
            break;
          default:
            self.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });

            break;
        }

      }
    }


  }

  certificado_vencido(param?:any){
    const dialogRef = this.dialog.open(AlertErrorCesion,{
      width: '800px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus:false,
    });

    if(param == 1){
        dialogRef.componentInstance.paso == 1;
        dialogRef.componentInstance.subtitle = "<strong>¡Importante!</strong> El Certificado Digital está vencido. Actualiza el archivo para continuar. Si necesitas asesoría, contacta a soporte."
    }

    if(param == 2){
      dialogRef.componentInstance.paso == 2;
      dialogRef.componentInstance.subtitle = "<strong>¡Importante!</strong> El Certificado Digital ingresado no corresponde al RUT autorizado. Para continuar, ingresa el Certificado Digital asociado al RUT autorizado. Si necesitas asesoría, contacta a soporte."

    }

    if(param == 3){
      dialogRef.componentInstance.paso == 3;
      dialogRef.componentInstance.subtitle = "<strong>¡Importante!</strong> No cuentas con el permiso para generar los AEC. Contacta a soporte para gestionar el caso."
    }

    if(param == 4){
      dialogRef.componentInstance.paso = 4;
      dialogRef.componentInstance.subtitle = "<strong>¡Algo ocurrió!</strong> El sistema del SII está más lento de lo normal. Inicia el proceso otra vez y si el problema persiste, contáctanos al <strong>+56 9 3467 7786.</strong>"
    }

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.error_certificado = true;
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == 1){
        this.deleteFile();
      }
      if(result == 'retry'){
        this.loading = false;
        this.loading_sii = false;
        this.loading_upload = false;
        this.loading_pass_cert = false;
      }
    });
  }

}
