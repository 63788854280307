<div mat-dialog-title>

      <h1 class="titulo-simpli">Registra o actualiza tu clave</h1>
      <small class="subtitulo-simpli">Para sincronizar tus datos, ingresa la clave que utilizas en el SII</small>


      <div class="btn_cerrar" mat-dialog-close>

        <i class="fa-sharp fa-regular fa-xmark"></i>

      </div>


</div>


<div mat-dialog-content>
    <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <mat-card class="animate__animated animate__fadeIn" style="border: none;box-shadow: none !important;">

            <mat-card-content>


                <form  [formGroup]="valForm">

                    <div class="row">


                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                            <mat-form-field appearance="fill">
                                <mat-label>Clave SII</mat-label>
                                <input formControlName="clave_sii" matInput type="password" required (keyup)="validate_key($event)"> 
                                <mat-spinner color="primary" diameter="20" matSuffix *ngIf="saving_sii"></mat-spinner>
                                <span class="material-icons animate__animated animate__fadeIn" matSuffix *ngIf="success_sii" style="color:#3BA46F;">check_circle</span>
                                <mat-hint>Ingresa tu clave SII</mat-hint>
                                <mat-error *ngIf="valForm.controls['clave_sii'].hasError('incorrect')">
                                    Clave SII inválida.
                                </mat-error>
                                <mat-error *ngIf="valForm.controls['clave_sii'].hasError('required')">
                                    Clave SII requerida.
                                </mat-error>
                            </mat-form-field>


                            <div class="leyenda">
 
                                Su clave está protegida con altos estándares de seguridad y encriptación. Al continuar, confirma que acepta nuestra
                                <a class="simpli-color2 cursor-pointer text-none" href="https://simplilatam.com/politica-de-privacidad/" target="_blank">política de privacidad</a>
                                diseñada para resguardar su información en todo momento.
        
                            </div>
        
                        </div>


                    </div>



                </form>
            </mat-card-content>
        </mat-card>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div [ngClass]="{'disable-btn':disable_btn == true, 'btn-simpli-container':true}">

                <button *ngIf="success_sii == false" [disabled]="disable_btn" mat-flat-button class="primary-button" type="button" style="margin-top: 10px;" (click)="verificar_guardar_clave_sii(true)">
                    <mat-label *ngIf="!spinner_btn">Verificar</mat-label>
                    <span *ngIf="spinner_btn"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                 </button>
    
                <button *ngIf="success_sii == true"  [disabled]="disable_btn" mat-flat-button class="primary-button"  (click)="verificar_guardar_clave_sii()">
                    <mat-label *ngIf="!spinner_btn">Guardar</mat-label>
                    <span *ngIf="spinner_btn"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                </button>

              </div>
              
            </div>
        </div>
    </div>
</div>

