<div [ngClass]="{'observation':true, 'externo':observation.tipo == 'externo'}">
    <div class="avatar" [ngStyle]="{'background-image': showInitials ? avatarBackground : 'url(' + observation.avatar + ')'}">
        <span>{{ initials }}</span>
    </div>
    <div class="content-container">
      <div class="author">
        {{ observation.nombre }}

      </div>
      <div class="message-container">
        <div class="message">
          {{ observation.contenido }}
        </div>
        <div class="date">
          {{ observation.fecha }} {{observation.hora}}
        </div>
      </div>
    </div>
  </div>
  