import { SelectionModel } from '@angular/cdk/collections';
import { Component,ViewChild, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-proveedores-fechas',
  templateUrl: './proveedores-fechas.component.html',
  styleUrls: ['./proveedores-fechas.component.less']
})
export class ProveedoresFechasComponent implements OnInit, OnDestroy {

  @Input() company_id: any;

  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];


  excel_download=0;
  ELEMENT_DATA_FACTURAS: Array<any>=[];
  displayedColumnsFacturas: string[] = ['folio','receptoracreedor__business_name','ultimoacreedor','mnt_total','fecha_emision','fechapp'];
  dataSourceFacturas = new MatTableDataSource<Facturas>(this.ELEMENT_DATA_FACTURAS);
  @ViewChild('paginatorCompras') paginatorCompras!: MatPaginator;
  
  desde='';
  hasta='';
  filter='';
  infotable:boolean=false;
  show_msg_acreedor_legal:boolean=false;

  @ViewChild(MatSort) sort!: MatSort;
  firstFormGroup: FormGroup;
  formFechas: FormGroup;
  cargando:Boolean=true
  info:Boolean=false
  infocesiones:Boolean=false;
  clave:number=0;
  valForm :FormGroup;
  
  selection = new SelectionModel<Facturas>(true, []);

  arr_documentos_to_simulate: Array<any> = [];
  arr_simulation: Array<any> = []
  disabled_enviar:boolean=true;
  disabled:boolean=true;

  total_total=0;
  insertar_vencimiento:boolean=false;

  
  checkall:boolean=false;
  minDate:any = new Date();

  se_activo_check:boolean=false;

  
  paymentMethod = new FormControl();

  paymentMethodList: string[] = ['Cheque', 'Vale vista', 'Transferencia', 'Aplicacion'];

  typeList: any[] = [{'value': 'fecha_emision', 'name':'Fecha de emisión'}, {'value': 'fechapp', 'name':' Fecha probable de pago'}];
  value_type_filter: string = "";

  fecha_emision_favorite: boolean = false;
  fecha_pp_favorite: boolean = false;
  fecha_vencimiento_favorite: boolean = false;

  constructor(
    public settings: SettingsService, 
    private router: Router, 
    private fb: FormBuilder, 
    public dialog:MatDialog,
    public snackBar: MatSnackBar){ 

      this.formFechas = this.fb.group({
        fechaI: ['', Validators.required],
        fechaF: ['', Validators.required]
      });
  
      this.valForm = fb.group({
        desde: '',
        hasta: '',
        filter:'',
        type:[['fecha_emision']]
      });
  
      this.firstFormGroup = this.fb.group({
        firstCtrl: ['', Validators.required],
        secondCtrl: [null, Validators.required],
        thridCtrl: [null, Validators.required],
        checkall:false,
      });

  }


  myObserver: any;

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngOnInit() {
    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
      this.load_all()
      };
    });
    this.load_all()
  }

  load_all() {
    this.company_id = Number(localStorage.getItem("company_id"))
    this.guardar_visita();
    this.load_table();
  }

  formatMoney = (amount: any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  }

  async load_table(){
    this.show_msg_acreedor_legal = false;
    this.disabled = true;
    let resultado: any = (await this.settings.get_query(1, 'ConfirmigCompras/'+this.company_id+'/?excel=' + this.excel_download + '&pageNumber=' + this.page + '&fecha_ini=' +this.desde + '&fecha_fin=' + this.hasta + '&filter=' + this.filter + '&ordenamiento=' + this.ordenamiento + '&columna=' + this.columna+'&tipoFecha='+this.value_type_filter));
    let body_data = resultado._body;
    if (resultado) {
      this.ELEMENT_DATA_FACTURAS = body_data.facturas;
        this.length = body_data.lenFacturas;
        this.disabled = false;
        $("#btn_llamativo").removeClass('disable_btn_llamativo');

        if(this.ELEMENT_DATA_FACTURAS.length >0){
          this.infotable = true;
        }else{
          this.infotable = false;
        }

        this.fecha_emision_favorite = body_data.ordenFechaEmision;
        this.fecha_pp_favorite = body_data.ordenFechapp;
        this.fecha_vencimiento_favorite = body_data.ordenFechaVencimiento;

        let is_all = false;
        let valid_checks = 0;
        let checks_selected = 0;
        for (let i = 0; i < body_data.facturas.length; i++) {
          if (body_data.facturas[i].historialAcreedores.length > 0) {
            this.show_msg_acreedor_legal = true;
          }
          if (this.arr_simulation.includes(body_data.facturas[i].id)) {

            body_data.facturas[i]['checked'] = true
            this.selection.isSelected(body_data.facturas[i])

            checks_selected = checks_selected + 1;
            
          } else {
            body_data.facturas[i]['checked'] = false                     
          }
          if ((!body_data.facturas[i].pagado) && !(body_data.facturas[i].aceptacionReclamo == 0) && !(body_data.facturas[i].estado == 'Contado/Gratuito')) {
            valid_checks = valid_checks + 1;
          } 
        }

        if ((checks_selected == valid_checks) && (checks_selected != 0)){
          is_all = true;
        }

        this.checkall = is_all;
        this.firstFormGroup.controls['checkall'].setValue(is_all);


        setTimeout(() => {
          this.dataSourceFacturas=new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
          this.dataSourceFacturas._updateChangeSubscription();
        });
    }
  }

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    // this.checkall = false;
    // this.firstFormGroup.controls['checkall'].setValue(false);
    this.load_table();
  }

  columna = "";
  ordenamiento = "";
  sortData($e: any) {
    this.columna = $e.active;
    this.ordenamiento = $e.direction;
    this.load_table()
  }

  filter1($e: any) {

    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    this.desde = year + '-' + mes + '-' + dia;

    if (this.hasta!="") {
      this.load_table()
    }

  }

  filter2($e: any) {
      let date = new Date($e.value);
      let mes = date.getMonth() + 1;
      let dia = date.getDate();
      let year = date.getFullYear();
      this.hasta = year + '-' + mes + '-' + dia;

    if (this.desde!="") {
      this.load_table()
    }
  }
  filtrar(){
    this.filter = this.valForm.controls['filter'].value;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    if(this.filter == null){
      this.filter = "";
    }
      this.load_table();
  }

  reset(filter: any){
    if(filter == ""){
      this.filter = "";
      this.load_table()
    }
  }

  reset_filters(){
    this.filter = "";
    this.desde = "";
    this.hasta = "";
    this.columna = "";
    this.ordenamiento = "";
    this.value_type_filter = "";
    this.valForm.reset()
    this.load_table()
  }


  reset_selection() {
    this.valForm.reset()
    this.insertar_vencimiento = false;
    this.arr_documentos_to_simulate = [];
    this.arr_simulation = []
    this.disabled_enviar = true;
    this.se_activo_check = false;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    // this.total_seleccionado = "Total"
    this.load_table()
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturas.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event: any) {
    !$event.checked  ?
    this.selection.clear() :
    this.dataSourceFacturas.filteredData.forEach(row => {
      if ((!row.pagado) && !(row.aceptacionReclamo == 0)) {
        this.selection.select(row)
      }
    });
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  activa(id: any, nombre: any, $e: any, objeto: any, index: any) {



    if ($e) $e.stopPropagation();



        const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);






        if (obje === undefined) {


          this.arr_documentos_to_simulate.push(objeto);
        } else {

          this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
        }



        if (this.arr_simulation.includes(id)) {

          this.arr_simulation.splice(this.arr_simulation.indexOf(id), 1);
        } else {
          this.arr_simulation.push(id);
        }




        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.insertar_vencimiento = true;
          this.disabled_enviar = false;
          // this.total_seleccionado = "Total Seleccionado";

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          // this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          // this.total_seleccionado = "Total";
          // this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    




  }

  activa_final(){
    this.checkall =  this.firstFormGroup.controls['checkall'].value;

    if(this.checkall == false){


        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
          if ((!this.dataSourceFacturas.filteredData[i].pagado) && !(this.dataSourceFacturas.filteredData[i].aceptacionReclamo == 0)) {
            if (this.arr_simulation.indexOf(this.dataSourceFacturas.filteredData[i].id) == -1){
              this.arr_simulation.push(this.dataSourceFacturas.filteredData[i].id)
            }
            if (this.arr_documentos_to_simulate.indexOf(this.dataSourceFacturas.filteredData[i]) == -1){
              this.arr_documentos_to_simulate.push(this.dataSourceFacturas.filteredData[i])
            }
          }
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          // this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento= true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          // this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          // this.total_seleccionado = "Total";
          // this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }


    
    }else if(this.checkall == true){

        // this.arr_simulation = []
        // this.arr_documentos_to_simulate = [];
        this.selection.clear()

        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
          this.dataSourceFacturas.filteredData[i].checked = false;
          if ((!this.dataSourceFacturas.filteredData[i].pagado) && !(this.dataSourceFacturas.filteredData[i].aceptacionReclamo == 0)) {

            this.arr_simulation.splice(this.arr_simulation.indexOf(this.dataSourceFacturas.filteredData[i].id), 1);
            this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(this.dataSourceFacturas.filteredData[i]), 1);
          }
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          // this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento = true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          // this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          // this.total_seleccionado = "Total";
          // this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    }


  }

  async put_table(from: any, index?: any, array?: any){
    let value = {}
    if(index != undefined && index != null){
      value = {'facturas': [array]}
    }else{
      value = {'facturas':this.arr_documentos_to_simulate}
    }
    let resultado: any = (await this.settings.get_query(3,'ConfirmigCompras/'+this.company_id +'/',value));
    let body_data = resultado._body;
    if (resultado) {
      this.firstFormGroup.controls['firstCtrl'].setValue(undefined);
      this.firstFormGroup.controls['secondCtrl'].setValue(undefined);
      this.firstFormGroup.controls['thridCtrl'].setValue(undefined);
      this.load_table();
    }

    if (from == 2) {
      this.reset_selection();
    }
  }

  clone_array(arr: any)
  {
    let aa =  JSON.stringify(arr);
    return  JSON.parse(aa)
  }

  fecha_individual($e: any, index: any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.ELEMENT_DATA_FACTURAS[index]["fecha_vencimiento"] = date_set
    this.ELEMENT_DATA_FACTURAS[index]["dias"] = diffDays;

    let array = this.clone_array(this.ELEMENT_DATA_FACTURAS)
    array[index].fechapp = null;
    this.put_table(1, index, array[index])

  }

  fecha_individual_pp($e: any, index: any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.ELEMENT_DATA_FACTURAS[index]["fechapp"] = date_set

    let array = this.clone_array(this.ELEMENT_DATA_FACTURAS)
    array[index].fecha_vencimiento = null;
    this.put_table(1, index, array[index])

  }

  fecha_todas($e: any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.arr_documentos_to_simulate){
        this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
        this.arr_documentos_to_simulate[i]["dias"] = diffDays
        this.arr_documentos_to_simulate[i]['fechapp'] = null
    }


    this.put_table(2)



  }

  capturar_fecha_por_dias($e: any){
      let dias = ($e.target.value - 1);
      let new_date = this.addDays(Number(dias))
      let fecha_calculada = new Date(new_date)

      this.firstFormGroup.controls['secondCtrl'].setValue(fecha_calculada)
      this.fecha_todas_por_dias(fecha_calculada)

  }

  addDays(days: any) {
    let fecha_hoy = new Date()
    let fecha_condias = new Date()
    fecha_condias.setDate(fecha_hoy.getDate()+days);
    return fecha_condias;
  }

  fecha_todas_por_dias(date: any){
      var date = date
      let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

      if (d_o_w == 0 || d_o_w == 6)
        date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

      let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      for(var i in this.arr_documentos_to_simulate){
          this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
          this.arr_documentos_to_simulate[i]["dias"] = diffDays
          this.arr_documentos_to_simulate[i]['fechapp'] = null

      }

      this.put_table(1)




  }

  async sincronizar(){
    let value = {}
    this.disabled = true;
    $("#btn_llamativo").addClass('disable_btn_llamativo');
    let resultado: any = (await this.settings.get_query(2,'sii-empresa/'+this.company_id+'/',value));
    if (resultado.status==200) {
      this.open_input_dialog("Sincronización", "Sincronización Exitosa", 
      "¡Todo listo! Nos hemos sincronizado con el SII",
      "Hemos actualizado los ultimos 6 meses con tus libros de compras, ventas y notas de credito!","sync","#3BA46F")
    }else{
      this.disabled = false;
    }
  }

  open_input_dialog(title: any, subtitle: any, mensajeprincipal: any, mensajenegrita: any, icon: any, coloricon: any){
    let dialogRef = this.dialog.open(InputDialog,{width:'700px'});
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
    dialogRef.afterClosed().subscribe((result:any) => { this.load_table() });
  }

  async pagar(pagado: any, id_compra: any, payment: any){
    if (pagado) {
      return false;
    }
    // this.reset_selection()
    let resultado: any = (await this.settings.get_query(3, 'PagarCompras/' + id_compra + '/', {'pagado': !pagado, 'mediopago': payment}));
    let body_data = resultado._body;
    if (resultado) {
      if (!body_data.error) {
        this.load_table();
      }
    } 

  }

  async guardar_visita(){
    let value = {'pantalla': 'pago-proveedores-fechas'}
    let resultado: any = (await this.settings.get_query(2, 'visitas-clientes-pantalla/', value));
    let body_data = resultado._body;
    if (resultado) {
      console.log(body_data)
    }
  }

  open_credenciales(){
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false,
    });
    dialogRef.componentInstance.id_company = this.company_id;

    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_all()
    });
  }

  async setFavorite(campo: any){
    let resultado: any = (await this.settings.get_query(2, 'ordenCompras/' + this.company_id + '/', {'campo': campo}));
    let body_data = resultado._body;
    if (resultado) {
      this.load_table();
    } 

  }

  type_filter(value: any) {
    let is_limpiar = false;
    value.forEach((element: any) => {
      if (element == '') {
        is_limpiar = true;
      }
    });
    if (is_limpiar) {
      this.value_type_filter = '';
      this.valForm.controls['type'].setValue('');
    } else {
      this.value_type_filter = value.join()
    }
    this.load_table();
  }

}

export interface Facturas{  
  id:any,
  checked: any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  pagado: any,
  status: any,
  aceptacionReclamo: any
}