<div class="row row_main">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli">Facturas Pagadas</h1>
        <small class="subtitulo-simpli">Revisa el detalle de tus Facturas Pagadas</small>

    </div>







    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">


        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

            <form [formGroup]="valForm">
                <div class="row container_filter">

                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                        <mat-form-field>
                            <mat-label>Desde</mat-label>
                            <input matInput [matDatepicker]="picker" (dateChange)="filter1($event)" formControlName="inicio">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                        <mat-form-field>
                            <mat-label>Hasta</mat-label>
                            <input matInput [matDatepicker]="picker2" (dateChange)="filter2($event)" formControlName="final">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>



                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                        <mat-form-field>
                            <mat-label>N.º Facturas</mat-label>
                            <mat-select multiple (selectionChange)="filtros(1, $event)">
                                <mat-option *ngFor="let docto of doctos" [value]="docto.docto">N.º Facturas: {{docto.docto}} - Cliente:{{docto.rut_cliente}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                        <mat-form-field>
                            <mat-label>Clientes</mat-label>
                            <mat-select multiple (selectionChange)="filtros(2, $event)">
                                <mat-option *ngFor="let deu of deudor" [value]="deu">{{deu}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-top animate__animated animate__fadeIn center_button">
                        <button class='stroked-btn' mat-stroked-button id="RESET" (click)="resetFilter()">Limpiar filtros</button>
                        <button class='stroked-btn' mat-stroked-button (click)='downloadXLXS()' [class.spinner]='loaderButton' [disabled]='loaderButton'>Descargar XLXS</button>
                    </div>

                </div>
            </form>
        </div>
        <mat-card class="card-home" *ngIf="!noData">



            <mat-card-content id="capa">



                <div class="row" style="position: relative; height: 200px;">
                    <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
                </div>


                <div class="row caja_docto opacidad opacidad{{i}} padding-responsive" style="padding-right: 1%;" *ngFor="let info of informacionFA;let i = index;">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-top" style="padding-left: 0;">
                                <small class="texto_tabla">Cliente: </small>
                                <br class="ocultar_mostrar">
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.deudor}}</small>
                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-4 col-xs-6 col-top caja_tabla margen_responsive caja_tabla_responsive">
                                <small class="texto_tabla">N.º Facturas</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.docto}}</small>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6 col-top caja_tabla caja_tabla_responsive">
                                <small class="texto_tabla">Total a pagar</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto)}}</small>
                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                                <small class="texto_tabla">Fecha Emisión</small>
                                <br>
                                <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_emision | date:
                                    'dd.MM.yyyy'}}</small>
                                <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_emision | date:
                                    'dd.MM.yy'}}</small>
                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                                <small class="texto_tabla">Fecha Vcto.</small>
                                <br>
                                <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_v | date:
                                    'dd.MM.yyyy'}}</small>
                                <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_v | date: 'dd.MM.yy'}}</small>
                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                                <small class="texto_tabla">Fecha PP.</small>
                                <br>
                                <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_pp | date:
                                    'dd.MM.yyyy'}}</small>
                                <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_pp | date:
                                    'dd.MM.yy'}}</small>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 col-top caja_tabla columna_cedida caja_tabla_responsive">
                                <small class="texto_tabla">Cedida a:</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.tipo_fondo}}</small>
                            </div>
                        </div>

                        <div class="row fechas margen_inferior">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                                <button class="botones_fechas" mat-flat-button>Fecha Emisión: {{info.fecha_emision |
                                    date: 'dd.MM.yyyy'}}</button>
                                <button class="botones_fechas" mat-flat-button>Fecha Vcto.: {{info.fecha_v | date:
                                    'dd.MM.yyyy'}}</button>
                                <button class="botones_fechas" mat-flat-button>Fecha Vcto.: {{info.fecha_pp | date:
                                    'dd.MM.yyyy'}}</button>
                            </div>
                        </div>

                        <div class="row linea">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-bottom: 0;">
                                <hr style="width: 101%;float: left;margin-top: 1px;color: #858489;margin-left: -15px;" />
                            </div>
                        </div>

                        <div class="row" style="margin-top: -5px;padding-bottom: 10px;">
                            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-top comentario_cobranza scroll_raking">
                                <small class="texto_tabla"><span
                                        style="font-weight: 600;color: #4d4d4d;">{{info.cobrador}}</span>
                                    {{info.date_cretaed | date: 'dd.MM.yyyy'}}</small>
                                <br>
                                <small *ngIf="info.observacion_gestion ==''" class="texto_tabla">No se han registrado
                                    observaciones de cobranza</small>
                                <small *ngIf="info.observacion_gestion !=''" class="texto_tabla" style="text-align:justify;">{{info.observacion_gestion}}</small>
                                <br>
                                <div *ngFor="let comen of info['comentarios_deudor'];let j = index;" style="padding-top: 8px;">
                                    <small class="texto_tabla"><span
                                            style="font-weight: 600;color: #4d4d4d;text-transform: uppercase;">{{comen.escribio}}</span>
                                        {{comen.date_created | date: 'dd.MM.yyyy'}}</small>
                                    <br>
                                    <small class="parrafo_observaciones">{{comen.observaciones}}</small>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-top caja_tabla margen_responsive" style="text-align: end;padding-left: 1%;margin-top: 0;">
                                <small class="texto_tabla ocultar_mostrar">Estado factura</small>
                                <small class="texto_status estado_factura">DOCTO. PAGADO</small>
                                <br>
                                <small>{{info.forma_de_pago}}</small>
                                <br>
                                <small>{{info.docto_recaudacion}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-paginator #paginator [length]="informacionFANum" [pageSize]="20" (page)="pageCustom($event)">
            </mat-paginator>


        </mat-card>

        <div class="sinRegistros" style="text-align: center;"  *ngIf="noData && !showSpinner">
            <small class="small-sinregistros">
              <div style="text-align: center;"><span style="font-size: 30px;" class="material-icons">info</span></div>
              ¿No encontraste la información que buscabas?
              Contáctanos al <strong>+56 9 3467 7786</strong> y gestionaremos tu requerimiento a la brevedad.
              </small>
        </div>

    </div>


</div>
