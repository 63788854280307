<div class="row row_main" style="background: white;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn border scroll_grafico_torta" style="overflow: scroll;overflow-y: hidden;">
        <div style="padding-bottom: 2%;margin-right: -10px;">
            <h1 class="titulo-simpli">Participación proveedores/compras</h1>
            <small class="subtitulo-simpli">{{ this.menos | date: 'dd-MM-yyyy'}} / {{ this.actual | date: 'dd-MM-yyyy'}}</small>
            <br>        
            <div class="sinCargando" *ngIf="cargando">
                <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
            </div>

            <div class="sinRegistros" *ngIf="!info && !cargando">
                <small>No se consiguieron registros.</small>
            </div>

            <div id="container-participacion-deudores-compras" style="width: 100%;"></div>
        </div>
    </div>
</div>