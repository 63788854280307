
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 tamanio"
 style="text-align: center;background-image: url('../../../assets/img/fondo_simpli_404.png');" (click)="go()">
 <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-top" style="margin-top: 200px;">
        <img src="../../../assets/img/simpli_404.png" class="notfound">
    </div>

 </div>
 <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 40px;">
        <img src="../../../assets/img/volver_simpli_404.png" class="volver">
    </div>
 </div>

<div>
    <img src="https://api-arrayan.cl//media/pics/recurso_492x.png" class="logo">
</div>
</div>

