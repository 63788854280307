import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';;
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ObsSimulation } from '../observaciones/obs';
import { AddFacturaInfo } from './add-factura-info/add-factura-info';
import { AlertDelete } from '../documentacion/alert-delete/alert-delete';
import { RechazarCondicionesDialog } from './rechazar-condiciones/rechazar-condiciones';
import { CargarCertificado } from './cargar-certificado/cargar-certificado';
import { AlertErrorCesion } from './alert-error-cesion/alert-error-cesion';
import { IMessage, WebSocketService } from 'src/app/services/websocket.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {Location} from '@angular/common';

declare var $: any;


@Component({
  selector: 'app-cesion-facturador',
  templateUrl: './cesion-facturador.component.html',
  styleUrls: ['./cesion-facturador.component.less']
})
export class CesionFacturadorComponent implements OnInit {


  constructor(private router:Router, public settings: SettingsService, public dialog: MatDialog, private activated_router: ActivatedRoute,
    private snackBar:MatSnackBar, private ws: WebSocketService, private _location: Location) {

    this.activated_router.params.subscribe((params:any) => {
      this.id_sim = params['id']
    })

  }

  @Input() id_company:any=localStorage.getItem('company_id');
  @Input() id_sim:any;
  @Input() parent:any;

  step1:boolean=false;
  step2:boolean=false;
  step3:boolean=false;

  change_step(step_name:string){
    $(".stepper-simpli").removeClass('stepper-simpli-active');
    $("#"+step_name).addClass('stepper-simpli-active');

    switch(step_name) {
      case 'step-simpli1': {
         this.step1 = true;
         this.step2 = false;
         this.step3 = false;

          break;
      }
      case 'step-simpli2': {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;


         break;
      }
      case 'step-simpli3': {
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;


          break;
     }
      default: {
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;

          break;
      }
   }
  }

  myObserver: any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  load_all(){
    window.scrollTo({top: 0, behavior: 'smooth'});

    this.get_resumen_sim();
    this.get_credenciales();
    this.get_facturador();
    this.get_estado_op();
  }


  is_responsive:boolean=false;
  ngOnInit() {

    this.onFeatureAec()

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });


    this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'finish_process_validation_aec_cesion':
          this.loading = true;
          this.facturas = [];
          this.load_all()
          break;
        default:
          break;
      }
    });

    this.load_all();

  }

  displayedColumns: string[] = ['factura', 'deudor', 'rut', 'monto', 'estado'];
  displayedColumnsResponsive: string[] = ['data'];
  ELEMENT_DATA: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  dataSourceResponsive = new MatTableDataSource(this.ELEMENT_DATA);


  //STEP 3
  displayedColumns2: string[] = ['numero_op', 'monto', 'estado', 'fecha','ver_detalle'];
  displayedColumns2Responsive: string[] = ['data'];
  ELEMENT_DATA2: any[] = [];
  dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
  dataSource2Responsive = new MatTableDataSource(this.ELEMENT_DATA2);



  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }


  date_created="";
  anticipo:any=0;
  valor_anticipo_total:any=0;
  dias_promedio:any=0;
  tasa_interes:any=0;
  comision:any=0;
  gastos:any=0;
  interes_total:any=0;
  iva_operacion:any=0;
  excedentes:any=0;
  monto_recibir:any=0;
  total:any=0;

  STATUS:any;
  loading:boolean=false;
  id_operacion:any=null;
  has_valid_aecs:boolean=false;
  async get_resumen_sim(){


    try {

      this.loading = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion/'+this.id_sim+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.STATUS = data.info.status;
          this.get_facturas();
          this.has_valid_aecs = data.info.has_valid_aecs;

          this.date_created = data.info.date_created;
          this.anticipo = this.formatMoney(data.info.anticipo);
          this.valor_anticipo_total = this.formatMoney(data.info.valor_anticipo_total);
          this.dias_promedio = data.info.dias_promedio;
          this.tasa_interes = data.info.tasa_interes;
          this.comision = this.formatMoney(data.info.comision);
          this.gastos = this.formatMoney(data.info.gastos);
          this.interes_total = this.formatMoney(data.info.interes_total);
          this.iva_operacion = this.formatMoney(data.info.iva_operacion);
          this.excedentes = this.formatMoney(data.info.excedentes);
          this.monto_recibir = this.formatMoney(data.info.monto_recibir);
          this.total = this.formatMoney(Number(data.info.total));
          this.id_operacion = data.info.id_operacion;

          this.loading = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  cantidad_facturas:any=0;
  facturas:any[]=[];

  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;

  show_descarte:boolean=false;
  async get_facturas(){


    try {


      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_sim+'/?page='+this.page);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:



          this.cantidad_facturas = data.total;
          this.pageLength = data.total;
          this.facturas = data.info;

          if(this.facturas.length > 0){
            this.show_descarte = false;
          }else{
            this.show_descarte = true;
          }


          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource._updateChangeSubscription()
          });

          setTimeout(() => {
            if(this.STATUS == 5 || this.STATUS == 16){
              this.change_step('step-simpli2');
            }else if(this.STATUS == 14 || this.STATUS == 15 || this.STATUS == 17){
              this.change_step('step-simpli3');
              this.get_estado_op();
            }else{
              this.change_step('step-simpli1');
            }
          });


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_facturas();
  }

  observaciones_get() {
    let dialogRef = this.dialog.open(ObsSimulation, {
      width: '800px',
      maxWidth: '95vw',
    });
    dialogRef.componentInstance.id_company = this.id_company;
  }

  loading_pdf_:boolean=false;
  async download_pdf() {
    try {
      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(
        1,
        '/cliente/simulacion-detalle-pdf/' + this.id_sim + '/',
      );

      const body_data: any = bd._body;
      const data = body_data.success;

      switch (bd.status) {
        case 200:
          console.log('Datos recibidos:', body_data);

          if (body_data.respuesta && body_data.respuesta.info) {
            window.open(body_data.respuesta.info, "_blank");
          } else if (data && data.info) {
            window.open(data.info, "_blank");
          } else {
            this.snackBar.open('No se encontró la URL del PDF en la respuesta del servidor', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
          }
          this.loading_pdf_ = false;
          break;

        default:
          this.loading_pdf_ = false;
          this.snackBar.open('Ocurrió un error al procesar la solicitud', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          break;
      }
    } catch (error: any) {
      console.error('Error capturado:', error);
      this.loading_pdf_ = false;

      if (error && error.status) {
        switch (error.status) {
          case 500:
            this.snackBar.open('Error del servidor, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
            break;

          case 400:
            this.snackBar.open('Solicitud inválida, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
            break;

          default:
            this.snackBar.open('Error inesperado, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
            break;
        }
      } else {
        this.snackBar.open('Error inesperado, intenta de nuevo', 'Aceptar', {
          duration: 20000,
          panelClass: 'simpli-snack',
        });
      }
    }
  }


  open_agregar_facturas_dialog(){
    const dialogRef = this.dialog.open(AddFacturaInfo,{
      width: '600px',
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_sim = this.id_sim;

    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  async delete_factura(id_factura:any){
    try {

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-validar-delete-folio/'+this.id_sim+'/?factura='+id_factura, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:



          if(data.info == 3){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_factura;
            dialogRef.componentInstance.id_sim = this.id_sim;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura!"
            dialogRef.componentInstance.title = "Si lo haces, será borrada de la"
            dialogRef.componentInstance.subtitle = "operación de forma definitiva."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
                if(result == true){
                  this.get_facturas();
                  this.get_resumen_sim()
                }
            });
          }

          if(data.info == 2){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_factura;
            dialogRef.componentInstance.id_sim = this.id_sim;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura importante!"
            dialogRef.componentInstance.title = "Si lo haces, el monto total disminuirá por debajo del mínimo"
            dialogRef.componentInstance.subtitle = "necesario para operar ($3.000.000) y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_facturas();
                this.get_resumen_sim()
              }
            });
          }

          if(data.info == 1){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_factura;
            dialogRef.componentInstance.id_sim = this.id_sim;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar tu única factura!"
            dialogRef.componentInstance.title = "Considera que ya no quedarán facturas "
            dialogRef.componentInstance.subtitle = "y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_facturas();
                this.get_resumen_sim()
              }
            });
          }

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
        this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
          this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }


  async aprobar_condiciones(){
    try {

      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(3, '/cliente/simulacion-gestionar-status/'+this.id_sim+'/?status=5', );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

              this.STATUS = 5;
              this.change_step('step-simpli2')

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  async rechazar_condiciones(){
    try {

      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(3, '/cliente/simulacion-gestionar-status/'+this.id_sim+'/?status=6', );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  rechazar(){
    const dialogRef = this.dialog.open(RechazarCondicionesDialog,{
      width: '600px',
      maxWidth: '95vw',
      autoFocus:false,
      disableClose: true,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  volver_simular(){
    this.router.navigate(['/home/']);

    setTimeout(() => {
      if( $('#caja_excel').length )
      {
        $("#caja_excel").trigger('click');
      }
    });
  }


  cediendo:boolean=false;


  ceder_facturas(){
    if(this.is_responsive == true){
        const dialogRefCertificado = this.dialog.open(CargarCertificado, {
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          maxHeight: '100vh',
          hasBackdrop: false,
          panelClass: 'dialogo-full-screen-responsive-white',
          autoFocus: false,
          disableClose: false,
      });

      dialogRefCertificado.componentInstance.parent = this;
      dialogRefCertificado.componentInstance.data = this.data;
      dialogRefCertificado.componentInstance.es_mipyme = this.es_mipyme
      dialogRefCertificado.componentInstance.id_sim = this.id_sim;
      dialogRefCertificado.componentInstance.id_operation = this.id_operacion;
      dialogRefCertificado.componentInstance.cantidad_facturas = this.pageLength;
      dialogRefCertificado.componentInstance.has_valid_aecs = this.has_valid_aecs;
      dialogRefCertificado.componentInstance.onCederByAEC = this.onCederByAEC;


      dialogRefCertificado.afterClosed().subscribe((result:any) => {
        this.get_resumen_sim();
        if(result == 'ceder_por_simpli'){
          this.ceder_final()
        }
      });
    }else{
      const dialogRefCertificado = this.dialog.open(CargarCertificado,{
        width: '700px',
        disableClose: true,
        maxWidth: '95vw',
        autoFocus:false,
      });

      dialogRefCertificado.componentInstance.parent = this;
      dialogRefCertificado.componentInstance.data = this.data;
      dialogRefCertificado.componentInstance.es_mipyme = this.es_mipyme
      dialogRefCertificado.componentInstance.id_sim = this.id_sim;
      dialogRefCertificado.componentInstance.id_operation = this.id_operacion;
      dialogRefCertificado.componentInstance.cantidad_facturas = this.pageLength;
      dialogRefCertificado.componentInstance.has_valid_aecs = this.has_valid_aecs;
      dialogRefCertificado.componentInstance.onCederByAEC = this.onCederByAEC;

      dialogRefCertificado.afterClosed().subscribe((result:any) => {
        this.get_resumen_sim();
          if(result == 'ceder_por_simpli'){
            this.ceder_final()
          }
      });
    }

  }

  clave_pfx:any;
  rut_autorizado:any;
  file_pfx:any;
  falta_certificado:boolean=false;
  data:any;
  async get_credenciales(){
    try {



      const bd: any = await this.settings.get_query(1, '/cliente/obtener-credenciales-sii/'+this.id_company+'/', );
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:

        this.data = body_data.success.info;

        this.rut_autorizado = data.rut_autorizado;
        this.clave_pfx = data.clave_pfx;
        this.file_pfx = data.archivo_pfx;

        if (this.file_pfx != null && this.file_pfx != "" && this.file_pfx != undefined) {
          this.falta_certificado = false;
        } else {
          this.falta_certificado = true;
          break;
        }

        if (this.clave_pfx != null && this.clave_pfx != "" && this.clave_pfx != undefined) {
          this.falta_certificado = false
        } else {
          this.falta_certificado = true;
          break
        }

        if (this.rut_autorizado != null && this.rut_autorizado != "" && this.rut_autorizado != undefined) {
          this.falta_certificado = false
        } else {
          this.falta_certificado = true;
          break
        }

        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }


  onCederByAEC: boolean = false;
  async onFeatureAec() {
    try {
      const body_data : any = await this.settings.get_query(1,`/cliente/cesiones/${this.id_company}/available-cesion-by-aec/`)
      const _body : any = body_data._body

      this.onCederByAEC = _body.success.data.feature_on
    } catch (error: any) {

      throw new Error(error);
    }
  }

  es_mipyme:boolean=false;
  async get_facturador(){
    try {


      const bd: any = await this.settings.get_query(1, 'facturador-registro/'+this.id_company+'/', );
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:

            if(data.success == "MiPyme"){
              this.es_mipyme = true;
            }else{
              this.es_mipyme = false;
            }

        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  volver_a_simular(){
    this.router.navigate(['/home/']);

    setTimeout(() => {
      if( $('#caja_excel').length )
      {
        $("#caja_excel").trigger('click');
      }
    });
  }

  async get_estado_op(){
    try {


      const bd: any = await this.settings.get_query(1, '/cliente/estadoFinalOperaciones/'+this.id_sim+'/?company_id='+this.id_company, );
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:



            setTimeout(() => {
              this.dataSource2 = new MatTableDataSource(data.success.info);
              this.dataSource2._updateChangeSubscription()
            });

        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  async ceder_final(){
    try {


      const bd: any = await this.settings.get_query(2, 'simulaciones/cesion/'+this.id_sim+'/ceder-facturas/', {} );
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:


          this.change_step('step-simpli3');
          this.get_estado_op();


        break;
        default:

        break;
      }

    } catch (bd:any) {
      switch (bd.status) {

        case 500:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }

    }
  }

  error_generico(){
    const dialogRef = this.dialog.open(AlertErrorCesion,{
      width: '800px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus:false,
    });


      dialogRef.componentInstance.paso == 1;
      dialogRef.componentInstance.subtitle = "<strong>¡Algo ocurrió!</strong> El sistema del SII está más lento de lo normal. Inicia el proceso otra vez y si el problema persiste, contáctanos al <strong>+56 9 3467 7786.</strong>"


    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.error_certificado = true;
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }


  contactar(){
    $("#chatEjecutivo").trigger('click');
  }

  return(){
    this._location.back();
  }


}
