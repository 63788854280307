import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Pipe, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UpdateAccountCompanyDialog } from './actualizar-ctas/updateacc.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;
import { CreateAccCompanyDialog } from './createacc.component';
declare var $ :any;


@Component({
  selector: 'ctas',
  templateUrl: './ctas.html',
  styleUrls: ['./ctas.less']
})


export class CuentasBancarias implements OnInit, OnChanges {

  role: any;

  @Input() id_company:any;
  //0 no actualizar 1 actualizar
  @Input() actualizar = 0;
  @Input() se_abrio_como_dialogo:boolean=false;
  has_accounts: boolean = false;
  sw_edit = true;
  all_accounts: any[] = [];

  valForm:FormGroup;

  @ViewChild('parent_accounts', { read: ViewContainerRef }) container_accounts!: ViewContainerRef;
  
  constructor(private _cfr: ComponentFactoryResolver, public dialog: MatDialog, public snackBar: MatSnackBar, fb: FormBuilder, public settings: SettingsService,private _formBuilder: FormBuilder ) {


      this.valForm = this._formBuilder.group({
        filter_cta: [''],
      });
    
  }

  ngOnChanges(changes: SimpleChanges){
    console.log("actualizar cambio: ", this.actualizar)
    if(this.actualizar == 1){
      this.reloadAccounts();
      this.actualizar = 0;
    }
  }


  ngOnInit() {
    this.role = localStorage.getItem("role")
    this.load_accounts()
  }

  filtro="";
  async load_accounts() {
    try {
      const bd: any = await this.settings.get_query(1, 'cuentasAcreedoresBank/' + this.id_company + "/?filtro=" +this.filtro);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        if (body_data.accounts.length == 0) {
          this.has_accounts = true;
        }
        else {
          this.has_accounts = false;
        }

        for (let i = 0; i < body_data.accounts.length; i++) {
          var comp = this._cfr.resolveComponentFactory(UpdateAccountCompanyDialog);

          var dComponent = this.container_accounts.createComponent(comp);


          dComponent.instance.sw_edit = this.sw_edit
          dComponent.instance.id = body_data.accounts[i].id
          dComponent.instance.banco = body_data.accounts[i].bank.name
          dComponent.instance.proveedor = body_data.accounts[i].name
          dComponent.instance.email = body_data.accounts[i].emailAcreedor;
          dComponent.instance.load_data = false;

          if (body_data.accounts[i].is_primary) {
            dComponent.instance.star = "star"
            dComponent.instance.star_class = "add_primary";
            dComponent.instance.is_primary_text = body_data.accounts[i].type.name + " - Cuenta Principal";
            dComponent.instance.disabled_delete = true;
          }
          else {
            dComponent.instance.star = "star_border"
            dComponent.instance.star_class = "add_primary_off";
            dComponent.instance.is_primary_text = body_data.accounts[i].type.name;
            dComponent.instance.disabled_delete = false;
          }

          this.role = localStorage.getItem("role")
          if (this.role == "teso") {
          dComponent.instance.disabledButton = true;
          //dComponent.instance.has_accounts = false;
          } else {
             dComponent.instance.disabledButton = false;
          }

          dComponent.instance.info = this;
          dComponent.instance.id_company = this.id_company;

          dComponent.instance.run = body_data.accounts[i].rut;
          dComponent.instance.number = body_data.accounts[i].number;
          dComponent.instance.is_tercero = body_data.accounts[i].is_tercero;
          console.log(body_data.accounts[i].is_tercero);

          dComponent.instance.full_name = body_data.accounts[i].name;

          dComponent.instance.bank_selected = body_data.accounts[i].bank.id;

          dComponent.instance.account_selected = body_data.accounts[i].type.id;

          this.all_accounts.push(dComponent)
        }

        setTimeout(function () { $("#parent_accounts").addClass("accordion_show"); }, 200);


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  buscar_cta(){
    let filtro = this.valForm.controls['filter_cta'].value;
    this.filtro = filtro;
    this.reloadAccounts();
  }

  reset_cta($e:any){
    if($e.target.value == ""){
      this.filtro = "";
      this.reloadAccounts();
    }
  }

  reloadAccounts() {
    $("#parent_accounts").removeClass("accordion_show");

    this.container_accounts.remove();

    for (let i = 0; i < this.all_accounts.length; i++) {
      this.all_accounts[i].destroy()
    }
    this.load_accounts();
  }

  create_account() {
    const dialogRef = this.dialog.open(CreateAccCompanyDialog);
    dialogRef.componentInstance.info = this;
    dialogRef.componentInstance.id_company = this.id_company;
    dialogRef.afterClosed().subscribe((result:any) => { });
  }




}


