<div class="row row_main" style="background: white;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="!hasInfo">
        <app-sin-registros [spin]="false"></app-sin-registros>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="hasInfo">
        <div class="table-responsive-simpli2 add_factoring">
            <table mat-table [dataSource]="dataSource" style="background: white;">

                <ng-container matColumnDef="periodo">
                    <th mat-header-cell *matHeaderCellDef>Periodo</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input" value="{{ element.fecha | date : 'MMMM yyyy' }}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> Promedio </td>
                </ng-container>

                <ng-container matColumnDef="facEmitidas">
                    <th mat-header-cell *matHeaderCellDef>Fac. Emitidas</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.facEmitidas) }}" matTooltip="Ver Detalle" (click)="verDetalles('ventas', element.dialogDate, element.facEmitidas)" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> $ {{formatMoney(promedios.promedio_facEmitidas)}} </td>
                </ng-container>

                <ng-container matColumnDef="ventas">
                    <th mat-header-cell *matHeaderCellDef>Ventas</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input" value="$ {{ formatMoney(element.ventas) }}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> $ {{formatMoney(promedios.promedio_ventas)}} </td>
                </ng-container>

                <ng-container matColumnDef="compras">
                    <th mat-header-cell *matHeaderCellDef>Compras</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.compras) }}"matTooltip="Ver Detalle" (click)="verDetalles('compras', element.dialogDate, element.compras)" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> $ {{formatMoney(promedios.promedio_compras)}} </td>
                </ng-container>


                <ng-container matColumnDef="notasCredito">
                    <th mat-header-cell *matHeaderCellDef>Notas de credito</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.notasCredito) }}" matTooltip="Ver Detalle" (click)="verDetalles('notascredito', element.dialogDate, element.notasCredito)" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> $ {{formatMoney(promedios.promedio_notasCredito)}} </td>
                </ng-container>

                <ng-container matColumnDef="ventaEfectiva">
                    <th mat-header-cell *matHeaderCellDef>Venta Efectiva</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input" value="$ {{ formatMoney(element.ventaEfectiva) }}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> $ {{formatMoney(promedios.promedio_ventaEfectiva)}} </td>
                </ng-container>

                <ng-container matColumnDef="ventasiva">
                    <th mat-header-cell *matHeaderCellDef>Ventas Iva-$M</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.ventasIva) }}"  readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> $ {{formatMoney(promedios.promedio_ventaIva)}} </td>
                </ng-container>

                <ng-container matColumnDef="ventasExportacion">
                    <th mat-header-cell *matHeaderCellDef>Ventas Exportación</th>
                    <td mat-cell *matCellDef="let element">
                        <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.ventasExportacion) }}" matTooltip="Ver Detalle" (click)="verDetalles('ventasExportacion', element.dialogDate, element.ventasExportacion)" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;" class="promedio"> $ {{formatMoney(promedios.promedio_ventaExportacion)}} </td>
                </ng-container>        

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="text-center"></tr>
                <tr mat-row *matRowDef="let row; let index = index, columns: displayedColumns" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </div>
</div>


