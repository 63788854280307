import {Component, ViewChild, OnInit, ViewContainerRef, Input, ComponentFactoryResolver} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;

@Component({
  selector: 'documentos',
  templateUrl: "./documentos.html",
  styleUrls: ['./documentos.less']
})
export class Documentos implements OnInit {

  arr_financieros = ["0.1","0.2","0.3"]
  arr_legales = ["1.2", "1.20", "1.8", "1.21", "1.22"] //aqui siendo consticion, antecedentes legales, informe, contrato, y contrato legalizado
  arr_otros = ["2.1"]

  @Input() id_company:any;

  all_legales:any[] = [];
  all_financieros:any[] = [];
  all_otros:any[] = [];
  rol:any;

  constructor(private _cfr: ComponentFactoryResolver, public dialog: MatDialog, fb: FormBuilder, 
    public settings: SettingsService, 
    public snackBar: MatSnackBar)
     {


    


  }



ngOnInit(){
  this.rol = localStorage.getItem('role');
}





}
