<div class="row row_main bottom-responsive">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;">
    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top responsive-mode">
        <h1 class="titulo-simpli" style="margin-top: 0px !important;">Revisa el detalle de tus facturas</h1>
        <!-- <small class="subtitulo-simpli" *ngIf="continue_mode == true"><span style="cursor: pointer;text-decoration: underline;color: var(--simpli-primary);" (click)="que_es_reemitir()">Descubre cómo reemitir las facturas eliminadas</span> o continúa con la cesión</small> -->
        <!-- <br *ngIf="continue_mode == true"><br *ngIf="continue_mode == true"> -->
        <div class="row" *ngIf="show_reemitir == true">
          <div  class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="box-error">
              <div class="box-error-flex">
                  <div style="margin-right: 20px;">
                      <div>
                          <i style="font-size: 25px;" class="fa-regular fa-triangle-exclamation"></i>
                      </div>
                  </div>
        
                  <div >
                    ¡Operación activada! <span style="text-decoration: underline;cursor: pointer;color:var(--simpli-primary);" (click)="que_es_reemitir()">Reemite</span> y vuelve a agregar las facturas eliminadas o continúa con la cesión.
                  </div>
        
              </div>
        
            </div>
          </div>
        </div>

        <small class="subtitulo-simpli">
          <span style="margin-right: 40px;" class="block-res">N° de operación: <strong>{{id_op}}</strong></span>
          <span style="margin-right: 40px;" class="block-res">Monto total: <strong>${{formatMoney(monto_girar)}}</strong></span>
        </small>
      </div>
    </div>
  </div>



  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

  <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <div class="row">

              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>

              <div  class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

                  <div class="row">

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"> 
                          <form [formGroup]="valFormFacturas" class="form-class">
  
                              <mat-form-field appearance="fill" style="width: 100%">
                                <mat-label>Buscar</mat-label>
                                <input matInput (keyup.enter)="add_filter($event.target.value);$event.stopPropagation();" (change)="add_filter($event.target.value);$event.stopPropagation();" formControlName="filter">
                                <mat-icon matSuffix class="icon-suffix">search</mat-icon>
                              </mat-form-field>
                        
                            </form>
                      </div>

                  </div>

              </div>


  
          </div>
        </div>
      
      


  </div>

  <div class="row animate__animated animate__fadeIn" *ngIf="loading == true && ELEMENT_DATA.length == 0">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="container-loading">


              <div class="img-loading">
                <img src="../../../../../assets/simpli/cesion/loading.png" class="img-loading1">
              </div>
        
              <div style="margin-top: 20px;">
                <mat-label class="label-loading">
                  <div>La información está siendo procesada.</div>
                  <div>Esto no tomará mucho tiempo.</div>
                </mat-label>
              </div>
        
            </div>
      </div>

  </div>

  <div class="row animate__animated animate__fadeIn" *ngIf="loading == false && ELEMENT_DATA.length > 0">

      <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <mat-card class="card-tabla desktop-table" style="position: relative;">

              <div class="table-responsive-simpli add_factoring">
          
                  <table mat-table [dataSource]="dataSource">
              

                  <ng-container matColumnDef="folio" sticky>
                      <th mat-header-cell *matHeaderCellDef>N° Factura</th>
                      <td mat-cell *matCellDef="let data" style="max-width: 50px !important;"> 
                          <input class="no-input" readonly value="{{data.folio}}">
                      </td>
                  </ng-container>
              
                  <ng-container matColumnDef="deudor">
                      <th mat-header-cell *matHeaderCellDef>Deudor</th>
                      <td mat-cell *matCellDef="let data" style="min-width: 150px !important;"> 
                          <input *ngIf="data.deudor != null" class="no-input" readonly value="{{data.deudor}}">
                          <input *ngIf="data.deudor == null" class="no-input" readonly value="--">
                      </td>
                  </ng-container>
              
                  <ng-container matColumnDef="rut">
                      <th mat-header-cell *matHeaderCellDef>RUT</th>
                      <td mat-cell *matCellDef="let data" style="min-width: 120px !important;"> 
                      <input class="no-input" readonly value="{{data.rut}}">
                      </td>
                  </ng-container>
      
                  <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef>Monto</th>
                      <td mat-cell *matCellDef="let data" style="min-width: 120px !important;"> 
                        <input class="no-input" readonly value="${{formatMoney(data.monto)}}">
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef>Estado</th>
                      <td mat-cell *matCellDef="let data" style="min-width: 140px !important;"> 
                        <input class="no-input" readonly value="{{data.estado}}">
                      </td>
                  </ng-container>
              
                  <ng-container matColumnDef="motivo">
                      <th mat-header-cell *matHeaderCellDef style="background: #924eff26 !important;">motivo</th>
                      <td mat-cell *matCellDef="let data" style="min-width: 120px !important;background: #924eff26 !important;"> 
                        <input class="no-input" readonly value="{{data.motivo}}">
                      </td>
                  </ng-container>


                  <ng-container matColumnDef="detalle">
                    <th mat-header-cell *matHeaderCellDef style="background: #924eff26 !important;">Detalle</th>
                    <td mat-cell *matCellDef="let data" style="min-width: 120px !important;background: #924eff26 !important;"> 
                      <input class="no-input" readonly value="{{data.detalle}}">
                    </td>
                </ng-container>

      
              
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="background: white !important;border-radius: 4px !important;"></tr>
              
              
                  </table>
          
          
          
              </div>

          </mat-card>

          <mat-card class="card-tabla resposive-table" style="position: relative;">

              <table mat-table [dataSource]="dataSource" class="table-res">
        
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let data; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
        
                    <div [ngClass]="{'box-factura':true}" (click)="$event.stopPropagation()">
        

                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                            <div class="container-label-res"><mat-label class="left-label">N° Factura:</mat-label><mat-label class="right-label">{{data.folio}}</mat-label></div>
                            <div class="container-label-res"><mat-label class="left-label">Deudor:</mat-label><mat-label class="right-label">{{data.deudor}}</mat-label></div>
                            <div class="container-label-res"><mat-label class="left-label">RUT:</mat-label><mat-label class="right-label">{{data.rut}}</mat-label></div>
                            <div class="container-label-res"><mat-label class="left-label">Monto:</mat-label><mat-label class="right-label">${{formatMoney(data.monto)}}</mat-label></div>

                            <div class="container-label-res"><mat-label class="left-label">Estado:</mat-label>

                              <mat-label style="position: relative;" class="right-label">
                                  {{data.estado}}
                              </mat-label>

                            </div>

                            <div class="container-label-res"><mat-label class="left-label">Motivo:</mat-label>

                              <mat-label style="position: relative;" class="right-label">
                                  {{data.motivo}}
                              </mat-label>

                            </div>

                            <div class="container-label-res"><mat-label class="left-label">Detalle:</mat-label>

                              <mat-label style="position: relative;" class="right-label">
                                  {{data.detalle}}
                              </mat-label>

                            </div>
        
                          </div>
                        </div>
                    </div>
                    
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRes"></tr>
                <tr mat-row *matRowDef="let row; let doc; columns: displayedColumnsRes;" ></tr>
              </table>
              
        
          </mat-card>

          <div class="responsive-mode" style="text-align: right;margin-top: 20px;" *ngIf="show_reemitir == false && continue_mode == false">
            <button class="primary-button" mat-flat-button (click)="reemitir()">
              Eliminar facturas no cedidas
            </button>
      
            <button class="secondary-button" mat-flat-button (click)="return()">
              Ver mis operaciones
            </button>
          </div>

          <div class="responsive-mode" style="text-align: right;margin-top: 20px;" *ngIf="show_reemitir == true && continue_mode == false">
            <button class="primary-button" mat-flat-button (click)="reemitir()">
              Facturas reemitidas
            </button>
      
            <button class="secondary-button" mat-flat-button (click)="continuar_cesion()">
              Continuar cesión
            </button>
          </div>

          <div class="responsive-mode" style="text-align: right;margin-top: 20px;" *ngIf="continue_mode == true">
            <button class="primary-button" mat-flat-button (click)="reemitir()">
              Eliminar facturas no cedidas
            </button>
      
            <button class="secondary-button" mat-flat-button (click)="return()">
              Ver mis operaciones
            </button>
          </div>

      </div>

  </div>

  <div style="margin-top: 20px;margin-bottom: 20px;">
    <numbered-pagination [pageSize]="7"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
  </div>
    


  </div>

</div>