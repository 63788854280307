
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


declare var $: any;


@Component({
  selector: 'app-que_es_reemitir',
  templateUrl: './que_es_reemitir.html',
  styleUrls: ['./que_es_reemitir.less']
})
export class QueEsReemitir implements OnInit {

  step=1;
  show_info:boolean=false;

  constructor(private dialogRef:MatDialogRef<QueEsReemitir>) {



   }



  ngOnInit() {


  }

  set_step(){
    this.step = 2
  }

  g_aec() {
    if(this.show_info == true){
      this.dialogRef.close(2)
    }else{
      this.dialogRef.close(true)
    }

  }


}