<!-- <mat-tab-group>

  <mat-tab> 
    <ng-template mat-tab-label>
      Facturas Recibidas SII 
      <i style="margin-left: 10px;" 
      class="fa-light fa-info-circle" 
      matTooltip="Selecciona tus facturas para añadir a tus pagos">
      </i>
    </ng-template> -->

    <app-facturas-compra [id_simulacion]="id_simulacion"></app-facturas-compra>
<!-- 
  </mat-tab>

  <mat-tab> 

    <ng-template mat-tab-label>
      Carga Manual 
      <i 
      style="margin-left: 10px;" 
      class="fa-light fa-info-circle" 
      matTooltipClass='red-tooltip' 
      matTooltip="Copia y pega en la tabla información de tus facturas o descarga la plantilla excel y súbela directamente con la información para emitir tus pagos">
      </i>
    </ng-template>
    
    <app-facturas-compra-manual [id_simulacion]="id_simulacion"></app-facturas-compra-manual>
    
  </mat-tab>

</mat-tab-group>  -->