import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';



@Component({
  selector: 'app-filtro-clientes',
  templateUrl: './filtro-clientes.html',
  styleUrls: ['./filtro-clientes.scss'],
})
export class FiltroClientesComponent implements OnInit {

  @Input() items: Item[] = [];
  @Input() selectedItems: string[] = [];
  @Input() title = 'Select Items';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<string[]>();

  filteredItems: Item[] = [];
  workingSelectedValues: string[] = [];

  constructor(private modalCtrl:MatBottomSheet, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, @Inject(MAT_BOTTOM_SHEET_DATA) public data_selected: any){

    this.items = data.facturas;
    this.selectedItems = data.data;

  }

  ngOnInit() {
    this.filteredItems = ([...this.items]).slice(0, 6);;
    this.workingSelectedValues = [...this.selectedItems];
  }

  trackItems(index: number, item: Item) {
    return item.deudor;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }

  confirmChanges() {
    this.modalCtrl.dismiss(this.workingSelectedValues);
  }

  alternar_msg:boolean=false;
  searchbarInput(ev:any) {
    this.filterList(ev.target.value);
  }

  filterList(searchQuery: string | undefined) {

    if(searchQuery == ""){
      this.alternar_msg = false;
    }else{
      this.alternar_msg = true;
    }

    if (searchQuery === undefined) {
      this.filteredItems = ([...this.items]).slice(0, 6);;
    } else {
      const normalizedQuery = String(searchQuery).toLowerCase();
      this.filteredItems = this.items.filter((item) => {
        return String(item.deudor).toLowerCase().includes(normalizedQuery);
      }).slice(0,6);
    }
  }

  isChecked(deudor: string) {
    return this.workingSelectedValues.find((item) => item === deudor);
  }

  checkboxChange(ev:any) {
    
    const selected = ev.options[0]._selected;
    const value = ev.options[0]._value;

    if (selected) {
      this.workingSelectedValues = [...this.workingSelectedValues, value];
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter((item) => item !== value);
    }
  }


  cancel() {
    return this.modalCtrl.dismiss();
  }

  selecciono_lista($event:any){
    console.log($event.source._value)
  }



}


export interface Item {
  deudor: string;
  value: string;
}