import { Injectable, OnDestroy } from '@angular/core';
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig,
} from 'rxjs/webSocket';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { switchMap, delay, retry, retryWhen, takeUntil, catchError, delayWhen } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';

export interface IMessage {
  action: any;
  command: string;
  args: any;
}

export interface INotification {
  room_id: string;
  date_created: string;
  delivery: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService implements OnDestroy {
  private socket$!: WebSocketSubject<any>;
  public isConnected$ = new BehaviorSubject<boolean>(false);
  public maxReachedConn$ = new BehaviorSubject<boolean>(false);
  private messageQueue: any[] = [];
  private reconnectTrigger$ = new Subject<void>();
  private pendingMessages$ = new BehaviorSubject<any[]>([]);
  private reconnectIntervalId: any;

  private destroy$ = new Subject<void>();

  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectInterval: number = 5000;

  constructor(private settings: SettingsService) {
    this.initializeWebSocket();
  }

  private initializeWebSocket() {

    // Inicializa la conexión WebSocket
    const token = window.localStorage.getItem('token') || '';
    // this.socket$ = webSocket({
    //   url: `${SettingsService.WS_URL}/?token=${token}`,
    //   openObserver: {
    //     next: (value) => {

    //       this.isConnected$.next(true);
    //       this.processMessageQueue();

    //       if (this.reconnectIntervalId) {
    //         clearInterval(this.reconnectIntervalId);
    //         this.reconnectIntervalId = null;
    //       }
    //       this.reconnectAttempts = 0;
    //       this.maxReachedConn$.next(false)
    //     },
    //   },
    //   closeObserver: {
    //     next: (error: any) => {
    //       this.isConnected$.next(false);
   
    //       // this.scheduleReconnect();
    //       if (this.reconnectAttempts < this.maxReconnectAttempts) {
    //         this.reconnectAttempts++;

    //         // Aumentar gradualmente el intervalo de reconexión (puedes personalizar esto)
    //         this.reconnectInterval *= 2;

    //         // Iniciar el intervalo de reconexión
    //         if (!this.reconnectIntervalId) {
    //           this.reconnectIntervalId = setInterval(
    //             () => this.initializeWebSocket(),
    //             5000
    //           );
    //         }
    //       } else {
    //         this.maxReachedConn$.next(true)
    //         clearInterval(this.reconnectIntervalId)

    //       }
    //     },
    //   },
    // });

    // this.socket$.subscribe({
    //   complete: () => {

    //   },
    // });


    this.socket$ = webSocket({
      url:  `${SettingsService.WS_URL}/?token=${token}`,
    })


    this.socket$.pipe(
      takeUntil(this.destroy$),
      catchError((error) => {

        this.isConnected$.next(false);

        if(this.reconnectAttempts < this.maxReconnectAttempts) {
          this.reconnectAttempts++;
          return timer(this.reconnectInterval);
        } else {
          return throwError(() => new Error('Max reconnect attemps reached'))
        }
      }),
      delayWhen(() => timer(this.reconnectInterval))
    ).subscribe({
      next: (value) => {

        this.isConnected$.next(true)
        this.reconnectAttempts = 0
        this.reconnectInterval = 5000
        this.processMessageQueue()
      },
      error: (err) => {

      }
    })
  }

  private processMessageQueue() {
    while (this.messageQueue.length > 0 && this.isConnected$.value) {
      const message = this.messageQueue.shift();
      this.sendMessage(message);
      this.pendingMessages$.next([]);
    }
  }

  private scheduleReconnect() {
    // this.reconnectTrigger$.pipe(
    //     switchMap((value) => {

    //       this.isConnected$.next(false);
    //       return this.socket$.pipe(
    //         retry(100),
    //         delay(10000)
    //       );
    //     })
    //   )
    //   .subscribe((value) => {

    //     this.socket$.complete();
    //     this.initializeWebSocket();
    //     // Dispara el reconectador
    //   });
    //   this.reconnectTrigger$.next();
  }

  public sendMessage(message: any): void {

    if (this.isConnected$.value) {
      this.socket$.next(message);
    } else {
      // Almacena el mensaje para reintentar más tarde
      this.messageQueue.push(message);
      this.pendingMessages$.next(this.messageQueue.slice());
    }
  }

  public clearPendingMessages(): void {
    this.messageQueue = [];
    this.pendingMessages$.next([]);
  }

  public getPendingMessages(): Observable<any[]> {
    return this.pendingMessages$.asObservable();
  }

  public onMessage(): Observable<any> {
    return this.socket$.asObservable();
  }

  public isConnected(): Observable<boolean> {
    return this.isConnected$.asObservable();
  }

  public getMaxReachedConnections(): Observable<boolean> {
    return this.maxReachedConn$.asObservable();
  }

  public close(): void {
    this.socket$.complete();
  }

  ngOnDestroy(): void {
    this.clearPendingMessages();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public Unsuscribe() {
    return this.socket$.unsubscribe()
  }
}
