<mat-card class="mat-card-simulacion add_factoring">

  <mat-card-title>Resumen de simulación</mat-card-title>
  <mat-card-subtitle >Revisa el detalle de tu simulación</mat-card-subtitle>
  <mat-card-content style="position: relative">
  
  <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
    <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
  </div>
  

    <div class="row col-top">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <small><input class="no-input" value="95% Anticipo de Factura" readonly></small>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="$ {{this.formatMoney(this.simulation_venta.anticipo)}}" readonly style="text-align: right;">
      </div>  
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="Vencimiento promedio" readonly>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="{{simulation_venta.vencimiento}} Días" readonly style="text-align: right;">
      </div>
    </div>
    <mat-divider class="separador5"></mat-divider>
    <div class="row col-top">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="Comisión" readonly>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="$ {{formatMoney(this.simulation_venta.comision)}}" readonly style="text-align: right;">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="Gastos Operaciones" readonly>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="$ {{formatMoney(this.simulation_venta.gastosOperacionales)}}" readonly style="text-align: right;">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="IVA" readonly>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="$ {{this.formatMoney(this.simulation_venta.iva_operaciones)}}" readonly style="text-align: right;">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="Diferencia de Precio" readonly>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input class="no-input" value="$ {{formatMoney(simulation_venta.interesTotal)}}" readonly style="text-align: right;">
      </div>
    </div>
    <mat-divider class="separador5"></mat-divider>
    <div class="row" style="margin-top: 5px;">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro total" readonly>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input  class="no-input" value="$ {{formatMoney(simulation_venta.liquidoRecibir)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a proveedores" readonly>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input  class="no-input" value="$ {{formatMoney(this.deuda_total)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input *ngIf="debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a tu cuenta" readonly>
        <input *ngIf="!debe_pagar" style="font-weight: bold;font-size: 15px;color: #626262;" class="no-input" value="Giro a pagar a Arrayán" readonly>
      </div> 
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <input  class="no-input" value="$ {{formatMoney(por_pagar)}}" readonly style="text-align: right;font-weight: bold;font-size: 16px;color: #626262;">
      </div>
    </div>
  </mat-card-content>
</mat-card>
