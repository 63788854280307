import { Component, Input } from '@angular/core';

@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrl: './skeleton-loader.component.less'
})


export class SkeletonLoaderComponent {

  @Input() show_avatar:boolean=false;
  @Input() show_line:boolean=true;
  @Input() show_line2:boolean=false;

}
