<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Administración de pagos</h1>
    <small class="subtitulo-simpli">Asigna fecha probable de pago a tus compras y ordena tu flujo de liquidez</small>
  </div>



  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;" >

    <div *ngIf="disabled" class="velo-inactivo animate__animated animate__fadeIn" style="background: #ffffffde;height: 100%;">
        <mat-spinner diameter="40" class="spinner-simpli" style="margin-top: 200px;"></mat-spinner>
        <div class="show-m">
            Sincronizando con el SII, esto puede llevar unos minutos, estamos actualizando tus facturas! :)
        </div>
    </div>

    <mat-card class="shadow-box card-tabs">
      <mat-card-content>

        <div class="w-100">

          <form [formGroup]="firstFormGroup" class="w-100" style="min-height: 375px;">
            <div class="row w-100">
                <div  class="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-top">

                  <form [formGroup]="valForm">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <mat-form-field>
                          <mat-label>Filtrar</mat-label>
                          <input matInput formControlName="filter" (change)="reset($event.target.value)">
                          <mat-icon matTooltip="Buscar" matSuffix class="btn_suffix" (click)="filtrar()">search</mat-icon>
                          <mat-hint>Introduce una palabra clave y dale click a la lupa para filtrar.</mat-hint>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <mat-form-field>
                          <mat-label>Desde</mat-label>
                          <input matInput [matDatepicker]="picker3" (dateChange)="filter1($event)" formControlName="desde" readonly>
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <mat-form-field>
                          <mat-label>Hasta</mat-label>
                          <input matInput [matDatepicker]="picker4" (dateChange)="filter2($event)" formControlName="hasta" readonly>
                          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                          <mat-datepicker #picker4></mat-datepicker>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <mat-form-field>
                          <mat-label>Tipo de fecha</mat-label>
                          <mat-select formControlName="type" multiple (selectionChange)="type_filter($event.value)">
                            <mat-option *ngFor="let type of typeList" [value]="type.value">{{type.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>


                    </div>
                  </form>

                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-top" style="text-align: right;">
                  <button  mat-stroked-button  class="stroked-btn" color="primary" id="btn_llamativo" (click)="sincronizar()" type="button">Sincronizar SII</button>
                  <button  mat-stroked-button  class="stroked-btn" (click)="reset_filters()" type="button">Limpiar filtros</button>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn"  *ngIf="insertar_vencimiento" style="display: none;">

                  <div class="row">

                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                          <mat-form-field>
                              <mat-label>Fecha de vencimiento común</mat-label>
                              <input matInput formControlName="secondCtrl"  [min]="minDate"  [matDatepicker]="picker2" (dateChange)="fecha_todas($event)" (click)="picker2.open()" style="text-decoration: none !important;" readonly>
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                              <mat-hint>*Opcional</mat-hint>
                          </mat-form-field>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                          <mat-form-field style="line-height: unset !important;height: auto !important;">
                              <mat-label>Plazo de vencimiento</mat-label>
                              <input matInput #fecha_todas_dias id="fecha_todas_dias"  (change)="capturar_fecha_por_dias($event)">
                              <mat-hint>*Opcional</mat-hint>
                          </mat-form-field>

                      </div>

                  </div>

                </div>

            </div>
            <div class="table-responsive-simpli add_factoring" *ngIf="infotable" style="margin-top: 20px;">

                <table mat-table [dataSource]="dataSourceFacturas" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc">

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef matTooltip="Se marcarán todas facturas de la página en visualización, de 20 en 20.">

                    <mat-checkbox (change)="$event ? masterToggle($event) : null"  (checked)="checkall" formControlName="checkall" (click)="activa_final(); $event.stopPropagation()">
                    </mat-checkbox>



                    </th>
                    <td mat-cell *matCellDef="let row, let index=index" style="text-align: center;">
                    <div style="border-radius: 3px;transition: all .3s ease-in-out;">

                        <input type="checkbox" [disabled]="row.pagado || row.aceptacionReclamo == 0 || row.estado == 'Contado/Gratuito'" [checked]="row.checked==true? row.checked:selection.isSelected(row)"
                        (click)="activa(row.id,row.nombre, $event, row); $event.stopPropagation();index">
                    </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                <ng-container matColumnDef="folio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header" style="text-align: left;">Folio</th>
                    <td mat-cell *matCellDef="let element;" style="min-width: 100px; max-width: 130px; text-align: left;" >
                        <input class="no-input" value="{{element.folio}}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="ultimoacreedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Acreedor Legal </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left; min-width: 200px;">
                        <input class="no-input" style="text-align: left;" matTooltip="{{element.ultimoacreedor + ' - ' + element.ultimorutacreedor}}" value="{{element.ultimoacreedor}}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                <ng-container matColumnDef="receptoracreedor__business_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Emisor Documento </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left; min-width: 200px;">
                        <input class="no-input" value="{{element.receptoracreedor_name}}" style="text-align: left; padding-left: 24px;" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"></td>
                </ng-container>

                <ng-container matColumnDef="mnt_total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Monto </th>
                        <td mat-cell *matCellDef="let element" style="min-width: 130px;">
                        <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;"></td>
                </ng-container>

                <ng-container matColumnDef="fecha_emision">
                    <th mat-header-cell *matHeaderCellDef>
                        <span *ngIf="fecha_emision_favorite"  matTooltip="Este campo es tu favorito para ordenar tus compras"  (click)="setFavorite('fecha_emision')">
                            <i style="cursor: pointer; color: #ff933b;padding-right: 10px;" class="fas fa-star"></i>
                        </span>

                        <span *ngIf="!fecha_emision_favorite" matTooltip="Marcar este campo como favorito para ordenar mis compras" (click)="setFavorite('fecha_emision')" >
                            <i style="cursor: pointer;padding-right: 10px;" class="fas fa-star"></i>
                        </span>
                        <span mat-sort-header style="display:inline-flex !important;">Fecha emisión</span>
                     </th>
                    <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                        <input   class="no-input" value="{{element.fecha_emision}}" readonly>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                <ng-container matColumnDef="fechapp">
                    <th mat-header-cell *matHeaderCellDef>
                        <span *ngIf="fecha_pp_favorite" matTooltip="Este campo es tu favorito para ordenar tus compras" (click)="setFavorite('fechapp')" style="padding-right: 10px;">
                            <i   style="cursor: pointer; color: #ff933b" class="fas fa-star"></i>
                        </span>

                        <span  *ngIf="!fecha_pp_favorite"  matTooltip="Marcar este campo como favorito para ordenar mis compras" (click)="setFavorite('fechapp')">
                            <i style="margin-right: 10px; cursor: pointer;"  class="fas fa-star"></i>
                        </span>
                        <span mat-sort-header style="display:inline-flex !important;" matTooltip="Agregar una fecha probable de pago es opcional">Fecha PP
                            <i  style="margin-left: 10px;" class="fas fa-info-circle"></i>
                       </span>
                    </th>

                    <td mat-cell *matCellDef="let element; let i=index; let index=index" style="position: relative;">
                        <span (click)="picker.open()" class="material-icons icon-calendar">
                            event
                        </span>
                        <input (click)="picker.open()" *ngIf="element.fechapp_str != null" style="text-align: center;" class="no-input" readonly value="{{element.fechapp_str}}">
                        <input (click)="picker.open()" *ngIf="element.fechapp_str == null" style="text-align: center;" class="no-input" readonly value="--/--/--">


                        <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                            <input matInput [min]="minDate" readonly [matDatepicker]="picker"  (dateChange)="fecha_individual_pp($event, index)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="pagado">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 130px; text-align: center;" > Pagado</th>
                    <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 130px; text-align: center;">
                        <span *ngIf="element.pagado">
                            <i matTooltip="Pagado {{element.fechapagado  | date:'dd/MM/yy'}} {{element.mediopago ? element.mediopago : ''}}" style="color:#3BA46F;cursor: pointer;" class="fa-light fa-dollar-sign"></i>
                        </span>
                        <span *ngIf="!element.pagado"  [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger">
                            <i matTooltip="Marcar como Pagado" style="color:gray;cursor: pointer;" class="fa-light fa-dollar-sign"></i>
                        </span>
                    <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                        <mat-card>
                        <mat-card-content>
                            <small>{{element.pagado ? '¿Marcar como no pagado?' : '¿Marcar como pagado?'}}</small><br>
                            <mat-form-field *ngIf="true">
                                <mat-label>Medio de pago</mat-label>
                                <mat-select [formControl]="paymentMethod">
                                  <mat-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod">{{paymentMethod}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-button class="pagar-btn" type="button" (click)="pagar(element.pagado, element.id, this.paymentMethod.value)">Aceptar</button>
                        </mat-card-content>
                        </mat-card>
                    </mde-popover>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                <!-- 0 es malo 1 es bueno 2 es no consultada -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px;text-align: center;" > Aceptación </th>
                    <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;text-align: center;">
                        <mat-icon
                        [mdePopoverTriggerFor]="reclamoPopover"
                        mdePopoverTriggerOn="hover"
                        #popoverTrigger="mdePopoverTrigger"
                        [ngClass]="{'status-icon':true,
                                    'status-icon-check':element.aceptacionReclamo == 1,
                                    'status-icon-rejected':element.aceptacionReclamo == 0}">{{ element.aceptacionReclamo == 1 ? 'check_circle': 'info'}}</mat-icon>
                    <mat-icon class="update-acep-recl">youtube_searched_for</mat-icon>



                    <mde-popover #reclamoPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                        <mat-card class="shadow-box card-reclamos">
                        <mat-card-header>
                            <h1 class="card-reclamos-title">{{ element.aceptacionReclamo == 1 ? 'Aceptada': element.aceptacionReclamo == 0 ? 'Rechadaza' : 'Sin información'  }}</h1>
                        </mat-card-header>
                        <mat-card-content class="text-center">
                            <div *ngIf="element.reclamos.length > 0">
                            <table class="w-100" cellspacing="0" >
                            <tr>
                                <td class="card-reclamos-table-header">{{ element.aceptacionReclamo == 1 ? 'Eventos': element.aceptacionReclamo == 0 ? 'Reclamos' : 'Detalles'  }}</td>
                            </tr>
                            <tr *ngFor="let i of element.reclamos" >
                                <td class="card-reclamos-table-td">
                                {{i}}
                                </td>
                            </tr>
                            </table>
                            </div>
                        </mat-card-content>
                        </mat-card>
                    </mde-popover>

                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturas; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsFacturas"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumnsFacturas"></tr>

                </table>


                <div class="row" style="width: 100%;">

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-msg">
                    <!-- <small *ngIf="show_msg_acreedor_legal" style="color: #15a83f; margin-top: 20px;display: block;">*Pasa el cursor sobre el Acreedor Legal para ver el Historial de Acreedores recientes.</small> -->
                    <!-- <small style="color: #ff933b;display: block;">*Factura por vencer.</small>
                    <small style="color: #FA454C;display: block;">*Factura vencida.</small> -->
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageCustom($event)">
                    </mat-paginator>
                </div>

                </div>


            </div>
            <div class="sinRegistros" *ngIf="!infotable && !disabled">
                <small class="small-sinregistros" style="text-align: center;margin-top: 150px;">
                    <div style="text-align: center;margin-bottom: 20px;">
                        <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 30px;"></i>
                    </div>
                    Todavía no hay historial de pago de proveedores. Simula un pago de proveedores y simplifica tus finanzas. <br>
                    <strong>Pagar Remuneraciones</strong>
                  <br />
                  <br />
                  <div style="text-align: center;">
                    <button  mat-flat-button class="primary-button" (click)="open_credenciales()" type="button">Vamos</button>
                  </div>

                </small>
            </div>

          </form>

        </div>

      </mat-card-content>
    </mat-card>
  </div>
</div>


