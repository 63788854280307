<div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: 300px;position: relative;min-width: 800px;">

  <div class="velo-inactivo animate__animated animate__fadeIn" style="background: #ffffffde;height: 200px;" *ngIf="spinner">
    <mat-spinner diameter="40" class="spinner-simpli" style="margin-top: 130px;"></mat-spinner>
  </div>



  <div class="overflow_hidden" *ngIf="!spinner" style="min-height: auto;padding-bottom: 0;">
    <h1 class="titulo-simpli" mat-dialog-title *ngIf="!spinner">{{stringfiltro}}</h1>
    <small style="font-size: 1.0em;text-transform: capitalize;" class="texto_tabla">{{this.fecha | date: 'MMMM
      yyyy'}}</small>
    <br>
    <small class="texto_tabla" style="font-size: 1.0em;">Total de {{stringfiltro}}: <span
        style="font-weight: 600;color: #3a1d6a;">$ {{formatMoney(this.datos["monto"])}}</span></small>



    <div class="row table-responsive">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 alto scroll_raking">
        <div *ngIf='noData'
          style='text-align:center;margin-top: 20px;color: #717171;background-color: #f1f1f1;border-radius: 4px;width: 100%;font-family: "DM Sans";padding: 10px;'>
          <p style="margin:0">No existen detalles de facturas para este periodo</p>
        </div>
        <table mat-table [dataSource]="dataSourceFacturas" class="table-striped" style="width:100%;padding-top: 2%;"
          *ngIf='!noData'>
          <ng-container matColumnDef="tipo">
            <th class="cabecera" mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field style="width: 98%;">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
              </mat-form-field>
            </th>

            <td mat-cell *matCellDef="let element; let i = index;"
              style="padding-bottom: 8px;border: 3px solid whitesmoke;background: white;border-top: 0;">
              <div class="row bordestop">
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 columnastop opacidad opacidad{{i}}"
                  style="text-align: inherit;">
                  <small class="titulo_tabla">Acreedor<br>
                    <div class='container-exportacion'>
                      <div class='is_exportacion' *ngIf="element.is_exportacion"
                        matTooltip="Factura de Exportación Electrónica"></div>
                      <span class="texto_tabla" #tooltip="matTooltip"
                        matTooltip="{{element.business_name}} {{element.rut}}" matTooltipPosition="right">
                        {{element.business_name.substring(0, 17) | uppercase }}</span>
                    </div>
                  </small>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnastop opacidad opacidad{{i}}">
                  <small class="titulo_tabla">Nº Folio<br>
                    <span class="texto_tabla">{{element.folio}}</span></small>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnastop opacidad opacidad{{i}}">
                  <small class="titulo_tabla">Fecha emisión<br>
                    <span class="texto_tabla">{{element.fecha_emision | date:"yyyy-MM-dd"}}</span></small>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnastop opacidad opacidad{{i}}">
                  <small class="titulo_tabla">Fecha recepción<br>
                    <span class="texto_tabla">{{element.fecha_recepcion | date:"yyyy-MM-dd"}}</span></small>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnastop opacidad opacidad{{i}}">
                  <small class="titulo_tabla">Estado<br>
                    <span class="texto_tabla" matTooltipClass="multiline-tooltip"
                      matTooltip="{{salto_linea(element.rz_cesionario, element.fecha_cesion) }}">{{element.estado}}</span></small>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnastop opacidad opacidad{{i}}">
                  <small class="titulo_tabla">Monto total<br>
                    <span class="texto_tabla">{{formatMoney(element.mnt_total)}}</span></small>
                  <span *ngIf="element.detalle" class="material-icons icono_flecha"
                    (click)="desplegar(i)">expand_more</span>
                </div>

                <div *ngIf="element.detalle" style="padding-top: 5px;"
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ocultar desplegable {{i}}_desplegable">
                  <small class="titulo_tabla" style="color: #3a1d6a;font-weight: bold;">{{stringDetalle}}</small>
                  <hr class="linea_morada">
                  <div class="row" style="padding-bottom: 1%;" *ngFor="let folio of element.folios">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-bottom: 5px;">
                      <small class="texto_tabla" style="font-size: 1.2em !important;"><span></span>
                        {{folio.business_name}}</small>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <small class="titulo_tabla">Nº Folio: <span class="texto_tabla">{{folio.folio}}</span></small>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <small class="titulo_tabla">Tipo factura: <span class="texto_tabla">{{folio.tipo}}</span></small>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <small class="titulo_tabla">Estado: <span class="texto_tabla">{{folio.estado}}</span></small>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <small class="titulo_tabla">Monto total: <span
                          class="texto_tabla">{{formatMoney(folio.mnt_total)}}</span></small>
                    </div>

                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturas"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturas;"></tr>
        </table>
        <mat-paginator #paginator [length]="this.len_info" [pageSize]="5"></mat-paginator>


        <button type="button" id="send_form" mat-flat-button (click)="dialogRef.close('N')" class="primary-button">
          <span>Cerrar</span>
        </button>

      </div>
    </div>

  </div>

</div>