<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <h1 class="titulo-simpli">Revisa tus operaciones en curso</h1>
        <small class="subtitulo-simpli">Conoce el estado actualizado de cada operación</small>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

      <tab-operaciones [tabs]="1" [parent]="this" [id_company]="id_company"></tab-operaciones>

  </div>

</div>


