import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InputDialog } from '../../input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoModalOnboarding } from 'src/app/onboarding/info-modal/info-modal';
import { CambiarEmpresa } from 'src/app/layout/cambiar-empresa/cambiar-empresa';
declare var $ :any;

@Component({
  selector: 'app-clavesii',
  templateUrl: './clavesii.html',
  styleUrls: ['./clavesii.less']
})


export class ClaveSIIComponent implements OnInit {
   
  @Input() id_company:any;
  @Input() data:any;
  @Input() token:any;
  @Input() parent:any;
  spinner_btn:boolean=false;

  rut_company = localStorage.getItem('rut');
  business_name = localStorage.getItem('business_name')


  valForm: FormGroup;

  constructor(public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService,private _formBuilder: FormBuilder, private dialogRef:MatDialogRef<ClaveSIIComponent> ){


      this.valForm = this._formBuilder.group({
        'clave_sii': ['',  Validators.compose([Validators.required])],
      });

  }

  ngOnInit() {

    this.id_company = localStorage.getItem('company_id');

    this.business_name = localStorage.getItem('business_name');
    this.rut_company = localStorage.getItem('rut');

    this.get_credenciales()

  }

  get_credenciales(){
    this.clave_sii = this.data?.clave_sii;
    if(this.clave_sii != null && this.clave_sii != "" && this.clave_sii != undefined){
      this.valForm.controls['clave_sii'].setValue(this.clave_sii)
    }
  }

  clave_sii:any;

  disable_btn:boolean=true;
  validate_key($event:any){
    if($event.target.value != undefined && $event.target.value != null && $event.target.value != ""){
      this.disable_btn = false;
    }else{
      this.disable_btn = true;
    }
  }



  //CLAVE SII
  saving_sii:boolean=false;
  success_sii:boolean=false;
  update(){

    this.dialogRef.close(true)

    this.snackBar.open("Cambio de Clave Exitoso", "Aceptar", {
      duration: 10000,
      panelClass: "simpli-snack"
    });

  }

  open_input_dialog(title:any,subtitle:any,mensajeprincipal:any, mensajenegrita:any,icon:any,coloricon:any) {
    const dialogRef = this.dialog.open(InputDialog, {
      width: '700px',
      autoFocus: false,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;

    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  // Back nuevo app cliente


  async verificar_guardar_clave_sii(validate?:any){
    try {

      this.spinner_btn = true;

      let value:any = {"sii_key": this.valForm.controls['clave_sii'].value}
      if (validate) {
        value["validate"] = true;
      }
  
      this.disable_btn = true;

      const bd: any = await this.settings.get_query(3, `cliente/validate-clave-sii/${this.id_company}/`,value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.success_sii = true;
        this.saving_sii = false;
        if (!validate) {
          this.update();
        }

        this.disable_btn = false;
        this.spinner_btn = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {


      this.valForm.controls['clave_sii'].setErrors({'incorrect': true});
      this.saving_sii = false;
      this.disable_btn = false;
      this.success_sii = false;
      this.spinner_btn = false;
      
      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

      case 400:
        let error = "Acceso bloqueado. Intente nuevamente más tarde."
        if(bd.error.error.errors.non_field_errors[0] == error){
          this.open_modal_info_disable(9); 
          this.valForm.disable();
        }


          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

    open_modal_info_disable(step:any) {
  
  
      
      let dialogRef = this.dialog.open(InfoModalOnboarding, {
        width: '700px',
        autoFocus: false,
        disableClose: false
      });
  
      dialogRef.componentInstance.step = step;

      dialogRef.afterClosed().subscribe(result => {
  
      });
  
    }

      cambiarEmpresa() {
        const dialogRef = this.dialog.open(CambiarEmpresa, {
          width: '700px',
          autoFocus: false,
        });
    
        dialogRef.componentInstance.element = this;
        dialogRef.componentInstance.se_abrio_desde_clave = true;
        dialogRef.afterClosed().subscribe((result:any) => {

        });
      }

      reset_parent(){
        console.log("reset parent")
        this.parent.ngOnInit();
        this.credencialessii();
      }


      async credencialessii(){
        try {
    
    
    
          const bd: any = await this.settings.get_query(1, `cliente/obtener-credenciales-sii/${this.id_company}/`, );
          var body_data: any = bd._body;
          switch (bd.status) {
            case 200:
    
    
    
            this.data = body_data.success.info;
    
            this.valForm.controls['clave_sii'].setValue(this.data.clave_sii);

            console.log("ACA PAPA",this.data)
    

    
            break;
            default:
    
            break;
          }
    
        } catch (bd:any) {
    

        }
      }

  
}

