import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import {Location} from '@angular/common';
import { Sort } from '@angular/material/sort';
import { FacturaDetailDialog } from '../../cliente-new-version/parrilla-cartola/factura-detail/factura-detail';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;


@Component({
  selector: 'app-parrilla-cartola-responsive',
  templateUrl: './parrilla-cartola-responsive.html',
  styleUrls: ['./parrilla-cartola-responsive.less']
})
export class ParrillaCartolaResponsiveComponent implements OnInit {


    selected: any = "";
    @Input() id_company = localStorage.getItem('company_id');
    displayedColumns: string[] = ['data'];
    ELEMENT_DATA: Array<any> = []
    dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    pageLength = 0;
    pageSize = 6;
    pageIndex = 0;
    page = 1;
    loading:boolean=true;

    @Input() parent:any;
    valFormFilter!:FormGroup;
    valFormFechas!:FormGroup;

  constructor(private _location: Location,public settings: SettingsService, 
    public dialog: MatDialog,public fb:FormBuilder, public router:Router ) { 
    this.id_company = localStorage.getItem('company_id')
    router.events.forEach((event) => {
        if(event instanceof NavigationEnd) {
          this.ngOnInit()
        }
    });

    this.valFormFilter = this.fb.group({
      filter: '',
    });
      
    this.valFormFechas = this.fb.group({
          fecha_ini: '',
          fecha_fin: '',
    });
    
  }




  return(){
    this._location.back();
  }

  ngOnInit() {

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });

    this.load_all()

  }


  load_all() {
    this.id_company = localStorage.getItem('company_id');
    window.scrollTo({top: 0, behavior: 'smooth'});

    this.get_cartola();
  }

  myObserver: any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  totales:any[]=[{'total_excedentes':0, 'total_facturas':0,'total_monto':0,'total_monto_pendiente':0,'total_mora':0}];
  async get_cartola(excel?:boolean) {

    let endpoint = `/cliente/cartola-operaciones/${this.id_company}/?page=${this.page}&filter=${this.filter}&date_ini=${this.fecha_inicio}&date_fin=${this.fecha_fin}&orientacion=${this.ordenamiento}&columna=${this.columna}`
    if(excel == true){
      endpoint = `/cliente/cartola-operaciones/${this.id_company}/?page=${this.page}&filter=${this.filter}&date_ini=${this.fecha_inicio}&date_fin=${this.fecha_fin}&orientacion=${this.ordenamiento}&columna=${this.columna}&excel=1`
    }

    try {
      this.loading = true;
      const bd: any = await this.settings.get_query(1, endpoint);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:



        if(excel == true){
          window.open(body_data.success.info, "blank");
          this.loading = false;

        }else{
          
          this.ELEMENT_DATA = body_data.success.info;
          this.totales = body_data.success.totales;
          this.pageLength =  body_data.success.totales.total;

          this.loading = false;
        }




          
          break;
        default:
            this.ELEMENT_DATA = [];
            this.loading = false;
          break;
      }
    } catch (bd: any) {

      this.ELEMENT_DATA = [];
      this.loading = false;

      this.settings.manage_errors(bd.status, "cartola", bd, false);
    }
  }

  columna = "";
  ordenamiento = "";
  sortDirection:any="asc"
  sortData(sort: Sort) {




    this.columna = sort.active;
    this.ordenamiento = sort.direction;


    if(sort.direction == "asc"){
      this.sortDirection = "desc"
    }else if(sort.direction == "desc"){
      this.sortDirection = "asc";
    }else{
      this.sortDirection = "";
    }

    this.get_cartola()


  }

  statuschange($e: any){
    if($e.value == "" || $e.value == undefined || $e.value == null){
      this.selected =""
      this.pageIndex = 0;
      this.page = 1;
      this.get_cartola();
    }else{
      this.pageIndex = 0;
      this.page = 1;
      this.get_cartola();
    }
  }

  reset_filter($event:any){
   if($event.target.value == ""){
    this.filter = "";
    this.get_cartola();
   }
  }

  reset(){
    this.valFormFechas.reset();
    this.fecha_fin = "";
    this.fecha_inicio = "";
    this.get_cartola();
  }

  simular(){
    if(this.parent == undefined){
      this.router.navigate(['home']);
      setTimeout(() => {
        $("#caja_excel").trigger('click')
      });
    }else{
      this.parent.switch_case(1)
    }

  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_cartola();
  }

  applyFilter(value:any) {
    this.pageIndex = 0;
    this.page = 1;
    
    this.valFormFilter.controls['filter'].setValue(value)
    this.filter = value;
    this.get_cartola()
  }

  applyFilter2() {
    this.pageIndex = 0;
    this.page = 1;

    this.filter = this.valFormFilter.controls['filter'].value;
    this.valFormFilter.controls['filter'].setValue(this.filter)

    this.get_cartola()
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  fecha_inicio:any="";
  fecha_fin:any="";
  filter:any="";
  date_change_start($event:any){

    let date_start = new Date(this.valFormFechas.controls['fecha_ini'].value);
    let dia, mes, ano;
    dia = String(date_start.getDate())
    mes = String(date_start.getMonth() + 1)
    ano = String(date_start.getFullYear())
    if (mes.length == 1) {
      mes = '0' + mes
    }
    if (dia.length == 1) {
      dia = '0' + dia
    }

    let start = ano + '-' + mes + '-' + dia;
    let start2 = ano + '-' + mes + '-' + dia;

    this.fecha_inicio = start;
    this.fecha_fin = start2;


  }

  date_change_end($event:any){

    if(this.valFormFechas.controls['fecha_fin'].value == null){

      this.fecha_inicio = this.fecha_inicio;

      let date_end = new Date(this.valFormFechas.controls['fecha_ini'].value);
      let dia2, mes2, ano2;
      dia2 = String(date_end.getDate())
      mes2 = String(date_end.getMonth() + 1)
      ano2 = String(date_end.getFullYear())
      if (mes2.length == 1) {
        mes2 = '0' + mes2
      }
      if (dia2.length == 1) {
        dia2 = '0' + dia2
      }
  
      let end = ano2 + '-' + mes2 + '-' + dia2;
      this.fecha_fin = end

    }else{
      let date_end = new Date(this.valFormFechas.controls['fecha_fin'].value);
      let dia2, mes2, ano2;
      dia2 = String(date_end.getDate())
      mes2 = String(date_end.getMonth() + 1)
      ano2 = String(date_end.getFullYear())
      if (mes2.length == 1) {
        mes2 = '0' + mes2
      }
      if (dia2.length == 1) {
        dia2 = '0' + dia2
      }
  
      let end = ano2 + '-' + mes2 + '-' + dia2;
      this.fecha_inicio = this.fecha_inicio;
      this.fecha_fin = end
    }

  }

  filtrar_por_fechas(){
    this.pageIndex = 0;
    this.page = 1;
    this.get_cartola();
  }

  open_detail_operacion(id_operacion:any){



    const dialogRef = this.dialog.open(FacturaDetailDialog, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'dialogo-full-screen-responsive',
        autoFocus: false,
        disableClose: false,
    });

    dialogRef.componentInstance.id_operacion = id_operacion;


    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }


}