<div mat-dialog-title>
  <div class="titulo-onboarding">
    @if(step == 1){
      ¿Qué es la sincronización?
    }@else if(step == 2){
      requisitos para sincronizar tu empresa
    }@else if(step == 3){
      ventajas de la vinculación con el SII
    }@else if(step == 4){
      consejos para evitar errores<br>con la clave SII de tu empresa
    }@else if(step == 5){
      ¿Qué es un sistema de facturación?
    }@else if(step == 6){
      ¿Qué es una operación de factoring?
    }


    <div mat-dialog-close class="close-dialog-icon"><i class="fa-sharp fa-regular fa-xmark"></i></div>
  </div>
  <div class="subtitulo-onboarding">
    @if(step == 2){
      Selecciona el encabezado del requisito para acceder a su descripción completa.
    }@else if(step == 3){
      Al ingresar la clave SII de tu empresa, podrás acceder a evaluaciones más ágiles,<br>además de contar con todos los beneficios de la sincronización.
    }
  </div>
</div>

<div mat-dialog-content>


  @if(step == 1){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Es una función que <strong>vincula los datos de tu empresa</strong> con esta plataforma, para que así puedas monitorear el <strong>progreso de tu negocio</strong> en tiempo real.
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if(step == 2){
    <div class="contenedor-expansion">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Ingresa la clave SII de tu empresa </mat-panel-title>
          </mat-expansion-panel-header>
  

          <div class="texto-expansion">
            Con este dato podemos vincularnos al SII para agilizar evaluaciones, ofrecerte opciones de financiamiento y generar tu Dashboard contable.
          </div>


        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Indicar cuál es el sistema de facturación que usas </mat-panel-title>
          </mat-expansion-panel-header>
  

          <div class="texto-expansion">
            Si ya estás emitiendo tus facturas a través de MiPyme, podemos ayudarte a simplificar la gestión de tus documentos tributarios. Con tu autorización para recibir las facturas directamente desde MiPyme, nos aseguramos de que toda tu información esté organizada y siempre disponible para tu negocio.
          </div>


        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if(step == 3){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <div class="swiper-container">

        <div class="titulo-onboarding" style="text-align: left;font-size: 26px;margin-bottom: 20px;">
          Beneficios de la sincronización:
        </div>

        <swiper-container id="swiper" #swiper 
        speed="500" autoplay="false" css-mode="false" loop="true"
        navigation="false" pagination="true" scrollbar="false" virtual="false" >


          <swiper-slide>

            <div class="row">

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper1.svg" />
                  </div>
                  <div>
                    Accede a tu dashboard<br>contable
                  </div>
  
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper2.svg" />
                  </div>
                  <div>
                    Indentifica a tus<br>principales clientes
                  </div>

                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper3.svg" />
                  </div>
                  <div>
                    consulta el Dicom de<br>tu empresa
                  </div>
  
                </div>
              </div>

            </div>
  

          </swiper-slide>


          <swiper-slide>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper4.svg" />
                  </div>
                  <div>
                    Revisa tus facturas<br>pagadas
                  </div>
  
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper5.svg" />
                  </div>
                  <div>
                    Gestiona tus<br>proveedores
                  </div>
  
                </div>
              </div>
            </div>


  

          </swiper-slide>





        </swiper-container>
      </div>




    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 4){
    <div class="image-container">
      <img src="../../../assets/iconografia/evitar_errores.svg" />
    </div>
  
    <div class="label-container">
      <ul>
        <li>Asegúrate de ingresar la clave de acceso al SII asociada al<br><strong>RUT de tu empresa</strong>.</li>
        <li>Para evitar que la clave sea bloqueada, <strong>no intentes ingresarla<br>más de 2 veces</strong>.</li>
        <li>Si no tienes seguridad de cuál es la clave, trata de <span>ingresar al SII</span> para validarla,<br> sin superar los 2 intentos recomendados.</li>
      </ul>  
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 5){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Son softwares o plataformas que permiten <strong>emitir, ceder o realizar otras operaciones</strong> con facturas electrónicas, respetando la normativa legal vigente.

      <br><br>
      Para más información, <span>contacta a nuestro equipo de experiencia cliente</span>.
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 6){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Es un proceso que <strong>otorga liquidez</strong> a través de la cesión de cuentas por cobrar, como facturas con pago a crédito.

      <br><br>
      En resumen, <strong>nos cedes tus facturas</strong>, te entregamos la liquidez y gestionamos la cobranza con el deudor (tu cliente).
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }





</div>






