import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-operaciones-alianzados',
  templateUrl: './operaciones-alianzados.component.html',
  styleUrls: ['./operaciones-alianzados.component.less']
})
export class OperacionesAlianzadosComponent implements OnInit {

  
  valFormComment: FormGroup;
  role: any;
  has_operaciones = true;
  operaciones: Array<any> = [];
  fecha_filter = '';
  selected_value: any;
  show_spinner = true;
  filter = '';
  filter_estado = '';
  fecha_cartera: any;
  total_monto_giro: any = 0;
  total_comision: any = 0;

  

  //filtro fechas
  selected: any = '';
  disabled: boolean = false;
  f_hoy = new Date();
  date_created: any;

  length: any = 0;
  pageSize: any = 20;
  pageSizeOptions: number[] = [20];
  page: any = 1;

  ELEMENT_DATA: Array<any> = []
  displayedColumns: string[] = ['position'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filtroMes: any = "";
  filtroAnio: any = "";
  
  anio_actual: any = '';
  //cajas
  boton_1: boolean = false;
  boton_2: boolean = false;
  boton_3: boolean = false;
  boton_4: boolean = false;
  display_velo: boolean = false;

  estado_list_select = [
    {
      number: '0',
      estado: 'Creado',
      borderStyle: { 'border-left': '10px solid #eee' },
      class: 'select_gray',
    },
    {
      number: '1',
      estado: 'Simulado',
      borderStyle: { 'border-left': '10px solid #3b86ff' },
      class: 'select_blue',
    },
    {
      number: '2',
      estado: 'Aprobado Cliente',
      borderStyle: { 'border-left': '10px solid #ff933b' },
      class: 'select_orange',
    },
    {
      number: '8',
      estado: 'Aprobado Gerente',
      borderStyle: { 'border-left': '10px solid #F9E78B' },
      class: 'select_yellow',
    },
    {
      number: '3',
      estado: 'Verificado',
      borderStyle: { 'border-left': '10px solid #c100da' },
      class: 'select_purple',
    },
    {
      number: '4',
      estado: 'Rechazo Riesgo',
      borderStyle: { 'border-left': '10px solid #F44B4B' },
      class: 'select_red',
    },
    {
      number: '5',
      estado: 'Cursado',
      borderStyle: { 'border-left': '10px solid #9500da' },
      class: 'select_fuchsia',
    },
    {
      number: '6',
      estado: 'Girado',
      borderStyle: { 'border-left': '10px solid #03caae' },
      class: 'select_green',
    },
    
  ];

  total_operaciones = 0;
  total_sin_giro = 0;
  total_excedentes = 0;
  flujo_dia = 0;
  caja_requerida = 0;
  operaciones_monto = 0;
  operaciones_sin = 0;
  comision_alianzado = 0;
  operaciones_excedentes = 0;
  cuenta_id=''

  
  
  @ViewChild('inputFilter', {
    read: MatInput,
  })
  inputFilter!: MatInput;

  @ViewChild('inputDate', {
    read: MatInput,
  })
  inputDate!: MatInput;

  fechaform:FormGroup;
  selected2: any = '';
 

  constructor(private router: Router,
    fb: FormBuilder,
    public settings: SettingsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { 
      let comment=new FormControl('')
      this.valFormComment=fb.group({
        'comentario':comment
      })

      let date = new FormControl(new Date)

      this.fechaform = fb.group({
        'date': date,
      });

    }

  ngOnInit() {
    this.role=localStorage.getItem("role")
    this.getOperaciones(this.page)
  }

  open_more_box(c: any, sw = false, rut: any, id: any) {
    //this.id_close = 0
    let prefix = '';

    if (sw) prefix = 'long_';

    if ($('#' + prefix + 'body_box_' + c).hasClass('body-active')) {
      //cerrar

      $('.box-prospecto-responsive-body').removeClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('.boton_arrow_box').removeClass('arrow_active animate__animated animate__fadeIn');
      //$("#"+prefix+"sel_arrow_"+c).removeClass("arrow_active animate__animated animate__fadeIn")

      $('#' + prefix + 'body_box_' + c).removeClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('#' + prefix + 'sel_arrow_' + c).removeClass(
        'arrow_active animate__animated animate__fadeIn'
      );

      // expandir caja extra
      setTimeout(() => {
        $('.box-prospecto-responsive-body-coment').removeClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_coment').removeClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab_'+c).removeClass('demarcador animate__animated animate__fadeIn')
        $('.box-prospecto-responsive-body-ejecutive').removeClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_ejecutive').removeClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab2_'+c).removeClass('demarcador animate__animated animate__fadeIn')
        $('.box-prospecto-responsive-body-cobro').removeClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_cobro').removeClass('arrow_active animate__animated animate__fadeIn');
        // $('#'+'cab3_'+c).removeClass('demarcador animate__animated animate__fadeIn')

        this.deuda_cartera = 0;
        this.deuda_neta = 0;
        this.sbif = 0;
        this.dicom = 0;
        this.venta_mensual = 0;
        this.type_company = 0;
        this.socios = [];
        this.hay_socios = false;
        this.cargandometricas = true;
      }, 100);
    } else {
      $('.box-prospecto-responsive-body').removeClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('.boton_arrow_box').removeClass('arrow_active animate__animated animate__fadeIn');
      $('#' + prefix + 'body_box_' + c).addClass('body-active animate__animated animate__fadeIn');
      $('#' + prefix + 'sel_arrow_' + c).addClass(
        'arrow_active animate__animated animate__fadeIn'
      );

      // cerrar caja extra
      setTimeout(() => {
        $('.box-prospecto-responsive-body-coment').addClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_coment').addClass('arrow_active animate__animated animate__fadeIn');
        $('#' + prefix + 'sel_sel_arrow_' + c).addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab_'+c).removeClass('demarcador animate__animated animate__fadeIn')

        $('.box-prospecto-responsive-body-ejecutive').addClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_ejecutive').addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        $('#' + prefix + 'well_arrow_' + c).addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab2_'+c).removeClass('demarcador animate__animated animate__fadeIn')

        $('.box-prospecto-responsive-body-cobro').addClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_cobro').addClass('arrow_active animate__animated animate__fadeIn');
        $('#' + prefix + 'cobr_arrow_' + c).addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab3_'+c).removeClass('demarcador animate__animated animate__fadeIn')

        this.deuda_cartera = 0;
        this.deuda_neta = 0;
        this.sbif = 0;
        this.dicom = 0;
        this.venta_mensual = 0;
        this.type_company = 0;
        this.socios = [];
        this.hay_socios = false;
        this.cargandometricas = true;

        this.colocacion(rut, id);
      }, 500);
    }
  }

  open_more_box_res(c: any, sw = false, rut: any, id: any) {
    //this.id_close = 0
    let prefix = '';

    if (sw) prefix = 'long_';

    if ($('#' + prefix + 'body_box_res' + c).hasClass('body-active')) {
      //ESTA CIERRA
      $('.box-prospecto-responsive-body').removeClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('.boton_arrow_box').removeClass('arrow_active animate__animated animate__fadeIn');
      $('#' + prefix + 'body_box_res' + c).removeClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('#' + prefix + 'sel_arrow_res' + c).removeClass(
        'arrow_active animate__animated animate__fadeIn'
      );

      setTimeout(() => {
        $('.box-prospecto-responsive-body-coment').removeClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_coment').removeClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab_'+c).removeClass('demarcador animate__animated animate__fadeIn')
        $('.box-prospecto-responsive-body-ejecutive').removeClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_ejecutive').removeClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        // $('#'+'cab2_'+c).removeClass('demarcador animate__animated animate__fadeIn')
        $('.box-prospecto-responsive-body-cobro').removeClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_cobro').removeClass('arrow_active animate__animated animate__fadeIn');
        // $('#'+'cab3_'+c).removeClass('demarcador animate__animated animate__fadeIn')

        this.deuda_cartera = 0;
        this.deuda_neta = 0;
        this.sbif = 0;
        this.dicom = 0;
        this.venta_mensual = 0;
        this.type_company = 0;
        this.socios = [];
        this.hay_socios = false;
        this.cargandometricas = true;
      }, 100);
    } else {
      //ESTE ABRE LA CAJA
      $('.box-prospecto-responsive-body').removeClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('.boton_arrow_box').removeClass('arrow_active animate__animated animate__fadeIn');
      $('#' + prefix + 'body_box_res' + c).addClass(
        'body-active animate__animated animate__fadeIn'
      );
      $('#' + prefix + 'sel_arrow_res' + c).addClass(
        'arrow_active animate__animated animate__fadeIn'
      );

      setTimeout(() => {
        $('.box-prospecto-responsive-body-coment').addClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_coment').addClass('arrow_active animate__animated animate__fadeIn');
        $('#' + prefix + 'sel_sel_arrow_res' + c).addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        $('.box-prospecto-responsive-body-ejecutive').addClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_ejecutive').addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        $('#' + prefix + 'well_arrow_res' + c).addClass(
          'arrow_active animate__animated animate__fadeIn'
        );
        $('.box-prospecto-responsive-body-cobro').addClass(
          'body-active animate__animated animate__fadeIn'
        );
        $('.boton_arrow_box_cobro').addClass('arrow_active animate__animated animate__fadeIn');
        $('#' + prefix + 'cobr_arrow_res' + c).addClass(
          'arrow_active animate__animated animate__fadeIn'
        );

        this.deuda_cartera = 0;
        this.deuda_neta = 0;
        this.sbif = 0;
        this.dicom = 0;
        this.venta_mensual = 0;
        this.type_company = 0;
        this.socios = [];
        this.hay_socios = false;
        this.cargandometricas = true;

        this.colocacion(rut, id);
      }, 500);
    }
  }

  getOperaciones(page: any) {

 
  }

  applyFilter(filtro: any) {
    this.filter = filtro;
    // this.paginator.firstPage();

    // this.paginator.pageIndex = 0;
    this.getOperaciones(this.page);
  }


  eventoCaja(val: any){
    switch (val) {
         case 1:
           if (this.boton_1==false) {
             $('#uno').addClass('montogiro');
             this.boton_1=true;
          }else{
            $('#uno').removeClass('montogiro');
            this.boton_1=false;
          }
         break;

         case 2:
           if (this.boton_2==false) {
             // code...
              $('#dos').addClass('montogiro');
              this.boton_2=true;
           }else{
             $('#dos').removeClass('montogiro');
             this.boton_2=false;
           }
         break;      

         case 3:
            if (this.boton_3==false) {
              // code...
              $('#tres').addClass('montogiro');
              this.boton_3=true;
            }else{
              $('#tres').removeClass('montogiro');
              this.boton_3=false;
            }
            
          break;
        }
        this.getOperaciones(this.page)
 
}

  /***********************************************/

  estadoFilter(filtro: any) {
    console.log(filtro);
    if (filtro == '' || filtro == undefined || filtro == null) {
      this.filter_estado = '';
      this.selected_value = '';
    } else {
      this.filter_estado = filtro;
      this.selected_value = filtro;
    }
    this.getOperaciones(this.page);
  }


  sendComment($event: any, value: any, id: any) {

  }



  totales = { total_deuda_neta: 0, total_monto_carteras: 0 };

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) { }
  }



  eliminarOperacion(id_operacion: any) {

  }

  eliminar(id_operacion: any, id_deudor: any, index: any) {

  }

  changeFecha(fecha: any) {
    if (fecha != null && fecha != undefined && fecha != '') {
      let splt_f = fecha.split('/');
      let fecha_format = new Date(`${splt_f[2]}-${splt_f[1]}-${splt_f[0]}`);
      this.fecha_filter = `${fecha_format.getFullYear()}-${this.format_month_number(
        fecha_format.getMonth() + 1
      )}-${fecha_format.getDate()}`;
    } else {
      this.fecha_filter = '';
    }

    this.getOperaciones(this.page);
  }

  format_month_number(month: any) {
    switch (month) {
      case 1:
        return '01';
        break;
      case 2:
        return '02';

      case 3:
        return '03';
      case 4:
        return '04';
      case 5:
        return '05';
      case 6:
        return '06';
      case 7:
        return '07';

      case 8:
        return '08';
      case 9:
        return '09';

      default:
        return month;
        break;
    }
  }

  deuda_cartera = 0;
  deuda_neta = 0;
  sbif = 0;
  dicom = 0;
  venta_mensual = 0;
  type_company = 0;
  socios = [];
  hay_socios: boolean = false;
  display_spinner: boolean = false;
  cargandometricas: boolean = true;
  get_indicadores(id_cliente: any) {

  }

  

 

  fecha_cartera2: any;

  colocacion(rut: any, id: any) {

  }

  cartera_cobranza(rut: any, bussines_name: any, id_cliente: any) {
    window.open(
      SettingsService.lemu_base +
      '/cobranza//#/home/detalle-cartera/' +
      rut +
      '/' +
      bussines_name +
      '/' +
      this.fecha_cartera2 +
      '/' +
      id_cliente,
      '_blank'
    );
  }
//filtrar por fecha

filtrarMes($e: any) {
  var fecha_filtro=new Date();

    if ($e.value == '' || $e.value == undefined || $e.value == null) {
      this.filtroMes = '';
      this.page=1
      this.length= 0;
      this.show_spinner=true
      this.getOperaciones(1);
    } else {
      this.filtroMes = $e.value
      this.page=1
      this.length= 0;
      this.show_spinner=true
      this.getOperaciones(1);
    }
  }
filtrarAnio($e: any) {
    var fecha_filtro=new Date();
      if ($e.value == '' || $e.value == undefined || $e.value == null) {
       
        this.filtroAnio = '';
        this.page=1
        this.length= 0;
        this.show_spinner=true
        this.getOperaciones(1);
        
        
      } else {
        this.filtroAnio = $e.value
        
        this.page=1
        this.length= 0;
        this.show_spinner=true
        this.getOperaciones(1);
        
      }
  }
  reset_filtros(){
    this.filter=''
    this.filter_estado=''
    this.fecha_filter=''
    this.inputFilter.value=''
    this.inputDate.value=''
    this.selected_value=undefined
    this.getOperaciones(this.page)
  }

  pageCustom($e: any)
  {
    //this.ELEMENT_DATA = []
    console.log($e.pageIndex)

    this.page=$e.pageIndex + 1
    console.log(this.page)
    this.getOperaciones(this.page)
  }

}
