
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;


@Component({
  selector: 'app-alert-delete',
  templateUrl: './alert-delete.html',
  styleUrls: ['./alert-delete.less']
})
export class AlertDelete implements OnInit {





  constructor(private change:ChangeDetectorRef,public dialog: MatDialog, public dialogRef:MatDialogRef<AlertDelete>,private snackBar:MatSnackBar,public settings: SettingsService,private router:Router) {



   }

  id_factura:any;
  id_sim:any;
  accion:any;
  deletefacturas:boolean=false;


  ngOnInit() {


  }

  close(){
    this.dialogRef.close(true)
  }

  title:string="¡Estás a punto de descartar el archivo!";
  subtitle:string="Si lo haces, será borrado de la documentación agregada.";
  titledelete:string="¡Estás a punto de eliminar tu única factura!";
  show_succes:boolean=false;
  loading_delete:boolean=false;
  async delete_factura(){
    this.loading_delete = true;

    try {
      
      const bd: any = await this.settings.get_query(4, '/cliente/simulacion-documentos/'+this.id_factura+'/?accion='+this.accion, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        if(this.accion == 1){
          this.titledelete = "¡Factura eliminada!"
          this.title = "Tu operación tuvo que ser descartada.";
          this.subtitle ="Para iniciar una nueva, vuelve a simular";
          this.show_succes = true;


        }else if(this.accion == 2){
          this.titledelete = "¡Factura eliminada!"
          this.title = "Tu operación tuvo que ser descartada.";
          this.subtitle ="Para iniciar una nueva, vuelve a simular";
          this.show_succes = true;

        
        }else{
          this.snackBar.open('Factura eliminada correctamente', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack'
          });
          this.dialogRef.close(true)
        }

        this.loading_delete = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {

      this.loading_delete = false;

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400: 

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  volver_simular(){
    this.dialogRef.close()
    this.router.navigate(['/home/']);

    setTimeout(() => {
      if( $('#caja_excel').length )      
      {
        $("#caja_excel").trigger('click');
      }
    });
  }
}