<div mat-dialog-title>

    <h1 class="titulo-simpli">Mis empresas</h1>
    <small class="subtitulo-simpli">Selecciona una empresa para visualizar su información</small>


</div>

<div mat-dialog-content>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
  
    <form >
      <mat-form-field appearance="fill">
          <mat-label>Empresas</mat-label>
          <input type="text" aria-label="Number" matInput [formControl]="myControl"
              [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete"
              (optionSelected)="selected($event)" >
              <mat-option *ngFor="let option of filteredOptions | async" data-id="{{option.id}}" 
                  [value]="option.name">
                  {{option.name}}
              </mat-option>
          </mat-autocomplete>
          <mat-spinner *ngIf="load" style="margin-right: 10px;"  diameter="20" matSuffix></mat-spinner>
      </mat-form-field>
    </form>
  

  </div>

</div>

<div mat-dialog-actions>

  <button mat-dialog-close mat-flat-button class="secondary-button">Cerrar</button>

  <button (click)="aceptar()" mat-flat-button class="primary-button">Aceptar</button>

</div>




