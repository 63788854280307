<div mat-dialog-title style="height: 10px !important;">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
  </div>
</div>

<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;" *ngIf="pausa_status == false && show_extra != 'bang bang'">
            <i style="font-size: 65px;color:var(--simpli-color6)" class="fa-regular fa-circle-info"></i>
        </div>

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;" *ngIf="pausa_status == true && show_extra != 'bang bang'">
          <i style="font-size: 65px;color:var(--simpli-color7)" class="fa-regular fa-circle-check"></i>
        </div>

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;" *ngIf="show_extra == 'bang bang'">
          <i style="font-size: 65px;color:var(--simpli-color6)" class="fa-regular fa-list-check"></i>
        </div>

        <div class="label-dialog-strong">{{title}}</div>
        <div class="label-dialog" [innerHTML]="subtitle"></div>

        <div class="label-dialog" *ngIf="show_extra" style="margin-top: 20px;text-decoration: underline;color:var(--simpli-secondary);font-size: 15px;cursor: pointer;" (click)="que_es_reemitir()">
          Descubre cómo reemitir las facturas en el SII
        </div>


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="show_extra == false">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button (click)="ver_detalle()">
                  Ver detalle
                </button>

                <button class="secondary-button" mat-flat-button mat-dialog-close>
                  Ver mis operaciones
                </button>
              </div>
              
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="show_extra == true">
      
              <div class="btn-simpli-container" style="margin-bottom: 30px;">
                <button *ngIf="pausa_status == false" class="primary-button" mat-flat-button (click)="reemitir_facturas()">
                  Reemitir facturas
                </button>

                <button *ngIf="pausa_status == false" class="secondary-button" mat-flat-button (click)="continuar_cesion()">
                  Continuar cesión
                </button>

                <button *ngIf="pausa_status == true" class="primary-button" mat-flat-button (click)="close_and_recharge()">
                 Ver mis operaciones
                </button>

                <button *ngIf="pausa_status == true" class="secondary-button" mat-flat-button (click)="reactivar()">
                  Reactivar operación
                </button>
              </div>

              <div *ngIf="pausa_status == false" class="label-dialog" style="margin-bottom: 40px;font-size: 15px;">
                Si lo necesitas, puedes <span (click)="pausar_operacion()" style="text-decoration: underline;color:var(--simpli-secondary);cursor: pointer;">pausar la operación.</span>
              </div>
              
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="show_extra == null">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button style="width: 230px;" (click)="reemitir_final()">
                  Reemitir facturas en el SII
                </button>

              </div>

              <div class="label-dialog" style="margin-bottom: 40px;font-size: 15px;">
                Si usas un facturador externo, <span (click)="contactar()" style="text-decoration: underline;color:var(--simpli-secondary);cursor: pointer;">contacta a tu ejecutivo.</span>
              </div>
              
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="show_extra == 'bang bang'">
      
              <div class="btn-simpli-container">
                <button class="primary-button" mat-flat-button style="width: 245px;" (click)="agregar_mas_facturas()">
                 Facturas reemitidas
                </button>

                <button class="secondary-button" mat-flat-button style="width: 245px;" (click)="continuar_cesion()">
                  Continuar cesión
                 </button>

              </div>

              
            </div>
        </div>



    </div>


    
</mat-dialog-content>
