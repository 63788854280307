



<div mat-dialog-title *ngIf="dialogView == true">
    <h1 class="titulo-simpli">Documentos</h1>
    <small class="subtitulo-simpli">Carga documentos legales, financieros y de todo tipo</small>
</div>

<div mat-dialog-content>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <mat-tab-group (selectedTabChange)="cambio_tipo($event)" mat-stretch-tabs="false" mat-align-tabs="start" stretchTabs="false" mat-align-tabs="start" dynamicHeight="true" fitInkBarToContent="false" preserveContent="false">

                <mat-tab label="Legales">

                    <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                            <div class="expansion-contenedor">
                                <mat-accordion class="example-headers-align">

                                    <mat-expansion-panel (opened)="cambiar_subtipo(i.nombre)" *ngFor="let i of profundidad_legales">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title><input value="{{i.nombre}}" readonly class="no-input" style="width: 100%;"></mat-panel-title>
                                        <mat-panel-description>
                                            <span style="visibility: hidden;">Type your name and age</span>
                                            <mat-icon *ngIf="i.documentos.length > 0" style="color:var(--green);">check_circle</mat-icon>
                                          </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      
        
                                      <mat-list>
                                        @for (file of i.documentos; track file) {
                                          <mat-list-item class="custom-list-files">
                                            <mat-icon matListItemIcon>folder</mat-icon>
                                            <div matListItemTitle><input class="no-input"  readonly value="{{get_name(file.name)}}"></div>
                                            <div matListItemLine>{{file.user }} - {{file.date_created }}</div>
                                            
                                            <div matListItemLine class="btn-list">
                                                <span (click)="deleteFile(file.id)" class="material-icons" style="margin-right: 5px;">delete</span>
                                                <span (click)="download_file(file.file)" class="material-icons">file_download</span>
                                            </div>

                                          </mat-list-item>
                                        }
                                      </mat-list>


                                      <div class="container-upload-btn">
                                        <button mat-mini-fab class="primary-button" [disabled]="showSpinner" (click)="trigger_up()">
                                            <mat-icon *ngIf="showSpinner == false">add</mat-icon>
                                            <span *ngIf="showSpinner == true">
                                                <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            </span>
                                        </button>
                                      </div>
        
        
                                    </mat-expansion-panel>

                                  </mat-accordion>


                            </div>

                        </div>

                    </div>



                </mat-tab>

                <mat-tab label="Financieros">

                    <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                            <div class="expansion-contenedor">
                                <mat-accordion class="example-headers-align">

                                    <mat-expansion-panel (opened)="cambiar_subtipo(i.nombre)" *ngFor="let i of profundidad_financiaros">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title><input value="{{i.nombre}}" readonly class="no-input" style="width: 100%;"></mat-panel-title>
                                        <mat-panel-description>
                                            <span style="visibility: hidden;">Type your name and age</span>
                                            <mat-icon *ngIf="i.documentos.length > 0" style="color:var(--green);">check_circle</mat-icon>
                                          </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      
        
                                      <mat-list>
                                        @for (file of i.documentos; track file) {
                                          <mat-list-item class="custom-list-files">
                                            <mat-icon matListItemIcon>folder</mat-icon>
                                            <div matListItemTitle><input class="no-input"  readonly value="{{get_name(file.name)}}"></div>
                                            <div matListItemLine>{{file.user }} - {{file.date_created }}</div>
                                            
                                            <div matListItemLine class="btn-list">
                                                <span (click)="deleteFile(file.id)" class="material-icons" style="margin-right: 5px;">delete</span>
                                                <span (click)="download_file(file.file)" class="material-icons">file_download</span>
                                            </div>

                                          </mat-list-item>
                                        }
                                      </mat-list>


                                      <div class="container-upload-btn">
                                        <button mat-mini-fab class="primary-button" [disabled]="showSpinner" (click)="trigger_up()">
                                            <mat-icon *ngIf="showSpinner == false">add</mat-icon>
                                            <span *ngIf="showSpinner == true">
                                                <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            </span>
                                        </button>
                                      </div>
        
        
                                    </mat-expansion-panel>

                                  </mat-accordion>
                            </div>

                        </div>

                    </div>

                </mat-tab>

                <mat-tab label="Otros">

                    <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                            <div class="expansion-contenedor">
                                <mat-accordion class="example-headers-align">

                                    <mat-expansion-panel (opened)="cambiar_subtipo(i.nombre)" *ngFor="let i of profundidad_otros">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title><input value="{{i.nombre}}" readonly class="no-input" style="width: 100%;"></mat-panel-title>
                                        <mat-panel-description>
                                            <span style="visibility: hidden;">Type your name and age</span>
                                            <mat-icon *ngIf="i.documentos.length > 0" style="color:var(--green);">check_circle</mat-icon>
                                          </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      
        
                                      <mat-list>
                                        @for (file of i.documentos; track file) {
                                          <mat-list-item class="custom-list-files">
                                            <mat-icon matListItemIcon>folder</mat-icon>
                                            <div matListItemTitle><input class="no-input"  readonly value="{{get_name(file.name)}}"></div>
                                            <div matListItemLine>{{file.user }} - {{file.date_created }}</div>
                                            
                                            <div matListItemLine class="btn-list">
                                                <span (click)="deleteFile(file.id)" class="material-icons" style="margin-right: 5px;">delete</span>
                                                <span (click)="download_file(file.file)" class="material-icons">file_download</span>
                                            </div>

                                          </mat-list-item>
                                        }
                                      </mat-list>


                                      <div class="container-upload-btn">
                                        <button mat-mini-fab class="primary-button" [disabled]="showSpinner" (click)="trigger_up()">
                                            <mat-icon *ngIf="showSpinner == false">add</mat-icon>
                                            <span *ngIf="showSpinner == true">
                                                <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            </span>
                                        </button>
                                      </div>
        
        
                                    </mat-expansion-panel>

                                  </mat-accordion>
                            </div>

                        </div>

                    </div>

                </mat-tab>

                <mat-tab label="UAF">




                    <div class="row">

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">

                                <h4 style="font-size: 15px !important;margin-bottom: 0px;">Declaración de beneficiarios finales</h4>
                                <div class="container-files">
                                    <button [disabled]="d_button" mat-mini-fab color="primary" (click)="trigger()"  class="primary-button container-fav">
                                        <mat-icon>attach_file</mat-icon>
                                    </button>
    
                                    <div *ngIf="file_dbf != null">
                                        <mat-list>
                                            <div mat-subheader>DBF</div>
                                            <mat-list-item>
                                              <mat-icon mat-list-icon style="color:#6e6c6c;">folder</mat-icon>
                                              <div mat-line matTooltip="{{get_name_url(file_dbf)}}">{{get_name_url(file_dbf)}}</div>
                                              <div mat-line style="font-size:12px; color:#979393;">{{res_dbf}}</div>
                                              <div mat-line (click)="open_file(file_dbf)" style="text-decoration: underline;font-size: 12px;color: #673ab7;cursor: pointer;width: 200px;">Descargar</div>
                                            </mat-list-item>
                                          </mat-list>
                                    </div>
    
                                    <input type="file" id="file_dbf" (change)="upload_dbf($event)"  style="display: none;" accept=".csv, .pdf, .png, .jpge, .jpg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
    
                                </div>
    
    
                            </div>
    
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
    
                                <h4 style="font-size: 15px !important;margin-bottom: 0px;">PEP (Personas Expuestas Políticamente)</h4>
                                <div class="container-files">
                                    <button [disabled]="d_button" mat-mini-fab color="primary" (click)="trigger_pep()" class="primary-button container-fav">
                                        <mat-icon>attach_file</mat-icon>
                                    </button>
    
                                    <div *ngIf="file_pep != null">
                                        <mat-list>
                                            <div mat-subheader>PEP</div>
                                            <mat-list-item>
                                              <mat-icon mat-list-icon style="color:#6e6c6c;">folder</mat-icon>
                                              <div mat-line matTooltip="{{get_name_url(file_pep)}}">{{get_name_url(file_pep)}}</div>
                                              <div mat-line style="font-size:12px; color:#979393;">{{res_pep}}</div>
                                              <div mat-line (click)="open_file(file_pep)" style="text-decoration: underline;font-size: 12px;color: #673ab7;cursor: pointer;width: 200px;">Descargar</div>
                                            </mat-list-item>
                                          </mat-list>
                                    </div>
    
                                    <input type="file" id="file_pep" (change)="upload_pep($event)"  style="display: none;" accept=".csv, .pdf, .png, .jpge, .jpg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
    
                                </div>
    
    
                            </div>

                    </div>



                </mat-tab>

            </mat-tab-group>

            <div *ngIf="loading_documents"><app-sin-registros [spin]="true"></app-sin-registros></div>

            <input type="file" id="input-subir-archivos" (change)="upload_file($event)" style="display: none;"/>


        </div>
    </div>

</div>


<div mat-dialog-actions *ngIf="dialogView == true">
    <button mat-flat-button color="primary" class="secondary-button" mat-dialog-close>Cerrar</button>
</div>

