import { Component, Output, OnInit, EventEmitter, ViewChild, Input, SimpleChanges, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseChartDirective } from 'ng2-charts';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;


@Component({
  selector: 'app-grafico-relacion-ventas',
  templateUrl: './grafico-relacion-ventas.component.html',
  styleUrls: ['./grafico-relacion-ventas.component.less']
})
export class GraficoRelacionVentasComponent{
  @Input() id:any;
  @Input() relacion_ventas_obj: any;
  show_graph = false
  @ViewChild(BaseChartDirective)
  public chart!: BaseChartDirective;
  iterador:any
  info:Boolean=false
  cargando:Boolean=true
  @Output() relacion_venta = new EventEmitter<string>();

  constructor(public snackBar: MatSnackBar, fb: FormBuilder, public dialog: MatDialog,  
    public settings: SettingsService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.informacion()
  }

  colores:any[] = ['#ffd293', '#ff7378', '#00def8']
  facturas:any = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: '#f1a23b',
        type: 'line',
        borderWidth: 3,
        fill:false,
        label: 'Cesiones Q',
        borderColor: '#f1a23b',
        yAxisID: 'y-axis-2',
        pointRadius: 3,
        pointHoverRadius: 8,
      },
      {
        data: [],
        backgroundColor: '#5dc4b5',
        type: 'line',
        fill:false,
        borderWidth: 3,
        label: 'Ventas Q',
        borderColor: '#5dc4b5',
        yAxisID: 'y-axis-2',
        pointRadius: 3,
        pointHoverRadius: 8
      },
      {
        data: [],
        backgroundColor: '#b583f7',
        type: 'line',
        fill:false,
        borderWidth: 3,
        label: 'Notas de credito Q',
        borderColor: '#b583f7',
        yAxisID: 'y-axis-2',
        pointRadius: 3,
        pointHoverRadius: 8
      },
      {
        data: [],
        backgroundColor: '#3fa9f5',
        type: 'bar',
        borderWidth: 0,
        label: 'Cesiones $',
        borderColor: 'white',
        yAxisID: 'y-axis-1',
      },
      {
        data: [],
        backgroundColor: '#342664',
        type: 'bar',
        borderWidth: 0,
        label: 'Ventas $',
        borderColor: 'white',
        yAxisID: 'y-axis-1',
      },
      {
        data: [],
        backgroundColor: '#d53073',
        type: 'bar',
        borderWidth: 0,
        label: 'Nota de credito $',
        borderColor: 'white',
        yAxisID: 'y-axis-1',
      }
    ]
  };

  dColors:any = [{
      backgroundColor: []
    }
  ];

  dOptions:any = {
    responsive: true,
    legend: {
      position: 'bottom', 
    labels: {fontSize: 12, usePointStyle: false}},
    
    // tooltips: {
    //   mode: 'index',
    //   intersect: false
    // },
    
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    tooltips: {
      callbacks: {
         label: function(t:any, d:any) {
            const nombre = t.label;
            const monto = t.value;
            const dataset = t.datasetIndex
            // console.log(dataset)
            const  h = ['Cesiones Q', 'Ventas Q', 'Notas de credito Q', 'Cesiones $', 'Ventas $' , 'Notas de credito $']
            let xLabel = nombre;
            let yLabel = monto >= 1000 ? ' ' + monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : + monto;
            return h[dataset] + ' : '+ '' + yLabel ;
         }
      }},
    animation: {animateRotate: true},
    fontFamily: '"DM Sans"',
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Meses'
        }
      }],
      yAxes: [{
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Pesos $'
        },
        position: 'left',
        id: 'y-axis-1',
        ticks: {

          userCallback: function(value:any, index:any, values:any) {
            return value >= 1000 ? ' ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : + value
        }
        }
      }, {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Q'
        },
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          drawOnChartArea: false
        }
      }],
    }
  };  

  empty_graph = () => {
    this.facturas.datasets[0].data = [];
    this.facturas.datasets[1].data = [];
    this.facturas.datasets[2].data = [];
    this.facturas.datasets[3].data = [];
    this.facturas.datasets[4].data = [];
    this.facturas.datasets[5].data = [];
  }

  async informacion() {
    try {

      this.cargando=true
      let mes_actual
      let mes_menos
      if (this.relacion_ventas_obj === undefined ) {
      //Mes actual
      let mensual = new Date();
      let mes_actual_ultimo = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);
      let mes1 = new Date(mes_actual_ultimo)
      let y1 = mes1.getFullYear();
      let m1:any = mes1.getMonth()+1;
      let d1:any = mes1.getDate();
      if(m1<10){m1="0"+m1}
      if(d1<10){d1="0"+d1}
      mes_actual = y1+"-"+m1+"-"+d1
  
      //Mes -6
      let mesmenos=new Date(mes_actual_ultimo.getTime() - (3650*60*60*1000)*1);
      let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
      let mes6 = new Date(ultimomenos)
      let y2 = mes6.getFullYear();
      let m2:any = mes6.getMonth()+1;
      let d2:any = mes6.getDate();
      if(m2<10){m2="0"+m2}
      if(d2<10){d2="0"+d2}
      mes_menos = y2+"-"+m2+"-"+d2
      } else{
        mes_actual = this.relacion_ventas_obj.end
        mes_menos = this.relacion_ventas_obj.start
      }
      
      this.show_graph = false

      
      const bd: any = await this.settings.get_query(1, 'cesion-venta-nc/'+this.id+'/'+"?inicio="+mes_menos+"&fin="+mes_actual);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.cargando=false
            if(body_data.success['time'].length>0){
              this.info=true
            }else{
              this.info=false
            }
      
            this.facturas.labels = body_data.success['time'];
            this.empty_graph()
            const range = (start:any, stop:any, step:any) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
            
            this.iterador = range(0, body_data.success['time'].length-1, 1)
            this.facturas.datasets[3].data = body_data.success['cesiones'];
            this.facturas.datasets[4].data = body_data.success['ventas'];
            this.facturas.datasets[5].data = body_data.success['notascredito'];
            this.facturas.datasets[0].data = body_data.success['cesiones_conteo'];
            this.facturas.datasets[1].data = body_data.success['ventas_conteo'];
            this.facturas.datasets[2].data = body_data.success['notascredito_conteo'];
            
            let len = body_data.success['time'].length
            for (let i = 0; i < len; i ++) {
              this.dColors[0].backgroundColor.push('#f1a23b');
            }
            
            this.dColors[0].backgroundColor.push( '#6c479c', '#479c5c')
            this.dColors[0].backgroundColor.push('#9ba2f0',  '#6c479c', '#479c5c')
            // console.log(this.dColors[0].backgroundColor)
            for (let index = 0; index < 3; index++) {
              let color = this.colores[index]
              for(let k = 1; k <= len; k ++){
                this.dColors[0].backgroundColor.push(color)
              }
            }
                  
            this.show_graph = true
            if (this.chart == undefined) {
              // console.log('error');
            } else {
              if (this.chart.chart == undefined) {
                // console.log('error2');
              } else {
                this.chart.chart.config.data = this.facturas;
                this.chart.chart.update();
              }
            }
            this.relacion_venta.emit('4')


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.cargando=false
      this.info=false
      //this.settings.empty_local_data();
      this.relacion_venta.emit('4')
    }
  }
  
}
