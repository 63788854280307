import {Component, ViewChild, OnInit, ChangeDetectorRef, ViewContainerRef, Input, ComponentFactoryResolver, HostListener} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Location} from '@angular/common';
import * as pdfjsLib from 'pdfjs-dist';
import { SettingsService } from 'src/app/settings/settings.service';
declare var $ :any;

@Component({
  selector: 'new-documentos',
  templateUrl: "./files.component.html",
  styleUrls: ['./files.component.less']
})
export class DocumentosComponent implements OnInit{

  arr_financieros = ["0.1","0.2","0.3"]
  arr_legales = ["1.2", "1.20", "1.8", "1.21", "1.22"] //aqui siendo consticion, antecedentes legales, informe, contrato, y contrato legalizado
  arr_otros = ["2.1"]

  @Input() id_company:any;
  @Input() role:any;
  @Input() token:any=localStorage.getItem('token');
  @Input() open_as_normalizacion:boolean=false;

  @Input() show_content:boolean=false;
  @Input() dialogView:boolean=false;

  all_legales:any[] = [];
  all_financieros:any[] = [];
  all_otros:any[] = [];

  

  
  spinner_records: boolean = false;
  call_records: any[] = []; 
  valFormFilter: FormGroup;
  date: string = '';


  constructor(private _cfr: ComponentFactoryResolver, public dialog: MatDialog, 
    public settings: SettingsService, fb: FormBuilder,
    public snackBar: MatSnackBar, private _location: Location, public snackbar: MatSnackBar,)
     {
      this.valFormFilter = fb.group({
        'filter': '',
        'date':'',
      });

      //pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
      pdfjsLib.GlobalWorkerOptions.workerSrc = './assets/js/pdf.worker.min.js';

  }


empty_files()
{

 for (let i = 0; i < this.all_legales.length; i++)
 {
   this.all_legales[i].destroy()
 }

 for (let i = 0; i < this.all_financieros.length; i++)
 {
   this.all_financieros[i].destroy()
 }

 for (let i = 0; i < this.all_otros.length; i++)
 {
   this.all_otros[i].destroy()
 }


}



ngAfterViewInit(): void {


}

ngOnInit(): void {

  this.cargar_documentos();

}

@HostListener('window:companyIdChanged', ['$event'])
onCompanyIdChanged(event: CustomEvent) {
  console.log('Company ID changed:', event.detail);
  this.id_company = localStorage.getItem('company_id');
  this.ngOnInit(); 
}

close(){
  this._location.back();
}

trigger(){
  $("#file_dbf").trigger('click')
}

trigger_pep(){
  $("#file_pep").trigger('click')
}

get_name_url(url:any){
  const file_name = url.substring(url.lastIndexOf('/')+1);
  return file_name
}

d_button:boolean=false;
upload_dbf = async ($e:any) => {
  let file_input = $e.target.files[0];
  let name = file_input.name;
  let formData = new FormData();
  formData.append('file', file_input, name);


  try {

    this.d_button = true;

    const bd: any = await this.settings.get_query(2,'/adjuntarFileUaf/'+this.id_company+'/?tipo=1',formData);
    var body_data: any = bd._body;

    switch (bd.status) {
      case 200:


            this.get_dbf();

            this.d_button = false;



        break;
      default:
        break;
    }
  } catch (bd: any) {
    switch (bd.status) {
      case 500:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;

      case 400:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;
      default:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );

        break;
    }
  }
}

upload_pep = async ($e:any) => {
  let file_input = $e.target.files[0];
  let name = file_input.name;
  let formData = new FormData();
  formData.append('file', file_input, name);


  try {

    this.d_button = true;
    const bd: any = await this.settings.get_query(2,'/adjuntarFileUaf/'+this.id_company+'/?tipo=2',formData);
    var body_data: any = bd._body;

    switch (bd.status) {
      case 200:


            this.get_pep();
            this.d_button = false;


        break;
      default:
        break;
    }
  } catch (bd: any) {
    switch (bd.status) {
      case 500:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;

      case 400:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;
      default:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );

        break;
    }
  }
}

file_dbf:any=null;
file_pep:any=null;
res_dbf:any=null;
res_pep:any=null;
get_dbf = async () => {

  try {
    const bd: any = await this.settings.get_query(1,'/adjuntarFileUaf/'+this.id_company+'/?tipo=1');
    var body_data: any = bd._body;
    var data = body_data.success;

    switch (bd.status) {
      case 200:

          this.file_dbf = data.info.file;
          this.res_dbf = data.info.responsable;


        break;
      default:
        break;
    }
  } catch (bd: any) {
    switch (bd.status) {
      case 500:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;

      case 400:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;
      default:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );

        break;
    }
  }
}

get_pep = async () => {

  try {
    const bd: any = await this.settings.get_query(1,'/adjuntarFileUaf/'+this.id_company+'/?tipo=2');
    var body_data: any = bd._body;
    var data = body_data.success;

    switch (bd.status) {
      case 201:
      case 200:

          this.file_pep = data.info.file;
          this.res_pep = data.info.responsable;



        break;
      default:
        break;
    }
  } catch (bd: any) {
    switch (bd.status) {
      case 500:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;

      case 400:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        break;
      default:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );

        break;
    }
  }
}

open_file(file:any){
  window.open(file, "blank")
}


get_call_records = async () => {
  try {
    this.spinner_records = true;
    const bd: any = await this.settings.get_query(1,`/tubo/call-record-file/${this.id_company}/?filter=${this.valFormFilter.controls["filter"].value}&date=${this.date}`);
    var body_data: any = bd._body;
    var data = body_data.success;
    switch (bd.status) {
      case 200:
          this.call_records = data.info;
          this.spinner_records = false;
        break;
      default:
          this.spinner_records = false;
        break;
    }
  } catch (bd: any) {
    switch (bd.status) {
      case 500:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        this.spinner_records = false;
        break;
      case 400:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        this.spinner_records = false;
        break;
      default:
        this.snackbar.open(
          'Ocurrió un error, intenta de nuevo',
          'Aceptar',
          {
            duration: 20000,
            panelClass: 'simpli-snack',
          }
        );
        this.spinner_records = false;
        break;
    }
  }
}

call_record_filter(value: any) {
  this.get_call_records();
}

call_record_date(value: any) {
  let y_i = value.getFullYear();
  let m_i: any = value.getMonth() + 1;
  let d_i: any = value.getDate();

  if (m_i < 10) {
    m_i = '0' + m_i;
  }
  if (d_i < 10) {
    d_i = '0' + d_i;
  }

  this.date = `${d_i}/${m_i}/${y_i}`;
  console.log(this.date)
  this.get_call_records();
}

reset() {
  this.valFormFilter.controls["filter"].setValue('');
  this.valFormFilter.controls["date"].setValue('');
  this.date = '';
  this.get_call_records();
}

legales:any[]=[];
financieros:any[]=[];
otros:any[]=[];

profundidad_financiaros:any[]=[];
profundidad_legales:any[]=[];
profundidad_otros:any[]=[];

LegalesName: { [key: string]: any } = {
  consulta_empresa: "Consulta empresa",
  publicacion_diario: "Publicación en diario",
  registro_comercio_cbrs: "Registro comercio CBRS",
  rut_sociedad: "Rut sociedad",
  ci: "CI",
  directorio_vigente: "Directorio vigente",
  antecedente_legal: "Antecedente legal",
  informe_sociedad: "Informe sociedad",
  contrato: "Contrato",
  contrato_legalizado: "Contrato legalizado"
};

FinancierosName: { [key: string]: any } = {
  carpeta_tributaria: "Carpeta tributaria",
  otros_docto_tributario: "Otros documentos tributarios",
  ultimo_balance: "Ultimo balance"
};

OtrosName: { [key: string]: any } = {
  otros: "Otros documentos",
};

loading:boolean=false;

tipo_documento:any="1";
subtitpo_documento:any;
//TIPOS
//1 legales
//0 financieros
//2 otros
cambio_tipo($e:any){
  console.log("cambio tipo, $e",$e)
  let tipo = $e.index;
  if(tipo == 0){
    this.tipo_documento = "1";
  }else if(tipo == 1){
    this.tipo_documento = "0";
  }else if(tipo == 2){
    this.tipo_documento = "2";
  }

  console.log(this.tipo_documento)
}

SubLegales: { [key: string]: string } = {
  "Consulta empresa": "1.2",
  "Publicación en diario":"1.3",
  "Registro comercio CBRS":"1.4",
  "Rut sociedad":"1.5",
  "CI":"1.6",
  "Directorio vigente":"1.7",
  "Antecedente legal":"1.20",
  "Informe sociedad":"1.8",
  "Contrato":"1.21",
  "Contrato legalizado":"1.22"
};

SubFinancieros: { [key: string]: string } = {
  "Carpeta tributaria":"0.1",
  "Otros documentos tributarios":"0.3",
  "Ultimo balance":"0.2"
};

SubOtros: { [key: string]: string } = {
  "Otros documentos":"2.1",
};

cambiar_subtipo($e:any){

  let valor = $e;
  console.log("valor", valor)
  if(valor && this.tipo_documento == "1"){
    this.subtitpo_documento = this.SubLegales[valor];
  }

  if(valor && this.tipo_documento == "0"){
    this.subtitpo_documento = this.SubFinancieros[valor];
  }

  if(valor && this.tipo_documento == "2"){
    this.subtitpo_documento = this.SubOtros[valor];
  }

  console.log("tipo ", this.tipo_documento)
  console.log("subtipo", this.subtitpo_documento)


}

loading_documents:boolean=false;
async cargar_documentos(){

  try {

    this.loading_documents = true;

    const bd: any = await this.settings.get_query(1, '/confeccionCarpeta/' + this.id_company +'/');
    const data: any = bd._body;

    switch (bd.status) {
      case 201:
      case 200:

      console.log(data)


      this.legales = data.confeccion_carpeta.legales;
      this.financieros = data.confeccion_carpeta.financieros;
      this.otros = data.confeccion_carpeta.otros;


      this.profundidad_financiaros = Object.keys(this.financieros || {}).map((key: any) => {
        return {
          nombre: this.FinancierosName[key] || key, 
          documentos: this.financieros[key]
        };
      });
      
      this.profundidad_legales = Object.keys(this.legales || {}).map((key: any) => {
        return {
          nombre: this.LegalesName[key] || key, 
          documentos: this.legales[key]
        };
      });
      
      this.profundidad_otros = Object.keys(this.otros || {}).map((key: any) => {
        return {
          nombre: this.OtrosName[key] || key, 
          documentos: this.otros[key]
        };
      });

      


      this.loading_documents = false;


      break;
      default:

      break;
    }

  } catch (bd:any) {

    this.profundidad_financiaros = [];
    this.profundidad_legales = [];
    this.profundidad_otros = [];
    this.loading_documents = false;

  }
}

get_name(url: any) {

  let file_name = url.substring(url.lastIndexOf('/') + 1);
  file_name = file_name.replace(/^\d+[-_]?/, '');
  
  return file_name;
}

trigger_up(){
  $("#input-subir-archivos").trigger('click');
}

showSpinner:boolean=false;
async upload_file($e:any)
{

  let formData: FormData = new FormData();
  this.showSpinner = true
  let file_input = $e.target.files;

  for(let i=0;i<file_input.length;i++){
    let name = this.tipo_documento + "-" + this.subtitpo_documento + "-" + file_input[i].name
    formData.append('file_'+i, file_input[i], name);
    console.log("name", name)
  }

  try {

    console.log("formdata", formData)

    const bd: any = await this.settings.get_query(2, '/confeccionCarpeta/' + this.id_company +'/',formData);
    var body_data: any = bd._body;
    switch (bd.status) {
      case 201:
      case 200:
          
          this.cargar_documentos()
          
          this.snackBar.open("Documentos subidos con exito", "Aceptar", {
            duration: 1500,
            panelClass: ['simpli-snack']
          });

          $("#input-subir-archivos").val("");

          this.showSpinner = false;

          //Enviar a leer el pdf

          if(body_data.type==0 && body_data.file_split_subtype=="0.1"){
            const loadingTask = pdfjsLib.getDocument(body_data.url);
            this.readPdf(loadingTask, this.id_company, this.token)
          }

          if(body_data.progresado==true){
            this.snackBar.open("Este prospecto ha progresado al 75% podras verlo en la tabla del 75%", "Aceptar", {
                duration: 10000,
                panelClass:['simpli-snack']
            });
        }

      break;
      default:

      break;
    }

  } catch (bd:any) {

    this.showSpinner = false;

  }
  

}

async readPdf(pdfUrl:any, company:any, token:any){
  let all_code:any[]=[]
  let arrayInternoPasAct:any[]=[]
  let contador= 1

  pdfUrl.promise.then(function (pdf:any) {
    let numPages = pdf.numPages; 
    let lastPromise = pdf.getMetadata();
    let meses=['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    let codigos:any[] = [
      {"codigo": "538", "suma": 2 },
      {"codigo": "142", "suma": 3 },
      {"codigo": "755", "suma": 2 },
      {"codigo": "509", "suma": 3 },
      {"codigo": "503", "suma": 2 },
      {"codigo": "020", "suma": 2 },
      {"codigo": "510", "suma": 2 },
      {"codigo": "563", "suma": 2 },
      {"codigo": "562", "suma": 2 },
      {"codigo": "504", "suma": 2 },
      {"codigo": "537", "suma": 2 },
      {"codigo": "91", "suma": 1 },
      {"codigo": "92", "suma": 1 },
      {"codigo": "93", "suma": 1 },
      {"codigo": "94", "suma": 1 },
      {"codigo": "795", "suma": 1 },
    ]
    let signos=['+', '−', '=', '*']

    let loadPage = function (pageNum:any) {
      return pdf.getPage(pageNum).then(function (page:any) {
        return page.getTextContent().then(function (content:any) {            
          let contenido = content.items.map(function (item:any) {return item.str;});
          let datos:any={}
          let pasAct:any={}

          if (meses.includes(contenido[0].split(" ")[0])==true || meses.includes(contenido[1].split(" ")[0])==true){
            let posicion_contenido = meses.indexOf(contenido[0].split(" ")[0]) == -1 ? 1 : 0
            datos['mes']=meses.indexOf(contenido[posicion_contenido].split(" ")[0])+1
            datos['ano']=contenido[posicion_contenido].split(" ")[1]
            datos['folio'] = contenido.includes('FOLIO') ? contenido[(contenido.indexOf('FOLIO')+2)] : ''

            codigos.map(function(x) {
              datos[x.codigo]= contenido.includes(x.codigo) ? signos.includes(contenido[(contenido.indexOf(x.codigo)+x.suma)]) ? 0 : contenido[(contenido.indexOf(x.codigo)+x.suma)] : 0
            });

            all_code.push(datos)
          }
          else{
            if(contenido.includes('122')){
              let splitTributario = contenido[0].split(" ")
              pasAct['anio'] = splitTributario[2]
              pasAct['activo'] = contenido[(contenido.indexOf('122')+2)]
              pasAct['pasivo'] = contenido[(contenido.indexOf('123')+2)]
              arrayInternoPasAct.push(pasAct)
            }
        }

          if (contador==numPages){    
            $.ajax({
              type: "POST",
              headers: {"Authorization": "JWT " + token},
              url: SettingsService.API_ENDPOINT + 'readVaciado/' + company +'/',
              data: {arrayFinal: JSON.stringify(all_code), arrayFinalAllCode: [], arrayInternoPasAct: JSON.stringify(arrayInternoPasAct)}                   
            });        
          }
          console.log(`contador`+contador)
          contador=contador+1
        })
        .then(function () {
        });
      });
    };

    for (let i = 1; i <= numPages; i++) {
      lastPromise = lastPromise.then(loadPage.bind(null, i));
    }
  
  }).catch(function (error:any){
      console.log(error)
  })
}

download_file(file:string){
  window.open(file, "_blank");
}

async deleteFile(id:any){

  if(this.role == "ecom" || this.role == "eneg" || this.role == "clie" || this.role == "anor"){
    this.snackBar.open("Su Perfil no tiene permitido eliminar archivos", "Aceptar", {duration: 10000, panelClass: ['simpli-snack']});
  }else{
    try {

      const bd: any = await this.settings.get_query(4,'/confeccionCarpeta/' + id +'/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          this.snackBar.open("Documentos eliminado", "Aceptar", {duration: 10000, panelClass: ['simpli-snack']});
          this.cargar_documentos()

        break;
        default:

        break;
      }

    } catch (bd:any) {

    }
  }
}



}
