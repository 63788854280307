<div [ngClass]="{'row':true, 'row_main': step != 2}">

  <!--detalle simulación-->
  @if(step == 1){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <div style="margin-top: 30px;">
      <div class="titulo-card" style="margin-bottom: 10px;">¡revisa la simulación de tu primera operación en Simpli!
      </div>

      <div class="label-container" style="text-align: center;">
        Las facturas que elegimos están <span (click)="open_modal_info(10)">preaprobadas</span> para una operación
        inmediata.
      </div>
    </div>



  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <mat-card class="centerabsolute" style="max-width: 600px;">

      <mat-card-content>

        <div class="box-info-onboarding">
          <div class="icon"><i class="fa-sharp fa-solid fa-circle-info"></i></div>
          <div>La apertura de la linea de factoring tiene un costo aproximado de $98.500, que se descontará de la primera operación.
          </div>
        </div>

        <div class="box-info-facturas">
          <div class="titulo-facturas">Facturas de la simulación</div>

          <div class="container-facturas add_factoring">

            <div class="row" style="margin-bottom: 5px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Cantidad de facturas:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="font-weight: bold;text-align: right;">
                @if(loading_cantidad){
                <skeleton-loader></skeleton-loader>
                }@else{
                {{cantidad_facturas}}
                }
              </div>

            </div>

            <div>
              <div style="text-align: right;">
                <mat-label class="pink-label" (click)="detalle_factura()">Abrir detalle <i
                    class="fa-solid fa-angle-right"></i></mat-label>
              </div>
            </div>

          </div>
        </div>

        <div class="box-info-facturas">
          <div class="titulo-facturas">Montos asociados</div>

          <div>
            <div class="row row-montos">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto total simulado:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;">
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                ${{settings.formatMoney(monto_simulado)}}
                }
              </div>
            </div>
            <div class="row row-montos">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Porcentaje de financiamiento:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;">
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                {{porcentaje_financiamiento}}%
                }
              </div>
            </div>
            <div class="row row-montos">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto de anticipo:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;">
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                ${{settings.formatMoney(monto_anticipo)}}
                }
              </div>
            </div>
            <div class="row row-montos">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vencimiento:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="text-align: right;font-weight: bold;">
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                {{dias_promedio}} días
                }
              </div>
            </div>
          </div>

        </div>

        <div class="contact-container" style="margin-top: 10px;">
          <div class="icons">
            <span (click)="step = 3"><i class="fa-sharp fa-light fa-pencil"></i>Editar simulación</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            @if(loading_pdf_ == false){
            <span (click)="download_pdf()"><i class="fa-sharp fa-light fa-download"></i>Descargar simulación</span>
            }@else{
            <span style="opacity: 0.5;"><i class="fa-solid fa-circle-notch fa-spin"></i> Descagar simulación</span>
            }

          </div>
        </div>

        <div class="caja-botones mt-20">
          <button mat-stroked-button class="primary-button" (click)="step = 2">Continuar</button>
        </div>


      </mat-card-content>

    </mat-card>



  </div>
  }


  <!--simulacion ok-->
  @if(step == 2){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="centerabsolute2" style="max-width: 700px;">
      <div class="titulo-card">
        @if(pendingDocs > 3){
        Faltan {{pendingDocs}} datos para completar la <br>documentación que debes firmar
        } @else if(pendingDocs > 0) {
        Estás muy cerca de formalizar tu incorporación
        } @else {
        Solo falta que formalices tu incorporación como cliente
        }
      </div>
      <mat-card-content>
        <div class="container-final" style="flex-direction: column;">
          <div class="imagen-final">
            <img *ngIf="!pendingDocs" src="../../assets/iconografia/formalizar.png" style="height: 160px !important;">
            <img *ngIf="pendingDocs" src="../../assets/iconografia/Iconografia - Development2.png"
              style="height: 160px !important;">
          </div>
          <div class="container-titulos" style="margin-top: 20px;">
            <div class="label-container">
              @if(pendingDocs > 3){
              Una parte de la documentación legal fue <strong>autocompletada.</strong>
              <br>Puedes ingresar los datos que faltan ahora o continuar
              <br>con la ayuda de un ejecutivo.
              } @else if(pendingDocs > 0) {
              Gran parte de la documentación legal que debes firmar fue <strong>autocompletada.</strong> Solo falta que
              ingreses <strong>{{pendingDocs}} datos.</strong>
              } @else {
                Para avanzar con el financiamiento de tu operación, <strong>debes firmar algunos documentos.</strong>
              <br><br>
              En breve, <strong>{{name}} te contactará </strong><br>para guiarte en este proceso.
              }
            </div>
          </div>

          <div class="caja-botones docs-faltantes">
            <button *ngIf="!pendingDocs" mat-stroked-button class="secondary-button secondary-responsive"
              (click)="step = 1">Volver a revisar simulación</button>
            <button *ngIf="pendingDocs" mat-stroked-button class="secondary-button"
              [style.marginBottom]="pendingDocs > 3 ? '30px' : '0'"
              (click)="step = 4; stepper_cuentas = 1; load_accounts()">Volver</button>
            <button *ngIf="!pendingDocs" mat-stroked-button class="primary-button"
              (click)="registrarCuenta()">Ir al home</button>
            <button *ngIf="pendingDocs" mat-stroked-button class="primary-button secondary-responsive"
              (click)="step = 4; stepper_cuentas = 2; getDocumentos()"
              [style.marginBottom]="pendingDocs > 3 ? '30px' : '0'">
              {{pendingDocs > 3 ? 'Ingresar datos ahora' : 'Ver documentación'}}
            </button>
          </div>

          <span *ngIf="pendingDocs > 3">
            <contact-container [tittle]="'Prefiero contactar a un ejecutivo.'"></contact-container>
          </span>


        </div>
      </mat-card-content>
    </mat-card>
  </div>
  }


  <!-- dialog estado situacion financiera -->
  @if(step == 5){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="centerabsolute2" style="max-width: 700px;">



      <!-- Seleccion inicial -->
      @if(situacionFinancieraView === 1) {
      <div style="position: relative;margin-top: 10px;">
        <div class="volver-dialog" (click)="stepper_cuentas = 2; step = 4">
          <i class="fa-solid fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>


      <div class="imagen-final" style="padding-top: 20px !important;">
        <img src="../../assets/iconografia/iconografia - web analysis.png" style="height: 160px !important;">
      </div>
      <mat-card-content>
        <div class="container-final" style="flex-direction: column;">
          <div class="titulo-card situacion-fin">¿Cuentas con un Estado de situación utilizado dentro de los <br>últimos
            6 meses en otra
            entidad?
          </div>
          <div class="caja-botones-situacion-fin">
            <button mat-stroked-button class="secondary-button" (click)="dialogSituacionFinanciera()">No</button>
            <button mat-stroked-button class="primary-button" (click)="situacionFinancieraView = 2">Sí</button>
          </div>
        </div>
      </mat-card-content>
      }

      @if(situacionFinancieraView === 2) {
      <div style="position: relative;margin-top: 10px;">
        <div class="volver-dialog" (click)="step = 5; situacionFinancieraView = 1">
          <i class="fa-solid fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>

      <div class="imagen-final" style="padding-top: 20px !important;">
        <img src="../../assets/iconografia/iconografia - web analysis.png" style="height: 160px !important;">
      </div>
      <mat-card-content>
        <div class="container-final" style="flex-direction: column;">
          <div class="titulo-card situacion-fin">carga tu Estado de situación financiera</div>
          <div class="subtitulo-onboarding">También puedes completar el documento en línea.</div>
          <div class="caja-botones-situacion-fin">
            <button mat-stroked-button class="secondary-button" (click)="dialogSituacionFinanciera()">Completar
              documento</button>
            <button mat-stroked-button class="primary-button" (click)="situacionFinancieraView = 3">Cargar
              documento</button>
          </div>
        </div>
      </mat-card-content>
      }

      @if(situacionFinancieraView === 3) {
      <div style="position: relative;margin-top: 10px;">
        <div class="volver-dialog" (click)="clearSelectedFile(); step = 5; situacionFinancieraView = 2">
          <i class="fa-solid fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>

      <div class="imagen-final" style="padding-top: 20px !important;">
        <img src="../../assets/iconografia/Iconografia - Upload file.png" style="height: 160px !important;">
      </div>
      <mat-card-content>
        <div class="container-final" style="flex-direction: column;">
          <div *ngIf="!isFileSelected" class="titulo-card situacion-fin">agrega el archivo desde tu equipo o dispositivo
          </div>
          <div *ngIf="isFileSelected" class="titulo-card situacion-fin">¡Archivo agregado!</div>
          <div *ngIf="isFileSelected" class="subtitulo-onboarding">Ya puedes cargar el documento.</div>
          <div class="name-file-container" *ngIf="isFileSelected" (click)="fileInput.click()">
            <input class="no-input select-name" [value]="selectedFileName" readonly>
            <i class="fa-regular fa-check" style="color: var(--green);"></i>
          </div>


          <input #fileInput type="file" accept=".pdf,.jpg,.jpeg" style="display: none;"
            (change)="onFileSelected($event)">

          <div *ngIf="!isFileSelected" class="agregar-archivo" (click)="fileInput.click()">
            <div class="icon-agregar">
              <i class="fa-light fa-circle-plus"></i>
            </div>
            <div class="text-agregar">
              <div class="titulo2">Agregar archivo</div>
              <div class="subtitulo2">Solo se admite el formato PDF o JPG y un peso máximo de “50 MB” por archivo.</div>
            </div>
          </div>

          <div *ngIf="isFileSelected" class="agregar-archivo" (click)="fileInput.click()">

          </div>


          <div class="caja-botones-situacion-fin">
            <button mat-stroked-button class="primary-button" [disabled]="!isFileSelected" (click)="uploadDocument()">
              Cargar documento
            </button>
          </div>
        </div>
      </mat-card-content>
      }

      @if(situacionFinancieraView === 4) {
      <div *ngIf="isLoading">
        <div class="imagen-final" style="padding-top: 20px !important;">
          <img src="../../assets/iconografia/Iconografia - Agency.png" style="height: 160px !important;">
        </div>
        <mat-card-content>
          <div class="container-final" style="flex-direction: column;">
            <div class="titulo-card situacion-fin">estamos procesando la información. <br>esto no tomará mucho tiempo
            </div>
            <loading-interno />
          </div>
        </mat-card-content>
      </div>

      <div *ngIf="!isLoading">
        <div style="position: relative;margin-top: 10px;">
          <div class="volver-dialog" (click)="step = 5; situacionFinancieraView = 3">
            <i class="fa-solid fa-chevron-left"></i>
            <span>Volver</span>
          </div>
        </div>

        <mat-card-content>
          <div class="container-final" style="flex-direction: column;">
            <div class="titulo-card situacion-fin">confirma los datos del Estado de situación cargado</div>
            <div class="subtitulo-onboarding">Si lo necesitas, puedes reemplazar el documento.</div>
            <div class="caja-botones-situacion-fin">
              <button mat-stroked-button class="secondary-button">Confirmar datos</button>
              <button mat-stroked-button class="primary-button" (click)="situacionFinancieraView = 3">Reemplazar
                documento</button>
            </div>
          </div>
        </mat-card-content>
      </div>
      }

    </mat-card>
  </div>
  }

  <!--editar simulacion-->
  @if(step == 3){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <div style="margin-top: 30px;">
      <div class="titulo-card" style="margin-bottom: 10px;">¡revisa las facturas que seleccionamos para tu simulación!
      </div>
      <div class="label-container" style="text-align: center;">
        Las siguientes facturas <strong>fueron evaluadas</strong> y ya cuentan con nuestra <span
          (click)="open_modal_info(10)">preaprobación</span>.
      </div>
    </div>



  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

        <div style="display: flex;align-items: center;">
          <mat-form-field appearance="fill" style="width: 400px;">
            <mat-label>Buscar</mat-label>
            <input matInput [formControl]="buscar" (change)="applyFilter()">
            <mat-icon matSuffix class="icon-suffix">search</mat-icon>
          </mat-form-field>

          <button style="margin-left: 20px;" (click)="open_modal_info(11)" mat-stroked-button
            class="secondary-button">Agregar más facturas</button>
        </div>

      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <div class="row">

      @if(facturas.length > 0 && loading_facturas == false){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <div class="label-table">Puedes eliminar o agregar más facturas antes de operar.</div>

        <mat-card class="card-tabla">
          <mat-card-content>
            <div class="table-responsive-simpli add_factoring">

              <table mat-table [dataSource]="dataSource">


                <ng-container matColumnDef="deudor">
                  <th mat-header-cell *matHeaderCellDef>Deudor</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.nombre_deudor}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="factura">
                  <th mat-header-cell *matHeaderCellDef>Factura</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.folio}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="rut">
                  <th mat-header-cell *matHeaderCellDef>RUT</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.rut_deudor}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef>Fecha de emisión</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.fecha_documento}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef>Monto</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="${{settings.formatMoney(element.document.monto_total)}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                    <span *ngIf="!element.isLoading"
                      (click)="delete_factura(element.document.document_factura_id, element.document_simulacion_id)"><i
                        class="fa-regular fa-trash-can"></i></span>
                    <span *ngIf="element.isLoading"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
                  </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

            </div>
          </mat-card-content>
        </mat-card>

        <div class="caja-botones" style="text-align: right;">
          <button mat-stroked-button class="primary-button" (click)="step = 1">Volver</button>
        </div>


      </div>
      }

      @if(facturas.length == 0 && loading_facturas == true){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <loading-interno></loading-interno>
      </div>
      }

      @if(loading_facturas == false){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <paginador-onboarding [pageSize]="7" [totalElements]="cantidad_facturas" [currentPage]="page"
          (pageChange)="pageEvent($event)">
        </paginador-onboarding>
      </div>
      }

      @if(facturas.length == 0 && loading_facturas == false){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;margin-bottom: 20px;margin-top: 20px;">
        <no-hay-registros [text]="'No se encontraron facturas.'"></no-hay-registros>
      </div>
      }

    </div>
  </div>
  }

  <!-- 4 cuentas bancarias-->
  @if(step == 4){

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <div class="title-container" style="text-align: center;">
      <div class="titulo-card">formaliza tu incorporación como cliente</div>
    </div>


    <div class="stepper">
      <div [ngClass]="{'step': true, 'active': stepper_cuentas == 1}">
        <div class="circle">
          <i class="fa-light fa-building-columns"></i>
        </div>
        <div class="step-text">Cuenta <br>bancaria</div>
      </div>
      <div class="line"></div>
      <div [ngClass]="{'step': true, 'active': stepper_cuentas == 2}">
        <div class="circle">
          <i class="fa-light fa-folder-open"></i>
        </div>
        <div class="step-text">Documentación<br><br></div>
      </div>
      <div class="line"></div>
      <div [ngClass]="{'step': true, 'active': stepper_cuentas == 3}">
        <div class="circle">
          <i class="fa-light fa-signature"></i>
        </div>
        <div class="step-text">Firma<br><br></div>
      </div>
    </div>



  </div>

  <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12 animate__animated animate__faster animate__fadeIn"></div>

  <!-- Registrar cuenta bancaria -->
  @if(stepper_cuentas == 1) {
  <div *ngIf="isLoading"
    class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <loading-interno></loading-interno>
  </div>

  <div *ngIf="!isLoading"
    class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <mat-card class="card-simpli">
      <mat-card-content>
        <div *ngIf="!hasAccounts" class="titulo-simpli">agrega una cuenta bancaria</div>
        <div *ngIf="!hasAccounts" class="texto-centrado">Registra la cuenta de tu empresa para recibir el
          financiamiento.</div>

        <div *ngIf="hasAccounts" class="titulo-simpli">¡Cuenta registrada!</div>
        <div *ngIf="hasAccounts" class="texto-centrado">Puedes revisar o editar los datos de la cuenta antes de
          continuar.</div>

        <div class="container-cuenta" *ngIf="!hasAccounts">
          <div>Cuenta bancaria</div>
          <div class="status-cuenta"><i class="fa-light fa-clock"></i> Pendiente</div>
        </div>

        <div class="container-cuenta" *ngIf="hasAccounts">
          <div>{{capitalizeText(accountData.bank.name)}}</div>
          <div class="status-cuenta-listo">
            <i class="fa-light fa-circle-check"></i> Registrada
            <span (click)="editarCuentaBancaria(accountData.id); $event.stopPropagation()"><i
                class="fa-light fa-pen edit-icon"></i></span>
          </div>
        </div>

        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="volverDesdeRegistro()">Volver</button>
          <button *ngIf="!hasAccounts" mat-stroked-button type="button" class="primary-button"
            (click)="agregarCuentaBancaria()">Agregar cuenta bancaria</button>
          <button *ngIf="hasAccounts" mat-stroked-button type="button" class="primary-button"
            (click)="verDocumentos()">Continuar</button>
        </div>


      </mat-card-content>
    </mat-card>

  </div>
  }

  <!-- Documentación -->
  @if(stepper_cuentas == 2) {

  <div *ngIf="isLoading" class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn"
    style="margin: 0 auto;">
    <loading-interno></loading-interno>
  </div>

  <div *ngIf="!isLoading"
    class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="card-simpli">
      <mat-card-content>
        <div class="titulo-simpli">{{titleText}}</div>
        <span class="subtitulo-doc">{{subtitleText}}</span>

        <div class="subtitulo-simpli-progress">Documentos completados</div>
        <div class="custom-progress-bar">
          <div class="custom-progress-bar-fill" [style.width.%]="progress"></div>
          <div class="progress-text">{{ progress }}%</div>
        </div>

        <ng-container *ngFor="let section of ['Documentos legales', 'Poderes legales', 'Contrato']">
          <ng-container *ngIf="shouldShowSection(section)">
            <div class="titulo-simpli-doc">{{section}}</div>

            <div class="container-cuenta-doc" *ngFor="let doc of getDocumentsBySection(section)">
              <div class="doc-container">
                <div class="doc-tittle">{{document_config[doc].displayName}}</div>
                <div [ngClass]="{'status-cuenta-listo': document_config[doc].status.hasData,
                                'status-cuenta-falta clickable': !document_config[doc].status.hasData}"
                  class="status-container"
                  (click)="!document_config[doc].status.hasData && handleDocumentClick(doc, 'view')">
                  <div class="status-text">
                    <i
                      [class]="document_config[doc].status.hasData ? 'fa-light fa-circle-check' : 'fa-light fa-light fa-clock'"></i>
                    {{ getStatusText(doc) }}
                  </div>


                  <div class="action-icons">
                    <span *ngIf="document_config[doc].status.hasData" (click)="handleDocumentClick(doc, 'view')"
                      class="clickable">
                      <i class="fa-light fa-eye edit-icon"></i>
                    </span>
                    <span *ngIf="!document_config[doc].status.hasData" (click)="$event.stopPropagation()">
                      <i class="fa-light fa-eye edit-icon" style="cursor: default;"></i>
                    </span>

                    <span *ngIf="document_config[doc].status.hasData" (click)="handleDocumentClick(doc, 'download')"
                      class="clickable">
                      <i class="fa-light fa-arrow-down-to-line edit-icon"></i>
                    </span>
                    <span *ngIf="!document_config[doc].status.hasData" (click)="$event.stopPropagation()">
                      <i class="fa-light fa-arrow-down-to-line edit-icon" style="cursor: default;"></i>
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>



        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="volverDesdeDocumentacion()">Volver</button>
          <button mat-stroked-button type="button" class="primary-button"
            (click)="stepper_cuentas = 3; clave_unica = 0">Ir a
            firmar</button> 
            <!-- [disabled]="hasPendingDocuments()" -->
        </div>

      </mat-card-content>
    </mat-card>
  </div>
  }

  <!-- Firmar -->
  @if(stepper_cuentas == 3) {

  @if(clave_unica == 0) {
  <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="card-simpli">
      <mat-card-content>
        <div class="titulo-simpli">pasos para firmar la documentación legal</div>

        <div class="firmar-container">
          <div class="paso">
            <div class="numero">1</div>
            <div class="texto">Para comenzar, deberás <strong>ingresar tu Clave Única</strong>.</div>
          </div>
          <div class="paso">
            <div class="numero">2</div>
            <div class="texto">Para ayudarnos a validar tus datos, tendrás que <strong>ingresar el número de
                documento</strong> de tu cédula.</div>
          </div>
          <div class="paso">
            <div class="numero">3</div>
            <div class="texto">Deberás <strong>responder 4 preguntas personales</strong> de seguridad para confirmar tu
              identidad.</div>
          </div>
          <div class="paso">
            <div class="numero">4</div>
            <div class="texto">Por último, tendrás que <strong>activar la geolocalización</strong> y crear tu <span
                (click)="open_modal_info(13)">PIN personal de firma segura</span>.</div>
          </div>
        </div>


        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="stepper_cuentas = 2; getDocumentos()">Volver</button>
          <button mat-stroked-button type="button" class="primary-button" (click)="loginClaveUnica()">Continuar</button>
        </div>

        <div class="container-info-geo">
          <span (click)="open_modal_info(14)">¿Por qué es necesario activar la geolocalización?</span>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
  } @if (clave_unica == 1) {
  <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="card-simpli">
      <mat-card-content *ngIf="!accessToken">

        <loading-interno></loading-interno>

        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="stepper_cuentas = 3; clave_unica = 0">Volver</button>
        </div>

      </mat-card-content>

      <mat-card-content *ngIf="accessToken">
        <div class="titulo-simpli">ingresa el número de documento de tu cédula</div>

        <div class="numeroDocumento-container">
          <img src="../../../assets/img/carnet-claveunica.png">
          <div class="texto">Encuentra el número solicitado en la cara frontal de tu cédula (mira la imagen). Este dato
            nos permitirá validar tu información personal (edad, nacionalidad, etc).</div>
        </div>

        <form [formGroup]="valFormNumeroDocumento">
          <mat-form-field>
            <mat-label>Número de documento</mat-label>
            <input type="text" formControlName="numeroDocumento" matInput required (keydown)="preventSpaces($event)" />
            <mat-hint>Ingresa el número de documento sin puntos</mat-hint>
            <mat-error>El número de documento es <strong>requerido</strong></mat-error>
          </mat-form-field>
        </form>

        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="stepper_cuentas = 3; clave_unica = 0;">Volver</button>
          <button mat-stroked-button type="button" class="primary-button" [disabled]="!valFormNumeroDocumento.valid"
            (click)="stepper_cuentas = 3; clave_unica = 2">Continuar</button>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
  } @if (clave_unica == 2) {
  <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="card-simpli">
      <mat-card-content *ngIf="!accessToken">

        <loading-interno></loading-interno>

        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="stepper_cuentas = 3; clave_unica = 1">Volver</button>
        </div>

      </mat-card-content>

      <mat-card-content *ngIf="accessToken">
        <div class="titulo-simpli">responde las 4 preguntas personales para confirmar tu identidad</div>

        <div class="numeroDocumento-container">
          <i class="fa-light fa-circle-info" style="color: #5B61EF;"></i>
          <div class="texto">Debes responder al menos <strong>3 preguntas correctamente</strong>. Tienes 3 intentos para
            lograrlo. Si se agotan, podrás volver a intentarlo en 24 horas.</div>
        </div>

        <div class="preguntas-container" style="padding-top: 10px;">
          <div class="titulo-simpli-doc">{{ questionData.preguntas[currentQuestion].descripcion }}</div>
                
          <mat-radio-group [(ngModel)]="selectedRadioValue">
            <div class="container-cuenta-doc" 
                 *ngFor="let alternativa of questionData.preguntas[currentQuestion].alternativas"
                 [ngClass]="{'selected-container': selectedRadioValue === parseInt(alternativa.id)}">
              <div class="doc-container">
                <div class="doc-tittle">
                  <mat-radio-button class="example-radio-button" 
                                   [value]="parseInt(alternativa.id)"
                                   (change)="onRadioChange(questionData.preguntas[currentQuestion].id, alternativa.id)">
                    {{ capitalizeTextQuestion(alternativa.descripcion) }}
                  </mat-radio-button>
                </div>
              </div>
            </div>
          </mat-radio-group>

          <div class="progress-container">
            <div *ngFor="let pregunta of questionData.preguntas; let i = index"
                 class="progress-step"
                 [ngClass]="{'active-step': i < currentQuestion || (i === currentQuestion && selectedAnswers[pregunta.id])}">
            </div>
          </div>

          <p>Pregunta {{ currentQuestion + 1 }} de {{ questionData.preguntas.length }}</p>
        </div>
  
        
        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
                  (click)="previousQuestion()">Volver</button>
          <button mat-stroked-button type="button" class="primary-button" 
                  [disabled]="!isAnswerSelected()"
                  (click)="nextQuestion()">
            {{ currentQuestion < questionData.preguntas.length - 1 ? 'Siguiente' : 'Continuar' }}
          </button>
        </div>
        


      </mat-card-content>
    </mat-card>

  </div>
  } @if (clave_unica == 3) {
    <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 animate__animated animate__faster animate__fadeIn">
      <mat-card class="card-simpli">
        <mat-card-content>
          <div class="titulo-simpli">activa la geolocalización antes de firmar</div>

          <div class="imagen-final" style="padding-top: 20px !important;">
            <img src="../../assets/iconografia/Iconografia - geolocalizacion.png" style="height: 160px !important;">
          </div>
  
          <div class="numeroDocumento-container">
            <div class="texto" style="text-align: center !important;">La notaría necesita verificar tu ubicación para validar la firma electrónica. Una vez que firmes los documentos, la geolocalización <strong>se desactivará de forma automática</strong>.</div>
          </div>
  
          <div class="preguntas-container" style="padding-top: 10px;">
            <div style="display: flex; align-items: center; justify-content: center;">
              <mat-slide-toggle [(ngModel)]="isGeolocationEnabled" (change)="onGeolocationToggle($event)">
                Activar geolocalización
              </mat-slide-toggle>
            </div>
          </div>
          
    
          
          <div class="btn-container">
            <button mat-stroked-button type="button" class="primary-button" [disabled]="!isGeolocationEnabled">
              Continuar
            </button>
          </div>
          
  
  
        </mat-card-content>
      </mat-card>
  
    </div>
  }



  }

  <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12 animate__animated animate__faster animate__fadeIn"></div>
  }





  <!--TEEUD-->
  @if(step == 0){


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <div class="title-container" style="text-align: center;">
      <h1 class="titulo-simpli">Necesitamos que subas unos documentos</h1>
      <small class="subtitulo-simpli">Puedes arrastrar o dar click en la zona correspondiente para subir cada tipo de
        documento</small>
    </div>

    <div>

      <div class="container-img-files"><img src="../../../assets/iconografia/adjuntar documentacion simulacion.png">
      </div>

      <div class="upload-container">

        <div class="dropzone" [class.dragover]="isDragOver1" (dragover)="onDragOver($event, 1)"
          (dragleave)="onDragLeave(1)" (drop)="onFileDrop($event, 1)" (click)="triggerFileInput(1)">

          <p *ngIf="!uploadedFile1">Constitución de la sociedad</p>
          <p *ngIf="uploadedFile1">{{ uploadedFile1.name }}</p>

          <input type="file" id="fileInput1" hidden (change)="onFileSelect($event, 1)" />

        </div>

        <div class="dropzone" [class.dragover]="isDragOver2" (dragover)="onDragOver($event, 2)"
          (dragleave)="onDragLeave(2)" (drop)="onFileDrop($event, 2)" (click)="triggerFileInput(2)">
          <p *ngIf="!uploadedFile2">Inscripción en el conservador de bienes raíces</p>
          <p *ngIf="uploadedFile2">{{ uploadedFile2.name }}</p>
          <input type="file" id="fileInput2" hidden (change)="onFileSelect($event, 2)" />
        </div>

      </div>

      <div class="btn-container" style="margin-top: 40px;">
        <button mat-stroked-button type="button" class="secondary-button" (click)="step = 2">Volver</button>
        <button mat-stroked-button [disabled]="uploadedFile1 == null || uploadedFile2 == null" type="button"
          class="primary-button" (click)="subir_archivos()">Subir documentos</button>
      </div>

    </div>

  </div>

  }

</div>