
<div mat-dialog-title>
    <h1 class="titulo-simpli">
        ¡Te has registrado con éxito!
    </h1>
</div>
<div mat-dialog-content>
    <div class="text-center">
        <p class="simpli-color3" style="margin: 0 15px;">
        Seras redireccionado a la pantalla de inicio.
        </p>

    </div>
</div>

<div mat-dialog-actions>
    <button mat-dialog-close type="button" mat-flat-button class="primary-button">Cerrar</button>
</div>