
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-alert-success',
  templateUrl: './alert-success.html',
  styleUrls: ['./alert-success.less']
})
export class AlertSuccess implements OnInit {


  @Input() id_sim:any;
  @Input() id_company:any;
  @Input() onboarding:boolean=false;

  open_as_sim:boolean=false;

  title:string="¡Todo listo! Tu simulación será evaluada";
  subtitle:string="Recibirás el resultado en tu correo y dispositivo móvil. Si es aprobada, podrás realizar la cesión. Por ahora, adjunta la documentación de respaldo para tus facturas o mira el estado de tu simulación."

  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<AlertSuccess>, public router:Router) {

    this.id_company = localStorage.getItem('company_id');

   }


  is_responsive:boolean=false;
  ngOnInit() {
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }

  ver_estado(){
    this.dialogRef.close();
    if(this.is_responsive == true){
      this.router.navigate(['home/simulaciones-r/',this.id_company])
    }else{
      this.router.navigate(['/home/simulaciones']);
    }

  }

  adjuntar_files(){
    this.dialogRef.close();
    this.router.navigate(['home/documentacion/',this.id_sim])
  }

  continue(){
    this.dialogRef.close(true)
  }

}