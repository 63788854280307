import {Component,ChangeDetectionStrategy,Input, Output,EventEmitter, OnInit, OnChanges} from '@angular/core';


@Component({
  selector: 'paginador-onboarding',
  templateUrl: './custom-paginator.html',
  styleUrls: ['./custom-paginator.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PaginadorOnboarding implements OnChanges {
  @Input() pageSize!: number;
  @Input() totalElements!: number;
  @Input() currentPage!: number; // Página actual
  @Output() pageChange = new EventEmitter<number>(); // Emitir el número de página

  totalPages!: number; // Número total de páginas
  displayedPages!: number[]; // Páginas visibles
  targetPage: number = this.currentPage;  // La página objetivo que selecciona el usuario

  ngOnChanges(): void {
    this.totalPages = Math.ceil(this.totalElements / this.pageSize); // Calcular total de páginas
    this.updateDisplayedPages(); // Actualizar páginas visibles
  }

  // Actualiza el arreglo de páginas visibles, centrando la página actual
  updateDisplayedPages(): void {
    const pages = [];

    // Página anterior, actual y siguiente
    const previousPage = Math.max(this.currentPage - 1, 1);
    const nextPage = Math.min(this.currentPage + 1, this.totalPages);

    // Si hay solo una página
    if (this.totalPages <= 3) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(previousPage === this.currentPage ? previousPage - 1 : previousPage);
      pages.push(this.currentPage);
      pages.push(nextPage === this.currentPage ? nextPage + 1 : nextPage);
    }

    this.displayedPages = pages.filter(page => page >= 1 && page <= this.totalPages);
  }

  // Emite el evento cuando se cambia de página
  onPageChange(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPages && pageNumber !== this.currentPage) {
      this.pageChange.emit(pageNumber);
      this.targetPage = pageNumber;  // Actualiza el input con la nueva página
    }
  }

}