import { Component, OnInit, ViewChild, ElementRef, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { SettingsService } from '../settings/settings.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-mirai-cliente',
  templateUrl: './mirai-cliente.html',
  styleUrls: ['./mirai-cliente.less'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('300ms', style({ opacity: 0, transform: 'translateY(20px)' }))
      ])
    ])
  ]
})
export class MiraiCliente implements OnInit {
  @ViewChild('chatContent') private chatContent!: ElementRef;
  @Input() initials:any;
  @Input() full_name:any;
  id_company = localStorage.getItem('company_id')

  avatarBackground: string = '';
  showInitials: boolean = true;

  chatVisible = false;
  userOption: string | null = null;
  botResponse: string | null = null;
  currentDate!: Date;
  userMessage: string = '';
  userMessages: any[] = [];
  observation:any;

  constructor(private settings:SettingsService, private router: Router, @Inject(DOCUMENT) private document: Document, private cdRef: ChangeDetectorRef){

  }

  ngOnInit() {
 
    this.currentDate = new Date();
    //this.avatarBackground = this.generateRandomBackground();
  }



  toggleChat() {
    this.chatVisible = !this.chatVisible;
  }

  selectOption(option: any, msg:string) {

    switch(option) {
      case 1:
        this.sendMessage_prefix(msg)
        this.botResponse = `
        ¡Con gusto te ayudaré! Es necesario que sepas lo siguiente de nuestros servicios:<br><br>

        Tenemos un gran ecosistema de servicios, que van desde el Pronto Pago a proveedores hasta el financiamiento de órdenes de compra (Ordering).<br><br>

        Conoce cada uno de los servicios de nuestro ecosistema en detalle, <a href="https://www.simplilatam.com/" target="blank">haciendo clic aquí</a>.<br><br>

        Si necesitas más ayuda, no dudes en escribirme.
      `;
        this.generateBotResponse_prefix(this.botResponse)
        break;
      case 2:
        this.sendMessage_prefix(msg)
        this.botResponse = `
        ¡Esto sí es interesante! Referente al funcionamiento del Factoring en Simpli, debes saber lo siguiente:<br><br>

        Antes de realizar la cesión de las facturas seleccionadas, <strong>se debe evaluar la operación</strong>, con el fin de calcular los costos asociados. 
        Esto se puede lograr a través de la simulación de operaciones.<br><br>

        Una vez que <strong>la operación es aprobada</strong> por la empresa que solicita el financiamiento y por Simpli, se hace entrega del adelanto de las cuentas por cobrar.<br><br>

        Por último, Simpli <strong>realiza la cobranza de las facturas al cliente.</strong> Una vez que este pague, se transfiere <strong>el saldo que quedó pendiente de la factura (excedente).<strong>

        <br><br>
        Si necesitas más ayuda, no olvides que puedes escribirme.
        `;
        this.generateBotResponse_prefix(this.botResponse)
        break;
      case 3:
        this.sendMessage_prefix(msg)
        this.botResponse = `
        ¡Muy bien! Te ayudaré con esto. Solo guíate por la siguiente información:<br><br>

        Ingresa al menú ubicado en el costado izquierdo de la plataforma y selecciona <strong>“Simulaciones”</strong> para visualizar la operación que dará paso a la cesión. 
        Haz clic en <strong>“Detalle”</strong> para revisar el Certificado Digital Electrónico que está cargado. Si ves todo en orden, presiona <strong>“Aceptar y Continuar”</strong>.<br><br>

        Si el Certificado Digital Electrónico no está en nuestro sistema, puedes cargar el archivo <strong>“AEC”</strong> haciendo clic en <strong>“Estado AEC”</strong>.<br><br>

        Si necesitas ayuda con algo más, no dudes en escribirme.
      `;
        this.generateBotResponse_prefix(this.botResponse)
        break;
        case 4:
          this.sendMessage_prefix(msg)
          this.botResponse = `
          ¡Muy bien! Con gusto te ayudaré.<br><br>

          Para realizar una simulación, ingresa a <strong>simula tu operación</strong>, elige las facturas que quieres simular y completa el proceso.<br><br>

          También puedes <strong>contactar a tu ejecutivo</strong> para solicitar su ayuda.<br><br>

          Además te llevaré a la pantalla de simular en 20 segundos, espero haberte ayudado.

        `;
          this.generateBotResponse_prefix(this.botResponse)
          break;
    }
    //this.userMessages.push(this.botResponse);
    this.currentDate = new Date();
    this.scrollToBottom();
  }

  countdown: number = 10; 
  intervalId: any; 
  go_to_simular(){

    this.intervalId = setInterval(() => {
      this.countdown = this.countdown - 1;
      if (this.countdown <= 0) {
        clearInterval(this.intervalId);
        this.performAction();
      }
    }, 2000);


  }

  performAction() {
    const element = this.document.getElementById('caja_excel');
    if (element) {
      element.click();
      const index = this.userMessages.findIndex(message => message.includes("Además te llevaré a la pantalla de simular en 20 segundos Espero haberte ayudado"));

      let updatedMessage = `
            ¡Muy bien! Con gusto te ayudaré.<br><br>
  
            Para realizar una simulación, ingresa a <strong>simula tu operación</strong>, elige las facturas que quieres simular y completa el proceso.<br><br>
  
            También puedes <strong>contactar a tu ejecutivo</strong> para solicitar su ayuda.<br><br>
  
            Ahora te encuentras en la pantalla de simular.
  
          `;
  
      this.userMessages[index] = updatedMessage;
    } else {
      this.router.navigate(['home']).then(() => {
        const homeElement = this.document.getElementById('caja_excel');
        if (homeElement) {
          homeElement.click();
        }
      });
    }
  }

  resetChat() {
    this.userOption = null;
    this.botResponse = null;
    this.userMessages = [];
    this.userMessage = '';
  }

  escribiendo:boolean=false;
  sendMessage() {
    if (this.userMessage.trim()) {
      this.userMessages.push(this.userMessage);
      console.log("MENSAJE", this.userMessages)
      this.send_msg(this.userMessage);
      this.userMessage = '';
      this.currentDate = new Date();
      this.scrollToBottom();
    }
  }

  generateBotResponse(userMessage: string) {
    let botResponse = userMessage;

    this.userMessages.push(botResponse);
  }


  sendMessage_prefix(msg:string) {
    if (msg.trim()) {
      this.userMessages.push(msg);
      this.userMessage = '';
      this.currentDate = new Date();
      this.scrollToBottom();
    }
  }

  generateBotResponse_prefix(userMessage: string) {
    let botResponse = userMessage;

    this.userMessages.push(botResponse);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
    }, 0);
  }

  generateRandomBackground(): string {
    const colors = ['#924eff', '#3b86ff', '#ff81f1', 'var(--simpli-primary)', '#ff2982', '#1b0943'];
    const randomIndex1 = Math.floor(Math.random() * colors.length);
    let randomIndex2 = Math.floor(Math.random() * colors.length);

    while (randomIndex2 === randomIndex1) {
        randomIndex2 = Math.floor(Math.random() * colors.length);
    }

    return `linear-gradient(-50deg, #9B56F6, #DD73F2)`;
  }


  disabled:boolean=false;
  async send_msg(msg:string) {
    try {
      this.disabled = true;
      this.escribiendo = true;
      const bd: any = await this.settings.get_query(1, '/cliente/chat-ai/'+this.id_company+'/?input_client='+msg);
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:

            console.log(body_data)
            this.escribiendo = false;
            this.disabled = false;


            this.generateBotResponse(body_data.success.info);
            this.scrollToBottom();


            setTimeout(() => {
              this.focusInput();
            });

          break;
        default:

          break;
      }
    } catch (bd: any) {

      this.escribiendo = false;
      this.disabled = false;

      this.generateBotResponse("Vaya, mis sistemas colapsaron con esa pregunta <i style='margin-left:10px;margin-right:10px;' class='fa-light fa-face-sad-cry'></i>, intentalo de nuevo, ¡estoy aprendiendo para ser la mejor!");
      this.scrollToBottom();

      setTimeout(() => {
        this.focusInput();
      });
    }
  }

  @ViewChild('inputclara') inputClara!: ElementRef;
  focusInput() {
      this.cdRef.detectChanges();
      this.inputClara.nativeElement.focus();
      $("#inputclara").trigger('focus')
  }




}
