import { Component,ViewChild, OnInit, Input, ComponentFactoryResolver, HostListener, ViewContainerRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RouterModule, Routes, ActivatedRoute, NavigationEnd, NavigationStart, RouterEvent } from '@angular/router';
import { Router } from '@angular/router';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { DialogCargaManualOnlyComponent } from './dialog-carga-manual-only/dialog-carga-manual-only.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { PagoSeleccion } from '../pago-seleccion/pago-seleccion';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
declare var $ :any;


@Component({
  selector: 'pago-proveedores',
  templateUrl: './pago-proveedores.html',
  styleUrls: ['./pago-proveedores.less']
})
export class PagoProveedores implements OnInit, OnDestroy {

  @Input() company_id:any;

  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  columna = "";
  orientacion = "";

  ELEMENT_DATA_FACTURAS: Array<any>=[];
  displayedColumnsFacturas: string[] = ['select','folio','receptoracreedor__business_name','ultimoacreedor','mnt_total','fecha_emision'];
  dataSourceFacturas = new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
  @ViewChild('paginator') paginator!: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  arr_documentos_to_simulate: Array<any> = [];

  valForm: FormGroup;
  desde='';
  hasta='';
  filter='';

  total_tabla=0; 
  total_proveedores = 0;

  infotable:boolean=true;
  show_msg_acreedor_legal:boolean=false;
  disabled:boolean=false;
  disabled_enviar:boolean=true;

  constructor(
    public router:Router,
    public settings: SettingsService,
    private fb: FormBuilder,
    public dialog:MatDialog,
    public snackBar: MatSnackBar){ 
  
    this.valForm = fb.group({
      desde: '',
      hasta: '',
      filter:''
    });

  }

  myObserver:any;
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngOnInit() {
    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all()
      };
     });
     this.load_all()
  }

  load_all()
  {
    this.company_id = Number(localStorage.getItem("company_id"))
    this.guardar_visita();
    this.load_table();
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 

  async load_table() {
    try {
      this.show_msg_acreedor_legal = false;
      this.disabled = true;
      const bd: any = await this.settings.get_query(1, `payments/compras-proveedores/${this.company_id}/?pageNumber=${this.page}&fecha_ini=${this.desde}&fecha_fin=${this.hasta}&filter=${this.filter}&orientacion=${this.orientacion}&columna=${this.columna}`);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
          this.ELEMENT_DATA_FACTURAS = data.compras;
          this.length = data.pager.total;
          if (this.ELEMENT_DATA_FACTURAS.length == 0) {
            this.infotable = false
          }
          this.selection.clear();
          this.ELEMENT_DATA_FACTURAS.forEach((element) => {
            const obje = this.arr_documentos_to_simulate.find(
              (x) =>
                x.id == element.id
            );
            if (obje) {
              this.selection.toggle(element);
            } else {
            }
          });
          this.disabled = false;
          setTimeout(() => {
            this.dataSourceFacturas=new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
            this.dataSourceFacturas._updateChangeSubscription();
          });
        break;
        default:

        break;
      }
    } catch (bd: any) {
      this.settings.manage_errors(bd.status, "Compras Pago a Proveedores", bd, false);
    }
  }

  filter_date($e: any, type:any) {
    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    switch (type) {
      case 1:
        this.desde = dia + '-' + mes + '-' + year;
        if (this.hasta!="") {
          this.load_table()
        }
        break;
      
      case 2:
        this.hasta = dia + '-' + mes + '-' + year;
        if (this.desde!="") {
          this.load_table()
        }
        break;
    
      default:
        break;
    }
  }

  filtrar() {
    this.paginator.firstPage();
    this.filter = this.valForm.controls['filter'].value;
    if(this.filter == null){
      this.filter = "";
    }
    this.load_table();
  }

  reset_filters(){
    this.filter = "";
    this.desde = "";
    this.hasta = "";
    this.columna = "";
    this.orientacion = "";
    this.valForm.reset()
    this.load_table()
  }

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.load_table();
  }

  sortData($e: any) {
    this.columna = $e.active;
    this.orientacion = $e.direction;
    this.load_table()
  }

  async sincronizar() {
    try {
      this.disabled = true;
      $("#btn_llamativo").addClass('disable_btn_llamativo');
      const bd: any = await this.settings.get_query(2, `sii-empresa/${this.company_id}/`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.open_input_dialog("Sincronización", "Sincronización Exitosa", 
          "¡Todo listo! Nos hemos sincronizado con el SII",
          "Hemos actualizado los ultimos 6 meses con tus libros de compras, ventas y notas de credito!","sync","#3BA46F")
        break;
        default:

        break;
      }
    } catch (bd: any) {
      this.disabled = false;
      $("#btn_llamativo").removeClass('disable_btn_llamativo');
      this.settings.manage_errors(bd.status, "Sincronizar", bd, false);
    }
  }

  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){
    const dialogRef = this.dialog.open(InputDialog,{
      width:'700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;

    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_table()
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturas.filteredData.length;
    return numSelected === numRows;
  }

  activa($e: any, objeto:any) {
    if ($e) $e.stopPropagation();
    const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);
    if (obje === undefined) {
      this.arr_documentos_to_simulate.push(objeto);
    } else {
      this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
    }
    this.total_group_by()
  }

  total_group_by() {
    this.total_tabla = this.arr_documentos_to_simulate.reduce((acc, cv) => Number(cv.mnt_total) + acc , 0);
    this.disabled_enviar = this.arr_documentos_to_simulate.length > 0 ? false : true;
    var group = this.group_by(this.arr_documentos_to_simulate, 'ultimorutacreedor');
    this.total_proveedores = Object.keys(group).length;
  }

  group_by(array:any, key:any) {
    return array.reduce((result:any, currentValue:any) => {(result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );return result;
    }, {}); 
  }

  masterToggle() {
    if (this.isAllSelected()) {
      for (let i = 0; i < this.dataSourceFacturas.data.length; i++) {
        let objeto = this.dataSourceFacturas.data[i];
        const obje = this.arr_documentos_to_simulate.find(
          (x) =>
            x.id == objeto.id
        );
        if (obje) {
          this.arr_documentos_to_simulate.splice(
            this.arr_documentos_to_simulate.indexOf(obje),
            1
          );
        } 
      }
      this.selection.clear();
    } else {
      for (let i = 0; i < this.dataSourceFacturas.data.length; i++) {
        let objeto = this.dataSourceFacturas.data[i];
        const obje = this.arr_documentos_to_simulate.find(
          (x) =>
            x.id == objeto.id
        );
        if (obje) {
          //
        } else {
          this.arr_documentos_to_simulate.push(objeto);
        }
      }
      this.selection.select(...this.dataSourceFacturas.data);
    }

    this.total_group_by()
  }

  clone_array(arr:any)
  {
    let aa =  JSON.stringify(arr);
    return  JSON.parse(aa)
  }

  seleccionar(){
    var deuda=0;
    for(let i in this.arr_documentos_to_simulate){
      deuda = deuda + this.arr_documentos_to_simulate[i].mnt_total
    }
    const dialogRef = this.dialog.open(PagoSeleccion, {
      maxHeight: '80vh',
      width:'1300px',
      disableClose: true
    });
    console.log(deuda);
    dialogRef.componentInstance.facturas = this.arr_documentos_to_simulate;
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.componentInstance.deuda = deuda
    dialogRef.afterClosed().subscribe((result:any) => {

        this.valForm.reset()
        this.arr_documentos_to_simulate = [];
        this.disabled_enviar = true;
        this.load_table();

        if(result == true){
          this.router.navigateByUrl('/home/operaciones-proveedores');
        }

    });
  }

  carga_manual(){
    const dialogRef = this.dialog.open(DialogCargaManualOnlyComponent,{
      width:'700px'
    });
    dialogRef.componentInstance.company_id = this.company_id;
    dialogRef.afterClosed().subscribe((result:any) => {
    });
  }

  reset_selection() {
    this.valForm.reset()
    this.arr_documentos_to_simulate = [];
    this.disabled_enviar = true;
    this.load_table()
  }

  async guardar_visita() {
    try {
      let value = {'pantalla': 'pago-proveedores'}
      const bd: any = await this.settings.get_query(2, 'visitas-clientes-pantalla/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          
        break;
        default:

        break;
      }
    } catch (bd: any) {
      this.settings.manage_errors(bd.status, "Set empresa local storage", bd, false);
    }
  }

  open_credenciales(){
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false,
    });
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.afterClosed().subscribe((result:any) => { 
      this.paginator.firstPage()
      this.load_all()
    });
  }

  async estado_enrol(valor:any) {
    let endpoint: any = await this.settings.get_query(1, 'get-libro/'+valor+"/")
    let body_data = endpoint._body
    if(!body_data['error']){
      // this.status_clave=body_data.status_clave;
    }
  }

}