import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import { ModalDialog } from 'src/app/modal/modal';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;


@Component({
  selector: 'cambiar-empresa',
  templateUrl: './cambiar-empresa.html',
  styleUrls: ['./cambiar-empresa.less']
})


export class CambiarEmpresa implements OnInit {
   
  element:any;


  constructor(public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService, private dialogRef: MatDialogRef<CambiarEmpresa>){

  }


  stateCtrl = new FormControl();
  filteredStates!: Observable<State[]>;
  toHighlight: string = '';

  states:State[]=[]
  public selected2:any;
  rol:any;

  ngOnInit() {

    this.rol = localStorage.getItem('role')

    this.empresas_relacionadas()


  }


  myControl = new FormControl();
  filteredOptions!: Observable<string[]>;
  options: State[] = [];

  filterStates(name: string) {
    this.toHighlight = name;
    return this.states.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  company_selected="";
  load:boolean=false;
  async load_companies() {
    try {
      this.options = [];
      this.states = [];
  
      this.load = true;

      const bd: any = await this.settings.get_query(1, '/cliente/empresas-relacionadas/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        let company_id = "";

        console.log(body_data)

        for(let i=0;i<body_data.success.info.length;i++){
          let id=body_data.success.info[i].company_id;
          let name=body_data.success.info[i].business_name;
          let rut=body_data.success.info[i].rut;
          let ruta=body_data.success.info[i].ruta;

          this.states.push({
            id:id, name:name,rut:rut, ruta:ruta
          })
          this.options.push({
            id:id, name:name,rut:rut, ruta:ruta
          })

          this.filteredStates = this.stateCtrl.valueChanges
          .pipe(
            startWith(''),
            map((state:any) => state ? this.filterStates(state) : this.states.slice())
          );


        }

        if (body_data.success.info.length > 0)
        {
          
          let company_id = Number(localStorage.getItem('company_id'))
          this.selected2 = Number(localStorage.getItem('company_id')) //company_id

          let ttt = body_data.success.info.find((obj:any) => obj.company_id == company_id)
          if (ttt != null){
            this.myControl.setValue(ttt.business_name)
            this.company_selected = ttt.business_name;
            this.element.company_selected = (this.TextAbstract(ttt.business_name, 75)).toUpperCase();
          }

        
          
        }


        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          map((value:any) => this._filter(value))
        );




        
        
          this.load = false;



          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  findObjectByKey(array:any, key:any, value:any) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i];
        }
    }
    return null;
  }


  private _filter(value: any): any[] {

    let filterValue;
    if(value.name){
      filterValue = value.name.toLowerCase();
    }else{
      filterValue = value.toLowerCase();
    }


    return this.options.filter(option => option.name.toLowerCase().includes(filterValue) === true).slice(0, 100);;
  }


  id_company:any;
  ruta:any;
  nombre_empresa: string = "";
  selected = ($e:any) => {
    this.ruta = $e.option.value.ruta;
    this.id_company = $e.option.id
    this.nombre_empresa = $e.option.value.name;

  }

  displayFn(option: any): string {
    return option && option.name ? option.name : option;
  }

  aceptar(){



    let id_company = this.id_company;
    localStorage.setItem('company_id', String(id_company));
    localStorage.setItem('business_name', String(this.nombre_empresa));
    const event = new CustomEvent('companyIdChanged', { detail: this.id_company });
    window.dispatchEvent(event);

    this.set_storage_detail_final();


    if(this.ruta == "home/onboarding"){
      this.router.navigate(['home/onboarding'], { replaceUrl: true });
      window.location.reload();
    }else if(this.ruta == "home"){
      if(this.router.url == "/home/onboarding"){
        this.router.navigate(['home'], { replaceUrl: true });
      }
    }

    this.showmsg = true;

    this.dialogRef.close();

    this.open_input_dialog("Cambiar empresa activa", "Cambio correcto",
      `Se cambio la empresa activa`,
      "Ahora podras visualizar la información de la empresa seleccionada","check_circle","#3BA46F");


  }

  showmsg:boolean=false;

  TextAbstract(text:any, length:any) {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    var last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return text + "..";
  }

  async empresas_relacionadas() {
    this.options = [];
    this.states = [];
    try {
      this.load = true;
      const bd: any = await this.settings.get_query(1, 'cliente/empresas-relacionadas/');
      var body_data: any =bd._body;
      var data = body_data.success.info;
      switch (bd.status) {
        case 200:

          if (data.length > 0) {

            for(let i in data){

   
                this.states.push({
                  id:data[i].company_id, name:data[i].business_name,rut:data[i].rut, ruta:data[i].ruta
                })
                this.options.push({
                  id:data[i].company_id, name:data[i].business_name,rut:data[i].rut, ruta:data[i].ruta
                })

            }


            this.filteredStates = this.stateCtrl.valueChanges
            .pipe(
              startWith(''),
              map((state:any) => state ? this.filterStates(state) : this.states.slice())
            );

            let company_id = Number(localStorage.getItem('company_id'))
            this.selected2 = Number(localStorage.getItem('company_id'))
            let empresa = data.find((obj:any) => obj.company_id == company_id)
            if (empresa != null){
              this.myControl.setValue(empresa.business_name)
              this.company_selected = empresa.business_name;
              this.element.company_selected = (this.TextAbstract(empresa.business_name, 75)).toUpperCase();
              localStorage.setItem('business_name', (this.TextAbstract(empresa.business_name, 75)).toUpperCase())
            }
          }
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            debounceTime(0),
            map((value:any) => this._filter(value))
          );
          this.load = false;


          
        break;
        default:
          this.load = false;
        break;
      }
    } catch (bd:any) {
      console.log("error", bd)
      this.load = false;
      this.settings.manage_errors(bd.status, "Cambiar empresa", bd);
    }
  }


  async set_storage_detail_final() {
    try {
      
      let company_id = localStorage.getItem('company_id')
      const bd: any = await this.settings.get_query(1, "clientcartera/" + company_id + "/");
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        if (body_data.datos != null) {

          localStorage.setItem('rut', body_data.datos.rut)
          
        }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  open_input_dialog(title:any,subtitle:any,mensajeprincipal:any, mensajenegrita:any,icon:any,coloricon:any) {
    const dialogRef = this.dialog.open(ModalDialog, {
      width: '700px',
      autoFocus: false,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
    dialogRef.afterClosed().subscribe((result) => {
    });
  }


}



export interface State {
  id: any;
  name:any,
  rut:any,
  ruta: any
  
}