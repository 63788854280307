import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgendarOnboarding } from 'src/app/onboarding/agendar/agendar';
import { SettingsService } from 'src/app/settings/settings.service';

@Component({
  selector: 'app-declaracion-jurada',
  templateUrl: './declaracion-jurada.component.html',
  styleUrl: './declaracion-jurada.component.less',
  encapsulation: ViewEncapsulation.None,
})
export class DeclaracionJuradaComponent implements OnInit {
  documentType: string;
  company_id = localStorage.getItem('company_id');
  step: number = 0;

  valFormStep1: FormGroup;
  valFormStep2: FormGroup;
  valFormStep3: FormGroup;
  valFormStep4: FormGroup;

  isReadonly = true;
  isButtonEnabled = false;

  beneficiarios: any[] = [];
  controlEfectivo: any[] = [];

  isLoading: boolean = true;
  isLoadingStep: boolean = false;
  buttonLoading: boolean = false;

  documentStatus: any = {
    antecedentes_declarante: false,
    beneficiarios_finales: false,
    control_efectivo: false,
    persona_natural: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DeclaracionJuradaComponent>,
    private settings: SettingsService,
    public snackBar: MatSnackBar,
  ) {
    this.valFormStep1 = fb.group({
      direccion: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      comuna: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      ciudad: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      telefono: ['', [Validators.required, Validators.pattern('^[0-9]{1}\\s[0-9]{4}\\s[0-9]{4}$')]]
  });
  

    this.valFormStep2 = fb.group({
      nombre: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      direccion: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      ciudad: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      pais: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });

    this.valFormStep3 = fb.group({
      nombre: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      direccion: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      ciudad: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      pais: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });

    this.valFormStep4 = fb.group({
      relacion_pj_declarante: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });

    this.documentType = data.documentType;
  }

  ngOnInit(): void {
    this.getDocumentos();
  }

  formatPhone(input: any) {
    let value = typeof input === 'object' ? input.target.value : input;
    value = value.replace(/[^\d\s]/g, '');
    
    if (typeof input === 'object' && input.inputType === 'deleteContentBackward') {
        value = value.slice(0, -1);
    }
    
    value = value.replace(/\s/g, '');
    
    if (value.length > 0) {
        if (value.length >= 5) {
            value = value.substring(0, 1) + ' ' + 
                   value.substring(1, 5) + ' ' + 
                   value.substring(5, 9);
        } else if (value.length >= 1) {
            value = value.substring(0, 1) + ' ' + 
                   value.substring(1);
        }
    }
    
    if (typeof input === 'object') {
        this.valFormStep1.patchValue({
            telefono: value
        }, {emitEvent: false});
    }
    return value;
  }


  async getDocumentos() {
    this.isLoading = true;
    try {
      this.isLoading = true;
      const response: any = await this.settings.get_query(1, `/onboarding/data-onboarding/${this.company_id}/`);
      const documentData = response._body.success.info.set_documentos.set1.declaracion_jurada;

      // Antecedentes declarante
      const AntecedentesDeclarante = documentData.antecedentes_declarante;

      if (AntecedentesDeclarante?.domicilio?.direccion && 
        AntecedentesDeclarante?.domicilio?.comuna && 
        AntecedentesDeclarante?.domicilio?.ciudad && 
        AntecedentesDeclarante?.telefono) {
        this.documentStatus.antecedentes_declarante = true;
      } else {
         this.documentStatus.antecedentes_declarante = false;
      }

      this.valFormStep1.patchValue({
        direccion: AntecedentesDeclarante?.domicilio?.direccion || '',
        comuna: AntecedentesDeclarante?.domicilio?.comuna || '',     
        ciudad: AntecedentesDeclarante?.domicilio?.ciudad || '',
        telefono: AntecedentesDeclarante?.telefono ? this.formatPhone(AntecedentesDeclarante.telefono) : ''
      });

        
      // Beneficiarios finales
    if (documentData.beneficiarios_finales?.length > 0) {
      this.documentStatus.beneficiarios_finales = true;
      this.beneficiarios = documentData.beneficiarios_finales.map((beneficiario: any) => ({
        nombre: beneficiario.nombre,
        domicilio: {
          direccion: beneficiario.domicilio.direccion,
          ciudad: beneficiario.domicilio.ciudad,
          pais: beneficiario.domicilio.pais
        }
      }));
    } else {
      this.documentStatus.beneficiarios_finales = false;
      this.beneficiarios = [];
    }

    // Control efectivo
    if (documentData.control_efectivo?.length > 0) {
      this.documentStatus.control_efectivo = true;
      this.controlEfectivo = documentData.control_efectivo.map((control: any) => ({
        nombre: control.nombre,
        domicilio: {
          direccion: control.domicilio.direccion,
          ciudad: control.domicilio.ciudad,
          pais: control.domicilio.pais
        }
      }));
      
    } else {
      this.documentStatus.control_efectivo = false;
      this.controlEfectivo = [];
    }

    // Persona natural
    if (documentData.persona_natural?.relacion_pj_declarante) {
      this.documentStatus.persona_natural = true;
      this.valFormStep4.patchValue({
        relacion_pj_declarante: documentData.persona_natural.relacion_pj_declarante,
      });
    } else {
      this.documentStatus.persona_natural = false;
    }
      
    this.isLoading = false;

    } catch (error) {
      console.error('Error al obtener datos declaracion jurada:', error); 
      this.isLoading = false;

    } finally {
      this.isLoading = false;
    }
    console.log('Document Status Declaracion jurada:', this.documentStatus);
  }

  get allDocumentsCompleted(): boolean {
    return (
      this.documentStatus.antecedentes_declarante &&
      this.documentStatus.beneficiarios_finales &&
      this.documentStatus.control_efectivo &&
      this.documentStatus.persona_natural
    );
  }

  async putStep1() {
    this.isLoadingStep = true;
    this.buttonLoading = true;
    try {
      const formData = {
        data_documento: {
          antecedentes_declarante: {
            domicilio: {
              direccion: this.valFormStep1.value.direccion,
              comuna: this.valFormStep1.value.comuna,
              ciudad: this.valFormStep1.value.ciudad
            },
            telefono: this.valFormStep1.value.telefono
          }
        },
        set_documento: "set1",
        tipo_documento: "declaracion_jurada"
      };
  
      const response: any = await this.settings.get_query(3, `/onboarding/data-onboarding/${this.company_id}/`, formData);
  
      if (response && response.status === 200) {
        this.step = 0;
        this.documentType = 'Declaración jurada';

        await this.getDocumentos(); 

        this.snackBar.open('Los datos fueron guardados', 'X', {
          duration: 20000,
          panelClass: 'simpli-snack-onboarding'
        });
      }

    } catch (error) {
      console.error('Error al editar antecedentes:', error);
    } finally {
      this.isLoadingStep = false;
      this.buttonLoading = false;
    }
  }

  async putStep2() {
    this.isLoadingStep = true;
    this.buttonLoading = true;
    try {
      const formData = {
        data_documento: {
          beneficiarios_finales: {
            identificacion: "",
            nombre: this.valFormStep2.value.nombre,
            domicilio: {
              direccion: this.valFormStep2.value.direccion,
              ciudad: this.valFormStep2.value.ciudad,
              pais: this.valFormStep2.value.pais,
            },
            porcentaje_participacion: 0,
          },
        },
        set_documento: "set1",
        tipo_documento: "declaracion_jurada" 
      };
  
      const response: any = await this.settings.get_query(3, `/onboarding/data-onboarding/${this.company_id}/`, formData);

      if (response && response.status === 200) {
        this.step = 0;
        this.documentType = 'Declaración jurada';

        await this.getDocumentos(); 

        this.snackBar.open('Los datos fueron guardados', 'X', {
          duration: 20000,
          panelClass: 'simpli-snack-onboarding'
        });
      }

    } catch (error) {
      console.error('Error al editar beneficiaros finales:', error);
    } finally {
      this.isLoadingStep = false;
      this.buttonLoading = false;
    }
  } 

  async putStep3() {
    this.isLoadingStep = true;
    this.buttonLoading = true;

    try {
      const formData = {
        data_documento: {
          control_efectivo: {
            identificacion: "",
            nombre: this.valFormStep3.value.nombre,
            domicilio: {
              direccion: this.valFormStep3.value.direccion,
              ciudad: this.valFormStep3.value.ciudad,
              pais: this.valFormStep3.value.pais,
            },
            porcentaje_participacion: 0,
          },
        },
        set_documento: "set1",
        tipo_documento: "declaracion_jurada" 
      };
  
      const response: any = await this.settings.get_query(3, `/onboarding/data-onboarding/${this.company_id}/`, formData);

      if (response && response.status === 200) {
        this.step = 0;
        this.documentType = 'Declaración jurada';

        await this.getDocumentos(); 

        this.snackBar.open('Los datos fueron guardados', 'X', {
          duration: 20000,
          panelClass: 'simpli-snack-onboarding'
        });
      }

    } catch (error) {
      console.error('Error al editar control de efectivo:', error);
    } finally {
      this.isLoadingStep = false;
      this.buttonLoading = false;
    }

  }

  async putStep4() {
    this.isLoading = true;
    this.buttonLoading = true;

    try {
      const formData = {
        data_documento: {
          persona_natural: {
            nacionalidad: "",
            identificacion: "",
            primer_nombre: "",
            segundo_nombre: "",
            apellido_paterno: "",
            apellido_materno: "",
            lugar_origen: "",
            relacion_pj_declarante: this.valFormStep4.value.relacion_pj_declarante,
          },
        },
        set_documento: "set1",
        tipo_documento: "declaracion_jurada" 
      };
  
      const response: any = await this.settings.get_query(3, `/onboarding/data-onboarding/${this.company_id}/`, formData);

      if (response && response.status === 200) {
        this.snackBar.open('Los datos fueron guardados', 'X', {
          duration: 20000,
          panelClass: 'simpli-snack-onboarding'
        });
      }

    } catch (error) {
      console.error('Error al editar relacion:', error);
    } finally {
      this.isLoading = false;
      this.buttonLoading = false;
    }
  }

  enviarDeclaracionJurada() {
    this.dialogRef.close();
  }

  
  agendar() {
    let dialogRef = this.dialog.open(AgendarOnboarding, {
      width: '700px',
      minHeight: '600px',
      autoFocus: false,
    });
  }
  
  
  pdfUrl: string = 'https://drive.google.com/file/d/1I82htebbGNBG48ky-IplhjiTHq8hD52x/preview';

  selectBeneficiario(beneficiario: any) {
    this.step = 2;
    this.valFormStep2.patchValue({
      nombre: beneficiario.nombre,
      direccion: beneficiario.domicilio.direccion,
      ciudad: beneficiario.domicilio.ciudad,
      pais: beneficiario.domicilio.pais
    });
  }
    
  selectControlEfectivo(control: any) {
     this.step = 3;
     this.valFormStep3.patchValue({
       nombre: control.nombre,
      direccion: control.domicilio.direccion,
      ciudad: control.domicilio.ciudad,
      pais: control.domicilio.pais
     });
  }

  cerrarDialogo() {
    this.dialogRef.close(true);
  }
}
