<div mat-dialog-title>

      <h1 class="titulo-simpli">Certificado Digital</h1>
      <small class="subtitulo-simpli">Cambia o actualiza tu Certificado Digital</small>

      <div class="btn_cerrar" mat-dialog-close>

        <i class="fa-sharp fa-regular fa-xmark"></i>

      </div>

</div>

<div mat-dialog-content>
    <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <mat-card class="animate__animated animate__fadeIn" style="border: none;box-shadow: none !important;">

            <mat-card-content>

                <form  [formGroup]="valForm">

                    <div class="row">


                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                            <mat-form-field color="primary" appearance="fill">
                                <mat-label>Adjuntar Certificado</mat-label>
                                <input required matInput formControlName="certificado_sii" readonly (click)="open_certificado()" >
                                <input id="certificado_pfx" (change)="subir_certificado_new($event)" type="file" accept=".pfx"  style="display: none;">
                                <mat-spinner color="primary" diameter="20" matSuffix *ngIf="saving_certificado"></mat-spinner>
                                <mat-icon matSuffix *ngIf="success_certificado" class="animate__animated animate__fadeIn" style="color:#3BA46F;">check_circle</mat-icon>
                                <mat-icon matSuffix *ngIf="!success_certificado" class="animate__animated animate__fadeIn" style="color: var(--simpli-primary);">attach_file</mat-icon>
                                <mat-hint> Solo se admite el formato PFX y un peso límite de 50 MB</mat-hint>
                                <mat-error *ngIf="valForm.controls['certificado_sii'].hasError('incorrect')">
                                    {{message_pfx}}
                                </mat-error>
                            </mat-form-field>


                            <mat-form-field color="primary" appearance="fill">
                              <mat-label>Rut Autorizado de la Empresa</mat-label>
                              <input required matInput formControlName="rut_autorizado"  (change)="subir_rut_autorizado($event)" >
                              <mat-spinner color="primary" diameter="20" matSuffix *ngIf="saving_rut"></mat-spinner>
                              <mat-icon matSuffix *ngIf="success_rut" class="animate__animated animate__fadeIn" style="color:#3BA46F;">check_circle</mat-icon>
                              <mat-hint>Ingresa el RUT del titular del Certificado</mat-hint>
                              <mat-error *ngIf="valForm.controls['rut_autorizado'].hasError('incorrect')">
                                  Rut Autorizado es inválido.
                              </mat-error>
                            </mat-form-field>

                            <mat-form-field color="primary" appearance="fill">
                                <mat-label>Clave Certificado</mat-label>
                                <input required matInput formControlName="clave_certificado" type="password" (change)="subir_clave_pfx($event)" >
                                <mat-spinner color="primary" diameter="20" matSuffix *ngIf="saving_clave"></mat-spinner>
                                <mat-icon matSuffix *ngIf="success_clave" class="animate__animated animate__fadeIn" style="color:#3BA46F;">check_circle</mat-icon>
                                <mat-hint>Ingresa la clave asociada al Certificado</mat-hint>
                                <mat-error *ngIf="valForm.controls['clave_certificado'].hasError('incorrect')">
                                    Clave del Certificado Digital inválida.
                                </mat-error>
                            </mat-form-field>


                            <div class="leyenda">
 
                                Su clave y certificado están protegidos con altos estándares de seguridad y encriptación. Al continuar, confirma que acepta nuestra
                                <a class="simpli-color2 cursor-pointer text-none" href="https://simplilatam.com/politica-de-privacidad/" target="_blank">política de privacidad</a>
                                diseñada para resguardar su información en todo momento.
        
                            </div>
        
                        </div>







                    </div>



                </form>
            </mat-card-content>
          </mat-card>
    </div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  
          <div [ngClass]="{'disable-btn':desactivar_guardar == true, 'btn-simpli-container':true}">
            <button [disabled]="desactivar_guardar" class="primary-button" mat-flat-button (click)="put_certificado()">
                <mat-label *ngIf="loading == false">Guardar</mat-label>
                <span *ngIf="loading == true"><i style="color:white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
            </button>
          </div>
          
        </div>
    </div>
</div>

