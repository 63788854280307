<div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                <div class="row">

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>

                    <div  class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <form [formGroup]="valFormEstados" class="form-class">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Filtrar por estado</mat-label>
                                        <mat-select (selectionChange)="filtrar_por_estado($event)" formControlName="status">
                                        <mat-option style="background: #e1e1e1;border-bottom: solid 1px #bfbfbf;">
                                            Limpiar
                                        </mat-option>
                                        <mat-divider></mat-divider>
                                          <mat-option [value]="0" style="font-family: 'DM Sans';">
                                            En validación
                                          </mat-option>
                                          <mat-option [value]="1" style="font-family: 'DM Sans';">
                                            Cedida
                                          </mat-option>
                                          <mat-option [value]="5" style="font-family: 'DM Sans';">
                                            Girada
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                </form>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"> 
                                <form [formGroup]="valFormFacturas" class="form-class">
        
                                    <mat-form-field appearance="fill" style="width: 100%">
                                      <mat-label>Buscar</mat-label>
                                      <input matInput (keyup.enter)="add_filter($event.target.value);$event.stopPropagation();" (change)="add_filter($event.target.value);$event.stopPropagation();" formControlName="filter">
                                      <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                              
                                  </form>
                            </div>

                        </div>

                    </div>


        
                </div>
              </div>
            
            


        </div>



        <div class="row animate__animated animate__fadeIn" *ngIf="loading == true && ELEMENT_DATA.length == 0">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="container-loading">

      
                    <div class="img-loading">
                      <img src="../../../../../assets/simpli/cesion/loading.png" class="img-loading1">
                    </div>
              
                    <div style="margin-top: 20px;">
                      <mat-label class="label-loading">
                        <div>La información está siendo procesada.</div>
                        <div>Esto no tomará mucho tiempo.</div>
                      </mat-label>
                    </div>
              
                  </div>
            </div>
    
        </div>
    
        <div class="row animate__animated animate__fadeIn" *ngIf="loading == false && ELEMENT_DATA.length == 0" style="margin-top: 30px;">
    

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                <div class="empty-msg">
                    
                            
                  <div style="padding-top: 50px;text-align: center;">
                    <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
                  </div>
            
                  <div style="padding-top: 50px;text-align: center;">
                    <mat-label class="label-bienvenida">
                      <div class="label-empty">
                        ¡Ups! Al parecer, no tienes operaciones para visualizar.
                      </div>
            
                      <div class="label-empty" style="margin-top: 20px;">
                        Realiza una simulación para que puedas comenzar a operar.
                      </div>
            
                    </mat-label>
                  </div>
            
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                      <div class="btn-simpli-container">
                        <button class="primary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
                      </div>
                      
                    </div>
                  </div>
            
                </div>
              </div>
    
        </div>

        <div class="row animate__animated animate__fadeIn" *ngIf="loading == false && ELEMENT_DATA.length > 0">

            <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <mat-card class="card-tabla desktop-table" style="position: relative;">

                    <div class="table-responsive-simpli add_factoring">
                
                        <table mat-table [dataSource]="dataSource"  matSortActive="name" [matSortStart]="sortDirection" (matSortChange)="sortData($event)" matSort>
                    

                        <ng-container matColumnDef="id" sticky>
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>N° Operación</th>
                            <td mat-cell *matCellDef="let data"> 
                                <input class="no-input" readonly value="{{data.id}}">
                            </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Estado</th>
                            <td mat-cell *matCellDef="let data" style="min-width: 150px !important;"> 
                                <input *ngIf="data.status_text != null && (data.status_simulation != 1 && data.status_simulation != 2) " class="no-input" readonly value="{{data.status_text}}">
                                <input *ngIf="data.status_text != null && data.status_simulation == 1" class="no-input" readonly value="Solicitada">
                                <input *ngIf="data.status_text != null && data.status_simulation == 2" class="no-input" readonly value="En evaluación">
                                <input *ngIf="data.status_text == null" class="no-input" readonly value="--">
                            </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="monto_girar">
                            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Monto a girar</th>
                            <td mat-cell *matCellDef="let data" style="min-width: 120px !important;" > 
                            <input class="no-input" readonly value="${{formatMoney(data.monto_girar)}}">
                            </td>
                        </ng-container>
            
                        <ng-container matColumnDef="monto_girado">
                            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Monto girado</th>
                            <td mat-cell *matCellDef="let data" style="min-width: 120px !important;"> 
                            <input class="no-input" readonly value="${{formatMoney(data.monto_girado)}}">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fecha_giro">
                            <th mat-header-cell *matHeaderCellDef>Fecha de giro</th>
                            <td mat-cell *matCellDef="let data" style="min-width: 140px !important;"> 
                            <input class="no-input" readonly value="{{data.fecha_giro}}">
                            </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="excedentes">
                            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Excedentes</th>
                            <td mat-cell *matCellDef="let data" style="min-width: 120px !important;position: relative;" > 
                            <input class="no-input" readonly value="${{formatMoney(data.excedentes)}}">

                            <span [mdePopoverTriggerFor]="appPopovertable" mdePopoverTriggerOn="hover" #popoverTriggerClose="mdePopoverTrigger" class="info-icon-dialog"><i class="fa-light fa-circle-info"></i></span>


                            <mde-popover #appPopovertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                                <mat-card class="popover-card" style="max-width: 370px;position: relative;">
                                    Para solicitar el giro de estos excedentes, se debe pagar el monto de la operación a Simpli.
                                </mat-card>
                            </mde-popover>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="detalle">
                            <th mat-header-cell *matHeaderCellDef  mat-sort-header></th>
                            <td mat-cell *matCellDef="let data" style="min-width: 45px !important;text-align: center;" > 
                                    <span class="btn-detalle" (click)="go_to_detail(data.id, data.simulacion_id, data.status_simulation, data.cesion_parcial)">Ver detalle</span>
                            </td>
                        </ng-container>

            
                    
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="background: white !important;border-radius: 4px !important;"></tr>
                    
                    
                        </table>
                
                
                
                    </div>

                </mat-card>

                <mat-card class="card-tabla resposive-table" style="position: relative;">

                    <table mat-table [dataSource]="dataSource" class="table-res">
              
                      <ng-container matColumnDef="data">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let data; let i=index" style="padding:0;padding-left: 10px !important;padding-right: 10px !important;" (click)="$event.stopPropagation()"> 
              
                          <div [ngClass]="{'box-factura':true}" (click)="$event.stopPropagation()">
              
  
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  style="position: relative;">
                                  <div class="container-label-res"><mat-label class="left-label">N° Operación:</mat-label><mat-label class="right-label">{{data.id}}</mat-label></div>
                                  <div class="container-label-res"><mat-label class="left-label">Monto a girar:</mat-label><mat-label class="right-label">${{formatMoney(data.monto_girar)}}</mat-label></div>
                                  <div class="container-label-res"><mat-label class="left-label">Monto girado:</mat-label><mat-label class="right-label">${{formatMoney(data.monto_girado)}}</mat-label></div>
                                  <div class="container-label-res"><mat-label class="left-label">Excedentes:</mat-label>
                                    <mat-label class="right-label">${{formatMoney(data.excedentes)}}
                                        <span style="margin-left: 10px;font-size: 17px;" [mdePopoverTriggerFor]="appPopovertableRes" mdePopoverTriggerOn="click" #popoverTriggerClose="mdePopoverTrigger"><i class="fa-light fa-circle-info"></i></span>

                                    </mat-label>

                                    <mde-popover #appPopovertableRes="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                                        <mat-card class="popover-card" style="max-width: 370px;position: relative;">
                                            Para solicitar el giro de estos excedentes, se debe pagar el monto de la operación a Simpli.
                                        </mat-card>
                                    </mde-popover>
                                </div>
                                  <div class="container-label-res"><mat-label class="left-label">Estado:</mat-label>
   
                                    <mat-label style="position: relative;" class="right-label">
                                        <span style="margin-right: 10px;color:#ff933b;font-size: 17px;"><i class="fa-light fa-clock"></i></span>
                                        {{data.status_text}}
                                    </mat-label>

            
                                  </div>
              
                                  <div style="display: flex;">
                                    <mat-label (click)="go_to_detail(data.id)" class="pink-label">Ver detalle <i class="fa-solid fa-angle-right"></i></mat-label>
                                  </div>
                                </div>
                              </div>
                          </div>
                          
                          </td>
                      </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsRes"></tr>
                      <tr mat-row *matRowDef="let row; let doc; columns: displayedColumnsRes;" ></tr>
                    </table>
                    
              
                </mat-card>

            </div>

        </div>


        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false">
  

          <paginador-onboarding
          [pageSize]="7"
          [totalElements]="pageLength"
          [currentPage]="page"
          (pageChange)="pageEvent($event)">
          </paginador-onboarding>
        </div>

    </div>

</div>
