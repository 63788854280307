import { Component, Input, OnInit } from '@angular/core';

interface Observation {
    avatar: any;
    nombre: any;
    company_id:any;
    date_created: any;
    efectiva: any;
    id: any;
    porcentaje:any;
    contenido: any;
    fecha:any;
    hora:any
    tipo: null;
    user: any;
    is_marketing: boolean;
  }

@Component({
  selector: 'app-obs-display',
  templateUrl: './obs-display.html',
  styleUrls: ['./obs-display.less']
})
export class ObservationDisplayComponent implements OnInit {

    @Input() observation!: Observation;
    avatarBackground: string = '';
    showInitials: boolean = false;

    ngOnInit() {


        if (!this.observation.avatar) {
            this.showInitials = true;
        }

        this.avatarBackground = this.generateRandomBackground();
    }


    get initials(): string {
        return this.observation.nombre
            .split(' ')
            .map((word:any) => word.charAt(0).toUpperCase())
            .join('');
    }
    

    handleError() {
        this.showInitials = true;
    }

    generateRandomBackground(): string {
    const colors = ['#924eff', '#3b86ff', '#ff81f1', 'var(--simpli-primary)', '#ff2982', '#1b0943'];
    const randomIndex1 = Math.floor(Math.random() * colors.length);
    let randomIndex2 = Math.floor(Math.random() * colors.length);

    while (randomIndex2 === randomIndex1) {
        randomIndex2 = Math.floor(Math.random() * colors.length);
    }

    return `linear-gradient(45deg, ${colors[randomIndex1]}, ${colors[randomIndex2]})`;
    }

    esHoy(cadenaFecha: string): boolean {
        const fechaComparar = this.convertirCadenaAFecha(cadenaFecha);
      
        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0);
        return (
          fechaComparar.getTime() === fechaActual.getTime()
        );
    }

    convertirCadenaAFecha(cadenaFecha: string): Date {
        const partesFecha = cadenaFecha.split(' ')[0].split('/');
      
        return new Date(
          parseInt(partesFecha[2]),
          parseInt(partesFecha[1]) - 1,
          parseInt(partesFecha[0])
        );
    }


}