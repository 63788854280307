import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class PushNotificationService {
  messaging: any;

  constructor(public router: Router, public settings: SettingsService) {
    const firebaseConfig:any = environment.configFirebase
    const fire = initializeApp(firebaseConfig);
    this.messaging = getMessaging(fire);
  }

  async requestPermission() {
    return new Promise(async (resolve, reject) => {
      const permsis = await Notification.requestPermission();
      if (permsis === 'granted') {
        console.log('Notification permission granted.');
        getToken(this.messaging, {vapidKey: 'BNpasM2eSInHZgjsELGEMfEXlcZwoqv_fU17tUlMsRXAMGR0CBbi6Msl8g6OLfGjzBb-u4n1vba06iMjW_erxUU'}).then((currentToken: any) => {
          if (currentToken) {
            this.sendTokenToServer(currentToken);
            resolve(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
            localStorage.setItem('sentToServer','0');
          }
        }).catch((err: any) => {
          console.log('An error occurred while retrieving token. ', err);
          localStorage.setItem('sentToServer','0');
        });
      } else {
        console.log('Unable to get permission to notify.');
        reject(new Error("No se otorgaron los permisos"))
      }
    })
  }

  sendTokenToServer(currentToken: any) {
    if (!this.isTokenSentToServer()) {
      console.log('Sending token to server...');
      this.setTokenSentToServer(true, currentToken);
    } else {
      console.log(currentToken)
      console.log('Token already sent to server so won\'t send it again ' + 'unless it changes');
    }
  }

  isTokenSentToServer() {
    return localStorage.getItem('sentToServer') === '1';
  }

  async setTokenSentToServer(sent: boolean, token: any = null) {
    try {
      const bd: any = await this.settings.get_query(2, 'notificaciones/cliente-device-token/', {token: token});
      var body_data: any =bd._body;
      var data = body_data.success.info;
      switch (bd.status) {
        case 200:
          localStorage.setItem('sentToServer', '1');
        break;
        default:
          localStorage.setItem('sentToServer', '0');
        break;
      }
    } catch (bd:any) {
      this.settings.manage_errors(bd.status, 'Guardar device token', bd, false);
    }
  }

  public messaginObservable = new Observable<MessagePayload>(observe => {
    onMessage(this.messaging, (payload: any) => {
      observe.next(payload);
    });
    return observe;
  })

  receiveMessage() {
    return this.messaginObservable;
  }


  haveNotificationPermissions() : boolean {

    return false
  }


}

interface INotificationPayload {
  body?: string;
  title?: string;
}

interface MessagePayload {
  notification: INotificationPayload,
  data?: { [key: string]: string }
}
