<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" (click)="close(false)">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>


<mat-dialog-content>

    <div style="text-align: center;">

        <div style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
            <i style="font-size: 65px;color:var(--simpli-color8)" class="fa-regular fa-circle-exclamation"></i>
        </div>

        <div class="label-dialog">¡Se detectaron algunos errores en la nómina! Para</div>
        <div class="label-dialog">solucionar esto, descárgala, corrige o completa los</div>
        <div class="label-dialog">campos <strong>destacados en rojo</strong> y vuelve a subirla.</div>

        <div *ngIf="disabled == true" class="l-simpli link-label" (click)="download_nomina()">Descargar nómina para corregir</div>


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      
              <div class="btn-simpli-container">
                <button [disabled]="disabled" [ngClass]="{'disabled-btn-simpli': disabled == true,'primary-button': true }" mat-flat-button (click)="close(true)">
                  Subir nómina corregida
                </button>
              </div>
              
            </div>
          </div>

    </div>


    
</mat-dialog-content>