import { Component, Output, OnInit, EventEmitter, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;

declare var require: any;
import Boost from 'highcharts/modules/boost';
import NoData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';

// Inicializa los módulos
Boost(Highcharts);
NoData(Highcharts);
More(Highcharts);

@Component({
  selector: 'app-grafico-participacion-factoring',
  templateUrl: './grafico-participacion-factoring.component.html',
  styleUrls: ['./grafico-participacion-factoring.component.less']
})
export class GraficoParticipacionFactoringComponent implements OnInit, OnChanges{
  @Input() id:any;
  @Input() participacion_factoring_obj: any;
  show_graph = false
  info:Boolean=false
  cargando:Boolean=true
  @Output() participacion_factoring = new EventEmitter<string>();
  actual:any;
  menos:any;
  classificationData:any[] = []
  elementData:any[] = [];
  factoring:any = {
    "deductions": [],
    "currency": "Monto"
  };

  options: any = { 
    accessibility: {
      enabled: false
    },
    useHTML: true,
    caption: {
      useHMTL: true,
    },
    colors: [
      "#ff81f1",
      "#924eff",
      "#ff2982",
      '#0038e3', 
      '#b175ff', 
      '#002bbf', 
      '#ff9df4', 
      '#ff559f', 
      '#7a3fcc', 
      '#001f99', 
      '#cc68d8', 
      '#e60066', 
      '#c5a3ff',
      '#669fff', 
      '#ffa0e4'  
    ],
    chart: { 
      type: 'pie'
    },    
    title: { text: ''},
    tooltip:{
      pointFormat: '',
      useHTML: true,
      shadow: false,
    },
    plotOptions: { 

    },
    series: [{
      name: '',
      data: this.classificationData,

      dataLabels: {

      } 
    }, 
    
    {
      name: this.factoring.currency,
      data: [],

    }]
  }

  constructor(public settings: SettingsService, private dialog:MatDialog) { }

  @Input() date_start:any;
  @Input() date_end:any;

  ngOnChanges(changes: SimpleChanges) {    
    $("#container-graph").hide()
    this.informacion()
  }

  ngOnInit(){
    $("#container-graph").hide()
  }

  hay_data:boolean=false;
  mostrar_spinner:boolean=false;
  loading:boolean=false;

  total_facturas:any=0;
  total_cantidad:any=0;

  informacion = async () => {   
    this.cargando=true ;
    this.loading = true;
    this.mostrar_spinner = true;
    let mes_actual
    let mes_menos
    if (this.participacion_factoring_obj === undefined ) {
    //Mes actual
    let mensual = new Date();
    let mes_actual_ultimo = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 0);
    let mes1 = new Date(mes_actual_ultimo)
    let y1 = mes1.getFullYear();
    let m1:any = mes1.getMonth()+1;
    let d1:any = mes1.getDate();
    if(m1<10){m1="0"+m1}
    if(d1<10){d1="0"+d1}
    mes_actual = y1+"-"+m1+"-"+d1

    //Mes -6
    let mesmenos=new Date(mes_actual_ultimo.getTime() - (3650*60*60*1000)*1);
    let ultimomenos = new Date(mesmenos.getFullYear(), mesmenos.getMonth(), 1);
    let mes6 = new Date(ultimomenos)
    let y2 = mes6.getFullYear();
    let m2:any = mes6.getMonth()+1;
    let d2:any = mes6.getDate();
    if(m2<10){m2="0"+m2}
    if(d2<10){d2="0"+d2}
    mes_menos = y2+"-"+m2+"-"+d2
    } else{
      mes_actual = this.participacion_factoring_obj.end
        mes_menos = this.participacion_factoring_obj.start
    }
    this.actual = mes_actual
    this.menos = mes_menos
    this.show_graph = false

    let resultado:any = (await this.settings.get_query(1, 'ranking-cesionario/'+this.id+'/'+"?inicio="+this.date_start+"&fin="+this.date_end))      
    let body_data=resultado._body;
    
    if(!body_data['error']){


      if(body_data.resumen.length > 0){
        this.hay_data = true;
        $("#container-graph").show()
      }else{
        this.hay_data = false;
        $("#container-graph").hide()
      }

      this.cargando=false
      this.mostrar_spinner = false;

      this.factoring.deductions=[]
      
      let deductions_total=0
      let totalFacturas=0
      for(let i=0; i< body_data.resumen.length; i++){
        if(body_data.resumen[i].total == false){          
          this.factoring.deductions.push({"ele": "<span style='font-weight: 200;font-size: 14px;color: #858489;font-family: DM Sans;'>"+body_data.resumen[i].factoring.substring(0, 20)+"</span> <br> <span style='font-family: 'DM Sans;font-size: 20px;'>"+body_data.resumen[i].porcentaje+" %</span>", "val": body_data.resumen[i].porcentaje})
        }
        else{
          deductions_total = body_data.resumen[i].monto
          totalFacturas = body_data.resumen[i].conteocesiones
        }
      }

      let array=[]

      for (let i = 0; i < this.factoring.deductions.length; i += 1) {
        array.push({"name": this.factoring.deductions[i].ele, "y": this.factoring.deductions[i].val});
      };
  
      this.classificationData[0] = {
        name: "",
        y: deductions_total,
        color: 'white',
        nrElements: this.factoring.deductions.length
      };

      this.elementData=array
      this.options.series[1].data=this.elementData;

      this.total_cantidad = totalFacturas;
      this.total_facturas = this.formatMoney(deductions_total);
      
      Highcharts.chart('container-graph', this.options)
      this.show_graph = true
      this.participacion_factoring.emit('2');
      this.loading = false;      
    }
    else{
      $("#container-graph").hide()
      this.cargando=false
      this.info=false
      this.mostrar_spinner = false;
      //this.settings.empty_local_data();
      this.participacion_factoring.emit('2') 
      this.loading = false;
    }
  }

  open_credenciales(){
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus:false,
    });

    dialogRef.componentInstance.id_company = this.id;
    dialogRef.afterClosed().subscribe((result:any) => {
    });
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  }   
}
