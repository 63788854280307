<div class="row row_main">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  
          <h1 class="titulo-simpli">Sincronización de credenciales</h1>

  
    </div>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div>
    
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="p-label">
                    Esta opción permite <strong>sincronizar los datos</strong> de tu empresa en esta plataforma, con<br>
                    el fin de generar procesos más <strong>simples y rápidos.</strong><br>


                    <br><br>
    
                    A continuación, revisa las principales <strong>ventajas de la sincronización:</strong><br>
    
                  </div>
    
                  <div class="row" style="margin-top: 40px;">
    
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                      <div class="img-ventaja">
                        <img src="../../../../../assets/simpli/cesion/ventaja1.png">
                      </div>
                      <div class="text-img-label">
                        Cede sin salir de la<br> plataforma.
                      </div>
                    </div>
    
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                      <div class="img-ventaja">
                        <img src="../../../../../assets/simpli/cesion/ventaja2.png">
                      </div>
                      <div class="text-img-label">
                        Evita errores de<br> autenticación.
                      </div>
                    </div>
    
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                      <div class="img-ventaja">
                        <img src="../../../../../assets/simpli/cesion/ventaja3.png">
                      </div>
                      <div class="text-img-label">
                        Opera desde cualquier<br> dispositivo.
                      </div>
                    </div>
    
                  </div>
    
                  <div class="row" style="margin-top: 20px;">
    
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                      <div class="img-ventaja">
                        <img src="../../../../../assets/simpli/cesion/ventaja4.png">
                      </div>
                      <div class="text-img-label">
                        Cede más de una<br> factura con un clic.
                      </div>
                    </div>
    
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                      <div class="img-ventaja">
                        <img src="../../../../../assets/simpli/cesion/ventaja5.png">
                      </div>
                      <div class="text-img-label">
                        Agiliza tus<br> operaciones.
                      </div>
                    </div>
    
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                      <div class="img-ventaja">
                        <img src="../../../../../assets/simpli/cesion/ventaja6.svg">
                      </div>
                      <div class="text-img-label">
                        Accede a tu<br> Dashboard contable.
                      </div>
                    </div>
    
                  </div>
    
    
                  <div style="margin-top: 60px;" class="en_responsive">
                    <button class="primary-button" mat-flat-button  routerLink="/home">
                      Ir al home
                    </button>
                  </div>
              </div>
    
    
    
        </div>
    
    </div>
  
  </div>
  
  
  