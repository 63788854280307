import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'notification-dialog',
    template: `
        <div mat-dialog-content style="text-align: center; padding: 15px;">Activa las notificaciones para recibir información actualizada de tus operaciones, directamente en tu equipo</div>
        <div mat-dialog-actions align="center" style="padding-bottom: 20px;">
          <button mat-flat-button class="primary-button" (click)="dialogRef.close('Y')">Activar</button>
          <button mat-flat-button class="secondary-button" (click)="dialogRef.close('N')">Bloquear</button>
        </div>
    `,
    styles: ['.btn-notif { background: white; border: 1px solid var(--simpli-primary); box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important; width: 120px; border-radius: 5px !important; margin-left: 10px !important; font-family: "DM Sans" !important; }', 
            '.btn-active { background: var(--simpli-primary); color: white; }'
            ]
})
export class NotificationsDialog  {
    constructor(public dialogRef: MatDialogRef<NotificationsDialog>) { }
}
