import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrl: './rich-text.component.less',
})
export class RichTextComponent implements AfterViewInit {

  @ViewChild('editor') editor!: ElementRef<HTMLDivElement>;
  selectedColor: string = '#000000';
  @Input() parent: any;

  private observer!: MutationObserver;

  ngAfterViewInit() {
    this.observer = new MutationObserver(() => {
      this.onEditorInput();
    });

    this.observer.observe(this.editor.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true
    });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  constructor(private renderer: Renderer2) {}

  disabled(){
    this.editor.nativeElement.contentEditable = 'false';
    this.editor.nativeElement.classList.add('disabled-editor');
  }

  format(command: string, value?: string) {
    if (command === 'createLink') {
      const url = prompt('Ingresa el link');
      if (url) {
        document.execCommand(command, false, url);
      }
    } else {
      document.execCommand(command, false, value);
    }
  }

  applyColor() {
    document.execCommand('foreColor', false, this.selectedColor);
  }

  selectAllContent() {
    const range = document.createRange();
    range.selectNodeContents(this.editor.nativeElement);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  copyContent() {
    this.selectAllContent();
    document.execCommand('copy');
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const img = document.createElement('img');
        img.src = e.target?.result as string;
        img.style.maxWidth = '100%';
        this.insertImageAtCaret(img);
      };
      reader.readAsDataURL(file);
    }
  }

  insertImageAtCaret(img: HTMLImageElement) {
    const range = window.getSelection()?.getRangeAt(0);
    if (range) {
      range.deleteContents();
      range.insertNode(img);
    }
  }

  observacion: any = "";
  asignarContenidoEditor(set_text: any): void {
    this.editor.nativeElement.innerHTML = set_text;
  }

  obtenerContenidoEditor(): string {
    return this.editor.nativeElement.innerHTML;
  }

  clear() {
    this.editor.nativeElement.innerHTML = "";
  }

  new_obs() {
    this.editor.nativeElement.innerHTML = "";
    this.parent.reset_obs();
  }

  selectFile(): void {
    const inputElement: HTMLElement | null = document.getElementById('simg');
    if (inputElement) {
      inputElement.click();
    }
  }

  changeFontSize(action: 'increase' | 'decrease'): void {
    const editor = this.editor.nativeElement;

    // Obtener el tamaño de la fuente actual
    let currentFontSize = parseInt(window.getComputedStyle(editor).fontSize);

    // Calcular el nuevo tamaño de la fuente
    if (action === 'increase') {
      currentFontSize += 2;
    } else if (action === 'decrease') {
      currentFontSize -= 2;
    }

    // Aplicar el nuevo tamaño de fuente al contenido del editor
    editor.style.fontSize = currentFontSize + 'px';
  }

  onEditorInput(): void {
    var contenidoEditado = this.editor.nativeElement.innerHTML.trim();
    console.log("contenidoEditado", contenidoEditado)
    if (contenidoEditado != "") {
      this.parent.disabled = false;
    } else {
      this.parent.disabled = true;
    }
  }

  onEditorPaste(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData: any = event.clipboardData;
    const items: any = clipboardData?.items;

    if (!items) {
        return;
    }

    let handled = false;

    console.log("ITEMS", items);

    // Primero verificamos si hay una imagen
    for (const item of items) {
        if (item.type.startsWith('image/')) {
            if (handled) continue;
            const file = item.getAsFile();
            const reader = new FileReader();

            reader.onload = (loadEvent: any) => {
                const img = document.createElement('img');
                img.src = loadEvent.target.result;
                this.insertImageAtCaret(img);
                this.onEditorInput();
            };

            console.log("Processing image");

            reader.readAsDataURL(file);
            handled = true;
            break;
        }
    }

    // Si no hay imagen, verificamos si hay HTML
    if (!handled) {
        for (const item of items) {
            if (item.type === 'text/html') {
                if (handled) continue;
                item.getAsString((html: string) => {
                    document.execCommand('insertHTML', false, html);
                    this.onEditorInput();
                });

                console.log("Processing HTML");

                handled = true;
                break;
            }
        }
    }

    // Si no hay HTML, verificamos si hay texto plano
    if (!handled) {
        for (const item of items) {
            if (item.type === 'text/plain') {
                if (handled) continue;
                item.getAsString((text: string) => {
                    document.execCommand('insertText', false, text);
                    this.onEditorInput();
                });

                console.log("Processing text");

                handled = true;
                break;
            }
        }
    }
  }

  undo() {
    document.execCommand('undo', false);
  }

  redo() {
    document.execCommand('redo', false);
  }



}
