

<section class="area-login animate__animated animate__fadeIn" [ngStyle]="{ 'background-image': 'url(' + randomImage + ')' }">
  <mat-card class="login" style="position: relative;">
    <mat-card-content>

      <div class="simpli-floating-box-header">
        <div class="simpli-floating-box-logo text-center">
          <img src="../../assets/simpli/simplilogo2.svg" height="90px">
        </div>
        <div class="simpli-floating-box-title text-center">
          <span class="color-tertiary"> 
            Recupera tu contraseña
          </span>
        </div>
      </div>

      <div class="simpli-floating-box-body">
        <form [formGroup]="valForm" (submit)="submitForm($event, valForm.value)">
          <mat-form-field appearance="fill">
            <mat-label>Correo electrónico</mat-label>
            <input matInput  formControlName="email" type="email" required>
            <mat-hint>Ingresa tu correo electrónico</mat-hint>
            <mat-error *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-error *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">
              Debe ser un correo electrónico valido
            </mat-error>
            <mat-error *ngIf="valForm.controls['email'].hasError('invalid')">
              {{ errorMsg }}
            </mat-error>
          </mat-form-field>
      

          <div class="btn-container">
            <button class="primary-button" [disabled]="show_spinner" mat-flat-button type="submit" color="primary" style="margin-right: 0px;">
              <mat-label *ngIf="show_spinner == false">Recuperar contraseña</mat-label>
              <span *ngIf="show_spinner == true"><i class="fa-solid fa-spinner fa-spin-pulse" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
            </button>
          </div>

          <div class="center-div">
            <a mat-button  class="label-button" routerLink="/login">Volver</a>
          </div>

        </form>
      </div>

    </mat-card-content>
  </mat-card>
</section>