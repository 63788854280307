import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';

@Component({
  selector: 'no-hay-registros',
  templateUrl: './sin-registros.html',
  styleUrls: ['./sin-registros.scss'],
})


export class SinRegistrosCustom implements OnInit {

@Input() text:any="No se encontró información relacionada."

  constructor(
    private settings:SettingsService, 
  ) { 




  }





  ngOnInit(): void {



    
  }




}

