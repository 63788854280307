<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  <h1 class="titulo-simpli" style="position: relative;">Agregar Compras</h1>
  <small class="subtitulo-simpli">Vista Previa</small>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

  <mat-stepper labelPosition="bottom" #stepper linear style="width: 100%;">
    
    <ng-template matStepperIcon="edit">
      <mat-icon >check</mat-icon>
    </ng-template>



    <mat-step>
      <ng-template matStepLabel>Insertar Datos</ng-template>

      <div style="width: 100%;position: relative;">

        <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando_spinner_manual">
          <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
        </div>

        <div class="row" style="width: 100%;">

          <!-- <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <h1 class="titulo-simpli">Subir archivo</h1>
            <small class="subtitulo" *ngIf="!success && !warning">Puedes utilizar la tabla o descargar la planilla y subir un archivo</small>
            <small class="subtitulo success-msg-text animate__animated animate__fadeIn"*ngIf="success">
              Carga Exitosa.
            </small>  
            <small class="subtitulo warning-msg-text animate__animated animate__fadeIn"*ngIf="warning">
              Hay algunos errores en los registros, verifique el formato en la Planilla.
            </small>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 align-right">
            <button  mat-mini-fab [disabled]="disabled_subir_file" aria-label="Descargar planilla" (click)="descargar_file()" type="button" class="fab-excel-d"><mat-icon>download</mat-icon></button>
            <button  mat-mini-fab [disabled]="disabled_subir_file" aria-label="Subir archivo" (click)="subir()" type="button" class="fab-excel-u"><mat-icon>attach_file</mat-icon></button>  
            <input id="file_but" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" style="display:none;" (change)="subir_file($event)"/>
          </div> -->
              
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
            <div class="excel-msg info-msg" style="position: relative;"><i class="fas fa-info-circle"></i>
              &nbsp; Descarga la plantilla excel aquí <mat-icon class="excel-btn" (click)="descargar_file()">download</mat-icon> una vez ingresadas tus facturas súbelo aquí <mat-icon class="excel-btn"(click)="subir()">attach_file</mat-icon>
            </div>
            <input id="file_but" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" style="display:none;" (change)="subir_file($event)"/>
            <div class="info-msg" style="position: relative;">
              <i class="fas fa-info-circle"></i>
              &nbsp; Puedes copiar y pegar desde el Excel, las filas se crearán automaticamente, eso sí, procura respetar el formato de esta tabla.
            </div>
            <div class="success-msg" style="position: relative;">
              <i class="fas fa-info-circle"></i>
              &nbsp; El formato de las fechas debe ser: <strong>día/mes/año, día-mes-año,  año-mes-día, año/mes/día.</strong> Ejemplo 12/12/2021 o 12-12-21.
            </div>
          </div>
  
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align:right; margin-bottom: 10px;">
            <!-- <button  mat-flat-button  class="primary-button" (click)="listoparaenviar()" type="button"  style="margin-top: 10px;background: #3BA46F;color:white;">Continuar</button> -->
            <button  mat-flat-button  class="primary-button" (click)="new_row()"  type="button"  style="margin-top: 10px;">Agregar Fila</button>
            <button  mat-flat-button  class="primary-button" (click)="delete_row()" type="button"  style="margin-top: 10px;">Eliminar Fila</button>
          </div> 
  
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
            <!-- <hot-table [hotId]="id" manualColumnResize="true" [allowInsertColumn]="true" [settings]="hotSettings" licenseKey="non-commercial-and-evaluation"   stretchH='all' [data]="data" [rowHeaders]="true"  style="position: relative;">
              <hot-column  title="Folio"></hot-column>
              <hot-column  title="RUT Emisor"></hot-column>
              <hot-column  title="Nombre Emisor"></hot-column>
              <hot-column  title="Fecha Emisión"></hot-column>
              <hot-column  title="Fecha Vencimiento"></hot-column>
              <hot-column  title="Monto"></hot-column>
            </hot-table> -->
          </div>


        </div>

      </div>
      
      <button  mat-flat-button  class="primary-button" mat-dialog-close type="button"  style="margin-top: 10px;">Cerrar</button>
      <button  mat-flat-button  class="primary-button" (click)="listoparaenviar()" type="button"  style="margin-top: 10px;">Continuar</button>
      <button style="display: none;" id="siguiente_step" mat-button matStepperNext>Next</button>

    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Corrección y Continuar</ng-template>

      <div style="width: 100%;position: relative;">

        <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando_spinner_manual">
          <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
        </div>

        <div style="width: 100%;overflow: auto;">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
            <div class="success-msg" *ngIf="no_continue == false && mostrar_resumen_facturas">
              <i class="fas fa-check-circle" style="color:#3BA46F;"></i> 
              &nbsp; Las facturas fueron corregidas, puedes emitir pago.
            </div>
            <div class="warning-msg" *ngIf="no_continue == true && mostrar_resumen_facturas" >
              <i class="fas fa-exclamation-circle" style="color:#ff933b;"></i> 
              &nbsp; Las facturas resaltadas en naranja tienen un error, introduzca el valor correcto.
            </div> 
            <div class="error-msg" *ngIf="hay_facturas_con_error == true">
              <i class="fas fa-times-circle" style="color:#FA454C;"></i> 
              &nbsp; Las facturas resaltadas en rojo tienen un error en el folio, no se encuentran o no están disponibles en tu libro de compras.
            </div>                 
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">             

            <table class="table" mat-table  [dataSource]="dataSourceVistaPrevia">
              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef ></th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="text-align: center;">
                  <i class="fas fa-check-circle" style="color:#3BA46F;" *ngIf="element.error == false && element.errorCompras == false"></i>
                  <i class="fas fa-exclamation-circle" style="color:#ff933b;" *ngIf="element.error == true && element.errorCompras == false"></i>
                  <i class="fas fa-times-circle" style="color:#FA454C;" *ngIf="element.error == true && element.errorCompras == true"></i>
                </td>
              </ng-container>

              <ng-container matColumnDef="folio">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Folio</th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                  <!-- <input *ngIf="element.errorFolio==true && element.errorCompras == false" class="no-input" value="{{element.folio}}" (change)="capture_change('folio',i, $event.target.value)"> -->
                  <input class="no-input" value="{{element.folio}}" readonly>
                </td> 
              </ng-container>
          
              <ng-container matColumnDef="emisor">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Emisor</th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                  <input class="no-input" value="{{element.receptoracreedor_name}}" readonly>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="rut_emisor">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Rut Emisor </th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                  <!-- <input *ngIf="element.errorRut==true && element.errorCompras == false" class="no-input corregir" value="{{element.receptoracreedor_rut}}" (change)="capture_change('rut_emisor',i, $event.target.value, 'errorRut',element.folio)"> -->
                  <input class="no-input" value="{{element.receptoracreedor_rut}}" readonly>
                </td>                        
              </ng-container>
          
              <ng-container matColumnDef="fecha_emision">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Fecha Emisión </th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                
                  <!-- <div style="position: relative;" *ngIf="element.errorFechaEmision == true && element.errorCompras == false">
                    <input class="no-input corregir" value="{{element.fecha_emision | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                  </div> -->

                  <div style="position: relative; text-align: center;" *ngIf="element.errorCompras == true">
                    <span>{{element.fecha_emision | date:'dd/MM/yy'}}</span>
                  </div>

                  <div style="position: relative;" *ngIf="element.errorCompras == false">
                    <input class="no-input" value="{{element.fecha_emision | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                  </div>


                  <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                    <mat-label>Value binding</mat-label>
                    <input matInput [matDatepicker]="index" (dateChange)="capture_date_error($event, i,'fecha_emision','errorFechaEmision')" [value]="element.fecha_emision">
                    <mat-datepicker-toggle matSuffix [for]="index"></mat-datepicker-toggle>
                    <mat-datepicker #index></mat-datepicker>
                  </mat-form-field>

                </td>

              </ng-container>

              <ng-container matColumnDef="fecha_vencimiento">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Fecha Vencimiento </th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                
                  <!-- <div style="position: relative;" *ngIf="element.errorFechaVencimiento==true && element.errorCompras == false">
                    <input [ngClass]="{'no-input':true,
                                        'corregir':element.errorFechaVencimiento==true && element.errorCompras == false}" value="{{element.fecha_vencimiento | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                  </div> -->

                  <div style="position: relative; text-align: center;" *ngIf="element.errorCompras == true">
                    <span>{{element.fecha_vencimiento | date:'dd/MM/yy'}}</span>
                  </div>

                  <div style="position: relative;" *ngIf="element.errorCompras == false">
                    <input [ngClass]="{'no-input':true, 'corregir':element.errorFechaVencimiento==true}" value="{{element.fecha_vencimiento | date:'dd/MM/yy'}}" (click)="index.open()" readonly>
                  </div>


                  <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                    <mat-label>Value binding</mat-label>
                    <input matInput [matDatepicker]="index" (dateChange)="capture_date_error2($event, i, 'fecha_vencimiento','errorFechaVencimiento')" [value]="element.fecha_vencimiento">
                    <mat-datepicker-toggle matSuffix [for]="index"></mat-datepicker-toggle>
                    <mat-datepicker #index></mat-datepicker>
                  </mat-form-field>

              </ng-container>

              <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;" > Monto </th>
                <td mat-cell *matCellDef="let element; let index=index; let i=index;" style="min-width: 100px;">
                  <!-- <input *ngIf="element.errorMonto==true" class="no-input corregir" value="{{element.mnt_total}}" (change)="capture_change('monto',index, $event.target.value, 'errorMonto')"> -->
                  <input class="no-input" value="${{formatMoney(element.mnt_total)}}" readonly>
                </td> 
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsVistaPrevia; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsVistaPrevia" [ngClass]="{'errorrow':element.error == true && element.errorCompras == true}"></tr>
              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="6">No hay registros.</td>
              </tr>
            </table>
    
            <mat-paginator #VistaPrevia="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
    
          </div>

        </div>

        <button id="ir_al_anterior"  mat-flat-button  class="primary-button" type="button" matStepperPrevious style="margin-top: 10px;">Anterior</button>
        <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" >Cerrar</button>      
        <button [disabled]="no_continue" (click)="open_emitir_pago()" mat-flat-button  class="primary-button" type="button" style="margin-top: 10px;background: #3BA46F;color:white;">Siguiente</button>
        <button matStepperNext  class="primary-button" id="pasitodos" type="button" style="display: none;" ></button>
      </div>
    </mat-step>

    <mat-step>
      <form [formGroup]="firstFormGroup2" style="position: relative;">

        <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
          <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
        </div>

          <ng-template matStepLabel>Ctas.</ng-template>

          <div class="warning-msg animate__animated animate__fadeIn" style="margin-top:20px;" *ngIf="desactivar_siguiente == true">
            <i class="fas fa-exclamation-circle"></i>
            &nbsp; Los registros resaltados <strong>no tienen una cuenta bancaria vinculada</strong>, dale click al icono amarillo y agrega una Cuenta Bancaria.
          </div>

          <div class="success-msg animate__animated animate__fadeIn" style="margin-top:20px;" *ngIf="esta_todo_listo">
            <i class="fa fa-check"></i>
            &nbsp; Todas las facturas seleccionadas ya estan con su Cuenta Bancaria seleccionada!
          </div>

          <div class="table-responsive " style="overflow-x: unset;">
            <table mat-table [dataSource]="dataSource2">
        
              <ng-container matColumnDef="cta">
                <th mat-header-cell *matHeaderCellDef>Cta. Bancaria</th>
                <td mat-cell *matCellDef="let element" style="text-align: center;max-width: 110px;">
                  <i type="button" (click)="create_account(element.company_id,element.ultimorutacreedor, element.ultimoacreedor)" matTooltip="Agregar nueva Cuenta Bancaria" class="fas fa-plus-circle" *ngIf="element.validBanco" style="color:#3BA46F;"></i>
                  <i type="button" (click)="create_account(element.company_id,element.ultimorutacreedor, element.ultimoacreedor)" class="fas fa-exclamation-circle" *ngIf="!element.validBanco" style="color:#ff933b;cursor: pointer;"></i>
                </td>
                <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>
        
        
              <ng-container matColumnDef="folio">
                  <th mat-header-cell *matHeaderCellDef> Folio </th>
                  <td mat-cell *matCellDef="let element;" style="max-width: 110px;" >
                      <input class="no-input" value="{{element.folio}}" readonly> 
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>
          
              <ng-container matColumnDef="receptoracreedor_name">
                  <th mat-header-cell *matHeaderCellDef > Acreedor Legal </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{element.ultimoacreedor}}" style="min-width: 130px;"> 
                      <input class="no-input"  value="{{element.ultimoacreedor}}" style="text-align: left;" readonly> 
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>
        
              <ng-container matColumnDef="receptoracreedor_rut">
                <th mat-header-cell *matHeaderCellDef > Rut </th>
                <td mat-cell *matCellDef="let element"> 
                    <input class="no-input"  value="{{element.ultimorutacreedor}}" readonly> 
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> Total</td>
              </ng-container>
        
              <ng-container matColumnDef="mnt_total">
                  <th mat-header-cell *matHeaderCellDef> Monto </th>
                    <td mat-cell *matCellDef="let element"> 
                      <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
                  </td>
                  <td mat-footer-cell *matFooterCellDef style="text-align: center;font-size: 17px;font-weight: bold;">$ {{formatMoney(total)}} </td>
              </ng-container>
        
              <ng-container matColumnDef="fecha_emision">
                  <th mat-header-cell *matHeaderCellDef> Fecha Emisión </th>
                  <td mat-cell *matCellDef="let element;" >
                  <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
                  </td>
                  <td mat-footer-cell *matFooterCellDef>  </td>
              </ng-container>


        
              <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; let element; columns: displayedColumns2" [ngClass]="{'trbackgroud':element.validBanco == false}"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns2"></tr>
        
            </table>
            <mat-paginator #MatPaginator2="matPaginator" [pageSizeOptions]="[20, 50, 100, 500]"></mat-paginator>
        
          </div>

          <div style="margin-bottom: 20px;margin-top: 20px;display: inline-flex;">
            <button  mat-flat-button matStepperPrevious  class="primary-button"  type="button" style="margin-top: 10px;">Anterior</button>
            <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" style="margin-top: 10px;">Cerrar</button>
            <button [disabled]="siguiente"  mat-flat-button matStepperNext  class="primary-button"  type="button" style="margin-top: 10px;" (click)="controlar_cuentas()" >Siguiente</button>
          </div>

      </form>

    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Finalizar</ng-template>

      <div class="warning-msg animate__animated animate__fadeIn" style="margin-top:20px;" *ngIf="!esta_todo_listo">
        <i class="fas fa-exclamation-circle"></i>
        &nbsp; Para continuar con el proceso de emisión del CSV <strong>selecciona una Cuenta Bancaria para los Acreedores.</strong>
      </div>
    
      <div class="success-msg animate__animated animate__fadeIn" style="margin-top:20px;" *ngIf="esta_todo_listo">
        <i class="fa fa-check"></i>
        &nbsp; Todas las facturas seleccionadas ya estan con su Cuenta Bancaria.
      </div>
      
      <div class="table-responsive " style="overflow-x: unset;">
        <table mat-table [dataSource]="dataSource3">
    
          <ng-container matColumnDef="cta">
            <th mat-header-cell *matHeaderCellDef>Cta. Bancaria</th>
            <td mat-cell *matCellDef="let element" style="text-align: center;max-width: 110px;">
              <i  class="fas fa-check-circle" *ngIf="element.validBanco" style="color:#3BA46F;"></i>
              <i  class="fas fa-exclamation-circle" *ngIf="!element.validBanco" style="color:#ff933b;cursor: pointer;"></i>
            </td>
          </ng-container>
    
    
          <ng-container matColumnDef="folio">
              <th mat-header-cell *matHeaderCellDef> Folio </th>
              <td mat-cell *matCellDef="let element;" style="max-width: 110px;" >
                  <input class="no-input" value="{{element.folio}}" readonly> 
              </td>
          </ng-container>
      
          <ng-container matColumnDef="receptoracreedor_name">
              <th mat-header-cell *matHeaderCellDef > Acreedor Legal </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.ultimoacreedor}}" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.ultimoacreedor}}" style="text-align: left;" readonly> 
              </td>
          </ng-container>
    
          <ng-container matColumnDef="receptoracreedor_rut">
            <th mat-header-cell *matHeaderCellDef > Rut </th>
            <td mat-cell *matCellDef="let element"> 
                <input class="no-input"  value="{{element.ultimorutacreedor}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"> Total</td>
          </ng-container>
    
          <ng-container matColumnDef="mnt_total">
              <th mat-header-cell *matHeaderCellDef> Monto </th>
                <td mat-cell *matCellDef="let element"> 
                  <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
              </td>
          </ng-container>
    
          <ng-container matColumnDef="fecha_emision">
              <th mat-header-cell *matHeaderCellDef> Fecha Emisión </th>
              <td mat-cell *matCellDef="let element;" >
              <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
              </td>

          </ng-container>
    
    
    
          <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef style="min-width: 300px;"> Seleccionar Cta. </th>
              <td mat-cell *matCellDef="let element;let index=index"  style="text-align: center;min-width: 300px;">
                <select  *ngIf="element.bancos.length > 0" name="select" style="width: 100%;border: none;box-shadow: none;background: none;" (change)="seleccionar_banco($event, element.ultimorutacreedor)">
                  <option *ngFor="let i of element.bancos"  value="{{i.id}}">{{i.banco + ' | ' + i.tipo + ' ' + i.numero}}</option>
                </select>
              </td>

          </ng-container>
    
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; let element; columns: displayedColumns3" [ngClass]="{'trbackgroud':element.validBanco == false}"></tr>

    
        </table>
        <mat-paginator #MatPaginator3="matPaginator" [pageSizeOptions]="[20, 50, 100, 500]"></mat-paginator>
    
      </div>

      <div style="margin-bottom: 20px;margin-top: 20px;display: inline-flex;">        
        <button  mat-flat-button matStepperPrevious  class="primary-button"  type="button" >Anterior</button>
        <button  mat-flat-button mat-dialog-close  class="primary-button"  type="button" >Cerrar</button>
        <button  mat-flat-button [disabled]="!esta_todo_listo" class="primary-button"  type="button"  (click)="download_csv()">Finalizar</button>
        <mat-spinner class="spinner-arrayan-general" *ngIf="displayspinner" diameter="40" style="margin-left: 10px;"></mat-spinner>
        <button  mat-flat-button matStepperNext id="elfulanito" class="primary-button" style="display: none;"  type="button" >Libro de Ventas</button>
      </div>

    </mat-step>

    <mat-step *ngIf="debe_agregar_ventas == true" style="width: 100%;" class="animate__animated animate__fadeIn">
      <ng-template matStepLabel>Extra: Agregar Ventas</ng-template>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn slow">
                <div style="width: 70%;text-align: center;margin:0 auto;margin-top: 20px;">
        La deuda es mayor al monto de la simulación por lo cual debes agregar facturas de venta para poder pagar tu Deuda total de: <strong style="font-size: 19px;">$ {{formatMoney(deuda_total)}}</strong>

        <div style="text-align: center;margin-bottom: 30px;margin-top: 30px;">
          ¿Desea agregar facturas desde el libro de ventas o ingresarlas de manera manual?

          <div style="margin-top: 30px;margin-bottom: 30px;">
              <button  mat-flat-button (click)="open_libro_ventas()"  class="primary-button"  type="button" style="background:#433da9;color:white;">Libro de Ventas</button>
              <!-- <button  mat-flat-button (click)="open_carga_manual()"  class="primary-button"  type="button" style="background:#433da9;color:white;">Ingreso Manual</button> -->
          </div>

        </div>

                </div>
      </div>



      <div style="margin-bottom: 30px;margin-top: 30px;display: inline-flex;">        
        <button  mat-flat-button matStepperPrevious  class="primary-button"  type="button" >Anterior</button>
      </div>


    </mat-step>

  </mat-stepper>

</div>