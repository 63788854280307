<div mat-dialog-title>
  <div class="titulo-onboarding">
    @if(step == 1){
      ¿Qué es la sincronización?
    }@else if(step == 2){
      requisitos para sincronizar tu empresa
    }@else if(step == 3){
      ventajas de la vinculación con el SII
    }@else if(step == 4){
      consejos para evitar errores<br>con la clave SII de tu empresa
    }@else if(step == 5){
      ¿Qué es un sistema de facturación?
    }@else if(step == 6){
      ¿Qué es una operación de factoring?
    }@else if(step == 7){
      Financia tu empresa con seguridad y confianza
    }@else if(step == 8){
      la clave que ingresaste no es válida
    }@else if(step == 9){
      la clave no es válida y por seguridad, no será posible realizar más intentos
    }@else if(step == 10){
      facturas con preaprobación
    }@else if(step == 11){
      ¡importante!
    }@else if(step == 12){
      <div style="font-size: 45px;"><i class="fa-sharp fa-regular fa-circle-info"></i></div>
     revisa tu lista de clientes calificados
    }@else if (step == 13) {
      ¿Qué es el PIN de firma segura?
    }@else if (step == 14) {
      ¿Por qué es necesario activar la geolocalización para firmar documentos?
    }




    @else if(step == 20){
      <div style="font-size: 45px;"><i class="fa-sharp fa-regular fa-circle-info"></i></div>
     error acceso
    }


    <div  mat-dialog-close class="close-dialog-icon"><i class="fa-sharp fa-regular fa-xmark"></i></div>
  </div>
  <div class="subtitulo-onboarding">
    @if(step == 2){
      Selecciona el encabezado del requisito para acceder a su descripción completa.
    }@else if(step == 3){
      Al ingresar la clave SII de tu empresa, podrás acceder a evaluaciones más ágiles,<br>además de contar con todos los beneficios de la sincronización.
    }@else if(step == 7){
      Conoce nuestra <strong>política de privacidad de datos</strong>.
    }@else if(step == 12){
      Antes de continuar, revisa quiénes de tus clientes calificaron<br>para una operación de factoring inmediata.
    }



    @else if(step == 20){
      error<br>aqui
    }
  </div>
</div>

<div mat-dialog-content>


  @if(step == 1){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Es una función que <strong>vincula los datos de tu empresa</strong> con esta plataforma, para que así puedas monitorear el <strong>progreso de tu negocio</strong> en tiempo real.
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if(step == 2){
    <div class="contenedor-expansion">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Ingresa la clave SII de tu empresa </mat-panel-title>
          </mat-expansion-panel-header>
  

          <div class="texto-expansion">
            Con este dato podemos vincularnos al SII para agilizar evaluaciones, ofrecerte opciones de financiamiento y generar tu Dashboard contable.
          </div>


        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Indicar cuál es el sistema de facturación que usas </mat-panel-title>
          </mat-expansion-panel-header>
  

          <div class="texto-expansion">
            Si ya estás emitiendo tus facturas a través de MiPyme, podemos ayudarte a simplificar la gestión de tus documentos tributarios. Con tu autorización para recibir las facturas directamente desde MiPyme, nos aseguramos de que toda tu información esté organizada y siempre disponible para tu negocio.
          </div>


        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if(step == 3){
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
      <div class="swiper-container">

        <div class="titulo-onboarding" style="text-align: left;font-size: 26px;margin-bottom: 20px;">
          Beneficios de la sincronización:
        </div>

        <swiper-container id="swiper" #swiper 
        speed="500" autoplay="true" css-mode="false" loop="true"
        navigation="false" pagination="true" scrollbar="false" virtual="false" pagination-clickable="true">


          <swiper-slide>

            <div class="row">

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper1.svg" />
                  </div>
                  <div>
                    Accede a tu dashboard<br>contable
                  </div>
  
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper2.svg" />
                  </div>
                  <div>
                    Indentifica a tus<br>principales clientes
                  </div>

                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper3.svg" />
                  </div>
                  <div>
                    consulta el Dicom de<br>tu empresa
                  </div>
  
                </div>
              </div>

            </div>
  

          </swiper-slide>


          <swiper-slide>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper4.svg" />
                  </div>
                  <div>
                    Revisa tus facturas<br>pagadas
                  </div>
  
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="swipper-content">
                  <div>
                    <img src="../../../assets/iconografia/swiper5.svg" />
                  </div>
                  <div>
                    Gestiona tus<br>proveedores
                  </div>
  
                </div>
              </div>
            </div>


  

          </swiper-slide>





        </swiper-container>
      </div>




    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 4){
    <div class="image-container">
      <img src="../../../assets/iconografia/evitar_errores.svg" />
    </div>
  
    <div class="label-container">
      <ul>
        <li>Asegúrate de ingresar la clave de acceso al SII asociada al<br><strong>RUT de tu empresa</strong>.</li>
        <li>Para evitar que la clave sea bloqueada, <strong>no intentes ingresarla<br>más de 2 veces</strong>.</li>
        <li>Si no tienes seguridad de cuál es la clave, trata de <span (click)="ingresar_al_sii()">ingresar al SII</span> para validarla,<br> sin superar los 2 intentos recomendados.</li>
      </ul>  
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 5){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Son softwares o plataformas que permiten <strong>emitir, ceder o realizar otras operaciones</strong> con facturas electrónicas, respetando la normativa legal vigente.

      <br><br>
      Para más información, <a href="https://api.whatsapp.com/send/?phone=%2B56934677786&text&type=phone_number&app_absent=0" target="_blank">contacta a nuestro equipo de experiencia cliente</a>.
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 6){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Es un proceso que <strong>otorga liquidez</strong> a través de la cesión de cuentas por cobrar, como facturas con pago a crédito.

      <br><br>
      En resumen, <strong>nos cedes tus facturas</strong>, te entregamos la liquidez y gestionamos la cobranza con el deudor (tu cliente).
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 7){
    <div class="imagen-container">
      <video width="370" height="240" controls muted poster="../../../../../../assets/images/Video.png">
        <source src="https://simpliassets12daacw.s3.us-east-2.amazonaws.com/politica_privacidad_simpli.mp4"
          type="video/mp4">
        Tu navegador no soporta el tag de video.
      </video>
    </div>

    <contact-container></contact-container>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close (click)="continue()">Continuar</button>
    </div>
  } @else if (step == 8){
    <div class="image-container">
      <img src="../../../assets/iconografia/Iconografia - Errorclavesii.svg" />
    </div>
  
    <div class="label-container">
      Podrás hacer un intento más, solo debes seguir los
      <span class="clickable-text" (click)="open_modal_info(4)">consejos para evitar errores al ingresar la clave SII.</span>
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" (click)="reintentar()">Reintentar</button>
    </div>
  }@else if (step == 9){
    <div class="image-container">
      <img src="../../../assets/iconografia/Iconografia - Errorclavesii.svg" />
    </div>
  
    <div class="label-container">
      Para evitar que el SII bloquee tu acceso por intentos reiterados de ingresar tu clave, continúa la sincronización con la ayuda del equipo de <strong>experiencia del cliente</strong>.
    </div>

  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" (click)="ir_a_ux()">Contactar con experiencia del cliente</button>
    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="secondary-button" style="margin-right: 0px;" mat-dialog-close>Cerrar</button>
    </div>
  }@else if (step == 10){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container" style="text-align: center;">
        Son facturas que evaluamos de forma anticipada para entregarte<br>una <strong>opción de financimiento personalizada e inmediata.</strong>
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if(step == 11){
    <div class="image-container">
      <img src="../../../assets/iconografia/que-es-una-simulacion.svg" />
    </div>
  
    <div class="label-container">
      Las facturas que estás a punto de agregar serán evaluadas por nuestro equipo. Una vez aprobadas, podrás comenzar a operar.
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="secondary-button" mat-dialog-close>Volver</button>
      <button mat-stroked-button class="primary-button" (click)="continuar_agregar()">Continuar</button>
    </div>
  }@else if (step == 12){

 
      @if(loading_deudores){
        <loading-interno></loading-interno>
      }@else{
        <div class="caja-deudores add_factoring">
          <table>
            <thead>
                <tr>
                    <th>Deudor (tu cliente)</th>
                    <th>RUT</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of deudores">
                    <td>{{i.nombre_deudor}}</td>
                    <td>{{i.rut_deudor}}</td>
                </tr>
            </tbody>
          </table> 
        </div>

      }

   
    <div class="label-container" style="text-align: center;">
      <strong>Si tienes preguntas, contacta a un ejecutivo.</strong>
    </div>

    <div class="contact-container">
      <div class="icons">
        <span (click)="agendar()"><i class="fa-light fa-video"></i>Videollamada</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a [href]="'tel:' + contacto"><i class="fa-sharp fa-solid fa-headset"></i>{{contacto}}</a>
      </div>
    </div>
  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" (click)="continue_six()">Continuar</button>
    </div>
  }@else if (step == 13) {
    <div class="image-container">
      <img src="../../../assets/iconografia/facturador.svg" />
    </div>
  
    <div class="label-container" style="text-align: center;">
      Es una <strong>clave de seguridad</strong> de 4 dígitos que debes crear para firmar documentos en la plataforma. Su función es evitar que personas no autorizadas utilicen tu firma electrónica. 
      <br><br>
      El PIN es <strong>personal e intransferible</strong> y deberás ingresarlo cada vez que necesites firmar un documento.
    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }@else if (step == 14) {
    <div class="image-container">
      <img src="../../../assets/iconografia/Iconografia - geolocalizacion.png" />
    </div>
  
    <div class="label-container" style="text-align: center;">
      Es un protocolo que la notaría solicita para validar una firma electrónica. <strong>No expondremos ni compartiremos</strong> tu ubicación; solo necesitamos comprobar que estás en territorio nacional.
    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" mat-dialog-close>Volver</button>
    </div>
  }

  @else if(step == 20){

    <div class="image-container">
      <img src="../../../assets/iconografia/Iconografia - Errorclavesii.svg" />
    </div>
  
    <div class="label-container">
      El RUT de empresa que ingresaste <strong>ya está registrado</strong> en la web.
      <br><br>
      Por seguridad, el RUT de una empresa puede ser registrado solo una vez. Para continuar con la creación de tu cuenta, 
          <strong>contacta a nuestro equipo de experiencia del cliente.</strong>
    </div>

  
    <div class="caja-botones">
      <button mat-stroked-button class="primary-button" style="margin-right: 0px;" (click)="ir_a_ux()">Contactar con experiencia del cliente</button>
    </div>

    <div class="caja-botones">
      <button mat-stroked-button class="secondary-button" style="margin-right: 0px;" mat-dialog-close>Cerrar</button>
    </div>

  }





</div>






