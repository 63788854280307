<section class="area-login animate__animated animate__fadeIn" [ngStyle]="{ 'background-image': 'url(' + randomImage + ')' }">
  <mat-card class="login" style="position: relative;">
    <mat-card-content>

      <div class="simpli-floating-box-header">

        <div class="simpli-floating-box-logo text-center" (click)="dialog_success()">
          <img src="../../assets/simpli/simplilogo2.svg" height="90px">
        </div>
        <div *ngIf="paso1" class="simpli-floating-box-title text-center">
          <span class="color-tertiary">Regístrate de forma <strong class="color-tertiary" style="color:var(--simpli-secondary) !important;">Simpli</strong></span>
        </div>
        <div *ngIf="paso2" class="simpli-floating-box-title text-center">
          <span class="color-tertiary">Ingresa tus datos de contacto</span>
        </div>
      </div>

      <div class="simpli-floating-box-body">
        <div *ngIf="paso1" class="animate__animated animate__fadeIn">
          <form [formGroup]="valForm" (submit)="submitFormCompany($event, valForm.value)" autocomplete="off">
            <mat-form-field appearance="fill">
              <mat-label>Correo electrónico</mat-label>
              <input type="email" matInput required autocomplete="email" formControlName="username" (blur)="changeMail($event)" autocomplete="off">
              <mat-spinner color="primary" style="margin-right: 10px;" matSuffix diameter="20" *ngIf="this.show_spinner_mail"></mat-spinner>
              <mat-hint>Ingresa tu correo electrónico</mat-hint>
              <mat-error *ngIf="valForm.controls['username'].hasError('required') && (valForm.controls['username'].dirty || valForm.controls['username'].touched)">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="valForm.controls['username'].hasError('pattern')">
                Debe ser un correo electrónico valido
              </mat-error>
              <mat-error *ngIf="valForm.controls['username'].hasError('incorrect')">
                Correo electrónico ya en uso
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Contraseña</mat-label>
              <input matInput formControlName="password" autocomplete="password" [type]="hide ? 'password' : 'text'" required  autocomplete="new-name">
              <button matSuffix mat-icon-button type="button" (click)="hide = !hide" [attr.aria-label]="'Ocultar password'"
              [attr.aria-pressed]="hide">
              <mat-icon class="simpli-color-gris">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
              <mat-hint>Crea tu contraseña</mat-hint>
              <mat-error *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="valForm.controls['password'].hasError('minlength')">
                Debe contener un mínimo de 6 caracteres
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Repite contraseña</mat-label>
              <input matInput formControlName="password_confirmation" [type]="hideRe ? 'password' : 'text'" required  name="password" autocomplete="new-password">
              <button matSuffix mat-icon-button type="button" (click)="hideRe = !hideRe" [attr.aria-label]="'Ocultar password'"
              [attr.aria-pressed]="hideRe">
              <mat-icon class="simpli-color-gris">{{hideRe ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-hint>Vuelve a introducir tu contraseña</mat-hint>
              <mat-error *ngIf="valForm.controls['password_confirmation'].hasError('required') && (valForm.controls['password_confirmation'].dirty || valForm.controls['password_confirmation'].touched)">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="valForm.get('password_confirmation').hasError('equalTo')">
                  La contraseña ingresada no coincide
              </mat-error>
            </mat-form-field>



            <div class="btn-container">
              <button class="primary-button" [disabled]="show_spinner" mat-flat-button type="submit" color="primary" style="margin-right: 0px;">
                <mat-label *ngIf="show_spinner == false">Continuar</mat-label>
                <span *ngIf="show_spinner == true"><i class="fa-solid fa-spinner fa-spin-pulse" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
              </button>
            </div>


            <div class="center-div">
              <a *ngIf="!valForm.controls['username'].hasError('incorrect')" mat-button class="label-button" routerLink="/login">Volver</a>
              <a *ngIf="valForm.controls['username'].hasError('incorrect')" mat-button class="label-button" (click)="referido()">Inicia sesión aqui</a>
            </div>

          </form>
        </div>

        <div *ngIf="paso2" class="animate__animated animate__fadeIn">
          <form [formGroup]="valFormCompany" (submit)="submitForm($event, valFormCompany.value)" autocomplete="off">

            <mat-form-field appearance="fill">
              <mat-label>Rut de empresa</mat-label>
              <input matInput type="text" required formControlName="rut" (keyup)="rutKey($event)" (change)="checkRut($event)" name="rut_empresa" autocomplete="new-rut_empresa">
              <mat-spinner color="primary" style="margin-right: 10px;" matSuffix diameter="20" *ngIf="display_spinner"></mat-spinner>
              <mat-hint>Ingresa tu RUT de Empresa sin puntos con guión</mat-hint>
              <mat-error *ngIf="valFormCompany.controls['rut'].hasError('required') && (valFormCompany.controls['rut'].dirty || valFormCompany.controls['rut'].touched)">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="valFormCompany.controls['rut'].hasError('invalid') || valFormCompany.controls['rut'].hasError('pattern')">El RUT ingresado no es válido</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Nombre y Apellido</mat-label>
              <input matInput required formControlName="nombre" autocomplete="off">
              <mat-hint>Ingresa tu Nombre y Apellido</mat-hint>
              <mat-error *ngIf="valFormCompany.controls['nombre'].hasError('required') && (valFormCompany.controls['nombre'].dirty || valFormCompany.controls['nombre'].touched)">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Teléfono</mat-label>
              <input matInput required formControlName="telefono"  autocomplete="off" (change)="checkPhone($event)">
              <mat-hint>Ingresa un número de teléfono válido</mat-hint>
              <mat-error *ngIf="valFormCompany.controls['telefono'].hasError('required') && (valFormCompany.controls['telefono'].dirty || valFormCompany.controls['telefono'].touched)">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="valFormCompany.controls['telefono'].hasError('invalid')">El número de teléfono no es válido</mat-error>
            </mat-form-field>


            <div class="text-center mt-20">
              <small class="simpli-color3">
                Al registrarte, estas aceptando nuestra
                <a class="simpli-color2 cursor-pointer text-none" href="https://simplilatam.com/politica-de-privacidad/" target="_blank">Política de Privacidad</a>.
                Además, enviaremos notificaciones por SMS. Si no deseas recibirlas, contacta a tu ejecutivo.
              </small>
            </div>


            <div class="btn-container">
              <button class="primary-button" [disabled]="show_spinner" mat-flat-button type="submit" color="primary" style="margin-right: 0px;">
                <mat-label *ngIf="show_spinner == false">Registrarme</mat-label>
                <span *ngIf="show_spinner == true"><i class="fa-solid fa-spinner fa-spin-pulse" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
              </button>
            </div>

            <div class="center-div">
              <a mat-button class="label-button" routerLink="/login">Volver</a>
            </div>

          </form>
        </div>
      </div>


    </mat-card-content>

  </mat-card>
</section>
