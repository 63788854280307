<div mat-dialog-title>
  <h1 class="titulo-simpli">{{stringfiltro}}</h1>
  <small class="subtitulo-simpli">{{this.fecha | date: 'MMMM yyyy'}}</small>
  <small class="subtitulo-simpli">Total de {{stringfiltro}}: ${{formatMoney(this.datos["monto"])}}</small>

</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-10 col-xs-10 col-top">
        <mat-form-field>
          <mat-label>Filtro</mat-label>
          <input matInput [(ngModel)]="buscar" >
        </mat-form-field>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-10 col-xs-10 col-top" style="margin-top: 25px;">
        <button type="button" mat-flat-button (click)="applyFilter()"  color="primary"  matTooltip="Buscar">
          Buscar
        </button>
        <button type="button" mat-flat-button (click)="reset()"  color="primary"  matTooltip="Limpiar" >
          Limpiar
        </button>
    </div>

  </div>

  <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top"  *ngIf="noData || spinner">
          <app-sin-registros [spin]="spinner"></app-sin-registros>
      </div>
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="table-responsive-simpli add_factoring">
            <table mat-table [dataSource]="dataSourceFacturas" *ngIf='!noData && !spinner'>
        
                  <ng-container matColumnDef="bussines_name">
                    <th mat-header-cell *matHeaderCellDef>Acreedor</th>
                    <td mat-cell *matCellDef="let element">

                      <input class="no-input"  readonly matTooltip="{{element.business_name}} {{element.rut}}" matTooltipPosition="right" value="{{element.business_name.substring(0, 17) | uppercase }}"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="folio">
                    <th mat-header-cell *matHeaderCellDef>Nº Folio</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input"  readonly value="{{element.folio}}"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_emision">
                    <th mat-header-cell *matHeaderCellDef>Fecha emisión</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input"  readonly value="{{element.fecha_emision | date:'yyyy-MM-dd'}}"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_recepcion">
                    <th mat-header-cell *matHeaderCellDef>Fecha Recepción</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input"  readonly value="{{element.fecha_recepcion | date:'yyyy-MM-dd'}}"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let element">
                      <input class="no-input" matTooltip="{{salto_linea(element.rz_cesionario, element.fecha_cesion) }}" readonly value="{{element.estado}}"/>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="monto">
                    <th mat-header-cell *matHeaderCellDef>Monto Total</th>
                    <td mat-cell *matCellDef="let element"  style="position: relative;">
                      <span *ngIf="element.detalle" [mdePopoverTriggerFor]="filtertable" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" class="drop-down"><i class="fa-solid fa-angle-down"></i></span>
                      <input class="no-input"  readonly value="{{formatMoney(element.mnt_total)}}"/>


                      <mde-popover #filtertable="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapEnabled]="false" [mdePopoverCloseOnClick]="false">
                        <mat-card style="width: 800px;background: whitesmoke;">
                            <mat-card-content>

                              <table class="table-custom">
                                <tr>
                                  <th class="th-custom">Folio</th>
                                  <th class="th-custom">Tipo factura</th>
                                  <th class="th-custom">Estado</th>
                                  <th class="th-custom">Monto total</th>
                                </tr>
                                <tr *ngFor="let folio of element.folios">
                                  <td class="td-custom">{{folio.folio}}</td>
                                  <td class="td-custom">{{folio.tipo}}</td>
                                  <td class="td-custom">{{folio.estado}}</td>
                                  <td class="td-custom">${{formatMoney(folio.mnt_total)}}</td>
                                </tr>
                              </table>
                                   
                            </mat-card-content>
                        </mat-card>
                      </mde-popover>


                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturas"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturas;"></tr>
            </table>
        </div>
          
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <mat-paginator #paginator [length]="this.len_info" [pageSize]="5"  (page)="pageCustom($event)" ></mat-paginator>
      </div>
    
  </div>
</div>

<div mat-dialog-actions>
  <button type="button" id="send_form" mat-flat-button (click)="dialogRef.close('N')" color="primary">
    Cerrar
  </button>
</div>