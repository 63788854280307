
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { ObsSimulation } from '../observaciones/obs';
import {Location} from '@angular/common';
import { DetalleFacturas } from '../detalle-facturas/detalle-facturas';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;


@Component({
  selector: 'app-ver-simulacion-rechazada',
  templateUrl: './ver-simulacion-rechazada.html',
  styleUrls: ['./ver-simulacion-rechazada.less']
})
export class VerSimulacionRechazadaComponent implements OnInit {



  @Input() id_company:any;
  @Input() id_sim:any;

  is_responsive:boolean=false;

  constructor(private _location: Location, public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, public snackBar:MatSnackBar,  private activated_router: ActivatedRoute, public router:Router) {

    this.id_company = localStorage.getItem('company_id');

    this.activated_router.params.subscribe((params:any) => {
      this.id_sim = params['id']
    })
  
   }

  return(){
    this._location.back();
  }

  ngOnInit() {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }


    this.get_resumen_sim();
    this.get_facturas();

    window.scrollTo({top: 0, behavior: 'smooth'});
  }


  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  date_created="";
  anticipo:any=0;
  valor_anticipo_total:any=0;
  dias_promedio:any=0;
  tasa_interes:any=0;
  comision:any=0;
  gastos:any=0;
  interes_total:any=0;
  iva_operacion:any=0;
  excedentes:any=0;
  monto_recibir:any=0;
  total:any=0;
  comentario_descarte = "No cumple condiciones"
  async get_resumen_sim(){


    try {


      const bd: any = await this.settings.get_query(1, '/cliente/simulacion/'+this.id_sim+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
          console.log("sim,", data)

          this.date_created = data.info.date_created;
          this.anticipo = this.formatMoney(data.info.anticipo);
          this.valor_anticipo_total = this.formatMoney(data.info.valor_anticipo_total);
          this.dias_promedio = data.info.dias_promedio;
          this.tasa_interes = data.info.tasa_interes;
          this.comision = this.formatMoney(data.info.comision);
          this.gastos = this.formatMoney(data.info.gastos);
          this.interes_total = this.formatMoney(data.info.interes_total);
          this.iva_operacion = this.formatMoney(data.info.iva_operacion);
          this.excedentes = this.formatMoney(data.info.excedentes);
          this.monto_recibir = this.formatMoney(data.info.monto_recibir);
          this.total = this.formatMoney(Number(data.info.total));

          if(data.info.descripcion_descarte != null){
            this.comentario_descarte = data.info.descripcion_descarte
          }


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  cantidad_facturas:any=0;
  facturas:any[]=[];
  loading:boolean=true;
  async get_facturas(){


    try {

      this.loading = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_sim+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.cantidad_facturas = data.total;
          this.facturas = data.info;

          this.loading = false;

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }

      this.loading = false;
      
    }


  }

  volver(){
    if(this.is_responsive == true){
      this.router.navigate(['home/simulaciones-r', this.id_company])
    }else{
      this.router.navigate(['home/simulaciones'])
    }
  }

  loading_pdf_:boolean=false;
  async download_pdf(){
    try {
      
      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-detalle-pdf/'+this.id_sim+'/', );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

          this.downloadPDF(data.info);
          this.loading_pdf_ = false;

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 
        this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
          this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  downloadPDF(pdf:any) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "vct_illustration.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  volver_simular(){
    this.router.navigate(['home/'])
  }

  observaciones_get() {
    let dialogRef = this.dialog.open(ObsSimulation, {
      width: '1200px',
      disableClose: true,
      autoFocus:false,
    });
    dialogRef.componentInstance.id_company = this.id_company;
  }

  detalle_factura(){
    const dialogRef = this.dialog.open(DetalleFacturas,{
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.componentInstance.id_sim = this.id_sim;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){

      }
    });
  }
  
}