
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


declare var $: any;


@Component({
  selector: 'app-alert-success-upload',
  templateUrl: './alert-success-upload.html',
  styleUrls: ['./alert-success-upload.less']
})
export class AlertSuccessUpload implements OnInit {





  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<AlertSuccessUpload>) {



   }



  ngOnInit() {


  }


}