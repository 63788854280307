import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Overlay } from '@angular/cdk/overlay';
import { RelacionCompraVentaComponent } from '../grafico-multivariables.component';
import { SettingsService } from 'src/app/settings/settings.service';
declare var $: any;

@Component({
  selector: 'app-ver-detalle-dialogo',
  templateUrl: 'ver-detalle-dialogo.component.html',
  styleUrls: ['ver-detalle-dialogo.component.less'],
})
export class VerDetalleDialogoComponent {
  public padre!: RelacionCompraVentaComponent;
  len_info: number = 0;
  ELEMENT_DATA_FACTURAS: Facturas[] = [];
  displayedColumnsFacturas: string[] = ['bussines_name', 'folio', 'fecha_emision', 'fecha_recepcion', 'estado', 'monto'];
  dataSourceFacturas = new MatTableDataSource<Facturas>(
    this.ELEMENT_DATA_FACTURAS
  );
  datos: any = [];
  spinner: Boolean = true;
  stringfiltro: string = 'venta';
  stringDetalle: string = 'Folios relacionados con la N.C.';
  fecha!: Date;
  noData: boolean = false;

  page: number = 1;
  length = 0;
  pageSize = 5;
  buscar:any=""

  @ViewChild('paginator') paginator!: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    overlay: Overlay,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<VerDetalleDialogoComponent>,
    public dialog: MatDialog,
    public settings: SettingsService
  ) {
    this.datos = data;

    if (this.datos['filtro'] == 'notascredito') {
      this.stringfiltro = 'N.C.';
      this.stringDetalle = 'Folios relacionados con la N.C.';
    }

    if (this.datos['filtro'] == 'ventas') {
      this.stringfiltro = 'Venta';
      this.stringDetalle = 'N.C. relacionados con la factura';
    }

    if (this.datos['filtro'] == 'compras') {
      this.stringfiltro = 'Compra';
    }
    if (this.data['filtro'] == 'ventasExportacion') {
      this.stringfiltro = 'Ventas por Exportación';
    }
  }

  ngOnInit() {
    this.informacion();
  }

  get_datalle(ele:any){
    console.log(ele)
  }

  informacion = async () => {
    this.ELEMENT_DATA_FACTURAS=[]
    let mensual = new Date(this.datos['fecha'] + '-01');
    let mes_actual = new Date(mensual.getFullYear(), mensual.getMonth() + 1, 1);
    let mes_actual_ultimo = new Date(
      mes_actual.getFullYear(),
      mes_actual.getMonth() + 1,
      0
    );
    this.fecha = mes_actual;

    //primer dia
    let dia1 = new Date(mes_actual);
    let y1 = dia1.getFullYear();
    let m1: any = dia1.getMonth() + 1;
    let d1: any = dia1.getDate();
    if (m1 < 10) {
      m1 = '0' + m1;
    }
    if (d1 < 10) {
      d1 = '0' + d1;
    }
    let dias1 = y1 + '-' + m1 + '-' + d1;

    //ultimo dia
    let dia2 = new Date(mes_actual_ultimo);
    let y2 = dia2.getFullYear();
    let m2: any = dia2.getMonth() + 1;
    let d2: any = dia2.getDate();
    if (m2 < 10) {
      m2 = '0' + m2;
    }
    if (d2 < 10) {
      d2 = '0' + d2;
    }
    let dias2 = y2 + '-' + m2 + '-' + d2;

    this.spinner = true;

    try {
      this.spinner = true;
      let endpoint = '/consultacomprasventasnc/' +
      this.datos['company'] +
      '/?inicio=' +
      dias1 +
      '&fin=' +
      dias2 +
      '&tipo=' +
      this.datos['filtro']+'&pageNumber='+this.page+"&buscador="+this.buscar;
      console.log("->",this.buscar)
      const bd: any = await this.settings.get_query(1, endpoint);
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:


        this.len_info = body_data.length;
          if (this.len_info == 0) {
            this.noData = true;
          }else{
            this.noData = false;
          }

          for (let i = 0; i < body_data.success.length; i++) {
            let detalle = false;
            if (body_data.success[i].asociacion.length > 0) {
              detalle = true;
            }

            let facturas = {
              folio: body_data.success[i].folio,
              rut: body_data.success[i].rut,
              business_name: body_data.success[i].business_name,
              fecha_emision: body_data.success[i].fecha_emision,
              fecha_recepcion: body_data.success[i].fecha_recepcion,
              estado: body_data.success[i].estado,
              mnt_exento: body_data.success[i].mnt_exento,
              mnt_iva: body_data.success[i].mnt_exento,
              mnt_neto: body_data.success[i].mnt_neto,
              mnt_total: body_data.success[i].mnt_total,
              detalle: detalle,
              folios: body_data.success[i].asociacion,
              rz_cesionario: body_data.success[i].rz_cesionario,
              fecha_cesion: body_data.success[i].fecha_cesion,
              is_exportacion: body_data.success[i].is_exportacion,
            };

            console.log(facturas);

            this.ELEMENT_DATA_FACTURAS.push(facturas);
          }
          this.dataSourceFacturas = new MatTableDataSource(
            this.ELEMENT_DATA_FACTURAS
          );
          // setTimeout(() => (this.dataSourceFacturas.paginator = this.paginator));
          this.dataSourceFacturas._updateChangeSubscription();
          // console.log("171",this.dataSourceFacturas.data)

          // console.log("173",this.len_info)
          this.spinner = false;
          this.spinner = false;
          break;
        default:
          this.spinner = false;
          break;
      }

    } catch (bd: any) {
      this.spinner = false;
      switch (bd.status) {
        default:

          break;
      }
    }
  };


  reset(){
    this.buscar=""
    this.informacion();
  }

  applyFilter = () => {
    // this.dataSourceFacturas.filter = filterValue.trim().toLowerCase();
    // console.log("------>",this.buscar)
    this.informacion();
  };

  formatMoney = (amount: any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) {}
  };

  marcador: number = 55555555555555555555;
  desplegar = (i: any) => {
    if (this.marcador != i) {
      $('.opacidad').removeClass('opacity');
      $('.opacidad' + i).addClass('opacity');
      $('.desplegable').addClass('ocultar');
      $('.' + i + '_desplegable').removeClass('ocultar');
      this.marcador = i;
    } else if (this.marcador == i) {
      $('.' + i + '_desplegable').addClass('ocultar');
      $('.opacidad').removeClass('opacity');
      this.marcador = 55555555555555555555;
    }
  };

  salto_linea = (string1: string, string2: string) => {
    if (string1 != '') {
      return 'Cedido a: ' + string1 + '. Fecha: ' + string2;
    }
  };



  resetPag() {
    this.ELEMENT_DATA_FACTURAS = [];
    this.dataSourceFacturas = new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
    this.dataSourceFacturas._updateChangeSubscription();
  }

  pageCustom($e:any) {

    this.page = $e.pageIndex + 1;
    this.resetPag();
    this.informacion();
  }
}

export interface Facturas {
  rut: string;
  business_name: string;
  fecha_emision: string;
  fecha_recepcion: string;
  estado: string;
  mnt_exento: number;
  mnt_iva: number;
  mnt_neto: number;
  mnt_total: number;
  is_exportacion: number;
}
