<div mat-dialog-title>

    <h1 class="titulo-simpli">Observaciones</h1>
    <small class="subtitulo-simpli">Vista previa</small>

</div>

 <div mat-dialog-content #chatContainer>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

      <div class="row" *ngIf="hay_observaciones == true">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="contenedorchat add_factoring" id="observations-container" #chatContent>
                <app-obs-display *ngFor="let observation  of observaciones" [observation]="observation"></app-obs-display>
            </div>


        </div>

      </div>

      <div class="row animate__animated animate__fadeIn" *ngIf="hay_observaciones == false" style="margin-top: 30px;">
        
        <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="sinRegistros" style="text-align: center;background: none;">
                <small class="small-sinregistros" style="font-size: 15px;">
                  <div style="text-align: center;"><i style="font-size: 30px;color:gray;margin-bottom: 20px;" class="fa-light fa-empty-set"></i></div>
                  No hay información.
                </small>
            </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
          <form  (ngSubmit)="enviar_mensaje()">
            <mat-form-field  color="primary">
              <mat-label>Escribe una observación</mat-label>
              <textarea matInput  name="observacion" required #mensajeInput></textarea>
              <button mat-icon-button matSuffix style=" color: #924eff;" type="submit">
                <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
              </button>
              <mat-hint>La observación es requerida</mat-hint>
            </mat-form-field>
          </form>
        </div>

      </div>

  </div>



</div>

<div mat-dialog-actions>

  <button mat-flat-button mat-dialog-close class="secondary-button">Cerrar</button>

</div>



