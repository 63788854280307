import { Component, Input, OnInit } from '@angular/core';
import { AgendarOnboarding } from '../onboarding/agendar/agendar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../settings/settings.service';

@Component({
  selector: 'contact-container',
  templateUrl: './contact-container.component.html',
  styleUrl: './contact-container.component.less'
})
export class ContactContainerComponent implements OnInit {
  contacto: any = "";
  name: any = "";
  loading_name: boolean = false;
  @Input() tittle: string = 'Si tienes preguntas, contacta a un ejecutivo.'; 

  id_company = localStorage.getItem('company_id');

  constructor(
    public settings: SettingsService, 
    public dialog: MatDialog, 
  ) { }

  ngOnInit(): void {
    this.get_ejecutivo_dinamico();
  }

  agendar() {
      console.log("a")
      let dialogRef = this.dialog.open(AgendarOnboarding, {
        width: '700px',
        minHeight: '600px',
        autoFocus: false,
      });
    }

    async get_ejecutivo_dinamico() {
      this.loading_name = true;
      try {
        const bd: any = await this.settings.get_query(1, `/cliente/ejecutivo-representante-empresa/` + this.id_company + '/');
        var body_data: any = bd._body;
        switch (bd.status) {
          case 200:
  
            this.contacto = body_data.success.info.numero_telefono;
            this.name =  body_data.success.info.nombre;
            
            this.loading_name  = false;

            break;

          default:
            break;
        }
  
      } catch (bd: any) {
  
      } finally {
        this.loading_name = false;
      }
    } 
}
