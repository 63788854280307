import { Component, Input, OnChanges,OnInit,AfterContentInit, SimpleChanges,ViewChild,} from "@angular/core";
import { NominaType } from "../../page/pago-remuneraciones-page.component";
import {AbstractControl, FormArray,FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import { CargaManualRemuneracionesComponent } from "../carga-manual-remuneraciones/carga-manual-remuneraciones.component";
import { SettingsService } from "src/app/settings/settings.service";
import { of } from "rxjs";
import { debounceTime, distinctUntilChanged, map,startWith,switchMap,} from "rxjs/operators";
import { SimulacionRemuneracionesComponent } from "../simulacion-remuneraciones/simulacion-remuneraciones.component";
import { InputDialog } from "src/app/input-dialog/dialog-input";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
declare var $ :any;

@Component({
  selector: "app-previous-nomina",
  templateUrl: "./previous-nomina.component.html",
  styleUrls: ["./previous-nomina.component.less"],
})
export class PreviousNominaComponent implements OnChanges, OnInit, AfterContentInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    // this.setErrorToDatos(this.nomina)
    this.initNominaFormArray();
  }

  @Input() nomina!: Array<NominaType>;
  @Input() accountType: any;
  @Input() bancos: any;
  @Input() company_id: any;
  @Input() showSpinner: any;

  originalDataSource: any;
  searchCtrl = new FormControl();
  noDataRow: boolean = false;
  disabled_subir_file: boolean = false;
  noDataFiltered: boolean = false;
  labelFloat: string = "never";

  data:any[]=[]
  dataSource = new MatTableDataSource(this.data);
  displayColumns = [
    "delete",
    "name",
    "rut",
    "number",
    "email",
    "amount",
    "banco",
    "tipo",
    "check",
  ];

  createNewNomina(): FormGroup {
    return this.fb.group({
      uid: [this.randomUID()],
      name: ["", Validators.required],
      rut: ["", Validators.required],
      number: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      amount: ["", Validators.required],
      id_bank: ["", Validators.required],
      id_type: ["", Validators.required],
      error: [true],
      errorRut: [false],
      errorName: [false],
      errorNumber: [false],
      errorBank: [false],
      errorType: [false],
      errorEmail: [false],
      errorAmount: [false],
      errorRemuneraciones: [true],
      arrayError: this.pushArrayErrores([]),
    });
  }

  getMatTooltipError(row: any) {
    return row.value.errorRemuneraciones &&
      row.value.error &&
      row.value.arrayError.length > 0
      ? this.errorArrayJoin(row)
      : row.value.error &&
        row.value.errorRemuneraciones &&
        row.value.arrayError.length <= 0
        ? "Información incorrecta"
        : "Información correcta";
  }


  ngOnChanges(changes: SimpleChanges): void {

  }

  get nominaArr() {
    return this.form.get("nomina") as FormArray;
  }

  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private settings: SettingsService
  ) {
    this.form = this.fb.group({
      nomina: this.fb.array([], Validators.required),
    });
  }


  ngAfterContentInit(): void {
    this.form.valueChanges.subscribe((data:any) => {
      this.checkNotEmptyRows();
      this.checkHaveErrorData();
    });
  }


  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  pushArrayErrores(errores: any) {
    const arrayErrores = this.fb.array([]);
    errores.forEach((error: any) => {
      arrayErrores.push(this.fb.control(error));
    });
    return arrayErrores;
  }

  checkHaveErrorData() {
    const filterError = this.nominaArr.value.filter(
      (row: any) => row.arrayError.length > 0
    );
    this.hasError = filterError.length > 0 ? true : false;
  }

  initNominaFormArray() {
    this.clearFormArray(this.nominaArr);
    this.nomina.forEach((nomina) => {
      this.nominaArr.push(
        this.fb.group({
          uid: [this.randomUID()],
          name: [nomina.name, Validators.required],
          rut: [this.formatRut(nomina.rut), Validators.required],
          number: [nomina.number, Validators.required],
          email: [nomina.email, [Validators.required, Validators.email]],
          amount: [
            `$ ${this.formatMoney(Math.round(nomina.amount))}`,
            Validators.required,
          ],
          id_bank: [nomina.id_bank, Validators.required],
          id_type: [nomina.id_type, Validators.required],
          error: nomina.error ? [nomina.error] : [false],
          errorRut: nomina.errorRut ? [nomina.errorRut] : [false],
          errorName: nomina.errorName ? [nomina.errorName] : [false],
          errorNumber: nomina.errorNumber ? [nomina.errorNumber] : [false],
          errorBank: nomina.errorBank ? [nomina.errorBank] : [false],
          errorType: nomina.errorType ? [nomina.errorType] : [false],
          errorEmail: nomina.errorEmail ? [nomina.errorEmail] : [false],
          errorAmount: nomina.errorAmount ? [nomina.errorAmount] : [false],
          errorRemuneraciones: nomina.errorRemuneraciones
            ? [nomina.errorRemuneraciones]
            : [false],
          arrayError: nomina.arrayError
            ? this.pushArrayErrores(nomina.arrayError)
            : this.pushArrayErrores([]),
        })
      );
    });

    let data = this.nominaArr.controls;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription()
    });
    this.checkHaveErrorData();
  }

  randomUID() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  errorArrayJoin(row: any) {
    const listErrors = row.value.arrayError;
    return listErrors.join(",").replace(".", "");
  }

  formArr: any;
  _filterGeneralByField(value: string) {
    this.formArr = this.searchCtrl.valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((val:any) => {
        return of(this.dataSource.data as AbstractControl[]).pipe(
          map((formArr: AbstractControl[]) =>
            formArr.filter((group: any) => {
              return group.get("formCtrl") .value.toLowerCase().includes(val.toLowerCase());
            })
          )
        );
      })
    );
    const dataToFilter = Array.from(this.nominaArr.controls);
    const filter = this.dataSource.data.filter((row: any) =>
      row.value.name.toLowerCase().includes(value)
    );
    if (value.length > 0) {
      let data = filter.length > 0 ? filter : [];
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource._updateChangeSubscription()
      });
    } else {

      let data = this.nominaArr.controls;
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource._updateChangeSubscription()
      });
    }

    this.noDataFiltered = this.dataSource.data.length === 0 ? true : false;
  }

  clean(rut: any) {
    if (typeof rut === "string") {
      return typeof rut === "string"
        ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
    }
    if (typeof rut === "number") {
      return typeof rut === "number"
        ? rut
            .toString()
            .replace(/^0+|[^0-9kK]+/g, "")
            .toUpperCase()
        : "";
    }
  }

  formatRut(
    rut: any,
    options = {
      dots: true,
    }
  ) {
    rut = this.clean(rut);

    if (rut === "") {
      return null;
    }

    let result;
    if (options.dots) {
      result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }
    } else {
      result = rut.slice(0, -1) + "-" + rut.substr(rut.length - 1);
    }

    return result;
  }

  addNewNomina() {
    this.nominaArr.insert(0, this.createNewNomina());
    let data = this.nominaArr.controls;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription()
    });

  }

  deleteEmpleado(row: any, index: any) {
    this.nominaArr.removeAt(index);

    let data = this.nominaArr.controls;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription()
    });

  }

  checkNotEmptyRows() {
    this.noDataRow = this.nominaArr.controls.length === 0 ? true : false;
  }

  getActualIndex(index: number) {
    return index + this.paginator.pageSize * this.paginator.pageIndex;
  }

  onChangeInputRut(event: any, row: FormGroup, index: number) {
    const rutFormat = this.formatRut(event.target.value);
    this.nominaArr.at(index).patchValue({
      rut: rutFormat,
    });
  }

  onChangeInput(event: any, row: any, index: any) {
    const value = event.target.value.replace(/[^0-9]/g, "");
    const uid = row.value["uid"];
    this.nominaArr.at(index).patchValue({
      amount: `$ ${this.formatMoney(value)}`,
    });
  }

  formatMoney = (amount: any, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? "-" : "";
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
      );
    } catch (e) {}
  };

  openDialogFile() {
    const dialogRef = this.dialog.open(CargaManualRemuneracionesComponent, {
      width: "800px",
      data: {
        company_id: this.company_id,
      },
    });

    dialogRef.componentInstance.company_id = this.company_id;
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.disabled_subir_file = true;
        this.nomina = [...result];
        this.setErrorToDatos(this.nomina);
      }
    });
  }

  validateField(input: string, row: FormGroup, index: number, field: string) {
    // if (row.value[field]) {
    //   row.controls[input].setErrors({ "incorrect": true });
    // }
    return (
      (row.controls[input].errors && row.controls[input].touched) ||
      row.value[field]
    );
  }

  validateIncorrect(
    field: string,
    row: FormGroup,
    index: number,
    errorField: string
  ) {
    return row.value[errorField] ? true : false;
  }

  hasError: boolean = false;
  setErrorToDatos(dataWithErrors: any) {
    this.nomina = [...dataWithErrors];
    // const filterError = dataWithErrors.filter((row) => row.arrayError.length > 0);
    // this.hasError = filterError.length > 0 ? true : false;
    this.clearFormArray(this.nominaArr);
    this.nomina.forEach((nomina) => {
      this.nominaArr.push(
        this.fb.group({
          name: [nomina.name, Validators.required],
          rut: [this.formatRut(nomina.rut), Validators.required],
          number: [nomina.number, Validators.required],
          email: [nomina.email, [Validators.required, Validators.email]],
          amount: [
            `$ ${this.formatMoney(Math.round(nomina.amount))}`,
            Validators.required,
          ],
          id_bank: [nomina.id_bank, Validators.required],
          id_type: [nomina.id_type, Validators.required],
          error: nomina.error ? [nomina.error] : [false],
          errorRut: nomina.errorRut ? [nomina.errorRut] : [false],
          errorName: nomina.errorName ? [nomina.errorName] : [false],
          errorNumber: nomina.errorNumber ? [nomina.errorNumber] : [false],
          errorBank: nomina.errorBank ? [nomina.errorBank] : [false],
          errorType: nomina.errorType ? [nomina.errorType] : [false],
          errorEmail: nomina.errorEmail ? [nomina.errorEmail] : [false],
          errorAmount: nomina.errorAmount ? [nomina.errorAmount] : [false],
          errorRemuneraciones: nomina.errorRemuneraciones
            ? [nomina.errorRemuneraciones]
            : [false],
          arrayError: nomina.arrayError
            ? this.pushArrayErrores(nomina.arrayError)
            : this.pushArrayErrores([]),
        })
      );
    });

    let data = this.nominaArr.controls;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription()
    });
    this.checkNotEmptyFields();
    this.checkHaveErrorData();
  }

  mapDataToPost(data: any) {
    return data.map((persona: any) => ({
      ...persona,
      amount: this.cleanAmount(persona.amount),
      rut: this.cleanRut(persona.rut),
    }));
  }

  checkDataHasError(data: any) {
    const filter = data.filter((persona: any) => persona.error);
    if (filter.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  touchAllFields(form: any) {
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        form.controls[key].markAsTouched();
      }
    }
  }

  touchAllControlsForm() {
    for (let index = 0; index < this.nominaArr.controls.length; index++) {
      const element = this.nominaArr.controls[index];
      this.touchAllFields(element);
    }
  }

  checkNotEmptyFields() {
    if (!this.form.valid) {
      this.touchAllControlsForm();
      return false;
    }
    // limpiar campos igualmente si no existen errores
    return true;
  }

  async validateDatosPost() {
    if (!this.checkNotEmptyFields()) {
      return;
    }

    const value = {
      datosRevisar: this.mapDataToPost(this.nominaArr.value),
    };
    try {
      const body_data: any = await this.settings.get_query(
        2,
        `pagoRemuneracionesManuales/${this.company_id}/`,
        value
      );
      const _body = JSON.parse(body_data._body);
      if (this.checkDataHasError(_body.arrayFront)) {
        this.setErrorToDatos(_body.arrayFront);
      } else {
        this.setErrorToDatos(_body.arrayFront);
        this.createSimulation(_body.arrayFront);
      }
    } catch (error) {}
  }

  cleanAmount(amount: any) {
    return amount.replace("$", "").replaceAll(".", "").replaceAll(" ", "");
  }

  cleanRut(rut: any) {
    return rut.replaceAll(".", "").replace(" ", "");
  }

  createSimulation(datos: any) {
    const dialogRef = this.dialog.open(SimulacionRemuneracionesComponent, {
      width: "1300px",
      maxHeight: "80vh",
      disableClose: true,
    });

    const deuda = datos.reduce(
      (acc: any, cv: any) => acc + parseInt(this.cleanAmount(cv.amount)),
      0
    );

    const values = datos.map((persona: any) => ({
      ...persona,
      bank: persona.id_bank,
      type: persona.id_type,
    }));

    dialogRef.componentInstance.facturas = values;
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.componentInstance.deuda = deuda;
    dialogRef.componentInstance.manual_ventas = true;
    dialogRef.componentInstance.is_not_remuneracion = false;

    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.open_input_dialog(
          "Simulacion creada con exito",
          "Se ha creado la simulacion con exito",
          "success",
          "",
          "info",
          "#FA454C"
        );
      }
    });
  }

  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any) {
    const dialogRef = this.dialog.open(InputDialog, {
      width: "700px",
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;

    dialogRef.afterClosed().subscribe((result:any) => {});
  }
}
