<ol [ngClass]="{'aling_right':aling_right, 'paginator-container':true}">
  <li (click)="navigateToPage(1)" style="font-size: 20px;"><<</li>
  <li (click)="navigateToPage(index - 1)" class="desk-pag"><</li>
  <li
    *ngFor="let page of pagination.pages; trackBy: trackByFn"
    class="paginator-number"
    [class.active]="page === pagination.index"
    (click)="navigateToPage(page)"
    [ngClass]="{'paginator-number':true , 'disabled-nav': page > maxPages}"
  >
    <span *ngIf="page != 0">{{ page }}</span>
  </li>
  <li (click)="navigateToPage(index + 1)" class="desk-pag2">></li>
  <li (click)="navigateToPage(maxPages)" style="font-size: 20px;">>></li>
</ol>
