import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;


@Component({
selector: 'app-obs-cartera',
templateUrl: './obs-cartera.html',
styleUrls: ['./obs-cartera.scss']
})
export class ObservacionesCartera implements OnInit, OnDestroy {


    i: any;
    element:any={};


    //Observaciones
    observaciones_cobranza: any[] = []
    observaciones_comite: any[] = []
    observaciones_ejecutivo: any[] = []
    observaciones_cliente: any[] = []
    Observaciones: FormGroup;
    rut_deudor: string = ""
    docto: string = ""
    deudor: string = ""

    //Activar observaciones
    act_observaciones: Boolean = false
    act_todas: Boolean = false
    activador_enviar_obs = false;


    constructor(
        private activated_router: ActivatedRoute, fb: FormBuilder, public snackBar: MatSnackBar,
        private router: Router, public dialog: MatDialog,
        public settings: SettingsService) {


            this.Observaciones = fb.group({
                'observacion': ['']
            });

    }


    myObserver: any
    ngOnDestroy() {
    this.myObserver.unsubscribe();
    }

    ngOnInit() {
        this.myObserver = this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
        console.log("router company_id-->" + localStorage.getItem('company_id'))
        this.load_all();
        }


        });


        this.load_all()


    }

    load_all() {



        this.set_storage_detail()
        this.activar_observaciones(this.element.rut_deudor, this.element.n_documento, this.element.deudor)

        this.rut_deudor = this.element.rut_deudor;
        this.docto = this.element.n_documento;
        this.deudor = this.element.deudor;

    }


    open_more_box(c: any, sw = false) {

        //this.id_close = 0
        let prefix = ""
    
    
        if (sw)
          prefix = "long_"
    
        if ($("#" + prefix + "body_box_" + c).hasClass("body-active")) {
          //cerrar
          console.log("aca1")
          $(".box-prospecto-responsive-body").removeClass("body-active animate__animated animate__fadeIn")
          $(".boton_arrow_box").removeClass("arrow_active animate__animated animate__fadeIn")
          //$("#"+prefix+"sel_arrow_"+c).removeClass("arrow_active animate__animated animate__fadeIn")
    
          $("#" + prefix + "body_box_" + c).removeClass("body-active animate__animated animate__fadeIn")
          $("#" + prefix + "sel_arrow_" + c).removeClass("arrow_active animate__animated animate__fadeIn")
    
          // expandir caja extra
          setTimeout(function () {
            $('.box-prospecto-responsive-body-coment').removeClass("body-active animate__animated animate__fadeIn")
            $(".boton_arrow_box_coment").removeClass("arrow_active animate__animated animate__fadeIn")
            // $('#'+'cab_'+c).removeClass('demarcador animate__animated animate__fadeIn')
            $('.box-prospecto-responsive-body-ejecutive').removeClass("body-active animate__animated animate__fadeIn")
            $(".boton_arrow_box_ejecutive").removeClass("arrow_active animate__animated animate__fadeIn")
            // $('#'+'cab2_'+c).removeClass('demarcador animate__animated animate__fadeIn')
            $('.box-prospecto-responsive-body-cobro').removeClass("body-active animate__animated animate__fadeIn")
            $(".boton_arrow_box_cobro").removeClass("arrow_active animate__animated animate__fadeIn")
            // $('#'+'cab3_'+c).removeClass('demarcador animate__animated animate__fadeIn')
          }, 500);
    
    
    
        }
        else {
          $(".box-prospecto-responsive-body").removeClass("body-active animate__animated animate__fadeIn")
          $(".boton_arrow_box").removeClass("arrow_active animate__animated animate__fadeIn")
          $("#" + prefix + "body_box_" + c).addClass("body-active animate__animated animate__fadeIn")
          $("#" + prefix + "sel_arrow_" + c).addClass("arrow_active animate__animated animate__fadeIn")
    
          // cerrar caja extra
          setTimeout(function () {
            $('.box-prospecto-responsive-body-coment').addClass('body-active animate__animated animate__fadeIn')
            $('.boton_arrow_box_coment').addClass('arrow_active animate__animated animate__fadeIn')
            $("#" + prefix + "sel_sel_arrow_" + c).addClass("arrow_active animate__animated animate__fadeIn")
            // $('#'+'cab_'+c).removeClass('demarcador animate__animated animate__fadeIn')
    
            $('.box-prospecto-responsive-body-ejecutive').addClass('body-active animate__animated animate__fadeIn')
            $('.boton_arrow_box_ejecutive').addClass('arrow_active animate__animated animate__fadeIn')
            $("#" + prefix + "well_arrow_" + c).addClass("arrow_active animate__animated animate__fadeIn")
            // $('#'+'cab2_'+c).removeClass('demarcador animate__animated animate__fadeIn')
    
            $('.box-prospecto-responsive-body-cobro').addClass('body-active animate__animated animate__fadeIn')
            $('.boton_arrow_box_cobro').addClass('arrow_active animate__animated animate__fadeIn')
            $("#" + prefix + "cobr_arrow_" + c).addClass("arrow_active animate__animated animate__fadeIn")
            // $('#'+'cab3_'+c).removeClass('demarcador animate__animated animate__fadeIn')
          }, 500);
    
        }
    }

    showSpinnerObs: boolean = true
    observaciones_cliente_pagador: any[] = [];
    show_spinner_obs: boolean = false;
    cargando_obs:boolean=true;
    obs_merge:any[] = [];
    async activar_observaciones(rut_deudor: string, dcto: string, deudor: string) {

      this.cargando_obs = true;
      this.showSpinnerObs = true
      this.observaciones_cobranza = []
      this.observaciones_comite = []
      this.observaciones_ejecutivo = []
      this.observaciones_cliente = []

      let result: any = await this.settings.get_query(1, `cartera-observaciones/?rut_deudor=${rut_deudor}&dcto=${dcto}`)

      if (result.status == 200) {
        var body_data: any = result._body
        console.log("OBSERVACIONES CARTERA")
        console.log(body_data)
        this.observaciones_cobranza = body_data.datos.observaciones_cobranza

        for(let i in this.observaciones_cobranza){
          this.observaciones_cobranza[i]['es_cobrador'] = true;
        }

        this.observaciones_comite = body_data.datos.observaciones_comite
        this.observaciones_ejecutivo = body_data.datos.observaciones_ejecutivo
        this.observaciones_cliente = body_data.datos.observaciones_cliente_pagador
        if (this.observaciones_cliente.length > 0 || this.observaciones_ejecutivo.length > 0 || this.observaciones_cobranza.length > 0) {
          this.showSpinnerObs = false
        }

        this.obs_merge = this.observaciones_cliente.concat(this.observaciones_cobranza)

        this.obs_merge.sort((a, b) => {
          return (a.date_created || a.date_created).localeCompare((b.date_created || b.date_created))
        })


        this.cargando_obs = false;

        console.log(this.obs_merge)

      }
    }

    cliente: string = ""
    async addObservacion($ev: any, value: any, accion: number) {

      this.activador_enviar_obs = true;
      this.show_spinner_obs = true;

      value['rut_deudor'] = this.rut_deudor
      value['docto'] = this.docto
      value['accion'] = accion
      value['fecha_compromiso'] = null
      value['cobradores'] = []
      value['ejecutivos'] = []
      value['prioridad'] = 1  
      value['cliente'] = this.cliente
      value['deudor'] = this.deudor
      value['mail'] = true

      $("#messagechat").val("")

      if (value.observacion) {

        try {
          const bd: any = await this.settings.get_query(2, 'cartera-observaciones/', value);
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:
    
                  this.activar_observaciones(this.rut_deudor, this.docto, this.deudor)
                  this.Observaciones.controls['observacion'].setValue("")
                  this.show_spinner_obs = false;
                  this.activador_enviar_obs = false

                  this.snackBar.open("El comentario se esta registrando espere un momento.", "Aceptar", {
                    duration: 7100,
                    panelClass: ['simpli-snack']
                  });
    
    
              break;
            default:
    
              break;
          }
        } catch (bd: any) {
    
        }
      }


    }

    async set_storage_detail() {
      try {
        
        let company_id = localStorage.getItem('company_id')


        const bd: any = await this.settings.get_query(1, "clientcartera/" + company_id + "/");
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
                if (body_data.datos != null) {
        
                  this.cliente = body_data.datos.nombre


                  if (body_data.datos.nombre == "") {
                    this.cliente = body_data.nombre_bd

                  }
                  else {
              
                  }
                }
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }

    stripHtml(html:any)
    {
       let tmp = document.createElement("DIV");
       tmp.innerHTML = html;
       return tmp.textContent || tmp.innerText || "";
    }


}
