
<div class="row row_main animate__animated animate__fadeIn">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <h1 class="title-label">¿Para qué necesitas el Certificado Digital Electrónico?</h1>

  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <div class="row">

          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div class="p-label">
                Para autentificar tu identidad y resguardar el proceso de cesión.<br>
                El Certificado te permite realizar trámites y transacciones digitales de forma segura, ya que<br>
                contiene tus datos y la información de las empresas asociadas a este archivo. Por esta rázon,<br>
                es muy importante validarlo antes de ceder.
              </div>

              <div class="link-label"><a href="https://www.sii.cl/servicios_online/1039-certificado_digital-1182.html" target="_blank">Descubre para qué sirve este Certificado</a></div>
          </div>


          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div style="width: 100%;">
                <img src="../../../../../assets/simpli/cesion/info-files.svg" class="img-files">
              </div>
          </div>

    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div class="btn-simpli-container">
          <button style="margin-left: 0px;" class="primary-button"  (click)="backClicked()" mat-flat-button>Volver</button>
        </div>
        
      </div>
    </div>

  </div>

</div>