import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { InputDialog } from '../../input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;


@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.html',
  styleUrls: ['./certificado.less']
})


export class Certificado implements OnInit {

  @Input() id_company:any;
  @Input() data:any;
  @Input() token:any;

  @Input() id_sim:any;
  
  valForm: FormGroup;
  run: any;
  rut:any;
  clave_pfx:any;
  file:any;
  clave_sii:any;

  success_certificado: boolean = false;
  message_pfx: string = "Certificado SII inválido."
  success_clave: boolean = false;
  success_rut: boolean = false;
  desactivar_guardar: boolean = false;

  constructor(public snackBar: MatSnackBar,
    private router: Router, public dialog: MatDialog,
    public settings: SettingsService, private _formBuilder: FormBuilder, private dialogRef: MatDialogRef<Certificado>) {


    this.valForm = this._formBuilder.group({
      certificado_sii: ['', Validators.required],
      clave_certificado: ['', Validators.required],
      rut_autorizado: ['', Validators.required],
    });

  }

  ngOnInit() {

    this.get_credenciales()

  }

  get_credenciales() {
    this.rut = this.data.rut_autorizado;
    this.clave_pfx = this.data.clave_pfx;
    this.clave_sii = this.data.clave_sii;
    this.file = this.data.archivo_pfx;


    if (this.file != null && this.file != "" && this.file != undefined) {
      this.valForm.controls['certificado_sii'].setValue(this.file)
      this.success_certificado = true
    } else {
      this.desactivar_guardar = true;
    }

    if (this.clave_pfx != null && this.clave_pfx != "" && this.clave_pfx != undefined) {
      this.valForm.controls['clave_certificado'].setValue(this.clave_pfx)
      this.success_clave = true
    } else {
      this.desactivar_guardar = true;
    }

    if (this.rut != null && this.file != "" && this.rut != undefined) {
      this.valForm.controls['rut_autorizado'].setValue(this.rut)
      this.success_rut = true
    } else {
      this.desactivar_guardar = true;
    }


  }

  open_certificado() {
    $("#certificado_pfx").trigger('click');
  }

  //CLAVE CERTIFICADO
  saving_clave:boolean=false;
  subir_clave_pfx($e:any) {
    this.saving_clave = true;
    this.desactivar_guardar = true;   
    this.clave_pfx = $e.target.value;

    if (this.clave_pfx == "") {
      this.desactivar_guardar = true;
      this.success_clave = false;
    } else {
      this.success_clave = true;
    }
    
    if (this.success_certificado && this.success_clave && this.success_rut) {
      this.desactivar_guardar = false;
    }
    this.saving_clave = false;
  }

  //RUT AUTORIZADO
  saving_rut:boolean=false;
  subir_rut_autorizado($e:any) {
    this.desactivar_guardar = true;
    let run = $e.target.value;
    run = run.toUpperCase();

    this.saving_rut = true;

    if (run == "") {
      this.desactivar_guardar = true;
      this.success_rut = false;
    } else {
      let dot = /\./gi
      let st = run.replace("-", "");
      st = st.replace(dot, "")
      let arr = st.split("");
      let st_out = "";
      for (let i = 0; i < arr.length; i++) {
        st_out = st_out + arr[i];
        if (i == arr.length - 2) {
          st_out = st_out + "-"
        }
      }
      this.rut = st_out.toUpperCase()
      this.valForm.controls['rut_autorizado'].setValue(this.rut)
      this.success_rut = true;
    }

    if (this.success_certificado && this.success_clave && this.success_rut) {
      this.desactivar_guardar = false;
    }

    this.saving_rut = false;


  }

  update() {

    this.dialogRef.close(true)

    this.snackBar.open("Datos de Certificado Digital Actualizado", "Aceptar", {
      duration: 10000,
      panelClass: "simpli-snack"
    });

  }

  open_input_dialog(title:any,subtitle:any,mensajeprincipal:any, mensajenegrita:any,icon:any,coloricon:any) {
    const dialogRef = this.dialog.open(InputDialog, {
      width: '700px',
      autoFocus: false,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;

    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  // Back nuevo app cliente
  saving_certificado:boolean=false;
  async subir_certificado_new($e:any){
    try {

      this.message_pfx = "Certificado SII inválido.";
      this.desactivar_guardar = true;
      let formData: FormData = new FormData();
      let file_input = $e.target.files;
      let file_name = "";
      this.saving_certificado = true;
      for (let i = 0; i < file_input.length; i++) {
        let name = file_input[i].name;
        formData.append('certificado_' + i, file_input[i], name);
        file_name = file_input[i].name;
      }
      this.valForm.controls['certificado_sii'].setValue(file_name);

      const bd: any = await this.settings.get_query(3, `cliente/cargar-certificado-digital/${this.id_company}/`,formData );
      var body_data: any = bd._body
      switch (bd.status) {
        case 200:


          this.file = file_name;
          this.valForm.controls['clave_certificado'].setValue("");
          this.valForm.controls['rut_autorizado'].setValue("");
          this.success_certificado = true;
          this.saving_certificado = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {

      this.saving_certificado = false;
      
      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:


          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }


  loading:boolean=false;
  async put_certificado(){
    try {
      this.loading = true;
      this.desactivar_guardar = true;
      let value:any = {
        "clave_pfx": this.clave_pfx,
        "rut_autorizado": this.rut
      }

      const bd: any = await this.settings.get_query(3, "cliente/certificado-digital/"+this.id_company+"/?simulation_id="+this.id_sim,value );
      var body_data: any = bd._body;
      switch (bd.status) {
        case 200:



          this.desactivar_guardar = false;
          this.loading = false;
          this.update();


        break;
        default:

        break;
      }

    } catch (bd:any) {


      this.valForm.controls['rut_autorizado'].setErrors({ 'incorrect': true });
      this.valForm.controls['clave_certificado'].setErrors({ 'incorrect': true });
      this.desactivar_guardar = false;
      this.loading = false;

      
      switch (bd.status) {
        case 500:


          break;
        case 400:


          break;
        default:


        break;
      }
    }
  }
  // Back nuevo app cliente

}

