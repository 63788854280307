import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AgregarFacturasNomina } from '../../agregar-facturas-nomina/agregar-facturas-nomina';
import { AgregarFacturas } from '../../agregar-facturas/agregar-facturas';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


declare var $: any;


@Component({
  selector: 'app-agregar-facturas-dialog',
  templateUrl: './agregar-facturas-dialog.html',
  styleUrls: ['./agregar-facturas-dialog.less']
})
export class AgregarFacturasDialog implements OnInit {


  @Input() id_company:any;
  @Input() parent:any;
  @Input() open_as_dialog:boolean=false;
  reemitida_mode:boolean=false;
  simulacion_id:any;
  @Input() id_sim:any;
  @Input() onboarding:boolean=false;

  constructor(public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, public snackBar:MatSnackBar, private router:Router, public dialogRef:MatDialogRef<AgregarFacturasDialog> ) {



    this.id_company = localStorage.getItem('company_id');



   }

  ngOnInit() {



  }

  facturas:any[]=[];
  comenzar_simulacion:boolean=false;
  open_agregar_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '530px',
      maxWidth: '95vw',
      disableClose: true,
    });


    dialogRef.componentInstance.open_as_dialog = this.open_as_dialog;
    dialogRef.componentInstance.reemitida_mode = this.reemitida_mode;
    dialogRef.componentInstance.simulacion_id = this.simulacion_id;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result;
        this.parent.facturas = this.facturas;
        this.parent.comenzar_simulacion = true;

        this.dialogRef.close(this.facturas);
      }
    });
  }

  open_agregar_facturas_by_nomina(){
    const dialogRef = this.dialog.open(AgregarFacturasNomina,{
      width: '600px',
      maxWidth: '95vw',
      disableClose: true,
      autoFocus:false,
    });

    dialogRef.componentInstance.open_as_dialog = this.open_as_dialog;
    dialogRef.componentInstance.reemitida_mode = this.reemitida_mode;
    dialogRef.componentInstance.simulacion_id = this.simulacion_id;

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result;

        this.parent.facturas = this.facturas;
        this.parent.comenzar_simulacion = true;

        this.dialogRef.close(this.facturas);
      }
    });
  }


}