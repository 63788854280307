<div class="container">
  <div class="row " style="padding-left:10px; padding-right:10px; width:100%; padding-bottom:30px; background-color:white;">
    <!-- Titulo screen  -->
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
          <h1 class="titulo-simpli">Alianzados Operaciones</h1>
      </div>
       <!-- caja datos -->
      <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 animate__animated animate__flipInX">
        <div class="caja_estadistica">
            <div class="caja-inner">
                <div class="button1">${{formatMoney(this.total_comision)}}</div>
                <small class="mami"> <strong>Total Mes</strong></small>
            </div>
        </div>
    </div>
<!-- dtapicker -->
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
    <div class="row">
      <div
        class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn"
      >
<!--         <mat-form-field style="position: relative">
          <mat-label>Filtrar</mat-label>
          <input matInput id="filtrosimulacion" (keyup)="validate($event)" />
          <i
            matSuffix
            class="fas fa-search"
            style="cursor: pointer"
            (click)="filtrar()"
          ></i>
          <mat-spinner
            *ngIf="display_spinner"
            matSuffix
            style="position: absolute; right: 30px; top: 0"
            diameter="40"
          ></mat-spinner>
        </mat-form-field> -->
      </div>

      <div
        class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn"
      >
        <mat-form-field style="width: 100%">
          <mat-label>Filtro por Mes</mat-label>
          <mat-select
            [(value)]="filtroMes"
            panelClass="my-select"
            id="selectionstatus"
            (selectionChange)="filtrarMes($event)"
          >
            <mat-option value="">Limpiar</mat-option>
            <mat-option value="1" class="purpleselect" >Enero</mat-option>
            <mat-option value="2" class="purpleselect" >Febrero</mat-option>
            <mat-option value="3" class="pinkselect">Marzo</mat-option>
            <mat-option value="4" class="pinkselect">Abril</mat-option>
            <mat-option value="5" class="orangeselect" >Mayo</mat-option>
            <mat-option value="6" class="orangeselect" >Junio</mat-option>
            <mat-option value="7" class="greenselect" >Julio</mat-option>
            <mat-option value="8" class="greenselect" >Agosto</mat-option>
            <mat-option value="9" class="greenselect" >Septiembre</mat-option>
            <mat-option value="10" class="redselect" >Octubre</mat-option >
            <mat-option value="11" class="redselect" >Noviembre</mat-option >
            <mat-option value="12" class="redselect" >Diciembre</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">


      <mat-form-field style="width: 100%">
          <mat-label>Filtro Año</mat-label>
          <mat-select
            [(value)]="filtroAnio"
            panelClass="my-select"
            id="selectionstatus"
            (selectionChange)="filtrarAnio($event)"
          >
            <mat-option value="">Limpiar</mat-option>
            <mat-option value="2020" class="redselect" >2020</mat-option>
             <mat-option value="2021" class="redselect" >2021</mat-option>
              <mat-option value="2022" class="redselect" >2022</mat-option>
               <mat-option value="2023" class="redselect" >2024</mat-option>
                <mat-option value="2024" class="redselect" >2025</mat-option>
            </mat-select>
      </mat-form-field>
<!--         <mat-form-field style="width: 100%">
          <mat-label>Año</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [value]="this.f_hoy"
            (dateChange)="filtro_fecha($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
      </div>
    </div>
  </div>
<!-- Tabla alianzados -->

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top:30px;">

          <div *ngIf="show_spinner">
              <mat-spinner diameter="40" class="spinner-arrayan-general centerabsolute"></mat-spinner>
          </div>

          <div *ngIf="!show_spinner">
              <div *ngIf="has_operaciones">
                  <div *ngIf="display_velo" class="velo-inactivo"></div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 header_operaciones">
                      <div class="row row_header">
                          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 header_column">
                              Cliente
                          </div>
                          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-1 header_column">
                              Estados
                          </div>
                          <div class="col-xs-6 col-sm-6 col-md-1 col-lg-2 header_column" style="padding-right:0px;">
                              Monto giro
                          </div>
                          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 header_column" style="padding-right:0px;">
                              Monto sin giro
                          </div>
                          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 header_column" style="padding-right:0px;">
                              Excedentes
                          </div>
                          <div class="col-xs-6 col-sm-6 col-md-1 col-lg-1 header_column" style="padding-right:0px;">
                              Comisión
                          </div>

                      </div>

                  </div>
                  <div class="operaciones_container col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                          <div *ngFor="let element of this.ELEMENT_DATA; let i=index" class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-left:0px; padding-right:0px;">
                              <div class="box-prospecto-responsive short-box-prospecto" style="background: white !important;position: relative;">

                                  <div class="box-prospecto-responsive-head row">
                                      <div class="ejecutivo name_empresa col-xs-6 col-sm-6 col-md-4 col-lg-4 col-box" style="justify-content:flex-start">
                                          <div>
                                              <!-- <button style="width:18px; height:35px; line-height:25px; right:2px;" mat-icon-button [matMenuTriggerFor]="appMenu2">
                          <mat-icon style="font-size: 16px;color:gray">more_vert</mat-icon>
                        </button>

                                              <mat-menu #appMenu2="matMenu"> -->

                                                    <!-- Agregando nueva vista -->
                         <!-- <button mat-menu-item *ngIf="element.excedentes == 0 && element.simulacion_id!=null" (click)="detalle_operacion(element.company.id,element.id)" >
                            <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Curse de Operación">chrome_reader_mode</mat-icon>
                          <span> Curse de Operación </span>
                        </button>

                        <button mat-menu-item matTooltip="Descargar" (click)="downloadExcel(element.id)">
                          <mat-icon type="button" style=" color: #3b86ff !important;font-size: 18px;width: 16px; height: 16px;" matTooltip="Descargar excel">get_app</mat-icon>
                          <span> Descargar en Excel </span>
                        </button>

                        <button mat-menu-item (click)="editarOperacion(element)">
                            <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Editar operación">edit</mat-icon>
                          <span> Editar operación</span>
                        </button>

                                                  <button mat-menu-item (click)="ver_observaciones(element.id)">
                          <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Editar operación">comment</mat-icon>
                        <span> Observaciones</span>
                      </button>
                                                  <button mat-menu-item matTooltip="Eliminar" (click)="dialog_descartar(element.id)">
                          <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Descartar operación">delete</mat-icon>
                          <span> Descartar operación</span>
                       </button>

                          <button mat-menu-item (click)="abrir_simulacion(element.company.id, element.simulacion_id)" *ngIf="element.simulacion_id!=null">
                            <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Editar operación">signal_cellular_alt</mat-icon>
                          <span> Abrir Simulación</span>
                        </button>


                      <button mat-menu-item (click)="ir_rib(element.company.id)">
                          <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px;     width: 16px; height: 16px;" matTooltip="Ver Rib">assessment</mat-icon>
                        <span> Ir al Rib</span>
                      </button>

                      <button mat-menu-item (click)="ir_baceado(element.company.id)" *ngIf="this.role =='admi' || this.role =='anri'">
                          <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px;     width: 16px; height: 16px;"  matTooltip="Ir al vaciado de balance">file_copy</mat-icon>
                        <span> Vaciado de Balance</span>
                      </button>

                      <button mat-menu-item (click)="documentos_legales(element.company.id)">
                          <mat-icon  type="button" style="color: #3b86ff !important; font-size: 18px;     width: 16px; height: 16px;"  matTooltip="Documentos legales">folder</mat-icon>
                        <span> Documentos legales</span>
                      </button>

                      <button mat-menu-item (click)="this.solicitud_linea(element.company.id)">
                        <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;"  matTooltip="Solicitud de Linea">assignment_turned_in</mat-icon>
                        <span>Solicitud de Linea</span>
                      </button>

                      <button mat-menu-item (click)="liquidaciones(element.company.id)">
                        <mat-icon  type="button" style="color: #3b86ff   !important; font-size: 18px;width: 16px; height: 16px;"  matTooltip="Liquidaciones">description</mat-icon>
                        <span>Liquidaciones</span>
                      </button>

                      <button mat-menu-item (click)="excedentes(element.company.id)">
                        <mat-icon  type="button" style="color: #3b86ff   !important; font-size: 18px;width: 16px; height: 16px;"  matTooltip="Liquidaciones">description</mat-icon>
                        <span>Excedentes</span>
                      </button>

                           </mat-menu> -->
                                          </div>
                                          <!-- <div (click)="open_more_box_res(element.id,true, element.company.rut, element.company.id);" style="padding-top:6px; margin-right:2px;" class="button-box button-down ">
                                              <i id="long_sel_arrow_res{{element.id}}" class="material-icons boton_arrow_box {{element.class_arrow}}" style="color: black; cursor:pointer;">
                          keyboard_arrow_down
                        </i>
                                          </div> -->
                                          <span class="num_operacion"><span matTooltip="{{ element.company.business_name }}">{{
                                            element.company.business_name.length > 38
                                              ? (element.company.business_name | slice: 0:38) +
                                                '..'
                                              : element.company.business_name
                                          }}</span>
                                        •
                                          <span style="font-size: 12px">{{
                                            element.company.rut
                                          }}
                                          </span>
                                          <!-- <span *ngIf="element.activo!=null && element.excedentes == 0 && element.simulacion_id!=null"  (click)="detalle_operacion(element.company.id,element.id)" style="cursor:pointer;">
                                            <span class="avatar-container"  style="background-color: #9595F9 !important;" matTooltip="En Ejecutivo" *ngIf="element.orden==1">
                                              EJ
                                            </span>

                                            <span class="avatar-container"  style="background-color: #7E6DFC !important;;" matTooltip="En Operación" *ngIf="element.orden==2">
                                              OP
                                            </span>

                                            <span class="avatar-container"  style="background-color: #6256F4 !important;;" matTooltip="En Riesgo" *ngIf="element.orden==3">
                                              RG
                                            </span>

                                            <span class="avatar-container"  style="background-color: #4D49F2 !important;;" matTooltip="En Sub Gerente" *ngIf="element.orden==4">
                                              SG
                                            </span>

                                            <span class="avatar-container"  style="background-color: #4040C1 !important;;" matTooltip="En Gerente Comercial" *ngIf="element.orden==5">
                                              GC
                                            </span>

                                            <span class="avatar-container"  matTooltip="En Gerente General" *ngIf="element.orden==6">
                                              GG
                                            </span>

                                            <span *ngIf="element.estado_operacion == 1 && element.excedentes == 0 && element.simulacion_id != null">&nbsp;&nbsp;<i class="fas fa-check" style="color: #3BA46F;" matTooltip="Ya aprobaste esta operación"></i></span>
                                            
                                          </span> -->
                                      </span>
                                      </div>
                                      <!-- <div class="ejecutivo col-xs-6 col-sm-6 col-md-2 col-lg-2 col-box">
                                          {{element.ejecutivo}}
                                      </div> -->
                                      <div class="col-xs-6 col-sm-6 col-md-1 col-lg-1 col-box" style="padding-left:0px;">

                                          <div class="estado_creado" *ngIf="element.estado ==0">
                                              <span mat-icon-button  style="cursor:pointer">
                                                Creada
                                              </span>

                                          </div>
                                          <div class="estado_simulado" *ngIf="element.estado ==1">
                                              <span mat-icon-button   style="cursor:pointer">
                                                Simulado
                                             </span>

                                          </div>
                                          <div class="estado_aprobado" *ngIf="element.estado ==2">
                                              <span mat-icon-button  style="cursor:pointer">
                                                Aprobado Cliente
                                              </span>
                                          </div>

                                          <div class="estado_gerente" *ngIf="element.estado ==8">
                                              <span mat-icon-button   style="cursor:pointer">
                                               Aprobado Gerente
                                              </span>
                                          </div>

                                          <div class="estado_verificado" *ngIf="element.estado ==3">
                                              <span mat-icon-button   style="cursor:pointer">
                                                Verificado
                                             </span>
                                          </div>

                                          <div class="estado_rechazado" *ngIf="element.estado ==4">
                                              <span mat-icon-button   style="cursor:pointer">
                                                 Rechazo Riesgo
                                              </span>
                                          </div>
                                          <div class="estado_cursado" *ngIf="element.estado ==5">
                                              <span mat-icon-button  style="cursor:pointer">
                                               Cursado
                                             </span>
                                          </div>
                                          <div class="estado_girado" *ngIf="element.estado ==6">
                                              <span mat-icon-button >
                                               Girado
                                              </span>
                                          </div>



                                          <mat-menu #appMenu="matMenu">
                                              <button style="text-align:center; border-left:10px solid #3b86ff" class="select_blue" mat-menu-item (click)="escogerEstado(1,element.id)">
                                                <span> Simulado</span>
                                             </button>
                                              <button style="text-align:center; border-left:10px solid #ff933b" class="select_orange" mat-menu-item (click)="escogerEstado(2,element.id)">
                                                 <span> Aprobado Cliente</span>
                                               </button>
                                               <button style="text-align:center; border-left:10px solid #F9E78B" class="select_yellow" mat-menu-item (click)="escogerEstado(8,element.id)">
                                                <span> Aprobado Gerente</span>
                                              </button>
                                              <button style="text-align:center; border-left:10px solid #c100da" class="select_purple" mat-menu-item (click)="escogerEstado(3,element.id)">
                                                  <span> Verificado</span>
                                                </button>
                                              <button style="text-align:center; border-left:10px solid #F44B4B" class="select_red" mat-menu-item (click)="escogerEstado(4,element.id)">
                                                 <span> Rechazo Riesgo</span>
                                               </button>
                                              <button style="text-align:center; border-left:10px solid #9500da" class="select_fuchsia" mat-menu-item (click)="escogerEstado(5,element.id)">
                                                  <span> Cursado</span>
                                                </button>
                                              <button style="text-align:center; border-left:10px solid #03caae" *ngIf="this.role=='admi' || this.role=='teso'" class="select_green" (click)="confirmGiro(element.id)" mat-menu-item>
                                                 <span> Girado</span>
                                              </button>
                                      </mat-menu>
                                      </div>

                                      <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-box" style="padding-left:0px;">
                                          $ {{formatMoney(element.monto_operacion)}}
                                      </div>
                                      <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-box" style="padding-left:0px;">
                                          ${{formatMoney(element.monto_sin_giro)}}
                                      </div>
                                      <div class="col-xs-6 col-sm-6 col-md-1 col-lg-2 col-box" style="padding-left:0px;">
                                          ${{formatMoney(element.excedentes)}}
                                      </div>
                                      <div class="col-xs-6 col-sm-6 col-md-1 col-lg-1 col-box" style="padding-left:0px;">
                                        ${{formatMoney(element.comision_alianzado)}}
                                    </div>
                                  </div>
                                  <div id="long_body_box_res{{element.id}}" class="box-prospecto-responsive-body {{element.class}}" style="background: white;width: 100% !important;">



                                      <!--INDICADORES GET-->

                                      <!-- <div class="row" style="width: 100% !important;margin:0 auto;position: relative;">
                                          <mat-spinner class="centerabsolute" *ngIf="cargandometricas" diameter="40" style="z-index: 99999;position: absolute !important;width: 100px !important;top:44px"></mat-spinner>
                                          <div *ngIf="cargandometricas" class="velo-inactivo"></div>
                                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="background: #f9f9f9;border-radius: 10px;padding: 5px;">

                                              <div class="row col-top" style="margin: 0 auto; width: 100%;">
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">Venta Mensual</span>
                                                          <span *ngIf="this.venta_mensual != null" (click)="carpeta_tributaria(element.company.id)" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;cursor:pointer">${{formatMoney(this.venta_mensual)}}</span>
                                                          <span *ngIf="this.venta_mensual == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;">No ingresada</span>

                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;cursor: pointer;">
                                                          <span style="display:block;font-size:12px">Dicom Empresa</span>
                                                          <span *ngIf="this.dicom != null" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span (click)="this.equifax(element.company.id, element.company.business_name, this.type_company)">${{formatMoney(this.dicom)}}</span></span>
                                                          <span *ngIf="this.dicom == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;" (click)="this.equifax(element.company.id, element.company.business_name, this.type_company)">Sin consulta</span>

                                                      </div>
                                                  </div>


                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0" *ngIf="hay_socios">
                                                      <div style="position: relative;text-align: center;cursor: pointer;" *ngFor="let soc of this.socios | slice:0:1">
                                                          <span style="display:block;font-size:12px">Socios</span>
                                                          <span *ngIf="soc.monto != null" matTooltip="{{soc.nombre}}" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;" (click)="this.equifax(soc.company_id, soc.nombre, soc.type_company)"><span (click)="this.equifax(this.id_cliente, this.bussines_name, this.type_company)">Ver detalle</span></span>
                                                          <span *ngIf="soc.monto == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;" (click)="this.equifax(this.id_cliente, this.bussines_name, this.type_company)">Sin consulta</span>

                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="min-height: 50px;padding:0" *ngIf="!hay_socios">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">Socios</span>
                                                          <span matTooltip="Socios no consultado" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span >Sin consulta</span></span>

                                                      </div>
                                                  </div>
                                              </div>


                                              <div class="row col-top" style="margin: 0 auto; width: 100%;">

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center; cursor:pointer;">
                                                          <span style="display:block;font-size:12px">Deuda Neta</span>
                                                          <span *ngIf="this.deuda_neta != null" (click)="this.cartera_cobranza(element.company.rut,element.company.business_name,element.company.id)" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span>${{formatMoney(this.deuda_neta)}}</span></span>
                                                          <span *ngIf="this.deuda_neta == null" (click)="this.cartera_cobranza(element.company.rut,element.company.business_name,element.company.id)" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span>Sin consulta</span></span>
                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" 
                                                  style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">Monto Total Facturas</span>
                                                          <span *ngIf="this.deuda_cartera != null" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span>${{formatMoney(this.deuda_cartera)}}</span></span>
                                                          <span *ngIf="this.deuda_cartera == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span>Sin consulta</span></span>

                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">SBIF</span>
                                                          <span *ngIf="this.sbif != null" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span>${{formatMoney(this.sbif)}}</span></span>
                                                          <span *ngIf="this.sbif == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span>Sin Consulta</span></span>

                                                      </div>
                                                  </div>

                                              </div>

                                          </div>
                                      </div> -->

                                      <!--END INDICADORES-->




                                      <!-- <div class="row" *ngIf="element.deudores.length>0" style="width: 100% !important;margin:0 auto; margin-top:20px; margin-bottom:20px;">

                                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin:0 auto;">
                                              <h1 class="titulodetalle">
                                                  <div class="titulodetail">Deudores vinculados</div>
                                              </h1>

                                              <table style="width: 100%; margin-left:20px;">
                                                  <tr>

                                                      <th style="text-align: left;color:#312c85;"><input value="Deudor" readonly style="width: 100%;background:none;border:none;
                                                          text-align:center"></th> -->
                                                      <!-- <th style="text-align: center;color:#312c85;"><input value="Informacion" readonly style="width: 100%;background:none;border:none;text-align: center;"></th> -->
                                                      <!-- <th style="text-align: center;color:#312c85;"><input value="SBIF" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>
                                                      <th style="text-align: center;color:#312c85;"><input value="Dicom" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>
                                                      <th style="text-align: center;color:#312c85;"><input value="Avaluo" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>
                                                      <th style="text-align: center;color:#312c85;"><input value="Tamaño" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>
                                                      <th style="text-align: center;color:#312c85;"><input value="Portal" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>

                                                  </tr>
                                                  <tr *ngFor="let d of element.deudores; let j=index">

                                                      <td style="text-align: left;" class="nombredeudor" matTooltip="{{d.business_name}}">
                                                          <i class="fas fa-minus-circle" style="cursor: pointer;color:#ada6a6; width:20%" matTooltip="Eliminar" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger"></i>
                                                          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                                                              <mat-card style="max-width: 500px;">
                                                                  <mat-card-content style="text-align: center;">
                                                                      ¿Desea remover este deudor de la operación?
                                                                      <br>
                                                                      <button type="button" (click)="eliminar(element.id,d.id,i)" style="width: 130px !important; max-width: 250px !important; margin-top: 10px !important;background: #dbdbdb !important; color: black;" mat-flat-button color="primary" class="btn-f-width btn-separated btn-success-in">Remover</button>


                                                                  </mat-card-content>
                                                              </mat-card>
                                                          </mde-popover>
                                                          <input type="text" value="{{d.business_name}}" style="width:80%" class="no-input">

                                                      </td>


                                                      <td style="text-align:center; font-size:13px;">
                                                          <input *ngIf="d.sbif != null" readonly value="${{formatMoney(d.sbif)}}" style="border:none;background: none;width: 100%; text-align:center;">
                                                          <input *ngIf="d.sbif == null" readonly value="No consultado" style="border:none;background: none;width: 100%; text-align:center;">
                                                      </td>
                                                      <td style="text-align:center; font-size:13px;">
                                                          <input *ngIf="d.dicom!= null" readonly value="${{formatMoney(d.dicom)}}" style="border:none;background: none;width: 100%; text-align:center;">
                                                          <input *ngIf="d.dicom== null" readonly value="No consultado" style="border:none;background: none;width: 100%; text-align:center;">
                                                      </td>

                                                      <td style="text-align:center; font-size:13px;">
                                                          <input *ngIf="d.avaluos != null" readonly value="${{formatMoney(d.avaluos)}}" style="border:none;background: none;width: 100%; text-align:center;">
                                                          <input *ngIf="d.avaluos == null" readonly value="No consultado" style="border:none;background: none;width: 100%; text-align:center;">
                                                      </td>

                                                      <td style="text-align:center; font-size:13px;">
                                                          <input *ngIf="d.tamano != null" readonly value="{{d.tamano}}" style="border:none;background: none;width: 100%; text-align:center;">
                                                          <input *ngIf="d.tamano == null" readonly value="No consultado" style="border:none;background: none;width: 100%; text-align:center;">
                                                      </td>

                                                      <td style="text-align:center; max-width: 88px;">
                                                          <i matTooltip="Perfil deudor" style="cursor:pointer;color:#ada6a6;" class="fas fa-address-card" (click)="deudor_profile(d.id)"></i>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </div>


                                      </div> -->
                                  </div>

                              </div>

                              <!--VERSION RESPONSIVE-->
                              <div class="box-prospecto-responsive phon-box-prospecto" style="background: white !important;position: relative;min-height: 100px !important;">
                                  <div [ngClass]="{'statusbadge1':element.estado==0,
                                'statusbadge2':element.estado==1,
                                'statusbadge3':element.estado==2,
                                'statusbadge4':element.estado==3,
                                'statusbadge5':element.estado==4,
                                'statusbadge6':element.estado==5,
                                'statusbadge7':element.estado==6,
                                'statusbadge8':element.estado==7,
                                'statusbadge9':element.estado==8,
                                'statusbadge10':element.estado==9,
                                'statusbadge11':element.estado==10}">
                                      <div style="text-align: center;margin-top: 13px;color:black;font-weight: 600;" *ngIf="element.estado ==0">
                                          <span mat-icon-button style="cursor:pointer">
                                      Creada
                                    </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==1">

                                          <span mat-icon-button style="cursor:pointer">
                                    Simulado
                                  </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==2">

                                          <span mat-icon-button  style="cursor:pointer">
                                            Aprobado Cliente
                                          </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==8">

                                          <span mat-icon-button  style="cursor:pointer">
                                            Aprobado Gerente
                                          </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==3">

                                          <span mat-icon-button  style="cursor:pointer">
                                    Verificada
                                  </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==4">

                                          <span mat-icon-button  style="cursor:pointer">
                                    Rechazada Riesgo
                                  </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==5">

                                          <span mat-icon-button  style="cursor:pointer">
                                    Cursada
                                  </span>
                                      </div>
                                      <div style="text-align: center;margin-top: 13px;color:white;font-weight: 600;" *ngIf="element.estado ==6">

                                          <span mat-icon-button>
                                    Girado
                                  </span>
                                      </div>


                                      <!-- <mat-menu #appMenu3="matMenu">

                                          <button style="text-align:center; border-left:10px solid #3b86ff" class="select_blue" mat-menu-item (click)="escogerEstado(1,element.id)">
                                    <span> Simulado</span>
                                  </button>
                                          <button style="text-align:center; border-left:10px solid #ff933b" class="select_orange" mat-menu-item (click)="escogerEstado(2,element.id)">
                                    <span> Aprobado Cliente</span>
                                  </button>
                                  <button style="text-align:center; border-left:10px solid #F9E78B"class="select_yellow" (click)="escogerEstado(8,element.id)" mat-menu-item>
                                    <span> Aprobado Gerente</span>
                                 </button>
                                  <button style="text-align:center; border-left:10px solid #c100da" class="select_purple" mat-menu-item (click)="escogerEstado(3,element.id)">
                                    <span> Verificado</span>
                                  </button>
                                          <button style="text-align:center; border-left:10px solid #F44B4B" class="select_red" mat-menu-item (click)="escogerEstado(4,element.id)">
                                    <span> Rechazo Riesgo</span>
                                  </button>
                                          <button style="text-align:center; border-left:10px solid #9500da" class="select_fuchsia" mat-menu-item (click)="escogerEstado(5,element.id)">
                                    <span> Cursado</span>
                                  </button>
                                          <button style="text-align:center; border-left:10px solid #03caae" *ngIf="this.role=='admi' || this.role=='teso'" class="select_green" (click)="confirmGiro(element.id)" mat-menu-item>
                                    <span> Girado</span>
                                   
                                  </button>

                                      </mat-menu> -->
                                      <!-- <button style="width:85%; display:flex; margin-top:20px; justify-content:flex-end; height:35px; line-height:25px; right:2px;" mat-icon-button [matMenuTriggerFor]="appMenu4">
                          <mat-icon style="font-size: 18px;color:gray">more_vert</mat-icon>
                        </button>
                                      <mat-menu #appMenu4="matMenu"> -->

                                      <!-- Agregando nueva vista -->
                         <!-- <button *ngIf="element.excedentes == 0 && element.simulacion_id!=null" mat-menu-item (click)="detalle_operacion(element.company.id,element.id)" >
                            <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Curse de Operación">chrome_reader_mode</mat-icon>
                          <span> Curse de Operación </span>
                        </button>
                                          <button mat-menu-item (click)="editarOperacion(element)">
                            <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Editar operación">edit</mat-icon>
                          <span> Editar operación</span>
                          </button>
                                          <button mat-menu-item matTooltip="Eliminar" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger">
                              <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Descartar operación">delete</mat-icon>
                              <span> Descartar operación</span>
                          </button>
                                          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                                              <mat-card style="max-width: 500px;">
                                                  <mat-card-content style="text-align: center;">
                                                      ¿Desea descartar esta operación?
                                                      <br>
                                                      <button type="button" (click)="eliminarOperacion(element.id)" style="width: 130px !important; max-width: 250px !important; margin-top: 10px !important;background: #dbdbdb !important;
                                color: black;" mat-flat-button color="primary" class="btn-f-width btn-separated btn-success-in">Remover</button>


                                                  </mat-card-content>
                                              </mat-card>

                                          </mde-popover>


                                          <button mat-menu-item (click)="abrir_simulacion(element.company.id, element.simulacion_id)" *ngIf="element.simulacion_id!=null">
                                <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;" matTooltip="Editar operación">signal_cellular_alt</mat-icon>
                              <span> Abrir Simulación</span>
                            </button>

                                          <button mat-menu-item (click)="ir_rib(element.company.id)">
                              <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px;     width: 16px; height: 16px;" matTooltip="Ver Rib">assessment</mat-icon>
                            <span> Ir al Rib</span>
                          </button>
                                          <button mat-menu-item (click)="ir_baceado(element.company.id)" *ngIf="this.role =='admi' || this.role =='anri'">
                              <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px;     width: 16px; height: 16px;"  matTooltip="Ir al vaciado de balance">file_copy</mat-icon>
                            <span> Vaciado de Balance</span>
                          </button>
                                          <button mat-menu-item (click)="documentos_legales(element.company.id)">
                              <mat-icon  type="button" style="color: #3b86ff !important; font-size: 18px;     width: 16px; height: 16px;"  matTooltip="Documentos legales">folder</mat-icon>
                            <span> Documentos legales</span>
                          </button>
                                          <button mat-menu-item (click)="this.solicitud_linea(element.company.id)">
                            <mat-icon  type="button" style="color: #3b86ff  !important; font-size: 18px; width: 16px; height: 16px;"  matTooltip="Solicitud de Linea">assignment_turned_in</mat-icon>
                            <span>Solicitud de Linea</span>
                          </button>
                                          <button mat-menu-item (click)="liquidaciones(element.company.id)">
                            <mat-icon  type="button" style="color: #3b86ff   !important; font-size: 18px;width: 16px; height: 16px;"  matTooltip="Liquidaciones">description</mat-icon>
                            <span>Liquidaciones</span>
                          </button>

                                          <button mat-menu-item (click)="excedentes(element.company.id)">
                            <mat-icon  type="button" style="color: #3b86ff   !important; font-size: 18px;width: 16px; height: 16px;"  matTooltip="Liquidaciones">description</mat-icon>
                            <span>Excedentes</span>
                          </button>

                                      </mat-menu> -->
                                  </div>
                                  <div class="box-prospecto-responsive-head">
                                      <div class="ejecutivo name_empresa">
                                        <span class="num_operacion">
                                          N°{{ element.id }}
                                        </span>
                                        <span>
                                          {{ element.company.business_name }} 
                                          <!-- <span *ngIf="element.activo!=null && element.excedentes == 0 && element.simulacion_id!=null" (click)="detalle_operacion(element.company.id,element.id)" style="cursor:pointer;">
                                            <span class="avatar-container"  style="background-color: #9595F9 !important;" matTooltip="En Ejecutivo" *ngIf="element.orden==1">
                                              EJ
                                            </span>
                                            <span class="avatar-container"  style="background-color: #7E6DFC !important;;" matTooltip="En Operación" *ngIf="element.orden==2">
                                              OP
                                            </span>
                                            <span class="avatar-container"  style="background-color: #6256F4 !important;;" matTooltip="En Riesgo" *ngIf="element.orden==3">
                                              RI
                                            </span>
                                            <span class="avatar-container"  style="background-color: #4D49F2 !important;;" matTooltip="En Sub Gerente" *ngIf="element.orden==4">
                                              SG
                                            </span>
                                            <span class="avatar-container"  style="background-color: #4040C1 !important;;" matTooltip="En Gerente Comercial" *ngIf="element.orden==5">
                                              GC
                                            </span>
                                            <span class="avatar-container"  style="background-color: #342664 !important;;" matTooltip="En Gerente General" *ngIf="element.orden==6">
                                              GG
                                            </span>

                                            <span *ngIf="element.estado_operacion == 1 && element.excedentes == 0 && element.simulacion_id != null">&nbsp;&nbsp;<i class="fas fa-check" style="color: #3BA46F;" matTooltip="Ya aprobaste esta operación"></i></span>

                                          
                                          </span> -->
                                        </span>
                                      </div>
                                      <div class="ejecutivo">
                                          {{element.company.rut}}
                                      </div>
                                      <div class="ejecutivo">
                                          Comisión: ${{formatMoney(element.comision_alianzado)}}
                                      </div>
                                      <div class="titulo_operacion" style="font-size:13px;">
                                          <span>
                      Giro: $ {{formatMoney(element.monto_operacion)}} / Sin Giro: ${{formatMoney(element.monto_sin_giro)}} / Excedentes:${{formatMoney(element.excedentes)}} 
                    </span>

                                      </div>

                                      <div class="menu-box-container">

                                          <!-- <div (click)="open_more_box(element.id,false, element.company.rut, element.company.id);" class="button-box button-down ">
                                              <i id="sel_arrow_{{element.id}}" class="material-icons boton_arrow_box {{element.class_arrow}}" style="color: black; cursor:pointer;">
                        keyboard_arrow_down
                      </i>
                                          </div> -->

                                      </div>

                                  </div>
                                  <div id="body_box_{{element.id}}" class="box-prospecto-responsive-body {{element.class}}" style="background: white;width: 100% !important;position: relative;">



                                      <!--INDICADORES GET-->

                                      <!-- <div class="row" style="width: 100% !important;margin:0 auto;position: relative;">
                                          <mat-spinner *ngIf="cargandometricas" class="centerabsolute" diameter="40" style="z-index: 99999;position: absolute !important;width: 100px !important;top:44px"></mat-spinner>
                                          <div *ngIf="cargandometricas" class="velo-inactivo"></div>
                                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="background: #f9f9f9;border-radius: 10px;padding: 5px;">

                                              <div class="row col-top" style="margin: 0 auto; width: 100%;">
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">Venta Mensual</span>
                                                          <span *ngIf="this.venta_mensual != null" (click)="carpeta_tributaria(element.company.id)" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;cursor:pointer">${{formatMoney(this.venta_mensual)}}</span>
                                                          <span *ngIf="this.venta_mensual == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;">No ingresada</span>

                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;cursor: pointer;">
                                                          <span style="display:block;font-size:12px">Dicom Empresa</span>
                                                          <span *ngIf="this.dicom != null" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span (click)="this.equifax(element.company.id, element.company.business_name, this.type_company)">${{formatMoney(this.dicom)}}</span></span>
                                                          <span *ngIf="this.dicom == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;" (click)="this.equifax(element.company.id, element.company.business_name, this.type_company)">Sin consulta</span>

                                                      </div>
                                                  </div>


                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0" *ngIf="hay_socios">
                                                      <div style="position: relative;text-align: center;cursor: pointer;" *ngFor="let soc of this.socios | slice:0:1">
                                                          <span style="display:block;font-size:12px">Socios</span>
                                                          <span *ngIf="soc.monto != null" matTooltip="{{soc.nombre}}" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;" (click)="this.equifax(soc.company_id, soc.nombre, soc.type_company)"><span (click)="this.equifax(this.id_cliente, this.bussines_name, this.type_company)">Ver detalle</span></span>
                                                          <span *ngIf="soc.monto == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;" (click)="this.equifax(this.id_cliente, this.bussines_name, this.type_company)">Sin consulta</span>

                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="min-height: 50px;padding:0" *ngIf="!hay_socios">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">Socios</span>
                                                          <span matTooltip="Socios no consultado" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span >Sin consulta</span></span>

                                                      </div>
                                                  </div>
                                              </div>


                                              <div class="row col-top" style="margin: 0 auto; width: 100%;">

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 hovermetrica" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center; cursor:pointer;">
                                                          <span style="display:block;font-size:12px">Deuda Neta</span>
                                                          <span *ngIf="this.deuda_neta != null" (click)="this.cartera_cobranza()" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span>${{formatMoney(this.deuda_neta)}}</span></span>
                                                          <span *ngIf="this.deuda_neta == null" (click)="this.cartera_cobranza()" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span>Sin consulta</span></span>
                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">Monto Total Facturas</span>
                                                          <span *ngIf="this.deuda_cartera != null" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span>${{formatMoney(this.deuda_cartera)}}</span></span>
                                                          <span *ngIf="this.deuda_cartera == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span>Sin consulta</span></span>

                                                      </div>
                                                  </div>

                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="min-height: 50px;padding:0">
                                                      <div style="position: relative;text-align: center;">
                                                          <span style="display:block;font-size:12px">SBIF</span>
                                                          <span *ngIf="this.sbif != null" class="centerabsolute jotaman2" style="font-size: 17px;font-weight: 600;top: 20px;"><span>${{formatMoney(this.sbif)}}</span></span>
                                                          <span *ngIf="this.sbif == null" class="centerabsolute jotaman2" style="font-size: 13px;font-weight: 600;top: 20px;color: #c7c7c7;"><span>Sin Consulta</span></span>

                                                      </div>
                                                  </div>

                                              </div>

                                          </div>
                                      </div> -->

                                      <!--END INDICADORES-->

                                      <!-- <div class="row"  *ngIf="element.deudores.length>0" style="width: 100% !important;margin:0 auto; margin-top:20px;">

                                          <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9" style="margin:0 auto;">
                                              <h1 class="titulodetalle">
                                                  <div class="titulodetail">Deudores vinculados</div>
                                              </h1>

                                              <table style="width: 100%; margin-left:20px;">
                                                  <tr>

                                                      <th style="text-align: left;color:#312c85;"><input value="Deudor" readonly style="width: 100%;background:none;border:none;
                                                          text-align:center"></th>
                                                      <th style="text-align: center;color:#312c85;"><input value="Informacion" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>
                                                      <th style="text-align: center;color:#312c85;"><input value="Portal" readonly style="width: 100%;background:none;border:none;text-align: center;"></th>

                                                  </tr>
                                                  <tr *ngFor="let d of element.deudores; let j=index">

                                                      <td style="text-align: left;" class="nombredeudor" matTooltip="{{d.business_name}}">
                                                          <i class="fas fa-minus-circle" style="cursor: pointer;color:#ada6a6; width:20%" matTooltip="Eliminar" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger"></i>
                                                          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                                                              <mat-card style="max-width: 500px;">
                                                                  <mat-card-content style="text-align: center;">
                                                                      ¿Desea remover este deudor de la operación?
                                                                      <br>
                                                                      <button type="button" (click)="eliminar(element.id,d.id,i)" style="width: 130px !important; max-width: 250px !important; margin-top: 10px !important;background: #dbdbdb !important;
                                    color: black;" mat-flat-button color="primary" class="btn-f-width btn-separated btn-success-in">Remover</button>


                                                                  </mat-card-content>
                                                              </mat-card>
                                                          </mde-popover>
                                                          <input type="text" value="{{d.business_name}}" style="width:80%" class="no-input">

                                                      </td>

                                                      <td style="text-align: center;">
                                                          <i class="fas fa-chart-line" style="color:#ada6a6;" [mdePopoverTriggerFor]="indice" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger"></i>


                                                          <mde-popover #indice="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                                                              <mat-card style="max-width: 500px;padding:0px" class="box-history ">

                                                                  <div class="inner-box-history" style="padding: 1%;">

                                                                      <div class="row row-year">
                                                                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-year" style="font-size: 12px;">
                                                                              Datos Financieros
                                                                          </div>
                                                                      </div>

                                                                      <div class="row" style="max-width: 100%; padding-top: 5px;">
                                                                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 inner-box-col-text" style="font-size: 13px;">
                                                                              SBIF
                                                                          </div>
                                                                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 inner-box-col-number" style="font-size: 13px;">
                                                                              <input *ngIf="d.sbif != null" readonly value="${{formatMoney(d.sbif)}}" style="border:none;background: none;width: 100%;">
                                                                              <input *ngIf="d.sbif == null" readonly value="No consultado" style="border:none;background: none;width: 100%;">
                                                                          </div>
                                                                      </div>

                                                                      <div class="row" style="max-width: 100%; padding-top: 5px;">
                                                                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 inner-box-col-text" style="font-size: 13px;">
                                                                              Dicom
                                                                          </div>
                                                                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 inner-box-col-number" style="font-size: 13px;">
                                                                              <input *ngIf="d.dicom!= null" readonly value="${{formatMoney(d.dicom)}}" style="border:none;background: none;width: 100%;">
                                                                              <input *ngIf="d.dicom== null" readonly value="No consultado" style="border:none;background: none;width: 100%;">
                                                                          </div>
                                                                      </div>

                                                                      <div class="row" style="max-width: 100%; padding-top: 5px;">
                                                                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 inner-box-col-text" style="font-size: 13px;">
                                                                              Avaluos
                                                                          </div>
                                                                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 inner-box-col-number" style="font-size: 13px;">
                                                                              <input *ngIf="d.avaluos != null" readonly value="${{formatMoney(d.avaluos)}}" style="border:none;background: none;width: 100%;">
                                                                              <input *ngIf="d.avaluos == null" readonly value="No consultado" style="border:none;background: none;width: 100%;">
                                                                          </div>
                                                                      </div>

                                                                      <div class="row" style="max-width: 100%; padding-top: 5px;">
                                                                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 inner-box-col-text" style="font-size: 13px;">
                                                                              Tamaño
                                                                          </div>
                                                                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 inner-box-col-number" style="font-size: 13px;">
                                                                              <input *ngIf="d.tamano != null" readonly value="{{d.tamano}}" style="border:none;background: none;width: 100%;">
                                                                              <input *ngIf="d.tamano == null" readonly value="No consultado" style="border:none;background: none;width: 100%;">
                                                                          </div>
                                                                      </div>




                                                                      <br>

                                                                  </div>



                                                              </mat-card>
                                                          </mde-popover>
                                                      </td>

                                                      <td style="text-align:center; max-width: 88px;">
                                                          <i matTooltip="Perfil deudor" style="cursor:pointer;color:#ada6a6;" class="fas fa-address-card" (click)="deudor_profile(d.id)"></i>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </div>
                                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                              <div class="row">
                                                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 30px;">


                                                      <img (click)="ver_observaciones(element.id)" src="../../../../assets/img/ver_observaciones.png" style="width: 100%;cursor: pointer;">

                                                  </div>

                                              </div>
                                          </div>


                                      </div> -->
                                  </div>

                                  <!-- hasta aqui -->

                              </div>
                          </div>
                          
                      </div>
                      <mat-paginator [length]="length"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions"
                        (page)="pageCustom($event)">
                        </mat-paginator>
                  </div>




              </div>

              <div class="sinRegistros" style="text-align: center;"  *ngIf="!this.has_operaciones">
                <small class="small-sinregistros">
                  <div style="text-align: center;"><span style="font-size: 30px;" class="material-icons">info</span></div>
                  ¿No encontraste la información que buscabas?
                  Contáctanos al <strong>+56 9 3467 7786</strong> y gestionaremos tu requerimiento a la brevedad.
                  </small>
              </div>

              
          </div>


      </div>
  </div>
</div>