<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!hasInfo" >
                <div class="box_placeholder animate__animated animate__fadeIn msg-empty">
                    <i class="material-icons ball"> chat </i>
                    <div class="mt-20">
                        No hay documentos asociados.
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="hasInfo">
                <div class="table-responsive-simpli">
                    <table mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="periodo">
                            <th mat-header-cell *matHeaderCellDef >Periodo</th>
                            <td mat-cell *matCellDef="let element" >
                                <input style="text-transform: capitalize;" class="no-input" value="{{ element.fecha | date : 'MMMM yyyy' }}" readonly>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="facEmitidas">
                            <th mat-header-cell *matHeaderCellDef >Ventas</th>
                            <td mat-cell *matCellDef="let element" >
                                <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.facEmitidas) }}" matTooltip="Ver Detalle" (click)="verDetalles('ventas', element.dialogDate, element.facEmitidas)" readonly>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="ventas">
                            <th mat-header-cell *matHeaderCellDef >Ventas - NC</th>
                            <td mat-cell *matCellDef="let element" >
                                <input class="no-input" value="$ {{ formatMoney(element.ventas) }}" readonly>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="compras">
                            <th mat-header-cell *matHeaderCellDef >Compras</th>
                            <td mat-cell *matCellDef="let element" >
                                <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.compras) }}"matTooltip="Ver Detalle" (click)="verDetalles('compras', element.dialogDate, element.compras)" readonly>
                            </td>
                        </ng-container>
        
        
                        <ng-container matColumnDef="notasCredito">
                            <th mat-header-cell *matHeaderCellDef >Notas de Creditos</th>
                            <td mat-cell *matCellDef="let element" >
                                <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.notasCredito) }}" matTooltip="Ver Detalle" (click)="verDetalles('notascredito', element.dialogDate, element.notasCredito)" readonly>
                            </td>
                        </ng-container>
    
                        <ng-container matColumnDef="ventaEfectiva">
                            <th mat-header-cell *matHeaderCellDef >Margen de Venta</th>
                            <td mat-cell *matCellDef="let element" >
                                <input class="no-input" value="$ {{ formatMoney(element.ventaEfectiva) }}" readonly>
                            </td>
                        </ng-container>
    
                        <ng-container matColumnDef="ventasExportacion">
                            <th mat-header-cell *matHeaderCellDef >Ventas Exportación</th>
                            <td mat-cell *matCellDef="let element" >
                                <input class="no-input cursor-pointer" value="$ {{ formatMoney(element.ventasExportacion) }}" matTooltip="Ver Detalle" (click)="verDetalles('ventasExportacion', element.dialogDate, element.ventasExportacion)" readonly>
                            </td>
                        </ng-container>        
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="text-center"></tr>
                        <tr mat-row *matRowDef="let row; let index = index, columns: displayedColumns" class="text-center"></tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>


