<mat-card class="add_factoring">
  <mat-card-title>Facturas de venta</mat-card-title>
  <div class="row m-0">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-0">
      <mat-card-subtitle>Facturas de venta en operación de factoring</mat-card-subtitle>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-0 icon-add" *ngIf="done">
      <span>Agregar facturas</span>
      <button mat-icon-button (click)="open_ventas()" [disabled]="cargando">
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content>
    <div class="row w-100" style="position: relative">

      <div class="velo-inactivo animate__animated animate__fadeIn" *ngIf="cargando">
        <mat-spinner class="spinner-arrayan-general" diameter="40"></mat-spinner>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <form [formGroup]="valForm">
          <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input formControlName="filter" matInput (change)="filtrar()" #input>
          </mat-form-field>
        </form>
      </div>
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" >
        <table mat-table [dataSource]="dataSourceVentas" class="w-100">

          <ng-container matColumnDef="folio">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Folio </th>
            <td mat-cell *matCellDef="let element; let i=index;" matTooltip="{{element.folio}}" style="min-width: 130px;text-align: center;position: relative;"> 
                <span 
                  *ngIf="is_not_done" matTooltip="Eliminar" [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger">
                  <i class="fa-light fa-minus-circle deleteicon" ></i>
                </span>
              
                <input class="no-input"  value="{{element.folio}}" style="text-align: center;" readonly> 

                <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="true">
                  <mat-card>
                    <mat-card-content>
                      <small>¿Eliminar esta factura?</small><br>

                      <button  type="button" (click)="eliminar_venta(simulation_id, element.id, i)" style="margin-top: 10px;">Eliminar</button>

                    </mat-card-content>
                  </mat-card>
                </mde-popover>
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
      
          <ng-container matColumnDef="receptoracreedor_name">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Deudor </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.receptoracreedor_name}}" style="min-width: 130px;"> 
                  <input class="no-input"  value="{{element.receptoracreedor_name}}" style="text-align: left;" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="receptoracreedor_rut">
            <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Rut </th>
            <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                <input class="no-input"  value="{{element.receptoracreedor_rut}}" readonly> 
            </td>
            <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="mnt_total">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Monto </th>
                <td mat-cell *matCellDef="let element" style="min-width: 130px;"> 
                  <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>
    
          <ng-container matColumnDef="fecha_emision">
              <th mat-header-cell *matHeaderCellDef style="min-width: 130px;"> Fecha Emisión </th>
              <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
              <input   class="no-input" value="{{element.fecha_emision}}" readonly> 
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>

          <ng-container matColumnDef="fecha_vencimiento">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Fecha vencimiento</th>
            <td mat-cell *matCellDef="let element; let i=index, let index=index" style="min-width: 130px;">

              <input   class="no-input" value="{{element.fecha_vencimiento}}" readonly> 
        
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
          </ng-container>


    
          <tr mat-header-row *matHeaderRowDef="displayedColumnsVentas; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; let element; columns: displayedColumnsVentas"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsVentas"></tr>

        </table>
        <mat-paginator #paginator_ventas [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageCustom($event)"></mat-paginator>
        <span class="span-leyenda"></span>
        <!-- <button  *ngIf="is_not_done" mat-flat-button class="primary-button"  type="button"  (click)="open_ventas()">Agregar Facturas</button> -->

      </div>
    </div>
  </mat-card-content>
</mat-card>