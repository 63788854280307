<section class="area-login animate__animated animate__fadeIn" [ngStyle]="{ 'background-image': 'url(' + randomImage + ')' }">
  <mat-card class="login" style="position: relative;">
    
    <mat-card-content>

        <div class="simpli-floating-box-header">
          <div class="simpli-floating-box-logo text-center">
            <img src="../../assets/simpli/simplilogo2.svg" height="90px">
          </div>
      
          <div class="simpli-floating-box-title text-center">
            <span class="color-tertiary">
              Ingresa y haz todo más <span class="simpli-color1"> Simpli </span>
            </span>
          </div>
      
        </div>
      
        <div >
          <form [formGroup]="valForm" (submit)="submitForm($event, valForm.value)" class="form-login animate__animated animate__fadeIn">
      
            <mat-form-field appearance="fill" color="primary">
              <mat-label>Correo electrónico</mat-label>
              <input formControlName="username" matInput type="email" required>
              <mat-hint>Ingresa tu correo electrónico</mat-hint>
              <mat-error *ngIf="valForm.controls['username'].hasError('required')">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="valForm.controls['username'].hasError('email')">
                Debe ser un correo electrónico valido
              </mat-error>
              <mat-error *ngIf="this.valForm.controls['username'].hasError('invalid')">
                El correo electrónico ingresado no está registrado
              </mat-error>
              <mat-icon matSuffix class="simpli-color-gris">email</mat-icon>
            </mat-form-field>
        
            <mat-form-field appearance="fill" color="primary" style="margin-top: 20px;">
              <mat-label>Contraseña</mat-label>
              <input formControlName="password" matInput type="password" [type]="hide ? 'password' : 'text'" required>
              <button matSuffix mat-icon-button type="button" (click)="hide = !hide" [attr.aria-label]="'Ocultar password'"
                [attr.aria-pressed]="hide">
                <mat-icon class="simpli-color-gris">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-hint>Ingresa tu contraseña</mat-hint>
              <mat-error *ngIf="valForm.controls['password'].hasError('required')">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-error *ngIf="this.valForm.controls['password'].hasError('invalid')">
                La contraseña ingresada no es válida
              </mat-error>
            </mat-form-field>
            
            
            <div class="btn-container">
              <button class="primary-button" [disabled]="showSpinner" mat-flat-button type="submit" color="primary" style="margin-right: 0px;">
                <mat-label *ngIf="showSpinner == false">Ingresar</mat-label>
                <span *ngIf="showSpinner == true"><i class="fa-solid fa-spinner fa-spin-pulse" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
              </button>
            </div>
        

            <div class="center-div">
              <a mat-button class="label-button" routerLink="/forgot-password">¿Olvidaste tu contraseña?</a>
              <div class="color-tertiary">o</div>
              <a mat-button class="label-button" [routerLink]="['/registro-empresa']">Regístrate aquí</a>
            </div>
        
          </form>
        </div>
      
    </mat-card-content>
  </mat-card>
</section>