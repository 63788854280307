import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


declare var $: any;

@Component({
  selector: 'app-cesion-asistida',
  templateUrl: './cesion-asistida.html',
  styleUrls: ['./cesion-asistida.less']
})
export class CesionAsistida implements OnInit {

  loading: boolean = false;
  successConfirmCesionAsistida: boolean = false;
  companyWithCesionAsistida: boolean = false;
  show_invalid_token: boolean = false;
  auth_code: string = "";
  company_id: Number | null = null;
  sub: any;


  errors_token: IErrorsToken = {
    expired_token: () => this.show_expired = true,
    invalid_token: () => this.show_invalid_token = true,
    company_with_cesion_asistida: () => this.companyWithCesionAsistida = true
  }


  constructor(private route: ActivatedRoute, public snackBar: MatSnackBar, public settings: SettingsService, private router: Router, public dialog: MatDialog, private change: ChangeDetectorRef) {



    this.sub = this.route.params.subscribe((params: any) => {
      this.company_id = params['id'];
    });

    this.route.queryParams.subscribe((params: any) => {
      this.auth_code = params['auth_code'];
    })

  }

  ngOnInit() {
    this.verificar_codigo()
    this.change.detectChanges();
  }



  show_expired: boolean = true;
  async verificar_codigo() {
    console.log('alodate')
    try {
      this.loading = true
      const bd: any = await this.settings.get_query(1, `/tubo/cliente/${this.company_id}/validate-cesion-asistida/?auth_code=${this.auth_code}`, {}, true);
      var body_data: any = bd._body;
      var data: ITokenValidationResp = body_data.success;
      const self = this
      this.loading = false
      switch (bd.status) {
        case 200:
          if (data.ok) {
            self.successConfirmCesionAsistida = true
          }
          if (!data.ok) {
            self.errors_token[data.err_code]()
            return
          }
          break;

        case 400:
          console.log(bd)
          break;
        default:

          break;
      }
    } catch (err: any) {
      if (err.error.status == 400) {
        this.errors_token[err.error.error.success.err_code]();
      }
      this.loading = false;
      throw new Error(err)
    }
  }


  async solicitar_enlace() {


    try {


      const bd: any = await this.settings.get_query(1, `/tubo/cliente/${this.company_id}/renew-cesion-asistida-token/?auth_code=${this.auth_code}`,{},true);
      var body_data: any = bd._body;

      switch (bd.status) {
        case 200:
          //hacer acción
          this.snackBar.open('Enviamos un correo de enlace a tu correo.', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack-success'
          });
          break;
        default:

          break;
      }
    } catch (bd: any) {
      //manejar error
    }

  }
}




interface ITokenValidationResp {
  err_code: string
  error: string
  message: string
  ok: boolean
}


type IErrorsToken = {
  [key: string]: () => void;
};
