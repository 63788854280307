<div style="position: relative;">

    <div class="velo-inactivo animate__animated animate__fadeIn" style="background: #ffffffde;height: 430px;" *ngIf="showSpinner">
  
        <mat-spinner diameter="40" class="spinner-simpli" style="margin-top: 200px;"></mat-spinner>
    
      </div>


    <div *ngIf="!noData && !showSpinner">


        <table mat-table [dataSource]="dataSource" style="width: 100%;" *ngIf="!noData">

            <ng-container matColumnDef="folio_doc">
                <th mat-header-cell *matHeaderCellDef>Docto</th>
                <td mat-cell *matCellDef="let element">{{ element.folio_doc }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="nombre_cedente">
                <th mat-header-cell *matHeaderCellDef>Cliente</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.nombre_cedente }}">
                    <input style="width: 100%" class="no-input" value="{{ element.nombre_cedente | slice: 0:18 }}" readonly>
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: 600; color: black">Total</td>
            </ng-container>

            <ng-container matColumnDef="rut_cedente">
                <th mat-header-cell *matHeaderCellDef>Rut Cedente</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.rut_cedente }}">
                    <input style="width: 100%" class="no-input" value="{{ element.rut_cedente }}" readonly>
                </td>

                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <ng-container matColumnDef="monto_total">
                <th mat-header-cell *matHeaderCellDef>Monto Cesión</th>
                <td mat-cell *matCellDef="let element" matTooltip="${{ formatMoney(element.monto_total) }}">
                    <input style="width: 100%" class="no-input" value="$ {{ formatMoney(element.monto_total) }}" readonly>
                </td>
                <td mat-footer-cell *matFooterCellDef style="color: black; font-weight: 600;text-align: center;">
                    $ {{ formatMoney(totalDeudaNeta) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="factoring_name">
                <th mat-header-cell *matHeaderCellDef>Factoring</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.factoring_name }}">
                    <input style="width: 100%" class="no-input" value="{{ element.factoring_name | slice: 0:18  }}" readonly>
                </td>

                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rut_factoring">
                <th mat-header-cell *matHeaderCellDef>Factoring</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.rut_factoring }}">
                    <input style="width: 100%" class="no-input" value="{{ element.rut_factoring  }}" readonly>
                </td>

                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rut_deudor">
                <th mat-header-cell *matHeaderCellDef>Rut Deudor</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.rut_deudor }}">
                    <input style="width: 100%" class="no-input" value="{{ element.rut_deudor  }}" readonly>
                </td>

                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="deudor">
                <th mat-header-cell *matHeaderCellDef>Deudor</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.deudor }}">
                    <input style="width: 100%" class="no-input" value="{{ element.deudor  }}" readonly>
                </td>

                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="f_emision_dte">
                <th mat-header-cell *matHeaderCellDef>Fecha Emisión</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.f_emision_dte }}">
                    <input style="width: 100%" class="no-input" value="{{ element.f_emision_dte}}" readonly>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="fecha_vence">
                <th mat-header-cell *matHeaderCellDef>Fecha Vencimiento</th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.fecha_vence }}">
                    <input style="width: 100%" class="no-input" value="{{ element.fecha_vence}}" readonly>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>


    </div>
    <div *ngIf="noData" class="box_placeholder" style="margin: 7% 0; cursor: pointer; width: 100%">
        <i class="material-icons ball"> assessment </i>
        <div style="margin-top: -20px">No existen detalles</div>
    </div>
    <mat-paginator style="min-width: 100% !important" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
</div>