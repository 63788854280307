<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" style="position: relative;margin-top: 20px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <h1 class="titulo-simpli" *ngIf="set_vencimiento == 0">Asigna el tipo de vencimiento </h1>
    <h1 class="titulo-simpli" *ngIf="set_vencimiento == 2">Asignar cantidad de días de vencimiento </h1>
    <h1 class="titulo-simpli" *ngIf="set_vencimiento == 1">Asignar fecha de vencimiento </h1>
  </div>
</div>

<mat-dialog-content>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">


            <!--STEPPER CONTROL-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-responsive" style="margin-top: 40px;display: none;">

              <div class="container-stepper-simpli">

                <div id="step-simpli1" class="stepper-simpli stepper-simpli-active" (click)="change_step('step-simpli1')">
                  <i class="fa-regular fa-pencil"></i>
                  <div class="step-text">Vencimiento</div>
                </div>
                
              </div>

            </div>


            <div *ngIf="step == 1 && set_vencimiento == 0 " class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

              <div style="text-align: center;margin-top: 60px;">
                <mat-label class="l-i">Monto total:</mat-label>
                <mat-label class="l-d" style="position: relative;">$ {{formatMoney(monto_total)}}</mat-label>
              </div>

            </div>

            <div *ngIf="step == 1 && set_vencimiento == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
              <form  [formGroup]="valFormFilter" style="margin-top: 30px;">


                <mat-form-field appearance="fill">
                  <mat-label>Tipo de vencimiento</mat-label>
                  <mat-select formControlName="tipo_vencimiento" (selectionChange)="statuschange($event)">

                    <!--fecha de vencimiento, muchas facturas-->
                    <mat-option *ngIf="!single_factura" value="1">Asignar misma fecha de vencimiento</mat-option>
                    <mat-option *ngIf="!single_factura" value="3">Asignar misma cantidad de días</mat-option>

                    <mat-option *ngIf="!single_factura" value="2">Asignar distintas fechas de vencimiento</mat-option>
                    <mat-option *ngIf="!single_factura" value="4">Asignar cantidad de días por factura</mat-option>


                    <!--opciones de 1 sola factura-->
                    <mat-option value="6" *ngIf="single_factura">Asignar fecha de vencimiento</mat-option>
                    <mat-option value="5" *ngIf="single_factura">Asignar días de vencimiento</mat-option>


                  </mat-select>
                  <mat-hint>Selecciona el tipo de vencimiento para tus facturas</mat-hint>
                  <mat-error *ngIf="valFormFilter.controls['tipo_vencimiento'].hasError('required')">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                </mat-form-field>

                <!--6-->
                <mat-form-field appearance="fill"  (click)="picker.open()" *ngIf="(selected == 6 || selected == 1) && single_factura == true || (selected == 6 || selected == 1) && single_factura == false">
                  <mat-label>Fecha de vencimiento</mat-label>
                  <input type="text" [min]="minDate" matInput [matDatepicker]="picker" (dateChange)="fecha_todas($event)" formControlName="fecha_vencimiento_utc">
                  <mat-hint>Asigna la fecha de vencimiento para tus facturas  </mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="valFormFilter.controls['fecha_vencimiento_utc'].hasError('invalid')">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                </mat-form-field>

                <!--5-->
                <mat-form-field appearance="fill" *ngIf="(selected == 5 || selected == 3) && single_factura == true || (selected == 5 || selected == 3) && single_factura == false">
                  <mat-label>Cantidad de días</mat-label>
                  <input matInput min="1" type="number" formControlName="dias_vencimiento" (change)="capturar_fecha_por_dias($event)">
                  <mat-hint>Asigna la cantidad de días de vencimiento</mat-hint>
                  <mat-error  *ngIf="valFormFilter.controls['dias_vencimiento'].hasError('invalid')">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                </mat-form-field>
            
            

            
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                    <div class="btn-simpli-container">
                      <button [disabled]="loading" class="primary-button" style="margin-right: 0px;" mat-flat-button (click)="submitForm($event, valFormFilter.value)">
                        <span *ngIf="loading == false">Continuar</span>
                        <span *ngIf="loading == true"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
                      </button>
                    </div>
                    
                  </div>
                </div>
            
            
              </form>
            </div>

            <div style="display: none;" *ngIf="set_vencimiento == 1 || set_vencimiento == 2" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

              <div style="text-align: center;">
                <h1 *ngIf="set_vencimiento == 1" class="title-step2">Asigna una fecha para cada factura</h1>
                <h1 *ngIf="set_vencimiento == 2" class="title-step2">Asigna una cantidad de días para cada factura</h1>
                <mat-label class="l-i">Monto total:</mat-label>
                <mat-label class="l-d" style="position: relative;">$ 1.600.000</mat-label>
              </div>

            </div>

            <div *ngIf="set_vencimiento == 1" class="col-lg-11 col-md-11 col-sm-11 col-xs-12 col-top animate__animated animate__fadeIn" style="margin: 0 auto;">

              <div class="scroll-container add_factoring">
                <div class="container-facturas" *ngFor="let i of facturas; let index=index">
                  <div class=row>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="row divrow">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="N° Factura:"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.folio}}"></div>
                      </div>

                      <div class="row divrow">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Deudor:"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.deudor}}"></div>
                      </div>

                      <div class="row divrow">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Monto:"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="${{formatMoney(i.monto)}}"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="text-align: center;margin-top: 5px;">

                      <div class="container-vencimiento l-vencimiento" style="text-align: justify;">Asigna la fecha de vencimiento</div>

                      <div [ngClass]="{'container-vencimiento':true}">
                        
                          <mat-form-field appearance="fill"  (click)="picker.open()" style="width: 100%;">
                            <mat-label>Fecha de vencimiento</mat-label>
                            <input type="text" readonly matInput [matDatepicker]="picker" [min]="minDate" (dateChange)="fecha_individual($event, index)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>

                      </div>

                    </div>

                  </div>

                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          
                  <div class="btn-simpli-container">
                    <button [disabled]="wait_to_send" class="primary-button" mat-flat-button (click)="validate_date()">
                      Finalizar
                    </button>
                  </div>
                  
                </div>
              </div>

            </div>

            <div *ngIf="set_vencimiento == 2" class="col-lg-11 col-md-11 col-sm-11 col-xs-12 col-top animate__animated animate__fadeIn" style="margin: 0 auto;">

              <div class="scroll-container add_factoring">
                <div class="container-facturas" *ngFor="let i of facturas; let index=index">
                  <div class=row>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="row divrow">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="N° Factura:"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.folio}}"></div>
                      </div>

                      <div class="row divrow">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Deudor:"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.deudor}}"></div>
                      </div>

                      <div class="row divrow">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Monto:"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="${{formatMoney(i.monto)}}"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="text-align: center;margin-top: 5px;">

                      <div class="container-vencimiento l-vencimiento" style="text-align: justify;width: 90%;">Asigna la cantidad de días de vencimiento</div>

                      <div style="width: 90%;" [ngClass]="{'container-vencimiento':true}">
                        

                          <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Cantidad de días</mat-label>
                            <input matInput min="1" type="number" (change)="dias_individual($event, index)">
                          </mat-form-field>

                      </div>

                    </div>

                  </div>

                </div>
              </div>



              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          
                  <div class="btn-simpli-container">
                    <button [disabled]="wait_to_send" class="primary-button" mat-flat-button (click)="validate_date()">
                      Finalizar
                    </button>
                  </div>
                  
                </div>
              </div>

            </div>



  </div>

</mat-dialog-content>