import { Component, OnInit } from '@angular/core';
import { CuentasBancarias } from './ctas';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;


@Component({
    selector: 'dialog-result-example-dialog',
    template: `
        <h1 mat-dialog-title>Eliminar Cuenta</h1>
        <div mat-dialog-content>¿Estas seguro que deseas eliminar la cuenta?</div>
        <div mat-dialog-actions>
          <button mat-button (click)="delete_add()" [disabled]="disabled">Si</button>
          <button mat-button (click)="dialogRef.close('N')">No</button>
          <mat-spinner class="spinner-arrayan-general" *ngIf="showSpinner" diameter="40" style="float: right; margin-right: 0px;margin-top: 0px;margin-left: 10px;"></mat-spinner>
        </div>
    `,
})
export class CompanyDeleteAccDialog  {
    id_acc: any;
    public info!: CuentasBancarias;
    showSpinner:boolean = false;
    disabled:boolean = false;

    constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyDeleteAccDialog>, public dialog: MatDialog, 
         public settings: SettingsService) { }

    async delete_add() {
        try {
          const bd: any = await this.settings.get_query(4, 'account/'+this.id_acc+'/');
          var body_data: any =bd._body;
          switch (bd.status) {
            case 200:
    
            this.showSpinner = false;
            this.disabled = false;
            let sb = this.snackBar.open("¡Dirección eliminada!", "Aceptar", {
                duration: 3000,
                panelClass: ['snackbar-out-main-success']
            });
            this.info.reloadAccounts();
            this.dialogRef.close('Y');
    
    
              break;
            default:
    
              break;
          }
        } catch (bd: any) {
    
        }
      }
}
