import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { ResumenAbonosComponent } from '../resumen-abonos/resumen-abonos';
import { DetalleObs } from '../../detalle-obs/detalle-obs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-dialogo-resumen-facturas',
  templateUrl: './dialogo-resumen-facturas.html',
  styleUrls: ['./dialogo-resumen-facturas.less'],
})

export class DialogoResumenFacturas implements OnInit {

  token:any=localStorage.getItem('token');
  role:any=localStorage.getItem('role');;
  valForm:FormGroup;

  @Input() id_factura:any="";
  @Input() docto:any="";
  
  constructor(
              private fb: FormBuilder, 
              public snackBar: MatSnackBar, 
              public settings: SettingsService, 
              public dialog: MatDialog,public router:Router, public dialogRef:MatDialogRef<DialogoResumenFacturas>){

    this.role = localStorage.getItem('role');
    this.token = localStorage.getItem('token');

    this.valForm = this.fb.group({
      'filter': new FormControl(''),
    });



   }

  is_responsive:boolean=false;
  ngOnInit(): void {

    this.get_detail();
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }

  go_to_detail(){
    this.dialogRef.close();
    this.router.navigate(['home/resumen-facturas', this.id_factura]);
  }


  cliente:any="Cargando cliente...";
  estado:any="";
  obsevacion:any="";
  fecha_giro:any="";
  deudor:any="";
  rut_deudor:any="";
  fecha_otorgamiento:any="";
  fecha_vencimiento:any="";
  fecha_curse_operacion:any="";
  total_monto_pagado:any="";
  fecha_pago:any="";
  diaz_plazo:any="";
  dias_mora:any="";
  total_mora_pendiente:any="";
  mora:any="";
  excedentes:any="";
  cant_abonos:any="";
  total_abonos:any="";
  excedentes_utilizados:any="";
  excedentes_disponibles:any="";
  hay_abonos:boolean=false;
  total_monto_financiado:any="";
  abonos:any[]=[];
  obs_plana:any=null;
  async get_detail(pdf?:boolean) {

    var endpoint =`/cliente/cartola-operacion-factura-detalle/${this.id_factura}/`;

    if(pdf == true){
      endpoint = `/cliente/cartola-operacion-factura-detalle/${this.id_factura}/?pdf=1`
    }

    try {

      const bd: any = await this.settings.get_query(1, endpoint);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        console.log("aca", body_data)


        if(pdf == true){
          window.open(body_data.success.info, "blank");
        }else{
          let data = body_data.success.info;
          this.estado = data.estado;
          this.cliente = data.cliente;
          this.obsevacion = data.observacion;
          this.obs_plana = this.stripHtml(data.observacion)
          this.fecha_giro = data.fecha_giro;
          this.deudor = data.deudor;
          this.rut_deudor = data.rut_deudor;
          this.fecha_otorgamiento = data.fecha_otorgamiento;
          this.fecha_vencimiento = data.fecha_vencimiento;
          this.fecha_curse_operacion = data.fecha_curse_operacion;
          this.total_monto_pagado = data.total_monto_pagado;
          this.fecha_pago = data.fecha_pago;
          this.diaz_plazo = data.diaz_plazo;
          this.dias_mora = data.dias_mora;
          this.total_mora_pendiente = data.total_mora_pendiente;
          this.mora = data.mora;
          this.excedentes = data.excedentes;
          this.cant_abonos = data.cant_abonos;
          this.total_abonos = data.total_abonos;
          this.excedentes_utilizados = data.excedentes_utilizados;
          this.excedentes_disponibles = data.excedentes_disponibles;
          this.total_monto_financiado = data.total_monto_financiado;

          this.abonos = data.abonos;

          if(data.abonos.length > 0){
            this.hay_abonos = true;
          }else{
            this.hay_abonos = false;
          }
        }




          
          break;
        default:

          break;
      }
    } catch (bd: any) {

      this.settings.manage_errors(bd.status, "detalle-cartola", bd, false);
    }
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  open_abonos() {
    const dialogRef = this.dialog.open(ResumenAbonosComponent,{
      width: '800px',
      autoFocus: false,
    });

    dialogRef.componentInstance.facturas = this.abonos;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  open_detail_obs(){


    if(this.is_responsive == true){
      const dialogRef = this.dialog.open(DetalleObs,{
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        autoFocus: false,
        disableClose: false,
      });
  
      dialogRef.componentInstance.obsevacion = this.obsevacion;
      dialogRef.afterClosed().subscribe(result => {
  
      });
    }else{
      const dialogRef = this.dialog.open(DetalleObs,{
        width: '1250px',
        autoFocus: false,
      });
  
      dialogRef.componentInstance.obsevacion = this.obsevacion;
      dialogRef.afterClosed().subscribe(result => {
  
      });
    }

  }

  stripHtml(html:any)
  {
     let tmp = document.createElement("DIV");
     tmp.innerHTML = html;
     return tmp.textContent || tmp.innerText || "";
  }

  


}
