import { Component, Inject, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SettingsService } from "src/app/settings/settings.service";
declare var $ :any;

@Component({
  selector: "app-dicom",
  templateUrl: "./dicom.component.html",
  styleUrls: ["./dicom.component.less"],
})
export class DicomComponent implements OnInit {
  showSpinner:boolean=true;
  public noData:any;
  public dicomFiles:any [] = [];
  dataSource = new MatTableDataSource([]);
  private error:any;
  public diasRestantes = 0;
  public isError: boolean = false;
  public diasFaltantes: number = 0;
  public errorMessage: string = "";
  show_spinner_loading:boolean=true;

  public displayedColumns = ["file", "dateCreated", "actions"];

  constructor(
    private settingsService: SettingsService,
  ) {}

  loading:boolean=false;
  ngOnInit(): void {
    this.getDicomFiles();
    this.load_all()
  }

  async getDicomFiles() {
    this.showSpinner = true;
    this.id_company=localStorage.getItem('company_id');
    this.loading = true;
    this.dataSource.data = []
    
    try {
      const body_data: any = await this.settingsService.get_query(
        1,
        `equifaxarchivos/${this.id_company}/`
      );
      if (body_data.status === 200) {
        const _body = body_data._body;
        this.dicomFiles = _body.archivos;
        this.diasRestantes = _body.dias_restantes;
        if (this.dicomFiles.length > 0) {
          this.noData = false;
          this.addFilesData(this.dicomFiles);
        } else {
          this.showSpinner = false;
          this.noData = true;
        }
      }
      this.showSpinner = false;
    } catch (error) {
      this.error;
      this.showSpinner = false;
      this.loading = false;
    }
  }

  addFilesData(files:any) {
    const newFiles = files.map((f:any) => {
      const date = f.date_created.split(" ")[0];
      const splitName = f.link.split("/")[2];
      const name = splitName.split(".")[0];
      return {
        ...f,
        name,
        date: date,
        link: `${f.link}`,
      };
    });
    this.dataSource = new MatTableDataSource(newFiles);
    this.loading = false;
  }

  
  id_company:any;
  company_id:any;
  load_all(){
    this.id_company = localStorage.getItem('company_id')
    this.company_id = this.id_company;
    

  }
    

  disabled:boolean=false;
  async solicitarDicom() {
    this.id_company=localStorage.getItem('company_id');
    this.showSpinner = true;
    this.disabled = true;
    try {
      const body_data: any = await this.settingsService.get_query(
        1,
        `print-equifax/${this.id_company}/`
      );
      if (body_data.status === 200) {
        const _body = body_data._body
        const file = _body.ruta;
        window.open(`${file}`, "_blank");
        this.disabled = false;
        this.getDicomFiles();
      } else {
     
        this.disabled = false;
        this.showSpinner = false;
        const error = body_data._body
        console.log(this.error);
        this.error = error.mensaje;
        this.isError = true;
        this.diasFaltantes = error.dias_faltantes;
        this.errorMessage = `Aún no puedes consultar tu DICOM faltan ${
          29 - this.diasFaltantes
        } días.`;
        setTimeout(() => {
          this.removeErrorMensaje();
        }, 4000);
        return;
      }
      this.showSpinner = false;
    }catch (error) {
      this.disabled = false;
      this.error = error;
      this.showSpinner = false;
      this.isError = true
      this.errorMessage = "Ha ocurrido un error al intentar acceder. Intentalo de nuevo.";
              setTimeout(() => {
                this.removeErrorMensaje();
              }, 4000);
    }
    
  }

  removeErrorMensaje() {
    const message:any = document.getElementById("errorMessage");
    message.parentNode.removeChild(message);
  }

  descargar(link:any){
    window.open(link, "_blank")
  }

  contactar(){
    window.open("https://wa.me/56934677786", "_blank")
  }

  
}
