import { Component,ViewChild, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OnDestroy } from '@angular/core'
import { SelectionModel } from '@angular/cdk/collections';
import * as Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { PagoSeleccion } from '../pago-seleccion/pago-seleccion';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
declare var $ :any;


@Component({
  selector: 'app-pp-facturas-pagadas',
  templateUrl: './pp-facturas-pagadas.component.html',
  styleUrls: ['./pp-facturas-pagadas.component.less']
})
export class PPFacturasPagadasComponent implements OnInit, OnDestroy {


  @ViewChild('uploadResultPaginator', {read: MatPaginator}) uploadResultpaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('MatSort2', {read: MatSort}) sort2!: MatSort;
  firstFormGroup: FormGroup;
  ELEMENT_DATA: any[]=[];
  displayedColumns: string[] = ['business_name','conteo','monto','porcentaje'];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  len_info: number = 0;
  @ViewChild('paginator') paginator!: MatPaginator;
  formFechas: FormGroup;
  cargando:Boolean=true
  info:Boolean=false
  infocesiones:Boolean=false;
  clave:number=0;
  valForm!:FormGroup;

  @Input() company_id:any;

  valForm2:FormGroup;

  
  paymentMethod = new FormControl();

  paymentMethodList: string[] = ['Cheque', 'Vale vista', 'Transferencia', 'Aplicacion'];

  typeList: any[] = [{'value': 'fecha_emision', 'name':'Fecha de emisión'}, {'value': 'fecha_vencimiento', 'name':'Fecha de vencimiento'}, {'value': 'fechapp', 'name':' Fecha probable de pago'}];
  value_type_filter: string = "";

  constructor(public router:Router, private cd: ChangeDetectorRef,public settings: SettingsService, private fb: FormBuilder, public dialog:MatDialog,public snackBar: MatSnackBar){ 


      this.formFechas = this.fb.group({
        fechaI: ['', Validators.required],
        fechaF: ['', Validators.required]
      });
  
      this.valForm = fb.group({
        desde: '',
        hasta: '',
        filter:'',
        type: [['fecha_emision']]
      });
  
      this.firstFormGroup = this.fb.group({
        firstCtrl: ['', Validators.required],
        secondCtrl: [null, Validators.required],
        thridCtrl: [null, Validators.required],
        checkall:false,
        fecha_vencimiento2: [null, Validators.required],
      });


      this.valForm2 = this.fb.group({
        fecha_vencimiento2: [null, Validators.required],
      });

  }



  myObserver:any

  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngOnInit() {
    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all()
        
      };
     });

     this.load_all()
  }

  load_all()
  {
    this.company_id = Number(localStorage.getItem("company_id"))
    this.load_table();
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 
  //0 no, 1 si
  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];

  excel_download=0;
  ELEMENT_DATA_FACTURAS: Array<any>=[];
  displayedColumnsFacturas: string[] = ['folio','receptoracreedor__business_name','ultimoacreedor','mnt_total','fecha_emision', 'fechapagado'];
  dataSourceFacturas:any = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);
  @ViewChild('paginatorCompras') paginatorCompras!: MatPaginator;

  desde='';
  hasta='';
  filter='';
  infotable:boolean=true;

  total_seleccionado:string="Total"
  total_tabla=0;
  show_msg_acreedor_legal:boolean=false;
  async load_table() {

    this.show_msg_acreedor_legal = false;
    this.disabled = true;

    try {
      const bd: any = await this.settings.get_query(1,  'ConfirmigCompras/'+this.company_id+'/?excel=' + this.excel_download + '&pageNumber=' + this.page + '&fecha_ini=' +this.desde + '&fecha_fin=' + this.hasta + '&filter=' + this.filter + '&ordenamiento=' + this.ordenamiento + '&columna=' + this.columna+'&tipoFecha='+this.value_type_filter+'&pagadas='+true);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.ELEMENT_DATA_FACTURAS = body_data.facturas;
        this.length = body_data.lenFacturas;
        this.disabled = false;
        $("#btn_llamativo").removeClass('disable_btn_llamativo');
        let sma = 0
        if(this.arr_documentos_to_simulate.length > 0){
          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            sma = sma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = sma;
        }else{
          this.total_total = body_data.montoTotal;
          this.total_tabla = body_data.montoTotal;
        }

        if(this.ELEMENT_DATA_FACTURAS.length >0){
          this.infotable = true;
        }else{
          this.infotable = false;
        }

        let is_all = false;
        let valid_checks = 0;
        let checks_selected = 0;
        for (let i = 0; i < body_data.facturas.length; i++) {
          if (body_data.facturas[i].historialAcreedores.length > 0) {
            this.show_msg_acreedor_legal = true;
          }
          if (this.arr_simulation.includes(body_data.facturas[i].id)) {

            body_data.facturas[i]['checked'] = true
            this.selection.isSelected(body_data.facturas[i])

            checks_selected = checks_selected + 1;
            
          } else {
            body_data.facturas[i]['checked'] = false                     
          }
          if ((!body_data.facturas[i].pagado) && !(body_data.facturas[i].aceptacionReclamo == 0) && !(body_data.facturas[i].estado == 'Contado/Gratuito')) {
            valid_checks = valid_checks + 1;
          } 
        }

        if ((checks_selected == valid_checks) && (checks_selected != 0)){
          is_all = true;
        }


        this.checkall = is_all;
        this.firstFormGroup.controls['checkall'].setValue(is_all);

        this.valForm2.reset()


        setTimeout(() => {
          this.dataSourceFacturas=new MatTableDataSource(this.ELEMENT_DATA_FACTURAS);
          this.dataSourceFacturas._updateChangeSubscription();
        });


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.load_table();


  }

  columna = "";
  ordenamiento = "";
  sortData($e: any) {
    this.columna = $e.active;
    this.ordenamiento = $e.direction;
    this.load_table()

  }

  disabled:boolean=false;
  async exportar() {
    this.disabled = true;

    try {
      const bd: any = await this.settings.get_query(1, 'ConfirmigCompras//'+this.company_id+'/?excel=' + '1' + '&pageNumber=' + this.page + '&fecha_ini=' +this.desde + '&fecha_fin=' + this.hasta + '&filter=' + this.filter);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          this.disabled= false;

          window.open(body_data.nameArchivo, "blank")


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.disabled = true;
    }
  }

  async sincronizar() {
    let value = {}
    this.disabled = true;
    $("#btn_llamativo").addClass('disable_btn_llamativo');

    try {
      const bd: any = await this.settings.get_query(2, 'sii-empresa/'+this.company_id+'/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.open_input_dialog("Sincronización", "Sincronización Exitosa", 
        "¡Todo listo! Nos hemos sincronizado con el SII",
        "Hemos actualizado los ultimos 6 meses con tus libros de compras, ventas y notas de credito!","sync","#3BA46F")


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.disabled= false;
      $("#btn_llamativo").removeClass('disable_btn_llamativo');
    }
  }

  //DIALOG INPUT
  open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

  const dialogRef = this.dialog.open(InputDialog,{
    width:'700px'
  });
  dialogRef.componentInstance.title = title;
  dialogRef.componentInstance.subtitle = subtitle;
  dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
  dialogRef.componentInstance.mensajenegrita = mensajenegrita;
  dialogRef.componentInstance.icon = icon;
  dialogRef.componentInstance.coloricon = coloricon;


  dialogRef.afterClosed().subscribe((result:any) => {
    this.load_table()
  });

  }

  filter1($e: any) {

    let date = new Date($e.value);
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    let year = date.getFullYear();
    this.desde = year + '-' + mes + '-' + dia;

    if (this.hasta!="") {
      this.load_table()
    }

  }

  filter2($e: any) {
      let date = new Date($e.value);
      let mes = date.getMonth() + 1;
      let dia = date.getDate();
      let year = date.getFullYear();
      this.hasta = year + '-' + mes + '-' + dia;

    if (this.desde!="") {
      this.load_table()
    }
  }

  filtrar(){
    this.filter = this.valForm.controls['filter'].value;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    if(this.filter == null){
      this.filter = "";
    }
      this.load_table();
  }

  reset(filter:any){
    if(filter == ""){
      this.filter = "";
      this.load_table()
    }
  }

  reset_filters(){
    this.filter = "";
    this.desde = "";
    this.hasta = "";
    this.columna = "";
    this.ordenamiento = "";
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.valForm.reset()
    this.load_table()
  }

  selection = new SelectionModel<Facturas>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturas.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event:any) {
    !$event.checked  ?
    this.selection.clear() :
    this.dataSourceFacturas.filteredData.forEach((row:any) => {
      if ((!row.pagado) && !(row.aceptacionReclamo == 0) && !(row.estado == 'Contado/Gratuito')){
        this.selection.select(row)
      }
    });
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  arr_documentos_to_simulate: Array<any> = [];
  arr_simulation: Array<any> = []
  disabled_enviar:boolean=true;
  total_total=0;
  insertar_vencimiento:boolean=false;
  total_proveedores = 0;
  activa(id:any, index:any, $e: any, objeto:any) {

    if ($e) $e.stopPropagation();

        const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);


        
        if (obje === undefined) {


          this.arr_documentos_to_simulate.push(objeto);
        } else {
          this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
        }

        if (this.arr_simulation.includes(id)) {

          this.arr_simulation.splice(this.arr_simulation.indexOf(id), 1);
        } else {
          this.arr_simulation.push(id);
        }




        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.insertar_vencimiento = true;
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    

        const groupBy = (array:any, key:any) => {
          return array.reduce((result:any, currentValue:any) => {(result[currentValue[key]] = result[currentValue[key]] || []).push(
              currentValue
            );return result;
          }, {}); 
        };
        var group = groupBy(this.arr_documentos_to_simulate, 'ultimorutacreedor');
        this.total_proveedores = Object.keys(group).length;


  }

  se_activo_check:boolean=false;
  activa_final(){
    this.checkall =  this.firstFormGroup.controls['checkall'].value;

    if(this.checkall == false){


        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
          if ((!this.dataSourceFacturas.filteredData[i].pagado) && !(this.dataSourceFacturas.filteredData[i].aceptacionReclamo == 0) && !(this.dataSourceFacturas.filteredData[i].estado == 'Contado/Gratuito')){
            if (this.arr_simulation.indexOf(this.dataSourceFacturas.filteredData[i].id) == -1){
              this.arr_simulation.push(this.dataSourceFacturas.filteredData[i].id)
            }
            if (this.arr_documentos_to_simulate.indexOf(this.dataSourceFacturas.filteredData[i]) == -1){
              this.arr_documentos_to_simulate.push(this.dataSourceFacturas.filteredData[i])
            }
          }
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento= true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }


    
    }else if(this.checkall == true){

        // this.arr_simulation = []
        // this.arr_documentos_to_simulate = [];
        this.selection.clear()

        for(let i=0;i<this.dataSourceFacturas.filteredData.length;i++){
          this.dataSourceFacturas.filteredData[i].checked = false;
          if ((!this.dataSourceFacturas.filteredData[i].pagado) && !(this.dataSourceFacturas.filteredData[i].aceptacionReclamo == 0) && !(this.dataSourceFacturas.filteredData[i].estado == 'Contado/Gratuito')){
            this.arr_simulation.splice(this.arr_simulation.indexOf(this.dataSourceFacturas.filteredData[i].id), 1);
            this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(this.dataSourceFacturas.filteredData[i]), 1);
          }
        }

        let suma = 0;
        if (this.arr_simulation.length > 0) {
          this.disabled_enviar = false;
          this.total_seleccionado = "Total Seleccionado";
          this.insertar_vencimiento = true;

          for(let i=0;i<this.arr_documentos_to_simulate.length;i++){
            suma = suma + this.arr_documentos_to_simulate[i].mnt_total
          }
          this.total_tabla = suma;
        } else {
          this.disabled_enviar = true;
          this.total_seleccionado = "Total";
          this.total_tabla = this.total_total;
          this.insertar_vencimiento = false;
        }

    }

    const groupBy = (array:any, key:any) => {
      return array.reduce((result:any, currentValue:any) => {(result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );return result;
      }, {}); 
    };
    var group = groupBy(this.arr_documentos_to_simulate, 'ultimorutacreedor');
    this.total_proveedores = Object.keys(group).length;


  }

  seleccionar() {
    console.log('ver que sucede aqui')
    var deuda=0;
    for(let i in this.arr_documentos_to_simulate){
      deuda = deuda + this.arr_documentos_to_simulate[i].mnt_total
    }
    // if (deuda < 500000){
    //   // this.snackBar.open("El monto total de las facturas debe superar los $ 500.000", "Aceptar", {
    //   //   duration: 5000,
    //   //   panelClass: ['JP-snackbar']
    //   // });
    //   this.open_input_dialog("Error", "", "¡Oops! El monto total de las facturas debe superar los $ 500.000","","info","#FA454C")
    //   return false;
    // }
    let folios_map = []
    folios_map =  this.arr_documentos_to_simulate.map((factura) => {
      return factura.folio
    });
    let folios = folios_map.join(",");
    this.aceptacionReclamo(folios, true, 1)
  }

  seleccionar_2(){
    var deuda=0;
    for(let i in this.arr_documentos_to_simulate){
      deuda = deuda + this.arr_documentos_to_simulate[i].mnt_total
    }
    const dialogRef = this.dialog.open(PagoSeleccion, {
      maxHeight: '80vh',
      width:'1300px',
      disableClose: true
    });
    dialogRef.componentInstance.facturas = this.arr_documentos_to_simulate;
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.componentInstance.deuda = deuda
    dialogRef.afterClosed().subscribe((result:any) => {

        this.valForm.reset()
        this.insertar_vencimiento = false;
        this.arr_documentos_to_simulate = [];
        this.arr_simulation = []
        this.disabled_enviar = true;
        this.se_activo_check = false;
        this.checkall = false;
        this.firstFormGroup.controls['checkall'].setValue(false);
        this.total_seleccionado = "Total"
        this.load_table();

        if(result == true){
          this.navigateToLogin()
        }

    });
  }

  navigateToLogin() {
    this.router.navigateByUrl('/home/operaciones-proveedores');
 }


  checkall:boolean=false;


  minDate:any = new Date()
  fecha_individual($e: any, index:any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.ELEMENT_DATA_FACTURAS[index]["fecha_vencimiento"] = date_set
    this.ELEMENT_DATA_FACTURAS[index]["dias"] = diffDays;

    let array = this.clone_array(this.ELEMENT_DATA_FACTURAS)
    array[index].fechapp = null;
    this.put_table(index, array[index])

  }

  fecha_individual_pp($e: any, index:any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()
    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    this.ELEMENT_DATA_FACTURAS[index]["fechapp"] = date_set

    let array = this.clone_array(this.ELEMENT_DATA_FACTURAS)
    array[index].fecha_vencimiento = null;
    this.put_table(index, array[index])

  }

  fecha_todas($e: any){
    var date = $e.value
    let d_o_w  = date.getDay()
    if (d_o_w == 0 || d_o_w ==6)
    date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
    
    let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

    const diffTime = Math.abs(date - this.minDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    for(var i in this.arr_documentos_to_simulate){
        this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
        this.arr_documentos_to_simulate[i]["dias"] = diffDays


    }


    this.put_table()



  }

  capturar_fecha_por_dias($e: any){
      let dias = ($e.target.value - 1);
      let new_date = this.addDays(Number(dias))
      let fecha_calculada = new Date(new_date)

      this.firstFormGroup.controls['secondCtrl'].setValue(fecha_calculada)
      this.fecha_todas_por_dias(fecha_calculada)

  }

  addDays(days:any) {
    let fecha_hoy = new Date()
    let fecha_condias = new Date()
    fecha_condias.setDate(fecha_hoy.getDate()+days);
    return fecha_condias;
  }

  fecha_todas_por_dias(date:any){
      var date = date
      let d_o_w = date.getDay() //0 domingo ...... 6 sabado 

      if (d_o_w == 0 || d_o_w == 6)
        date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))

      let date_set = date.getDate() + "-" + (date.getMonth()  + 1 ) + "-" + date.getFullYear()

      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      for(var i in this.arr_documentos_to_simulate){
          this.arr_documentos_to_simulate[i]['fecha_vencimiento'] = date_set
          this.arr_documentos_to_simulate[i]["dias"] = diffDays

      }

      this.put_table()




  }


  async put_table(index?:any, array?:any) {
    try {
      
      let value = {}
      if(index != undefined && index != null){
            value = {'facturas': [array]}
      }else{
            value = {'facturas':this.arr_documentos_to_simulate}
      }

    
      const bd: any = await this.settings.get_query(1,'ConfirmigCompras/'+this.company_id +'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          this.firstFormGroup.controls['secondCtrl'].setValue(undefined);
          this.firstFormGroup.controls['thridCtrl'].setValue(undefined)

          this.load_table()

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  tabs($e: any) {
    // tab carga manual
    if ($e == 1) {
      this.new_row()
      this.delete_row()
    }
  }

  //CARGA MANUAL

  private hotRegisterer = new HotTableRegisterer();
  id = 'hotInstance';
  hotSettings: Handsontable.default.GridSettings = {
      startRows: 10,
      colHeaders: true,
      //colWidths: [150,200,150,150],
      rowHeights: [25],
      width: '99%',
      height: '100px',
      columns: [
        {
          type: 'numeric'
        }
      ],

  }
  data: any[] = [
    [null, null,null,null,null, null]
  ]
  
  new_row(){
    this.hotRegisterer.getInstance(this.id).alter('insert_row_below', this.data.length+1)
    // this.reset_carga_manual()
  }

  delete_row(){
    try {
      this.hotRegisterer.getInstance(this.id).alter('remove_row', this.data.length+1) 
    } catch {

    } 
  }


  ELEMENT_DATA_VISTAPREVIA: Array<any>=[];
  displayedColumnsVistaPrevia: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
  dataSourceVistaPrevia:any = new MatTableDataSource<Facturas>(this.ELEMENT_DATA_VISTAPREVIA);
  @ViewChild('VistaPrevia') vistaprevia!: MatPaginator;

  //ERROR
  ELEMENT_DATA_VISTAPREVIA_ERROR: Array<any>=[];
  // displayedColumnsVistaPreviaError: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
  // dataSourceVistaPreviaError = new MatTableDataSource<Facturas>(this.ELEMENT_DATA_VISTAPREVIA_ERROR);
  // @ViewChild('VistaPreviaError') vistapreviaerror: MatPaginator;

  hay_facturas_con_error:boolean=true;
  async listoparaenviar() {

    this.ELEMENT_DATA_VISTAPREVIA = [];
    this.ELEMENT_DATA_VISTAPREVIA_ERROR = [];
    this.no_listo = true;
    this.mostrar_resumen_facturas = false;

    var index = this.data.indexOf("");

    if (~index) {
      this.data[index] = null;
    }


    let valid = this.validate_cargar_manual(this.data);

    if(valid == true){

      let value = {'datosRevisar':this.data}

      try {
        const bd: any = await this.settings.get_query(1, 'ComprasVentasManuales/'+this.company_id +'/?accion=0', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
          if (body_data.arrayFront.length == 0){

            this.open_input_dialog("Error", "Errores", "¡Oops! No existen facturas válidas","","info","#FA454C")
            this.no_listo = false;
            return false;
          }

          for(let i in body_data.arrayFront){
            if(body_data.arrayFront[i].errorCompras == true){
              this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i])
            }else if(body_data.arrayFront[i].errorCompras == false){
              this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i])
            }
          }

          if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
            this.hay_facturas_con_error = true;
          }else{
            this.hay_facturas_con_error = false;
          }


          for(let i in this.ELEMENT_DATA_VISTAPREVIA){
            this.mostrar_resumen_facturas = true;
            this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
            if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
                this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
            }

          }

          for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
            this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
            if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
                this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
            }

          }

          let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

          this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

          setTimeout(() => {
            this.dataSourceVistaPrevia=new MatTableDataSource(array3);
            this.dataSourceVistaPrevia.paginator = this.vistaprevia;
            this.dataSourceVistaPrevia._updateChangeSubscription();
            this.no_listo = false;
          });

          this.open_emitir_pago_previo()


  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }
  


  }

  destroy:boolean=false;
  async capture_change(column:any,index:any,value:any, tipo_error:any,folio?:any) {
    try {
      let valor_enviar = {'column':column, 'value':value,  'index':index}

      const bd: any = await this.settings.get_query(3, 'ComprasVentasManuales/'+this.company_id +'/?folio='+folio+'&accion=0', valor_enviar);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        if(body_data.error == false){
          if(body_data.id_date == true){
            let date = new Date(body_data.valor)
            this.ELEMENT_DATA_VISTAPREVIA[index][column] = date;
          }else{
            this.ELEMENT_DATA_VISTAPREVIA[index][column] = body_data.valor;
          }

          this.ELEMENT_DATA_VISTAPREVIA[index][tipo_error] = false;
        }

        if(this.ELEMENT_DATA_VISTAPREVIA[index]['errorCompras'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorFechaEmision'] == false && 
        this.ELEMENT_DATA_VISTAPREVIA[index]['errorFechaVencimiento'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorFolio'] == false &&
        this.ELEMENT_DATA_VISTAPREVIA[index]['errorMonto'] == false && this.ELEMENT_DATA_VISTAPREVIA[index]['errorRut'] == false){
          this.ELEMENT_DATA_VISTAPREVIA[index]['error'] = false;
        }

        this.destroy = true;
        this.valForm2.reset();
        this.valForm2.controls['fecha_vencimiento2'].setValue(new Date())

        this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  capture_date_error($e: any, index:any, column:any, tipo_error:any) {

    let value = new Date($e.value);
    let yy = value.getFullYear();
    let mm = value.getMonth()+1;
    let dd = value.getDate();
    let date = dd+'/'+mm+'/'+yy;
    this.capture_change(column,index,date, tipo_error)

  }

  capture_date_error2($e: any, index:any, column:any, tipo_error:any) {

    let value = new Date($e.value);
    let yy = value.getFullYear();
    let mm = value.getMonth()+1;
    let dd = value.getDate();
    let date = dd+'/'+mm+'/'+yy;
    this.capture_change(column,index,date, tipo_error)

  }

  no_continue:boolean=true;
  check_errors(array:any){
    this.no_continue = true;
    for(let i in array){
      if(array[i]['error'] == true){
          this.no_continue = true;
          break;
      }else{
          this.no_continue = false;
      }
    }
  }

  open_emitir_pago_previo() {
    var deuda=0;
    let array = [];
    for(let i in this.ELEMENT_DATA_VISTAPREVIA){
      deuda = deuda + this.ELEMENT_DATA_VISTAPREVIA[i].mnt_total;
    }

    // if (deuda < 500000){
    //   // this.snackBar.open("El monto total de las facturas debe superar los $ 500.000", "Aceptar", {
    //   //   duration: 5000,
    //   //   panelClass: ['JP-snackbar']
    //   // });
    //   this.open_input_dialog("Error", "", "¡Oops! El monto total de las facturas debe superar los $ 500.000","","info","#FA454C")
    //   return false;
    // }

    let folios_map = []
    folios_map =  this.ELEMENT_DATA_VISTAPREVIA.map((factura) => {
      return factura.folio
    });
    let folios = folios_map.join(",");
    this.aceptacionReclamo(folios, true, 2)
  }

  dialog_open: boolean = false;
  open_emitir_pago(){

    var deuda=0;
    let array = [];
    for(let i in this.ELEMENT_DATA_VISTAPREVIA){
      deuda = deuda + this.ELEMENT_DATA_VISTAPREVIA[i].mnt_total;
    }

    // if (deuda < 500000){
    //   // this.snackBar.open("El monto total de las facturas debe superar los $ 500.000", "Aceptar", {
    //   //   duration: 5000,
    //   //   panelClass: ['JP-snackbar']
    //   // });
    //   this.open_input_dialog("Error", "", "¡Oops! El monto total de las facturas debe superar los $ 500.000","","info","#FA454C")
    //   return false;
    // }
    this.dialog_open = true;
    const dialogRef = this.dialog.open(PagoSeleccion, {
      maxHeight: '80vh',
      width:'1300px',
      disableClose: true,
    });

    array = this.clone_array(this.ELEMENT_DATA_VISTAPREVIA)


    for(let i=0;i<array.length;i++){
      let fecha_emision = new Date(array[i].fecha_emision)
      if(array[i].fecha_vencimiento != null && array[i].fecha_vencimiento != undefined){
        let fecha_vencimiento = new Date(array[i].fecha_vencimiento);
        let dd = fecha_vencimiento.getDate();
        let mm = fecha_vencimiento.getMonth()+1;
        let yy = fecha_vencimiento.getFullYear();
        let new_date = dd+'-'+mm+'-'+yy
        array[i].fecha_vencimiento = new_date;
      }
      let dd = fecha_emision.getDate();
      let mm = fecha_emision.getMonth()+1;
      let yy = fecha_emision.getFullYear();
      let new_date = dd+'-'+mm+'-'+yy
      array[i].fecha_emision = new_date;
    }
    

    dialogRef.componentInstance.facturas = array;
    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.componentInstance.deuda = deuda;
    dialogRef.componentInstance.manual_ventas = true;

    dialogRef.afterClosed().subscribe((result:any) => {
      this.data = [
        [null, null,null,null,null, null]
      ]
      $("#ir_al_anterior").click()
      this.dialog_open = false;
    });

  }

  clone_array(arr:any)
  {
    let aa =  JSON.stringify(arr);
    return  JSON.parse(aa)
  }

  mostrar_resumen_facturas:boolean=false;
  no_listo:boolean=false;
  
  async descargar_file() {
    this.disabled_subir_file=true;    
    let resultado: any = (await this.settings.get_query(1, 'ComprasVentasManuales/' + this.company_id + '/'));
    let body_data = resultado._body;
    if (resultado) {
      window.open(body_data.nameArchivo, "blank")
      this.disabled_subir_file=false;
    }    
  }

  subir(){
    $("#file_but").trigger("click");
  }

  success:boolean=false;
  warning:boolean=false;
  disabled_subir_file:boolean=false;
  async subir_file($e: any) {

    this.no_listo = true;
    this.success=false;
    this.warning=false;
    this.disabled_subir_file=true;

    this.reset_tabla();

    let formData: FormData = new FormData();
    let file_input = $e.target.files;

    for(let i=0;i<file_input.length;i++){
      let name = file_input[i].name
      formData.append('cv_'+i, file_input[i], name);
    }

    let resultado: any = (await this.settings.get_query(2, 'ComprasVentasManuales/' + this.company_id + '/?accion=0', formData));
    let body_data = resultado._body;
    if (resultado) {
      try {
        if (body_data.error.length > 0) {
          this.warning = true;
          this.disabled_subir_file=false;
          $("#file_but").val("")
          // this.snackBar.open("Hay algunos errores en los registros, verifique el formato en la Planilla.", "Aceptar", {
          //   duration: 5000,
          //   panelClass: ['JP-snackbar']
          // });
          this.open_input_dialog("Error", "Errores en los registros", "¡Oops! Hay algunos errores en los registros, verifique el formato en la Planilla.","","info","#FA454C")
          this.no_listo = false;
          return false;
        } else {
          this.success = true;
          this.reset_file()
        }
      } catch {
        this.success = true;
        this.reset_file()
      }

      if (body_data.arrayFront.length == 0) {
        this.warning = true;
        this.disabled_subir_file=false;
        $("#file_but").val("")
        return false;
      }
      

      for(let i in body_data.arrayFront){
        if(body_data.arrayFront[i].errorCompras == true){
          this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(body_data.arrayFront[i])
        }else if(body_data.arrayFront[i].errorCompras == false){
          this.ELEMENT_DATA_VISTAPREVIA.push(body_data.arrayFront[i])
        }
      }

      if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
        this.hay_facturas_con_error = true;
      }else{
        this.hay_facturas_con_error = false;
      }


      for(let i in this.ELEMENT_DATA_VISTAPREVIA){
        this.mostrar_resumen_facturas = true;
        this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
        if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
            this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
        }

      }

      for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
        this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
        if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
            this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
        }

      }

      
      let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)

      this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)

      



      setTimeout(() => {
        this.dataSourceVistaPrevia=new MatTableDataSource(array3);
        this.dataSourceVistaPrevia.paginator = this.vistaprevia;
        this.dataSourceVistaPrevia._updateChangeSubscription();
        this.mostrar_resumen_facturas = true;
      });

      this.open_emitir_pago_previo();
      // $("#siguiente_step").trigger('click')
    }
    
    this.disabled_subir_file=false;
    $("#file_but").val("")

  }

  reset_file() {
    $("#file_but").val("")
    this.success=false;
    this.warning=false;   
    this.disabled_subir_file=false;
  }

  reset_tabla() {
    this.ELEMENT_DATA_VISTAPREVIA = []
    this.ELEMENT_DATA_VISTAPREVIA_ERROR = []

    this.dataSourceVistaPrevia=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA);
    this.dataSourceVistaPrevia.paginator = this.vistaprevia;
    this.dataSourceVistaPrevia._updateChangeSubscription();
  }

  async pagar(pagado:any, id_compra:any, payment:any){
    if (pagado) {
      return false;
    }
    this.reset_selection()
    let resultado: any = (await this.settings.get_query(3, 'PagarCompras/' + id_compra + '/', {'pagado': !pagado, 'mediopago': payment}));
    let body_data = resultado._body;
    if (resultado) {
      if (!body_data.error) {
        this.load_table();
      }
    } 

  }

  reset_selection() {
    this.valForm.reset()
    this.insertar_vencimiento = false;
    this.arr_documentos_to_simulate = [];
    this.arr_simulation = []
    this.disabled_enviar = true;
    this.se_activo_check = false;
    this.checkall = false;
    this.firstFormGroup.controls['checkall'].setValue(false);
    this.total_seleccionado = "Total"
    this.load_table()
  }

  validate_cargar_manual(data:any) {
    let valid = false;

    if (data.length == 0) {
      this.no_listo = false;
      // this.snackBar.open("Debe agregar al menos una factura.", "Aceptar", {
      //   duration: 5000,
      //   panelClass: ['JP-snackbar']
      // });
      this.open_input_dialog("Error", "Errores", "¡Oops! Debe agregar al menos una factura","","info","#FA454C")
      return false;
    }

    let puntos = /\./gi;
    let pesos = /\$/gi;
    let spaces = /\ /gi;
    let guion = /\-/gi;
    var b = "-";
    var saltos = /(\r\n|\n|\r)/gm;
    var coma = /,/g;

    for(let i=0;i<data.length;i++){
      if(data[i][5] != null){
        data[i][5] = data[i][5].replace(saltos,"").trim()
        data[i][5] = data[i][5].replace(coma,"").trim()
        data[i][5] = data[i][5].replace(puntos,"")
        data[i][5] = data[i][5].replace(pesos,"")
        data[i][5] = data[i][5].replace(spaces,"")      
        data[i][5] = parseInt(data[i][5])
        data[i][5] = data[i][5].toString();
      }

      if(data[i][1] != null){
        data[i][1] = data[i][1].replace(saltos,"").trim()
        data[i][1] = data[i][1].replace(puntos,"")
        data[i][1] = data[i][1].replace(guion,"")
        data[i][1] = ([data[i][1].slice(0, -1), b, data[i][1].slice(-1)].join('')).toUpperCase();
      }

      if(data[i][0] != null){
        data[i][0] = data[i][0].replace(saltos,"").trim()
        data[i][0] = parseInt(data[i][0])
        data[i][0] = data[i][0].toString();
      }

      if(data[i][4] != null){
        data[i][4] =  data[i][4].replace(saltos,"").trim()
      }

      if(data[i][3] != null){
        data[i][3] =  data[i][3].replace(saltos,"").trim()
        data[i][3] = data[i][3].replace(spaces,"").trim()
      }

      if(
        data[i][0] == null 
        || data[i][1] == null 
        || data[i][3] == null 
        || data[i][5] == null
        || data[i][0] == "" 
        || data[i][1] == "" 
        || data[i][3] == "" 
        || data[i][5] == ""
        || !(Number.isInteger(parseInt(data[i][0]))) 
        || !(Number.isInteger(parseInt(data[i][5])))
        ){
        // this.snackBar.open("El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.", "Aceptar", {
        //   duration: 5000,
        //   panelClass: ['JP-snackbar']
        // });
        this.open_input_dialog("Error", "", "¡Oops! El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.","","info","#FA454C")
        valid = false;
        break;
      }else{
        valid = true;
      }
    }

    return valid;
  }

  async aceptacionReclamo(folio:any, is_masivo:any, manual:any) {
    if (manual == 1) {
      this.seleccionar_2();
    }
    if (manual == 2) {
      $("#siguiente_step").trigger('click');
      this.disabled = false;
    }
    // // try {
    //   this.disabled = true;
    //   let resultado: any = (await this.settings.get_query(1, 'ppaceptacionyreclamo/' + this.company_id + '/?folios='+folio));
    //   let body_data = resultado._body;
    //   if (resultado) {
    //     let res = body_data.resultado;
    //     let folios_con_errores = []
    //     res.forEach(element => {
    //       if (element.data != null) {
    //         if (element.data.tieneReclamos == true) {
    //           folios_con_errores.push(element.data.dhdrFolio);
    //         }
    //       }
    //     });
    //     if (is_masivo) {
    //       if (folios_con_errores.length > 0){
    //         if (manual == 1) {
    //           let mensaje = "Intenta continuar sin esas facturas o seleciona nuevas facturas.";
    //           let ids_documentos_excluir = []

    //           for (let index = 0; index < this.arr_documentos_to_simulate.length; index++) {
    //             let element = this.arr_documentos_to_simulate[index];
    //             if (folios_con_errores.includes(element.folio)) {
    //               ids_documentos_excluir.push(index);
    //             }
    //           }

    //           for (let index = 0; index < ids_documentos_excluir.length; index++) {
    //             let i = ids_documentos_excluir[index];
    //             this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(this.arr_documentos_to_simulate[i]), 1);             
    //           } 

    //           this.arr_simulation = this.arr_documentos_to_simulate.map(factura => {return factura.id})            
              
    //           this.open_input_dialog("Información", "Facturas de compra", 
    //           "¡Oops! Las siguientes facturas fueron desmarcadas porque tienen reclamos: "+folios_con_errores.join(", "),
    //           mensaje,"highlight_off","#ff933b")
    //           this.load_table();
    //         }

    //         if (manual == 2) {
    //           let mensaje = "Intenta continuar sin esas facturas o seleciona nuevas facturas.";              
    //           this.open_input_dialog("Información", "Facturas de compra", 
    //           "¡Oops! Las siguientes facturas fueron desmarcadas porque tienen reclamos: "+folios_con_errores.join(", "),
    //           mensaje,"highlight_off","#ff933b");
              
    //           let ids_documentos_excluir = []

    //           for (let index = 0; index < this.ELEMENT_DATA_VISTAPREVIA.length; index++) {
    //             let element = this.ELEMENT_DATA_VISTAPREVIA[index];
    //             if (folios_con_errores.includes(element.folio)) {
    //               ids_documentos_excluir.push(index);
    //             }
    //           }

    //           for (let index = 0; index < ids_documentos_excluir.length; index++) {
    //             let i = ids_documentos_excluir[index];
    //             this.ELEMENT_DATA_VISTAPREVIA.splice(this.ELEMENT_DATA_VISTAPREVIA.indexOf(this.ELEMENT_DATA_VISTAPREVIA[i]), 1);
    //             this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].errorCompras = true;
    //             this.ELEMENT_DATA_VISTAPREVIA_ERROR.push(this.ELEMENT_DATA_VISTAPREVIA[i])        
    //           }
    
    //           if(this.ELEMENT_DATA_VISTAPREVIA_ERROR.length > 0){
    //             this.hay_facturas_con_error = true;
    //           }else{
    //             this.hay_facturas_con_error = false;
    //           }
    
    
    //           for(let i in this.ELEMENT_DATA_VISTAPREVIA){
    //             this.mostrar_resumen_facturas = true;
    //             this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_emision)
    //             if(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento != null){
    //                 this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA[i].fecha_vencimiento)
    //             }    
    //           }
    
    //           for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR){
    //             this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_emision)
    //             if(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento != null){
    //                 this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR[i].fecha_vencimiento)
    //             }
    
    //           }
    
    //           let array3 = this.ELEMENT_DATA_VISTAPREVIA.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR)
    
    //           this.check_errors(this.ELEMENT_DATA_VISTAPREVIA)
    
    //           setTimeout(() => {
    //             this.dataSourceVistaPrevia=new MatTableDataSource(array3);
    //             this.dataSourceVistaPrevia.paginator = this.vistaprevia;
    //             this.dataSourceVistaPrevia._updateChangeSubscription();
    
    //             // this.dataSourceVistaPreviaError=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA_ERROR);
    //             // this.dataSourceVistaPreviaError.paginator = this.vistapreviaerror;
    //             // this.dataSourceVistaPreviaError._updateChangeSubscription();
    //             this.no_listo = false;
    //           });
    //         }
    //       } else {
    //         if (manual == 1) {
    //           this.seleccionar_2();
    //         }
    //         if (manual == 2) {
    //           // this.open_emitir_pago();
    //           $("#siguiente_step").trigger('click');
    //           this.disabled = false;
    //         }
    //       }
    //     } else {
    //       this.load_table();
    //     }
    //   }
    // // } catch {
    // //   alert("Error")
    // //   this.disabled = false;
    // // }    
  }

  open_credenciales(){
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false,
    });

    dialogRef.componentInstance.id_company = this.company_id;
    dialogRef.afterClosed().subscribe((result:any) => {
      this.load_all()
    });
  }

  type_filter(value:any) {
    let is_limpiar = false;
    value.forEach((element:any) => {
      if (element == '') {
        is_limpiar = true;
      }
    });
    if (is_limpiar) {
      this.value_type_filter = '';
      this.valForm.controls['type'].setValue('');
    } else {
      this.value_type_filter = value.join()
    }
    this.load_table();
  }

  status_clave: boolean = false;
  estado_enrol  =async(valor:any)=>{
    console.log(valor)
    let endpoint: any = await this.settings.get_query(1, 'get-libro/'+valor+"/")
    let body_data = endpoint._body
    if(!body_data['error']){
      this.status_clave=body_data.status_clave;
    }
  }

}

export interface Facturas{  
  id:any,
  checked: any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  pagado: any,
  payment:any,
  status: any,
  aceptacionReclamo: any 
}

export interface VISTAPREVIA{  
  id:any,
  checked: any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  pagado: any,
  payment:any,
  status: any,
  aceptacionReclamo: any,
  errorCompras: boolean
}
