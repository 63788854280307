import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Meta } from "@angular/platform-browser";
import { NavigationExtras, Router } from "@angular/router";
import { SettingsService } from "../settings/settings.service";
import { register } from 'swiper/element/bundle';
import { InfoModalOnboarding } from "./info-modal/info-modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AgendarOnboarding } from "./agendar/agendar";
register();

declare var $: any;

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.html',
  styleUrls: ['./onboarding.less']
})
export class OnBoardingComponent implements OnInit {


  valForm: FormGroup;
  valFormFacturador:FormGroup;

  business_name:any=localStorage.getItem('business_name')

  constructor(private _formBuilder: FormBuilder, public settings: SettingsService, public dialog: MatDialog, public route:Router ) { 
    
    this.valForm = this._formBuilder.group({
      'clave_sii': ['',  Validators.compose([Validators.required])],
    });

    this.valFormFacturador = this._formBuilder.group({
      'facturador': ['',  Validators.compose([Validators.required])],
      'otro': [''],
    });

  }

  hide: boolean = true;
  step:any=1;
  id_company=localStorage.getItem('company_id');
  ngOnInit() {

    this.get_facturadores();
    this.get_ejecutivo_dinamico();
  }


  open_modal_info(step:any) {


    
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.step = step;
    dialogRef.componentInstance.contacto = this.contacto;

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result == true){
        this.step = 2;
      }
      if(result == 'retry'){
        this.valForm.controls['clave_sii'].reset();
      }

      if(result == 'six'){
        this.step = 6;
        setTimeout(() => {
          this.route.navigate(['home/simulacion',this.id_simulation])
        }, 1500);
      }
      
    });

  }

  open_modal_info_disable(step:any) {


    
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
      disableClose: false
    });

    dialogRef.componentInstance.step = step;
    dialogRef.componentInstance.contacto = this.contacto;

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result == true){
        this.step = 2;
      }
      if(result == 'retry'){
        this.valForm.controls['clave_sii'].reset();
      }

      if(result == 'six'){
        this.step = 6;
        setTimeout(() => {
          this.route.navigate(['home/simulacion',this.id_simulation])
        }, 1500);
      }

    });

  }

  agendar() {
    let dialogRef = this.dialog.open(AgendarOnboarding, {
      width: '700px',
      minHeight: '600px',
      autoFocus: false,
    });



  }
  

  navegar_a(url:string){
    window.open(url, "_blank")
  }


  loading:boolean=false;
  pasar_al_paso_2(){
    this.loading = true;

    this.guardar_clave_sii();
    setTimeout(() => {
      this.loading = false;

      this.step = 3;
    }, 1500);

  }

  facturador_seleccionado:any=null;
  selecionar_facturador($event:any){
    const value = Number($event.value);
    this.facturador_seleccionado = value;
  }

  disable_btn:boolean=true;
  validate_key($event:any){
    if($event.target.value != undefined && $event.target.value != null && $event.target.value != ""){
      this.disable_btn = false;
    }else{
      this.disable_btn = true;
    }
  }

  loading_sii:boolean=false;
  success_sii:boolean=false;
  async verificar_guardar_clave_sii(validate?:any){
    try {


      let value:any = {"sii_key": this.valForm.controls['clave_sii'].value}
      if (validate) {
        value["validate"] = true;
      }
  
      this.loading_sii = true;
      this.disable_btn = true;
      this.success_sii = false;


      const bd: any = await this.settings.get_query(3, `cliente/validate-clave-sii/${this.id_company}/`,value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          this.disable_btn = false;
          this.loading_sii = false;
          this.success_sii = true;





        break;
        default:

        break;
      }

    } catch (bd:any) {



      this.valForm.controls['clave_sii'].setErrors({'incorrect': true});
      this.disable_btn = false;
      this.loading_sii = false;
      this.success_sii = false;

      switch (bd.status) {
        case 400:
          let error = "Acceso bloqueado. Intente nuevamente más tarde."
          if(bd.error.error.errors.non_field_errors[0] == error){
            this.open_modal_info_disable(9); 
            this.valForm.disable();
          }else{
            this.open_modal_info(8); 
          }

      }

    }
  }

  async guardar_clave_sii(){

    try {


      let value:any = {"sii_key": this.valForm.controls['clave_sii'].value}
       value["validate"] = false;
  

      const bd: any = await this.settings.get_query(3, `cliente/validate-clave-sii/${this.id_company}/`,value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        break;
        default:

        break;
      }

    } catch (bd:any) {



    }
  }

  facturadores:any[]=[];
  async get_facturadores(){
    try {



      const bd: any = await this.settings.get_query(1, `cliente/facturador-electronico/`, );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.facturadores = body_data.success.info;
        console.log(this.facturadores)


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  name_facturador_otro:string="";
  disabled_facturador:boolean=false;
  async put_facturador(){
    try {
      this.disabled_facturador = true;
      this.loading = true;
      let value: any = { 'facturador_id': this.facturador_seleccionado };
  
      if (this.facturador_seleccionado == 7) {
        value['facturador_otro'] = this.valFormFacturador.controls['otro'].value;
      }

      const bd: any = await this.settings.get_query(3, `cliente/facturador-electronico-individual/${this.id_company}/`, value);
      const body_data: any = bd._body;
  

      switch (bd.status) {
        case 200:
        
          //AQUI VAS A MOSTRAR EL PASO 4


          if(this.facturador_seleccionado == 1){
            //el 6 es un loading
            //this.step = 6; 
            this.robots_riesgo();


          }else{
            this.step = 5;
            this.disabled_facturador = false;
            this.loading = false;
          }


          break;
  

      }
  
    } catch (bd: any) {

      this.disabled_facturador = false;
      this.loading = false;

    }
  }

  id_simulation:any;
  async robots_riesgo(){
    try {

      const bd: any = await this.settings.get_query(1, `/onboarding/risk-pilot/${this.id_company}/`);
      const body_data: any = bd._body;
  

      switch (bd.status) {
        case 200:
        
              this.step = 4;
              this.id_simulation = body_data.success.data.simulation_id;

              //apagamos los spinner del paso anterior
              this.disabled_facturador = false;
              this.loading = false;



          break;
  

      }
  
    } catch (bd: any) {


      this.step = 5;
      this.disabled_facturador = false;
      this.loading = false;

    }
  }

  omitir(){
    this.route.navigate(['/home/onboarding/omitir']);
  }

  ir_al_paso($event:any){
    let value = Number($event.target.value);
    this.step = value;
  }


  //endpoint contacto dinamico
  contacto:any="Cargando"
  async get_ejecutivo_dinamico(){
    try {



      const bd: any = await this.settings.get_query(1, `/cliente/ejecutivo-representante-empresa/`+this.id_company+'/' );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          this.contacto = body_data.success.info.numero_telefono;


        break;
        default:

        break;
      }

    } catch (bd:any) {



    }
  }

  navigateToHome() {
    this.route.navigate(['home/']);
  }

}