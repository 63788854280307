import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';;
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { SelectionModel } from '@angular/cdk/collections';
import * as Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-facturas-ventas-manuales',
  templateUrl: './facturas-ventas-manuales.html',
  styleUrls: ['./facturas-ventas-manuales.less']
})

export class FacturasVentasManuales implements OnInit {

  
  id_company;
  
  @Input() deuda: any;
  @Input() deuda_total: any;
  @Input() id_simulation: any;
  @Input() manual_ventas:boolean=true;    
  @Input() simulacion_recibida: any;
  @Input() facturas_venta: any;
  @Input() facturas: any;

  mostrar_resumen_facturas:boolean=false;

  ELEMENT_DATA: Facturas[]=[];
  displayedColumns: string[] = ['select','folio','receptoracreedor_name','receptoracreedor_rut','mnt_total','fecha_emision','fecha_vencimiento'];

  dataSource = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);

  @ViewChild('MatPaginator2') paginator2!: MatPaginator;
  @ViewChild('MatPaginator3') paginator3!: MatPaginator;

  firstFormGroup: FormGroup;
  secondFormGroup!: FormGroup;
  optionalLabelText!: string;
  optionalLabelTextChoices: string[] = ['Option 1', 'Option 2', 'Option 3'];

  constructor(public snackBar: MatSnackBar,public settings: SettingsService, private fb: FormBuilder, public dialog:MatDialog, public dialogRef:MatDialogRef<FacturasVentasManuales>) { 

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required]
    });



    this.id_company = localStorage.getItem('company_id')

  }


  ngOnInit() {

    console.log("FACTURAs", this.facturas)

  }


  page:Number=1;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];


  cargando:boolean=true;
  esta_todo_listo:boolean=false;
  total=0;
  agregar_vencimiento:boolean=false;

  simulation={'anticipo':0,'vencimiento':0,'comision':0,'gastosOperacionales':0,'iva_operaciones':0,'interesTotal':0,'liquidoRecibir':0, 'porPagar':false,'porPagarMonto':0};
  has_simulation:boolean=false;
  por_pagar=0;
  debe_pagar:boolean=false;

  //simulation
  operacionAnticipo=0.95;
  gastosOperacionales=30000;
  tasa_porc=0.02;
  anticipo_porc=0.95;
  vencimiento=30;

  recalcular:boolean=true;

  minDate:any = new Date()



  formatMoney = (amount: any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) ;
    } catch (e) {
    }
  } 



  objeto_nelson: Array<any> = [];
  arr_documentos_to_simulate: Array<any> = [];
  arr_simulation: Array<any> = []
  disabled_enviar:boolean=true;
  total_total=0;

  disabled_next:boolean=true;
  activa() {

        console.log("HSATA AQUI", this.arr_documentos_to_simulate)

        //HACER SIMULACION
        let acumuladorVentas=0;
        let comision=50000;
        let iva_operaciones=0;
        let interesTotal=0;
        let liquidoRecibir=0;
        let porPagar:boolean=false;
        let porPagarMonto=0;
        let suma=0;

        for(let i=0; i< this.arr_documentos_to_simulate.length;i++){
          acumuladorVentas=acumuladorVentas+this.arr_documentos_to_simulate[i].mnt_total;
          comision=0.005*(acumuladorVentas*this.operacionAnticipo)
          if(comision < 50000){
            comision=50000
          }

          iva_operaciones=(Number(this.gastosOperacionales) + Number(comision))*0.19;
          //30 es cantidad de dias

          let dias_vencimiento_default = 30;
          console.log("original position", this.arr_documentos_to_simulate[i].fecha_vencimiento)
          if(this.arr_documentos_to_simulate[i].fecha_vencimiento != null && this.arr_documentos_to_simulate[i].fecha_vencimiento != undefined){
            dias_vencimiento_default = this.arr_documentos_to_simulate[i].dias;
          }

          console.log("dias vencimiento", dias_vencimiento_default)
          interesTotal= this.arr_documentos_to_simulate[i].mnt_total * this.anticipo_porc * this.tasa_porc / 30 * dias_vencimiento_default;
          liquidoRecibir=(acumuladorVentas*this.operacionAnticipo)-Number(comision)-Number(this.gastosOperacionales)-Number(iva_operaciones)-Number(interesTotal);
    
          suma = dias_vencimiento_default + suma;

        }

        let prom_dias = suma / this.arr_documentos_to_simulate.length;

        this.vencimiento = Math.round(prom_dias);

        if(Number(liquidoRecibir) > this.deuda){
          this.debe_pagar = true;
          this.por_pagar = Number(liquidoRecibir) - this.deuda;
          this.disabled_next = false;



        }else{
          //SI SE CUMPLE AQUI NO DEJAR CONTINUAR.
          this.disabled_next = true
          this.por_pagar = this.deuda - Number(liquidoRecibir);
          this.debe_pagar = false;

        }

        let valorAnticipo=Number(acumuladorVentas*this.operacionAnticipo);


        this.simulation={'anticipo':valorAnticipo,'vencimiento':this.vencimiento,'comision':comision,'gastosOperacionales':this.gastosOperacionales,'iva_operaciones':iva_operaciones,
        'interesTotal':interesTotal,'liquidoRecibir': Number(liquidoRecibir), 'porPagar':this.debe_pagar,'porPagarMonto':this.por_pagar};

        console.log("simu", this.simulation)
        console.log("activa", this.arr_documentos_to_simulate)

  }

  selection = new SelectionModel<Facturas>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event: any) {
    console.log($event)
    !$event.checked  ?
    this.selection.clear() :
    this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

    //CARGA MANUAL
    private hotRegisterer3 = new HotTableRegisterer();
    id3 = 'hotInstance3';
    hotSettings3: Handsontable.default.GridSettings = {
        startRows: 1,
        colHeaders: true,
        // colWidths: [150,200,150,150],
        rowHeights: [25],
        width: '100%',
        height: 'auto',
        columns: [
          {
            type: 'numeric'
          }
        ],
  
    }
    data3: any[] = [
      [null, null,null,null,null, null]
    ]
    
    new_row(){
      this.hotRegisterer3.getInstance(this.id3).alter('insert_row_below', this.data3.length+1)
  
    }
  
    delete_row(){
      try {
        this.hotRegisterer3.getInstance(this.id3).alter('remove_row', this.data3.length+1)
      } catch {
  
      } 
    }
  
  
    ELEMENT_DATA_VISTAPREVIA2: Array<any>=[];
    displayedColumnsVistaPrevia2: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
    dataSourceVistaPrevia2 = new MatTableDataSource<VISTAPREVIA2>(this.ELEMENT_DATA_VISTAPREVIA2);
    @ViewChild('VistaPrevia2') vistaprevia2!: MatPaginator;
  
    //ERROR
    ELEMENT_DATA_VISTAPREVIA_ERROR2: Array<any>=[];
    // displayedColumnsVistaPreviaError2: string[] = ['check','folio','emisor','rut_emisor','fecha_emision','fecha_vencimiento','monto'];
    // dataSourceVistaPreviaError2 = new MatTableDataSource<VISTAPREVIA2>(this.ELEMENT_DATA_VISTAPREVIA_ERROR2);
    // @ViewChild('VistaPreviaError2') vistapreviaerror2: MatPaginator;
  
    hay_facturas_con_error:boolean=true;
    listoparaenviar(){

  
  
  
  
    }
  
    capture_change(column: any,index: any,value: any, tipo_error: any,folio?: any){

    }
  
    capture_date_error($e: any, index: any, column: any, tipo_error: any) {
  
      let value = new Date($e.value);
      let yy = value.getFullYear();
      let mm = value.getMonth()+1;
      let dd = value.getDate();
      let date = dd+'/'+mm+'/'+yy;
      this.capture_change(column,index,date, tipo_error)
  
    }
  
    capture_date_error2($e: any, index: any, column: any, tipo_error: any) {
  
      let value = new Date($e.value);
      let yy = value.getFullYear();
      let mm = value.getMonth()+1;
      let dd = value.getDate();
      let date = dd+'/'+mm+'/'+yy;
      this.capture_change(column,index,date, tipo_error)
  
    }
  
    no_continue:boolean=true;
    check_errors(array: any){
      this.no_continue = true;
      for(let i in array){
        if(array[i]['error'] == true){
            this.no_continue = true;
            break;
        }else{
            this.no_continue = false;
        }
      }
    }

    recalculate_manual(){


      let array_2=[];

      array_2 = this.clone_array(this.ELEMENT_DATA_VISTAPREVIA2)
      this.arr_documentos_to_simulate = array_2;

      let date_hoy = new Date();
      date_hoy.setDate(date_hoy.getDate() + 30);

      for(let i in this.arr_documentos_to_simulate){
        this.fecha_individual_SIM(new Date(this.arr_documentos_to_simulate[i]['fecha_vencimiento']), i)
      }


      //this.activa()

      var re = "/"
      for(let i in this.arr_documentos_to_simulate){
        let date_emision = new Date(this.arr_documentos_to_simulate[i].fecha_emision);
        let dd = date_emision.getDate(); let mm = date_emision.getMonth()+1; let yy = date_emision.getFullYear();
        this.arr_documentos_to_simulate[i].fecha_emision = dd+'-'+mm+'-'+yy;
        this.arr_documentos_to_simulate[i].fecha_vencimiento = this.arr_documentos_to_simulate[i].fecha_vencimiento.replace(re, '-')
        this.arr_documentos_to_simulate[i].fecha_vencimiento = this.arr_documentos_to_simulate[i].fecha_vencimiento.replace(re, '-')
      }

      console.log("AQUI", this.arr_documentos_to_simulate)
      this.agregar_facturas()

    }



    fecha_individual_SIM(fecha: any, index: any){

      console.log("fila", fecha, this.arr_documentos_to_simulate[index])
  
      var date = fecha
      let d_o_w  = date.getDay()
      if (d_o_w == 0 || d_o_w ==6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7))
      let date_set = date.getDate() + "/" + (date.getMonth()  + 1 ) + "/" + date.getFullYear()
      const diffTime = Math.abs(date - this.minDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  
      console.log("dias", diffDays)
      console.log("date", date_set)
  
      this.arr_documentos_to_simulate[index]["fecha_vencimiento"] = date_set
      this.arr_documentos_to_simulate[index]["dias"] = diffDays;
  
  
    }
  


    paso_1:boolean=true;
    paso_2:boolean=false;

    array_seleccion_clone: Array<any>=[];
    array_ventas_clone: Array<any>=[];
    array_consolidado: Array<any>=[];

    arreglo_simulacion: Array<any>=[]
    arreglo_simulacion_clone: Array<any>=[]

    clone_array(arr: any)
    {
      let aa =  JSON.stringify(arr);
      return  JSON.parse(aa)
    }

    agregar_facturas(){
      console.log("facturas seleccionadas", this.arr_documentos_to_simulate)
      console.log("facturas de simulacion", this.facturas_venta)

      this.arreglo_simulacion.push(this.simulacion_recibida)

      this.array_seleccion_clone = this.clone_array(this.arr_documentos_to_simulate);
      this.array_ventas_clone = this.clone_array(this.facturas_venta)
      this.arreglo_simulacion_clone = this.clone_array(this.arreglo_simulacion)

      this.array_consolidado = this.array_seleccion_clone.concat(this.array_ventas_clone);

      this.esto_recalcula_muy_bien()



      
    }


    vencimiento_operacion=30;
    ELEMENT_DATA_2: Facturas_2[]=[];
    displayedColumns_2: string[] = ['folio','receptoracreedor_name','mnt_total','fecha_emision','fecha_vencimiento'];
    dataSource_2 = new MatTableDataSource<Facturas_2>(this.ELEMENT_DATA_2);
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    esto_recalcula_muy_bien(){

      console.log("Simulacion recibida", this.arreglo_simulacion_clone)
 

        //HACER SIMULACION
        let acumuladorVentas=0;
        let comision=50000;
        let iva_operaciones=0;
        let interesTotal=0;
        let liquidoRecibir=0;
        let porPagar:boolean=false;
        let porPagarMonto=0;
        let suma=0;
        let acumulador_interes=0;


        let gastos = this.arreglo_simulacion[0].gastos;
        let tasa_interes = this.arreglo_simulacion[0].tasa_interes;
        let porcentaje_anticipo = this.arreglo_simulacion[0].porc_anticipo;

        for(let i in this.array_consolidado){
          acumuladorVentas=acumuladorVentas+Number(this.array_consolidado[i].mnt_total);
          let dias_vencimiento_default = 30;
          if(this.array_consolidado[i].fecha_vencimiento != null && this.array_consolidado[i].fecha_vencimiento != undefined){
            dias_vencimiento_default = this.array_consolidado[i].dias;
          }

          interesTotal= Number(this.array_consolidado[i].mnt_total) * porcentaje_anticipo * tasa_interes / 30 * dias_vencimiento_default;
          suma = dias_vencimiento_default + suma;
          acumulador_interes = acumulador_interes + interesTotal;

          }


          comision=0.005*(acumuladorVentas*porcentaje_anticipo)
          if(comision < 50000){
            comision=50000
          }

          iva_operaciones=(Number(gastos) + Number(comision))*0.19;
          liquidoRecibir=(acumuladorVentas*porcentaje_anticipo)-Number(comision)-Number(gastos)-Number(iva_operaciones)-Number(acumulador_interes);
          let prom_dias = suma / this.array_consolidado.length;
          this.vencimiento_operacion = Math.round(prom_dias);

        if(Number(liquidoRecibir) > this.deuda_total){
          this.debe_pagar = true;
          this.por_pagar = Number(liquidoRecibir) - this.deuda_total;
          this.disabled_next = false;

        }else{
          //SI SE CUMPLE AQUI NO DEJAR CONTINUAR.
          this.disabled_next = true
          this.por_pagar = this.deuda_total - Number(liquidoRecibir);
          this.debe_pagar = false;

        }

        let valorAnticipo=Number(acumuladorVentas*this.operacionAnticipo);


        this.simulacion_recibida={'anticipo':Math.round(valorAnticipo),'vencimiento':this.vencimiento_operacion,'comision':Math.round(comision),'gastosOperacionales':gastos,'iva_operaciones':Math.round(iva_operaciones),
        'interesTotal':Math.round(acumulador_interes),'liquidoRecibir': Math.round(Number(liquidoRecibir)), 'porPagar':this.debe_pagar,'porPagarMonto':Math.round(this.por_pagar)};

        console.log("activa", this.array_consolidado)
        console.log("simulacion realizada", this.simulacion_recibida)

        this.ELEMENT_DATA_2 = this.array_consolidado;


        setTimeout(() => {
          this.dataSource_2=new MatTableDataSource(this.ELEMENT_DATA_2);
          this.dataSource_2.paginator = this.paginator;
          this.dataSource_2._updateChangeSubscription();
        });

        this.titulo="Simulación Generada"
        this.paso_1 = false;
        this.paso_2 = true;
  

    }

    titulo="Ingreso Manual de Facturas";
    subtitulo="Vista Previa"
    anterior(){
      this.titulo="Libro de Ventas"
      this.paso_2 = false;
      this.paso_1 = true;
    }

    disabled:boolean=false;
    cerrar_y_continuar()
    {
      this.disabled = true;
      this.agregar_facturas_compras(this.facturas, this.arr_documentos_to_simulate)
    }

    //DIALOG INPUT
    open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

    console.log("se abrio dialog")
    const dialogRef = this.dialog.open(InputDialog,{
      width:'700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
  
  
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  
    }

    agregar_facturas_compras(compras: any, ventas: any){

  

  
    }

    guardar_facturas_venta(ventas: any){

    }


    async descargar_file() {
      this.disabled_subir_file=true;    
      let resultado: any = (await this.settings.get_query(1, 'ComprasVentasManuales/' + this.id_company + '/'));
      let body_data = resultado._body;
      if (resultado) {
        window.open(body_data.nameArchivo, "blank")
        this.disabled_subir_file=false;
      }    
    }
  
    subir(){
      $("#file_but_ventas").trigger("click");
    }
  
    success:boolean=false;
    warning:boolean=false;
    disabled_subir_file:boolean=false;
    async subir_file($e: any) {
  
      this.success=false;
      this.warning=false;
      this.disabled_subir_file=true;
  
      this.reset_tabla();
  
      let formData: FormData = new FormData();
      let file_input = $e.target.files;
  
      for(let i=0;i<file_input.length;i++){
        let name = file_input[i].name
        formData.append('cv_'+i, file_input[i], name);
      }
  
      let resultado: any = (await this.settings.get_query(2, 'ComprasVentasManuales/' + this.id_company + '/?accion=1', formData));
      let body_data = resultado._body;
      if (resultado) {
        try {
          if (body_data.error.length > 0) {
            this.warning = true;
            this.disabled_subir_file=false;
            $("#file_but_ventas").val("")
            // this.snackBar.open("Hay algunos errores en los registros, verifique el formato en la Planilla.", "Aceptar", {
            //   duration: 5000,
            //   panelClass: ['JP-snackbar']
            // });
            this.open_input_dialog("Error", "Errores", "¡Oops! Hay algunos errores en los registros, verifique el formato en la Planilla.","","info","#FA454C")
            return false;
          } else {
            this.success = true;
            this.reset_file()
          }
        } catch {
          this.success = true;
          this.reset_file()
        }

        if (body_data.arrayFront.length == 0) {
          this.warning = true;
          this.disabled_subir_file=false;
          $("#file_but_ventas").val("")
          return false;
        }
        
  
        for(let i in body_data.arrayFront){
          if(body_data.arrayFront[i].errorVentas == true){
            this.ELEMENT_DATA_VISTAPREVIA_ERROR2.push(body_data.arrayFront[i])
          }else if(body_data.arrayFront[i].errorVentas == false){
            this.ELEMENT_DATA_VISTAPREVIA2.push(body_data.arrayFront[i])
          }
        }

        if(this.ELEMENT_DATA_VISTAPREVIA_ERROR2.length > 0){
          this.hay_facturas_con_error = true;
        }else{
          this.hay_facturas_con_error = false;
        }


        for(let i in this.ELEMENT_DATA_VISTAPREVIA2){
          this.mostrar_resumen_facturas = true;
          this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_emision)
          if(this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_vencimiento != null){
              this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA2[i].fecha_vencimiento)
          }

        }

        for(let i in this.ELEMENT_DATA_VISTAPREVIA_ERROR2){
          this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_emision = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_emision)
          if(this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_vencimiento != null){
              this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_vencimiento = new Date(this.ELEMENT_DATA_VISTAPREVIA_ERROR2[i].fecha_vencimiento)
          }

        }

        let array3 = this.ELEMENT_DATA_VISTAPREVIA2.concat(this.ELEMENT_DATA_VISTAPREVIA_ERROR2)

        this.check_errors(this.ELEMENT_DATA_VISTAPREVIA2)

        setTimeout(() => {
          this.dataSourceVistaPrevia2=new MatTableDataSource(array3);
          this.dataSourceVistaPrevia2.paginator = this.vistaprevia2;
          this.dataSourceVistaPrevia2._updateChangeSubscription();

          // this.dataSourceVistaPreviaError2=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA_ERROR2);
          // this.dataSourceVistaPreviaError2.paginator = this.vistapreviaerror2;
          // this.dataSourceVistaPreviaError2._updateChangeSubscription();
          this.mostrar_resumen_facturas = true;
        });

        $("#siguiente_step2").trigger('click')
      }
      
      this.disabled_subir_file=false;
      $("#file_but_ventas").val("")
  
    }
  
    reset_file() {
      $("#file_but_ventas").val("")
      this.success=false;
      this.warning=false;   
      this.disabled_subir_file=false;
    }
  
    reset_tabla() {
      this.ELEMENT_DATA_VISTAPREVIA2 = []
      this.ELEMENT_DATA_VISTAPREVIA_ERROR2 = []
  
      this.dataSourceVistaPrevia2=new MatTableDataSource(this.ELEMENT_DATA_VISTAPREVIA2);
      this.dataSourceVistaPrevia2.paginator = this.vistaprevia2;
      this.dataSourceVistaPrevia2._updateChangeSubscription();
    }
  
    validate_cargar_manual(data: any) {
      let valid = false;
  
      if (data.length == 0) {
        // this.snackBar.open("Debe agregar al menos una factura.", "Aceptar", {
        //   duration: 5000,
        //   panelClass: ['JP-snackbar']
        // });
        this.open_input_dialog("Error", "Errores", "¡Oops! Debe agregar al menos una factura.","","info","#FA454C")
        return false;
      }
  
      let puntos = /\./gi;
      let pesos = /\$/gi;
      let spaces = /\ /gi;
      let guion = /\-/gi;
      var b = "-";
      var saltos = /(\r\n|\n|\r)/gm;
      var coma = /,/g;
  
      for(let i=0;i<data.length;i++){
        if(data[i][5] != null){
          data[i][5] = data[i][5].replace(saltos,"").trim()
          data[i][5] = data[i][5].replace(coma,"").trim()
          data[i][5] = data[i][5].replace(puntos,"")
          data[i][5] = data[i][5].replace(pesos,"")
          data[i][5] = data[i][5].replace(spaces,"")      
          data[i][5] = parseInt(data[i][5])
          data[i][5] = data[i][5].toString();
        }

        if(data[i][1] != null){
          data[i][1] = data[i][1].replace(saltos,"").trim()
          data[i][1] = data[i][1].replace(puntos,"")
          data[i][1] = data[i][1].replace(guion,"")
          data[i][1] = ([data[i][1].slice(0, -1), b, data[i][1].slice(-1)].join('')).toUpperCase();
        }

        if(data[i][0] != null){
          data[i][0] = data[i][0].replace(saltos,"").trim()
          data[i][0] = parseInt(data[i][0])
          data[i][0] = data[i][0].toString();
        }

        if(data[i][4] != null){
          data[i][4] =  data[i][4].replace(saltos,"").trim()
        }

        if(data[i][3] != null){
          data[i][3] =  data[i][3].replace(saltos,"").trim()
        }
  
        if(
          data[i][5] == null 
          || data[i][0] == null 
          || data[i][3] == null 
          || data[i][0] == null 
          || data[i][4] == null
          || data[i][5] == "" 
          || data[i][0] == "" 
          || data[i][3] == "" 
          || data[i][0] == "" 
          || data[i][4] == ""
          || !(Number.isInteger(parseInt(data[i][0]))) 
          || !(Number.isInteger(parseInt(data[i][5])))
          ){
          // this.snackBar.open("El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.", "Aceptar", {
          //   duration: 5000,
          //   panelClass: ['JP-snackbar']
          // });
          this.open_input_dialog("Error", "Errores", "¡Oops! El folio, RUT, fecha de emisión y monto no deben estar vacíos. El folio y monto solo admiten números.","","info","#FA454C")
          valid = false;
          break;
        }else{
          valid = true;
        }
      }
  
      return valid;
    }
}

export interface Facturas{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}

export interface Facturas2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any,
  bancos:any;
}

export interface VISTAPREVIA2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  fecha_vencimiento:any,
  simulado:any,
}

export interface Facturas_2{  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}