
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


declare var $: any;


@Component({
  selector: 'app-alert-anticipo',
  templateUrl: './alert-anticipo.html',
  styleUrls: ['./alert-anticipo.less']
})
export class AlertAnticipo implements OnInit {





  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<AlertAnticipo>) {



   }



  ngOnInit() {


  }


}