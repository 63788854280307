import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';;
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

declare var $ :any;


@Component({
  selector: 'app-facturador',
  templateUrl: './facturador.html',
  styleUrls: ['./facturador.less']
})


export class FacturadorEle implements OnInit {
  [x: string]: any;
   
  @Input() id_company:any;
  @Input() data:any;
  @Input() token:any;


  valForm: FormGroup;
  fac:any[]=[];
  show_spinner:boolean=false;

  filteredStates!: Observable<Facturador[]>;

  constructor(public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService,private _formBuilder: FormBuilder, private dialogRef:MatDialogRef<FacturadorEle> ){


      this.valForm = this._formBuilder.group({
        facturador: [''],
        otro_facturador: [''],
        rut_nubox: new FormControl('', Validators.compose([Validators.required])),
        clave_nubox: new FormControl('', Validators.compose([Validators.required])),

      });


  }

  ngOnInit() {

    this.get_facturadores();
    this.get_facturador(this.data);

  }





  //FACTURADOR
  stateCtrl = new FormControl();
  facturadores: Facturador[] = [];
  success_facturador:boolean=false;
  saving_facturador:boolean=false;
  saving_facturador_nubox:boolean=false;

  private _filterStates(value: string): Facturador[] {
    const filterValue = value.toLowerCase();

    return this.facturadores.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  }

  async get_factorizador(){
    try {


      const bd: any = await this.settings.get_query(1, 'get-facturador/' , );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.facturadores = body_data.success;
        this.fac = body_data.success;



        this.filteredStates = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map((state:any) => state ? this._filterStates(state) : this.facturadores.slice())
        );


        break;
        default:

        break;
      }

    } catch (bd:any) {


      
      switch (bd.status) {
        case 500:


          break;
        case 400:


          break;
        default:


        break;
      }
    }
  }

  nombre_facturador:any;
  id_facturador:any;
  mostrar_form:boolean=false;
  mostrar_form2:boolean=false;
  mostrar_put_nubox:boolean=false;
  set_facturador($e:any){

      this.nombre_facturador = $e.option.value;

      if(this.nombre_facturador == 'Otro'  ){
        this.mostrar_form = true;
        this.mostrar_put_nubox=false;

      }else{

        this.show_success_factorizador = false;
        this.mostrar_form = false;
        this.valForm.controls['otro_facturador'].reset();
        this.name = "";
        this.mostrar_put_nubox=false;
      }

      if( this.nombre_facturador=='Nubox' ){
        this.mostrar_form2 = true;
        this.mostrar_put_nubox=true;
        
        
      }else{

        this.mostrar_form2 = false;
        this.mostrar_put_nubox=false;
      }
      
      
      this.id_facturador = this.fac.find(x => x.name == this.nombre_facturador).id;

  }

  success_facturador_otro:boolean=false;
  name:any;
  escribio_otro(){
    this.name = this.valForm.controls['otro_facturador'].value;
  }
  success_facturador_nubox:boolean=false;
  


  show_last_step:boolean=false;
  async subir_factorizador(){
    try {

      this.success_facturador = false;
      this.saving_facturador = true;
      let value = {'facturador':this.nombre_facturador}

      const bd: any = await this.settings.get_query(2, 'facturador-registro/' + this.id_company + '/',value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        if(this.nombre_facturador == "MiPyme"){
          this.show_success_factorizador = true;
        }else{
          this.show_success_factorizador = false;
        }

        this.show_last_step = true;

        this.saving_facturador = false;
        this.success_facturador = true;


        break;
        default:

        break;
      }

    } catch (bd:any) {


      
      switch (bd.status) {
        case 500:


          break;
        case 400:


          break;
        default:


        break;
      }
    }
  }

  validate($e:any){

    if($e.target.value == ""){

      this.success_facturador = false;
      this.saving_facturador = false;

    }


  }

  show_success_factorizador:boolean=true;
  get_individual_factorizador(){

    if(this.data.facturador.name != null && this.data.facturador.name != undefined && this.data.facturador.name != ""){
      this.stateCtrl.setValue(this.data.facturador.name)
      this.nombre_facturador = this.data.facturador.name;
      this.id_facturador = this.data.facturador.id;
      this.success_facturador = true;
      this.show_last_step = true;

      if(this.data.facturador.name == "MiPyme"){

        this.show_success_factorizador = true;

      }

      if(this.data.facturador.id == 7){

        this.mostrar_form = true;
        this.valForm.controls['otro_facturador'].setValue(this.data.facturador_otro)
        this.show_success_factorizador = true;
        this.success_facturador_otro = true

      }else{

        this.mostrar_form = false;
        this.valForm.controls['otro_facturador'].reset();
        this.show_success_factorizador = false;
        this.success_facturador_otro = false;

      }

      

    }

  }


  async update_2(){
    try {

      this.success_facturador = false;
      this.saving_facturador = true;
      let value = {'facturador':this.id_facturador, 'name_otro': this.name}

      const bd: any = await this.settings.get_query(2, 'facturador-registro/' + this.id_company + '/',value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        if(this.id_facturador == "MiPyme"){

          this.show_success_factorizador = true;

        }else{

          this.show_success_factorizador = false;

        }

        this.saving_facturador = false;
        this.success_facturador_otro = true;

        this.success_facturador = true


        break;
        default:

        break;
      }

    } catch (bd:any) {

      this.success_facturador_otro = false;
      this.saving_facturador = false;
      
      switch (bd.status) {
        case 500:


          break;
        case 400:


          break;
        default:


        break;
      }
    }
  }

  update(){

    this.dialogRef.close(true)

    this.snackBar.open("Facturador Electrónico guardado con exito!", "Aceptar", {
      duration: 10000,
      panelClass: "simpli-snack"
    });

  }

  open_input_dialog(title:any,subtitle:any,mensajeprincipal:any, mensajenegrita:any,icon:any,coloricon:any) {
    const dialogRef = this.dialog.open(InputDialog, {
      width: '700px',
      autoFocus: false,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;

    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  // Back nuevo app cliente


  async put_facturador(){
    try {

      this.disable_btn = true;

      let value:any = {'facturador_id':this.id_facturador};
      if (this.id_facturador == 7) {
        value['facturador_otrofotro'] = this.name;
      }
      
      if (!this.id_facturador) {
        this.snackBar.open("Debe seleccionar un Facturador", "Aceptar", {
          duration: 10000,
          panelClass: "simpli-snack"
        });
        return false;
      }
      this.success_facturador = false;
      this.saving_facturador = true;
      this.saving_facturador_nubox = true;

      const bd: any = await this.settings.get_query(3,`cliente/facturador-electronico-individual/${this.id_company}/`, value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          if(this.nombre_facturador == "MiPyme"){
            this.show_success_factorizador = true;
          }else{
            this.show_success_factorizador = false;
          }
          
          this.saving_facturador = false;
          this.success_facturador_otro = true;

          this.disable_btn = false;

          
          this.update();



        break;
        default:

        break;
      }

    } catch (bd:any) {

      this.success_facturador_otro = false;
      this.saving_facturador = false;
      this.saving_facturador_nubox = false;

      this.disable_btn = false;

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  get_facturador(data:any) {

    this.mostrar_form = false;
    this.valForm.controls['otro_facturador'].reset();
    this.show_success_factorizador = false;
    this.success_facturador_otro = false;

    if(this.data.facturador != null){
      this.stateCtrl.setValue(data.facturador.name)
      this.nombre_facturador = data.facturador.name;
      this.id_facturador = data.facturador.id;
      this.success_facturador = true;
      this.show_last_step = true;


      if(data.facturador.name === "MiPyme"){
        this.show_success_factorizador = true;
      }

      if(data.facturador.id == 7){
        this.mostrar_form = true;
        this.valForm.controls['otro_facturador'].setValue(data.facturador_otro)
        this.show_success_factorizador = true;
        this.success_facturador_otro = true
      }

      if(data.facturador.id == 6){
        this.mostrar_form2 = true;
      }else{
        this.mostrar_form2 = false;
      }

    }
      

  }


  async get_facturadores(){
    try {

      this.show_spinner = true;

      const bd: any = await this.settings.get_query(1, `cliente/facturador-electronico/`, );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.facturadores = body_data.success.info;
        this.fac = body_data.success.info;
        this.filteredStates = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map(state => state ? this._filterStates(state) : this.facturadores.slice())
        );

        this.show_spinner = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }


disable_btn:boolean=false
async submitForm($ev:any, value: any){

  $ev.preventDefault();
  for (let c in this.valForm.controls) {
    this.valForm.controls[c].markAsTouched();
  }

  if (this.valForm.valid) {
    try {

      this.disable_btn=true;
      value['usuario_nubox'] = this.valForm.controls['rut_nubox'].value;
      value['nubox_key'] = this.valForm.controls['clave_nubox'].value;
      value['facturador_id']=this.id_facturador;
      this.success_facturador = false;
      this.saving_facturador = true;
      this.saving_facturador_nubox = true;
      this.disable_btn = true;
  
  
      this.success_facturador = false;
      this.saving_facturador = true;
      this.saving_facturador_nubox = true;
  
      const bd: any = await this.settings.get_query(3, `cliente/facturador-electronico-individual/${this.id_company}/`,value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
  
  
        if(this.nombre_facturador == "MiPyme"){
          this.show_success_factorizador = true;
        }else{
          this.show_success_factorizador = false;
        }
        
        this.saving_facturador = false;
        this.success_facturador_otro = true;
        
        this.update();
  
  
        break;
        default:
  
        break;
      }
  
    } catch (bd:any) {
  
      this.snackBar.open("Error", "Aceptar", {
        duration: 10000,
        panelClass: "simpli-snack"
      });
      this.success_facturador_otro = false;
      this.saving_facturador = false;
      this.saving_facturador_nubox = false;
      this.disable_btn=false;
      
      switch (bd.status) {
        case 500:
  
  
          break;
        case 400:
  
  
          break;
        default:
  
  
        break;
      }
    }
  }


}



}

export interface Facturador {
  flag: string;
  name: string;
  id: number;
}