<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top caja1-modo-responsivo" style="padding-left:15px;"> 
    <mat-form-field style="width: 100%;">
        <mat-label>Filtrar</mat-label>
        <input matInput (change)="filtrar($event)">
        <i matSuffix class="fas fa-search" style="cursor:pointer;"></i>
    </mat-form-field>
  </div>
</div>

<div class="row" *ngIf="!display_spinner" style="padding-left:15px; padding-right:15px;">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="empresas.length>0">
    <div style=width:100% *ngFor="let empresa of empresas">
      <div class="row caja_docto">
        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnasResponsive">
          <small class="texto_tabla">RUT</small>
          <br>
          <small class="texto_tabla2" style="font-weight: 600;color: #4d4d4d;">{{empresa.rut}}</small>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnasResponsive">
          <small class="texto_tabla">Cliente</small>
          <br>
          <small class="texto_tabla2" matTooltip="{{empresa.business_name}}" style="font-weight: 600;color: #4d4d4d;cursor:pointer;"><input readonly class="noinputs" value="{{empresa.business_name}}"> </small>   
        </div>

        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnasResponsive">
          <small class="texto_tabla">Fecha</small>
          <br>
          <small class="texto_tabla2" style="font-weight: 600;color: #4d4d4d;">{{empresa.fecha_alianza|date:'dd/MM/yyyy'}}</small>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnasResponsive">
          <small class="texto_tabla">Hora</small>
          <br>
          <small class="texto_tabla2" style="font-weight: 600;color: #4d4d4d;">{{empresa.fecha_alianza|date:'hh:mm'}}</small>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnasResponsive">
          <small class="texto_tabla">Monto Total Simulaciones</small>
          <br>
          <small class="texto_tabla2" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(empresa.monto_simulaciones)}}</small>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 columnasResponsive">
          <small class="texto_tabla">Monto Total Operaciones</small>
          <br>
          <small class="texto_tabla2" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(empresa.monto_operaciones)}}</small>
        </div>

      </div>

    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageCustom($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 box_placeholder"  *ngIf="empresas.length==0">
    <i class="material-icons ball"> assessment </i>
    <div style="margin-top: -20px;">No tienes empresas vinculadas.</div>
  </div>

</div>

<div class="row" *ngIf="display_spinner">
    <mat-spinner diameter="40" class="spinner-arrayan-general centerabsolute"></mat-spinner>
</div>