import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;
import { addFechaVencimiento } from '../../add-fecha-vencimiento/add-fecha-vencimiento';
import { AgregarFacturasNomina } from '../../agregar-facturas-nomina/agregar-facturas-nomina';
import { AgregarFacturas } from '../../agregar-facturas/agregar-facturas';
import { AgregarFacturasDialog } from '../../simular-facturas/agregar-facturas-dialog/agregar-facturas-dialog';
import { FacturasDelSii } from '../facturas-del-sii/facturas-del-sii';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


declare var $: any;


@Component({
  selector: 'app-add-factura-info',
  templateUrl: './add-factura-info.html',
  styleUrls: ['./add-factura-info.less']
})
export class AddFacturaInfo implements OnInit {


  @Input() id_company:any;
  @Input() parent:any;
  @Input() id_sim:any="";
  @Input() onboarding:boolean=false;


  constructor(public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, public snackBar:MatSnackBar, 
    private router:Router, public dialogRef:MatDialogRef<AddFacturaInfo> ) {



    this.id_company = localStorage.getItem('company_id');



   }


  step:any=1;

  ngOnInit() {



  }

  facturas:any[]=[];
  comenzar_simulacion:boolean=false;
  open_agregar_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '530px',
      maxWidth: '95vw',
      disableClose: true,
    });

    dialogRef.componentInstance.simulacion_id = "";
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result;
        this.parent.facturas = this.facturas;
        this.parent.comenzar_simulacion = true;

        this.dialogRef.close();
      }
    });
  }

  open_agregar_facturas_by_nomina(){
    const dialogRef = this.dialog.open(AgregarFacturasNomina,{
      width: '600px',
      maxWidth: '95vw',
      disableClose: true,
      autoFocus:false,
    });
    dialogRef.componentInstance.simulacion_id = "";
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result;

        this.parent.facturas = this.facturas;
        this.parent.comenzar_simulacion = true;

        this.dialogRef.close();
      }
    });
  }

  facturassii(){
    const dialogRef = this.dialog.open(FacturasDelSii,{
      width: '1300px',
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.id_sim = this.id_sim;
    dialogRef.componentInstance.onboarding = this.onboarding


    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.dialogRef.close(true);
      }
    });
  }

  open_agregar_facturas_dialog(){
    const dialogRef = this.dialog.open(AgregarFacturasDialog,{
      width: '600px',
      disableClose: true,
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.open_as_dialog = true;
    dialogRef.componentInstance.id_sim = this.id_sim;
    dialogRef.componentInstance.simulacion_id = "";

    dialogRef.afterClosed().subscribe((result:any) => {

      if(result.length > 0){
        const dialogRef2 = this.dialog.open(addFechaVencimiento,{
          width: '530px',
          disableClose: true,
          maxWidth: '95vw',
          autoFocus:false,
        });
    
        dialogRef2.componentInstance.facturas = this.facturas;
        dialogRef2.componentInstance.id_sim = this.id_sim;
        dialogRef2.componentInstance.onboarding = this.onboarding;
        dialogRef2.afterClosed().subscribe((result:any) => {
            if(result == true){
              this.dialogRef.close(true);
            }
        });
      }

    });
  }


}