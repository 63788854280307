import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';;
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddCta } from './add-cta';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;


@Component({
  selector: 'app-pago-seleccion',
  templateUrl: './pago-seleccion.html',
  styleUrls: ['./pago-seleccion.less']
})

export class PagoSeleccion implements OnInit {

  @Input() facturas:any;
  @Input() id_company:any;
  @Input() deuda:any;
  @Input() is_not_remuneracion: boolean = true;
  @Input() manual_ventas: boolean = false;
  
  filter: string = "";
  firstFormGroup: FormGroup;
  mostrando_dialogo_de_espera: boolean = true;
  page: Number = 1;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10];
  ELEMENT_DATA: Array<any> = [];
  displayedColumns: string[] = ['select', 'folio', 'receptoracreedor_name', 'receptoracreedor_rut', 'mnt_total', 'fecha_emision', 'fecha_vencimiento'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild('paginator2', {read: MatPaginator}) paginator2!: MatPaginator;
  
  simulation:any = {
    "anticipo": 95,
    "comision": 50000,
    "dias_promedio": 30,
    "excedentes": 0,
    "gastos": 30000,
    "interes_total": 0,
    "iva_operacion": 15200,
    "monto_recibir": 0,
    "status": 1,
    "tasa_efectiva": 2,
    "tasa_interes": 2,
    "total": 0,
    "valor_anticipo_total": 0
  };
  has_simulation: boolean = false;
  esta_todo_listo: boolean = false;
  total = 0;
  compras_agrupadas: Array<any> = [];
  compras_no_agrupadas: Array<any> = [];
  cargando: boolean = true;
  data:any[]=[]
  dataSourceCuentas = new MatTableDataSource(this.data);
  displayedColumnsCuentas: string[] = ['receptoracreedor_name', 'receptoracreedor_rut', 'total_facturas', 'banco', 'cta'];
  siguiente: boolean = true;
  displayspinner: boolean = false;
  minDate: any = new Date();
  arr_documentos_to_simulate:any[] = [];
  disabled_next: boolean = true;
  selection = new SelectionModel<any>(true, []);
  coutdown:any;
  counter = 1;
  
  constructor(
    private cd: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public settings: SettingsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PagoSeleccion>) {

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      filter: ['']
    });

  }

  ngOnInit() {
    this.facturasComprasBancos();
    this.loadSimulationInfo();
  }

  // Paso 1: Comprass
  async facturasComprasBancos() {
    try {
      this.cargando = true;
      let value = { 'facturas': this.facturas };
      const bd: any = await this.settings.get_query(2, `payments/compras-proveedores/${this.id_company}/`, value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          console.log("pago seleccion 1")
          this.compras_no_agrupadas = body_data.success.compras;
          this.compras_no_agrupadas.forEach(item => {
            item.id_bank = null;
            if (item.bancos.length > 0) {
              item.id_bank = item.bancos[0].id;
            }
          });
          this.total = this.compras_no_agrupadas.reduce((acc, cv) => acc + cv.mnt_total, 0);
          const groupByRut = this.mapDataToGroupByReceptor(this.compras_no_agrupadas);
          this.mapDataToShowInTableCtas(groupByRut);
          this.dataSourceCuentas = new MatTableDataSource(this.compras_agrupadas);
          this.dataSourceCuentas._updateChangeSubscription();
          this.dataSourceCuentas.paginator = this.paginator2;
          this.cargando = false;
          this.dialogRef.disableClose = false;
          this.setTimer();
        break;
        default:

        break;
      }
    } catch (bd:any) {
      this.settings.manage_errors(bd.status, "Facturas de compra con bancos", bd, false);
      this.cargando = false;
    }
  }
  
  createAccount(id:any, rut:any, nombre:any) {
    let dialogRef = this.dialog.open(AddCta, {
      disableClose: true
    });
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = id;
    dialogRef.componentInstance.dialog_open = true;
    dialogRef.componentInstance.rut_acreedor = rut;
    dialogRef.componentInstance.nombre_acreedor = nombre;
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  // Paso 2: Ventas y Simulación
  async calculateSimulation() {  
    try {
      let url = `payments/simulacion-automatica/${this.id_company}/`;
      const bd: any = await this.settings.get_query(2, url, {"ventas": this.arr_documentos_to_simulate});
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.simulation = body_data.success.info;
          this.disabled_next = (Number(this.simulation.monto_recibir) > this.deuda) ? false : true;
          this.has_simulation = true;
          break;
        default:

          break;
      }
    } catch (bd:any) {
      console.log("bd getre", bd)
      this.has_simulation = false;
      this.disabled_next = false;
      this.settings.manage_errors(bd.status, "Calcular simulacion", bd, false);
    }
  }

  async loadSimulationInfo() {  
    try {
      let url = `payments/simulacion-automatica/${this.id_company}/?deuda=${this.deuda}`;
      const bd: any = await this.settings.get_query(1, url);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.arr_documentos_to_simulate = body_data.success.info;
          var obj_fecha = this.getFechaDias(this.setDias(30));
          this.arr_documentos_to_simulate = this.arr_documentos_to_simulate.map(
            (item:any) => 
            ({ ...item, fecha_vencimiento: obj_fecha['fecha_vencimiento'], dias: obj_fecha["dias"] })
          );
          if (this.arr_documentos_to_simulate.length > 0) {
            this.loadDocuments();
            this.calculateSimulation();
          }
          break;
        default:

          break;
      }
    } catch (bd:any) {
      console.log("bd gegsdg", bd)
      this.has_simulation = false;
      this.settings.manage_errors(bd.status, "Calcular simulacion", bd, false);
    }
  }

  async loadDocuments() {  
    try {
      let url = `/payments/ventas-proveedores/${this.id_company}/?page=${this.page}&pageSize=${this.pageSize}&filter=${this.filter}`;
      const bd: any = await this.settings.get_query(1, url);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          this.ELEMENT_DATA = body_data.success.facturas;
          this.length = body_data.success.pager.total;
          this.selection.clear();
          this.ELEMENT_DATA.forEach((element:any) => {
            const obje:any = this.arr_documentos_to_simulate.find(
              (x:any) =>
                x.venta_id == element.venta_id
            );
            if (obje) {
              this.selection.toggle(element);
              element["fecha_vencimiento"] = obje.fecha_vencimiento;
              element["dias"] = obje.dias;
            } else {
            }
          });
          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource._updateChangeSubscription();
          });
          break;
        default:

          break;
      }
    } catch (bd:any) {

      this.settings.manage_errors(bd.status, "Get ventas", bd, false);
    }
  }

  pageCustom($e:any) {
    this.page = $e.pageIndex + 1;
    this.loadDocuments();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  activa(objeto:any) {
    const obje:any = this.arr_documentos_to_simulate.find((x:any) => x.venta_id == objeto.venta_id);
    if (obje === undefined) {
      if (objeto.fecha_vencimiento == null) this.setFecha(objeto);
      this.arr_documentos_to_simulate.push(objeto);
    } else {
      this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
    }
    this.calculateSimulation();
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach(objeto => {
        const obje:any = this.arr_documentos_to_simulate.find(
          (x:any) =>
            x.venta_id == objeto.venta_id
        );
        if (obje) {
          this.arr_documentos_to_simulate.splice(
            this.arr_documentos_to_simulate.indexOf(obje),
            1
          );
        } 
      });
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(objeto => {
        const obje = this.arr_documentos_to_simulate.find(
          (x:any) =>
            x.venta_id == objeto.venta_id
        );
        if (obje) {
          //
        } else {
          this.arr_documentos_to_simulate.push(objeto);
          if (objeto.fecha_vencimiento == null) this.setFecha(objeto);
        }
      });
      this.selection.select(...this.dataSource.data);
    }
    this.calculateSimulation();
  }

  setFecha(item:any) {
    var obj_fecha = this.getFechaDias(this.setDias(30));
    item['fecha_vencimiento'] = obj_fecha['fecha_vencimiento']
    item["dias"] = obj_fecha["dias"]
  }

  getFechaDias(fecha:any) {
    var date: any = fecha;
    let d_o_w = date.getDay();
    if (d_o_w == 0 || d_o_w == 6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7));
    let date_set = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    let diffTime = Math.abs(date - this.minDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return {
      'fecha_vencimiento': date_set,
      'dias': diffDays
    }
  }

  setDias(nro_dias:any) {
    let fecha_hoy = new Date()
    let fecha_dias = new Date()
    fecha_dias.setDate(fecha_hoy.getDate() + nro_dias);
    return fecha_dias;
  }

  recalculateFecha($e:any, row:any) {
    var date: any = new Date($e.value);
    var obj_fecha = this.getFechaDias(date);
    this.arr_documentos_to_simulate.forEach((objeto:any) => {
      if (objeto.venta_id == row.venta_id) {
        objeto["fecha_vencimiento"] = obj_fecha['fecha_vencimiento'];
        objeto["dias"] = obj_fecha["dias"];
      } 
    });
    row["fecha_vencimiento"] = obj_fecha['fecha_vencimiento'];
    row["dias"] = obj_fecha["dias"];
    this.calculateSimulation();
  }

  recalculateFechaAll($e:any) {
    var date: any = new Date($e.value);
    var obj_fecha = this.getFechaDias(date);
    this.arr_documentos_to_simulate = this.arr_documentos_to_simulate.map(
      (item:any) => 
      ({ ...item, fecha_vencimiento: obj_fecha['fecha_vencimiento'], dias: obj_fecha["dias"] })
    );
    this.dataSource.data.forEach(objeto => {
      const obje = this.arr_documentos_to_simulate.find((x:any) => x.venta_id == objeto.venta_id);
      if (obje) {
        objeto["fecha_vencimiento"] = obj_fecha['fecha_vencimiento'];
        objeto["dias"] = obj_fecha["dias"];
      } 
    });
    this.calculateSimulation();
  }

  filtrar() {
    this.filter = this.firstFormGroup.controls['filter'].value;
    if (this.filter != "") {
      this.loadDocuments();
    }
  }

  // Paso 3: Crear simulación
  async postSimulacion() {
      try {
        this.displayspinner = true;
        let url = `payments/simulacion-proveedores/${this.id_company}/`;
        const bd: any = await this.settings.get_query(2, url, {'compras': this.compras_no_agrupadas, 'ventas': this.arr_documentos_to_simulate});
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
            this.displayspinner = false;
            this.open_input_dialog("", "",
              "¡Todo listo! Pronto te contactará un ejecutivo",
              "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Proveedores", "check_circle", "#3BA46F");
            this.dialogRef.close(true);
            break;
          default:
            this.displayspinner = false;
            break;
        }
      } catch (bd:any) {
        this.displayspinner = false;
        this.settings.manage_errors(bd.status, "Calcular simulacion", bd, false);
      }
  }

  // Helpers
  reset(filter:any) {
    if (filter == "" || filter == null) {
      this.filter = "";
      this.firstFormGroup.controls['filter'].setValue("");
      this.loadDocuments();
    }
  }

  setTimer() {
    console.log("el endpoint ya cargo, dandole 5 segundos al cliente para terminar de leer");
    this.coutdown = setInterval(() => {
      console.log(this.counter + 1);
      if (this.counter == 5) {
        clearInterval(this.coutdown);
        this.mostrando_dialogo_de_espera = false;
      } else {
        this.counter++;
      }
    }, 500);
  }
  
  mapDataToGroupByReceptor(data: any[]) {
    return data.reduce((acc, cv) => {
      if (!acc[cv.ultimorutacreedor]) {
        acc[cv.ultimorutacreedor] = [];
      }
      acc[cv.ultimorutacreedor].push(cv);
      return acc;
    }, {});
  }

  mapDataToShowInTableCtas(data:any) {
    console.log(this.esta_todo_listo)
    this.compras_agrupadas = [];
    let esta_todo_listo = true;
    for (const rut in data) {
      const facturas = data[rut];
      const total_facturas = facturas.reduce((acc:any, cv:any) => acc + cv.mnt_total, 0);
      const folios = facturas.map((cv:any) => cv.folio);
      const ultimoacreedor = facturas[0].ultimoacreedor;
      const ultimorutacreedor = facturas[0].ultimorutacreedor;
      const receptoracreedor_name = facturas[0].receptoracreedor_name;
      const receptoracreedor_rut = facturas[0].receptoracreedor_rut;
      const receptoracreedor_id = facturas[0].receptoracreedor_id;
      const bancos = facturas[0].bancos;
      const id_bank = facturas[0].id_bank;
      const company_id = facturas[0].company_id;
      const obj = {
        receptoracreedor_id,
        receptoracreedor_rut,
        receptoracreedor_name,
        ultimoacreedor,
        ultimorutacreedor,
        folios,
        total_facturas,
        bancos,
        id_bank,
        facturas,
        company_id
      };
      this.compras_agrupadas.push(obj);
      if (facturas[0].bancos.length == 0) {
       esta_todo_listo = false;
      }
    }
    this.esta_todo_listo = esta_todo_listo;

  }

  asignarBanco(ultimorutacreedor:any, id_bank:any, field = 'ultimorutacreedor') {
    let ready = 0;
    id_bank = Number(id_bank);
    this.compras_no_agrupadas.forEach(item => {
      if (ultimorutacreedor == item[field]) {
        item.id_bank = id_bank;
        ready = ready + 1;
      }
    });
    this.esta_todo_listo = ready == this.compras_no_agrupadas.length ? true : false; 
  }

  formatMoney = (amount:any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  };

  open_input_dialog(title:any, subtitle:any, mensajeprincipal:any, mensajenegrita:any, icon:any, coloricon:any) {
    const dialogRef = this.dialog.open(InputDialog, {
      width: '700px',
      autoFocus:false,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  volver() {
    this.reset("");
  }

  // Remuneraciones
  facturasComprasBancos_remuneraciones() {
    this.cargando = true;
    let suma = 0;
    for (let i = 0; i < this.facturas.length; i++) {
      suma = suma + this.facturas[i].amount;
      this.siguiente = false;
    }
    this.total = suma;
    this.cargando = false;
    this.dialogRef.disableClose = false;
    //this.mostrando_dialogo_de_espera = false;
    this.setTimer();
  }

  async crear_operacion_remuneraciones() {
    this.displayspinner = true;
    let value = { 'remuneraciones': this.facturas, 'simulation': this.simulation, 'ventas': this.arr_documentos_to_simulate };
    let resultado: any = (await this.settings.get_query(2, 'pagoRemuneraciones/' + this.id_company + '/', value));
    let body_data = resultado._body;
    if (resultado) {
      this.displayspinner = false;
      this.open_input_dialog("", "",
        "¡Todo listo! Pronto te contactará un ejecutivo",
        "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Remuneraciones", "check_circle", "#3BA46F");
      this.dialogRef.close();
    }
  }

}