import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditarFacturasVentaComponent } from 'src/app/home/operaciones-proveedores/editar-facturas-venta/editar-facturas-venta.component';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-simulacion',
  templateUrl: './simulacion.component.html',
  styleUrls: ['./simulacion.component.less']
})
export class SimulacionComponent {

  @Input() id:any;
  @Input() done:any;
  @Input() clicked:any;
  @Input() change_giro:any;
  simulation_id:any;
  is_not_done:any;
  id_company:any;
  ventas:any;
  facturas:any;
  tipo = 0;
  simulation_venta={'anticipo':0,'vencimiento':0,'comision':0,'gastosOperacionales':0,'iva_operaciones':0,'interesTotal':0,'liquidoRecibir':0, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':0.95,'tasa_interes':0, 'gastos':0};
  anticipo=95;
  comision=0;
  dias_promedio=30;
  gastos=0;
  interes_total=0;
  iva_operacion=0;
  monto_recibir=0;
  tasa_interes=0;
  total=0;
  valor_anticipo_total=0;
  cargando:boolean=true;
  deuda_total=0;
  por_pagar=0;
  debe_pagar:boolean=false;
  porc_anticipo=0;
  tasa_int=0.02;
  gastos_operacionales=30000;

  constructor(
    private dialog: MatDialog,
    public settings: SettingsService,
    public snackBar: MatSnackBar) { 

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'clicked': 
            const click = JSON.stringify(changes[propName].currentValue);   
            if (click === 'true') {     
              this.simulation_id = this.id;
              this.is_not_done = this.done;
              this.get_simulation()  
            }
          break;
          case 'change_giro': 
            this.get_simulation();
          break;
        }
      }
    }
  }

  formatMoney(amount:any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
  }

  load_all()
  {
    this.id_company = localStorage.getItem("company_id");
    this.get_simulation()
  }

  async get_simulation(){
    this.cargando = true;
    let resultado: any = (await this.settings.get_query(1, 'controldtedetailsimulation/'+this.simulation_id +'/?tipo='+this.tipo+'&accion=1'));
    let body_data = resultado._body;
    if (resultado) {
      this.anticipo= body_data.simulation.anticipo;
        this.comision=body_data.simulation.comision;
        this.dias_promedio=body_data.simulation.dias_promedio;
        this.gastos=body_data.simulation.gastos;
        this.interes_total=body_data.simulation.interes_total;
        this.iva_operacion=body_data.simulation.iva_operacion;
        this.monto_recibir=body_data.simulation.monto_recibir;
        this.tasa_interes=body_data.simulation.tasa_interes;
        this.total=body_data.simulation.total;
        this.valor_anticipo_total=body_data.simulation.valor_anticipo_total;
        this.deuda_total = body_data.total_compras;
        this.porc_anticipo = (body_data.simulation.anticipo / 100);
        this.tasa_int = (body_data.simulation.tasa_interes / 100);
        this.gastos_operacionales = body_data.simulation.gastos;


        this.simulation_venta={'anticipo':this.valor_anticipo_total,'vencimiento':this.dias_promedio,'comision':this.comision,'gastosOperacionales':this.gastos,
        'iva_operaciones':this.iva_operacion,'interesTotal':this.interes_total,'liquidoRecibir':this.monto_recibir, 'porPagar':false,'porPagarMonto':0,'porc_anticipo':this.porc_anticipo,
        'tasa_interes':this.tasa_int, 'gastos':this.gastos_operacionales};

        this.ventas = body_data.ventas;
        this.facturas = body_data.facturas;

        if(Number(this.monto_recibir) > this.deuda_total){
          this.debe_pagar = true;
          this.por_pagar = Number(this.monto_recibir) - this.deuda_total;
        }else{
          this.por_pagar = this.deuda_total - Number(this.monto_recibir);
          this.debe_pagar = false;
        }

        this.cargando = false;

    }
  } 

  open_ventas(){
    const dialogRef = this.dialog.open(EditarFacturasVentaComponent, {
      width:'1300px',
      disableClose: true,
    });
    let total = this.facturas.reduce((acc:any, cv:any) => Number(cv.mnt_total) + acc , 0);
    dialogRef.componentInstance.id_simulation = this.simulation_id;
    dialogRef.componentInstance.deuda = 0;
    dialogRef.componentInstance.deuda_total = total;
    dialogRef.componentInstance.simulacion_recibida = this.simulation_venta;
    dialogRef.componentInstance.facturas_venta = this.ventas;
    dialogRef.componentInstance.facturas = this.facturas;

    dialogRef.afterClosed().subscribe((result:any) => {
      // if(result==true){
        this.load_all()
      // }
    });
  }

  async eliminar_venta(id_simulacion:any,id_factura:any,index:any) { 
    let resultado: any = (await this.settings.get_query(4, 'RecalculateVentas/' + id_simulacion + '/' + id_factura + '/'));
    let body_data = resultado._body;
    if (resultado) {
      console.log(body_data)
      if (body_data.error != null) {          
        this.snackBar.open(body_data.error, "Aceptar", {
          duration: 7000,
          panelClass: ['JP-snackbar']
        });
        return false;
      } 
      this.load_all() 
    }    
  }

}

export interface FacturasVenta {  
  id:any,
  folio: any,
  receptoracreedor_name:any,
  receptoracreedor_rut:any,
  receptoracreedor_id:any,
  mnt_total:any,
  fecha_emision:any,
  estado:any,
  validBanco:any,
  id_bank:any,
  ultimorutacreedor:any,
  ultimoacreedor:any,
  fecha_vencimiento:any,
  simulado:any
}