
import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';;
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
declare var $: any;
import { SettingsService } from 'src/app/settings/settings.service';;
import { SelectionModel } from '@angular/cdk/collections';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { PagoSeleccion } from '../../../pago-seleccion/pago-seleccion';
import { AddCta } from '../../../pago-seleccion/add-cta';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-simulacion-remuneraciones',
  templateUrl: './simulacion-remuneraciones.component.html',
  styleUrls: ['./simulacion-remuneraciones.component.less']
})
export class SimulacionRemuneracionesComponent implements OnInit {

  @Input() facturas: any;
  @Input() id_company: any;
  @Input() deuda: any;
  @Input() is_not_remuneracion: boolean = true;

  filter: string = "";

  ELEMENT_DATA: Array<any> = [];
  displayedColumns: string[] = ['select', 'folio', 'receptoracreedor_name', 'receptoracreedor_rut', 'mnt_total', 'fecha_emision', 'fecha_vencimiento'];

  dataSource = new MatTableDataSource<Facturas>(this.ELEMENT_DATA);

  @ViewChild('paginator2', { read: MatPaginator }) paginator2!: MatPaginator;
  // @ViewChild('MatPaginator3') paginator3: MatPaginator;

  firstFormGroup: FormGroup;
  secondFormGroup!: FormGroup;

  @Input() manual_ventas: boolean = false;

  mostrando_dialogo_de_espera: boolean = true;

  constructor(
    public snackBar: MatSnackBar,
    public settings: SettingsService, private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PagoSeleccion>) {

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: [null, Validators.required],
      filter: ['']
    });

  }

  initComponent() {
    this.get_facturas_venta();
    this.scrapping();
    this.recalculate_change();
  }

  ngOnInit() {
    this.initComponent();
  }

  page: Number = 1;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10];
  cargando: boolean = true;
  esta_todo_listo: boolean = false;
  total = 0;
  agregar_vencimiento: boolean = false;
  simulation = { 'acumuladorFacturas': [], 'anticipo': 0, 'vencimiento': 0, 'comision': 0, 'gastosOperacionales': 0, 'iva_operaciones': 0, 'interesTotal': 0, 'liquidoRecibir': 0, 'porPagar': false, 'porPagarMonto': 0 };
  has_simulation: boolean = false;
  por_pagar = 0;
  debe_pagar: boolean = false;
  operacionAnticipo = 0;
  gastosOperacionales = 0;
  tasa_porc = 0;
  anticipo_porc = 0;
  vencimiento = 30;
  recalcular: boolean = true;
  async get_facturas_venta(filter?: any) {
    let resultado: any = (await this.settings.get_query(1, 'PagoproveedoresSimulation/' + this.id_company + '/?pageNumber=' + this.page + '&deuda=' + this.deuda + '&filter=' + this.filter));
    let body_data = resultado._body;
    if (resultado) {
      this.has_simulation = body_data.simulationSuccess;
      this.ELEMENT_DATA = body_data.ventas;
      if (filter != "" && filter != null) {
        this.has_simulation = true;
      }
      let array: Array<any> = [];
      if (this.recalcular == true) {
        this.simulation = body_data.simulation;
        this.operacionAnticipo = body_data.simulation.operacionAnticipo;
        this.gastosOperacionales = Number(body_data.simulation.gastosOperacionales);
        this.anticipo_porc = Number(body_data.simulation.anticipo_porc);
        this.tasa_porc = Number(body_data.simulation.tasa_porc);
        this.vencimiento = Math.ceil(body_data.simulation.vencimiento);
        this.por_pagar = body_data.simulation.porPagarMonto;
        this.debe_pagar = body_data.simulation.porPagar;
        body_data.simulation.acumuladorFacturas = body_data.simulation.acumuladorFacturas.map((factura: any) => {
          return {
            ...factura,
            simulado: true
          };
        });
        body_data.simulation.acumuladorFacturas.forEach((item: any) => {
          if (array.includes(item.id)) {
            // 
          } else {
            array.push(item.id);
            this.activa(item.id, item);
          }
        });
      } else {
        this.arr_documentos_to_simulate.forEach((item: any) => {
          array.push(item.id);
          this.activa(item.id, item);
        });
      }

      // this.fecha_todas_simulation(date_hoy)
      for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
        if (array.includes(this.ELEMENT_DATA[i].id)) {
        } else {
          this.ELEMENT_DATA[i].simulado = false;
        }
        for (let l = 0; l < this.arr_documentos_to_simulate.length; l++) {
          if (this.arr_documentos_to_simulate[l].id == this.ELEMENT_DATA[i].id && this.arr_documentos_to_simulate[l].simulado == true) {
            this.ELEMENT_DATA[i].simulado = true;
            this.ELEMENT_DATA[i].fecha_vencimiento = this.arr_documentos_to_simulate[l].fecha_vencimiento;
          }
        }
      }
      this.length = body_data.totalVentas;
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource._updateChangeSubscription();
      });
      this.recalcular = false;
    }
  }

  loading_scrapping: boolean = true;
  async scrapping() {
    this.loading_scrapping = true;
    let resultado: any = (await this.settings.get_query(1, 'get-libro-confirming/' + this.id_company + '/'));
    let body_data = resultado._body;
    if (resultado) {
      this.loading_scrapping = false;
      if (this.is_not_remuneracion) {
        this.post_facturas();
      } else {
        this.post_facturas_remuneraciones();
      }
    }
  }

  compras_agrupadas: Array<any> = [];

  compras_no_agrupadas: Facturas2[] = [];
  dataSourceFinalizarStep = new MatTableDataSource<any>([]);
  dataSource3 = new MatTableDataSource<Facturas2>(this.compras_no_agrupadas);
  displayedColumns2: string[] = ['cta', 'folio', 'receptoracreedor_name', 'receptoracreedor_rut', 'mnt_total', 'fecha_emision'];
  displayedColumns3: string[] = ['cta', 'receptoracreedor_name', 'receptoracreedor_rut', 'estado'];
  displayedColumnsFinalizarStep: string[] = ['receptoracreedor_name', 'receptoracreedor_rut', 'total_facturas', 'banco', 'cta'];
  siguiente: boolean = true;
  async post_facturas() {
    this.cargando = true;
    let value = { 'facturas': this.facturas };
    let resultado: any = (await this.settings.get_query(2, 'EmitirPagoConfirmigAcreedores/' + this.id_company + '/?procesado=0', value));
    let body_data = resultado._body;
    if (resultado) {
      this.compras_no_agrupadas = body_data.facturas;
      this.controlar_cuentas();
      let suma = 0;
      let valid: boolean = false;
      for (let i = 0; i < body_data.facturas.length; i++) {
        suma = suma + body_data.facturas[i].mnt_total;
        if (body_data.facturas[i].validBanco == true) {
          this.desactivar_siguiente = false;
          valid = true;
          this.siguiente = false;
        } else {
          this.desactivar_siguiente = true;
          valid = false;
          this.siguiente = true;
        }
      }
      this.total = suma;
      var distinc = [], output = [], l = this.compras_no_agrupadas.length, i;
      for (i = 0; i < l; i++) {
        if (distinc[this.compras_no_agrupadas[i].ultimorutacreedor]) continue;
        distinc[this.compras_no_agrupadas[i].ultimorutacreedor] = true;
        output.push(this.compras_no_agrupadas[i]);
      }

      const groupByRut = this.mapDataToGroupByReceptor(this.compras_no_agrupadas);
      this.mapDataToShowInTableCtas(groupByRut);

      // setTimeout(() => {
      // this.dataSourceFinalizarStep=new MatTableDataSource(this.compras_no_agrupadas);
      this.dataSourceFinalizarStep = new MatTableDataSource(this.compras_agrupadas);
      this.dataSourceFinalizarStep._updateChangeSubscription();
      this.dataSourceFinalizarStep.paginator = this.paginator2;

      // this.dataSource3 = new MatTableDataSource(output);
      // this.dataSource3._updateChangeSubscription();
      // this.dataSource3.paginator = this.paginator3;

      // });
      this.cargando = false;
      this.dialogRef.disableClose = false;
      //this.mostrando_dialogo_de_espera = false;
      this.set_timer();
    } else {
      this.cargando = false;
    }
  }

  mapDataToGroupByReceptor(data: any[]) {
    return data.reduce((acc, cv) => {
      if (!acc[cv.receptoracreedor_rut]) {
        acc[cv.receptoracreedor_rut] = [];
      }
      acc[cv.receptoracreedor_rut].push(cv);
      return acc;
    }, {});
  }

  mapDataToShowInTableCtas(data: any) {
    this.compras_agrupadas = [];
    let esta_todo_listo = true;
    for (const rut in data) {
      const facturas = data[rut];

      const total_facturas = facturas.reduce((acc: any, cv: any) => acc + cv.mnt_total, 0);
      const folios = facturas.map((cv: any) => cv.folio);
      const receptoracreedor_name = facturas[0].receptoracreedor_name;
      const receptoracreedor_rut = facturas[0].receptoracreedor_rut;
      const receptoracreedor_id = facturas[0].receptoracreedor_id;
      const bancos = facturas[0].bancos;
      const validBanco = facturas[0].validBanco;
      const id_bank = facturas[0].id_bank;
      const company_id = facturas[0].company_id;

      const obj = {
        receptoracreedor_id,
        receptoracreedor_name,
        receptoracreedor_rut,
        folios,
        total_facturas,
        bancos,
        facturas,
        validBanco,
        id_bank,
        company_id
      };
      this.compras_agrupadas.push(obj);
      if (!facturas[0].validBanco) {
        esta_todo_listo = false;
      }
    }
    this.esta_todo_listo = esta_todo_listo;
  }

  displayspinner: boolean = false;
  mostrar_descargar: boolean = false;
  url_csv: any;
  async download_csv() {
    try {
      let value = { 'facturas': this.compras_no_agrupadas, 'simulation': this.simulation, 'ventas': this.arr_documentos_to_simulate, 'rol': 0 };

      const bd: any = await this.settings.get_query(2, 'EmitirPagoConfirmigAcreedores/' + this.id_company + '/?procesado=1', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          this.displayspinner = false;


          this.open_input_dialog("Información", "La simulación fue creada exitosamente",
            "¡Todo listo! Pronto te contactará un Ejecutivo",
            "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Remuneraciones", "check_circle", "#3BA46F");


          this.dialogRef.close(true);

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  formatMoney = (amount: any, decimalCount = 2, decimal = ',', thousands = '.') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  };

  create_account(id: any, rut: any, nombre: any) {
    let dialogRef = this.dialog.open(AddCta, {
      disableClose: true
    });
    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_company = id;
    dialogRef.componentInstance.dialog_open = true;
    dialogRef.componentInstance.rut_acreedor = rut;
    dialogRef.componentInstance.nombre_acreedor = nombre;
    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  seleccionar_banco(banco: any, ultimorutacreedor: any) {
    let id_bank = Number(banco);
    console.log(id_bank, ultimorutacreedor);
    this.asignar_banco(ultimorutacreedor, id_bank, this.compras_no_agrupadas);
  }


  bloquear_siguiente: boolean = true;
  asignar_banco(ultimorutacreedor: any, id_bank: any, data: any, field = 'ultimorutacreedor') {
    this.compras_no_agrupadas.forEach((item: any) => {
      if (ultimorutacreedor == item[field]) {
        item.id_bank = id_bank;
      }
    });
    for (let i = 0; i < this.compras_no_agrupadas.length; i++) {
      if (this.compras_no_agrupadas[i].validBanco == true && this.compras_no_agrupadas[i].id_bank != null) {
        this.esta_todo_listo = true;
      } else {
        this.esta_todo_listo = false;
        break;
      }
    }
    console.log(this.compras_no_agrupadas);
  }

  controlar_cuentas() {
    this.compras_no_agrupadas.forEach((item: any) => {
      if (item.bancos.length > 0) {
        let id_bank = item.bancos[0].id;
        let ultimorutacreedor = item.ultimorutacreedor;
        this.asignar_banco(ultimorutacreedor, id_bank, this.compras_no_agrupadas);
      }

    });
  }

  desactivar_siguiente: boolean = true;
  check_bank(data: any) {
    let valid: boolean = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id_bank != null) {
        this.desactivar_siguiente = false;
        valid = true;
        this.siguiente = false;
      } else {
        this.desactivar_siguiente = true;
        valid = false;
        this.siguiente = true;
        break;
      }
    }
  }


  siguiente_paso: boolean = true;
  check_vencimiento() {
    for (let i = 0; i < this.compras_no_agrupadas.length; i++) {
      if (this.compras_no_agrupadas[i].fecha_vencimiento != null && this.compras_no_agrupadas[i].fecha_vencimiento != undefined) {
        this.siguiente_paso = false;
      } else {
        this.siguiente_paso = true;
        break;
      }
    }
  }

  minDate: any = new Date();
  set_fecha_30(value: any, item: any) {
    var date = value;
    let d_o_w = date.getDay();
    if (d_o_w == 0 || d_o_w == 6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7));
    let date_set = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    const diffTime = Math.abs(date - this.minDate);
    let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    diffDays = diffDays;
    item['fecha_vencimiento'] = date_set;
    item["dias"] = diffDays;
  }

  recalculate_fecha($e: any, index: any) {
    var date = $e.value;
    let d_o_w = date.getDay();
    if (d_o_w == 0 || d_o_w == 6)
      date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7));
    let date_set = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    const diffTime = Math.abs(date - this.minDate);
    let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    diffDays = diffDays;
    if (diffDays >= 30) {
      if (index === true) {
        this.arr_documentos_to_simulate = this.arr_documentos_to_simulate.map((item: any) => ({ ...item, fecha_vencimiento: date_set, dias: diffDays }));
        this.ELEMENT_DATA.forEach((item: any) => {
          if (item.simulado == true) {
            item["fecha_vencimiento"] = date_set;
            item["dias"] = diffDays;
          }
        });
      } else {
        for (let j = 0; j < this.arr_documentos_to_simulate.length; j++) {
          if (this.arr_documentos_to_simulate[j].id == this.ELEMENT_DATA[index].id) {
            this.arr_documentos_to_simulate[j]["fecha_vencimiento"] = date_set;
            this.arr_documentos_to_simulate[j]["dias"] = diffDays;
          }
        }
        this.ELEMENT_DATA[index]["fecha_vencimiento"] = date_set;
        this.ELEMENT_DATA[index]["dias"] = diffDays;
      }
    } else {
      // this.snackBar.open("La fecha debe tener mínimo 30 días de vencimiento", "Aceptar", {
      //   duration: 5000,
      //   panelClass: ['JP-snackbar']
      // });
      this.open_input_dialog("Error", "", "¡Oops! La simulación debe tener un plazo mínimo de 30 días", "", "info", "#FA454C");
      return false;
    }
    this.recalculate_change();
  }

  recalculate_change() {
    this.calcular_dias_diferencia();
    let acumuladorVentas = 0;
    let comision = 50000;
    let iva_operaciones = 0;
    let interesTotal = 0;
    let liquidoRecibir = 0;
    for (let i in this.arr_documentos_to_simulate) {
      acumuladorVentas = acumuladorVentas + this.arr_documentos_to_simulate[i].mnt_total;
      comision = 0.005 * (acumuladorVentas * this.operacionAnticipo);
      if (comision < 50000) {
        comision = 50000;
      }
      iva_operaciones = (this.gastosOperacionales + comision) * 0.19;
      interesTotal = acumuladorVentas * this.anticipo_porc * this.tasa_porc / 30 * this.vencimiento;
      liquidoRecibir = (acumuladorVentas * this.operacionAnticipo) - comision - this.gastosOperacionales - iva_operaciones - interesTotal;
    }
    if (Number(liquidoRecibir) > this.deuda) {
      this.debe_pagar = true;
      this.por_pagar = Number(liquidoRecibir) - this.deuda;
      this.disabled_next = false;
    } else {
      this.por_pagar = this.deuda - Number(liquidoRecibir);
      this.debe_pagar = false;
      this.disabled_next = true;
    }
    let valorAnticipo = Number(acumuladorVentas * this.operacionAnticipo);

    this.simulation = {
      'acumuladorFacturas': [],
      'anticipo': valorAnticipo, 'vencimiento': this.vencimiento, 'comision': comision,
      'gastosOperacionales': this.gastosOperacionales, 'iva_operaciones': iva_operaciones,
      'interesTotal': interesTotal, 'liquidoRecibir': Number(liquidoRecibir),
      'porPagar': this.debe_pagar, 'porPagarMonto': this.por_pagar
    };
  }

  calcular_dias_diferencia() {
    let total_dias_diferencia = 0;
    for (let i = 0; i < this.arr_documentos_to_simulate.length; i++) {
      if (this.arr_documentos_to_simulate[i].fecha_vencimiento == null || this.arr_documentos_to_simulate[i].fecha_vencimiento == "") {
        let fecha = new Date();
        fecha.setDate(fecha.getDate() + 30);
        this.set_fecha_30(fecha, this.arr_documentos_to_simulate[i]);
      }
      let split_fecha = this.arr_documentos_to_simulate[i].fecha_vencimiento.split("-");
      let mes = (split_fecha[1] - 1);
      if (mes == -1) {
        mes = 0;
      }
      let date_vencimiento = new Date(split_fecha[2], mes, split_fecha[0]);
      let date: any;
      date = date_vencimiento;
      let d_o_w = date.getDay();
      if (d_o_w == 0 || d_o_w == 6)
        date = new Date(date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7));
      const diffTime = Math.abs(date - this.minDate);
      let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      diffDays = diffDays;
      this.arr_documentos_to_simulate[i].dias = diffDays;
      total_dias_diferencia = total_dias_diferencia + this.arr_documentos_to_simulate[i].dias;
    }
    if (this.arr_documentos_to_simulate.length == 0) {
      this.vencimiento = 30;
    } else {
      this.vencimiento = Math.floor(total_dias_diferencia / this.arr_documentos_to_simulate.length);
    }
  }

  pageCustom($e: any) {
    this.page = $e.pageIndex + 1;
    this.get_facturas_venta();
  }

  arr_documentos_to_simulate: Array<any> = [];
  arr_simulation: Array<any> = [];
  disabled_enviar: boolean = true;
  total_total = 0;
  disabled_next: boolean = true;
  activa(id: any, objeto: any, evt?: any) {

    if (evt != undefined) {
      objeto.simulado = evt.target.checked;
    }
    const obje = this.arr_documentos_to_simulate.find(x => x.id == objeto.id);
    if (obje === undefined) {
      this.arr_documentos_to_simulate.push(objeto);
    } else {
      if (objeto.simulado == false) {
        this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
      }
    }
    if (this.arr_simulation.includes(id)) {
      this.arr_simulation.splice(this.arr_simulation.indexOf(id), 1);
    } else {
      this.arr_simulation.push(id);
    }
    this.recalculate_change();
  }

  selection = new SelectionModel<Facturas>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event: any) {
    !$event.checked ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Facturas): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  filtrar() {
    this.filter = this.firstFormGroup.controls['filter'].value;
    if (this.filter != "") {
      this.get_facturas_venta(true);
    }
  }

  reset(filter: any) {
    if (filter == "" || filter == null) {
      this.filter = "";
      this.firstFormGroup.controls['filter'].setValue("");
      this.get_facturas_venta(true);
    }
  }

  volver() {
    this.reset("");
  }

  open_input_dialog(title: any, subtitle: any, mensajeprincipal: any, mensajenegrita: any, icon: any, coloricon: any) {

    const dialogRef = this.dialog.open(InputDialog, {
      width: '700px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;


    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  post_facturas_remuneraciones() {
    this.cargando = true;
    let suma = 0;
    let valid: boolean = false;
    for (let i = 0; i < this.facturas.length; i++) {
      suma = suma + this.facturas[i].amount;
      this.desactivar_siguiente = false;
      valid = true;
      this.siguiente = false;
    }
    this.total = suma;
    this.cargando = false;
    this.dialogRef.disableClose = false;
    //this.mostrando_dialogo_de_espera = false;
    this.set_timer();
  }

  async crear_operacion_remuneraciones() {
    this.displayspinner = true;

    let value = { 'remuneraciones': this.facturas, 'simulation': this.simulation, 'ventas': this.arr_documentos_to_simulate };

    let resultado: any = (await this.settings.get_query(2, 'pagoRemuneraciones/' + this.id_company + '/', value));
    let body_data = resultado._body;
    if (resultado) {
      this.displayspinner = false;
      this.open_input_dialog("Información", "La simulación fue creada exitosamente",
        "¡Todo listo! Pronto te contactará un Ejecutivo",
        "Puedes ver el detalle de tu simulación en la pantalla de Operaciones de Remuneraciones", "check_circle", "#3BA46F");
      this.dialogRef.close();
    }

  }

  coutdown: any;
  counter = 1;
  set_timer() {
    console.log("el endpoint ya cargo, dandole 5 segundos al cliente para terminar de leer");
    this.coutdown = setInterval(() => {
      console.log(this.counter + 1);
      this.startTime();
    }, 500);
  }

  startTime() {
    if (this.counter == 5) {
      clearInterval(this.coutdown);
      this.mostrando_dialogo_de_espera = false;
    } else {
      this.counter++;
    }
  }


  async sincronizar() {
    try {
      const body_data: any = await this.settings.get_query(2, `sii-empresa/${this.id_company}/`);
      const _body = JSON.stringify(body_data._body);
      this.open_input_dialog("Sincronización", "Sincronización Exitosa",
        "¡Todo listo! Nos hemos sincronizado con el SII",
        "Hemos actualizado los ultimos 6 meses con tus libros de compras, ventas y notas de credito!", "sync", "#3BA46F");
      this.initComponent();
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

}

export interface Facturas {
  id: any,
  folio: any,
  receptoracreedor_name: any,
  receptoracreedor_rut: any,
  receptoracreedor_id: any,
  mnt_total: any,
  fecha_emision: any,
  estado: any,
  validBanco: any,
  id_bank: any,
  ultimorutacreedor: any,
  ultimoacreedor: any,
  fecha_vencimiento: any,
  simulado: any;
}

export interface Facturas2 {
  id?: any,
  folio: any,
  receptoracreedor_name: any,
  receptoracreedor_rut: any,
  receptoracreedor_id: any,
  mnt_total?: any,
  fecha_emision: any,
  estado?: any,
  validBanco: any,
  id_bank: any,
  ultimorutacreedor: any,
  ultimoacreedor: any,
  fecha_vencimiento: any,
  simulado: any,
  bancos: any;
}

export interface VISTAPREVIA2 {
  id: any,
  folio: any,
  receptoracreedor_name: any,
  receptoracreedor_rut: any,
  mnt_total: any,
  fecha_emision: any,
  estado: any,
  fecha_vencimiento: any,
  simulado: any,
}
