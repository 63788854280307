import { Component, Input, OnInit } from '@angular/core';
import { IMessage, INotification, WebSocketService,} from 'src/app/services/websocket.service';
import { SettingsService } from 'src/app/settings/settings.service';;
import { ChatClienteComponent } from '../../chat-cliente/chat-app.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.less'],
  providers: [],
})
export class ChatBubbleComponent implements OnInit {
  @Input() id_company: any;
  @Input() id_user: any;
  received: any[] = [];
  total: any = 0;
  disable: boolean = false;
  notifications: INotification[] = [];

  constructor(
    public dialog: MatDialog,
    private ws: WebSocketService,
    public settings: SettingsService
  ) {
    this.id_user = localStorage.getItem('user_id');
    this.id_company = localStorage.getItem('company_id');
  }

  is_responsive:boolean=false;
  ngOnInit(): void {


    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.get_user_notifications();
    this.getInfoCompany()

    this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'message':
          if(msg.args.data.from !== `USER#${this.id_user}`) {
            this.total += 1;
            const newNotif = {
              room_id: msg.args.roomId,
              date_created: msg.args.dateCreated,
              delivery: msg.args.delivery,
            };
            this.notifications = [newNotif, ...this.notifications];
          }
          break;
        default:
          break;
      }
    });
  }

  async get_user_notifications() {
    try {
      const bd: any = await this.settings.get_query(
        1,
        `ws/chat/get-notifications/`
      );
      const body_data: any =bd._body;
      const data = body_data.success;
      this.total = data.notifications.length;
      this.notifications = data.notifications;
    } catch (bd: any) {
      throw new Error(`ERROR: ${bd}`);
    }
  }

  exe_id: number = 0;
  async getInfoCompany() {

    try {
      const company_id = window.localStorage.getItem('company_id') || ''
      const bd: any = await this.settings.get_query(1, `/company/${company_id}/`);
      var body_data: any =bd._body;
      var data = body_data;
      this.exe_id = data.company.executive.id
      window.localStorage.setItem('avatar_executive',`${SettingsService.API_ENDPOINT}/${data.company.executive.avatar}`)
      window.localStorage.setItem('exe_id',`${this.exe_id}`)
    } catch (bd: any) {
      // this.settings.manage_errors(bd.status, '', bd, false);
    }
  }

  openChat() {
    if(this.is_responsive == true){
      const dialogRef = this.dialog.open(ChatClienteComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'chat-ejecutivo-cliente-responsive',
        autoFocus: false,
        disableClose: false,
      });
      dialogRef.componentInstance.parent = this;
      dialogRef.componentInstance.id_user = window.localStorage.getItem('user_id') || '';

    }else{
      const dialogRef = this.dialog.open(ChatClienteComponent, {
        width: '400px',
        minHeight: 'calc(100vh - 90px)',
        height : 'auto',
        position: { right: '10px', top: '10px' },
        hasBackdrop: false,
        panelClass: 'chat-ejecutivo-cliente',
        autoFocus: false,
        disableClose: false,
      });
      dialogRef.componentInstance.parent = this;
      dialogRef.componentInstance.id_user = window.localStorage.getItem('user_id') || '';
    }
  }

}
