import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UrlService } from '../services/url.service';
declare var $ :any;

@Component({
  selector: 'app-puente-cliente',
  templateUrl: './puente-cliente.component.html',
  styleUrls: ['./puente-cliente.component.less']
})
export class PuenteClienteComponent implements OnInit {

  role:any;


  constructor(public snackBar: MatSnackBar,private router: Router, public settings: SettingsService, private urlService: UrlService)
   {

    }

  ngOnInit() {
    this.role=localStorage.getItem("role");
    this.get_current_user()
  }

  async set_storage_detail(company_id:any, portal: any) {
    try {
      const bd: any = await this.settings.get_query(1, "clientcartera/"+company_id+"/");
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        if ( body_data.datos != null)
        {
          localStorage.setItem("rut", body_data.datos.rut )
          localStorage.setItem("razon_social", body_data.datos.nombre)
          localStorage.setItem("fecha", body_data.datos.date)
          localStorage.setItem("company_id", company_id)
        }

        const originalUrl = this.urlService.getOriginalUrl();


        if (portal == "cliente") {
          if(originalUrl == ''){
            this.router.navigate(['home']);
          }else{
            this.router.navigateByUrl(originalUrl);
          }

        } else {
          if(originalUrl == ''){
            this.router.navigate(['home/confirming']);
          }else{
            this.router.navigateByUrl(originalUrl);
          }
        }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


  async load_companies(portal="cliente") {
    try {
      const bd: any = await this.settings.get_query(1, 'clientrelatedcompany/?portal='+portal);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        let company_id = ""

        if (body_data.companies.length > 0)
        {

          let id=body_data.companies[0].id;

          if (id != null)
            this.set_storage_detail(id, portal)

        }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  enterClient(){

    localStorage.setItem("role", "clie");

    setTimeout(()=>{ 
      this.load_companies("cliente")
    });
  }

  enterConfirming(){
    
    localStorage.setItem("role", "conf");

    setTimeout(()=>{ 
      this.load_companies("confirming")
    });

  }

  enterProntoPago(){
    
    localStorage.setItem("role", "prpa");

    setTimeout(()=>{ 
      this.load_companies("confirming")
    });

  }


  rol_cli=false
  rol_conf=false
  rol_prpa=false;
  async get_current_user() {
    try {
      const bd: any = await this.settings.get_query(1, 'current-user/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        let roles=body_data.user.roles
        console.log(roles)

        for(let i=0; roles.length>i; i++){
         
          if(roles[i].code=="clie"){
            this.rol_cli=true
           
          }else if(roles[i].code=="conf"){
            this.rol_conf=true
          }else if (roles[i].code=="ecom" || roles[i].code=="eneg"){
           this.rol_cli=true
           this.rol_conf=true
          }else if(roles[i].code == "prpa"){
            this.rol_prpa = true;
          }
        }

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


}
