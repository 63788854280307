import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { register } from 'swiper/element/bundle';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "src/app/settings/settings.service";
import { InfoModalOnboarding } from "../info-modal/info-modal";
import { MatTableDataSource } from "@angular/material/table";
import { AgregarFacturas } from "src/app/home/cliente-new-version/agregar-facturas/agregar-facturas";
import { DetalleFacturas } from "src/app/home/cliente-new-version/detalle-facturas/detalle-facturas";
import { AlertDelete } from "src/app/home/cliente-new-version/documentacion/alert-delete/alert-delete";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AddFacturaInfo } from "src/app/home/cliente-new-version/cesion-facturador/add-factura-info/add-factura-info";

register();

declare var $: any;

@Component({
  selector: 'app-simulacion',
  templateUrl: './simulacion.html',
  styleUrls: ['./simulacion.less']
})
export class SimulacionOnboarding implements OnInit {


  business_name:any=localStorage.getItem('business_name');
  id_simulation:any;

  constructor(public settings: SettingsService, public dialog: MatDialog, public router:Router, private route:ActivatedRoute, private snackBar:MatSnackBar ) { 
    
    this.route.params.subscribe((params:any) => {
      this.id_simulation = +params['id_simulation'];
    });


  }

  step:any=1;
  id_company=localStorage.getItem('company_id');
  ngOnInit() {

   this.get_simulation();
   this.get_facturas();
  }

  monto_simulado:any=0;
  porcentaje_financiamiento:any=0;
  monto_anticipo:any=0;
  dias_promedio:any=0;
  loading:boolean=false;
  async get_simulation(){
    try {

      this.loading = true;


      const bd: any = await this.settings.get_query(1, `/cliente/simulacion/${this.id_simulation}/`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          console.log("simulation info", body_data)

          let sim = body_data.success.info;
          this.monto_simulado = sim.total;
          this.porcentaje_financiamiento = sim.anticipo;
          this.monto_anticipo = sim.valor_anticipo_total;
          this.dias_promedio = sim.dias_promedio;

          this.loading = false;

        break;
        default:

        break;
      }

    } catch (bd:any) {



    }
  }

  detalle_factura(){

    const dialogRef = this.dialog.open(DetalleFacturas,{
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.componentInstance.id_sim = this.id_simulation;

  }

  cantidad_facturas:any=0;
  loading_cantidad:boolean=false;
  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;
  filtro:any="";
  loading_facturas:boolean=false;
  async get_facturas(){


    try {

      this.loading_facturas = true;
      this.facturas = [];

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_simulation+'/?page='+this.page+'&filtro='+this.filtro);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          this.cantidad_facturas = data.total;
          this.facturas = data.info;
          this.loading_facturas = false;
          
          console.log(this.facturas)

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource._updateChangeSubscription();
          });


          


        break;
        }

    } catch (bd:any) {



    }

  }

  applyFilter(){
    this.filtro = this.buscar.value;
    this.get_facturas();
  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_facturas();
  }


  open_modal_info(step:any) {


    
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.step = step;

    dialogRef.afterClosed().subscribe(result => {

      if(result == 'add'){
        //this.open_agregar_facturas();
        this.open_agregar_facturas_dialog();
      }

    });

  }


  home(){
   this.router.navigate(['/home'])
  }

  buscar:any = new FormControl('');

  displayedColumns: string[] = ['deudor', 'factura', 'rut', 'fecha', 'monto', 'accion'];
  ELEMENT_DATA: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);


  facturas:any[]=[];
  comenzar_simulacion:boolean=false;
  open_agregar_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '530px',
      maxWidth: '95vw',
      disableClose: true,
    });

    dialogRef.componentInstance.agregar_onboarding = true;
    dialogRef.componentInstance.open_as_dialog = true;

    dialogRef.afterClosed().subscribe(result => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result
        console.log(this.facturas)
      }
    });
  }


  async delete_factura(id_factura:any, id_document_simulation:any){

    const elemento = this.facturas.find((el:any) => el.document.document_factura_id === id_factura);
    if (elemento) {
      elemento.isLoading = true;
    }

    try {

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-validar-delete-folio/'+this.id_simulation+'/?factura='+id_document_simulation, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


        elemento.isLoading = false;

          if(data.info == 3){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura!"
            dialogRef.componentInstance.title = "Si lo haces, será borrada de la"
            dialogRef.componentInstance.subtitle = "operación de forma definitiva."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
                if(result == true){
                  this.get_simulation();
                  this.get_facturas();
                }
            });
          }

          if(data.info == 2){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura importante!"
            dialogRef.componentInstance.title = "Si lo haces, el monto total disminuirá por debajo del mínimo"
            dialogRef.componentInstance.subtitle = "necesario para operar ($3.000.000) y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_simulation();
                this.get_facturas();
              }
            });
          }

          if(data.info == 1){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar tu única factura!"
            dialogRef.componentInstance.title = "Considera que ya no quedarán facturas "
            dialogRef.componentInstance.subtitle = "y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_simulation();
                this.get_facturas();
              }
            });


          }

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  async delete(id_factura:any, accion:any){
    try {
      
      const bd: any = await this.settings.get_query(4, '/cliente/simulacion-documentos/'+id_factura+'/?accion='+accion, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:




        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }


  open_agregar_facturas_dialog(){
    const dialogRef = this.dialog.open(AddFacturaInfo,{
      width: '600px',
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_sim = this.id_simulation;
    dialogRef.componentInstance.step = 2;
    dialogRef.componentInstance.onboarding = true;

    dialogRef.afterClosed().subscribe((result:any) => {

      if(result == true){
        this.get_facturas();
        this.get_simulation();
      }

    });
  }
  
}