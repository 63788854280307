import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RichTextComponent } from '../../rich-text/rich-text.component';



@Component({
  selector: 'app-detalle-obs',
  templateUrl: './detalle-obs.html',
  styleUrls: ['./detalle-obs.less'],
})

export class DetalleObs implements OnInit {

  token:any=localStorage.getItem('token');
  role:any=localStorage.getItem('role');;
  valForm: FormGroup;

  obsevacion:any;
  constructor(
              private fb: FormBuilder, 
              public snackBar: MatSnackBar, 
              public settings: SettingsService, 
              public dialog: MatDialog,public router:Router, public dialogRef:MatDialogRef<DetalleObs>){

    this.role = localStorage.getItem('role');
    this.token = localStorage.getItem('token');

    this.valForm = this.fb.group({
      'observacion': new FormControl(''),
    });



   }


  @ViewChild(RichTextComponent) richTextComponent!: RichTextComponent;
  ngOnInit(): void {

    console.log("this.obsevacion", this.obsevacion)


    setTimeout(() => {
      this.richTextComponent.asignarContenidoEditor(this.obsevacion);
      this.richTextComponent.disabled();
    });
   

  }



  


}
