import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;


@Component({
  selector: 'app-parrilla-simulaciones',
  templateUrl: './parrilla-simulaciones.component.html',
  styleUrls: ['./parrilla-simulaciones.component.less']
})
export class ParrillaSimulacionsComponent implements OnInit {

  valFormFilter!: FormGroup
  selected: any               = "";
  @Input() id_company         = localStorage.getItem('company_id');
  filter: string              = '';
  displayedColumns: string[]  = ['id', 'operacion', 'date_created', 'total','estado', 'detalle'];
  ELEMENT_DATA: Array<any>    = [];
  dataSource                  = new MatTableDataSource(this.ELEMENT_DATA);
  pageLength                  = 0;
  pageSize                    = 6;
  pageIndex                   = 0;
  page                        = 1;
  loading:boolean             = true;

  constructor(public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder, public router:Router, private snackBar:MatSnackBar ) {
    this.valFormFilter = this.fb.group({
      filter: '',
    });
  }

  ngOnInit() {

    this.id_company = localStorage.getItem('company_id')

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });

    this.load_all()

  }

  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('Company ID changed:', event.detail);
    this.id_company = localStorage.getItem('company_id');
    this.ngOnInit();
  }


  simular() {
    this.router.navigate(['home']);
    setTimeout(() => {
      $("#caja_excel").trigger('click')
    });
  }

  load_all() {
    this.id_company = localStorage.getItem('company_id');

    this.get_simulaciones();
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  myObserver: any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  async get_simulaciones() {
    try {
      this.loading = true;
      const bd: any = await this.settings.get_query(1, `cliente/simulaciones/${this.id_company}/?page=${this.page}&filtro=${this.filter}&estado=${this.selected}`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:
          console.log(body_data)
          this.ELEMENT_DATA = body_data.success.info;
          this.pageLength =  body_data.success.total;
          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource._updateChangeSubscription()
          });
          this.loading = false;
          break;
        default:
            this.ELEMENT_DATA = [];
            this.loading = false;
          break;
      }
    } catch (bd: any) {

      this.ELEMENT_DATA = [];
      this.loading = false;

      this.settings.manage_errors(bd.status, "simulaciones", bd, false);
    }
  }

  statuschange($e: any){
    if($e.value == "" || $e.value == undefined || $e.value == null){
      this.selected =""
      this.pageIndex = 0;
      this.page = 1;
      this.get_simulaciones();
    }else{
      this.pageIndex = 0;
      this.page = 1;
      this.get_simulaciones();
    }
  }

  reset_filter($event:any){
   if($event.target.value == ""){
    this.filter = "";
    this.get_simulaciones();
   }
  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_simulaciones();
  }

  applyFilter(value:any) {
    this.pageIndex = 0;
    this.page = 1;

    this.valFormFilter.controls['filter'].setValue(value)
    this.filter = value;
    this.get_simulaciones()
  }

  applyFilter2() {
    this.pageIndex = 0;
    this.page = 1;

    this.filter = this.valFormFilter.controls['filter'].value;
    this.valFormFilter.controls['filter'].setValue(this.filter)

    this.get_simulaciones()
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  ver_detalle(id:any, status:any, id_op:any){

    console.log("STATUS", status)

    if(status == 1 || status == 2 || status == 8 || status == 10 || status == 13 || status == 7 || status == 9){
      this.router.navigate(['home/ver-detalle/',id])
    }

    if(status == 4 || status == 11){
      this.router.navigate(['home/ver-detalle-rechazada/',id])
    }

    if(status == 3 || status == 5 || status == 6){
     this.router.navigate(['home/detalle-operacion/',id])
    }

    if(status == 14 || status == 15 || status == 16 || status == 17 || status == 18 || status == 19){
      this.router.navigate(['home/operation-detail/',id_op])
    }


  }

  loading_pdf_:boolean=false;
  downloadingId: any = null;
  async download_pdf(id_sim:any){
    try {

      this.downloadingId = id_sim;
      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-detalle-pdf/'+id_sim+'/', );
      var body_data: any =bd._body;
      //ESTE ES EL ENDPOINT MODIFICADO

      var data = body_data.respuesta;
      switch (bd.status) {
        case 200:

          this.downloadPDF(data.info);
          this.loading_pdf_ = false;
          this.downloadingId = null;

        break;
        default:
          this.downloadingId = null;
        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.loading_pdf_ = false;
            this.downloadingId = null;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
            this.settings.send_mail_500('Recuperar contraseña', bd);
          break;

        case 400:
        this.loading_pdf_ = false;
        this.downloadingId = null;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
          this.loading_pdf_ = false;
          this.downloadingId = null;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  downloadPDF(pdfUrl: string) {
    const fechaActual = new Date();
    const date = fechaActual.toISOString().split('T')[0];
    const time = fechaActual.toTimeString().split(' ')[0].replace(/:/g, '-');
    const fileName = `simulacion_${date}_${time}.pdf`;

    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = fileName;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  }
  
  abrirOperacion(numero_operacion:any){
    window.location.href = '#/home/operaciones';
  }

}
