import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;


@Component({
  selector: 'app-detalle-facturas-malas',
  templateUrl: './detalle-facturas.html',
  styleUrls: ['./detalle-facturas.less']
})
export class DetalleFacturasMalas implements OnInit {

  paginar_front:boolean=false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private changeDetectorRef: ChangeDetectorRef,public _MatPaginatorIntl: MatPaginatorIntl,public settings: SettingsService, public dialog: MatDialog, private dialogRef:MatDialogRef<DetalleFacturasMalas>, private snackBar:MatSnackBar ) { }

  @Input() id_sim:any
  @Input() caso:any

  cantidad_facturas:any=0;
  facturas:any[]=[]
  displayedColumns: string[] = [ 'factura','monto','deudor'];
  displayedColumnsRes: string[] = [ 'data'];
  dataSource = new MatTableDataSource(this.facturas);


  ngOnInit() {

    this._MatPaginatorIntl.firstPageLabel = 'primera página';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Facturas por página';
    this._MatPaginatorIntl.lastPageLabel = 'ultima página';
    this._MatPaginatorIntl.nextPageLabel = 'siguiente';
    this._MatPaginatorIntl.previousPageLabel = 'anterior'; 

    if(this.paginar_front == false){

      this.get_facturas();

    }else{

      console.log(this.facturas)
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.facturas);
        this.dataSource.paginator = this.paginator;
        this.dataSource._updateChangeSubscription();
      });

    }

  }

  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;
  async get_facturas(){


    try {


      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_sim+'/?page='+this.page);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          this.cantidad_facturas = data.total;
          this.facturas = data.info;
          


          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource._updateChangeSubscription();
          });


          


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400: 

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }

    }


  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_facturas();
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }




}