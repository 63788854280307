import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-factura-detail-dialog',
  templateUrl: './factura-detail.html',
  styleUrls: ['./factura-detail.less'],
})

export class FacturaDetailDialog implements OnInit {

  token:any=localStorage.getItem('token');
  role:any=localStorage.getItem('role');;
  valForm:FormGroup;

  @Input() id_operacion:any="";
  @Input() vista_ejecutivo:boolean=false;
  
  constructor(
              private fb: FormBuilder, 
              public snackBar: MatSnackBar, 
              public settings: SettingsService, 
              public dialog: MatDialog,public router:Router, public dialogRef:MatDialogRef<FacturaDetailDialog>){

    this.role = localStorage.getItem('role');
    this.token = localStorage.getItem('token');

    this.valForm = this.fb.group({
      'filter': new FormControl(''),
    });



   }

  is_responsive:boolean=false;
  ngOnInit(): void {

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

     this.get_detail();
   

  }

  go_to_detail(){
    this.dialogRef.close();
    if(this.vista_ejecutivo == true){
        this.router.navigate(['facturas-cliente', this.id_operacion]);
    }else{
      if(this.is_responsive == true){
        this.router.navigate(['home/resumen-facturas-r', this.id_operacion]);
      }else{
        this.router.navigate(['home/resumen-facturas', this.id_operacion]);
      }
    }

  }

  total_monto:any=0;
  cliente:any="Cargando cliente...";
  cant_deudores:any=0;
  fecha_operacion:any="Cargando fecha...";
  cant_facturas_pagadas:any=0;
  porcentaje_anticipo:any=0;
  total_monto_anticipo:any=0;
  total_monto_pendiente:any=0;
  diferencia_precio:any=0;
  comision:any=0;
  total_giro:any=0;
  descuentos:any=0;
  cxp:any=0;
  aplicaciones:any=0;
  saldo_pendiente:any=0;
  gastos_operacionales:any=0;
  total:any=0;
  iva:any=0;
  excedentes:any=0;
  total_monto_sin_giro:any=0;
  loading:boolean=false;
  async get_detail(pdf?:boolean) {

    this.loading = true;
    try {

      var endpoint =  `cliente/cartola-operacion-detalle/${this.id_operacion}/`;

      if(pdf == true){
        endpoint =  `cliente/cartola-operacion-detalle/${this.id_operacion}/?pdf=1`
      }

      const bd: any = await this.settings.get_query(1, endpoint);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          if(pdf == true){
            window.open(body_data.success.info, "blank");
            this.loading = false;
          }else{
            this.cliente = body_data.success.info.cliente;
            this.cant_deudores = body_data.success.info.cant_deudores;
            this.fecha_operacion = body_data.success.info.fecha_operacion;
            this.cant_facturas_pagadas = body_data.success.info.cant_facturas_pagadas;
            this.total_monto = body_data.success.info.total_monto;
            this.porcentaje_anticipo = body_data.success.info.porcentaje_anticipo;
            this.total_monto_anticipo = body_data.success.info.total_monto_anticipo;
            this.total_monto_pendiente = body_data.success.info.total_monto_pendiente;
            this.diferencia_precio = body_data.success.info.diferencia_precio;
            this.comision = body_data.success.info.comision;
            this.total_giro = body_data.success.info.total_giro;
            this.descuentos = body_data.success.info.descuentos;
            this.cxp = body_data.success.info.cxp;
            this.aplicaciones = body_data.success.info.aplicaciones;
            this.saldo_pendiente = body_data.success.info.saldo_pendiente;
            this.gastos_operacionales = body_data.success.info.gastos_operacionales;
            this.total = (this.saldo_pendiente + this.aplicaciones + this.cxp);
            this.iva = body_data.success.info.iva;
            this.excedentes = body_data.success.info.excedentes;
            this.total_monto_sin_giro = body_data.success.info.total_monto_sin_giro;
            this.loading = false;
          }



          
          break;
        default:

          break;
      }
    } catch (bd: any) {

      this.settings.manage_errors(bd.status, "detalle-cartola", bd, false);
    }
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }




}
