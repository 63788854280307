import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatSort, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { ObsCartera } from './observacioens-cartera/obs';
import { UrlService } from 'src/app/services/url.service';
import { MatDialog } from '@angular/material/dialog';
import { DocumentPreviewComponent } from 'src/app/home/cliente-new-version/cesion-facturador/document-preview/document-preview';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, NavigationEnd } from '@angular/router';
declare var $ :any;

@Component({
  selector: 'app-detalle-cartera',
  templateUrl: './detalle-cartera.component.html',
  styleUrls: ['./detalle-cartera.component.less']
})
export class DetalleCarteraComponent implements OnInit, OnDestroy {

  @Input() id_company:any;

  valForm:FormGroup;
  valFormFacturas:FormGroup;

  show_mora: boolean = false;
  show_ejecutiva: boolean = true;
  show_fondo: boolean = false;
  show_codigo: boolean = true;
  show_saldo: boolean = false;
  show_anticipo: boolean = false;
  show_monto: boolean = true;
  show_f_prorroga: boolean = false;
  show_n_op: boolean = false;
  show_f_op: boolean = false;
  show_v_p: boolean = false;
  show_vencimiento: boolean = true;
  show_emision: boolean = true;
  show_me: boolean = true;
  show_folio: boolean = true;
  show_t_doc: boolean = true;

  hay_box1:boolean=false;
  hay_box2:boolean=false;
  hay_box3:boolean=false;
  hay_box4:boolean=false;
  hay_box5:boolean=false;

  apply_filter_box(param:any){

    this.add_filter(param)


  }

  constructor(public _MatPaginatorIntl: MatPaginatorIntl,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public settings: SettingsService,
    public dialog: MatDialog,
    private router:Router,
    private urlService: UrlService) {

    this.id_company = localStorage.getItem("company_id")
    this.valForm = this.fb.group({
      'filter': new FormControl(''),
    });

    this.valFormFacturas = this.fb.group({
      'filter': new FormControl(''),
    });

    const urlOriginal = "home/detalle-cartera"
    this.urlService.setOriginalUrl(urlOriginal);

  }
  myObserver: any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }


  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('Company ID changed:', event.detail);
    this.id_company = localStorage.getItem('company_id');
    this.ngOnInit();
  }



  ngOnInit() {

    this.id_company = localStorage.getItem("company_id");

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        console.log("router company_id-->" + localStorage.getItem('company_id'))
        this.load_all();
      }
    });

    this.load_all()

  }


  role:any;
  load_all() {
    this.id_company = localStorage.getItem("company_id")
    this.role = localStorage.getItem("role")
    this.get_metricas();
    this.get_individual_facturas();
  }



  metricas:Object ={
    1:{'saldo_vs_global':0,'total_doctos': 0,'total_monto': 0,'total_saldo': 0},
    2:{'saldo_vs_global':0,'total_doctos': 0,'total_monto': 0,'total_saldo': 0},
    3:{'saldo_vs_global':0,'total_doctos': 0,'total_monto': 0,'total_saldo': 0},
    4:{'saldo_vs_global':0,'total_doctos': 0,'total_monto': 0,'total_saldo': 0},
    5:{'saldo_vs_global':0,'total_doctos': 0,'total_monto': 0,'total_saldo': 0}
  }

  loading_metricas:boolean=false;
  async get_metricas(){

    try {
      this.loading_metricas = true;

      const bd: any = await this.settings.get_query(1, 'cliente/cartera-range-count/'+this.id_company + '/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


        this.metricas = data.info;
        this.loading_metricas = false;
        console.log("metricas", this.metricas)


        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  ELEMENT_DATA:any[]=[];
  expansion_data:any[]=[];
  ELEMENT_DATA2:any[]=[];

  show_tramo1:boolean=false;
  show_tramo2:boolean=false;
  show_tramo3:boolean=false;
  show_tramo4:boolean=false;
  show_deuda_neta:boolean=true;
  show_vigente:boolean=true;

  displayedColumns: string[] = ['company__business_name', 'company__rut', 'vigente', 'tramo1', 'tramo2','tramo3','tramo4','sum_deuda_neta','executive__first_name','jefe__first_name'];
  displayedColumns2: string[] = ['folio','deudor','tipo_doc', 'emision', 'vencimiento','f_operacion','n_operacion','f_prorroga','v_prorroga','monto','anticipo','saldo', 'codigo','mora','ejecutivo','estado_docto__ordenamiento','obs'];
  displayedColumnsResponsive: string[] = ['data'];

  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement!:null;

  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
  loading:boolean=true;
  async get_initial(){

    try {


      const bd: any = await this.settings.get_query(1, '/cobranza/carteraEjecutivo'+'/?page='+this.page+'&filter='+this.filter+ '&columna=' + this.columna + '&orientacion=' + this.orientacion + '&tabs=1');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        this.expansion_data = data.info;
        this.pageLength = data.total;

        this.ELEMENT_DATA = [];


        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource._updateChangeSubscription();

        });



        this.loading = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.loading = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400:
            this.loading = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.loading = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }






  }

  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;
  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_initial();
  }

  pageEvent2(pageNumber: number): void {
    this.page2 = pageNumber;
    this.get_individual_facturas();
  }

  pageLength2 = 0;
  pageSize2 = 7;
  pageIndex2 = 0;
  page2 = 1;
  pageCustom($e:any) {

    this.page2 = $e.pageIndex + 1
    this.get_individual_facturas()
  }

  filter_list:any[]=[];
  filter:any="";
  add_filter(filter:any){

    filter = filter.toLowerCase();

    if(filter == "moroso" || filter == "morosa"){
      filter = "moroso"
    }

    const obje = this.filter_list.find((x: any) => x == filter);

    if (obje) {
      //no hacer nada si ya esta el filtro aplicado
      //this.filter_list.splice(this.filter_list.indexOf(obje), 1);
      this.valForm.controls['filter'].reset();
      this.valForm.reset();
    }else{
      if(filter != ''){
        this.filter_list.push(filter);
        this.valForm.controls['filter'].reset();
        this.valForm.reset();


        this.page = 1;
        this.get_filter();
      }
    }



  }

  filter_facturas:any="";

  add_filter_facturas(filter:any){


    if(filter  == ""){
      this.filter_facturas = "";
    }else{
      this.
      filter_facturas = filter;
    }

    this.page2 = 1;

    this.get_individual_facturas();


  }

  remove_filter(filter:any){
    const obje = this.filter_list.find((x: any) => x == filter);

    if (obje) {
      this.filter_list.splice(this.filter_list.indexOf(obje), 1);
    }


    this.get_filter();

  }

  @ViewChild(MatSort) myMatSort!:MatSort
  reset_filter(){

    this.filter_facturas = ""

    this.page2 = 1;

    this.page = 1;
    this.valForm.reset();
    this.valFormFacturas.reset();
    this.filter = "";
    this.filter_list = [];

    this.columna = "";
    this.orientacion = "";

    this.columna2 ="";
    this.orientacion2 ="";

    this.get_individual_facturas();

  }

  get_filter(){
    let filter:any="";
    for( let i in this.filter_list){
      filter = filter +','+ this.filter_list[i];
    }
    filter = filter.substring(1);
    this.filter = filter;

    this.get_individual_facturas();
  }

  loading2:boolean=true;
  loading_table:boolean=true;
  async get_individual_facturas(){

    try {

      this.loading_table = true;

      this.ELEMENT_DATA2 = [];
      setTimeout(() => {
        this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
        this.dataSource2._updateChangeSubscription();

      });


      this.loading2 = true;

      const bd: any = await this.settings.get_query(1, '/cliente/cartera-cliente/'+this.id_company+'/?page='+this.page2+'&filter='+this.filter+ '&columna=' + this.columna2 + '&orientacion=' + this.orientacion2);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        console.log("data individual", data)

        this.selection.clear();

        this.ELEMENT_DATA2 = data.info;
        this.pageLength2 = data.total;


        this.ELEMENT_DATA2.forEach((element: any) => {
          let obje = this.arr_facturas.find((x: any) => x.id == element.id);
          if (obje) {
              element.checked = true;
              this.selection.toggle(element);
          }
        });


        this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
        this.dataSource2._updateChangeSubscription();


        this.loading2 = false;

        this.loading_table = false;

        //logica de multi filtros
        if(this.filter.includes('vig')){
          this.hay_box1 = true;
        }else{
          this.hay_box1 = false;
        }

        if(this.filter.includes('1-30')){
          this.hay_box2 = true;
        }else{
          this.hay_box2 = false;
        }

        if(this.filter.includes('31-60')){
          this.hay_box3 = true;
        }else{
          this.hay_box3 = false;
        }

        if(this.filter.includes('61-90')){
          this.hay_box4 = true;
        }else{
          this.hay_box4 = false;
        }

        if(this.filter.includes('>90')){
          this.hay_box5 = true;
        }else{
          this.hay_box5 = false;
        }



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:
            this.loading2 = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400:
            this.loading2 = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.loading2 = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  diasbleex:boolean=false;
  async down(){

    try {

      this.diasbleex = true;

      const bd: any = await this.settings.get_query(1, '/cliente/cartera-cliente/'+this.id_company+'/?excel='+1+'&filter='+this.filter);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        console.log("download", data)
        this.diasbleex = false;
        window.open(data.info, "blank")



        break;
        default:

        break;
      }

    } catch (bd:any) {

      this.diasbleex = false;

      switch (bd.status) {


        case 500:
            this.loading2 = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:
            this.loading2 = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
            this.loading2 = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }

  }


  //DRAG EXTERNO
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  slider = document.querySelector<HTMLElement>('.detail-desktop');
  startDragging(e:any, flag:any, el:any) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }

  stopDragging(e:any, flag:any) {
    this.mouseDown = false;
  }

  moveEvent(e:any, el:any) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }

    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }

  //DRAG INTERNO
  mouseDown2 = false;
  startX2: any;
  scrollLeft2: any;
  slider2 = document.querySelector<HTMLElement>('.table-responsive-desktop');
  startDragging2(e:any, flag:any, el:any) {
    this.mouseDown2 = true;
    this.startX2 = e.pageX - el.offsetLeft;
    this.scrollLeft2 = el.scrollLeft;
  }

  stopDragging2(e:any, flag:any) {
    this.mouseDown2 = false;
  }

  moveEvent2(e:any, el:any) {
    e.preventDefault();
    if (!this.mouseDown2) {
      return;
    }

    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX2;
    el.scrollLeft = this.scrollLeft2 - scroll;
  }





  //SELECTED
  selection = new SelectionModel<any>(true, []);
  arr_facturas: Array<any> = [];
  total_seleccionado:any;
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource2.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event: any) {
      if(!$event.checked) {
          this.selection.clear()
          this.ELEMENT_DATA2.forEach((element: any) => {
              element.checked = false;
          });
      }else{
          this.ELEMENT_DATA2.forEach((element: any) => {
              let object = this.arr_facturas.find((x: any) => x.id === element.id);
              if(object){
                  element.checked = true;
                  this.selection.select(element);
              }
          });
      }
  }

  checkboxLabel(row?: any): string {
      if (!row) {
          return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
          return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  selectOneElement(docto: any, objeto: any) {
    const obje = this.arr_facturas.find((x: any) => x.id == objeto.id);

    if (obje) {
        this.arr_facturas.splice(this.arr_facturas.indexOf(obje), 1);
    } else {
        this.arr_facturas.push(objeto);
    }

    this.total_seleccionado = 0;
    var group = this.groupBy(this.arr_facturas);
    this.total_seleccionado = Object.keys(group).length;
  }

  selectAllElements(){
    if (this.isAllSelected()) {
        for(let i=0;i<this.dataSource2.filteredData.length;i++){
            const obje = this.arr_facturas.find((x: any) => x.id == this.dataSource2.filteredData[i].id);
            if (obje) {
                this.arr_facturas.splice(this.arr_facturas.indexOf(obje), 1);
            }
        }

    } else {
        for(let i=0;i<this.dataSource2.filteredData.length;i++){
            const obje = this.arr_facturas.find((x: any) => x.id == this.dataSource2.filteredData[i].id);
            if (obje) {
            } else {
                this.arr_facturas.push(this.dataSource2.filteredData[i])
            }
        }
    }

    this.total_seleccionado = 0;
    var group = this.groupBy(this.arr_facturas);
    this.total_seleccionado = Object.keys(group).length;


  }

  groupBy(array: any) {
      return array.reduce((result: any, currentValue: any) => {(result[currentValue["id"]] = result[currentValue["id"]] || []).push(
          currentValue
          );
          return result;
      }, {});
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }


  gestionar_factura(id:any){
    this.router.navigate(['gestion-factura',id])
  }


  columna = "";
  orientacion = "";
  sortDirection:any="asc"
  sortData(sort: Sort) {



    this.columna = sort.active;
    this.orientacion = sort.direction;


    if(sort.direction == "asc"){
      this.sortDirection = "asc"
    }else if(sort.direction == "desc"){
      this.sortDirection = "desc";
    }else{
      this.sortDirection = "";
    }

    this.get_initial()


  }

  columna2 = "";
  orientacion2 = "";
  sortDirection2:any="asc"
  sortData2(sort: Sort) {




    this.columna2 = sort.active;
    this.orientacion2 = sort.direction;


    if(sort.direction == "asc"){
      this.sortDirection2 = "desc"
    }else if(sort.direction == "desc"){
      this.sortDirection2 = "asc";
    }else{
      this.sortDirection2 = "";
    }

    console.log(this.sortDirection2)

    this.get_individual_facturas()


  }

  check_expanded(i:any){


    this.columna = "";
    this.orientacion ="";
    this.columna2 = "";
    this.orientacion2 ="";
    this.id_company = i.company_id;

    this.page2 = 1;
    this.filter_facturas = ""

    this.get_individual_facturas();
  }

  collapse_all(){
    this.ELEMENT_DATA2 = [];
  }

  observaciones_get(id:any) {
    let dialogRef = this.dialog.open(ObsCartera, {
      width: '900px',
      autoFocus: false,
    });
    dialogRef.componentInstance.id_company = id;
    dialogRef.componentInstance.token = localStorage.getItem('token');
    dialogRef.componentInstance.role = this.role;
  }

  openPreviewDocument(url: string) {
    if (url === null || url === undefined || url === '') {
      this.snackBar.open('No existe el PDF', 'Aceptar', {
        duration: 2000,
        panelClass: ['simpli-snack']
      })
      return ;
    }

    let dialogRef = this.dialog.open(DocumentPreviewComponent, {
      minWidth: '50vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      autoFocus: true,
    });
    dialogRef.componentInstance.url = url;
  }
}
