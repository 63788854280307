<div class="modal-header">
    <span class="material-icons custom-dismiss" (click)="cerrar();">
        arrow_back
    </span>
    Detalle
</div>

<div *ngIf="loading == false && detalle_excedentes.length > 0" lass="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__faster animate__animated animate__fadeIn">
    <mat-card *ngFor="let info of detalle_excedentes">
        <mat-card-content>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Fecha pago</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="chip-fecha vaya-manera2">{{info?.fecha_pago | date: 'dd-MM-yyyy'}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Fecha otorgamiento</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="chip-fecha vaya-manera2">{{info.fecha_otorgamiento | date: 'dd-MM-yyyy'}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Fecha vencimiento</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="chip-fecha vaya-manera2">{{info.fecha_vencimiento | date: 'dd-MM-yyyy'}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Monto pagado</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="vaya-manera">${{settings.formatMoney(info?.monto_pagado)}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Dias de vencimiento</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="vaya-manera">{{info?.dias_mora}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Interés mora</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="vaya-manera">${{settings.formatMoney(info?.interes_mora)}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Excedentes</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="vaya-manera">${{settings.formatMoney(info?.excedentes)}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Monto anticipo</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="vaya-manera">${{settings.formatMoney(info.monto_anticipado)}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span>Monto total</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span class="vaya-manera">${{settings.formatMoney(info.monto_documento)}}</span>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>


<div *ngIf="loading == false && detalle_excedentes.length == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__faster animate__animated animate__fadeIn">
    <no-hay-registros></no-hay-registros>
</div>

<div *ngIf="loading == true && detalle_excedentes.length == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__faster animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="loading == false && detalle_excedentes.length > 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
  <div  style="text-align: center;">
    <custom-paginator
    [pageSize]="10"
    [totalElements]="length"
    [currentPage]="page"
    (pageChange)="pageEvent($event)">
  </custom-paginator>
  </div>
</div>