import { Component, HostBinding, HostListener, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SettingsService } from "src/app/settings/settings.service";
declare var $ :any;

@Component({
  selector: "app-facturas-arrayan",
  templateUrl: "./facturas-arrayan.component.html",
  styleUrls: ["./facturas-arrayan.component.less"],
})
export class FacturasArrayanComponent implements OnInit {

  @HostBinding('class') classes = 'facturas__container'

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  show_spinner_loading:boolean=true;

  private pre_ruta = "" + SettingsService.API_ENDPOINT + "media/facturas_nubox";
  private url = "cliente/facturas-nubox";
  private error!: string;
  public noFacturas: boolean = false;;
  public facturas:any[] = [];
  public showSpinner!: boolean;
  public dataSource = new MatTableDataSource();
  private id_company = localStorage.getItem('company_id');
  public displayedColumns: string[] = [
    "documento",
    "fecha_creacion",
    "acciones",
  ];

  public pageSizes = [20, 30, 40, 50];

  constructor(private settigsService: SettingsService) {}

  ngOnInit() {
    this.id_company = localStorage.getItem('company_id')
    this.getFacturas();
  }


  async getFacturas() {

    try {
      this.show_spinner_loading = true;
      const bd: any = await this.settigsService.get_query(
        1,
        `${this.url}/${this.id_company}/`
      );
      var body_data: any=bd._body;
      var data=body_data.success;
      switch (bd.status){
        case 200:
          this.facturas= this.changeDateFormat(data.url_file);
          this.facturas = this.addRuteToFile(this.facturas);

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource.paginator = this.paginator;
            this.dataSource._updateChangeSubscription()
          });

          if(this.facturas.length > 0){
            this.noFacturas = true;
            this.show_spinner_loading = false;
          }else{
            this.noFacturas = false;
            this.show_spinner_loading = false;
          }
        break;
        default:
          this.show_spinner_loading=false;
        break;
  

      }
      
    } catch (bd: any) {
      this.show_spinner_loading = false;
      this.settigsService.manage_errors(bd.status, "documento", bd);

    }
  }

  addRuteToFile(filesData: any) {
    return filesData.map((f: any) => ({
      ...f,
      ruta: `${this.pre_ruta}/${f.archivo}`,
    }));
  }

  filterByDate(date: any) {
    console.log("input date");
    console.log("date");
  }

  applyFilter(event: Event) {
    console.log('Esta es la data -->',this.dataSource)
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeDateFormat(archivos: any) {
    return archivos.map((a: any) => {
      const date = a.date_created.split('/')
      const newDate = `${date[1]}/${date[0]}/${date[2]}`
      return {
        ...a,
        formatDate : newDate 
      }
    })
  }


  @HostListener('window:companyIdChanged', ['$event'])
  onCompanyIdChanged(event: CustomEvent) {
    console.log('excedentes Company ID changed:', event.detail);
    this.ngOnInit(); 
  }

  
}

export interface Factura {
  file: string;
  dateCreated: string;
}
