import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { SettingsService } from 'src/app/settings/settings.service';;
import { AgregarFacturasNomina } from '../../cliente-new-version/agregar-facturas-nomina/agregar-facturas-nomina';
import { AgregarFacturas } from '../../cliente-new-version/agregar-facturas/agregar-facturas';
import { AgregarFacturasDialog } from '../../cliente-new-version/simular-facturas/agregar-facturas-dialog/agregar-facturas-dialog';
import {Location} from '@angular/common';
import { UrlService } from 'src/app/services/url.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;


@Component({
  selector: 'app-simular-facturas-responsive',
  templateUrl: './simular-facturas-responsive.html',
  styleUrls: ['./simular-facturas-responsive.less']
})
export class SimularFacturasResponsive implements OnInit {


  valFormFilter!: FormGroup
  valFormFechas!: FormGroup 

  @Input() id_company = localStorage.getItem('company_id');


  constructor(private urlService: UrlService,public settings: SettingsService, public dialog: MatDialog,public fb:FormBuilder,private _location: Location,private route:Router ) {

    const urlOrigin = String(this.route.url)
    const urlOriginal = urlOrigin;
    this.urlService.setOriginalUrl(urlOriginal);

    this.valFormFilter = this.fb.group({
      filter: '',
    });
  
    this.valFormFechas = this.fb.group({
      fecha_ini: '',
      fecha_fin: '',
    });

   }

  return(){
    this._location.back();
  }

  tiene_credenciales:boolean=false;
  ngOnInit() {


    let key = localStorage.getItem('keys')
    if(key == "true"){
        this.tiene_credenciales = true;
    }

    this.get_facturas();


  }

  displayedColumns: string[] = ['check', 'data'];
  ELEMENT_DATA: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;
  suma_facturas=0;
  total_para_simular = 0;
  loading:boolean=true;
  async get_facturas() {


    try {
      //this.loading = true;

      const bd: any = await this.settings.get_query(1, `/cliente/sii-ventas/${this.id_company}/?page=${this.page}&filter=${this.filter}&fecha_ini=${this.fecha_inicio}&fecha_fin=${this.fecha_fin}`);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

  

          this.documentos = [];
          this.selection.clear();

          this.documentos = data.info;

          this.total_para_simular = data.totales;

          this.documentos.forEach((element: any) => {
              let obje = this.arr_documentos_to_simulate.find((x: any) => x.venta_id == element.venta_id);        
              if (obje) {
                  element.checked = true;
                  this.selection.toggle(element);  
              }
          }); 

          this.ELEMENT_DATA = data.info;
          this.pageLength = data.total;
          this.suma_facturas = data.totales;

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource._updateChangeSubscription()
          });




          this.loading = false;



          break;
        default:
            this.ELEMENT_DATA = [];
            this.loading = false;
          break;
      }
    } catch (bd: any) {

      this.ELEMENT_DATA = [];
      this.loading = false;

      this.settings.manage_errors(bd.status, "operacionesPorCeder", bd, false);
    }
  }

  reset_filter($event:any){
   if($event.target.value == ""){
    this.filter = "";
    this.get_facturas();
   }
  }

  reset(){
    this.valFormFechas.reset();
    this.fecha_fin = "";
    this.fecha_inicio = "";
    this.get_facturas();
  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_facturas();
  }

  fecha_inicio:any="";
  fecha_fin:any="";
  filter:any="";
  date_change_start($event:any){

    let date_start = new Date(this.valFormFechas.controls['fecha_ini'].value);
    let dia, mes, ano;
    dia = String(date_start.getDate())
    mes = String(date_start.getMonth() + 1)
    ano = String(date_start.getFullYear())
    if (mes.length == 1) {
      mes = '0' + mes
    }
    if (dia.length == 1) {
      dia = '0' + dia
    }

    let start = ano + '-' + mes + '-' + dia + ' 00:00:00';
    let start2 = ano + '-' + mes + '-' + dia + ' 23:59:59';

    this.fecha_inicio = start;
    this.fecha_fin = start2;


  }

  date_change_end($event:any){

    if(this.valFormFechas.controls['fecha_fin'].value == null){

      this.fecha_inicio = this.fecha_inicio;

      let date_end = new Date(this.valFormFechas.controls['fecha_ini'].value);
      let dia2, mes2, ano2;
      dia2 = String(date_end.getDate())
      mes2 = String(date_end.getMonth() + 1)
      ano2 = String(date_end.getFullYear())
      if (mes2.length == 1) {
        mes2 = '0' + mes2
      }
      if (dia2.length == 1) {
        dia2 = '0' + dia2
      }
  
      let end = ano2 + '-' + mes2 + '-' + dia2 + ' 23:59:59';
      this.fecha_fin = end

    }else{
      let date_end = new Date(this.valFormFechas.controls['fecha_fin'].value);
      let dia2, mes2, ano2;
      dia2 = String(date_end.getDate())
      mes2 = String(date_end.getMonth() + 1)
      ano2 = String(date_end.getFullYear())
      if (mes2.length == 1) {
        mes2 = '0' + mes2
      }
      if (dia2.length == 1) {
        dia2 = '0' + dia2
      }
  
      let end = ano2 + '-' + mes2 + '-' + dia2 + ' 23:59:59';
      this.fecha_inicio = this.fecha_inicio;
      this.fecha_fin = end
    }

  }

  filtrar_por_fechas(){
    this.pageIndex = 0;
    this.page = 1;
    this.get_facturas();
  }

  //FILTER
  applyFilter(value:any) {
    this.pageIndex = 0;
    this.page = 1;
    
    this.valFormFilter.controls['filter'].setValue(value)
    this.filter = value;
    this.get_facturas()
  }

  applyFilter2() {
    this.pageIndex = 0;
    this.page = 1;

    this.filter = this.valFormFilter.controls['filter'].value;
    this.valFormFilter.controls['filter'].setValue(this.filter)

    this.get_facturas()
  }

  
  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }



  //SELECTED

  documentos: any;
  conteo_documentosobjeto: any;
  arr_documentos_to_simulate: Array<any> = [];
  display_total= false;
  total_facturas: any = 0;
  totales: any[] = [];
  diferencia: any = 0;
  simular_disabled = true;

  hijo_diferencia = 0;
  hijo_total = 0;
  hide= true;
  num_deudores: any;
  monto_total=0;


  total_proveedores=0;
  selectOneElement(venta_id: any, objeto: any) {
      const obje = this.arr_documentos_to_simulate.find((x: any) => x.venta_id == objeto.venta_id);

      if (obje) {
          this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
      } else {
          this.arr_documentos_to_simulate.push(objeto);
      }

      this.hijo_diferencia = this.arr_documentos_to_simulate.reduce((acc, cv) => Number(cv.mnt_total) + acc , 0);
      this.total_facturas = this.formatMoney(this.hijo_diferencia);

      this.diferencia = 0 ? (this.diferencia == this.hijo_total) : (this.hijo_total - this.hijo_diferencia);
      this.diferencia = this.formatMoney(this.diferencia);
      
      this.simular_disabled = true ? (this.arr_documentos_to_simulate.length == 0) : false;

      this.total_proveedores = 0;
      var group = this.groupBy(this.arr_documentos_to_simulate);
      this.total_proveedores = Object.keys(group).length;
  }

  selectAllElements(){
    if (this.isAllSelected()) {
        for(let i=0;i<this.dataSource.filteredData.length;i++){
            const obje = this.arr_documentos_to_simulate.find((x: any) => x.venta_id == this.dataSource.filteredData[i].venta_id);        
            if (obje) {
                this.arr_documentos_to_simulate.splice(this.arr_documentos_to_simulate.indexOf(obje), 1);
            }
        }

    } else {
        for(let i=0;i<this.dataSource.filteredData.length;i++){
            const obje = this.arr_documentos_to_simulate.find((x: any) => x.venta_id == this.dataSource.filteredData[i].venta_id);        
            if (obje) {
            } else {
                this.arr_documentos_to_simulate.push(this.dataSource.filteredData[i])
            }
        }
    }

    this.hijo_diferencia = this.arr_documentos_to_simulate.reduce((acc, cv) => Number(cv.mnt_total) + acc , 0);
    this.total_facturas = this.formatMoney(this.hijo_diferencia);

    this.diferencia = 0 ? (this.diferencia == this.hijo_total) : (this.hijo_total - this.hijo_diferencia);
    this.diferencia = this.formatMoney(this.diferencia);
    
    this.simular_disabled = true ? (this.arr_documentos_to_simulate.length == 0) : false;
    
    this.total_proveedores = 0;
    var group = this.groupBy(this.arr_documentos_to_simulate);
    this.total_proveedores = Object.keys(group).length;
  }

  groupBy(array: any) {
      return array.reduce((result: any, currentValue: any) => {(result[currentValue["receptoracreedor"]["id"]] = result[currentValue["receptoracreedor"]["id"]] || []).push(
          currentValue
          );
          return result;
      }, {}); 
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle($event: any) {
      if(!$event.checked) {
          this.selection.clear()
          this.documentos.forEach((element: any) => {
              element.checked = false;
          });
      }else{
          this.documentos.forEach((element: any) => {  
              let object = this.arr_documentos_to_simulate.find((x: any) => x.venta_id === element.venta_id);
              if(object){
                  element.checked = true;
                  this.selection.select(element);   
              }
          });
      }
  }

  checkboxLabel(row?: any): string {
      if (!row) {
          return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
          return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.venta_id + 1}`;
  }



  facturas:any[]=[];
  comenzar_simulacion:boolean=false;
  open_agregar_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      autoFocus: false,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result
      }
    });
  }

  open_agregar_facturas_by_nomina(){
    const dialogRef = this.dialog.open(AgregarFacturasNomina,{
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      autoFocus: false,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result
      }
    });
  }

  open_agregar_facturas_dialog(){
    const dialogRef = this.dialog.open(AgregarFacturasDialog,{
      width: '600px',
      maxWidth: '95vw',
      disableClose: true,
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;

    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

  simular(){

    let facturas:any[]=[];

    for(let i in this.arr_documentos_to_simulate){

      let f_e = new Date(moment(this.arr_documentos_to_simulate[i].fecha_emision, 'DD-MM-YYYY').format('M-D-YYYY'));

      let yyyy = f_e.getFullYear();
      let mm = f_e.getMonth()+1;
      let dd = f_e.getDate();

      let fecha_emision  = yyyy+"-"+mm+'-'+dd;

      let obj =  {
        count_errors: 0,
        deudor: this.arr_documentos_to_simulate[i].receptoracreedor.business_name,
        deudor_id: this.arr_documentos_to_simulate[i].receptoracreedor.id,
        errorFechaEmision: null,
        errorFolio: null,
        errorMonto: null,
        errorRut: null,
        fecha_emision: fecha_emision,
        folio: this.arr_documentos_to_simulate[i].folio,
        monto: this.arr_documentos_to_simulate[i].mnt_total,
        rut: this.arr_documentos_to_simulate[i].receptoracreedor.rut,
      }

      facturas.push(obj)

    }

    this.comenzar_simulacion = true;
    this.facturas = facturas




  }


}