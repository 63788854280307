<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
        <h1 class="titulo-simpli">{{title}}</h1>
        <small class="subtitulo-simpli">Para ver el detalle de una operación, selecciona el icono (+)</small>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">

        <form  [formGroup]="valFormFilter">

            <div class="row" style="width: 100%;">

              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 col-top" ></div>

              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top" >
                <mat-form-field appearance="fill">
                  <mat-label>Buscar</mat-label>
                  <input matInput formControlName="filter" (change)="reset_filter($event)" (keyup.enter)="applyFilter($event.target.value)">
                  <mat-icon (click)="applyFilter2()" matSuffix class="icon-suffix">search</mat-icon>
                  <mat-hint>Buscar por monto, número de operación o número de factura</mat-hint>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top" style="text-align: center;">
                <button [disabled]="loading" (click)="get_cartola(true)" style="margin-top: 20px;" type="button" class="primary-button" mat-flat-button>Descargar</button>
              </div>

            </div>

        </form>
      </div>
    </div>
  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <form [formGroup]="valFormFechas">
      <div class="row" style="width: 100%;">
        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-12">
          <mat-form-field appearance="fill" color="primary">
              <mat-label>Fecha</mat-label>
              <mat-date-range-input color="primary" [rangePicker]="picker" (click)="picker.open()" >
                  <input matStartDate color="primary" readonly (dateChange)="date_change_start($event)" formControlName="fecha_ini">
                  <input matEndDate color="primary" readonly (dateChange)="date_change_end($event)" formControlName="fecha_fin">
              </mat-date-range-input>
              <mat-hint>Introduce una fecha o un rango de fecha</mat-hint>
              <mat-datepicker-toggle color="primary" matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker color="primary" #picker>
                  <mat-date-range-picker-actions style="justify-content: center;">
                    <button class="secondary-button" (click)="reset()" mat-flat-button matDateRangePickerCancel>Cancelar</button>
                    <button class="primary-button" mat-flat-button matDateRangePickerApply (click)="filtrar_por_fechas()">Aplicar</button>
                      </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <!--Loading-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">

    <div class="container-loading">

      
      <div class="img-loading">
        <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
      </div>

      <div style="margin-top: 20px;">
        <mat-label class="label-loading">
          <div>La información está siendo procesada.</div>
          <div>Esto no tomará mucho tiempo.</div>
        </mat-label>
      </div>

    </div>


  </div>

  <!--TABLA-->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length >0">

    <mat-card class="card-tabla" style="position: relative;">

      <div class="table-responsive-simpli add_factoring">

        <table mat-table [dataSource]="dataSource" matSortActive="name" matSortActive="name" [matSortStart]="sortDirection" (matSortChange)="sortData($event)" matSort (matSortChange)="sortData($event)" matSort>

        
          <ng-container matColumnDef="fecha_operacion">
            <th mat-header-cell *matHeaderCellDef> Fecha operación </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;min-width: 150px !important;"> 
                <input class="no-input" value="{{element.fecha_operacion}}" readonly>  
            </td>
            <td mat-footer-cell *matFooterCellDef class="simpli-footer">Total</td>
          </ng-container>

        <ng-container matColumnDef="nro_operacion">
          <th mat-header-cell *matHeaderCellDef> N° operación </th>
          <td mat-cell *matCellDef="let element" style="width: 110px;text-align: center;"> 
            <input class="no-input " value="{{element.nro_operacion}}" readonly>  
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
    
        <ng-container matColumnDef="cant_facturas">
          <th mat-header-cell *matHeaderCellDef> Facturas </th>
          <td mat-cell *matCellDef="let element" style="min-width: 110px;" > 
            <input class="no-input " value="{{element.cant_facturas}}" readonly>  
        </td>
        <td mat-footer-cell *matFooterCellDef class="simpli-footer">{{totales.total_facturas}}</td>
        </ng-container>
    
        <ng-container matColumnDef="total_monto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto líquido <span matTooltip="Es el monto que será girado a tu cuenta" style="margin-left: 10px;color:#924eff;font-size: 20px;"><i class="fa-light fa-circle-info"></i></span></th>
          <td mat-cell *matCellDef="let element" style="min-width: 110px;position: relative;" >
             <span *ngIf="element.total_monto == 0" matTooltip="El monto fue utilizado para regularizar otras operaciones que tienes en curso. Para saber más, contacta a tu ejecutivo." class="info-sin-giro"><i class="fa-light fa-circle-info"></i></span>
             <input class="no-input "  value="${{formatMoney(element.total_monto)}}" readonly> 
          </td>
          <td mat-footer-cell *matFooterCellDef class="simpli-footer">${{formatMoney(totales.total_monto)}}</td>
        </ng-container>

        <ng-container matColumnDef="total_monto_pendiente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto pendiente <span matTooltip="Corresponde al saldo pendiente de giro." style="margin-left: 10px;color:#924eff;font-size: 20px;"><i class="fa-light fa-circle-info"></i></span></th>
          <td mat-cell *matCellDef="let element" style="min-width: 110px;" > 
            <input class="no-input " value="${{formatMoney(element.total_monto_pendiente)}}" readonly> 
        </td>
        <td mat-footer-cell *matFooterCellDef class="simpli-footer">${{formatMoney(totales.total_monto_pendiente)}}</td>
        </ng-container>

        <ng-container matColumnDef="mora">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mora </th>
          <td mat-cell *matCellDef="let element" style="text-align: center;min-width: 110px;position: relative;">
            <span matTooltip="Algunas facturas de la operación están generando mora." *ngIf="element.mora > 0" class="a-down"><i class="fa-solid fa-arrow-up-long"></i></span>
            <input class="no-input " value="${{formatMoney(element.mora)}}" readonly> 
          </td>
          <td mat-footer-cell *matFooterCellDef class="simpli-footer">${{formatMoney(totales.total_mora)}}</td>
        </ng-container>

        <ng-container matColumnDef="excedentes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Excedentes <span matTooltip="Son los excedentes originales de la operación. Este monto está sujeto a la mora." style="margin-left: 10px;color:#924eff;font-size: 20px;"><i class="fa-light fa-circle-info"></i></span></th>
          <td mat-cell *matCellDef="let element" style="text-align: center;min-width: 110px;position: relative;"> 
            <input class="no-input " value="${{formatMoney(element.excedentes)}}" readonly> 
        </td>
        <td mat-footer-cell *matFooterCellDef class="simpli-footer">${{formatMoney(totales.total_excedentes)}}</td>
        </ng-container>

        <ng-container matColumnDef="detalle">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="width: 20px !important;max-width:20px !important;text-align: center;" > 
            <span style="cursor: pointer;" (click)="open_detail_operacion(element.nro_operacion)"><i class="fa-light fa-circle-plus" style="font-size: 20px;color:#1B0943;margin-top: 3px;"></i></span>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="footer-row"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns" class=""></tr>
        </table>

      </div>

    </mat-card>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="loading == false && ELEMENT_DATA.length == 0">
    <div class="empty-msg">
        
                
      <div style="padding-top: 50px;text-align: center;">
        <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
      </div>

      <div style="padding-top: 50px;text-align: center;">
        <mat-label class="label-bienvenida">
          <div class="label-empty">
            ¡Ups! Al parecer, no tienes movimientos para visualizar.
          </div>

          <div class="label-empty" style="margin-top: 20px;" *ngIf="vista_ejecutivo == false">
            Realiza una operación para que puedas comenzar a un seguimiento.
          </div>

          <div class="label-empty" style="margin-top: 20px;" *ngIf="vista_ejecutivo == true">
            {{company}} no tiene ninguna operación.
          </div>

        </mat-label>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="btn-simpli-container" *ngIf="vista_ejecutivo == false">
            <button class="primary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
          </div>
          
        </div>
      </div>

    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;" *ngIf="loading == false">
    <numbered-pagination [pageSize]="8"  [totalCount]="pageLength" [index]="page" (page)="pageEvent($event)"></numbered-pagination>
  </div>

</div>
