<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Excedentes Disponibles : &nbsp; ${{formatMoney(this.excedentes_totales)}}

      <span *ngIf="solicitar_excedentes == true" style="margin-left: 20px;"> 

        <button matTooltip="Solicitar Excedentes" [disabled]="loading_excentes" class='primary-button' mat-flat-button  style="margin-top: 15px;display: none;" (click)="sol_excedentes()">
          <span *ngIf="loading_excentes == false">Solicitar</span>
          <span *ngIf="loading_excentes == true"><i class="fa-light fa-spinner fa-spin-pulse"></i></span>
        </button>


      </span>

    </h1>
    <small class="subtitulo-simpli">{{this.ultima_fecha}}</small>

  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <div class="row">

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <mat-form-field appearance="fill">
          <mat-label>Buscar</mat-label>
          <input matInput [formControl]="buscar" (key.enter)="apply_filter()" (change)="cuando_busca_vacio($event.target.value)">
          <mat-icon matSuffix>search</mat-icon>
          <mat-hint>Busca por número de factura o número de operación</mat-hint>
        </mat-form-field>
      </div>


      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <button style="margin-top: 20px;"  mat-stroked-button class="secondary-button" (click)="limpiar_filtros()">
          Limpiar
          <mat-icon class="material-icons">sync</mat-icon>
        </button>
      </div>

    </div>




  </div>

  <div *ngIf="loading == false && excedentes.length > 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__faster animate__animated animate__fadeIn">


    <table mat-table
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z8" style="display: none;">

        <ng-container matColumnDef="operacion">
          <th mat-header-cell *matHeaderCellDef> Operación </th>
          <td mat-cell *matCellDef="let element"> {{element.numero_operacion}}</td>
        </ng-container>

        <ng-container matColumnDef="factura">
          <th mat-header-cell *matHeaderCellDef> Factura </th>
          <td mat-cell *matCellDef="let element"> {{element.numero_factura}} </td>
        </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            @if (expandedElement === element) {
              <mat-icon>keyboard_arrow_up</mat-icon>
            } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
            }
          </button>
        </td>
      </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-diagram">
          <div class="example-element-position"> {{element.position}} </div>
          <div class="example-element-symbol"> {{element.symbol}} </div>
          <div class="example-element-name"> {{element.name}} </div>
          <div class="example-element-weight"> {{element.weight}} </div>
        </div>
        <div class="example-element-description">
          {{element.description}}
          <span class="example-element-description-attribution"> -- Wikipedia </span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    <mat-card class="card-excedentes">
      <mat-card-content>

        <mat-list>
          @for (i of excedentes; track i) {
            <mat-list-item class="custom-list-item" (click)="openDetail(i)">

              <div matListItemTitle>Operación N°{{i.numero_operacion}}</div>
              <div matListItemLine>Documento | número de factura {{i.numero_factura}}</div>

              <mat-icon matListItemIcon>chevron_right</mat-icon>
              <mat-icon matListItemIcon>description</mat-icon>
            </mat-list-item>

          }
        </mat-list>

      </mat-card-content>
    </mat-card>




  </div>

  <div *ngIf="loading == false && excedentes.length == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__faster animate__animated animate__fadeIn">
      <no-hay-registros></no-hay-registros>
  </div>

  <div *ngIf="loading == true && excedentes.length == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__faster animate__animated animate__fadeIn">
    <loading-interno></loading-interno>
  </div>

  <div *ngIf="loading == false && excedentes.length > 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <div  style="text-align: center;">
      <custom-paginator
      [pageSize]="10"
      [totalElements]="length"
      [currentPage]="page"
      (pageChange)="pageEvent($event)">
    </custom-paginator>
    </div>
  </div>



</div>