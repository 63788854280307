<div class="row row-padre background-wrapper" style="position: relative;">

  <div class="container-image"><img src="../../assets/simpli/simplilogo2.svg" height="100px"></div>

  <div class="fondo1"></div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

    <div class="row custom-row">

      <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12">
        <div class="labels-card">

          <div class="titulo-grande">Convierte tus facturas en la liquidez que tu empresa necesita</div>
          <div class="bajada-titulo">La oportunidad que buscas comienza aquí</div>


          <div class="row mt animate__animated animate__fadeInUp">


            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 numb-space">
              <div class="text-pink">más de</div>
              <div class="text-white">{{animatedNumber}} mil</div>
              <div class="text-gray">millones de pesos facilitados por Simpli para impulsar negocios como el tuyo</div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 numb-space">
              <div class="text-pink">más de</div>
              <div class="text-white">{{formatMoney(animatedNumber2)}}</div>
              <div class="text-gray">empresas han confiado en nosotros para potenciar su crecimiento</div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div class="text-pink">más de</div>
              <div class="text-white">{{formatMoney(animatedNumber3)}}</div>
              <div class="text-gray">operaciones realizadas respaldan nuestra experiencia</div>
            </div>

          </div>

        </div>
      </div>

      <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12" id="registro-form">
        <div class="form-card">
          <mat-card>

            <mat-card-content>

              <div class="titulo-card">Rellena los detalles para recibir una llamada de nuestros especialistas</div>

              <div class="container-stepper">

                <div class="step-group">
                  <div class="bubble-step" [ngClass]="{ 'bubble-step-active': paso == 1 }">
                    1
                  </div>

                </div>

                <div class="line-stepper2">
                  <div></div>
                </div>

                <div class="step-group">
                  <div class="bubble-step" [ngClass]="{ 'bubble-step-active': paso == 2 }">
                    2
                  </div>
                </div>

                <div class="line-stepper2">
                  <div></div>
                </div>

                <div class="step-group">
                  <div class="bubble-step" [ngClass]="{ 'bubble-step-active': paso == 3 }">
                    3
                  </div>
                </div>
              </div>

              @if(paso == 1){
              <form [formGroup]="valForm" (submit)="submitFormCompany($event, valForm.value)" autocomplete="off"
                class="animate__animated animate__fadeIn">
                <mat-form-field appearance="outline">
                  <mat-label>Correo electrónico</mat-label>
                  <input type="email" matInput required autocomplete="email" formControlName="username"
                    (change)="changeMail($event)" autocomplete="off">
                  <mat-spinner color="primary" style="margin-right: 10px;" matSuffix diameter="20"
                    *ngIf="this.show_spinner_mail"></mat-spinner>
                  <mat-hint>Ingresa tu correo electrónico</mat-hint>
                  <mat-error
                    *ngIf="valForm.controls['username'].hasError('required') && (valForm.controls['username'].dirty || valForm.controls['username'].touched)">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="valForm.controls['username'].hasError('pattern')">
                    Debe ser un correo electrónico valido
                  </mat-error>
                  <mat-error *ngIf="valForm.controls['username'].hasError('incorrect')">
                    Correo electrónico ya en uso
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Contraseña</mat-label>
                  <input matInput formControlName="password" autocomplete="password" [type]="hide ? 'password' : 'text'"
                    required autocomplete="new-name">
                  <button matSuffix mat-icon-button type="button" (click)="hide = !hide"
                    [attr.aria-label]="'Ocultar password'" [attr.aria-pressed]="hide">
                    <mat-icon class="simpli-color-gris">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-hint>Crea tu contraseña</mat-hint>
                  <mat-error
                    *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="valForm.controls['password'].hasError('minlength')">
                    Debe contener un mínimo de 6 caracteres
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Repite contraseña</mat-label>
                  <input matInput formControlName="password_confirmation" [type]="hideRe ? 'password' : 'text'" required
                    name="password" autocomplete="new-password">
                  <button matSuffix mat-icon-button type="button" (click)="hideRe = !hideRe"
                    [attr.aria-label]="'Ocultar password'" [attr.aria-pressed]="hideRe">
                    <mat-icon class="simpli-color-gris">{{hideRe ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-hint>Vuelve a introducir tu contraseña</mat-hint>
                  <mat-error
                    *ngIf="valForm.controls['password_confirmation'].hasError('required') && (valForm.controls['password_confirmation'].dirty || valForm.controls['password_confirmation'].touched)">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="valForm.get('password_confirmation').hasError('equalTo')">
                    La contraseña ingresada no coincide
                  </mat-error>
                </mat-form-field>

                <div class="btn-container">
                  <button class="primary-button-landing" [disabled]="show_spinner" mat-flat-button type="submit"
                    color="primary" style="margin-right: 0px;">
                    <mat-label *ngIf="show_spinner == false">Siguiente</mat-label>
                    <span *ngIf="show_spinner == true"><i class="fa-solid fa-spinner fa-spin-pulse"
                        style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
                  </button>

                </div>



              </form>
              }

              @if(paso == 2){
              <form [formGroup]="valFormCompany" (submit)="submitForm($event, valFormCompany.value)" autocomplete="off"
                class="animate__animated animate__fadeIn">

                <mat-form-field appearance="outline">
                  <mat-label>Rut de empresa</mat-label>
                  <input matInput type="text" required formControlName="rut" (keyup)="rutKey($event)"
                    (change)="checkRut($event)" name="rut_empresa" autocomplete="new-rut_empresa">
                  <mat-spinner color="primary" style="margin-right: 10px;" matSuffix diameter="20"
                    *ngIf="display_spinner"></mat-spinner>
                  <mat-hint>Ingresa tu RUT de Empresa sin puntos con guión</mat-hint>
                  <mat-error
                    *ngIf="valFormCompany.controls['rut'].hasError('required') && (valFormCompany.controls['rut'].dirty || valFormCompany.controls['rut'].touched)">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="valFormCompany.controls['rut'].hasError('invalid') || valFormCompany.controls['rut'].hasError('pattern')">El
                    RUT ingresado no es válido</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Nombre y Apellido</mat-label>
                  <input matInput required formControlName="nombre" autocomplete="off">
                  <mat-hint>Ingresa tu Nombre y Apellido</mat-hint>
                  <mat-error
                    *ngIf="valFormCompany.controls['nombre'].hasError('required') && (valFormCompany.controls['nombre'].dirty || valFormCompany.controls['nombre'].touched)">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Teléfono</mat-label>
                  <input matInput required formControlName="telefono" autocomplete="off" (change)="checkPhone($event)">
                  <mat-hint>Ingresa un número de teléfono válido</mat-hint>
                  <mat-error
                    *ngIf="valFormCompany.controls['telefono'].hasError('required') && (valFormCompany.controls['telefono'].dirty || valFormCompany.controls['telefono'].touched)">
                    Este campo es <strong>obligatorio</strong>
                  </mat-error>
                  <mat-error *ngIf="valFormCompany.controls['telefono'].hasError('invalid')">El número de teléfono no es
                    válido</mat-error>
                </mat-form-field>


                <div class="text-center mt-20">
                  <small class="simpli-color3">
                    Al registrarte, estas aceptando nuestra
                    <a class="simpli-color2 cursor-pointer text-none"
                      href="https://simplilatam.com/politica-de-privacidad/" target="_blank">Política de Privacidad</a>.
                    Además, enviaremos notificaciones por SMS. Si no deseas recibirlas, contacta a tu ejecutivo.
                  </small>
                </div>


                <div class="btn-container">

                  <button class="secondary-button-landing" mat-flat-button type="submit" color="primary"
                    (click)="paso = 1">
                    <mat-label>Atrás</mat-label>
                  </button>

                  <button class="primary-button-landing" [disabled]="show_spinner" mat-flat-button type="submit"
                    color="primary" style="margin-right: 0px;">
                    <mat-label *ngIf="show_spinner == false">Registrarme</mat-label>
                    <span *ngIf="show_spinner == true"><i class="fa-solid fa-spinner fa-spin-pulse"
                        style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
                  </button>
                </div>


              </form>
              }

              @if(paso == 3){
              <div class="text-center animate__animated animate__fadeIn">
                <div class="titulo-card">Tu cuenta ha sido creada con éxito</div>
                <div class="bajada-titulo">Ya puedes comenzar a usar tu cuenta Simpli</div>

                <button class="primary-button-landing" [disabled]="show_spinner" mat-flat-button color="primary"
                  style="margin-right: 0px;" (click)="dialog_success()">
                  Ingresar
                </button>
              </div>
              }


            </mat-card-content>
          </mat-card>
        </div>
      </div>

    </div>

  </div>


</div>

<div class="row" style="position: relative;">

  <div class="fondo2"></div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 scroll-animate">
    <div class="container-fondo2">
      Beneficios del factoring con Simpli
    </div>
  </div>

</div>



<div class="row custom-row2" style="position: relative;">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 swipercajas">

    <div class="container-cajas">
      <div class="cajas" *ngFor="let caja of cajas">
        <div class="icon-caja"><i [class]="caja.icon"></i></div>
        <div class="titulo-caja">{{caja.titulo}}</div>
        <div class="bajada-caja">{{caja.bajada}}</div>
      </div>
    </div>


  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 swipercajas-mobile">
    <div class="container-cajas">
      <div style="width: 90%;">
        <swiper-container id="swiper" #swiper speed="500" autoplay="false" css-mode="false" loop="true" navigation="false"
          pagination="true" scrollbar="false" virtual="false">
  
  
          <swiper-slide *ngFor="let caja of cajas">

            <div class="cajas" style="width: 90% !important;margin: 0 auto !important;margin-bottom: 100px !important;">
              <div class="icon-caja"><i [class]="caja.icon"></i></div>
              <div class="titulo-caja">{{caja.titulo}}</div>
              <div class="bajada-caja">{{caja.bajada}}</div>
            </div>

          </swiper-slide>

  
        </swiper-container>
      </div>
  
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 scroll-animate">

    <div class="btn-container btnresponsive" style="margin-bottom: 140px;margin-top: 50px;">
      <button mat-stroked-button class="primary-button-landing-extra" (click)="scrollToForm()">
        Hazte cliente
      </button>

    </div>

  </div>

</div>


<div class="flex-container">

  <div class="row custom-row6">
    <div class="fondo3"></div>

    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 container-blanco" style="position: relative; padding: 0px">
      <div class="container-texto-blanco">
        <div class="texto-blanco">
          Nuestra<br>presencia en<br>medios
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 container-medios">
      <div class="row">

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="container-medios-img text-only">
            <p class="text-img">Haz clic sobre las imágenes para poder ir a los videos de entrevistas y noticias</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="container-medios-img">
            <a href="https://youtu.be/ArIaXJEY5hA?si=OLURfDWF8MFeeBrR" target="_blank">
              <img src="../../assets/landing/duna.png" class="img-medios">
            </a>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="container-medios-img">
            <a href="https://youtu.be/MWow2fcU2pI?si=l5dDdy8MiEsm7p3Q" target="_blank">
              <img src="../../assets/landing/infinita.png" class="img-medios">
            </a>
          </div>
        </div>
        
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="container-medios-img">
            <a href="https://youtu.be/VQrON5Piwk4?si=JcKDIEtPNY1qEPsv" target="_blank">
              <img src="../../assets/landing/cnn.png" class="img-medios">
            </a>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="container-medios-img">
            <a href="https://simplilatam.com/category/eventos-y-prensa/" target="_blank">
              <img src="../../assets/landing/simpliblog.png" class="img-medios">
            </a>
          </div>
        </div>
        
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="container-medios-img">
            <a href="https://www.eldinamo.cl/empren-d/2024/07/10/presentan-simplihub-la-innovadora-suite-de-herramientas-para-la-optimizacion-del-financiamiento-empresarial/" target="_blank">
              <img src="../../assets/landing/el dinamo.jpg" class="img-medios">
            </a>
          </div>
        </div>
      </div>
    </div>



  </div>

  <div class="row custom-row3" style="position: relative;">

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div class="box-imgs">
        <img src="../../assets/landing/finte.png" class="imgs">
        <div class="text-img">Somos parte de FinteChile.</div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
      <div class="box-imgs">
        <img src="../../assets/landing/uaf.jpg" class="imgs">
        <div class="text-img">Regulados por la UAF.</div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
      <div class="box-imgs">
        <img src="../../assets/landing/cmf.png" class="imgs">
        <div class="text-img">En proceso de inscripción en la CMF</div>
      </div>
    </div>
  </div>

</div>

<div class="row custom-slice">
  <div class="fondo3"></div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

    <div class="container-slice">
      <div class="texto-slice">
        Nuestra<br>presencia en<br>medios
      </div>
    </div>
  </div>

  <div class="steepers">
    <div style="width: 90%;">
      <swiper-container id="swiper" #swiper speed="500" autoplay="false" css-mode="false" loop="true" navigation="true"
        pagination="true" scrollbar="false" virtual="false">


        <swiper-slide>
          <div class="slices">
            <div>
              <img src="../../assets/landing/simpliblog.png"
                (click)="openLink('https://simplilatam.com/category/eventos-y-prensa/')" class="square-image">
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="slices">
            <div>
              <img src="../../assets/landing/cnn.png"
                (click)="openLink('https://youtu.be/VQrON5Piwk4?si=JcKDIEtPNY1qEPsv')" class="square-image">
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="slices">
            <div>
              <img src="../../assets/landing/infinita.png"
                (click)="openLink('https://youtu.be/MWow2fcU2pI?si=l5dDdy8MiEsm7p3Q')" class="square-image">
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="slices">
            <div>
              <img src="../../assets/landing/duna.png"
                (click)="openLink('https://youtu.be/ArIaXJEY5hA?si=OLURfDWF8MFeeBrR')" class="square-image">
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="slices">
            <div>
              <img src="../../assets/landing/simplihub.png"
                (click)="openLink('https://www.eldinamo.cl/empren-d/2024/07/10/presentan-simplihub-la-innovadora-suite-de-herramientas-para-la-optimizacion-del-financiamiento-empresarial/')"
                class="square-image">
            </div>
          </div>
        </swiper-slide>

      </swiper-container>
    </div>

  </div>


</div>


<div class="row custom-row4" style="position: relative;">

  <div class="fondo4"></div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 scroll-animate">
    <div class="container-fondo2">
      Estamos a tu lado para que tu<br> negocio nunca se detenga
    </div>
  </div>

</div>



<div class="row custom-row5">


  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <div class="text-preg">Preguntas frecuentes</div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <mat-accordion>

      <!-- Primer panel -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            ¿Qué necesito para ser cliente?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Tener a la mano la clave de acceso al SII y registrarte en la plataforma.</p>
      </mat-expansion-panel>

      <!-- Segundo panel -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            ¿Cómo es el proceso de registro?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>El proceso es rápido y sencillo. Solo necesitas completar el formulario en línea.</p>
      </mat-expansion-panel>

      <!-- Tercer panel -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            ¿Hay un costo asociado al uso de la plataforma?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>No, la plataforma es completamente gratuita para nuestros clientes.</p>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>

<div class="row footer">
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 logo-footer fres" >
    <img src="../../assets/simpli/simplilogo2.svg" height="127px">
    <div class="text-footer">
      <a href="https://simplilatam.com/terminos-y-condiciones/">Términos y condiciones</a>

      <a
        href="https://simplilatam.com/politica-de-privacidad/?_gl=1*11wsf1f*_gcl_aw*R0NMLjE3MjgzOTM1NDguQ2owS0NRandzSk80QmhEb0FSSXNBRER2NHZCcmpDeFphS21HU0E2Y2RuQWtVaWxVbmM3b1ZZR1hxcFFiZlVhMzNQYWgtNWZDNEl3ZC05a2FBdGpfRUFMd193Y0I.*_gcl_au*MTM4OTI2NTk5LjE3MjgzOTM1NDg.*_ga*MTU1MTYxNDIzMS4xNzE1NzkzOTky*_ga_H1548MQ057*MTczMjEzMTYzMi4xMy4xLjE3MzIxMzI4NzguMTguMC4w">Política
        de privacidad</a>

      <a href="https://simplilatam.buk.cl/cul_partner_complaint/tickets/new" target="_blank">Canal de denuncias</a>
    </div>

  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-10 fres">
    <div class="text-final">© Todos los derechos reservados Simpli SA 2024</div>
  </div>
</div>

<button class="whatsapp-button">
  <a href="https://wa.me/+56934677786" target="_blank">
    <i class="qlwapp-icon qlwapp-whatsapp-icon"></i> ¿Conversemos?
  </a>
</button>