<div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;margin-top: 20px;">
      <div class="close-dialog" mat-dialog-close style="display: none;">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
</div>



<mat-dialog-content>

    <div style="text-align: center;">

        <div *ngIf="cedio == false" class="label-dialog-strong">Cede a través de un facturador<br> electrónico externo</div>

        <div *ngIf="cedio == false" style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
          <i style="font-size: 65px;color:var(--simpli-color6)" class="fa-light fa-hand-peace"></i>
        </div>

        <div *ngIf="cedio == true" style="text-align: center;margin-bottom: 60px;margin-top: 40px;">
          <i style="font-size: 65px;color:var(--simpli-color7)" class="fa-light fa-circle-check"></i>
        </div>

        <div *ngIf="cedio == false" class="label-dialog">Recuerda que al ceder por un facturador electrónico externo,<br> también estás aceptando las condiciones de esta operación.</div>

        <div *ngIf="cedio == true" class="label-dialog">Una vez aplicada la cesión, podrás revisar el estado<br> de la recepción de tus facturas por esta plataforma.</div>


        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
            <div class="btn-simpli-container">
              <button class="primary-button" mat-flat-button *ngIf="cedio == false" (click)="ceder()">
                Continuar
              </button>

              <button class="primary-button" mat-flat-button *ngIf="cedio == true" (click)="close()">
                Ver estado
              </button>

              <button class="secondary-button" mat-flat-button mat-dialog-close *ngIf="cedio == false">
                Volver
              </button>
            </div>
            
          </div>
        </div>

    </div>


    
</mat-dialog-content>